const colors = [{
      "hue":1,
      "brightness":1,
      "vividness":1,
      "r":20,
      "g":20,
      "b":20,
      "hex":"#141414",
      "file_location":"assets/colors_organized/hue_01/brightness_01/2020040413104900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":1,
      "vividness":2,
      "r":19,
      "g":17,
      "b":18,
      "hex":"#131112",
      "file_location":"assets/colors_organized/hue_01/brightness_01/2020040413105001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":1,
      "vividness":3,
      "r":19,
      "g":15,
      "b":14,
      "hex":"#130f0e",
      "file_location":"assets/colors_organized/hue_01/brightness_01/2020040413105100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":1,
      "vividness":4,
      "r":19,
      "g":13,
      "b":13,
      "hex":"#130d0d",
      "file_location":"assets/colors_organized/hue_01/brightness_01/2020040413105200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":1,
      "vividness":5,
      "r":19,
      "g":9,
      "b":8,
      "hex":"#130908",
      "file_location":"assets/colors_organized/hue_01/brightness_01/2020040413105400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":1,
      "vividness":6,
      "r":18,
      "g":6,
      "b":6,
      "hex":"#120606",
      "file_location":"assets/colors_organized/hue_01/brightness_01/2020040413105500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":1,
      "vividness":7,
      "r":19,
      "g":3,
      "b":3,
      "hex":"#130303",
      "file_location":"assets/colors_organized/hue_01/brightness_01/2020040413105700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":1,
      "vividness":8,
      "r":19,
      "g":0,
      "b":0,
      "hex":"#130000",
      "file_location":"assets/colors_organized/hue_01/brightness_01/2020040413105800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":2,
      "vividness":1,
      "r":35,
      "g":35,
      "b":35,
      "hex":"#232323",
      "file_location":"assets/colors_organized/hue_01/brightness_02/2020040413103600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":2,
      "vividness":2,
      "r":33,
      "g":32,
      "b":30,
      "hex":"#21201e",
      "file_location":"assets/colors_organized/hue_01/brightness_02/2020040413103700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":2,
      "vividness":3,
      "r":34,
      "g":30,
      "b":29,
      "hex":"#221e1d",
      "file_location":"assets/colors_organized/hue_01/brightness_02/2020040413103701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":2,
      "vividness":4,
      "r":34,
      "g":28,
      "b":28,
      "hex":"#221c1c",
      "file_location":"assets/colors_organized/hue_01/brightness_02/2020040413103800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":2,
      "vividness":5,
      "r":35,
      "g":25,
      "b":24,
      "hex":"#231918",
      "file_location":"assets/colors_organized/hue_01/brightness_02/2020040413103801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":2,
      "vividness":6,
      "r":34,
      "g":22,
      "b":22,
      "hex":"#221616",
      "file_location":"assets/colors_organized/hue_01/brightness_02/2020040413103900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":2,
      "vividness":7,
      "r":33,
      "g":21,
      "b":21,
      "hex":"#211515",
      "file_location":"assets/colors_organized/hue_01/brightness_02/2020040413103901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":2,
      "vividness":8,
      "r":34,
      "g":18,
      "b":18,
      "hex":"#221212",
      "file_location":"assets/colors_organized/hue_01/brightness_02/2020040413104000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":2,
      "vividness":9,
      "r":34,
      "g":14,
      "b":13,
      "hex":"#220e0d",
      "file_location":"assets/colors_organized/hue_01/brightness_02/2020040413104100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":2,
      "vividness":10,
      "r":34,
      "g":13,
      "b":12,
      "hex":"#220d0c",
      "file_location":"assets/colors_organized/hue_01/brightness_02/2020040413104101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":2,
      "vividness":11,
      "r":34,
      "g":10,
      "b":10,
      "hex":"#220a0a",
      "file_location":"assets/colors_organized/hue_01/brightness_02/2020040413104200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":2,
      "vividness":12,
      "r":35,
      "g":7,
      "b":6,
      "hex":"#230706",
      "file_location":"assets/colors_organized/hue_01/brightness_02/2020040413104300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":2,
      "vividness":13,
      "r":34,
      "g":3,
      "b":1,
      "hex":"#220301",
      "file_location":"assets/colors_organized/hue_01/brightness_02/2020040413104400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":3,
      "vividness":1,
      "r":50,
      "g":50,
      "b":50,
      "hex":"#323232",
      "file_location":"assets/colors_organized/hue_01/brightness_03/2020040413102400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":3,
      "vividness":2,
      "r":50,
      "g":46,
      "b":45,
      "hex":"#322e2d",
      "file_location":"assets/colors_organized/hue_01/brightness_03/2020040413102401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":3,
      "vividness":3,
      "r":49,
      "g":43,
      "b":43,
      "hex":"#312b2b",
      "file_location":"assets/colors_organized/hue_01/brightness_03/2020040413102500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":3,
      "vividness":4,
      "r":50,
      "g":40,
      "b":39,
      "hex":"#322827",
      "file_location":"assets/colors_organized/hue_01/brightness_03/2020040413102501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":3,
      "vividness":5,
      "r":50,
      "g":36,
      "b":36,
      "hex":"#322424",
      "file_location":"assets/colors_organized/hue_01/brightness_03/2020040413102600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":3,
      "vividness":6,
      "r":49,
      "g":33,
      "b":33,
      "hex":"#312121",
      "file_location":"assets/colors_organized/hue_01/brightness_03/2020040413102700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":3,
      "vividness":7,
      "r":50,
      "g":30,
      "b":29,
      "hex":"#321e1d",
      "file_location":"assets/colors_organized/hue_01/brightness_03/2020040413102701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":3,
      "vividness":8,
      "r":48,
      "g":27,
      "b":26,
      "hex":"#301b1a",
      "file_location":"assets/colors_organized/hue_01/brightness_03/2020040413102800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":3,
      "vividness":9,
      "r":49,
      "g":23,
      "b":22,
      "hex":"#311716",
      "file_location":"assets/colors_organized/hue_01/brightness_03/2020040413102801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":3,
      "vividness":10,
      "r":49,
      "g":19,
      "b":19,
      "hex":"#311313",
      "file_location":"assets/colors_organized/hue_01/brightness_03/2020040413102900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":3,
      "vividness":11,
      "r":49,
      "g":15,
      "b":14,
      "hex":"#310f0e",
      "file_location":"assets/colors_organized/hue_01/brightness_03/2020040413102901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":3,
      "vividness":12,
      "r":49,
      "g":13,
      "b":13,
      "hex":"#310d0d",
      "file_location":"assets/colors_organized/hue_01/brightness_03/2020040413103000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":3,
      "vividness":13,
      "r":49,
      "g":9,
      "b":9,
      "hex":"#310909",
      "file_location":"assets/colors_organized/hue_01/brightness_03/2020040413103001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":3,
      "vividness":14,
      "r":48,
      "g":6,
      "b":7,
      "hex":"#300607",
      "file_location":"assets/colors_organized/hue_01/brightness_03/2020040413103100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":3,
      "vividness":15,
      "r":49,
      "g":3,
      "b":3,
      "hex":"#310303",
      "file_location":"assets/colors_organized/hue_01/brightness_03/2020040413103101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":4,
      "vividness":1,
      "r":65,
      "g":65,
      "b":65,
      "hex":"#414141",
      "file_location":"assets/colors_organized/hue_01/brightness_04/2020040413101200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":4,
      "vividness":2,
      "r":65,
      "g":59,
      "b":59,
      "hex":"#413b3b",
      "file_location":"assets/colors_organized/hue_01/brightness_04/2020040413101201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":4,
      "vividness":3,
      "r":65,
      "g":55,
      "b":54,
      "hex":"#413736",
      "file_location":"assets/colors_organized/hue_01/brightness_04/2020040413101300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":4,
      "vividness":4,
      "r":66,
      "g":52,
      "b":52,
      "hex":"#423434",
      "file_location":"assets/colors_organized/hue_01/brightness_04/2020040413101301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":4,
      "vividness":5,
      "r":65,
      "g":47,
      "b":47,
      "hex":"#412f2f",
      "file_location":"assets/colors_organized/hue_01/brightness_04/2020040413101400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":4,
      "vividness":6,
      "r":64,
      "g":43,
      "b":42,
      "hex":"#402b2a",
      "file_location":"assets/colors_organized/hue_01/brightness_04/2020040413101401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":4,
      "vividness":7,
      "r":64,
      "g":38,
      "b":37,
      "hex":"#402625",
      "file_location":"assets/colors_organized/hue_01/brightness_04/2020040413101500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":4,
      "vividness":8,
      "r":65,
      "g":33,
      "b":34,
      "hex":"#412122",
      "file_location":"assets/colors_organized/hue_01/brightness_04/2020040413101501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":4,
      "vividness":9,
      "r":65,
      "g":29,
      "b":29,
      "hex":"#411d1d",
      "file_location":"assets/colors_organized/hue_01/brightness_04/2020040413101600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":4,
      "vividness":10,
      "r":65,
      "g":27,
      "b":26,
      "hex":"#411b1a",
      "file_location":"assets/colors_organized/hue_01/brightness_04/2020040413101601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":4,
      "vividness":11,
      "r":65,
      "g":21,
      "b":22,
      "hex":"#411516",
      "file_location":"assets/colors_organized/hue_01/brightness_04/2020040413101700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":4,
      "vividness":12,
      "r":66,
      "g":18,
      "b":18,
      "hex":"#421212",
      "file_location":"assets/colors_organized/hue_01/brightness_04/2020040413101701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":4,
      "vividness":13,
      "r":64,
      "g":13,
      "b":12,
      "hex":"#400d0c",
      "file_location":"assets/colors_organized/hue_01/brightness_04/2020040413101800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":4,
      "vividness":14,
      "r":65,
      "g":7,
      "b":6,
      "hex":"#410706",
      "file_location":"assets/colors_organized/hue_01/brightness_04/2020040413101801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":4,
      "vividness":15,
      "r":64,
      "g":3,
      "b":2,
      "hex":"#400302",
      "file_location":"assets/colors_organized/hue_01/brightness_04/2020040413101900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":5,
      "vividness":1,
      "r":80,
      "g":80,
      "b":80,
      "hex":"#505050",
      "file_location":"assets/colors_organized/hue_01/brightness_05/2020040413095900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":5,
      "vividness":2,
      "r":80,
      "g":74,
      "b":74,
      "hex":"#504a4a",
      "file_location":"assets/colors_organized/hue_01/brightness_05/2020040413095901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":5,
      "vividness":3,
      "r":79,
      "g":69,
      "b":68,
      "hex":"#4f4544",
      "file_location":"assets/colors_organized/hue_01/brightness_05/2020040413100000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":5,
      "vividness":4,
      "r":80,
      "g":64,
      "b":64,
      "hex":"#504040",
      "file_location":"assets/colors_organized/hue_01/brightness_05/2020040413100100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":5,
      "vividness":5,
      "r":79,
      "g":58,
      "b":57,
      "hex":"#4f3a39",
      "file_location":"assets/colors_organized/hue_01/brightness_05/2020040413100101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":5,
      "vividness":6,
      "r":81,
      "g":53,
      "b":52,
      "hex":"#513534",
      "file_location":"assets/colors_organized/hue_01/brightness_05/2020040413100200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":5,
      "vividness":7,
      "r":79,
      "g":48,
      "b":46,
      "hex":"#4f302e",
      "file_location":"assets/colors_organized/hue_01/brightness_05/2020040413100201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":5,
      "vividness":8,
      "r":80,
      "g":42,
      "b":41,
      "hex":"#502a29",
      "file_location":"assets/colors_organized/hue_01/brightness_05/2020040413100300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":5,
      "vividness":9,
      "r":80,
      "g":36,
      "b":37,
      "hex":"#502425",
      "file_location":"assets/colors_organized/hue_01/brightness_05/2020040413100400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":5,
      "vividness":10,
      "r":80,
      "g":32,
      "b":32,
      "hex":"#502020",
      "file_location":"assets/colors_organized/hue_01/brightness_05/2020040413100401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":5,
      "vividness":11,
      "r":80,
      "g":26,
      "b":26,
      "hex":"#501a1a",
      "file_location":"assets/colors_organized/hue_01/brightness_05/2020040413100500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":5,
      "vividness":12,
      "r":80,
      "g":22,
      "b":21,
      "hex":"#501615",
      "file_location":"assets/colors_organized/hue_01/brightness_05/2020040413100600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":5,
      "vividness":13,
      "r":80,
      "g":14,
      "b":15,
      "hex":"#500e0f",
      "file_location":"assets/colors_organized/hue_01/brightness_05/2020040413100601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":5,
      "vividness":14,
      "r":80,
      "g":10,
      "b":10,
      "hex":"#500a0a",
      "file_location":"assets/colors_organized/hue_01/brightness_05/2020040413100700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":5,
      "vividness":15,
      "r":80,
      "g":6,
      "b":7,
      "hex":"#500607",
      "file_location":"assets/colors_organized/hue_01/brightness_05/2020040413100701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":6,
      "vividness":1,
      "r":95,
      "g":95,
      "b":95,
      "hex":"#5f5f5f",
      "file_location":"assets/colors_organized/hue_01/brightness_06/2020040413094500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":6,
      "vividness":2,
      "r":94,
      "g":88,
      "b":88,
      "hex":"#5e5858",
      "file_location":"assets/colors_organized/hue_01/brightness_06/2020040413094600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":6,
      "vividness":3,
      "r":94,
      "g":82,
      "b":82,
      "hex":"#5e5252",
      "file_location":"assets/colors_organized/hue_01/brightness_06/2020040413094601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":6,
      "vividness":4,
      "r":94,
      "g":76,
      "b":76,
      "hex":"#5e4c4c",
      "file_location":"assets/colors_organized/hue_01/brightness_06/2020040413094700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":6,
      "vividness":5,
      "r":95,
      "g":69,
      "b":68,
      "hex":"#5f4544",
      "file_location":"assets/colors_organized/hue_01/brightness_06/2020040413094800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":6,
      "vividness":6,
      "r":94,
      "g":63,
      "b":61,
      "hex":"#5e3f3d",
      "file_location":"assets/colors_organized/hue_01/brightness_06/2020040413094900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":6,
      "vividness":7,
      "r":95,
      "g":57,
      "b":56,
      "hex":"#5f3938",
      "file_location":"assets/colors_organized/hue_01/brightness_06/2020040413094901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":6,
      "vividness":8,
      "r":95,
      "g":49,
      "b":49,
      "hex":"#5f3131",
      "file_location":"assets/colors_organized/hue_01/brightness_06/2020040413095000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":6,
      "vividness":9,
      "r":95,
      "g":44,
      "b":43,
      "hex":"#5f2c2b",
      "file_location":"assets/colors_organized/hue_01/brightness_06/2020040413095100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":6,
      "vividness":10,
      "r":96,
      "g":38,
      "b":37,
      "hex":"#602625",
      "file_location":"assets/colors_organized/hue_01/brightness_06/2020040413095101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":6,
      "vividness":11,
      "r":95,
      "g":31,
      "b":31,
      "hex":"#5f1f1f",
      "file_location":"assets/colors_organized/hue_01/brightness_06/2020040413095200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":6,
      "vividness":12,
      "r":95,
      "g":25,
      "b":25,
      "hex":"#5f1919",
      "file_location":"assets/colors_organized/hue_01/brightness_06/2020040413095201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":6,
      "vividness":13,
      "r":95,
      "g":19,
      "b":19,
      "hex":"#5f1313",
      "file_location":"assets/colors_organized/hue_01/brightness_06/2020040413095300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":6,
      "vividness":14,
      "r":94,
      "g":13,
      "b":12,
      "hex":"#5e0d0c",
      "file_location":"assets/colors_organized/hue_01/brightness_06/2020040413095400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":6,
      "vividness":15,
      "r":95,
      "g":7,
      "b":6,
      "hex":"#5f0706",
      "file_location":"assets/colors_organized/hue_01/brightness_06/2020040413095401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":7,
      "vividness":1,
      "r":110,
      "g":110,
      "b":110,
      "hex":"#6e6e6e",
      "file_location":"assets/colors_organized/hue_01/brightness_07/2020040413093100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":7,
      "vividness":2,
      "r":110,
      "g":101,
      "b":102,
      "hex":"#6e6566",
      "file_location":"assets/colors_organized/hue_01/brightness_07/2020040413093200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":7,
      "vividness":3,
      "r":109,
      "g":95,
      "b":94,
      "hex":"#6d5f5e",
      "file_location":"assets/colors_organized/hue_01/brightness_07/2020040413093300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":7,
      "vividness":4,
      "r":109,
      "g":88,
      "b":87,
      "hex":"#6d5857",
      "file_location":"assets/colors_organized/hue_01/brightness_07/2020040413093400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":7,
      "vividness":5,
      "r":110,
      "g":80,
      "b":80,
      "hex":"#6e5050",
      "file_location":"assets/colors_organized/hue_01/brightness_07/2020040413093401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":7,
      "vividness":6,
      "r":109,
      "g":73,
      "b":73,
      "hex":"#6d4949",
      "file_location":"assets/colors_organized/hue_01/brightness_07/2020040413093500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":7,
      "vividness":7,
      "r":110,
      "g":66,
      "b":65,
      "hex":"#6e4241",
      "file_location":"assets/colors_organized/hue_01/brightness_07/2020040413093600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":7,
      "vividness":8,
      "r":109,
      "g":58,
      "b":57,
      "hex":"#6d3a39",
      "file_location":"assets/colors_organized/hue_01/brightness_07/2020040413093700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":7,
      "vividness":9,
      "r":110,
      "g":50,
      "b":50,
      "hex":"#6e3232",
      "file_location":"assets/colors_organized/hue_01/brightness_07/2020040413093701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":7,
      "vividness":10,
      "r":109,
      "g":43,
      "b":44,
      "hex":"#6d2b2c",
      "file_location":"assets/colors_organized/hue_01/brightness_07/2020040413093800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":7,
      "vividness":11,
      "r":110,
      "g":36,
      "b":35,
      "hex":"#6e2423",
      "file_location":"assets/colors_organized/hue_01/brightness_07/2020040413093900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":7,
      "vividness":12,
      "r":110,
      "g":30,
      "b":29,
      "hex":"#6e1e1d",
      "file_location":"assets/colors_organized/hue_01/brightness_07/2020040413093901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":7,
      "vividness":13,
      "r":110,
      "g":22,
      "b":21,
      "hex":"#6e1615",
      "file_location":"assets/colors_organized/hue_01/brightness_07/2020040413094000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":7,
      "vividness":14,
      "r":111,
      "g":13,
      "b":12,
      "hex":"#6f0d0c",
      "file_location":"assets/colors_organized/hue_01/brightness_07/2020040413094001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":7,
      "vividness":15,
      "r":110,
      "g":6,
      "b":5,
      "hex":"#6e0605",
      "file_location":"assets/colors_organized/hue_01/brightness_07/2020040413094100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":8,
      "vividness":1,
      "r":124,
      "g":124,
      "b":124,
      "hex":"#7c7c7c",
      "file_location":"assets/colors_organized/hue_01/brightness_08/2020040413090800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":8,
      "vividness":2,
      "r":124,
      "g":115,
      "b":116,
      "hex":"#7c7374",
      "file_location":"assets/colors_organized/hue_01/brightness_08/2020040413091300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":8,
      "vividness":3,
      "r":124,
      "g":108,
      "b":108,
      "hex":"#7c6c6c",
      "file_location":"assets/colors_organized/hue_01/brightness_08/2020040413091400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":8,
      "vividness":4,
      "r":123,
      "g":99,
      "b":99,
      "hex":"#7b6363",
      "file_location":"assets/colors_organized/hue_01/brightness_08/2020040413091500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":8,
      "vividness":5,
      "r":123,
      "g":92,
      "b":90,
      "hex":"#7b5c5a",
      "file_location":"assets/colors_organized/hue_01/brightness_08/2020040413091600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":8,
      "vividness":6,
      "r":123,
      "g":83,
      "b":83,
      "hex":"#7b5353",
      "file_location":"assets/colors_organized/hue_01/brightness_08/2020040413091700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":8,
      "vividness":7,
      "r":124,
      "g":74,
      "b":73,
      "hex":"#7c4a49",
      "file_location":"assets/colors_organized/hue_01/brightness_08/2020040413091800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":8,
      "vividness":8,
      "r":124,
      "g":66,
      "b":65,
      "hex":"#7c4241",
      "file_location":"assets/colors_organized/hue_01/brightness_08/2020040413091900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":8,
      "vividness":9,
      "r":123,
      "g":57,
      "b":58,
      "hex":"#7b393a",
      "file_location":"assets/colors_organized/hue_01/brightness_08/2020040413092000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":8,
      "vividness":10,
      "r":124,
      "g":48,
      "b":48,
      "hex":"#7c3030",
      "file_location":"assets/colors_organized/hue_01/brightness_08/2020040413092100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":8,
      "vividness":11,
      "r":124,
      "g":40,
      "b":40,
      "hex":"#7c2828",
      "file_location":"assets/colors_organized/hue_01/brightness_08/2020040413092400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":8,
      "vividness":12,
      "r":124,
      "g":34,
      "b":34,
      "hex":"#7c2222",
      "file_location":"assets/colors_organized/hue_01/brightness_08/2020040413092401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":8,
      "vividness":13,
      "r":125,
      "g":22,
      "b":23,
      "hex":"#7d1617",
      "file_location":"assets/colors_organized/hue_01/brightness_08/2020040413092500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":8,
      "vividness":14,
      "r":124,
      "g":14,
      "b":13,
      "hex":"#7c0e0d",
      "file_location":"assets/colors_organized/hue_01/brightness_08/2020040413092600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":8,
      "vividness":15,
      "r":125,
      "g":7,
      "b":7,
      "hex":"#7d0707",
      "file_location":"assets/colors_organized/hue_01/brightness_08/2020040413092601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":9,
      "vividness":1,
      "r":139,
      "g":139,
      "b":139,
      "hex":"#8b8b8b",
      "file_location":"assets/colors_organized/hue_01/brightness_09/2020040413084800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":9,
      "vividness":2,
      "r":139,
      "g":131,
      "b":129,
      "hex":"#8b8381",
      "file_location":"assets/colors_organized/hue_01/brightness_09/2020040413084900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":9,
      "vividness":3,
      "r":139,
      "g":121,
      "b":121,
      "hex":"#8b7979",
      "file_location":"assets/colors_organized/hue_01/brightness_09/2020040413085000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":9,
      "vividness":4,
      "r":139,
      "g":111,
      "b":110,
      "hex":"#8b6f6e",
      "file_location":"assets/colors_organized/hue_01/brightness_09/2020040413085100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":9,
      "vividness":5,
      "r":140,
      "g":101,
      "b":102,
      "hex":"#8c6566",
      "file_location":"assets/colors_organized/hue_01/brightness_09/2020040413085200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":9,
      "vividness":6,
      "r":139,
      "g":93,
      "b":93,
      "hex":"#8b5d5d",
      "file_location":"assets/colors_organized/hue_01/brightness_09/2020040413085300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":9,
      "vividness":7,
      "r":139,
      "g":83,
      "b":82,
      "hex":"#8b5352",
      "file_location":"assets/colors_organized/hue_01/brightness_09/2020040413085400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":9,
      "vividness":8,
      "r":139,
      "g":73,
      "b":74,
      "hex":"#8b494a",
      "file_location":"assets/colors_organized/hue_01/brightness_09/2020040413085401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":9,
      "vividness":9,
      "r":139,
      "g":65,
      "b":64,
      "hex":"#8b4140",
      "file_location":"assets/colors_organized/hue_01/brightness_09/2020040413085500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":9,
      "vividness":10,
      "r":139,
      "g":55,
      "b":55,
      "hex":"#8b3737",
      "file_location":"assets/colors_organized/hue_01/brightness_09/2020040413085600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":9,
      "vividness":11,
      "r":140,
      "g":46,
      "b":46,
      "hex":"#8c2e2e",
      "file_location":"assets/colors_organized/hue_01/brightness_09/2020040413085800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":9,
      "vividness":12,
      "r":139,
      "g":36,
      "b":37,
      "hex":"#8b2425",
      "file_location":"assets/colors_organized/hue_01/brightness_09/2020040413085900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":9,
      "vividness":13,
      "r":139,
      "g":27,
      "b":26,
      "hex":"#8b1b1a",
      "file_location":"assets/colors_organized/hue_01/brightness_09/2020040413090000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":9,
      "vividness":14,
      "r":140,
      "g":18,
      "b":17,
      "hex":"#8c1211",
      "file_location":"assets/colors_organized/hue_01/brightness_09/2020040413090100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":9,
      "vividness":15,
      "r":139,
      "g":9,
      "b":9,
      "hex":"#8b0909",
      "file_location":"assets/colors_organized/hue_01/brightness_09/2020040413090200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":10,
      "vividness":1,
      "r":154,
      "g":154,
      "b":154,
      "hex":"#9a9a9a",
      "file_location":"assets/colors_organized/hue_01/brightness_10/2020040413083100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":10,
      "vividness":2,
      "r":154,
      "g":144,
      "b":143,
      "hex":"#9a908f",
      "file_location":"assets/colors_organized/hue_01/brightness_10/2020040413083200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":10,
      "vividness":3,
      "r":154,
      "g":134,
      "b":135,
      "hex":"#9a8687",
      "file_location":"assets/colors_organized/hue_01/brightness_10/2020040413083300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":10,
      "vividness":4,
      "r":154,
      "g":122,
      "b":123,
      "hex":"#9a7a7b",
      "file_location":"assets/colors_organized/hue_01/brightness_10/2020040413083400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":10,
      "vividness":5,
      "r":154,
      "g":112,
      "b":113,
      "hex":"#9a7071",
      "file_location":"assets/colors_organized/hue_01/brightness_10/2020040413083401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":10,
      "vividness":6,
      "r":154,
      "g":103,
      "b":102,
      "hex":"#9a6766",
      "file_location":"assets/colors_organized/hue_01/brightness_10/2020040413083500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":10,
      "vividness":7,
      "r":153,
      "g":92,
      "b":91,
      "hex":"#995c5b",
      "file_location":"assets/colors_organized/hue_01/brightness_10/2020040413083600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":10,
      "vividness":8,
      "r":153,
      "g":81,
      "b":82,
      "hex":"#995152",
      "file_location":"assets/colors_organized/hue_01/brightness_10/2020040413083700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":10,
      "vividness":9,
      "r":153,
      "g":72,
      "b":71,
      "hex":"#994847",
      "file_location":"assets/colors_organized/hue_01/brightness_10/2020040413083800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":10,
      "vividness":10,
      "r":155,
      "g":61,
      "b":61,
      "hex":"#9b3d3d",
      "file_location":"assets/colors_organized/hue_01/brightness_10/2020040413083900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":10,
      "vividness":11,
      "r":155,
      "g":51,
      "b":50,
      "hex":"#9b3332",
      "file_location":"assets/colors_organized/hue_01/brightness_10/2020040413084000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":10,
      "vividness":12,
      "r":155,
      "g":41,
      "b":41,
      "hex":"#9b2929",
      "file_location":"assets/colors_organized/hue_01/brightness_10/2020040413084001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":10,
      "vividness":13,
      "r":154,
      "g":30,
      "b":30,
      "hex":"#9a1e1e",
      "file_location":"assets/colors_organized/hue_01/brightness_10/2020040413084100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":10,
      "vividness":14,
      "r":154,
      "g":20,
      "b":19,
      "hex":"#9a1413",
      "file_location":"assets/colors_organized/hue_01/brightness_10/2020040413084200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":10,
      "vividness":15,
      "r":154,
      "g":10,
      "b":9,
      "hex":"#9a0a09",
      "file_location":"assets/colors_organized/hue_01/brightness_10/2020040413084300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":11,
      "vividness":1,
      "r":169,
      "g":169,
      "b":169,
      "hex":"#a9a9a9",
      "file_location":"assets/colors_organized/hue_01/brightness_11/2020040413073400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":11,
      "vividness":2,
      "r":168,
      "g":158,
      "b":157,
      "hex":"#a89e9d",
      "file_location":"assets/colors_organized/hue_01/brightness_11/2020040413073500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":11,
      "vividness":3,
      "r":169,
      "g":148,
      "b":147,
      "hex":"#a99493",
      "file_location":"assets/colors_organized/hue_01/brightness_11/2020040413073600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":11,
      "vividness":4,
      "r":169,
      "g":135,
      "b":134,
      "hex":"#a98786",
      "file_location":"assets/colors_organized/hue_01/brightness_11/2020040413073700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":11,
      "vividness":5,
      "r":169,
      "g":123,
      "b":123,
      "hex":"#a97b7b",
      "file_location":"assets/colors_organized/hue_01/brightness_11/2020040413073900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":11,
      "vividness":6,
      "r":169,
      "g":113,
      "b":112,
      "hex":"#a97170",
      "file_location":"assets/colors_organized/hue_01/brightness_11/2020040413074000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":11,
      "vividness":7,
      "r":169,
      "g":101,
      "b":100,
      "hex":"#a96564",
      "file_location":"assets/colors_organized/hue_01/brightness_11/2020040413074100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":11,
      "vividness":8,
      "r":170,
      "g":90,
      "b":89,
      "hex":"#aa5a59",
      "file_location":"assets/colors_organized/hue_01/brightness_11/2020040413074200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":11,
      "vividness":9,
      "r":169,
      "g":79,
      "b":79,
      "hex":"#a94f4f",
      "file_location":"assets/colors_organized/hue_01/brightness_11/2020040413074300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":11,
      "vividness":10,
      "r":169,
      "g":66,
      "b":67,
      "hex":"#a94243",
      "file_location":"assets/colors_organized/hue_01/brightness_11/2020040413074400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":11,
      "vividness":11,
      "r":170,
      "g":56,
      "b":56,
      "hex":"#aa3838",
      "file_location":"assets/colors_organized/hue_01/brightness_11/2020040413074500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":11,
      "vividness":12,
      "r":169,
      "g":45,
      "b":45,
      "hex":"#a92d2d",
      "file_location":"assets/colors_organized/hue_01/brightness_11/2020040413074600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":11,
      "vividness":13,
      "r":169,
      "g":33,
      "b":33,
      "hex":"#a92121",
      "file_location":"assets/colors_organized/hue_01/brightness_11/2020040413074800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":11,
      "vividness":14,
      "r":170,
      "g":22,
      "b":22,
      "hex":"#aa1616",
      "file_location":"assets/colors_organized/hue_01/brightness_11/2020040413074900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":11,
      "vividness":15,
      "r":169,
      "g":9,
      "b":9,
      "hex":"#a90909",
      "file_location":"assets/colors_organized/hue_01/brightness_11/2020040413075000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":12,
      "vividness":1,
      "r":184,
      "g":184,
      "b":184,
      "hex":"#b8b8b8",
      "file_location":"assets/colors_organized/hue_01/brightness_12/2020040413071100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":12,
      "vividness":2,
      "r":184,
      "g":172,
      "b":172,
      "hex":"#b8acac",
      "file_location":"assets/colors_organized/hue_01/brightness_12/2020040413071200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":12,
      "vividness":3,
      "r":184,
      "g":160,
      "b":160,
      "hex":"#b8a0a0",
      "file_location":"assets/colors_organized/hue_01/brightness_12/2020040413071300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":12,
      "vividness":4,
      "r":183,
      "g":147,
      "b":147,
      "hex":"#b79393",
      "file_location":"assets/colors_organized/hue_01/brightness_12/2020040413071500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":12,
      "vividness":5,
      "r":184,
      "g":136,
      "b":136,
      "hex":"#b88888",
      "file_location":"assets/colors_organized/hue_01/brightness_12/2020040413071700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":12,
      "vividness":6,
      "r":184,
      "g":122,
      "b":123,
      "hex":"#b87a7b",
      "file_location":"assets/colors_organized/hue_01/brightness_12/2020040413071800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":12,
      "vividness":7,
      "r":184,
      "g":110,
      "b":109,
      "hex":"#b86e6d",
      "file_location":"assets/colors_organized/hue_01/brightness_12/2020040413072000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":12,
      "vividness":8,
      "r":184,
      "g":98,
      "b":97,
      "hex":"#b86261",
      "file_location":"assets/colors_organized/hue_01/brightness_12/2020040413072100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":12,
      "vividness":9,
      "r":184,
      "g":86,
      "b":85,
      "hex":"#b85655",
      "file_location":"assets/colors_organized/hue_01/brightness_12/2020040413072200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":12,
      "vividness":10,
      "r":185,
      "g":73,
      "b":72,
      "hex":"#b94948",
      "file_location":"assets/colors_organized/hue_01/brightness_12/2020040413072300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":12,
      "vividness":11,
      "r":185,
      "g":61,
      "b":61,
      "hex":"#b93d3d",
      "file_location":"assets/colors_organized/hue_01/brightness_12/2020040413072400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":12,
      "vividness":12,
      "r":184,
      "g":48,
      "b":48,
      "hex":"#b83030",
      "file_location":"assets/colors_organized/hue_01/brightness_12/2020040413072500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":12,
      "vividness":13,
      "r":184,
      "g":36,
      "b":36,
      "hex":"#b82424",
      "file_location":"assets/colors_organized/hue_01/brightness_12/2020040413072600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":12,
      "vividness":14,
      "r":185,
      "g":22,
      "b":23,
      "hex":"#b91617",
      "file_location":"assets/colors_organized/hue_01/brightness_12/2020040413072700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":12,
      "vividness":15,
      "r":185,
      "g":13,
      "b":13,
      "hex":"#b90d0d",
      "file_location":"assets/colors_organized/hue_01/brightness_12/2020040413072800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":13,
      "vividness":1,
      "r":199,
      "g":199,
      "b":199,
      "hex":"#c7c7c7",
      "file_location":"assets/colors_organized/hue_01/brightness_13/2020040413064900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":13,
      "vividness":2,
      "r":200,
      "g":186,
      "b":186,
      "hex":"#c8baba",
      "file_location":"assets/colors_organized/hue_01/brightness_13/2020040413065100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":13,
      "vividness":3,
      "r":199,
      "g":173,
      "b":172,
      "hex":"#c7adac",
      "file_location":"assets/colors_organized/hue_01/brightness_13/2020040413065200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":13,
      "vividness":4,
      "r":199,
      "g":159,
      "b":159,
      "hex":"#c79f9f",
      "file_location":"assets/colors_organized/hue_01/brightness_13/2020040413065300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":13,
      "vividness":5,
      "r":200,
      "g":146,
      "b":146,
      "hex":"#c89292",
      "file_location":"assets/colors_organized/hue_01/brightness_13/2020040413065400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":13,
      "vividness":6,
      "r":199,
      "g":133,
      "b":134,
      "hex":"#c78586",
      "file_location":"assets/colors_organized/hue_01/brightness_13/2020040413065500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":13,
      "vividness":7,
      "r":199,
      "g":119,
      "b":118,
      "hex":"#c77776",
      "file_location":"assets/colors_organized/hue_01/brightness_13/2020040413065600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":13,
      "vividness":8,
      "r":200,
      "g":106,
      "b":106,
      "hex":"#c86a6a",
      "file_location":"assets/colors_organized/hue_01/brightness_13/2020040413065700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":13,
      "vividness":9,
      "r":199,
      "g":93,
      "b":93,
      "hex":"#c75d5d",
      "file_location":"assets/colors_organized/hue_01/brightness_13/2020040413065800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":13,
      "vividness":10,
      "r":199,
      "g":79,
      "b":80,
      "hex":"#c74f50",
      "file_location":"assets/colors_organized/hue_01/brightness_13/2020040413070000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":13,
      "vividness":11,
      "r":200,
      "g":66,
      "b":65,
      "hex":"#c84241",
      "file_location":"assets/colors_organized/hue_01/brightness_13/2020040413070100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":13,
      "vividness":12,
      "r":198,
      "g":52,
      "b":52,
      "hex":"#c63434",
      "file_location":"assets/colors_organized/hue_01/brightness_13/2020040413070200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":13,
      "vividness":13,
      "r":199,
      "g":39,
      "b":39,
      "hex":"#c72727",
      "file_location":"assets/colors_organized/hue_01/brightness_13/2020040413070300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":13,
      "vividness":14,
      "r":199,
      "g":27,
      "b":27,
      "hex":"#c71b1b",
      "file_location":"assets/colors_organized/hue_01/brightness_13/2020040413070400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":13,
      "vividness":15,
      "r":198,
      "g":13,
      "b":11,
      "hex":"#c60d0b",
      "file_location":"assets/colors_organized/hue_01/brightness_13/2020040413070500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":14,
      "vividness":1,
      "r":214,
      "g":214,
      "b":214,
      "hex":"#d6d6d6",
      "file_location":"assets/colors_organized/hue_01/brightness_14/2020040413062600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":14,
      "vividness":2,
      "r":215,
      "g":201,
      "b":201,
      "hex":"#d7c9c9",
      "file_location":"assets/colors_organized/hue_01/brightness_14/2020040413062700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":14,
      "vividness":3,
      "r":215,
      "g":185,
      "b":185,
      "hex":"#d7b9b9",
      "file_location":"assets/colors_organized/hue_01/brightness_14/2020040413062900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":14,
      "vividness":4,
      "r":215,
      "g":171,
      "b":172,
      "hex":"#d7abac",
      "file_location":"assets/colors_organized/hue_01/brightness_14/2020040413063000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":14,
      "vividness":5,
      "r":215,
      "g":157,
      "b":156,
      "hex":"#d79d9c",
      "file_location":"assets/colors_organized/hue_01/brightness_14/2020040413063100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":14,
      "vividness":6,
      "r":214,
      "g":142,
      "b":143,
      "hex":"#d68e8f",
      "file_location":"assets/colors_organized/hue_01/brightness_14/2020040413063200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":14,
      "vividness":7,
      "r":214,
      "g":128,
      "b":127,
      "hex":"#d6807f",
      "file_location":"assets/colors_organized/hue_01/brightness_14/2020040413063400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":14,
      "vividness":8,
      "r":214,
      "g":114,
      "b":114,
      "hex":"#d67272",
      "file_location":"assets/colors_organized/hue_01/brightness_14/2020040413063500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":14,
      "vividness":9,
      "r":214,
      "g":100,
      "b":100,
      "hex":"#d66464",
      "file_location":"assets/colors_organized/hue_01/brightness_14/2020040413063600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":14,
      "vividness":10,
      "r":215,
      "g":85,
      "b":85,
      "hex":"#d75555",
      "file_location":"assets/colors_organized/hue_01/brightness_14/2020040413063700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":14,
      "vividness":11,
      "r":215,
      "g":71,
      "b":71,
      "hex":"#d74747",
      "file_location":"assets/colors_organized/hue_01/brightness_14/2020040413063800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":14,
      "vividness":12,
      "r":215,
      "g":57,
      "b":56,
      "hex":"#d73938",
      "file_location":"assets/colors_organized/hue_01/brightness_14/2020040413063900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":14,
      "vividness":13,
      "r":214,
      "g":42,
      "b":42,
      "hex":"#d62a2a",
      "file_location":"assets/colors_organized/hue_01/brightness_14/2020040413064000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":14,
      "vividness":14,
      "r":213,
      "g":28,
      "b":26,
      "hex":"#d51c1a",
      "file_location":"assets/colors_organized/hue_01/brightness_14/2020040413064200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":14,
      "vividness":15,
      "r":215,
      "g":13,
      "b":13,
      "hex":"#d70d0d",
      "file_location":"assets/colors_organized/hue_01/brightness_14/2020040413064300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":15,
      "vividness":1,
      "r":229,
      "g":229,
      "b":229,
      "hex":"#e5e5e5",
      "file_location":"assets/colors_organized/hue_01/brightness_15/2020040413055000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":15,
      "vividness":2,
      "r":229,
      "g":215,
      "b":214,
      "hex":"#e5d7d6",
      "file_location":"assets/colors_organized/hue_01/brightness_15/2020040413055200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":15,
      "vividness":3,
      "r":229,
      "g":199,
      "b":199,
      "hex":"#e5c7c7",
      "file_location":"assets/colors_organized/hue_01/brightness_15/2020040413055300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":15,
      "vividness":4,
      "r":229,
      "g":183,
      "b":183,
      "hex":"#e5b7b7",
      "file_location":"assets/colors_organized/hue_01/brightness_15/2020040413055400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":15,
      "vividness":5,
      "r":229,
      "g":167,
      "b":168,
      "hex":"#e5a7a8",
      "file_location":"assets/colors_organized/hue_01/brightness_15/2020040413055500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":15,
      "vividness":6,
      "r":230,
      "g":152,
      "b":152,
      "hex":"#e69898",
      "file_location":"assets/colors_organized/hue_01/brightness_15/2020040413055800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":15,
      "vividness":7,
      "r":229,
      "g":138,
      "b":137,
      "hex":"#e58a89",
      "file_location":"assets/colors_organized/hue_01/brightness_15/2020040413055900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":15,
      "vividness":8,
      "r":230,
      "g":122,
      "b":122,
      "hex":"#e67a7a",
      "file_location":"assets/colors_organized/hue_01/brightness_15/2020040413060000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":15,
      "vividness":9,
      "r":230,
      "g":108,
      "b":107,
      "hex":"#e66c6b",
      "file_location":"assets/colors_organized/hue_01/brightness_15/2020040413060100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":15,
      "vividness":10,
      "r":229,
      "g":91,
      "b":89,
      "hex":"#e55b59",
      "file_location":"assets/colors_organized/hue_01/brightness_15/2020040413060300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":15,
      "vividness":11,
      "r":230,
      "g":76,
      "b":76,
      "hex":"#e64c4c",
      "file_location":"assets/colors_organized/hue_01/brightness_15/2020040413060400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":15,
      "vividness":12,
      "r":229,
      "g":61,
      "b":60,
      "hex":"#e53d3c",
      "file_location":"assets/colors_organized/hue_01/brightness_15/2020040413060600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":15,
      "vividness":13,
      "r":229,
      "g":45,
      "b":45,
      "hex":"#e52d2d",
      "file_location":"assets/colors_organized/hue_01/brightness_15/2020040413060700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":15,
      "vividness":14,
      "r":229,
      "g":29,
      "b":29,
      "hex":"#e51d1d",
      "file_location":"assets/colors_organized/hue_01/brightness_15/2020040413060800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":1,
      "brightness":15,
      "vividness":15,
      "r":229,
      "g":15,
      "b":15,
      "hex":"#e50f0f",
      "file_location":"assets/colors_organized/hue_01/brightness_15/2020040413061000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":1,
      "vividness":1,
      "r":19,
      "g":18,
      "b":14,
      "hex":"#13120e",
      "file_location":"assets/colors_organized/hue_02/brightness_01/2020040414273300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":1,
      "vividness":2,
      "r":20,
      "g":15,
      "b":12,
      "hex":"#140f0c",
      "file_location":"assets/colors_organized/hue_02/brightness_01/2020040414273400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":1,
      "vividness":3,
      "r":20,
      "g":12,
      "b":9,
      "hex":"#140c09",
      "file_location":"assets/colors_organized/hue_02/brightness_01/2020040414273501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":1,
      "vividness":4,
      "r":19,
      "g":9,
      "b":7,
      "hex":"#130907",
      "file_location":"assets/colors_organized/hue_02/brightness_01/2020040414273700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":1,
      "vividness":5,
      "r":20,
      "g":6,
      "b":3,
      "hex":"#140603",
      "file_location":"assets/colors_organized/hue_02/brightness_01/2020040414273801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":1,
      "vividness":6,
      "r":20,
      "g":7,
      "b":0,
      "hex":"#140700",
      "file_location":"assets/colors_organized/hue_02/brightness_01/2020040414273901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":2,
      "vividness":1,
      "r":35,
      "g":34,
      "b":32,
      "hex":"#232220",
      "file_location":"assets/colors_organized/hue_02/brightness_02/2020040414272000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":2,
      "vividness":2,
      "r":35,
      "g":31,
      "b":30,
      "hex":"#231f1e",
      "file_location":"assets/colors_organized/hue_02/brightness_02/2020040414272001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":2,
      "vividness":3,
      "r":35,
      "g":30,
      "b":27,
      "hex":"#231e1b",
      "file_location":"assets/colors_organized/hue_02/brightness_02/2020040414272100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":2,
      "vividness":4,
      "r":35,
      "g":27,
      "b":25,
      "hex":"#231b19",
      "file_location":"assets/colors_organized/hue_02/brightness_02/2020040414272101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":2,
      "vividness":5,
      "r":34,
      "g":24,
      "b":22,
      "hex":"#221816",
      "file_location":"assets/colors_organized/hue_02/brightness_02/2020040414272200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":2,
      "vividness":6,
      "r":34,
      "g":23,
      "b":21,
      "hex":"#221715",
      "file_location":"assets/colors_organized/hue_02/brightness_02/2020040414272300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":2,
      "vividness":7,
      "r":35,
      "g":21,
      "b":18,
      "hex":"#231512",
      "file_location":"assets/colors_organized/hue_02/brightness_02/2020040414272301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":2,
      "vividness":8,
      "r":34,
      "g":19,
      "b":14,
      "hex":"#22130e",
      "file_location":"assets/colors_organized/hue_02/brightness_02/2020040414272400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":2,
      "vividness":9,
      "r":35,
      "g":18,
      "b":11,
      "hex":"#23120b",
      "file_location":"assets/colors_organized/hue_02/brightness_02/2020040414272401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":2,
      "vividness":10,
      "r":34,
      "g":15,
      "b":9,
      "hex":"#220f09",
      "file_location":"assets/colors_organized/hue_02/brightness_02/2020040414272500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":2,
      "vividness":11,
      "r":34,
      "g":13,
      "b":10,
      "hex":"#220d0a",
      "file_location":"assets/colors_organized/hue_02/brightness_02/2020040414272600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":2,
      "vividness":12,
      "r":33,
      "g":13,
      "b":6,
      "hex":"#210d06",
      "file_location":"assets/colors_organized/hue_02/brightness_02/2020040414272601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":2,
      "vividness":13,
      "r":34,
      "g":9,
      "b":2,
      "hex":"#220902",
      "file_location":"assets/colors_organized/hue_02/brightness_02/2020040414272700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":2,
      "vividness":14,
      "r":34,
      "g":6,
      "b":2,
      "hex":"#220602",
      "file_location":"assets/colors_organized/hue_02/brightness_02/2020040414272701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":3,
      "vividness":1,
      "r":48,
      "g":47,
      "b":45,
      "hex":"#302f2d",
      "file_location":"assets/colors_organized/hue_02/brightness_03/2020040414270900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":3,
      "vividness":2,
      "r":49,
      "g":44,
      "b":41,
      "hex":"#312c29",
      "file_location":"assets/colors_organized/hue_02/brightness_03/2020040414270901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":3,
      "vividness":3,
      "r":50,
      "g":42,
      "b":40,
      "hex":"#322a28",
      "file_location":"assets/colors_organized/hue_02/brightness_03/2020040414271000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":3,
      "vividness":4,
      "r":49,
      "g":38,
      "b":36,
      "hex":"#312624",
      "file_location":"assets/colors_organized/hue_02/brightness_03/2020040414271001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":3,
      "vividness":5,
      "r":49,
      "g":35,
      "b":34,
      "hex":"#312322",
      "file_location":"assets/colors_organized/hue_02/brightness_03/2020040414271100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":3,
      "vividness":6,
      "r":49,
      "g":34,
      "b":29,
      "hex":"#31221d",
      "file_location":"assets/colors_organized/hue_02/brightness_03/2020040414271101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":3,
      "vividness":7,
      "r":50,
      "g":31,
      "b":27,
      "hex":"#321f1b",
      "file_location":"assets/colors_organized/hue_02/brightness_03/2020040414271200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":3,
      "vividness":8,
      "r":49,
      "g":28,
      "b":23,
      "hex":"#311c17",
      "file_location":"assets/colors_organized/hue_02/brightness_03/2020040414271201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":3,
      "vividness":9,
      "r":50,
      "g":27,
      "b":19,
      "hex":"#321b13",
      "file_location":"assets/colors_organized/hue_02/brightness_03/2020040414271300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":3,
      "vividness":10,
      "r":50,
      "g":23,
      "b":14,
      "hex":"#32170e",
      "file_location":"assets/colors_organized/hue_02/brightness_03/2020040414271301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":3,
      "vividness":11,
      "r":49,
      "g":20,
      "b":12,
      "hex":"#31140c",
      "file_location":"assets/colors_organized/hue_02/brightness_03/2020040414271400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":3,
      "vividness":12,
      "r":50,
      "g":17,
      "b":8,
      "hex":"#321108",
      "file_location":"assets/colors_organized/hue_02/brightness_03/2020040414271401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":3,
      "vividness":13,
      "r":49,
      "g":15,
      "b":6,
      "hex":"#310f06",
      "file_location":"assets/colors_organized/hue_02/brightness_03/2020040414271500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":3,
      "vividness":14,
      "r":49,
      "g":12,
      "b":3,
      "hex":"#310c03",
      "file_location":"assets/colors_organized/hue_02/brightness_03/2020040414271600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":4,
      "vividness":1,
      "r":65,
      "g":61,
      "b":60,
      "hex":"#413d3c",
      "file_location":"assets/colors_organized/hue_02/brightness_04/2020040414265600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":4,
      "vividness":2,
      "r":65,
      "g":57,
      "b":55,
      "hex":"#413937",
      "file_location":"assets/colors_organized/hue_02/brightness_04/2020040414265601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":4,
      "vividness":3,
      "r":65,
      "g":54,
      "b":52,
      "hex":"#413634",
      "file_location":"assets/colors_organized/hue_02/brightness_04/2020040414265700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":4,
      "vividness":4,
      "r":64,
      "g":51,
      "b":45,
      "hex":"#40332d",
      "file_location":"assets/colors_organized/hue_02/brightness_04/2020040414265701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":4,
      "vividness":5,
      "r":65,
      "g":47,
      "b":43,
      "hex":"#412f2b",
      "file_location":"assets/colors_organized/hue_02/brightness_04/2020040414265800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":4,
      "vividness":6,
      "r":64,
      "g":43,
      "b":38,
      "hex":"#402b26",
      "file_location":"assets/colors_organized/hue_02/brightness_04/2020040414265900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":4,
      "vividness":7,
      "r":64,
      "g":39,
      "b":34,
      "hex":"#402722",
      "file_location":"assets/colors_organized/hue_02/brightness_04/2020040414265901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":4,
      "vividness":8,
      "r":65,
      "g":36,
      "b":30,
      "hex":"#41241e",
      "file_location":"assets/colors_organized/hue_02/brightness_04/2020040414270000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":4,
      "vividness":9,
      "r":64,
      "g":34,
      "b":26,
      "hex":"#40221a",
      "file_location":"assets/colors_organized/hue_02/brightness_04/2020040414270100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":4,
      "vividness":10,
      "r":64,
      "g":30,
      "b":21,
      "hex":"#401e15",
      "file_location":"assets/colors_organized/hue_02/brightness_04/2020040414270101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":4,
      "vividness":11,
      "r":65,
      "g":27,
      "b":18,
      "hex":"#411b12",
      "file_location":"assets/colors_organized/hue_02/brightness_04/2020040414270200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":4,
      "vividness":12,
      "r":65,
      "g":22,
      "b":13,
      "hex":"#41160d",
      "file_location":"assets/colors_organized/hue_02/brightness_04/2020040414270300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":4,
      "vividness":13,
      "r":66,
      "g":20,
      "b":7,
      "hex":"#421407",
      "file_location":"assets/colors_organized/hue_02/brightness_04/2020040414270301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":4,
      "vividness":14,
      "r":64,
      "g":15,
      "b":1,
      "hex":"#400f01",
      "file_location":"assets/colors_organized/hue_02/brightness_04/2020040414270400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":5,
      "vividness":1,
      "r":79,
      "g":75,
      "b":74,
      "hex":"#4f4b4a",
      "file_location":"assets/colors_organized/hue_02/brightness_05/2020040414264400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":5,
      "vividness":2,
      "r":80,
      "g":70,
      "b":68,
      "hex":"#504644",
      "file_location":"assets/colors_organized/hue_02/brightness_05/2020040414264401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":5,
      "vividness":3,
      "r":80,
      "g":66,
      "b":63,
      "hex":"#50423f",
      "file_location":"assets/colors_organized/hue_02/brightness_05/2020040414264500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":5,
      "vividness":4,
      "r":80,
      "g":63,
      "b":56,
      "hex":"#503f38",
      "file_location":"assets/colors_organized/hue_02/brightness_05/2020040414264501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":5,
      "vividness":5,
      "r":79,
      "g":58,
      "b":53,
      "hex":"#4f3a35",
      "file_location":"assets/colors_organized/hue_02/brightness_05/2020040414264600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":5,
      "vividness":6,
      "r":81,
      "g":54,
      "b":47,
      "hex":"#51362f",
      "file_location":"assets/colors_organized/hue_02/brightness_05/2020040414264700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":5,
      "vividness":7,
      "r":80,
      "g":50,
      "b":42,
      "hex":"#50322a",
      "file_location":"assets/colors_organized/hue_02/brightness_05/2020040414264701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":5,
      "vividness":8,
      "r":79,
      "g":45,
      "b":36,
      "hex":"#4f2d24",
      "file_location":"assets/colors_organized/hue_02/brightness_05/2020040414264800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":5,
      "vividness":9,
      "r":79,
      "g":41,
      "b":32,
      "hex":"#4f2920",
      "file_location":"assets/colors_organized/hue_02/brightness_05/2020040414264801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":5,
      "vividness":10,
      "r":79,
      "g":36,
      "b":27,
      "hex":"#4f241b",
      "file_location":"assets/colors_organized/hue_02/brightness_05/2020040414264900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":5,
      "vividness":11,
      "r":80,
      "g":34,
      "b":21,
      "hex":"#502215",
      "file_location":"assets/colors_organized/hue_02/brightness_05/2020040414265000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":5,
      "vividness":12,
      "r":80,
      "g":28,
      "b":15,
      "hex":"#501c0f",
      "file_location":"assets/colors_organized/hue_02/brightness_05/2020040414265001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":5,
      "vividness":13,
      "r":80,
      "g":22,
      "b":10,
      "hex":"#50160a",
      "file_location":"assets/colors_organized/hue_02/brightness_05/2020040414265100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":5,
      "vividness":14,
      "r":79,
      "g":20,
      "b":6,
      "hex":"#4f1406",
      "file_location":"assets/colors_organized/hue_02/brightness_05/2020040414265101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":6,
      "vividness":1,
      "r":95,
      "g":90,
      "b":87,
      "hex":"#5f5a57",
      "file_location":"assets/colors_organized/hue_02/brightness_06/02.jpg"
   },
   {
      "hue":2,
      "brightness":6,
      "vividness":2,
      "r":95,
      "g":84,
      "b":82,
      "hex":"#5f5452",
      "file_location":"assets/colors_organized/hue_02/brightness_06/03.jpg"
   },
   {
      "hue":2,
      "brightness":6,
      "vividness":3,
      "r":94,
      "g":79,
      "b":76,
      "hex":"#5e4f4c",
      "file_location":"assets/colors_organized/hue_02/brightness_06/04.jpg"
   },
   {
      "hue":2,
      "brightness":6,
      "vividness":4,
      "r":95,
      "g":74,
      "b":69,
      "hex":"#5f4a45",
      "file_location":"assets/colors_organized/hue_02/brightness_06/05.jpg"
   },
   {
      "hue":2,
      "brightness":6,
      "vividness":5,
      "r":94,
      "g":69,
      "b":62,
      "hex":"#5e453e",
      "file_location":"assets/colors_organized/hue_02/brightness_06/06.jpg"
   },
   {
      "hue":2,
      "brightness":6,
      "vividness":6,
      "r":94,
      "g":64,
      "b":56,
      "hex":"#5e4038",
      "file_location":"assets/colors_organized/hue_02/brightness_06/07.jpg"
   },
   {
      "hue":2,
      "brightness":6,
      "vividness":7,
      "r":95,
      "g":58,
      "b":49,
      "hex":"#5f3a31",
      "file_location":"assets/colors_organized/hue_02/brightness_06/08.jpg"
   },
   {
      "hue":2,
      "brightness":6,
      "vividness":8,
      "r":94,
      "g":54,
      "b":44,
      "hex":"#5e362c",
      "file_location":"assets/colors_organized/hue_02/brightness_06/09.jpg"
   },
   {
      "hue":2,
      "brightness":6,
      "vividness":9,
      "r":95,
      "g":48,
      "b":38,
      "hex":"#5f3026",
      "file_location":"assets/colors_organized/hue_02/brightness_06/10.jpg"
   },
   {
      "hue":2,
      "brightness":6,
      "vividness":10,
      "r":95,
      "g":43,
      "b":30,
      "hex":"#5f2b1e",
      "file_location":"assets/colors_organized/hue_02/brightness_06/11.jpg"
   },
   {
      "hue":2,
      "brightness":6,
      "vividness":11,
      "r":95,
      "g":39,
      "b":24,
      "hex":"#5f2718",
      "file_location":"assets/colors_organized/hue_02/brightness_06/12.jpg"
   },
   {
      "hue":2,
      "brightness":6,
      "vividness":12,
      "r":95,
      "g":33,
      "b":20,
      "hex":"#5f2114",
      "file_location":"assets/colors_organized/hue_02/brightness_06/2020040414263501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":6,
      "vividness":13,
      "r":95,
      "g":30,
      "b":12,
      "hex":"#5f1e0c",
      "file_location":"assets/colors_organized/hue_02/brightness_06/2020040414263800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":6,
      "vividness":14,
      "r":94,
      "g":22,
      "b":7,
      "hex":"#5e1607",
      "file_location":"assets/colors_organized/hue_02/brightness_06/2020040414263900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":7,
      "vividness":1,
      "r":109,
      "g":104,
      "b":101,
      "hex":"#6d6865",
      "file_location":"assets/colors_organized/hue_02/brightness_07/2020040414262001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":7,
      "vividness":2,
      "r":109,
      "g":98,
      "b":94,
      "hex":"#6d625e",
      "file_location":"assets/colors_organized/hue_02/brightness_07/2020040414262100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":7,
      "vividness":3,
      "r":110,
      "g":92,
      "b":88,
      "hex":"#6e5c58",
      "file_location":"assets/colors_organized/hue_02/brightness_07/2020040414262101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":7,
      "vividness":4,
      "r":109,
      "g":86,
      "b":80,
      "hex":"#6d5650",
      "file_location":"assets/colors_organized/hue_02/brightness_07/2020040414262200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":7,
      "vividness":5,
      "r":109,
      "g":80,
      "b":72,
      "hex":"#6d5048",
      "file_location":"assets/colors_organized/hue_02/brightness_07/2020040414262201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":7,
      "vividness":6,
      "r":111,
      "g":74,
      "b":66,
      "hex":"#6f4a42",
      "file_location":"assets/colors_organized/hue_02/brightness_07/2020040414262300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":7,
      "vividness":7,
      "r":110,
      "g":67,
      "b":58,
      "hex":"#6e433a",
      "file_location":"assets/colors_organized/hue_02/brightness_07/2020040414262301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":7,
      "vividness":8,
      "r":110,
      "g":62,
      "b":50,
      "hex":"#6e3e32",
      "file_location":"assets/colors_organized/hue_02/brightness_07/2020040414262400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":7,
      "vividness":9,
      "r":110,
      "g":57,
      "b":43,
      "hex":"#6e392b",
      "file_location":"assets/colors_organized/hue_02/brightness_07/2020040414262401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":7,
      "vividness":10,
      "r":110,
      "g":51,
      "b":35,
      "hex":"#6e3323",
      "file_location":"assets/colors_organized/hue_02/brightness_07/2020040414262500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":7,
      "vividness":11,
      "r":110,
      "g":44,
      "b":28,
      "hex":"#6e2c1c",
      "file_location":"assets/colors_organized/hue_02/brightness_07/2020040414262501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":7,
      "vividness":12,
      "r":110,
      "g":39,
      "b":21,
      "hex":"#6e2715",
      "file_location":"assets/colors_organized/hue_02/brightness_07/2020040414262600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":7,
      "vividness":13,
      "r":110,
      "g":34,
      "b":11,
      "hex":"#6e220b",
      "file_location":"assets/colors_organized/hue_02/brightness_07/2020040414262601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":7,
      "vividness":14,
      "r":109,
      "g":27,
      "b":6,
      "hex":"#6d1b06",
      "file_location":"assets/colors_organized/hue_02/brightness_07/2020040414262602-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":8,
      "vividness":1,
      "r":123,
      "g":118,
      "b":115,
      "hex":"#7b7673",
      "file_location":"assets/colors_organized/hue_02/brightness_08/2020040414260801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":8,
      "vividness":2,
      "r":124,
      "g":110,
      "b":107,
      "hex":"#7c6e6b",
      "file_location":"assets/colors_organized/hue_02/brightness_08/2020040414260900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":8,
      "vividness":3,
      "r":123,
      "g":104,
      "b":98,
      "hex":"#7b6862",
      "file_location":"assets/colors_organized/hue_02/brightness_08/2020040414260901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":8,
      "vividness":4,
      "r":123,
      "g":98,
      "b":91,
      "hex":"#7b625b",
      "file_location":"assets/colors_organized/hue_02/brightness_08/2020040414260902-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":8,
      "vividness":5,
      "r":124,
      "g":91,
      "b":82,
      "hex":"#7c5b52",
      "file_location":"assets/colors_organized/hue_02/brightness_08/2020040414261000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":8,
      "vividness":6,
      "r":124,
      "g":84,
      "b":74,
      "hex":"#7c544a",
      "file_location":"assets/colors_organized/hue_02/brightness_08/2020040414261001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":8,
      "vividness":7,
      "r":124,
      "g":78,
      "b":65,
      "hex":"#7c4e41",
      "file_location":"assets/colors_organized/hue_02/brightness_08/2020040414261100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":8,
      "vividness":8,
      "r":123,
      "g":71,
      "b":57,
      "hex":"#7b4739",
      "file_location":"assets/colors_organized/hue_02/brightness_08/2020040414261101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":8,
      "vividness":9,
      "r":123,
      "g":64,
      "b":48,
      "hex":"#7b4030",
      "file_location":"assets/colors_organized/hue_02/brightness_08/2020040414261200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":8,
      "vividness":10,
      "r":123,
      "g":58,
      "b":40,
      "hex":"#7b3a28",
      "file_location":"assets/colors_organized/hue_02/brightness_08/2020040414261201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":8,
      "vividness":11,
      "r":124,
      "g":51,
      "b":34,
      "hex":"#7c3322",
      "file_location":"assets/colors_organized/hue_02/brightness_08/2020040414261300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":8,
      "vividness":12,
      "r":124,
      "g":43,
      "b":22,
      "hex":"#7c2b16",
      "file_location":"assets/colors_organized/hue_02/brightness_08/2020040414261301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":8,
      "vividness":13,
      "r":124,
      "g":38,
      "b":15,
      "hex":"#7c260f",
      "file_location":"assets/colors_organized/hue_02/brightness_08/2020040414261400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":8,
      "vividness":14,
      "r":125,
      "g":31,
      "b":6,
      "hex":"#7d1f06",
      "file_location":"assets/colors_organized/hue_02/brightness_08/2020040414261401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":9,
      "vividness":1,
      "r":140,
      "g":132,
      "b":130,
      "hex":"#8c8482",
      "file_location":"assets/colors_organized/hue_02/brightness_09/2020040414255700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":9,
      "vividness":2,
      "r":139,
      "g":124,
      "b":121,
      "hex":"#8b7c79",
      "file_location":"assets/colors_organized/hue_02/brightness_09/2020040414255701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":9,
      "vividness":3,
      "r":138,
      "g":118,
      "b":111,
      "hex":"#8a766f",
      "file_location":"assets/colors_organized/hue_02/brightness_09/2020040414255800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":9,
      "vividness":4,
      "r":139,
      "g":110,
      "b":102,
      "hex":"#8b6e66",
      "file_location":"assets/colors_organized/hue_02/brightness_09/2020040414255801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":9,
      "vividness":5,
      "r":139,
      "g":102,
      "b":93,
      "hex":"#8b665d",
      "file_location":"assets/colors_organized/hue_02/brightness_09/2020040414255900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":9,
      "vividness":6,
      "r":139,
      "g":95,
      "b":82,
      "hex":"#8b5f52",
      "file_location":"assets/colors_organized/hue_02/brightness_09/2020040414255901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":9,
      "vividness":7,
      "r":139,
      "g":87,
      "b":74,
      "hex":"#8b574a",
      "file_location":"assets/colors_organized/hue_02/brightness_09/2020040414260000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":9,
      "vividness":8,
      "r":139,
      "g":80,
      "b":64,
      "hex":"#8b5040",
      "file_location":"assets/colors_organized/hue_02/brightness_09/2020040414260001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":9,
      "vividness":9,
      "r":139,
      "g":72,
      "b":55,
      "hex":"#8b4837",
      "file_location":"assets/colors_organized/hue_02/brightness_09/2020040414260100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":9,
      "vividness":10,
      "r":140,
      "g":65,
      "b":46,
      "hex":"#8c412e",
      "file_location":"assets/colors_organized/hue_02/brightness_09/2020040414260101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":9,
      "vividness":11,
      "r":139,
      "g":57,
      "b":36,
      "hex":"#8b3924",
      "file_location":"assets/colors_organized/hue_02/brightness_09/2020040414260200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":9,
      "vividness":12,
      "r":140,
      "g":50,
      "b":26,
      "hex":"#8c321a",
      "file_location":"assets/colors_organized/hue_02/brightness_09/2020040414260300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":9,
      "vividness":13,
      "r":140,
      "g":42,
      "b":17,
      "hex":"#8c2a11",
      "file_location":"assets/colors_organized/hue_02/brightness_09/2020040414260301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":9,
      "vividness":14,
      "r":139,
      "g":35,
      "b":10,
      "hex":"#8b230a",
      "file_location":"assets/colors_organized/hue_02/brightness_09/2020040414260400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":10,
      "vividness":1,
      "r":154,
      "g":146,
      "b":144,
      "hex":"#9a9290",
      "file_location":"assets/colors_organized/hue_02/brightness_10/2020040414254500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":10,
      "vividness":2,
      "r":153,
      "g":138,
      "b":133,
      "hex":"#998a85",
      "file_location":"assets/colors_organized/hue_02/brightness_10/2020040414254501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":10,
      "vividness":3,
      "r":153,
      "g":130,
      "b":122,
      "hex":"#99827a",
      "file_location":"assets/colors_organized/hue_02/brightness_10/2020040414254600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":10,
      "vividness":4,
      "r":154,
      "g":121,
      "b":112,
      "hex":"#9a7970",
      "file_location":"assets/colors_organized/hue_02/brightness_10/2020040414254700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":10,
      "vividness":5,
      "r":153,
      "g":113,
      "b":103,
      "hex":"#997167",
      "file_location":"assets/colors_organized/hue_02/brightness_10/2020040414254701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":10,
      "vividness":6,
      "r":154,
      "g":105,
      "b":91,
      "hex":"#9a695b",
      "file_location":"assets/colors_organized/hue_02/brightness_10/2020040414254800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":10,
      "vividness":7,
      "r":154,
      "g":96,
      "b":82,
      "hex":"#9a6052",
      "file_location":"assets/colors_organized/hue_02/brightness_10/2020040414254801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":10,
      "vividness":8,
      "r":154,
      "g":88,
      "b":72,
      "hex":"#9a5848",
      "file_location":"assets/colors_organized/hue_02/brightness_10/2020040414254900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":10,
      "vividness":9,
      "r":155,
      "g":80,
      "b":61,
      "hex":"#9b503d",
      "file_location":"assets/colors_organized/hue_02/brightness_10/2020040414254901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":10,
      "vividness":10,
      "r":154,
      "g":72,
      "b":50,
      "hex":"#9a4832",
      "file_location":"assets/colors_organized/hue_02/brightness_10/2020040414255000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":10,
      "vividness":11,
      "r":153,
      "g":63,
      "b":39,
      "hex":"#993f27",
      "file_location":"assets/colors_organized/hue_02/brightness_10/2020040414255001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":10,
      "vividness":12,
      "r":154,
      "g":54,
      "b":30,
      "hex":"#9a361e",
      "file_location":"assets/colors_organized/hue_02/brightness_10/2020040414255100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":10,
      "vividness":13,
      "r":155,
      "g":47,
      "b":19,
      "hex":"#9b2f13",
      "file_location":"assets/colors_organized/hue_02/brightness_10/2020040414255101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":10,
      "vividness":14,
      "r":154,
      "g":39,
      "b":10,
      "hex":"#9a270a",
      "file_location":"assets/colors_organized/hue_02/brightness_10/2020040414255200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":11,
      "vividness":1,
      "r":170,
      "g":160,
      "b":158,
      "hex":"#aaa09e",
      "file_location":"assets/colors_organized/hue_02/brightness_11/2020040414253001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":11,
      "vividness":2,
      "r":169,
      "g":151,
      "b":147,
      "hex":"#a99793",
      "file_location":"assets/colors_organized/hue_02/brightness_11/2020040414253100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":11,
      "vividness":3,
      "r":169,
      "g":142,
      "b":135,
      "hex":"#a98e87",
      "file_location":"assets/colors_organized/hue_02/brightness_11/2020040414253101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":11,
      "vividness":4,
      "r":170,
      "g":133,
      "b":124,
      "hex":"#aa857c",
      "file_location":"assets/colors_organized/hue_02/brightness_11/2020040414253200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":11,
      "vividness":5,
      "r":168,
      "g":124,
      "b":113,
      "hex":"#a87c71",
      "file_location":"assets/colors_organized/hue_02/brightness_11/2020040414253300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":11,
      "vividness":6,
      "r":168,
      "g":115,
      "b":101,
      "hex":"#a87365",
      "file_location":"assets/colors_organized/hue_02/brightness_11/2020040414253301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":11,
      "vividness":7,
      "r":169,
      "g":106,
      "b":89,
      "hex":"#a96a59",
      "file_location":"assets/colors_organized/hue_02/brightness_11/2020040414253400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":11,
      "vividness":8,
      "r":168,
      "g":97,
      "b":79,
      "hex":"#a8614f",
      "file_location":"assets/colors_organized/hue_02/brightness_11/2020040414253401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":11,
      "vividness":9,
      "r":169,
      "g":88,
      "b":67,
      "hex":"#a95843",
      "file_location":"assets/colors_organized/hue_02/brightness_11/2020040414253500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":11,
      "vividness":10,
      "r":169,
      "g":79,
      "b":55,
      "hex":"#a94f37",
      "file_location":"assets/colors_organized/hue_02/brightness_11/2020040414253501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":11,
      "vividness":11,
      "r":170,
      "g":70,
      "b":44,
      "hex":"#aa462c",
      "file_location":"assets/colors_organized/hue_02/brightness_11/2020040414253600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":11,
      "vividness":12,
      "r":169,
      "g":61,
      "b":33,
      "hex":"#a93d21",
      "file_location":"assets/colors_organized/hue_02/brightness_11/2020040414253700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":11,
      "vividness":13,
      "r":169,
      "g":51,
      "b":21,
      "hex":"#a93315",
      "file_location":"assets/colors_organized/hue_02/brightness_11/2020040414253701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":11,
      "vividness":14,
      "r":170,
      "g":43,
      "b":10,
      "hex":"#aa2b0a",
      "file_location":"assets/colors_organized/hue_02/brightness_11/2020040414253800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":12,
      "vividness":1,
      "r":184,
      "g":174,
      "b":172,
      "hex":"#b8aeac",
      "file_location":"assets/colors_organized/hue_02/brightness_12/2020040414251900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":12,
      "vividness":2,
      "r":184,
      "g":165,
      "b":161,
      "hex":"#b8a5a1",
      "file_location":"assets/colors_organized/hue_02/brightness_12/2020040414251901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":12,
      "vividness":3,
      "r":184,
      "g":155,
      "b":147,
      "hex":"#b89b93",
      "file_location":"assets/colors_organized/hue_02/brightness_12/2020040414252000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":12,
      "vividness":4,
      "r":184,
      "g":146,
      "b":135,
      "hex":"#b89287",
      "file_location":"assets/colors_organized/hue_02/brightness_12/2020040414252001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":12,
      "vividness":5,
      "r":183,
      "g":135,
      "b":121,
      "hex":"#b78779",
      "file_location":"assets/colors_organized/hue_02/brightness_12/2020040414252100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":12,
      "vividness":6,
      "r":184,
      "g":125,
      "b":109,
      "hex":"#b87d6d",
      "file_location":"assets/colors_organized/hue_02/brightness_12/2020040414252101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":12,
      "vividness":7,
      "r":184,
      "g":115,
      "b":99,
      "hex":"#b87363",
      "file_location":"assets/colors_organized/hue_02/brightness_12/2020040414252200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":12,
      "vividness":8,
      "r":183,
      "g":105,
      "b":85,
      "hex":"#b76955",
      "file_location":"assets/colors_organized/hue_02/brightness_12/2020040414252201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":12,
      "vividness":9,
      "r":184,
      "g":96,
      "b":72,
      "hex":"#b86048",
      "file_location":"assets/colors_organized/hue_02/brightness_12/2020040414252300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":12,
      "vividness":10,
      "r":184,
      "g":86,
      "b":61,
      "hex":"#b8563d",
      "file_location":"assets/colors_organized/hue_02/brightness_12/2020040414252301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":12,
      "vividness":11,
      "r":184,
      "g":76,
      "b":48,
      "hex":"#b84c30",
      "file_location":"assets/colors_organized/hue_02/brightness_12/2020040414252400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":12,
      "vividness":12,
      "r":185,
      "g":66,
      "b":36,
      "hex":"#b94224",
      "file_location":"assets/colors_organized/hue_02/brightness_12/2020040414252401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":12,
      "vividness":13,
      "r":184,
      "g":55,
      "b":23,
      "hex":"#b83717",
      "file_location":"assets/colors_organized/hue_02/brightness_12/2020040414252500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":12,
      "vividness":14,
      "r":184,
      "g":45,
      "b":12,
      "hex":"#b82d0c",
      "file_location":"assets/colors_organized/hue_02/brightness_12/2020040414252501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":13,
      "vividness":1,
      "r":199,
      "g":188,
      "b":186,
      "hex":"#c7bcba",
      "file_location":"assets/colors_organized/hue_02/brightness_13/2020040414250700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":13,
      "vividness":2,
      "r":199,
      "g":178,
      "b":173,
      "hex":"#c7b2ad",
      "file_location":"assets/colors_organized/hue_02/brightness_13/2020040414250701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":13,
      "vividness":3,
      "r":200,
      "g":167,
      "b":158,
      "hex":"#c8a79e",
      "file_location":"assets/colors_organized/hue_02/brightness_13/2020040414250800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":13,
      "vividness":4,
      "r":200,
      "g":158,
      "b":146,
      "hex":"#c89e92",
      "file_location":"assets/colors_organized/hue_02/brightness_13/2020040414250801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":13,
      "vividness":5,
      "r":200,
      "g":147,
      "b":133,
      "hex":"#c89385",
      "file_location":"assets/colors_organized/hue_02/brightness_13/2020040414250900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":13,
      "vividness":6,
      "r":199,
      "g":136,
      "b":119,
      "hex":"#c78877",
      "file_location":"assets/colors_organized/hue_02/brightness_13/2020040414250901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":13,
      "vividness":7,
      "r":200,
      "g":125,
      "b":106,
      "hex":"#c87d6a",
      "file_location":"assets/colors_organized/hue_02/brightness_13/2020040414251000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":13,
      "vividness":8,
      "r":199,
      "g":114,
      "b":93,
      "hex":"#c7725d",
      "file_location":"assets/colors_organized/hue_02/brightness_13/2020040414251001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":13,
      "vividness":9,
      "r":199,
      "g":103,
      "b":79,
      "hex":"#c7674f",
      "file_location":"assets/colors_organized/hue_02/brightness_13/2020040414251100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":13,
      "vividness":10,
      "r":199,
      "g":94,
      "b":65,
      "hex":"#c75e41",
      "file_location":"assets/colors_organized/hue_02/brightness_13/2020040414251200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":13,
      "vividness":11,
      "r":200,
      "g":82,
      "b":52,
      "hex":"#c85234",
      "file_location":"assets/colors_organized/hue_02/brightness_13/2020040414251201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":13,
      "vividness":12,
      "r":200,
      "g":71,
      "b":39,
      "hex":"#c84727",
      "file_location":"assets/colors_organized/hue_02/brightness_13/2020040414251300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":13,
      "vividness":13,
      "r":199,
      "g":61,
      "b":25,
      "hex":"#c73d19",
      "file_location":"assets/colors_organized/hue_02/brightness_13/2020040414251301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":13,
      "vividness":14,
      "r":199,
      "g":49,
      "b":12,
      "hex":"#c7310c",
      "file_location":"assets/colors_organized/hue_02/brightness_13/2020040414251400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":14,
      "vividness":1,
      "r":214,
      "g":203,
      "b":201,
      "hex":"#d6cbc9",
      "file_location":"assets/colors_organized/hue_02/brightness_14/2020040414245401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":14,
      "vividness":2,
      "r":214,
      "g":191,
      "b":185,
      "hex":"#d6bfb9",
      "file_location":"assets/colors_organized/hue_02/brightness_14/2020040414245500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":14,
      "vividness":3,
      "r":214,
      "g":180,
      "b":171,
      "hex":"#d6b4ab",
      "file_location":"assets/colors_organized/hue_02/brightness_14/2020040414245501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":14,
      "vividness":4,
      "r":215,
      "g":168,
      "b":158,
      "hex":"#d7a89e",
      "file_location":"assets/colors_organized/hue_02/brightness_14/2020040414245600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":14,
      "vividness":5,
      "r":215,
      "g":157,
      "b":143,
      "hex":"#d79d8f",
      "file_location":"assets/colors_organized/hue_02/brightness_14/2020040414245601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":14,
      "vividness":6,
      "r":214,
      "g":145,
      "b":129,
      "hex":"#d69181",
      "file_location":"assets/colors_organized/hue_02/brightness_14/2020040414245700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":14,
      "vividness":7,
      "r":215,
      "g":134,
      "b":113,
      "hex":"#d78671",
      "file_location":"assets/colors_organized/hue_02/brightness_14/2020040414245701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":14,
      "vividness":8,
      "r":215,
      "g":123,
      "b":100,
      "hex":"#d77b64",
      "file_location":"assets/colors_organized/hue_02/brightness_14/2020040414245800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":14,
      "vividness":9,
      "r":215,
      "g":111,
      "b":84,
      "hex":"#d76f54",
      "file_location":"assets/colors_organized/hue_02/brightness_14/2020040414245801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":14,
      "vividness":10,
      "r":215,
      "g":100,
      "b":71,
      "hex":"#d76447",
      "file_location":"assets/colors_organized/hue_02/brightness_14/2020040414245900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":14,
      "vividness":11,
      "r":215,
      "g":88,
      "b":56,
      "hex":"#d75838",
      "file_location":"assets/colors_organized/hue_02/brightness_14/2020040414245901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":14,
      "vividness":12,
      "r":215,
      "g":77,
      "b":41,
      "hex":"#d74d29",
      "file_location":"assets/colors_organized/hue_02/brightness_14/2020040414250000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":14,
      "vividness":13,
      "r":214,
      "g":64,
      "b":27,
      "hex":"#d6401b",
      "file_location":"assets/colors_organized/hue_02/brightness_14/2020040414250001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":14,
      "vividness":14,
      "r":215,
      "g":54,
      "b":12,
      "hex":"#d7360c",
      "file_location":"assets/colors_organized/hue_02/brightness_14/2020040414250100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":15,
      "vividness":1,
      "r":229,
      "g":218,
      "b":214,
      "hex":"#e5dad6",
      "file_location":"assets/colors_organized/hue_02/brightness_15/2020040414243800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":15,
      "vividness":2,
      "r":229,
      "g":206,
      "b":198,
      "hex":"#e5cec6",
      "file_location":"assets/colors_organized/hue_02/brightness_15/2020040414243801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":15,
      "vividness":3,
      "r":229,
      "g":192,
      "b":183,
      "hex":"#e5c0b7",
      "file_location":"assets/colors_organized/hue_02/brightness_15/2020040414243900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":15,
      "vividness":4,
      "r":230,
      "g":180,
      "b":169,
      "hex":"#e6b4a9",
      "file_location":"assets/colors_organized/hue_02/brightness_15/2020040414244000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":15,
      "vividness":5,
      "r":230,
      "g":168,
      "b":153,
      "hex":"#e6a899",
      "file_location":"assets/colors_organized/hue_02/brightness_15/2020040414244100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":15,
      "vividness":6,
      "r":229,
      "g":156,
      "b":137,
      "hex":"#e59c89",
      "file_location":"assets/colors_organized/hue_02/brightness_15/2020040414244101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":15,
      "vividness":7,
      "r":230,
      "g":143,
      "b":123,
      "hex":"#e68f7b",
      "file_location":"assets/colors_organized/hue_02/brightness_15/2020040414244200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":15,
      "vividness":8,
      "r":230,
      "g":132,
      "b":107,
      "hex":"#e6846b",
      "file_location":"assets/colors_organized/hue_02/brightness_15/2020040414244300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":15,
      "vividness":9,
      "r":229,
      "g":120,
      "b":91,
      "hex":"#e5785b",
      "file_location":"assets/colors_organized/hue_02/brightness_15/2020040414244301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":15,
      "vividness":10,
      "r":230,
      "g":107,
      "b":76,
      "hex":"#e66b4c",
      "file_location":"assets/colors_organized/hue_02/brightness_15/2020040414244400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":15,
      "vividness":11,
      "r":229,
      "g":95,
      "b":60,
      "hex":"#e55f3c",
      "file_location":"assets/colors_organized/hue_02/brightness_15/2020040414244401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":15,
      "vividness":12,
      "r":230,
      "g":82,
      "b":44,
      "hex":"#e6522c",
      "file_location":"assets/colors_organized/hue_02/brightness_15/2020040414244500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":15,
      "vividness":13,
      "r":229,
      "g":70,
      "b":30,
      "hex":"#e5461e",
      "file_location":"assets/colors_organized/hue_02/brightness_15/2020040414244600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":2,
      "brightness":15,
      "vividness":14,
      "r":229,
      "g":58,
      "b":14,
      "hex":"#e53a0e",
      "file_location":"assets/colors_organized/hue_02/brightness_15/2020040414244601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":1,
      "vividness":1,
      "r":19,
      "g":18,
      "b":13,
      "hex":"#13120d",
      "file_location":"assets/colors_organized/hue_03/brightness_01/2020040414310201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":1,
      "vividness":2,
      "r":20,
      "g":13,
      "b":7,
      "hex":"#140d07",
      "file_location":"assets/colors_organized/hue_03/brightness_01/2020040414310501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":1,
      "vividness":3,
      "r":19,
      "g":10,
      "b":3,
      "hex":"#130a03",
      "file_location":"assets/colors_organized/hue_03/brightness_01/2020040414310700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":2,
      "vividness":1,
      "r":35,
      "g":31,
      "b":28,
      "hex":"#231f1c",
      "file_location":"assets/colors_organized/hue_03/brightness_02/2020040414304700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":2,
      "vividness":2,
      "r":34,
      "g":30,
      "b":27,
      "hex":"#221e1b",
      "file_location":"assets/colors_organized/hue_03/brightness_02/2020040414304800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":2,
      "vividness":3,
      "r":34,
      "g":29,
      "b":25,
      "hex":"#221d19",
      "file_location":"assets/colors_organized/hue_03/brightness_02/2020040414304900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":2,
      "vividness":4,
      "r":35,
      "g":28,
      "b":22,
      "hex":"#231c16",
      "file_location":"assets/colors_organized/hue_03/brightness_02/2020040414304901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":2,
      "vividness":5,
      "r":34,
      "g":27,
      "b":21,
      "hex":"#221b15",
      "file_location":"assets/colors_organized/hue_03/brightness_02/2020040414305000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":2,
      "vividness":6,
      "r":34,
      "g":25,
      "b":18,
      "hex":"#221912",
      "file_location":"assets/colors_organized/hue_03/brightness_02/2020040414305100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":2,
      "vividness":7,
      "r":35,
      "g":22,
      "b":14,
      "hex":"#23160e",
      "file_location":"assets/colors_organized/hue_03/brightness_02/2020040414305200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":2,
      "vividness":8,
      "r":34,
      "g":21,
      "b":12,
      "hex":"#22150c",
      "file_location":"assets/colors_organized/hue_03/brightness_02/2020040414305201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":2,
      "vividness":9,
      "r":34,
      "g":22,
      "b":10,
      "hex":"#22160a",
      "file_location":"assets/colors_organized/hue_03/brightness_02/2020040414305300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":2,
      "vividness":10,
      "r":34,
      "g":20,
      "b":9,
      "hex":"#221409",
      "file_location":"assets/colors_organized/hue_03/brightness_02/2020040414305400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":2,
      "vividness":11,
      "r":34,
      "g":18,
      "b":5,
      "hex":"#221205",
      "file_location":"assets/colors_organized/hue_03/brightness_02/2020040414305401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":2,
      "vividness":12,
      "r":34,
      "g":15,
      "b":1,
      "hex":"#220f01",
      "file_location":"assets/colors_organized/hue_03/brightness_02/2020040414305500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":3,
      "vividness":1,
      "r":49,
      "g":48,
      "b":46,
      "hex":"#31302e",
      "file_location":"assets/colors_organized/hue_03/brightness_03/2020040414303301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":3,
      "vividness":2,
      "r":49,
      "g":45,
      "b":42,
      "hex":"#312d2a",
      "file_location":"assets/colors_organized/hue_03/brightness_03/2020040414303400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":3,
      "vividness":3,
      "r":49,
      "g":44,
      "b":40,
      "hex":"#312c28",
      "file_location":"assets/colors_organized/hue_03/brightness_03/2020040414303500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":3,
      "vividness":4,
      "r":49,
      "g":42,
      "b":36,
      "hex":"#312a24",
      "file_location":"assets/colors_organized/hue_03/brightness_03/2020040414303501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":3,
      "vividness":5,
      "r":49,
      "g":40,
      "b":33,
      "hex":"#312821",
      "file_location":"assets/colors_organized/hue_03/brightness_03/2020040414303600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":3,
      "vividness":6,
      "r":50,
      "g":37,
      "b":29,
      "hex":"#32251d",
      "file_location":"assets/colors_organized/hue_03/brightness_03/2020040414303700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":3,
      "vividness":7,
      "r":50,
      "g":36,
      "b":27,
      "hex":"#32241b",
      "file_location":"assets/colors_organized/hue_03/brightness_03/2020040414303701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":3,
      "vividness":8,
      "r":50,
      "g":33,
      "b":23,
      "hex":"#322117",
      "file_location":"assets/colors_organized/hue_03/brightness_03/2020040414303800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":3,
      "vividness":9,
      "r":50,
      "g":32,
      "b":20,
      "hex":"#322014",
      "file_location":"assets/colors_organized/hue_03/brightness_03/2020040414303900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":3,
      "vividness":10,
      "r":49,
      "g":30,
      "b":15,
      "hex":"#311e0f",
      "file_location":"assets/colors_organized/hue_03/brightness_03/2020040414303901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":3,
      "vividness":11,
      "r":49,
      "g":27,
      "b":13,
      "hex":"#311b0d",
      "file_location":"assets/colors_organized/hue_03/brightness_03/2020040414304000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":3,
      "vividness":12,
      "r":49,
      "g":26,
      "b":8,
      "hex":"#311a08",
      "file_location":"assets/colors_organized/hue_03/brightness_03/2020040414304100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":3,
      "vividness":13,
      "r":50,
      "g":23,
      "b":6,
      "hex":"#321706",
      "file_location":"assets/colors_organized/hue_03/brightness_03/2020040414304101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":3,
      "vividness":14,
      "r":49,
      "g":22,
      "b":3,
      "hex":"#311603",
      "file_location":"assets/colors_organized/hue_03/brightness_03/2020040414304200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":4,
      "vividness":1,
      "r":65,
      "g":61,
      "b":58,
      "hex":"#413d3a",
      "file_location":"assets/colors_organized/hue_03/brightness_04/2020040414302101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":4,
      "vividness":2,
      "r":64,
      "g":59,
      "b":55,
      "hex":"#403b37",
      "file_location":"assets/colors_organized/hue_03/brightness_04/2020040414302200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":4,
      "vividness":3,
      "r":64,
      "g":57,
      "b":51,
      "hex":"#403933",
      "file_location":"assets/colors_organized/hue_03/brightness_04/2020040414302201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":4,
      "vividness":4,
      "r":64,
      "g":54,
      "b":45,
      "hex":"#40362d",
      "file_location":"assets/colors_organized/hue_03/brightness_04/2020040414302300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":4,
      "vividness":5,
      "r":64,
      "g":51,
      "b":42,
      "hex":"#40332a",
      "file_location":"assets/colors_organized/hue_03/brightness_04/2020040414302400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":4,
      "vividness":6,
      "r":65,
      "g":48,
      "b":38,
      "hex":"#413026",
      "file_location":"assets/colors_organized/hue_03/brightness_04/2020040414302401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":4,
      "vividness":7,
      "r":64,
      "g":46,
      "b":34,
      "hex":"#402e22",
      "file_location":"assets/colors_organized/hue_03/brightness_04/2020040414302500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":4,
      "vividness":8,
      "r":65,
      "g":43,
      "b":29,
      "hex":"#412b1d",
      "file_location":"assets/colors_organized/hue_03/brightness_04/2020040414302501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":4,
      "vividness":9,
      "r":64,
      "g":41,
      "b":27,
      "hex":"#40291b",
      "file_location":"assets/colors_organized/hue_03/brightness_04/2020040414302600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":4,
      "vividness":10,
      "r":64,
      "g":38,
      "b":21,
      "hex":"#402615",
      "file_location":"assets/colors_organized/hue_03/brightness_04/2020040414302700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":4,
      "vividness":11,
      "r":64,
      "g":35,
      "b":17,
      "hex":"#402311",
      "file_location":"assets/colors_organized/hue_03/brightness_04/2020040414302701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":4,
      "vividness":12,
      "r":64,
      "g":33,
      "b":12,
      "hex":"#40210c",
      "file_location":"assets/colors_organized/hue_03/brightness_04/2020040414302800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":4,
      "vividness":13,
      "r":65,
      "g":31,
      "b":6,
      "hex":"#411f06",
      "file_location":"assets/colors_organized/hue_03/brightness_04/2020040414302801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":4,
      "vividness":14,
      "r":65,
      "g":28,
      "b":2,
      "hex":"#411c02",
      "file_location":"assets/colors_organized/hue_03/brightness_04/2020040414302900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":5,
      "vividness":1,
      "r":80,
      "g":76,
      "b":73,
      "hex":"#504c49",
      "file_location":"assets/colors_organized/hue_03/brightness_05/2020040414300901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":5,
      "vividness":2,
      "r":80,
      "g":72,
      "b":69,
      "hex":"#504845",
      "file_location":"assets/colors_organized/hue_03/brightness_05/2020040414301000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":5,
      "vividness":3,
      "r":79,
      "g":70,
      "b":63,
      "hex":"#4f463f",
      "file_location":"assets/colors_organized/hue_03/brightness_05/2020040414301100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":5,
      "vividness":4,
      "r":80,
      "g":67,
      "b":58,
      "hex":"#50433a",
      "file_location":"assets/colors_organized/hue_03/brightness_05/2020040414301101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":5,
      "vividness":5,
      "r":80,
      "g":63,
      "b":53,
      "hex":"#503f35",
      "file_location":"assets/colors_organized/hue_03/brightness_05/2020040414301200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":5,
      "vividness":6,
      "r":79,
      "g":60,
      "b":46,
      "hex":"#4f3c2e",
      "file_location":"assets/colors_organized/hue_03/brightness_05/2020040414301201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":5,
      "vividness":7,
      "r":80,
      "g":57,
      "b":41,
      "hex":"#503929",
      "file_location":"assets/colors_organized/hue_03/brightness_05/2020040414301300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":5,
      "vividness":8,
      "r":79,
      "g":53,
      "b":36,
      "hex":"#4f3524",
      "file_location":"assets/colors_organized/hue_03/brightness_05/2020040414301400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":5,
      "vividness":9,
      "r":79,
      "g":51,
      "b":30,
      "hex":"#4f331e",
      "file_location":"assets/colors_organized/hue_03/brightness_05/2020040414301401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":5,
      "vividness":10,
      "r":80,
      "g":48,
      "b":27,
      "hex":"#50301b",
      "file_location":"assets/colors_organized/hue_03/brightness_05/2020040414301500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":5,
      "vividness":11,
      "r":80,
      "g":44,
      "b":22,
      "hex":"#502c16",
      "file_location":"assets/colors_organized/hue_03/brightness_05/2020040414301501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":5,
      "vividness":12,
      "r":80,
      "g":40,
      "b":14,
      "hex":"#50280e",
      "file_location":"assets/colors_organized/hue_03/brightness_05/2020040414301600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":5,
      "vividness":13,
      "r":79,
      "g":38,
      "b":10,
      "hex":"#4f260a",
      "file_location":"assets/colors_organized/hue_03/brightness_05/2020040414301700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":5,
      "vividness":14,
      "r":80,
      "g":35,
      "b":6,
      "hex":"#502306",
      "file_location":"assets/colors_organized/hue_03/brightness_05/2020040414301701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":6,
      "vividness":1,
      "r":95,
      "g":91,
      "b":88,
      "hex":"#5f5b58",
      "file_location":"assets/colors_organized/hue_03/brightness_06/2020040414295800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":6,
      "vividness":2,
      "r":95,
      "g":88,
      "b":82,
      "hex":"#5f5852",
      "file_location":"assets/colors_organized/hue_03/brightness_06/2020040414295801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":6,
      "vividness":3,
      "r":94,
      "g":84,
      "b":75,
      "hex":"#5e544b",
      "file_location":"assets/colors_organized/hue_03/brightness_06/2020040414295900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":6,
      "vividness":4,
      "r":95,
      "g":78,
      "b":68,
      "hex":"#5f4e44",
      "file_location":"assets/colors_organized/hue_03/brightness_06/2020040414295901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":6,
      "vividness":5,
      "r":94,
      "g":76,
      "b":62,
      "hex":"#5e4c3e",
      "file_location":"assets/colors_organized/hue_03/brightness_06/2020040414300000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":6,
      "vividness":6,
      "r":95,
      "g":72,
      "b":56,
      "hex":"#5f4838",
      "file_location":"assets/colors_organized/hue_03/brightness_06/2020040414300001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":6,
      "vividness":7,
      "r":95,
      "g":68,
      "b":49,
      "hex":"#5f4431",
      "file_location":"assets/colors_organized/hue_03/brightness_06/2020040414300100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":6,
      "vividness":8,
      "r":95,
      "g":64,
      "b":44,
      "hex":"#5f402c",
      "file_location":"assets/colors_organized/hue_03/brightness_06/2020040414300200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":6,
      "vividness":9,
      "r":95,
      "g":60,
      "b":38,
      "hex":"#5f3c26",
      "file_location":"assets/colors_organized/hue_03/brightness_06/2020040414300201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":6,
      "vividness":10,
      "r":94,
      "g":57,
      "b":30,
      "hex":"#5e391e",
      "file_location":"assets/colors_organized/hue_03/brightness_06/2020040414300300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":6,
      "vividness":11,
      "r":94,
      "g":53,
      "b":25,
      "hex":"#5e3519",
      "file_location":"assets/colors_organized/hue_03/brightness_06/2020040414300400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":6,
      "vividness":12,
      "r":94,
      "g":49,
      "b":20,
      "hex":"#5e3114",
      "file_location":"assets/colors_organized/hue_03/brightness_06/2020040414300401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":6,
      "vividness":13,
      "r":95,
      "g":45,
      "b":12,
      "hex":"#5f2d0c",
      "file_location":"assets/colors_organized/hue_03/brightness_06/2020040414300500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":6,
      "vividness":14,
      "r":95,
      "g":41,
      "b":7,
      "hex":"#5f2907",
      "file_location":"assets/colors_organized/hue_03/brightness_06/2020040414300501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":7,
      "vividness":1,
      "r":110,
      "g":105,
      "b":102,
      "hex":"#6e6966",
      "file_location":"assets/colors_organized/hue_03/brightness_07/2020040414294501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":7,
      "vividness":2,
      "r":110,
      "g":101,
      "b":94,
      "hex":"#6e655e",
      "file_location":"assets/colors_organized/hue_03/brightness_07/2020040414294600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":7,
      "vividness":3,
      "r":110,
      "g":95,
      "b":88,
      "hex":"#6e5f58",
      "file_location":"assets/colors_organized/hue_03/brightness_07/2020040414294700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":7,
      "vividness":4,
      "r":110,
      "g":92,
      "b":80,
      "hex":"#6e5c50",
      "file_location":"assets/colors_organized/hue_03/brightness_07/2020040414294701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":7,
      "vividness":5,
      "r":110,
      "g":88,
      "b":74,
      "hex":"#6e584a",
      "file_location":"assets/colors_organized/hue_03/brightness_07/2020040414294800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":7,
      "vividness":6,
      "r":110,
      "g":83,
      "b":66,
      "hex":"#6e5342",
      "file_location":"assets/colors_organized/hue_03/brightness_07/2020040414294801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":7,
      "vividness":7,
      "r":110,
      "g":79,
      "b":58,
      "hex":"#6e4f3a",
      "file_location":"assets/colors_organized/hue_03/brightness_07/2020040414294900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":7,
      "vividness":8,
      "r":110,
      "g":74,
      "b":50,
      "hex":"#6e4a32",
      "file_location":"assets/colors_organized/hue_03/brightness_07/2020040414295000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":7,
      "vividness":9,
      "r":110,
      "g":70,
      "b":44,
      "hex":"#6e462c",
      "file_location":"assets/colors_organized/hue_03/brightness_07/2020040414295001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":7,
      "vividness":10,
      "r":110,
      "g":67,
      "b":35,
      "hex":"#6e4323",
      "file_location":"assets/colors_organized/hue_03/brightness_07/2020040414295100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":7,
      "vividness":11,
      "r":110,
      "g":61,
      "b":29,
      "hex":"#6e3d1d",
      "file_location":"assets/colors_organized/hue_03/brightness_07/2020040414295101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":7,
      "vividness":12,
      "r":109,
      "g":57,
      "b":20,
      "hex":"#6d3914",
      "file_location":"assets/colors_organized/hue_03/brightness_07/2020040414295200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":7,
      "vividness":13,
      "r":110,
      "g":52,
      "b":14,
      "hex":"#6e340e",
      "file_location":"assets/colors_organized/hue_03/brightness_07/2020040414295300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":7,
      "vividness":14,
      "r":109,
      "g":47,
      "b":6,
      "hex":"#6d2f06",
      "file_location":"assets/colors_organized/hue_03/brightness_07/2020040414295301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":8,
      "vividness":1,
      "r":124,
      "g":119,
      "b":116,
      "hex":"#7c7774",
      "file_location":"assets/colors_organized/hue_03/brightness_08/2020040414293300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":8,
      "vividness":2,
      "r":123,
      "g":114,
      "b":107,
      "hex":"#7b726b",
      "file_location":"assets/colors_organized/hue_03/brightness_08/2020040414293400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":8,
      "vividness":3,
      "r":123,
      "g":109,
      "b":98,
      "hex":"#7b6d62",
      "file_location":"assets/colors_organized/hue_03/brightness_08/2020040414293401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":8,
      "vividness":4,
      "r":123,
      "g":104,
      "b":90,
      "hex":"#7b685a",
      "file_location":"assets/colors_organized/hue_03/brightness_08/2020040414293500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":8,
      "vividness":5,
      "r":124,
      "g":98,
      "b":83,
      "hex":"#7c6253",
      "file_location":"assets/colors_organized/hue_03/brightness_08/2020040414293501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":8,
      "vividness":6,
      "r":125,
      "g":94,
      "b":74,
      "hex":"#7d5e4a",
      "file_location":"assets/colors_organized/hue_03/brightness_08/2020040414293600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":8,
      "vividness":7,
      "r":124,
      "g":90,
      "b":65,
      "hex":"#7c5a41",
      "file_location":"assets/colors_organized/hue_03/brightness_08/2020040414293601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":8,
      "vividness":8,
      "r":124,
      "g":84,
      "b":58,
      "hex":"#7c543a",
      "file_location":"assets/colors_organized/hue_03/brightness_08/2020040414293700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":8,
      "vividness":9,
      "r":124,
      "g":79,
      "b":48,
      "hex":"#7c4f30",
      "file_location":"assets/colors_organized/hue_03/brightness_08/2020040414293800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":8,
      "vividness":10,
      "r":124,
      "g":74,
      "b":41,
      "hex":"#7c4a29",
      "file_location":"assets/colors_organized/hue_03/brightness_08/2020040414293801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":8,
      "vividness":11,
      "r":123,
      "g":69,
      "b":33,
      "hex":"#7b4521",
      "file_location":"assets/colors_organized/hue_03/brightness_08/2020040414293900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":8,
      "vividness":12,
      "r":123,
      "g":64,
      "b":22,
      "hex":"#7b4016",
      "file_location":"assets/colors_organized/hue_03/brightness_08/2020040414293901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":8,
      "vividness":13,
      "r":123,
      "g":59,
      "b":15,
      "hex":"#7b3b0f",
      "file_location":"assets/colors_organized/hue_03/brightness_08/2020040414294000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":8,
      "vividness":14,
      "r":124,
      "g":54,
      "b":5,
      "hex":"#7c3605",
      "file_location":"assets/colors_organized/hue_03/brightness_08/2020040414294100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":9,
      "vividness":1,
      "r":139,
      "g":134,
      "b":130,
      "hex":"#8b8682",
      "file_location":"assets/colors_organized/hue_03/brightness_09/2020040414292101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":9,
      "vividness":2,
      "r":139,
      "g":129,
      "b":120,
      "hex":"#8b8178",
      "file_location":"assets/colors_organized/hue_03/brightness_09/2020040414292102-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":9,
      "vividness":3,
      "r":139,
      "g":123,
      "b":110,
      "hex":"#8b7b6e",
      "file_location":"assets/colors_organized/hue_03/brightness_09/2020040414292200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":9,
      "vividness":4,
      "r":139,
      "g":117,
      "b":103,
      "hex":"#8b7567",
      "file_location":"assets/colors_organized/hue_03/brightness_09/2020040414292300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":9,
      "vividness":5,
      "r":138,
      "g":111,
      "b":92,
      "hex":"#8a6f5c",
      "file_location":"assets/colors_organized/hue_03/brightness_09/2020040414292301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":9,
      "vividness":6,
      "r":138,
      "g":106,
      "b":83,
      "hex":"#8a6a53",
      "file_location":"assets/colors_organized/hue_03/brightness_09/2020040414292400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":9,
      "vividness":7,
      "r":140,
      "g":100,
      "b":74,
      "hex":"#8c644a",
      "file_location":"assets/colors_organized/hue_03/brightness_09/2020040414292401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":9,
      "vividness":8,
      "r":139,
      "g":96,
      "b":64,
      "hex":"#8b6040",
      "file_location":"assets/colors_organized/hue_03/brightness_09/2020040414292500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":9,
      "vividness":9,
      "r":139,
      "g":89,
      "b":54,
      "hex":"#8b5936",
      "file_location":"assets/colors_organized/hue_03/brightness_09/2020040414292501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":9,
      "vividness":10,
      "r":138,
      "g":82,
      "b":45,
      "hex":"#8a522d",
      "file_location":"assets/colors_organized/hue_03/brightness_09/2020040414292600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":9,
      "vividness":11,
      "r":139,
      "g":77,
      "b":36,
      "hex":"#8b4d24",
      "file_location":"assets/colors_organized/hue_03/brightness_09/2020040414292700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":9,
      "vividness":12,
      "r":139,
      "g":72,
      "b":27,
      "hex":"#8b481b",
      "file_location":"assets/colors_organized/hue_03/brightness_09/2020040414292701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":9,
      "vividness":13,
      "r":139,
      "g":67,
      "b":17,
      "hex":"#8b4311",
      "file_location":"assets/colors_organized/hue_03/brightness_09/2020040414292800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":9,
      "vividness":14,
      "r":138,
      "g":61,
      "b":9,
      "hex":"#8a3d09",
      "file_location":"assets/colors_organized/hue_03/brightness_09/2020040414292801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":10,
      "vividness":1,
      "r":153,
      "g":148,
      "b":144,
      "hex":"#999490",
      "file_location":"assets/colors_organized/hue_03/brightness_10/2020040414290901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":10,
      "vividness":2,
      "r":155,
      "g":142,
      "b":134,
      "hex":"#9b8e86",
      "file_location":"assets/colors_organized/hue_03/brightness_10/2020040414291000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":10,
      "vividness":3,
      "r":154,
      "g":136,
      "b":122,
      "hex":"#9a887a",
      "file_location":"assets/colors_organized/hue_03/brightness_10/2020040414291001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":10,
      "vividness":4,
      "r":153,
      "g":130,
      "b":112,
      "hex":"#998270",
      "file_location":"assets/colors_organized/hue_03/brightness_10/2020040414291100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":10,
      "vividness":5,
      "r":154,
      "g":123,
      "b":103,
      "hex":"#9a7b67",
      "file_location":"assets/colors_organized/hue_03/brightness_10/2020040414291101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":10,
      "vividness":6,
      "r":154,
      "g":117,
      "b":91,
      "hex":"#9a755b",
      "file_location":"assets/colors_organized/hue_03/brightness_10/2020040414291200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":10,
      "vividness":7,
      "r":154,
      "g":110,
      "b":81,
      "hex":"#9a6e51",
      "file_location":"assets/colors_organized/hue_03/brightness_10/2020040414291201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":10,
      "vividness":8,
      "r":154,
      "g":105,
      "b":72,
      "hex":"#9a6948",
      "file_location":"assets/colors_organized/hue_03/brightness_10/2020040414291300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":10,
      "vividness":9,
      "r":154,
      "g":99,
      "b":60,
      "hex":"#9a633c",
      "file_location":"assets/colors_organized/hue_03/brightness_10/2020040414291400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":10,
      "vividness":10,
      "r":154,
      "g":92,
      "b":51,
      "hex":"#9a5c33",
      "file_location":"assets/colors_organized/hue_03/brightness_10/2020040414291401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":10,
      "vividness":11,
      "r":154,
      "g":86,
      "b":41,
      "hex":"#9a5629",
      "file_location":"assets/colors_organized/hue_03/brightness_10/2020040414291500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":10,
      "vividness":12,
      "r":153,
      "g":80,
      "b":29,
      "hex":"#99501d",
      "file_location":"assets/colors_organized/hue_03/brightness_10/2020040414291501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":10,
      "vividness":13,
      "r":154,
      "g":75,
      "b":19,
      "hex":"#9a4b13",
      "file_location":"assets/colors_organized/hue_03/brightness_10/2020040414291600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":10,
      "vividness":14,
      "r":154,
      "g":68,
      "b":9,
      "hex":"#9a4409",
      "file_location":"assets/colors_organized/hue_03/brightness_10/2020040414291601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":11,
      "vividness":1,
      "r":170,
      "g":162,
      "b":159,
      "hex":"#aaa29f",
      "file_location":"assets/colors_organized/hue_03/brightness_11/2020040414285600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":11,
      "vividness":2,
      "r":169,
      "g":156,
      "b":147,
      "hex":"#a99c93",
      "file_location":"assets/colors_organized/hue_03/brightness_11/2020040414285700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":11,
      "vividness":3,
      "r":169,
      "g":150,
      "b":135,
      "hex":"#a99687",
      "file_location":"assets/colors_organized/hue_03/brightness_11/2020040414285701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":11,
      "vividness":4,
      "r":169,
      "g":142,
      "b":123,
      "hex":"#a98e7b",
      "file_location":"assets/colors_organized/hue_03/brightness_11/2020040414285800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":11,
      "vividness":5,
      "r":170,
      "g":135,
      "b":113,
      "hex":"#aa8771",
      "file_location":"assets/colors_organized/hue_03/brightness_11/2020040414285900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":11,
      "vividness":6,
      "r":169,
      "g":130,
      "b":101,
      "hex":"#a98265",
      "file_location":"assets/colors_organized/hue_03/brightness_11/2020040414285901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":11,
      "vividness":7,
      "r":168,
      "g":122,
      "b":89,
      "hex":"#a87a59",
      "file_location":"assets/colors_organized/hue_03/brightness_11/2020040414290000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":11,
      "vividness":8,
      "r":169,
      "g":115,
      "b":79,
      "hex":"#a9734f",
      "file_location":"assets/colors_organized/hue_03/brightness_11/2020040414290001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":11,
      "vividness":9,
      "r":169,
      "g":107,
      "b":66,
      "hex":"#a96b42",
      "file_location":"assets/colors_organized/hue_03/brightness_11/2020040414290100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":11,
      "vividness":10,
      "r":169,
      "g":101,
      "b":56,
      "hex":"#a96538",
      "file_location":"assets/colors_organized/hue_03/brightness_11/2020040414290200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":11,
      "vividness":11,
      "r":168,
      "g":95,
      "b":44,
      "hex":"#a85f2c",
      "file_location":"assets/colors_organized/hue_03/brightness_11/2020040414290201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":11,
      "vividness":12,
      "r":169,
      "g":88,
      "b":33,
      "hex":"#a95821",
      "file_location":"assets/colors_organized/hue_03/brightness_11/2020040414290300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":11,
      "vividness":13,
      "r":169,
      "g":80,
      "b":20,
      "hex":"#a95014",
      "file_location":"assets/colors_organized/hue_03/brightness_11/2020040414290400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":11,
      "vividness":14,
      "r":169,
      "g":74,
      "b":10,
      "hex":"#a94a0a",
      "file_location":"assets/colors_organized/hue_03/brightness_11/2020040414290401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":12,
      "vividness":1,
      "r":185,
      "g":178,
      "b":172,
      "hex":"#b9b2ac",
      "file_location":"assets/colors_organized/hue_03/brightness_12/2020040414284300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":12,
      "vividness":2,
      "r":184,
      "g":170,
      "b":161,
      "hex":"#b8aaa1",
      "file_location":"assets/colors_organized/hue_03/brightness_12/2020040414284400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":12,
      "vividness":3,
      "r":184,
      "g":162,
      "b":148,
      "hex":"#b8a294",
      "file_location":"assets/colors_organized/hue_03/brightness_12/2020040414284401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":12,
      "vividness":4,
      "r":184,
      "g":156,
      "b":135,
      "hex":"#b89c87",
      "file_location":"assets/colors_organized/hue_03/brightness_12/2020040414284500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":12,
      "vividness":5,
      "r":183,
      "g":147,
      "b":121,
      "hex":"#b79379",
      "file_location":"assets/colors_organized/hue_03/brightness_12/2020040414284501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":12,
      "vividness":6,
      "r":184,
      "g":141,
      "b":109,
      "hex":"#b88d6d",
      "file_location":"assets/colors_organized/hue_03/brightness_12/2020040414284600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":12,
      "vividness":7,
      "r":185,
      "g":132,
      "b":98,
      "hex":"#b98462",
      "file_location":"assets/colors_organized/hue_03/brightness_12/2020040414284601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":12,
      "vividness":8,
      "r":184,
      "g":125,
      "b":85,
      "hex":"#b87d55",
      "file_location":"assets/colors_organized/hue_03/brightness_12/2020040414284700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":12,
      "vividness":9,
      "r":185,
      "g":118,
      "b":73,
      "hex":"#b97649",
      "file_location":"assets/colors_organized/hue_03/brightness_12/2020040414284800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":12,
      "vividness":10,
      "r":183,
      "g":109,
      "b":60,
      "hex":"#b76d3c",
      "file_location":"assets/colors_organized/hue_03/brightness_12/2020040414284801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":12,
      "vividness":11,
      "r":184,
      "g":103,
      "b":48,
      "hex":"#b86730",
      "file_location":"assets/colors_organized/hue_03/brightness_12/2020040414284900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":12,
      "vividness":12,
      "r":184,
      "g":95,
      "b":35,
      "hex":"#b85f23",
      "file_location":"assets/colors_organized/hue_03/brightness_12/2020040414285000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":12,
      "vividness":13,
      "r":183,
      "g":88,
      "b":22,
      "hex":"#b75816",
      "file_location":"assets/colors_organized/hue_03/brightness_12/2020040414285001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":12,
      "vividness":14,
      "r":184,
      "g":81,
      "b":12,
      "hex":"#b8510c",
      "file_location":"assets/colors_organized/hue_03/brightness_12/2020040414285100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":13,
      "vividness":1,
      "r":199,
      "g":192,
      "b":186,
      "hex":"#c7c0ba",
      "file_location":"assets/colors_organized/hue_03/brightness_13/2020040414282300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":13,
      "vividness":2,
      "r":200,
      "g":183,
      "b":173,
      "hex":"#c8b7ad",
      "file_location":"assets/colors_organized/hue_03/brightness_13/2020040414282301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":13,
      "vividness":3,
      "r":199,
      "g":176,
      "b":160,
      "hex":"#c7b0a0",
      "file_location":"assets/colors_organized/hue_03/brightness_13/2020040414282400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":13,
      "vividness":4,
      "r":199,
      "g":167,
      "b":146,
      "hex":"#c7a792",
      "file_location":"assets/colors_organized/hue_03/brightness_13/2020040414282401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":13,
      "vividness":5,
      "r":199,
      "g":159,
      "b":133,
      "hex":"#c79f85",
      "file_location":"assets/colors_organized/hue_03/brightness_13/2020040414282500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":13,
      "vividness":6,
      "r":200,
      "g":151,
      "b":119,
      "hex":"#c89777",
      "file_location":"assets/colors_organized/hue_03/brightness_13/2020040414282501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":13,
      "vividness":7,
      "r":199,
      "g":143,
      "b":106,
      "hex":"#c78f6a",
      "file_location":"assets/colors_organized/hue_03/brightness_13/2020040414282600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":13,
      "vividness":8,
      "r":199,
      "g":136,
      "b":93,
      "hex":"#c7885d",
      "file_location":"assets/colors_organized/hue_03/brightness_13/2020040414282700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":13,
      "vividness":9,
      "r":199,
      "g":127,
      "b":79,
      "hex":"#c77f4f",
      "file_location":"assets/colors_organized/hue_03/brightness_13/2020040414282701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":13,
      "vividness":10,
      "r":199,
      "g":119,
      "b":66,
      "hex":"#c77742",
      "file_location":"assets/colors_organized/hue_03/brightness_13/2020040414282800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":13,
      "vividness":11,
      "r":200,
      "g":111,
      "b":53,
      "hex":"#c86f35",
      "file_location":"assets/colors_organized/hue_03/brightness_13/2020040414282801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":13,
      "vividness":12,
      "r":198,
      "g":103,
      "b":37,
      "hex":"#c66725",
      "file_location":"assets/colors_organized/hue_03/brightness_13/2020040414282900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":13,
      "vividness":13,
      "r":199,
      "g":94,
      "b":26,
      "hex":"#c75e1a",
      "file_location":"assets/colors_organized/hue_03/brightness_13/2020040414282901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":13,
      "vividness":14,
      "r":199,
      "g":87,
      "b":13,
      "hex":"#c7570d",
      "file_location":"assets/colors_organized/hue_03/brightness_13/2020040414283000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":14,
      "vividness":1,
      "r":215,
      "g":206,
      "b":201,
      "hex":"#d7cec9",
      "file_location":"assets/colors_organized/hue_03/brightness_14/02.jpg"
   },
   {
      "hue":3,
      "brightness":14,
      "vividness":2,
      "r":214,
      "g":198,
      "b":185,
      "hex":"#d6c6b9",
      "file_location":"assets/colors_organized/hue_03/brightness_14/03.jpg"
   },
   {
      "hue":3,
      "brightness":14,
      "vividness":3,
      "r":214,
      "g":188,
      "b":171,
      "hex":"#d6bcab",
      "file_location":"assets/colors_organized/hue_03/brightness_14/04.jpg"
   },
   {
      "hue":3,
      "brightness":14,
      "vividness":4,
      "r":215,
      "g":180,
      "b":158,
      "hex":"#d7b49e",
      "file_location":"assets/colors_organized/hue_03/brightness_14/05.jpg"
   },
   {
      "hue":3,
      "brightness":14,
      "vividness":5,
      "r":215,
      "g":171,
      "b":144,
      "hex":"#d7ab90",
      "file_location":"assets/colors_organized/hue_03/brightness_14/06.jpg"
   },
   {
      "hue":3,
      "brightness":14,
      "vividness":6,
      "r":214,
      "g":164,
      "b":129,
      "hex":"#d6a481",
      "file_location":"assets/colors_organized/hue_03/brightness_14/07.jpg"
   },
   {
      "hue":3,
      "brightness":14,
      "vividness":7,
      "r":214,
      "g":155,
      "b":115,
      "hex":"#d69b73",
      "file_location":"assets/colors_organized/hue_03/brightness_14/08.jpg"
   },
   {
      "hue":3,
      "brightness":14,
      "vividness":8,
      "r":214,
      "g":146,
      "b":101,
      "hex":"#d69265",
      "file_location":"assets/colors_organized/hue_03/brightness_14/09.jpg"
   },
   {
      "hue":3,
      "brightness":14,
      "vividness":9,
      "r":214,
      "g":137,
      "b":85,
      "hex":"#d68955",
      "file_location":"assets/colors_organized/hue_03/brightness_14/2020040414281300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":14,
      "vividness":10,
      "r":214,
      "g":128,
      "b":71,
      "hex":"#d68047",
      "file_location":"assets/colors_organized/hue_03/brightness_14/2020040414281500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":14,
      "vividness":11,
      "r":214,
      "g":120,
      "b":56,
      "hex":"#d67838",
      "file_location":"assets/colors_organized/hue_03/brightness_14/2020040414281600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":14,
      "vividness":12,
      "r":214,
      "g":111,
      "b":42,
      "hex":"#d66f2a",
      "file_location":"assets/colors_organized/hue_03/brightness_14/2020040414281601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":14,
      "vividness":13,
      "r":214,
      "g":102,
      "b":28,
      "hex":"#d6661c",
      "file_location":"assets/colors_organized/hue_03/brightness_14/2020040414281700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":14,
      "vividness":14,
      "r":215,
      "g":94,
      "b":13,
      "hex":"#d75e0d",
      "file_location":"assets/colors_organized/hue_03/brightness_14/2020040414281701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":15,
      "vividness":1,
      "r":229,
      "g":220,
      "b":213,
      "hex":"#e5dcd5",
      "file_location":"assets/colors_organized/hue_03/brightness_15/2020040414275100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":15,
      "vividness":2,
      "r":229,
      "g":211,
      "b":197,
      "hex":"#e5d3c5",
      "file_location":"assets/colors_organized/hue_03/brightness_15/2020040414275200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":15,
      "vividness":3,
      "r":229,
      "g":202,
      "b":183,
      "hex":"#e5cab7",
      "file_location":"assets/colors_organized/hue_03/brightness_15/2020040414275201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":15,
      "vividness":4,
      "r":229,
      "g":193,
      "b":167,
      "hex":"#e5c1a7",
      "file_location":"assets/colors_organized/hue_03/brightness_15/2020040414275300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":15,
      "vividness":5,
      "r":229,
      "g":182,
      "b":152,
      "hex":"#e5b698",
      "file_location":"assets/colors_organized/hue_03/brightness_15/2020040414275400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":15,
      "vividness":6,
      "r":229,
      "g":173,
      "b":136,
      "hex":"#e5ad88",
      "file_location":"assets/colors_organized/hue_03/brightness_15/2020040414275401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":15,
      "vividness":7,
      "r":229,
      "g":165,
      "b":121,
      "hex":"#e5a579",
      "file_location":"assets/colors_organized/hue_03/brightness_15/2020040414275500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":15,
      "vividness":8,
      "r":229,
      "g":155,
      "b":106,
      "hex":"#e59b6a",
      "file_location":"assets/colors_organized/hue_03/brightness_15/2020040414275600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":15,
      "vividness":9,
      "r":229,
      "g":147,
      "b":91,
      "hex":"#e5935b",
      "file_location":"assets/colors_organized/hue_03/brightness_15/2020040414275601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":15,
      "vividness":10,
      "r":230,
      "g":137,
      "b":76,
      "hex":"#e6894c",
      "file_location":"assets/colors_organized/hue_03/brightness_15/2020040414275700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":15,
      "vividness":11,
      "r":230,
      "g":129,
      "b":61,
      "hex":"#e6813d",
      "file_location":"assets/colors_organized/hue_03/brightness_15/2020040414275701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":15,
      "vividness":12,
      "r":229,
      "g":119,
      "b":44,
      "hex":"#e5772c",
      "file_location":"assets/colors_organized/hue_03/brightness_15/2020040414275800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":15,
      "vividness":13,
      "r":229,
      "g":110,
      "b":30,
      "hex":"#e56e1e",
      "file_location":"assets/colors_organized/hue_03/brightness_15/2020040414275801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":3,
      "brightness":15,
      "vividness":14,
      "r":230,
      "g":100,
      "b":14,
      "hex":"#e6640e",
      "file_location":"assets/colors_organized/hue_03/brightness_15/2020040414275900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":1,
      "vividness":1,
      "r":19,
      "g":19,
      "b":17,
      "hex":"#131311",
      "file_location":"assets/colors_organized/hue_04/brightness_01/2020040414351401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":1,
      "vividness":2,
      "r":19,
      "g":14,
      "b":10,
      "hex":"#130e0a",
      "file_location":"assets/colors_organized/hue_04/brightness_01/2020040414351601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":1,
      "vividness":3,
      "r":19,
      "g":15,
      "b":6,
      "hex":"#130f06",
      "file_location":"assets/colors_organized/hue_04/brightness_01/2020040414351701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":1,
      "vividness":4,
      "r":19,
      "g":12,
      "b":2,
      "hex":"#130c02",
      "file_location":"assets/colors_organized/hue_04/brightness_01/2020040414351801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":1,
      "vividness":5,
      "r":19,
      "g":13,
      "b":0,
      "hex":"#130d00",
      "file_location":"assets/colors_organized/hue_04/brightness_01/2020040414352000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":2,
      "vividness":1,
      "r":34,
      "g":33,
      "b":29,
      "hex":"#22211d",
      "file_location":"assets/colors_organized/hue_04/brightness_02/2020040414350401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":2,
      "vividness":2,
      "r":35,
      "g":32,
      "b":27,
      "hex":"#23201b",
      "file_location":"assets/colors_organized/hue_04/brightness_02/2020040414350500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":2,
      "vividness":3,
      "r":34,
      "g":31,
      "b":24,
      "hex":"#221f18",
      "file_location":"assets/colors_organized/hue_04/brightness_02/2020040414350501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":2,
      "vividness":4,
      "r":34,
      "g":29,
      "b":23,
      "hex":"#221d17",
      "file_location":"assets/colors_organized/hue_04/brightness_02/2020040414350600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":2,
      "vividness":5,
      "r":34,
      "g":30,
      "b":21,
      "hex":"#221e15",
      "file_location":"assets/colors_organized/hue_04/brightness_02/2020040414350601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":2,
      "vividness":6,
      "r":34,
      "g":27,
      "b":17,
      "hex":"#221b11",
      "file_location":"assets/colors_organized/hue_04/brightness_02/2020040414350602-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":2,
      "vividness":7,
      "r":35,
      "g":27,
      "b":16,
      "hex":"#231b10",
      "file_location":"assets/colors_organized/hue_04/brightness_02/2020040414350700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":2,
      "vividness":8,
      "r":34,
      "g":26,
      "b":13,
      "hex":"#221a0d",
      "file_location":"assets/colors_organized/hue_04/brightness_02/2020040414350701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":2,
      "vividness":9,
      "r":34,
      "g":25,
      "b":10,
      "hex":"#22190a",
      "file_location":"assets/colors_organized/hue_04/brightness_02/2020040414350800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":2,
      "vividness":10,
      "r":35,
      "g":23,
      "b":9,
      "hex":"#231709",
      "file_location":"assets/colors_organized/hue_04/brightness_02/2020040414350801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":2,
      "vividness":11,
      "r":34,
      "g":22,
      "b":6,
      "hex":"#221606",
      "file_location":"assets/colors_organized/hue_04/brightness_02/2020040414350900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":2,
      "vividness":12,
      "r":35,
      "g":22,
      "b":3,
      "hex":"#231603",
      "file_location":"assets/colors_organized/hue_04/brightness_02/2020040414350901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":2,
      "vividness":13,
      "r":34,
      "g":21,
      "b":2,
      "hex":"#221502",
      "file_location":"assets/colors_organized/hue_04/brightness_02/2020040414351000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":3,
      "vividness":1,
      "r":50,
      "g":47,
      "b":42,
      "hex":"#322f2a",
      "file_location":"assets/colors_organized/hue_04/brightness_03/2020040414345400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":3,
      "vividness":2,
      "r":49,
      "g":46,
      "b":39,
      "hex":"#312e27",
      "file_location":"assets/colors_organized/hue_04/brightness_03/2020040414345401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":3,
      "vividness":3,
      "r":50,
      "g":43,
      "b":35,
      "hex":"#322b23",
      "file_location":"assets/colors_organized/hue_04/brightness_03/2020040414345500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":3,
      "vividness":4,
      "r":50,
      "g":43,
      "b":33,
      "hex":"#322b21",
      "file_location":"assets/colors_organized/hue_04/brightness_03/2020040414345501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":3,
      "vividness":5,
      "r":50,
      "g":42,
      "b":31,
      "hex":"#322a1f",
      "file_location":"assets/colors_organized/hue_04/brightness_03/2020040414345600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":3,
      "vividness":6,
      "r":49,
      "g":39,
      "b":27,
      "hex":"#31271b",
      "file_location":"assets/colors_organized/hue_04/brightness_03/2020040414345601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":3,
      "vividness":7,
      "r":50,
      "g":38,
      "b":22,
      "hex":"#322616",
      "file_location":"assets/colors_organized/hue_04/brightness_03/2020040414345700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":3,
      "vividness":8,
      "r":49,
      "g":38,
      "b":20,
      "hex":"#312614",
      "file_location":"assets/colors_organized/hue_04/brightness_03/2020040414345701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":3,
      "vividness":9,
      "r":50,
      "g":35,
      "b":14,
      "hex":"#32230e",
      "file_location":"assets/colors_organized/hue_04/brightness_03/2020040414345702-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":3,
      "vividness":10,
      "r":50,
      "g":35,
      "b":12,
      "hex":"#32230c",
      "file_location":"assets/colors_organized/hue_04/brightness_03/2020040414345800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":3,
      "vividness":11,
      "r":50,
      "g":34,
      "b":9,
      "hex":"#322209",
      "file_location":"assets/colors_organized/hue_04/brightness_03/2020040414345801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":3,
      "vividness":12,
      "r":49,
      "g":32,
      "b":6,
      "hex":"#312006",
      "file_location":"assets/colors_organized/hue_04/brightness_03/2020040414345900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":3,
      "vividness":13,
      "r":50,
      "g":30,
      "b":3,
      "hex":"#321e03",
      "file_location":"assets/colors_organized/hue_04/brightness_03/2020040414350000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":4,
      "vividness":1,
      "r":64,
      "g":63,
      "b":59,
      "hex":"#403f3b",
      "file_location":"assets/colors_organized/hue_04/brightness_04/2020040414344300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":4,
      "vividness":2,
      "r":64,
      "g":61,
      "b":56,
      "hex":"#403d38",
      "file_location":"assets/colors_organized/hue_04/brightness_04/2020040414344301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":4,
      "vividness":3,
      "r":66,
      "g":59,
      "b":51,
      "hex":"#423b33",
      "file_location":"assets/colors_organized/hue_04/brightness_04/2020040414344400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":4,
      "vividness":4,
      "r":64,
      "g":58,
      "b":46,
      "hex":"#403a2e",
      "file_location":"assets/colors_organized/hue_04/brightness_04/2020040414344401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":4,
      "vividness":5,
      "r":65,
      "g":55,
      "b":43,
      "hex":"#41372b",
      "file_location":"assets/colors_organized/hue_04/brightness_04/2020040414344500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":4,
      "vividness":6,
      "r":65,
      "g":53,
      "b":37,
      "hex":"#413525",
      "file_location":"assets/colors_organized/hue_04/brightness_04/2020040414344501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":4,
      "vividness":7,
      "r":65,
      "g":52,
      "b":33,
      "hex":"#413421",
      "file_location":"assets/colors_organized/hue_04/brightness_04/2020040414344600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":4,
      "vividness":8,
      "r":65,
      "g":50,
      "b":29,
      "hex":"#41321d",
      "file_location":"assets/colors_organized/hue_04/brightness_04/2020040414344601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":4,
      "vividness":9,
      "r":65,
      "g":49,
      "b":26,
      "hex":"#41311a",
      "file_location":"assets/colors_organized/hue_04/brightness_04/2020040414344700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":4,
      "vividness":10,
      "r":64,
      "g":47,
      "b":21,
      "hex":"#402f15",
      "file_location":"assets/colors_organized/hue_04/brightness_04/2020040414344701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":4,
      "vividness":11,
      "r":65,
      "g":45,
      "b":18,
      "hex":"#412d12",
      "file_location":"assets/colors_organized/hue_04/brightness_04/2020040414344800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":4,
      "vividness":12,
      "r":64,
      "g":43,
      "b":12,
      "hex":"#402b0c",
      "file_location":"assets/colors_organized/hue_04/brightness_04/2020040414344801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":4,
      "vividness":13,
      "r":64,
      "g":42,
      "b":5,
      "hex":"#402a05",
      "file_location":"assets/colors_organized/hue_04/brightness_04/2020040414344802-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":4,
      "vividness":14,
      "r":65,
      "g":39,
      "b":2,
      "hex":"#412702",
      "file_location":"assets/colors_organized/hue_04/brightness_04/2020040414344900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":5,
      "vividness":1,
      "r":79,
      "g":78,
      "b":74,
      "hex":"#4f4e4a",
      "file_location":"assets/colors_organized/hue_04/brightness_05/2020040414342500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":5,
      "vividness":2,
      "r":80,
      "g":75,
      "b":69,
      "hex":"#504b45",
      "file_location":"assets/colors_organized/hue_04/brightness_05/2020040414342501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":5,
      "vividness":3,
      "r":80,
      "g":73,
      "b":63,
      "hex":"#50493f",
      "file_location":"assets/colors_organized/hue_04/brightness_05/2020040414342600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":5,
      "vividness":4,
      "r":79,
      "g":71,
      "b":58,
      "hex":"#4f473a",
      "file_location":"assets/colors_organized/hue_04/brightness_05/2020040414342601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":5,
      "vividness":5,
      "r":80,
      "g":68,
      "b":52,
      "hex":"#504434",
      "file_location":"assets/colors_organized/hue_04/brightness_05/2020040414342700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":5,
      "vividness":6,
      "r":80,
      "g":67,
      "b":48,
      "hex":"#504330",
      "file_location":"assets/colors_organized/hue_04/brightness_05/2020040414342701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":5,
      "vividness":7,
      "r":80,
      "g":65,
      "b":42,
      "hex":"#50412a",
      "file_location":"assets/colors_organized/hue_04/brightness_05/2020040414342800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":5,
      "vividness":8,
      "r":80,
      "g":63,
      "b":37,
      "hex":"#503f25",
      "file_location":"assets/colors_organized/hue_04/brightness_05/2020040414342801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":5,
      "vividness":9,
      "r":79,
      "g":60,
      "b":30,
      "hex":"#4f3c1e",
      "file_location":"assets/colors_organized/hue_04/brightness_05/2020040414342900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":5,
      "vividness":10,
      "r":81,
      "g":58,
      "b":27,
      "hex":"#513a1b",
      "file_location":"assets/colors_organized/hue_04/brightness_05/2020040414343000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":5,
      "vividness":11,
      "r":80,
      "g":56,
      "b":22,
      "hex":"#503816",
      "file_location":"assets/colors_organized/hue_04/brightness_05/2020040414343001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":5,
      "vividness":12,
      "r":79,
      "g":54,
      "b":14,
      "hex":"#4f360e",
      "file_location":"assets/colors_organized/hue_04/brightness_05/2020040414343100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":5,
      "vividness":13,
      "r":79,
      "g":52,
      "b":9,
      "hex":"#4f3409",
      "file_location":"assets/colors_organized/hue_04/brightness_05/2020040414343101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":5,
      "vividness":14,
      "r":81,
      "g":50,
      "b":6,
      "hex":"#513206",
      "file_location":"assets/colors_organized/hue_04/brightness_05/2020040414343200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":6,
      "vividness":1,
      "r":95,
      "g":92,
      "b":87,
      "hex":"#5f5c57",
      "file_location":"assets/colors_organized/hue_04/brightness_06/2020040414341000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":6,
      "vividness":2,
      "r":94,
      "g":90,
      "b":81,
      "hex":"#5e5a51",
      "file_location":"assets/colors_organized/hue_04/brightness_06/2020040414341001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":6,
      "vividness":3,
      "r":95,
      "g":87,
      "b":76,
      "hex":"#5f574c",
      "file_location":"assets/colors_organized/hue_04/brightness_06/2020040414341100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":6,
      "vividness":4,
      "r":95,
      "g":83,
      "b":69,
      "hex":"#5f5345",
      "file_location":"assets/colors_organized/hue_04/brightness_06/2020040414341101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":6,
      "vividness":5,
      "r":95,
      "g":82,
      "b":63,
      "hex":"#5f523f",
      "file_location":"assets/colors_organized/hue_04/brightness_06/2020040414341200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":6,
      "vividness":6,
      "r":95,
      "g":79,
      "b":56,
      "hex":"#5f4f38",
      "file_location":"assets/colors_organized/hue_04/brightness_06/2020040414341201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":6,
      "vividness":7,
      "r":94,
      "g":77,
      "b":49,
      "hex":"#5e4d31",
      "file_location":"assets/colors_organized/hue_04/brightness_06/2020040414341300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":6,
      "vividness":8,
      "r":95,
      "g":74,
      "b":43,
      "hex":"#5f4a2b",
      "file_location":"assets/colors_organized/hue_04/brightness_06/2020040414341301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":6,
      "vividness":9,
      "r":95,
      "g":72,
      "b":38,
      "hex":"#5f4826",
      "file_location":"assets/colors_organized/hue_04/brightness_06/2020040414341400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":6,
      "vividness":10,
      "r":94,
      "g":69,
      "b":29,
      "hex":"#5e451d",
      "file_location":"assets/colors_organized/hue_04/brightness_06/2020040414341401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":6,
      "vividness":11,
      "r":94,
      "g":67,
      "b":24,
      "hex":"#5e4318",
      "file_location":"assets/colors_organized/hue_04/brightness_06/2020040414341500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":6,
      "vividness":12,
      "r":95,
      "g":64,
      "b":18,
      "hex":"#5f4012",
      "file_location":"assets/colors_organized/hue_04/brightness_06/2020040414341600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":6,
      "vividness":13,
      "r":95,
      "g":61,
      "b":13,
      "hex":"#5f3d0d",
      "file_location":"assets/colors_organized/hue_04/brightness_06/2020040414341700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":6,
      "vividness":14,
      "r":94,
      "g":58,
      "b":6,
      "hex":"#5e3a06",
      "file_location":"assets/colors_organized/hue_04/brightness_06/2020040414341701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":7,
      "vividness":1,
      "r":110,
      "g":107,
      "b":102,
      "hex":"#6e6b66",
      "file_location":"assets/colors_organized/hue_04/brightness_07/2020040414335801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":7,
      "vividness":2,
      "r":111,
      "g":104,
      "b":94,
      "hex":"#6f685e",
      "file_location":"assets/colors_organized/hue_04/brightness_07/2020040414335900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":7,
      "vividness":3,
      "r":109,
      "g":101,
      "b":88,
      "hex":"#6d6558",
      "file_location":"assets/colors_organized/hue_04/brightness_07/2020040414335901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":7,
      "vividness":4,
      "r":109,
      "g":98,
      "b":80,
      "hex":"#6d6250",
      "file_location":"assets/colors_organized/hue_04/brightness_07/2020040414340000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":7,
      "vividness":5,
      "r":110,
      "g":95,
      "b":72,
      "hex":"#6e5f48",
      "file_location":"assets/colors_organized/hue_04/brightness_07/2020040414340001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":7,
      "vividness":6,
      "r":109,
      "g":92,
      "b":66,
      "hex":"#6d5c42",
      "file_location":"assets/colors_organized/hue_04/brightness_07/2020040414340100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":7,
      "vividness":7,
      "r":110,
      "g":89,
      "b":58,
      "hex":"#6e593a",
      "file_location":"assets/colors_organized/hue_04/brightness_07/2020040414340101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":7,
      "vividness":8,
      "r":110,
      "g":86,
      "b":50,
      "hex":"#6e5632",
      "file_location":"assets/colors_organized/hue_04/brightness_07/2020040414340200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":7,
      "vividness":9,
      "r":110,
      "g":82,
      "b":43,
      "hex":"#6e522b",
      "file_location":"assets/colors_organized/hue_04/brightness_07/2020040414340201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":7,
      "vividness":10,
      "r":109,
      "g":80,
      "b":36,
      "hex":"#6d5024",
      "file_location":"assets/colors_organized/hue_04/brightness_07/2020040414340300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":7,
      "vividness":11,
      "r":109,
      "g":77,
      "b":30,
      "hex":"#6d4d1e",
      "file_location":"assets/colors_organized/hue_04/brightness_07/2020040414340301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":7,
      "vividness":12,
      "r":110,
      "g":74,
      "b":22,
      "hex":"#6e4a16",
      "file_location":"assets/colors_organized/hue_04/brightness_07/2020040414340400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":7,
      "vividness":13,
      "r":110,
      "g":71,
      "b":12,
      "hex":"#6e470c",
      "file_location":"assets/colors_organized/hue_04/brightness_07/2020040414340401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":7,
      "vividness":14,
      "r":109,
      "g":68,
      "b":6,
      "hex":"#6d4406",
      "file_location":"assets/colors_organized/hue_04/brightness_07/2020040414340500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":8,
      "vividness":1,
      "r":124,
      "g":121,
      "b":116,
      "hex":"#7c7974",
      "file_location":"assets/colors_organized/hue_04/brightness_08/2020040414334701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":8,
      "vividness":2,
      "r":125,
      "g":118,
      "b":108,
      "hex":"#7d766c",
      "file_location":"assets/colors_organized/hue_04/brightness_08/2020040414334800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":8,
      "vividness":3,
      "r":123,
      "g":114,
      "b":99,
      "hex":"#7b7263",
      "file_location":"assets/colors_organized/hue_04/brightness_08/2020040414334801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":8,
      "vividness":4,
      "r":123,
      "g":110,
      "b":91,
      "hex":"#7b6e5b",
      "file_location":"assets/colors_organized/hue_04/brightness_08/2020040414334900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":8,
      "vividness":5,
      "r":124,
      "g":108,
      "b":83,
      "hex":"#7c6c53",
      "file_location":"assets/colors_organized/hue_04/brightness_08/2020040414334901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":8,
      "vividness":6,
      "r":123,
      "g":104,
      "b":72,
      "hex":"#7b6848",
      "file_location":"assets/colors_organized/hue_04/brightness_08/2020040414335000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":8,
      "vividness":7,
      "r":124,
      "g":102,
      "b":65,
      "hex":"#7c6641",
      "file_location":"assets/colors_organized/hue_04/brightness_08/2020040414335001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":8,
      "vividness":8,
      "r":123,
      "g":98,
      "b":57,
      "hex":"#7b6239",
      "file_location":"assets/colors_organized/hue_04/brightness_08/2020040414335100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":8,
      "vividness":9,
      "r":125,
      "g":94,
      "b":48,
      "hex":"#7d5e30",
      "file_location":"assets/colors_organized/hue_04/brightness_08/2020040414335101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":8,
      "vividness":10,
      "r":123,
      "g":91,
      "b":40,
      "hex":"#7b5b28",
      "file_location":"assets/colors_organized/hue_04/brightness_08/2020040414335200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":8,
      "vividness":11,
      "r":123,
      "g":88,
      "b":34,
      "hex":"#7b5822",
      "file_location":"assets/colors_organized/hue_04/brightness_08/2020040414335201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":8,
      "vividness":12,
      "r":124,
      "g":84,
      "b":22,
      "hex":"#7c5416",
      "file_location":"assets/colors_organized/hue_04/brightness_08/2020040414335300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":8,
      "vividness":13,
      "r":124,
      "g":81,
      "b":13,
      "hex":"#7c510d",
      "file_location":"assets/colors_organized/hue_04/brightness_08/2020040414335301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":8,
      "vividness":14,
      "r":124,
      "g":77,
      "b":5,
      "hex":"#7c4d05",
      "file_location":"assets/colors_organized/hue_04/brightness_08/2020040414335400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":9,
      "vividness":1,
      "r":139,
      "g":136,
      "b":129,
      "hex":"#8b8881",
      "file_location":"assets/colors_organized/hue_04/brightness_09/2020040414333601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":9,
      "vividness":2,
      "r":139,
      "g":133,
      "b":121,
      "hex":"#8b8579",
      "file_location":"assets/colors_organized/hue_04/brightness_09/2020040414333700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":9,
      "vividness":3,
      "r":139,
      "g":128,
      "b":110,
      "hex":"#8b806e",
      "file_location":"assets/colors_organized/hue_04/brightness_09/2020040414333701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":9,
      "vividness":4,
      "r":139,
      "g":124,
      "b":101,
      "hex":"#8b7c65",
      "file_location":"assets/colors_organized/hue_04/brightness_09/2020040414333800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":9,
      "vividness":5,
      "r":138,
      "g":121,
      "b":93,
      "hex":"#8a795d",
      "file_location":"assets/colors_organized/hue_04/brightness_09/2020040414333801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":9,
      "vividness":6,
      "r":140,
      "g":117,
      "b":83,
      "hex":"#8c7553",
      "file_location":"assets/colors_organized/hue_04/brightness_09/2020040414333900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":9,
      "vividness":7,
      "r":138,
      "g":113,
      "b":73,
      "hex":"#8a7149",
      "file_location":"assets/colors_organized/hue_04/brightness_09/2020040414333901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":9,
      "vividness":8,
      "r":138,
      "g":110,
      "b":63,
      "hex":"#8a6e3f",
      "file_location":"assets/colors_organized/hue_04/brightness_09/2020040414334000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":9,
      "vividness":9,
      "r":139,
      "g":106,
      "b":55,
      "hex":"#8b6a37",
      "file_location":"assets/colors_organized/hue_04/brightness_09/2020040414334001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":9,
      "vividness":10,
      "r":139,
      "g":103,
      "b":45,
      "hex":"#8b672d",
      "file_location":"assets/colors_organized/hue_04/brightness_09/2020040414334100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":9,
      "vividness":11,
      "r":139,
      "g":98,
      "b":36,
      "hex":"#8b6224",
      "file_location":"assets/colors_organized/hue_04/brightness_09/2020040414334101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":9,
      "vividness":12,
      "r":139,
      "g":94,
      "b":26,
      "hex":"#8b5e1a",
      "file_location":"assets/colors_organized/hue_04/brightness_09/2020040414334200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":9,
      "vividness":13,
      "r":139,
      "g":91,
      "b":17,
      "hex":"#8b5b11",
      "file_location":"assets/colors_organized/hue_04/brightness_09/2020040414334201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":9,
      "vividness":14,
      "r":139,
      "g":88,
      "b":9,
      "hex":"#8b5809",
      "file_location":"assets/colors_organized/hue_04/brightness_09/2020040414334300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":10,
      "vividness":1,
      "r":154,
      "g":151,
      "b":144,
      "hex":"#9a9790",
      "file_location":"assets/colors_organized/hue_04/brightness_10/2020040414332401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":10,
      "vividness":2,
      "r":154,
      "g":146,
      "b":135,
      "hex":"#9a9287",
      "file_location":"assets/colors_organized/hue_04/brightness_10/2020040414332500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":10,
      "vividness":3,
      "r":154,
      "g":143,
      "b":123,
      "hex":"#9a8f7b",
      "file_location":"assets/colors_organized/hue_04/brightness_10/2020040414332501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":10,
      "vividness":4,
      "r":154,
      "g":138,
      "b":113,
      "hex":"#9a8a71",
      "file_location":"assets/colors_organized/hue_04/brightness_10/2020040414332600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":10,
      "vividness":5,
      "r":154,
      "g":135,
      "b":103,
      "hex":"#9a8767",
      "file_location":"assets/colors_organized/hue_04/brightness_10/2020040414332700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":10,
      "vividness":6,
      "r":154,
      "g":130,
      "b":92,
      "hex":"#9a825c",
      "file_location":"assets/colors_organized/hue_04/brightness_10/2020040414332701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":10,
      "vividness":7,
      "r":154,
      "g":125,
      "b":81,
      "hex":"#9a7d51",
      "file_location":"assets/colors_organized/hue_04/brightness_10/2020040414332800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":10,
      "vividness":8,
      "r":154,
      "g":120,
      "b":72,
      "hex":"#9a7848",
      "file_location":"assets/colors_organized/hue_04/brightness_10/2020040414332801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":10,
      "vividness":9,
      "r":154,
      "g":118,
      "b":60,
      "hex":"#9a763c",
      "file_location":"assets/colors_organized/hue_04/brightness_10/2020040414332900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":10,
      "vividness":10,
      "r":154,
      "g":113,
      "b":51,
      "hex":"#9a7133",
      "file_location":"assets/colors_organized/hue_04/brightness_10/2020040414332901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":10,
      "vividness":11,
      "r":154,
      "g":109,
      "b":41,
      "hex":"#9a6d29",
      "file_location":"assets/colors_organized/hue_04/brightness_10/2020040414333000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":10,
      "vividness":12,
      "r":154,
      "g":105,
      "b":29,
      "hex":"#9a691d",
      "file_location":"assets/colors_organized/hue_04/brightness_10/2020040414333001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":10,
      "vividness":13,
      "r":154,
      "g":102,
      "b":19,
      "hex":"#9a6613",
      "file_location":"assets/colors_organized/hue_04/brightness_10/2020040414333100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":10,
      "vividness":14,
      "r":154,
      "g":97,
      "b":10,
      "hex":"#9a610a",
      "file_location":"assets/colors_organized/hue_04/brightness_10/2020040414333101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":11,
      "vividness":1,
      "r":169,
      "g":166,
      "b":159,
      "hex":"#a9a69f",
      "file_location":"assets/colors_organized/hue_04/brightness_11/2020040414331300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":11,
      "vividness":2,
      "r":168,
      "g":160,
      "b":147,
      "hex":"#a8a093",
      "file_location":"assets/colors_organized/hue_04/brightness_11/2020040414331301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":11,
      "vividness":3,
      "r":169,
      "g":157,
      "b":135,
      "hex":"#a99d87",
      "file_location":"assets/colors_organized/hue_04/brightness_11/2020040414331400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":11,
      "vividness":4,
      "r":168,
      "g":151,
      "b":123,
      "hex":"#a8977b",
      "file_location":"assets/colors_organized/hue_04/brightness_11/2020040414331401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":11,
      "vividness":5,
      "r":170,
      "g":147,
      "b":113,
      "hex":"#aa9371",
      "file_location":"assets/colors_organized/hue_04/brightness_11/2020040414331500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":11,
      "vividness":6,
      "r":170,
      "g":142,
      "b":102,
      "hex":"#aa8e66",
      "file_location":"assets/colors_organized/hue_04/brightness_11/2020040414331600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":11,
      "vividness":7,
      "r":169,
      "g":139,
      "b":89,
      "hex":"#a98b59",
      "file_location":"assets/colors_organized/hue_04/brightness_11/2020040414331601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":11,
      "vividness":8,
      "r":169,
      "g":134,
      "b":80,
      "hex":"#a98650",
      "file_location":"assets/colors_organized/hue_04/brightness_11/2020040414331700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":11,
      "vividness":9,
      "r":169,
      "g":129,
      "b":67,
      "hex":"#a98143",
      "file_location":"assets/colors_organized/hue_04/brightness_11/2020040414331701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":11,
      "vividness":10,
      "r":169,
      "g":124,
      "b":56,
      "hex":"#a97c38",
      "file_location":"assets/colors_organized/hue_04/brightness_11/2020040414331800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":11,
      "vividness":11,
      "r":168,
      "g":119,
      "b":43,
      "hex":"#a8772b",
      "file_location":"assets/colors_organized/hue_04/brightness_11/2020040414331900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":11,
      "vividness":12,
      "r":170,
      "g":115,
      "b":33,
      "hex":"#aa7321",
      "file_location":"assets/colors_organized/hue_04/brightness_11/2020040414331901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":11,
      "vividness":13,
      "r":170,
      "g":110,
      "b":22,
      "hex":"#aa6e16",
      "file_location":"assets/colors_organized/hue_04/brightness_11/2020040414332000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":11,
      "vividness":14,
      "r":169,
      "g":106,
      "b":9,
      "hex":"#a96a09",
      "file_location":"assets/colors_organized/hue_04/brightness_11/2020040414332001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":12,
      "vividness":1,
      "r":184,
      "g":179,
      "b":173,
      "hex":"#b8b3ad",
      "file_location":"assets/colors_organized/hue_04/brightness_12/2020040414321201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":12,
      "vividness":2,
      "r":184,
      "g":175,
      "b":160,
      "hex":"#b8afa0",
      "file_location":"assets/colors_organized/hue_04/brightness_12/2020040414321300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":12,
      "vividness":3,
      "r":184,
      "g":169,
      "b":146,
      "hex":"#b8a992",
      "file_location":"assets/colors_organized/hue_04/brightness_12/2020040414321301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":12,
      "vividness":4,
      "r":184,
      "g":165,
      "b":135,
      "hex":"#b8a587",
      "file_location":"assets/colors_organized/hue_04/brightness_12/2020040414321400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":12,
      "vividness":5,
      "r":184,
      "g":160,
      "b":122,
      "hex":"#b8a07a",
      "file_location":"assets/colors_organized/hue_04/brightness_12/2020040414321401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":12,
      "vividness":6,
      "r":184,
      "g":156,
      "b":109,
      "hex":"#b89c6d",
      "file_location":"assets/colors_organized/hue_04/brightness_12/2020040414321500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":12,
      "vividness":7,
      "r":183,
      "g":150,
      "b":97,
      "hex":"#b79661",
      "file_location":"assets/colors_organized/hue_04/brightness_12/2020040414321600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":12,
      "vividness":8,
      "r":184,
      "g":145,
      "b":86,
      "hex":"#b89156",
      "file_location":"assets/colors_organized/hue_04/brightness_12/2020040414321601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":12,
      "vividness":9,
      "r":184,
      "g":141,
      "b":73,
      "hex":"#b88d49",
      "file_location":"assets/colors_organized/hue_04/brightness_12/2020040414321700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":12,
      "vividness":10,
      "r":183,
      "g":135,
      "b":59,
      "hex":"#b7873b",
      "file_location":"assets/colors_organized/hue_04/brightness_12/2020040414321800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":12,
      "vividness":11,
      "r":185,
      "g":130,
      "b":48,
      "hex":"#b98230",
      "file_location":"assets/colors_organized/hue_04/brightness_12/2020040414321900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":12,
      "vividness":12,
      "r":184,
      "g":125,
      "b":35,
      "hex":"#b87d23",
      "file_location":"assets/colors_organized/hue_04/brightness_12/2020040414321901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":12,
      "vividness":13,
      "r":184,
      "g":120,
      "b":22,
      "hex":"#b87816",
      "file_location":"assets/colors_organized/hue_04/brightness_12/2020040414322000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":12,
      "vividness":14,
      "r":184,
      "g":115,
      "b":12,
      "hex":"#b8730c",
      "file_location":"assets/colors_organized/hue_04/brightness_12/2020040414322001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":13,
      "vividness":1,
      "r":199,
      "g":195,
      "b":186,
      "hex":"#c7c3ba",
      "file_location":"assets/colors_organized/hue_04/brightness_13/2020040414320000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":13,
      "vividness":2,
      "r":200,
      "g":188,
      "b":172,
      "hex":"#c8bcac",
      "file_location":"assets/colors_organized/hue_04/brightness_13/2020040414320001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":13,
      "vividness":3,
      "r":199,
      "g":183,
      "b":158,
      "hex":"#c7b79e",
      "file_location":"assets/colors_organized/hue_04/brightness_13/2020040414320100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":13,
      "vividness":4,
      "r":199,
      "g":179,
      "b":146,
      "hex":"#c7b392",
      "file_location":"assets/colors_organized/hue_04/brightness_13/2020040414320200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":13,
      "vividness":5,
      "r":198,
      "g":173,
      "b":132,
      "hex":"#c6ad84",
      "file_location":"assets/colors_organized/hue_04/brightness_13/2020040414320201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":13,
      "vividness":6,
      "r":199,
      "g":167,
      "b":118,
      "hex":"#c7a776",
      "file_location":"assets/colors_organized/hue_04/brightness_13/2020040414320300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":13,
      "vividness":7,
      "r":199,
      "g":163,
      "b":105,
      "hex":"#c7a369",
      "file_location":"assets/colors_organized/hue_04/brightness_13/2020040414320301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":13,
      "vividness":8,
      "r":198,
      "g":157,
      "b":93,
      "hex":"#c69d5d",
      "file_location":"assets/colors_organized/hue_04/brightness_13/2020040414320400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":13,
      "vividness":9,
      "r":199,
      "g":151,
      "b":79,
      "hex":"#c7974f",
      "file_location":"assets/colors_organized/hue_04/brightness_13/2020040414320500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":13,
      "vividness":10,
      "r":199,
      "g":146,
      "b":66,
      "hex":"#c79242",
      "file_location":"assets/colors_organized/hue_04/brightness_13/2020040414320501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":13,
      "vividness":11,
      "r":198,
      "g":141,
      "b":52,
      "hex":"#c68d34",
      "file_location":"assets/colors_organized/hue_04/brightness_13/2020040414320600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":13,
      "vividness":12,
      "r":199,
      "g":135,
      "b":38,
      "hex":"#c78726",
      "file_location":"assets/colors_organized/hue_04/brightness_13/2020040414320601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":13,
      "vividness":13,
      "r":199,
      "g":130,
      "b":26,
      "hex":"#c7821a",
      "file_location":"assets/colors_organized/hue_04/brightness_13/2020040414320700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":13,
      "vividness":14,
      "r":198,
      "g":125,
      "b":12,
      "hex":"#c67d0c",
      "file_location":"assets/colors_organized/hue_04/brightness_13/2020040414320701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":14,
      "vividness":1,
      "r":215,
      "g":208,
      "b":200,
      "hex":"#d7d0c8",
      "file_location":"assets/colors_organized/hue_04/brightness_14/2020040414313900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":14,
      "vividness":2,
      "r":215,
      "g":204,
      "b":186,
      "hex":"#d7ccba",
      "file_location":"assets/colors_organized/hue_04/brightness_14/2020040414313901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":14,
      "vividness":3,
      "r":215,
      "g":198,
      "b":172,
      "hex":"#d7c6ac",
      "file_location":"assets/colors_organized/hue_04/brightness_14/2020040414314000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":14,
      "vividness":4,
      "r":214,
      "g":191,
      "b":157,
      "hex":"#d6bf9d",
      "file_location":"assets/colors_organized/hue_04/brightness_14/2020040414314001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":14,
      "vividness":5,
      "r":214,
      "g":185,
      "b":143,
      "hex":"#d6b98f",
      "file_location":"assets/colors_organized/hue_04/brightness_14/2020040414314100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":14,
      "vividness":6,
      "r":213,
      "g":180,
      "b":127,
      "hex":"#d5b47f",
      "file_location":"assets/colors_organized/hue_04/brightness_14/2020040414314101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":14,
      "vividness":7,
      "r":214,
      "g":174,
      "b":113,
      "hex":"#d6ae71",
      "file_location":"assets/colors_organized/hue_04/brightness_14/2020040414314200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":14,
      "vividness":8,
      "r":215,
      "g":168,
      "b":100,
      "hex":"#d7a864",
      "file_location":"assets/colors_organized/hue_04/brightness_14/2020040414314201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":14,
      "vividness":9,
      "r":215,
      "g":164,
      "b":85,
      "hex":"#d7a455",
      "file_location":"assets/colors_organized/hue_04/brightness_14/2020040414314300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":14,
      "vividness":10,
      "r":214,
      "g":157,
      "b":70,
      "hex":"#d69d46",
      "file_location":"assets/colors_organized/hue_04/brightness_14/2020040414314301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":14,
      "vividness":11,
      "r":214,
      "g":151,
      "b":56,
      "hex":"#d69738",
      "file_location":"assets/colors_organized/hue_04/brightness_14/2020040414314400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":14,
      "vividness":12,
      "r":214,
      "g":145,
      "b":41,
      "hex":"#d69129",
      "file_location":"assets/colors_organized/hue_04/brightness_14/2020040414314401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":14,
      "vividness":13,
      "r":214,
      "g":141,
      "b":28,
      "hex":"#d68d1c",
      "file_location":"assets/colors_organized/hue_04/brightness_14/2020040414314500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":14,
      "vividness":14,
      "r":214,
      "g":134,
      "b":13,
      "hex":"#d6860d",
      "file_location":"assets/colors_organized/hue_04/brightness_14/2020040414314501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":15,
      "vividness":1,
      "r":230,
      "g":223,
      "b":213,
      "hex":"#e6dfd5",
      "file_location":"assets/colors_organized/hue_04/brightness_15/2020040414312600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":15,
      "vividness":2,
      "r":229,
      "g":218,
      "b":198,
      "hex":"#e5dac6",
      "file_location":"assets/colors_organized/hue_04/brightness_15/2020040414312601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":15,
      "vividness":3,
      "r":229,
      "g":212,
      "b":184,
      "hex":"#e5d4b8",
      "file_location":"assets/colors_organized/hue_04/brightness_15/2020040414312700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":15,
      "vividness":4,
      "r":229,
      "g":205,
      "b":167,
      "hex":"#e5cda7",
      "file_location":"assets/colors_organized/hue_04/brightness_15/2020040414312701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":15,
      "vividness":5,
      "r":229,
      "g":198,
      "b":151,
      "hex":"#e5c697",
      "file_location":"assets/colors_organized/hue_04/brightness_15/2020040414312800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":15,
      "vividness":6,
      "r":229,
      "g":192,
      "b":137,
      "hex":"#e5c089",
      "file_location":"assets/colors_organized/hue_04/brightness_15/2020040414312900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":15,
      "vividness":7,
      "r":230,
      "g":186,
      "b":121,
      "hex":"#e6ba79",
      "file_location":"assets/colors_organized/hue_04/brightness_15/2020040414312901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":15,
      "vividness":8,
      "r":229,
      "g":181,
      "b":107,
      "hex":"#e5b56b",
      "file_location":"assets/colors_organized/hue_04/brightness_15/2020040414313000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":15,
      "vividness":9,
      "r":229,
      "g":174,
      "b":91,
      "hex":"#e5ae5b",
      "file_location":"assets/colors_organized/hue_04/brightness_15/2020040414313001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":15,
      "vividness":10,
      "r":229,
      "g":168,
      "b":75,
      "hex":"#e5a84b",
      "file_location":"assets/colors_organized/hue_04/brightness_15/2020040414313100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":15,
      "vividness":11,
      "r":230,
      "g":162,
      "b":61,
      "hex":"#e6a23d",
      "file_location":"assets/colors_organized/hue_04/brightness_15/2020040414313200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":15,
      "vividness":12,
      "r":229,
      "g":156,
      "b":45,
      "hex":"#e59c2d",
      "file_location":"assets/colors_organized/hue_04/brightness_15/2020040414313201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":15,
      "vividness":13,
      "r":230,
      "g":149,
      "b":31,
      "hex":"#e6951f",
      "file_location":"assets/colors_organized/hue_04/brightness_15/2020040414313300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":4,
      "brightness":15,
      "vividness":14,
      "r":230,
      "g":143,
      "b":14,
      "hex":"#e68f0e",
      "file_location":"assets/colors_organized/hue_04/brightness_15/2020040414313301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":1,
      "vividness":1,
      "r":19,
      "g":20,
      "b":14,
      "hex":"#13140e",
      "file_location":"assets/colors_organized/hue_05/brightness_01/2020040414383600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":1,
      "vividness":2,
      "r":19,
      "g":20,
      "b":12,
      "hex":"#13140c",
      "file_location":"assets/colors_organized/hue_05/brightness_01/2020040414383601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":1,
      "vividness":3,
      "r":20,
      "g":17,
      "b":10,
      "hex":"#14110a",
      "file_location":"assets/colors_organized/hue_05/brightness_01/2020040414383800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":1,
      "vividness":4,
      "r":20,
      "g":18,
      "b":6,
      "hex":"#141206",
      "file_location":"assets/colors_organized/hue_05/brightness_01/2020040414383900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":1,
      "vividness":5,
      "r":19,
      "g":17,
      "b":2,
      "hex":"#131102",
      "file_location":"assets/colors_organized/hue_05/brightness_01/2020040414384001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":1,
      "vividness":6,
      "r":19,
      "g":15,
      "b":3,
      "hex":"#130f03",
      "file_location":"assets/colors_organized/hue_05/brightness_01/2020040414384100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":1,
      "vividness":7,
      "r":20,
      "g":14,
      "b":0,
      "hex":"#140e00",
      "file_location":"assets/colors_organized/hue_05/brightness_01/2020040414384200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":2,
      "vividness":1,
      "r":34,
      "g":33,
      "b":28,
      "hex":"#22211c",
      "file_location":"assets/colors_organized/hue_05/brightness_02/2020040414382301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":2,
      "vividness":2,
      "r":34,
      "g":34,
      "b":24,
      "hex":"#222218",
      "file_location":"assets/colors_organized/hue_05/brightness_02/2020040414382400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":2,
      "vividness":3,
      "r":34,
      "g":31,
      "b":22,
      "hex":"#221f16",
      "file_location":"assets/colors_organized/hue_05/brightness_02/2020040414382401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":2,
      "vividness":4,
      "r":34,
      "g":32,
      "b":20,
      "hex":"#222014",
      "file_location":"assets/colors_organized/hue_05/brightness_02/2020040414382500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":2,
      "vividness":5,
      "r":35,
      "g":32,
      "b":17,
      "hex":"#232011",
      "file_location":"assets/colors_organized/hue_05/brightness_02/2020040414382501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":2,
      "vividness":6,
      "r":34,
      "g":31,
      "b":14,
      "hex":"#221f0e",
      "file_location":"assets/colors_organized/hue_05/brightness_02/2020040414382600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":2,
      "vividness":7,
      "r":34,
      "g":31,
      "b":12,
      "hex":"#221f0c",
      "file_location":"assets/colors_organized/hue_05/brightness_02/2020040414382700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":2,
      "vividness":8,
      "r":35,
      "g":30,
      "b":10,
      "hex":"#231e0a",
      "file_location":"assets/colors_organized/hue_05/brightness_02/2020040414382701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":2,
      "vividness":9,
      "r":34,
      "g":29,
      "b":7,
      "hex":"#221d07",
      "file_location":"assets/colors_organized/hue_05/brightness_02/2020040414382801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":2,
      "vividness":10,
      "r":35,
      "g":29,
      "b":3,
      "hex":"#231d03",
      "file_location":"assets/colors_organized/hue_05/brightness_02/2020040414382900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":2,
      "vividness":11,
      "r":34,
      "g":28,
      "b":2,
      "hex":"#221c02",
      "file_location":"assets/colors_organized/hue_05/brightness_02/2020040414382901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":3,
      "vividness":1,
      "r":50,
      "g":49,
      "b":45,
      "hex":"#32312d",
      "file_location":"assets/colors_organized/hue_05/brightness_03/2020040414381001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":3,
      "vividness":2,
      "r":49,
      "g":48,
      "b":43,
      "hex":"#31302b",
      "file_location":"assets/colors_organized/hue_05/brightness_03/2020040414381100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":3,
      "vividness":3,
      "r":50,
      "g":47,
      "b":40,
      "hex":"#322f28",
      "file_location":"assets/colors_organized/hue_05/brightness_03/2020040414381101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":3,
      "vividness":4,
      "r":49,
      "g":47,
      "b":35,
      "hex":"#312f23",
      "file_location":"assets/colors_organized/hue_05/brightness_03/2020040414381200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":3,
      "vividness":5,
      "r":49,
      "g":45,
      "b":33,
      "hex":"#312d21",
      "file_location":"assets/colors_organized/hue_05/brightness_03/2020040414381201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":3,
      "vividness":6,
      "r":49,
      "g":46,
      "b":29,
      "hex":"#312e1d",
      "file_location":"assets/colors_organized/hue_05/brightness_03/2020040414381300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":3,
      "vividness":7,
      "r":50,
      "g":45,
      "b":26,
      "hex":"#322d1a",
      "file_location":"assets/colors_organized/hue_05/brightness_03/2020040414381301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":3,
      "vividness":8,
      "r":50,
      "g":44,
      "b":22,
      "hex":"#322c16",
      "file_location":"assets/colors_organized/hue_05/brightness_03/2020040414381400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":3,
      "vividness":9,
      "r":50,
      "g":44,
      "b":20,
      "hex":"#322c14",
      "file_location":"assets/colors_organized/hue_05/brightness_03/2020040414381401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":3,
      "vividness":10,
      "r":49,
      "g":42,
      "b":14,
      "hex":"#312a0e",
      "file_location":"assets/colors_organized/hue_05/brightness_03/2020040414381500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":3,
      "vividness":11,
      "r":49,
      "g":42,
      "b":13,
      "hex":"#312a0d",
      "file_location":"assets/colors_organized/hue_05/brightness_03/2020040414381501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":3,
      "vividness":12,
      "r":48,
      "g":42,
      "b":10,
      "hex":"#302a0a",
      "file_location":"assets/colors_organized/hue_05/brightness_03/2020040414381600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":3,
      "vividness":13,
      "r":49,
      "g":41,
      "b":5,
      "hex":"#312905",
      "file_location":"assets/colors_organized/hue_05/brightness_03/2020040414381601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":3,
      "vividness":14,
      "r":49,
      "g":39,
      "b":3,
      "hex":"#312703",
      "file_location":"assets/colors_organized/hue_05/brightness_03/2020040414381700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":4,
      "vividness":1,
      "r":65,
      "g":64,
      "b":60,
      "hex":"#41403c",
      "file_location":"assets/colors_organized/hue_05/brightness_04/2020040414375801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":4,
      "vividness":2,
      "r":65,
      "g":62,
      "b":55,
      "hex":"#413e37",
      "file_location":"assets/colors_organized/hue_05/brightness_04/2020040414375900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":4,
      "vividness":3,
      "r":64,
      "g":62,
      "b":50,
      "hex":"#403e32",
      "file_location":"assets/colors_organized/hue_05/brightness_04/2020040414375901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":4,
      "vividness":4,
      "r":64,
      "g":61,
      "b":46,
      "hex":"#403d2e",
      "file_location":"assets/colors_organized/hue_05/brightness_04/2020040414380000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":4,
      "vividness":5,
      "r":65,
      "g":60,
      "b":41,
      "hex":"#413c29",
      "file_location":"assets/colors_organized/hue_05/brightness_04/2020040414380001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":4,
      "vividness":6,
      "r":65,
      "g":58,
      "b":39,
      "hex":"#413a27",
      "file_location":"assets/colors_organized/hue_05/brightness_04/2020040414380100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":4,
      "vividness":7,
      "r":65,
      "g":59,
      "b":33,
      "hex":"#413b21",
      "file_location":"assets/colors_organized/hue_05/brightness_04/2020040414380101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":4,
      "vividness":8,
      "r":65,
      "g":58,
      "b":30,
      "hex":"#413a1e",
      "file_location":"assets/colors_organized/hue_05/brightness_04/2020040414380200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":4,
      "vividness":9,
      "r":64,
      "g":58,
      "b":26,
      "hex":"#403a1a",
      "file_location":"assets/colors_organized/hue_05/brightness_04/2020040414380201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":4,
      "vividness":10,
      "r":64,
      "g":56,
      "b":20,
      "hex":"#403814",
      "file_location":"assets/colors_organized/hue_05/brightness_04/2020040414380300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":4,
      "vividness":11,
      "r":64,
      "g":54,
      "b":18,
      "hex":"#403612",
      "file_location":"assets/colors_organized/hue_05/brightness_04/2020040414380301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":4,
      "vividness":12,
      "r":65,
      "g":53,
      "b":11,
      "hex":"#41350b",
      "file_location":"assets/colors_organized/hue_05/brightness_04/2020040414380400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":4,
      "vividness":13,
      "r":65,
      "g":52,
      "b":7,
      "hex":"#413407",
      "file_location":"assets/colors_organized/hue_05/brightness_04/2020040414380401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":4,
      "vividness":14,
      "r":65,
      "g":53,
      "b":3,
      "hex":"#413503",
      "file_location":"assets/colors_organized/hue_05/brightness_04/2020040414380500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":5,
      "vividness":1,
      "r":80,
      "g":79,
      "b":74,
      "hex":"#504f4a",
      "file_location":"assets/colors_organized/hue_05/brightness_05/2020040414374501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":5,
      "vividness":2,
      "r":80,
      "g":77,
      "b":68,
      "hex":"#504d44",
      "file_location":"assets/colors_organized/hue_05/brightness_05/2020040414374600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":5,
      "vividness":3,
      "r":80,
      "g":76,
      "b":64,
      "hex":"#504c40",
      "file_location":"assets/colors_organized/hue_05/brightness_05/2020040414374601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":5,
      "vividness":4,
      "r":80,
      "g":75,
      "b":56,
      "hex":"#504b38",
      "file_location":"assets/colors_organized/hue_05/brightness_05/2020040414374700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":5,
      "vividness":5,
      "r":80,
      "g":74,
      "b":52,
      "hex":"#504a34",
      "file_location":"assets/colors_organized/hue_05/brightness_05/2020040414374800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":5,
      "vividness":6,
      "r":79,
      "g":73,
      "b":47,
      "hex":"#4f492f",
      "file_location":"assets/colors_organized/hue_05/brightness_05/2020040414374801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":5,
      "vividness":7,
      "r":79,
      "g":73,
      "b":41,
      "hex":"#4f4929",
      "file_location":"assets/colors_organized/hue_05/brightness_05/2020040414374900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":5,
      "vividness":8,
      "r":80,
      "g":72,
      "b":36,
      "hex":"#504824",
      "file_location":"assets/colors_organized/hue_05/brightness_05/2020040414374901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":5,
      "vividness":9,
      "r":79,
      "g":70,
      "b":31,
      "hex":"#4f461f",
      "file_location":"assets/colors_organized/hue_05/brightness_05/2020040414375000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":5,
      "vividness":10,
      "r":80,
      "g":68,
      "b":26,
      "hex":"#50441a",
      "file_location":"assets/colors_organized/hue_05/brightness_05/2020040414375001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":5,
      "vividness":11,
      "r":80,
      "g":67,
      "b":22,
      "hex":"#504316",
      "file_location":"assets/colors_organized/hue_05/brightness_05/2020040414375100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":5,
      "vividness":12,
      "r":80,
      "g":67,
      "b":15,
      "hex":"#50430f",
      "file_location":"assets/colors_organized/hue_05/brightness_05/2020040414375101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":5,
      "vividness":13,
      "r":79,
      "g":67,
      "b":9,
      "hex":"#4f4309",
      "file_location":"assets/colors_organized/hue_05/brightness_05/2020040414375200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":5,
      "vividness":14,
      "r":80,
      "g":65,
      "b":6,
      "hex":"#504106",
      "file_location":"assets/colors_organized/hue_05/brightness_05/2020040414375201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":6,
      "vividness":1,
      "r":94,
      "g":93,
      "b":88,
      "hex":"#5e5d58",
      "file_location":"assets/colors_organized/hue_05/brightness_06/2020040414373300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":6,
      "vividness":2,
      "r":95,
      "g":93,
      "b":81,
      "hex":"#5f5d51",
      "file_location":"assets/colors_organized/hue_05/brightness_06/2020040414373301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":6,
      "vividness":3,
      "r":94,
      "g":91,
      "b":76,
      "hex":"#5e5b4c",
      "file_location":"assets/colors_organized/hue_05/brightness_06/2020040414373400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":6,
      "vividness":4,
      "r":95,
      "g":90,
      "b":70,
      "hex":"#5f5a46",
      "file_location":"assets/colors_organized/hue_05/brightness_06/2020040414373401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":6,
      "vividness":5,
      "r":94,
      "g":88,
      "b":62,
      "hex":"#5e583e",
      "file_location":"assets/colors_organized/hue_05/brightness_06/2020040414373500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":6,
      "vividness":6,
      "r":94,
      "g":88,
      "b":56,
      "hex":"#5e5838",
      "file_location":"assets/colors_organized/hue_05/brightness_06/2020040414373600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":6,
      "vividness":7,
      "r":94,
      "g":86,
      "b":49,
      "hex":"#5e5631",
      "file_location":"assets/colors_organized/hue_05/brightness_06/2020040414373601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":6,
      "vividness":8,
      "r":96,
      "g":84,
      "b":44,
      "hex":"#60542c",
      "file_location":"assets/colors_organized/hue_05/brightness_06/2020040414373700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":6,
      "vividness":9,
      "r":95,
      "g":82,
      "b":37,
      "hex":"#5f5225",
      "file_location":"assets/colors_organized/hue_05/brightness_06/2020040414373701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":6,
      "vividness":10,
      "r":95,
      "g":82,
      "b":30,
      "hex":"#5f521e",
      "file_location":"assets/colors_organized/hue_05/brightness_06/2020040414373800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":6,
      "vividness":11,
      "r":94,
      "g":82,
      "b":24,
      "hex":"#5e5218",
      "file_location":"assets/colors_organized/hue_05/brightness_06/2020040414373801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":6,
      "vividness":12,
      "r":95,
      "g":79,
      "b":20,
      "hex":"#5f4f14",
      "file_location":"assets/colors_organized/hue_05/brightness_06/2020040414373900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":6,
      "vividness":13,
      "r":95,
      "g":77,
      "b":11,
      "hex":"#5f4d0b",
      "file_location":"assets/colors_organized/hue_05/brightness_06/2020040414373901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":6,
      "vividness":14,
      "r":95,
      "g":76,
      "b":7,
      "hex":"#5f4c07",
      "file_location":"assets/colors_organized/hue_05/brightness_06/2020040414374000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":7,
      "vividness":1,
      "r":109,
      "g":108,
      "b":103,
      "hex":"#6d6c67",
      "file_location":"assets/colors_organized/hue_05/brightness_07/2020040414365701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":7,
      "vividness":2,
      "r":109,
      "g":107,
      "b":94,
      "hex":"#6d6b5e",
      "file_location":"assets/colors_organized/hue_05/brightness_07/2020040414365800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":7,
      "vividness":3,
      "r":110,
      "g":104,
      "b":88,
      "hex":"#6e6858",
      "file_location":"assets/colors_organized/hue_05/brightness_07/2020040414365801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":7,
      "vividness":4,
      "r":110,
      "g":104,
      "b":80,
      "hex":"#6e6850",
      "file_location":"assets/colors_organized/hue_05/brightness_07/2020040414365900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":7,
      "vividness":5,
      "r":109,
      "g":102,
      "b":73,
      "hex":"#6d6649",
      "file_location":"assets/colors_organized/hue_05/brightness_07/2020040414365901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":7,
      "vividness":6,
      "r":110,
      "g":102,
      "b":66,
      "hex":"#6e6642",
      "file_location":"assets/colors_organized/hue_05/brightness_07/2020040414370000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":7,
      "vividness":7,
      "r":110,
      "g":98,
      "b":56,
      "hex":"#6e6238",
      "file_location":"assets/colors_organized/hue_05/brightness_07/2020040414370001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":7,
      "vividness":8,
      "r":110,
      "g":98,
      "b":50,
      "hex":"#6e6232",
      "file_location":"assets/colors_organized/hue_05/brightness_07/2020040414370100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":7,
      "vividness":9,
      "r":109,
      "g":96,
      "b":43,
      "hex":"#6d602b",
      "file_location":"assets/colors_organized/hue_05/brightness_07/2020040414370101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":7,
      "vividness":10,
      "r":110,
      "g":95,
      "b":36,
      "hex":"#6e5f24",
      "file_location":"assets/colors_organized/hue_05/brightness_07/2020040414370200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":7,
      "vividness":11,
      "r":111,
      "g":93,
      "b":29,
      "hex":"#6f5d1d",
      "file_location":"assets/colors_organized/hue_05/brightness_07/2020040414370201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":7,
      "vividness":12,
      "r":110,
      "g":91,
      "b":22,
      "hex":"#6e5b16",
      "file_location":"assets/colors_organized/hue_05/brightness_07/2020040414370300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":7,
      "vividness":13,
      "r":109,
      "g":90,
      "b":13,
      "hex":"#6d5a0d",
      "file_location":"assets/colors_organized/hue_05/brightness_07/2020040414372700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":7,
      "vividness":14,
      "r":110,
      "g":89,
      "b":6,
      "hex":"#6e5906",
      "file_location":"assets/colors_organized/hue_05/brightness_07/xxx.jpg"
   },
   {
      "hue":5,
      "brightness":8,
      "vividness":1,
      "r":123,
      "g":123,
      "b":115,
      "hex":"#7b7b73",
      "file_location":"assets/colors_organized/hue_05/brightness_08/2020040414364701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":8,
      "vividness":2,
      "r":123,
      "g":121,
      "b":108,
      "hex":"#7b796c",
      "file_location":"assets/colors_organized/hue_05/brightness_08/2020040414364800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":8,
      "vividness":3,
      "r":124,
      "g":119,
      "b":99,
      "hex":"#7c7763",
      "file_location":"assets/colors_organized/hue_05/brightness_08/2020040414364801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":8,
      "vividness":4,
      "r":124,
      "g":119,
      "b":90,
      "hex":"#7c775a",
      "file_location":"assets/colors_organized/hue_05/brightness_08/2020040414364900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":8,
      "vividness":5,
      "r":124,
      "g":115,
      "b":82,
      "hex":"#7c7352",
      "file_location":"assets/colors_organized/hue_05/brightness_08/2020040414364901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":8,
      "vividness":6,
      "r":123,
      "g":114,
      "b":73,
      "hex":"#7b7249",
      "file_location":"assets/colors_organized/hue_05/brightness_08/2020040414365000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":8,
      "vividness":7,
      "r":124,
      "g":114,
      "b":65,
      "hex":"#7c7241",
      "file_location":"assets/colors_organized/hue_05/brightness_08/2020040414365001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":8,
      "vividness":8,
      "r":124,
      "g":111,
      "b":58,
      "hex":"#7c6f3a",
      "file_location":"assets/colors_organized/hue_05/brightness_08/2020040414365100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":8,
      "vividness":9,
      "r":123,
      "g":109,
      "b":48,
      "hex":"#7b6d30",
      "file_location":"assets/colors_organized/hue_05/brightness_08/2020040414365101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":8,
      "vividness":10,
      "r":123,
      "g":108,
      "b":41,
      "hex":"#7b6c29",
      "file_location":"assets/colors_organized/hue_05/brightness_08/2020040414365200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":8,
      "vividness":11,
      "r":124,
      "g":106,
      "b":34,
      "hex":"#7c6a22",
      "file_location":"assets/colors_organized/hue_05/brightness_08/2020040414365201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":8,
      "vividness":12,
      "r":123,
      "g":105,
      "b":23,
      "hex":"#7b6917",
      "file_location":"assets/colors_organized/hue_05/brightness_08/2020040414365300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":8,
      "vividness":13,
      "r":124,
      "g":103,
      "b":14,
      "hex":"#7c670e",
      "file_location":"assets/colors_organized/hue_05/brightness_08/2020040414365301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":8,
      "vividness":14,
      "r":124,
      "g":101,
      "b":7,
      "hex":"#7c6507",
      "file_location":"assets/colors_organized/hue_05/brightness_08/2020040414365400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":9,
      "vividness":1,
      "r":139,
      "g":139,
      "b":131,
      "hex":"#8b8b83",
      "file_location":"assets/colors_organized/hue_05/brightness_09/2020040414363701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":9,
      "vividness":2,
      "r":139,
      "g":136,
      "b":121,
      "hex":"#8b8879",
      "file_location":"assets/colors_organized/hue_05/brightness_09/2020040414363800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":9,
      "vividness":3,
      "r":139,
      "g":135,
      "b":110,
      "hex":"#8b876e",
      "file_location":"assets/colors_organized/hue_05/brightness_09/2020040414363801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":9,
      "vividness":4,
      "r":139,
      "g":132,
      "b":103,
      "hex":"#8b8467",
      "file_location":"assets/colors_organized/hue_05/brightness_09/2020040414363900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":9,
      "vividness":5,
      "r":139,
      "g":131,
      "b":94,
      "hex":"#8b835e",
      "file_location":"assets/colors_organized/hue_05/brightness_09/2020040414363901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":9,
      "vividness":6,
      "r":139,
      "g":128,
      "b":82,
      "hex":"#8b8052",
      "file_location":"assets/colors_organized/hue_05/brightness_09/2020040414363902-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":9,
      "vividness":7,
      "r":139,
      "g":126,
      "b":74,
      "hex":"#8b7e4a",
      "file_location":"assets/colors_organized/hue_05/brightness_09/2020040414364000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":9,
      "vividness":8,
      "r":139,
      "g":124,
      "b":65,
      "hex":"#8b7c41",
      "file_location":"assets/colors_organized/hue_05/brightness_09/2020040414364001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":9,
      "vividness":9,
      "r":140,
      "g":123,
      "b":54,
      "hex":"#8c7b36",
      "file_location":"assets/colors_organized/hue_05/brightness_09/2020040414364100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":9,
      "vividness":10,
      "r":139,
      "g":121,
      "b":45,
      "hex":"#8b792d",
      "file_location":"assets/colors_organized/hue_05/brightness_09/2020040414364200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":9,
      "vividness":11,
      "r":140,
      "g":119,
      "b":36,
      "hex":"#8c7724",
      "file_location":"assets/colors_organized/hue_05/brightness_09/2020040414364201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":9,
      "vividness":12,
      "r":138,
      "g":117,
      "b":26,
      "hex":"#8a751a",
      "file_location":"assets/colors_organized/hue_05/brightness_09/2020040414364300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":9,
      "vividness":13,
      "r":139,
      "g":115,
      "b":17,
      "hex":"#8b7311",
      "file_location":"assets/colors_organized/hue_05/brightness_09/2020040414364301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":9,
      "vividness":14,
      "r":138,
      "g":113,
      "b":10,
      "hex":"#8a710a",
      "file_location":"assets/colors_organized/hue_05/brightness_09/2020040414364302-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":10,
      "vividness":1,
      "r":155,
      "g":152,
      "b":145,
      "hex":"#9b9891",
      "file_location":"assets/colors_organized/hue_05/brightness_10/2020040414362601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":10,
      "vividness":2,
      "r":154,
      "g":151,
      "b":134,
      "hex":"#9a9786",
      "file_location":"assets/colors_organized/hue_05/brightness_10/2020040414362700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":10,
      "vividness":3,
      "r":154,
      "g":148,
      "b":122,
      "hex":"#9a947a",
      "file_location":"assets/colors_organized/hue_05/brightness_10/2020040414362800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":10,
      "vividness":4,
      "r":155,
      "g":146,
      "b":113,
      "hex":"#9b9271",
      "file_location":"assets/colors_organized/hue_05/brightness_10/2020040414362801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":10,
      "vividness":5,
      "r":153,
      "g":144,
      "b":103,
      "hex":"#999067",
      "file_location":"assets/colors_organized/hue_05/brightness_10/2020040414362900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":10,
      "vividness":6,
      "r":154,
      "g":142,
      "b":92,
      "hex":"#9a8e5c",
      "file_location":"assets/colors_organized/hue_05/brightness_10/2020040414362901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":10,
      "vividness":7,
      "r":155,
      "g":140,
      "b":81,
      "hex":"#9b8c51",
      "file_location":"assets/colors_organized/hue_05/brightness_10/2020040414363000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":10,
      "vividness":8,
      "r":153,
      "g":138,
      "b":71,
      "hex":"#998a47",
      "file_location":"assets/colors_organized/hue_05/brightness_10/2020040414363001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":10,
      "vividness":9,
      "r":154,
      "g":136,
      "b":60,
      "hex":"#9a883c",
      "file_location":"assets/colors_organized/hue_05/brightness_10/2020040414363100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":10,
      "vividness":10,
      "r":155,
      "g":134,
      "b":51,
      "hex":"#9b8633",
      "file_location":"assets/colors_organized/hue_05/brightness_10/2020040414363101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":10,
      "vividness":11,
      "r":153,
      "g":132,
      "b":39,
      "hex":"#998427",
      "file_location":"assets/colors_organized/hue_05/brightness_10/2020040414363200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":10,
      "vividness":12,
      "r":154,
      "g":130,
      "b":30,
      "hex":"#9a821e",
      "file_location":"assets/colors_organized/hue_05/brightness_10/2020040414363201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":10,
      "vividness":13,
      "r":154,
      "g":127,
      "b":20,
      "hex":"#9a7f14",
      "file_location":"assets/colors_organized/hue_05/brightness_10/2020040414363300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":10,
      "vividness":14,
      "r":154,
      "g":125,
      "b":8,
      "hex":"#9a7d08",
      "file_location":"assets/colors_organized/hue_05/brightness_10/2020040414363301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":11,
      "vividness":1,
      "r":170,
      "g":167,
      "b":158,
      "hex":"#aaa79e",
      "file_location":"assets/colors_organized/hue_05/brightness_11/2020040414361501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":11,
      "vividness":2,
      "r":168,
      "g":165,
      "b":146,
      "hex":"#a8a592",
      "file_location":"assets/colors_organized/hue_05/brightness_11/2020040414361600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":11,
      "vividness":3,
      "r":169,
      "g":162,
      "b":134,
      "hex":"#a9a286",
      "file_location":"assets/colors_organized/hue_05/brightness_11/2020040414361601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":11,
      "vividness":4,
      "r":169,
      "g":161,
      "b":124,
      "hex":"#a9a17c",
      "file_location":"assets/colors_organized/hue_05/brightness_11/2020040414361700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":11,
      "vividness":5,
      "r":169,
      "g":158,
      "b":112,
      "hex":"#a99e70",
      "file_location":"assets/colors_organized/hue_05/brightness_11/2020040414361701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":11,
      "vividness":6,
      "r":169,
      "g":156,
      "b":101,
      "hex":"#a99c65",
      "file_location":"assets/colors_organized/hue_05/brightness_11/2020040414361800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":11,
      "vividness":7,
      "r":169,
      "g":153,
      "b":91,
      "hex":"#a9995b",
      "file_location":"assets/colors_organized/hue_05/brightness_11/2020040414361801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":11,
      "vividness":8,
      "r":169,
      "g":151,
      "b":79,
      "hex":"#a9974f",
      "file_location":"assets/colors_organized/hue_05/brightness_11/2020040414361900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":11,
      "vividness":9,
      "r":170,
      "g":149,
      "b":66,
      "hex":"#aa9542",
      "file_location":"assets/colors_organized/hue_05/brightness_11/2020040414361901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":11,
      "vividness":10,
      "r":168,
      "g":147,
      "b":54,
      "hex":"#a89336",
      "file_location":"assets/colors_organized/hue_05/brightness_11/2020040414362000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":11,
      "vividness":11,
      "r":168,
      "g":144,
      "b":44,
      "hex":"#a8902c",
      "file_location":"assets/colors_organized/hue_05/brightness_11/2020040414362001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":11,
      "vividness":12,
      "r":169,
      "g":143,
      "b":33,
      "hex":"#a98f21",
      "file_location":"assets/colors_organized/hue_05/brightness_11/2020040414362100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":11,
      "vividness":13,
      "r":169,
      "g":140,
      "b":22,
      "hex":"#a98c16",
      "file_location":"assets/colors_organized/hue_05/brightness_11/2020040414362200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":11,
      "vividness":14,
      "r":169,
      "g":139,
      "b":9,
      "hex":"#a98b09",
      "file_location":"assets/colors_organized/hue_05/brightness_11/2020040414362201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":12,
      "vividness":1,
      "r":185,
      "g":182,
      "b":173,
      "hex":"#b9b6ad",
      "file_location":"assets/colors_organized/hue_05/brightness_12/2020040414360500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":12,
      "vividness":2,
      "r":184,
      "g":179,
      "b":160,
      "hex":"#b8b3a0",
      "file_location":"assets/colors_organized/hue_05/brightness_12/2020040414360501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":12,
      "vividness":3,
      "r":184,
      "g":177,
      "b":148,
      "hex":"#b8b194",
      "file_location":"assets/colors_organized/hue_05/brightness_12/2020040414360600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":12,
      "vividness":4,
      "r":184,
      "g":175,
      "b":136,
      "hex":"#b8af88",
      "file_location":"assets/colors_organized/hue_05/brightness_12/2020040414360601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":12,
      "vividness":5,
      "r":184,
      "g":172,
      "b":122,
      "hex":"#b8ac7a",
      "file_location":"assets/colors_organized/hue_05/brightness_12/2020040414360700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":12,
      "vividness":6,
      "r":184,
      "g":169,
      "b":110,
      "hex":"#b8a96e",
      "file_location":"assets/colors_organized/hue_05/brightness_12/2020040414360701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":12,
      "vividness":7,
      "r":184,
      "g":167,
      "b":97,
      "hex":"#b8a761",
      "file_location":"assets/colors_organized/hue_05/brightness_12/2020040414360800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":12,
      "vividness":8,
      "r":183,
      "g":164,
      "b":85,
      "hex":"#b7a455",
      "file_location":"assets/colors_organized/hue_05/brightness_12/2020040414360801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":12,
      "vividness":9,
      "r":184,
      "g":163,
      "b":72,
      "hex":"#b8a348",
      "file_location":"assets/colors_organized/hue_05/brightness_12/2020040414360900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":12,
      "vividness":10,
      "r":184,
      "g":160,
      "b":60,
      "hex":"#b8a03c",
      "file_location":"assets/colors_organized/hue_05/brightness_12/2020040414360901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":12,
      "vividness":11,
      "r":184,
      "g":158,
      "b":48,
      "hex":"#b89e30",
      "file_location":"assets/colors_organized/hue_05/brightness_12/2020040414361000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":12,
      "vividness":12,
      "r":184,
      "g":155,
      "b":35,
      "hex":"#b89b23",
      "file_location":"assets/colors_organized/hue_05/brightness_12/2020040414361001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":12,
      "vividness":13,
      "r":183,
      "g":153,
      "b":23,
      "hex":"#b79917",
      "file_location":"assets/colors_organized/hue_05/brightness_12/2020040414361100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":12,
      "vividness":14,
      "r":184,
      "g":151,
      "b":12,
      "hex":"#b8970c",
      "file_location":"assets/colors_organized/hue_05/brightness_12/2020040414361101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":13,
      "vividness":1,
      "r":199,
      "g":197,
      "b":185,
      "hex":"#c7c5b9",
      "file_location":"assets/colors_organized/hue_05/brightness_13/2020040414355500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":13,
      "vividness":2,
      "r":200,
      "g":195,
      "b":173,
      "hex":"#c8c3ad",
      "file_location":"assets/colors_organized/hue_05/brightness_13/2020040414355501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":13,
      "vividness":3,
      "r":200,
      "g":191,
      "b":158,
      "hex":"#c8bf9e",
      "file_location":"assets/colors_organized/hue_05/brightness_13/2020040414355502-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":13,
      "vividness":4,
      "r":200,
      "g":188,
      "b":146,
      "hex":"#c8bc92",
      "file_location":"assets/colors_organized/hue_05/brightness_13/2020040414355600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":13,
      "vividness":5,
      "r":199,
      "g":186,
      "b":133,
      "hex":"#c7ba85",
      "file_location":"assets/colors_organized/hue_05/brightness_13/2020040414355601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":13,
      "vividness":6,
      "r":199,
      "g":184,
      "b":119,
      "hex":"#c7b877",
      "file_location":"assets/colors_organized/hue_05/brightness_13/2020040414355700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":13,
      "vividness":7,
      "r":199,
      "g":180,
      "b":104,
      "hex":"#c7b468",
      "file_location":"assets/colors_organized/hue_05/brightness_13/2020040414355701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":13,
      "vividness":8,
      "r":199,
      "g":179,
      "b":93,
      "hex":"#c7b35d",
      "file_location":"assets/colors_organized/hue_05/brightness_13/2020040414355800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":13,
      "vividness":9,
      "r":199,
      "g":175,
      "b":79,
      "hex":"#c7af4f",
      "file_location":"assets/colors_organized/hue_05/brightness_13/2020040414355801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":13,
      "vividness":10,
      "r":200,
      "g":173,
      "b":66,
      "hex":"#c8ad42",
      "file_location":"assets/colors_organized/hue_05/brightness_13/2020040414355900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":13,
      "vividness":11,
      "r":200,
      "g":171,
      "b":53,
      "hex":"#c8ab35",
      "file_location":"assets/colors_organized/hue_05/brightness_13/2020040414355901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":13,
      "vividness":12,
      "r":198,
      "g":168,
      "b":38,
      "hex":"#c6a826",
      "file_location":"assets/colors_organized/hue_05/brightness_13/2020040414360000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":13,
      "vividness":13,
      "r":199,
      "g":166,
      "b":27,
      "hex":"#c7a61b",
      "file_location":"assets/colors_organized/hue_05/brightness_13/2020040414360001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":13,
      "vividness":14,
      "r":199,
      "g":162,
      "b":12,
      "hex":"#c7a20c",
      "file_location":"assets/colors_organized/hue_05/brightness_13/2020040414360100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":14,
      "vividness":1,
      "r":214,
      "g":212,
      "b":200,
      "hex":"#d6d4c8",
      "file_location":"assets/colors_organized/hue_05/brightness_14/2020040414354201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":14,
      "vividness":2,
      "r":215,
      "g":209,
      "b":185,
      "hex":"#d7d1b9",
      "file_location":"assets/colors_organized/hue_05/brightness_14/2020040414354300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":14,
      "vividness":3,
      "r":214,
      "g":206,
      "b":170,
      "hex":"#d6ceaa",
      "file_location":"assets/colors_organized/hue_05/brightness_14/2020040414354301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":14,
      "vividness":4,
      "r":214,
      "g":203,
      "b":157,
      "hex":"#d6cb9d",
      "file_location":"assets/colors_organized/hue_05/brightness_14/2020040414354400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":14,
      "vividness":5,
      "r":215,
      "g":200,
      "b":143,
      "hex":"#d7c88f",
      "file_location":"assets/colors_organized/hue_05/brightness_14/2020040414354401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":14,
      "vividness":6,
      "r":215,
      "g":198,
      "b":128,
      "hex":"#d7c680",
      "file_location":"assets/colors_organized/hue_05/brightness_14/2020040414354500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":14,
      "vividness":7,
      "r":213,
      "g":195,
      "b":113,
      "hex":"#d5c371",
      "file_location":"assets/colors_organized/hue_05/brightness_14/2020040414354501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":14,
      "vividness":8,
      "r":215,
      "g":192,
      "b":99,
      "hex":"#d7c063",
      "file_location":"assets/colors_organized/hue_05/brightness_14/2020040414354600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":14,
      "vividness":9,
      "r":213,
      "g":188,
      "b":85,
      "hex":"#d5bc55",
      "file_location":"assets/colors_organized/hue_05/brightness_14/2020040414354601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":14,
      "vividness":10,
      "r":215,
      "g":185,
      "b":71,
      "hex":"#d7b947",
      "file_location":"assets/colors_organized/hue_05/brightness_14/2020040414354602-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":14,
      "vividness":11,
      "r":214,
      "g":183,
      "b":56,
      "hex":"#d6b738",
      "file_location":"assets/colors_organized/hue_05/brightness_14/2020040414354700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":14,
      "vividness":12,
      "r":214,
      "g":181,
      "b":42,
      "hex":"#d6b52a",
      "file_location":"assets/colors_organized/hue_05/brightness_14/2020040414354701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":14,
      "vividness":13,
      "r":214,
      "g":177,
      "b":27,
      "hex":"#d6b11b",
      "file_location":"assets/colors_organized/hue_05/brightness_14/2020040414354800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":14,
      "vividness":14,
      "r":214,
      "g":175,
      "b":12,
      "hex":"#d6af0c",
      "file_location":"assets/colors_organized/hue_05/brightness_14/2020040414354801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":15,
      "vividness":1,
      "r":229,
      "g":227,
      "b":214,
      "hex":"#e5e3d6",
      "file_location":"assets/colors_organized/hue_05/brightness_15/2020040414353100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":15,
      "vividness":2,
      "r":229,
      "g":223,
      "b":197,
      "hex":"#e5dfc5",
      "file_location":"assets/colors_organized/hue_05/brightness_15/2020040414353101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":15,
      "vividness":3,
      "r":229,
      "g":221,
      "b":184,
      "hex":"#e5ddb8",
      "file_location":"assets/colors_organized/hue_05/brightness_15/2020040414353200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":15,
      "vividness":4,
      "r":229,
      "g":217,
      "b":167,
      "hex":"#e5d9a7",
      "file_location":"assets/colors_organized/hue_05/brightness_15/2020040414353201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":15,
      "vividness":5,
      "r":229,
      "g":215,
      "b":152,
      "hex":"#e5d798",
      "file_location":"assets/colors_organized/hue_05/brightness_15/2020040414353202-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":15,
      "vividness":6,
      "r":229,
      "g":211,
      "b":137,
      "hex":"#e5d389",
      "file_location":"assets/colors_organized/hue_05/brightness_15/2020040414353300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":15,
      "vividness":7,
      "r":229,
      "g":209,
      "b":122,
      "hex":"#e5d17a",
      "file_location":"assets/colors_organized/hue_05/brightness_15/2020040414353301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":15,
      "vividness":8,
      "r":229,
      "g":205,
      "b":107,
      "hex":"#e5cd6b",
      "file_location":"assets/colors_organized/hue_05/brightness_15/2020040414353400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":15,
      "vividness":9,
      "r":229,
      "g":201,
      "b":91,
      "hex":"#e5c95b",
      "file_location":"assets/colors_organized/hue_05/brightness_15/2020040414353401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":15,
      "vividness":10,
      "r":229,
      "g":199,
      "b":75,
      "hex":"#e5c74b",
      "file_location":"assets/colors_organized/hue_05/brightness_15/2020040414353500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":15,
      "vividness":11,
      "r":229,
      "g":195,
      "b":61,
      "hex":"#e5c33d",
      "file_location":"assets/colors_organized/hue_05/brightness_15/2020040414353501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":15,
      "vividness":12,
      "r":229,
      "g":193,
      "b":45,
      "hex":"#e5c12d",
      "file_location":"assets/colors_organized/hue_05/brightness_15/2020040414353600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":15,
      "vividness":13,
      "r":229,
      "g":189,
      "b":29,
      "hex":"#e5bd1d",
      "file_location":"assets/colors_organized/hue_05/brightness_15/2020040414353601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":5,
      "brightness":15,
      "vividness":14,
      "r":229,
      "g":187,
      "b":15,
      "hex":"#e5bb0f",
      "file_location":"assets/colors_organized/hue_05/brightness_15/2020040414353700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":1,
      "vividness":1,
      "r":19,
      "g":19,
      "b":9,
      "hex":"#131309",
      "file_location":"assets/colors_organized/hue_06/brightness_01/2020040414475301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":1,
      "vividness":2,
      "r":19,
      "g":20,
      "b":6,
      "hex":"#131406",
      "file_location":"assets/colors_organized/hue_06/brightness_01/2020040414475401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":1,
      "vividness":3,
      "r":19,
      "g":20,
      "b":2,
      "hex":"#131402",
      "file_location":"assets/colors_organized/hue_06/brightness_01/2020040414475601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":1,
      "vividness":4,
      "r":20,
      "g":19,
      "b":0,
      "hex":"#141300",
      "file_location":"assets/colors_organized/hue_06/brightness_01/2020040414475701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":2,
      "vividness":1,
      "r":34,
      "g":35,
      "b":30,
      "hex":"#22231e",
      "file_location":"assets/colors_organized/hue_06/brightness_02/2020040414474001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":2,
      "vividness":2,
      "r":34,
      "g":35,
      "b":29,
      "hex":"#22231d",
      "file_location":"assets/colors_organized/hue_06/brightness_02/2020040414474002-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":2,
      "vividness":3,
      "r":34,
      "g":35,
      "b":27,
      "hex":"#22231b",
      "file_location":"assets/colors_organized/hue_06/brightness_02/2020040414474100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":2,
      "vividness":4,
      "r":34,
      "g":34,
      "b":22,
      "hex":"#222216",
      "file_location":"assets/colors_organized/hue_06/brightness_02/2020040414474200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":2,
      "vividness":5,
      "r":34,
      "g":35,
      "b":21,
      "hex":"#222315",
      "file_location":"assets/colors_organized/hue_06/brightness_02/2020040414474201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":2,
      "vividness":6,
      "r":34,
      "g":35,
      "b":17,
      "hex":"#222311",
      "file_location":"assets/colors_organized/hue_06/brightness_02/2020040414474300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":2,
      "vividness":7,
      "r":35,
      "g":34,
      "b":14,
      "hex":"#23220e",
      "file_location":"assets/colors_organized/hue_06/brightness_02/2020040414474301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":2,
      "vividness":8,
      "r":35,
      "g":34,
      "b":13,
      "hex":"#23220d",
      "file_location":"assets/colors_organized/hue_06/brightness_02/2020040414474400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":2,
      "vividness":9,
      "r":35,
      "g":35,
      "b":9,
      "hex":"#232309",
      "file_location":"assets/colors_organized/hue_06/brightness_02/2020040414474401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":2,
      "vividness":10,
      "r":34,
      "g":34,
      "b":6,
      "hex":"#222206",
      "file_location":"assets/colors_organized/hue_06/brightness_02/2020040414474501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":2,
      "vividness":11,
      "r":34,
      "g":35,
      "b":3,
      "hex":"#222303",
      "file_location":"assets/colors_organized/hue_06/brightness_02/2020040414474600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":3,
      "vividness":1,
      "r":49,
      "g":50,
      "b":45,
      "hex":"#31322d",
      "file_location":"assets/colors_organized/hue_06/brightness_03/2020040414473000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":3,
      "vividness":2,
      "r":49,
      "g":50,
      "b":42,
      "hex":"#31322a",
      "file_location":"assets/colors_organized/hue_06/brightness_03/2020040414473001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":3,
      "vividness":3,
      "r":49,
      "g":49,
      "b":39,
      "hex":"#313127",
      "file_location":"assets/colors_organized/hue_06/brightness_03/2020040414473100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":3,
      "vividness":4,
      "r":49,
      "g":50,
      "b":36,
      "hex":"#313224",
      "file_location":"assets/colors_organized/hue_06/brightness_03/2020040414473101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":3,
      "vividness":5,
      "r":49,
      "g":50,
      "b":34,
      "hex":"#313222",
      "file_location":"assets/colors_organized/hue_06/brightness_03/2020040414473200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":3,
      "vividness":6,
      "r":50,
      "g":49,
      "b":29,
      "hex":"#32311d",
      "file_location":"assets/colors_organized/hue_06/brightness_03/2020040414473201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":3,
      "vividness":7,
      "r":50,
      "g":50,
      "b":26,
      "hex":"#32321a",
      "file_location":"assets/colors_organized/hue_06/brightness_03/2020040414473300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":3,
      "vividness":8,
      "r":49,
      "g":49,
      "b":21,
      "hex":"#313115",
      "file_location":"assets/colors_organized/hue_06/brightness_03/2020040414473301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":3,
      "vividness":9,
      "r":49,
      "g":50,
      "b":19,
      "hex":"#313213",
      "file_location":"assets/colors_organized/hue_06/brightness_03/2020040414473400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":3,
      "vividness":10,
      "r":50,
      "g":50,
      "b":14,
      "hex":"#32320e",
      "file_location":"assets/colors_organized/hue_06/brightness_03/2020040414473401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":3,
      "vividness":11,
      "r":49,
      "g":49,
      "b":11,
      "hex":"#31310b",
      "file_location":"assets/colors_organized/hue_06/brightness_03/2020040414473500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":3,
      "vividness":12,
      "r":49,
      "g":50,
      "b":10,
      "hex":"#31320a",
      "file_location":"assets/colors_organized/hue_06/brightness_03/2020040414473501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":3,
      "vividness":13,
      "r":49,
      "g":50,
      "b":6,
      "hex":"#313206",
      "file_location":"assets/colors_organized/hue_06/brightness_03/2020040414473502-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":3,
      "vividness":14,
      "r":50,
      "g":49,
      "b":1,
      "hex":"#323101",
      "file_location":"assets/colors_organized/hue_06/brightness_03/2020040414473600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":4,
      "vividness":1,
      "r":64,
      "g":65,
      "b":59,
      "hex":"#40413b",
      "file_location":"assets/colors_organized/hue_06/brightness_04/2020040414471901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":4,
      "vividness":2,
      "r":65,
      "g":65,
      "b":55,
      "hex":"#414137",
      "file_location":"assets/colors_organized/hue_06/brightness_04/2020040414472000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":4,
      "vividness":3,
      "r":64,
      "g":65,
      "b":51,
      "hex":"#404133",
      "file_location":"assets/colors_organized/hue_06/brightness_04/2020040414472001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":4,
      "vividness":4,
      "r":64,
      "g":65,
      "b":47,
      "hex":"#40412f",
      "file_location":"assets/colors_organized/hue_06/brightness_04/2020040414472100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":4,
      "vividness":5,
      "r":65,
      "g":64,
      "b":43,
      "hex":"#41402b",
      "file_location":"assets/colors_organized/hue_06/brightness_04/2020040414472101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":4,
      "vividness":6,
      "r":65,
      "g":65,
      "b":39,
      "hex":"#414127",
      "file_location":"assets/colors_organized/hue_06/brightness_04/2020040414472200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":4,
      "vividness":7,
      "r":64,
      "g":65,
      "b":33,
      "hex":"#404121",
      "file_location":"assets/colors_organized/hue_06/brightness_04/2020040414472201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":4,
      "vividness":8,
      "r":65,
      "g":65,
      "b":29,
      "hex":"#41411d",
      "file_location":"assets/colors_organized/hue_06/brightness_04/2020040414472300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":4,
      "vividness":9,
      "r":64,
      "g":64,
      "b":26,
      "hex":"#40401a",
      "file_location":"assets/colors_organized/hue_06/brightness_04/2020040414472301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":4,
      "vividness":10,
      "r":64,
      "g":65,
      "b":21,
      "hex":"#404115",
      "file_location":"assets/colors_organized/hue_06/brightness_04/2020040414472400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":4,
      "vividness":11,
      "r":65,
      "g":64,
      "b":16,
      "hex":"#414010",
      "file_location":"assets/colors_organized/hue_06/brightness_04/2020040414472401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":4,
      "vividness":12,
      "r":65,
      "g":65,
      "b":13,
      "hex":"#41410d",
      "file_location":"assets/colors_organized/hue_06/brightness_04/2020040414472500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":4,
      "vividness":13,
      "r":65,
      "g":64,
      "b":7,
      "hex":"#414007",
      "file_location":"assets/colors_organized/hue_06/brightness_04/2020040414472501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":4,
      "vividness":14,
      "r":65,
      "g":65,
      "b":3,
      "hex":"#414103",
      "file_location":"assets/colors_organized/hue_06/brightness_04/2020040414472600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":5,
      "vividness":1,
      "r":79,
      "g":80,
      "b":74,
      "hex":"#4f504a",
      "file_location":"assets/colors_organized/hue_06/brightness_05/2020040414470901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":5,
      "vividness":2,
      "r":80,
      "g":80,
      "b":68,
      "hex":"#505044",
      "file_location":"assets/colors_organized/hue_06/brightness_05/2020040414471000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":5,
      "vividness":3,
      "r":79,
      "g":80,
      "b":64,
      "hex":"#4f5040",
      "file_location":"assets/colors_organized/hue_06/brightness_05/2020040414471001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":5,
      "vividness":4,
      "r":80,
      "g":79,
      "b":58,
      "hex":"#504f3a",
      "file_location":"assets/colors_organized/hue_06/brightness_05/2020040414471100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":5,
      "vividness":5,
      "r":80,
      "g":80,
      "b":52,
      "hex":"#505034",
      "file_location":"assets/colors_organized/hue_06/brightness_05/2020040414471101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":5,
      "vividness":6,
      "r":80,
      "g":79,
      "b":48,
      "hex":"#504f30",
      "file_location":"assets/colors_organized/hue_06/brightness_05/2020040414471200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":5,
      "vividness":7,
      "r":79,
      "g":79,
      "b":41,
      "hex":"#4f4f29",
      "file_location":"assets/colors_organized/hue_06/brightness_05/2020040414471201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":5,
      "vividness":8,
      "r":79,
      "g":80,
      "b":36,
      "hex":"#4f5024",
      "file_location":"assets/colors_organized/hue_06/brightness_05/2020040414471300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":5,
      "vividness":9,
      "r":80,
      "g":79,
      "b":31,
      "hex":"#504f1f",
      "file_location":"assets/colors_organized/hue_06/brightness_05/2020040414471301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":5,
      "vividness":10,
      "r":80,
      "g":80,
      "b":26,
      "hex":"#50501a",
      "file_location":"assets/colors_organized/hue_06/brightness_05/2020040414471400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":5,
      "vividness":11,
      "r":80,
      "g":79,
      "b":22,
      "hex":"#504f16",
      "file_location":"assets/colors_organized/hue_06/brightness_05/2020040414471401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":5,
      "vividness":12,
      "r":79,
      "g":80,
      "b":14,
      "hex":"#4f500e",
      "file_location":"assets/colors_organized/hue_06/brightness_05/2020040414471500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":5,
      "vividness":13,
      "r":80,
      "g":80,
      "b":10,
      "hex":"#50500a",
      "file_location":"assets/colors_organized/hue_06/brightness_05/2020040414471501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":5,
      "vividness":14,
      "r":79,
      "g":79,
      "b":5,
      "hex":"#4f4f05",
      "file_location":"assets/colors_organized/hue_06/brightness_05/2020040414471502-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":6,
      "vividness":1,
      "r":94,
      "g":95,
      "b":87,
      "hex":"#5e5f57",
      "file_location":"assets/colors_organized/hue_06/brightness_06/2020040414470000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":6,
      "vividness":2,
      "r":94,
      "g":95,
      "b":81,
      "hex":"#5e5f51",
      "file_location":"assets/colors_organized/hue_06/brightness_06/2020040414470001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":6,
      "vividness":3,
      "r":94,
      "g":96,
      "b":75,
      "hex":"#5e604b",
      "file_location":"assets/colors_organized/hue_06/brightness_06/2020040414470002-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":6,
      "vividness":4,
      "r":95,
      "g":95,
      "b":69,
      "hex":"#5f5f45",
      "file_location":"assets/colors_organized/hue_06/brightness_06/2020040414470100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":6,
      "vividness":5,
      "r":95,
      "g":94,
      "b":63,
      "hex":"#5f5e3f",
      "file_location":"assets/colors_organized/hue_06/brightness_06/2020040414470101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":6,
      "vividness":6,
      "r":94,
      "g":94,
      "b":56,
      "hex":"#5e5e38",
      "file_location":"assets/colors_organized/hue_06/brightness_06/2020040414470200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":6,
      "vividness":7,
      "r":96,
      "g":95,
      "b":49,
      "hex":"#605f31",
      "file_location":"assets/colors_organized/hue_06/brightness_06/2020040414470201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":6,
      "vividness":8,
      "r":95,
      "g":95,
      "b":43,
      "hex":"#5f5f2b",
      "file_location":"assets/colors_organized/hue_06/brightness_06/2020040414470300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":6,
      "vividness":9,
      "r":95,
      "g":94,
      "b":37,
      "hex":"#5f5e25",
      "file_location":"assets/colors_organized/hue_06/brightness_06/2020040414470301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":6,
      "vividness":10,
      "r":94,
      "g":94,
      "b":30,
      "hex":"#5e5e1e",
      "file_location":"assets/colors_organized/hue_06/brightness_06/2020040414470400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":6,
      "vividness":11,
      "r":95,
      "g":95,
      "b":25,
      "hex":"#5f5f19",
      "file_location":"assets/colors_organized/hue_06/brightness_06/2020040414470401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":6,
      "vividness":12,
      "r":94,
      "g":95,
      "b":19,
      "hex":"#5e5f13",
      "file_location":"assets/colors_organized/hue_06/brightness_06/2020040414470500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":6,
      "vividness":13,
      "r":95,
      "g":94,
      "b":12,
      "hex":"#5f5e0c",
      "file_location":"assets/colors_organized/hue_06/brightness_06/2020040414470501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":6,
      "vividness":14,
      "r":95,
      "g":95,
      "b":7,
      "hex":"#5f5f07",
      "file_location":"assets/colors_organized/hue_06/brightness_06/2020040414470600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":7,
      "vividness":1,
      "r":110,
      "g":110,
      "b":102,
      "hex":"#6e6e66",
      "file_location":"assets/colors_organized/hue_06/brightness_07/2020040414464901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":7,
      "vividness":2,
      "r":109,
      "g":110,
      "b":94,
      "hex":"#6d6e5e",
      "file_location":"assets/colors_organized/hue_06/brightness_07/2020040414465000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":7,
      "vividness":3,
      "r":110,
      "g":109,
      "b":88,
      "hex":"#6e6d58",
      "file_location":"assets/colors_organized/hue_06/brightness_07/2020040414465001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":7,
      "vividness":4,
      "r":109,
      "g":110,
      "b":79,
      "hex":"#6d6e4f",
      "file_location":"assets/colors_organized/hue_06/brightness_07/2020040414465100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":7,
      "vividness":5,
      "r":110,
      "g":110,
      "b":72,
      "hex":"#6e6e48",
      "file_location":"assets/colors_organized/hue_06/brightness_07/2020040414465101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":7,
      "vividness":6,
      "r":109,
      "g":110,
      "b":66,
      "hex":"#6d6e42",
      "file_location":"assets/colors_organized/hue_06/brightness_07/2020040414465200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":7,
      "vividness":7,
      "r":110,
      "g":110,
      "b":58,
      "hex":"#6e6e3a",
      "file_location":"assets/colors_organized/hue_06/brightness_07/2020040414465201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":7,
      "vividness":8,
      "r":110,
      "g":110,
      "b":50,
      "hex":"#6e6e32",
      "file_location":"assets/colors_organized/hue_06/brightness_07/2020040414465300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":7,
      "vividness":9,
      "r":109,
      "g":110,
      "b":44,
      "hex":"#6d6e2c",
      "file_location":"assets/colors_organized/hue_06/brightness_07/2020040414465301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":7,
      "vividness":10,
      "r":109,
      "g":109,
      "b":35,
      "hex":"#6d6d23",
      "file_location":"assets/colors_organized/hue_06/brightness_07/2020040414465400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":7,
      "vividness":11,
      "r":109,
      "g":110,
      "b":30,
      "hex":"#6d6e1e",
      "file_location":"assets/colors_organized/hue_06/brightness_07/2020040414465401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":7,
      "vividness":12,
      "r":110,
      "g":110,
      "b":22,
      "hex":"#6e6e16",
      "file_location":"assets/colors_organized/hue_06/brightness_07/2020040414465500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":7,
      "vividness":13,
      "r":110,
      "g":110,
      "b":12,
      "hex":"#6e6e0c",
      "file_location":"assets/colors_organized/hue_06/brightness_07/2020040414465501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":7,
      "vividness":14,
      "r":109,
      "g":110,
      "b":6,
      "hex":"#6d6e06",
      "file_location":"assets/colors_organized/hue_06/brightness_07/2020040414465600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":8,
      "vividness":1,
      "r":124,
      "g":124,
      "b":116,
      "hex":"#7c7c74",
      "file_location":"assets/colors_organized/hue_06/brightness_08/2020040414463901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":8,
      "vividness":2,
      "r":123,
      "g":124,
      "b":108,
      "hex":"#7b7c6c",
      "file_location":"assets/colors_organized/hue_06/brightness_08/2020040414464000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":8,
      "vividness":3,
      "r":124,
      "g":124,
      "b":98,
      "hex":"#7c7c62",
      "file_location":"assets/colors_organized/hue_06/brightness_08/2020040414464001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":8,
      "vividness":4,
      "r":124,
      "g":124,
      "b":90,
      "hex":"#7c7c5a",
      "file_location":"assets/colors_organized/hue_06/brightness_08/2020040414464100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":8,
      "vividness":5,
      "r":123,
      "g":124,
      "b":82,
      "hex":"#7b7c52",
      "file_location":"assets/colors_organized/hue_06/brightness_08/2020040414464101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":8,
      "vividness":6,
      "r":124,
      "g":124,
      "b":74,
      "hex":"#7c7c4a",
      "file_location":"assets/colors_organized/hue_06/brightness_08/2020040414464200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":8,
      "vividness":7,
      "r":124,
      "g":123,
      "b":66,
      "hex":"#7c7b42",
      "file_location":"assets/colors_organized/hue_06/brightness_08/2020040414464201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":8,
      "vividness":8,
      "r":123,
      "g":124,
      "b":58,
      "hex":"#7b7c3a",
      "file_location":"assets/colors_organized/hue_06/brightness_08/2020040414464300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":8,
      "vividness":9,
      "r":123,
      "g":124,
      "b":48,
      "hex":"#7b7c30",
      "file_location":"assets/colors_organized/hue_06/brightness_08/2020040414464301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":8,
      "vividness":10,
      "r":124,
      "g":123,
      "b":40,
      "hex":"#7c7b28",
      "file_location":"assets/colors_organized/hue_06/brightness_08/2020040414464400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":8,
      "vividness":11,
      "r":124,
      "g":124,
      "b":34,
      "hex":"#7c7c22",
      "file_location":"assets/colors_organized/hue_06/brightness_08/2020040414464401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":8,
      "vividness":12,
      "r":123,
      "g":124,
      "b":22,
      "hex":"#7b7c16",
      "file_location":"assets/colors_organized/hue_06/brightness_08/2020040414464500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":8,
      "vividness":13,
      "r":124,
      "g":124,
      "b":14,
      "hex":"#7c7c0e",
      "file_location":"assets/colors_organized/hue_06/brightness_08/2020040414464501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":8,
      "vividness":14,
      "r":123,
      "g":124,
      "b":5,
      "hex":"#7b7c05",
      "file_location":"assets/colors_organized/hue_06/brightness_08/2020040414464600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":9,
      "vividness":1,
      "r":139,
      "g":139,
      "b":129,
      "hex":"#8b8b81",
      "file_location":"assets/colors_organized/hue_06/brightness_09/2020040414403001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":9,
      "vividness":2,
      "r":138,
      "g":139,
      "b":121,
      "hex":"#8a8b79",
      "file_location":"assets/colors_organized/hue_06/brightness_09/2020040414403100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":9,
      "vividness":3,
      "r":139,
      "g":139,
      "b":111,
      "hex":"#8b8b6f",
      "file_location":"assets/colors_organized/hue_06/brightness_09/2020040414403101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":9,
      "vividness":4,
      "r":139,
      "g":139,
      "b":101,
      "hex":"#8b8b65",
      "file_location":"assets/colors_organized/hue_06/brightness_09/2020040414403200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":9,
      "vividness":5,
      "r":140,
      "g":139,
      "b":93,
      "hex":"#8c8b5d",
      "file_location":"assets/colors_organized/hue_06/brightness_09/2020040414403201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":9,
      "vividness":6,
      "r":139,
      "g":140,
      "b":83,
      "hex":"#8b8c53",
      "file_location":"assets/colors_organized/hue_06/brightness_09/2020040414403300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":9,
      "vividness":7,
      "r":139,
      "g":140,
      "b":74,
      "hex":"#8b8c4a",
      "file_location":"assets/colors_organized/hue_06/brightness_09/2020040414403400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":9,
      "vividness":8,
      "r":139,
      "g":139,
      "b":65,
      "hex":"#8b8b41",
      "file_location":"assets/colors_organized/hue_06/brightness_09/2020040414403401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":9,
      "vividness":9,
      "r":139,
      "g":138,
      "b":55,
      "hex":"#8b8a37",
      "file_location":"assets/colors_organized/hue_06/brightness_09/2020040414403500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":9,
      "vividness":10,
      "r":138,
      "g":139,
      "b":45,
      "hex":"#8a8b2d",
      "file_location":"assets/colors_organized/hue_06/brightness_09/2020040414403501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":9,
      "vividness":11,
      "r":138,
      "g":139,
      "b":37,
      "hex":"#8a8b25",
      "file_location":"assets/colors_organized/hue_06/brightness_09/2020040414403600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":9,
      "vividness":12,
      "r":139,
      "g":139,
      "b":27,
      "hex":"#8b8b1b",
      "file_location":"assets/colors_organized/hue_06/brightness_09/2020040414403700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":9,
      "vividness":13,
      "r":139,
      "g":139,
      "b":17,
      "hex":"#8b8b11",
      "file_location":"assets/colors_organized/hue_06/brightness_09/2020040414403701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":9,
      "vividness":14,
      "r":138,
      "g":139,
      "b":9,
      "hex":"#8a8b09",
      "file_location":"assets/colors_organized/hue_06/brightness_09/2020040414403800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":10,
      "vividness":1,
      "r":154,
      "g":154,
      "b":144,
      "hex":"#9a9a90",
      "file_location":"assets/colors_organized/hue_06/brightness_10/2020040414401901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":10,
      "vividness":2,
      "r":155,
      "g":154,
      "b":134,
      "hex":"#9b9a86",
      "file_location":"assets/colors_organized/hue_06/brightness_10/2020040414402000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":10,
      "vividness":3,
      "r":154,
      "g":155,
      "b":123,
      "hex":"#9a9b7b",
      "file_location":"assets/colors_organized/hue_06/brightness_10/2020040414402001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":10,
      "vividness":4,
      "r":153,
      "g":154,
      "b":112,
      "hex":"#999a70",
      "file_location":"assets/colors_organized/hue_06/brightness_10/2020040414402100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":10,
      "vividness":5,
      "r":154,
      "g":154,
      "b":102,
      "hex":"#9a9a66",
      "file_location":"assets/colors_organized/hue_06/brightness_10/2020040414402101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":10,
      "vividness":6,
      "r":154,
      "g":154,
      "b":92,
      "hex":"#9a9a5c",
      "file_location":"assets/colors_organized/hue_06/brightness_10/2020040414402200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":10,
      "vividness":7,
      "r":154,
      "g":154,
      "b":82,
      "hex":"#9a9a52",
      "file_location":"assets/colors_organized/hue_06/brightness_10/2020040414402201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":10,
      "vividness":8,
      "r":155,
      "g":154,
      "b":72,
      "hex":"#9b9a48",
      "file_location":"assets/colors_organized/hue_06/brightness_10/2020040414402300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":10,
      "vividness":9,
      "r":153,
      "g":154,
      "b":60,
      "hex":"#999a3c",
      "file_location":"assets/colors_organized/hue_06/brightness_10/2020040414402301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":10,
      "vividness":10,
      "r":153,
      "g":154,
      "b":50,
      "hex":"#999a32",
      "file_location":"assets/colors_organized/hue_06/brightness_10/2020040414402400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":10,
      "vividness":11,
      "r":154,
      "g":154,
      "b":40,
      "hex":"#9a9a28",
      "file_location":"assets/colors_organized/hue_06/brightness_10/2020040414402401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":10,
      "vividness":12,
      "r":154,
      "g":154,
      "b":30,
      "hex":"#9a9a1e",
      "file_location":"assets/colors_organized/hue_06/brightness_10/2020040414402500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":10,
      "vividness":13,
      "r":154,
      "g":154,
      "b":20,
      "hex":"#9a9a14",
      "file_location":"assets/colors_organized/hue_06/brightness_10/2020040414402501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":10,
      "vividness":14,
      "r":155,
      "g":154,
      "b":10,
      "hex":"#9b9a0a",
      "file_location":"assets/colors_organized/hue_06/brightness_10/2020040414402600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":11,
      "vividness":1,
      "r":169,
      "g":169,
      "b":157,
      "hex":"#a9a99d",
      "file_location":"assets/colors_organized/hue_06/brightness_11/2020040414400800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":11,
      "vividness":2,
      "r":170,
      "g":169,
      "b":148,
      "hex":"#aaa994",
      "file_location":"assets/colors_organized/hue_06/brightness_11/2020040414400801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":11,
      "vividness":3,
      "r":169,
      "g":169,
      "b":135,
      "hex":"#a9a987",
      "file_location":"assets/colors_organized/hue_06/brightness_11/2020040414400900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":11,
      "vividness":4,
      "r":170,
      "g":169,
      "b":123,
      "hex":"#aaa97b",
      "file_location":"assets/colors_organized/hue_06/brightness_11/2020040414400901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":11,
      "vividness":5,
      "r":169,
      "g":170,
      "b":113,
      "hex":"#a9aa71",
      "file_location":"assets/colors_organized/hue_06/brightness_11/2020040414401000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":11,
      "vividness":6,
      "r":168,
      "g":169,
      "b":101,
      "hex":"#a8a965",
      "file_location":"assets/colors_organized/hue_06/brightness_11/2020040414401001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":11,
      "vividness":7,
      "r":168,
      "g":169,
      "b":89,
      "hex":"#a8a959",
      "file_location":"assets/colors_organized/hue_06/brightness_11/2020040414401100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":11,
      "vividness":8,
      "r":169,
      "g":169,
      "b":79,
      "hex":"#a9a94f",
      "file_location":"assets/colors_organized/hue_06/brightness_11/2020040414401101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":11,
      "vividness":9,
      "r":168,
      "g":169,
      "b":67,
      "hex":"#a8a943",
      "file_location":"assets/colors_organized/hue_06/brightness_11/2020040414401200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":11,
      "vividness":10,
      "r":169,
      "g":169,
      "b":55,
      "hex":"#a9a937",
      "file_location":"assets/colors_organized/hue_06/brightness_11/2020040414401300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":11,
      "vividness":11,
      "r":169,
      "g":169,
      "b":45,
      "hex":"#a9a92d",
      "file_location":"assets/colors_organized/hue_06/brightness_11/2020040414401301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":11,
      "vividness":12,
      "r":169,
      "g":170,
      "b":34,
      "hex":"#a9aa22",
      "file_location":"assets/colors_organized/hue_06/brightness_11/2020040414401400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":11,
      "vividness":13,
      "r":169,
      "g":169,
      "b":21,
      "hex":"#a9a915",
      "file_location":"assets/colors_organized/hue_06/brightness_11/2020040414401401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":11,
      "vividness":14,
      "r":169,
      "g":169,
      "b":9,
      "hex":"#a9a909",
      "file_location":"assets/colors_organized/hue_06/brightness_11/2020040414401500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":12,
      "vividness":1,
      "r":184,
      "g":184,
      "b":172,
      "hex":"#b8b8ac",
      "file_location":"assets/colors_organized/hue_06/brightness_12/2020040414395601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":12,
      "vividness":2,
      "r":184,
      "g":184,
      "b":160,
      "hex":"#b8b8a0",
      "file_location":"assets/colors_organized/hue_06/brightness_12/2020040414395700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":12,
      "vividness":3,
      "r":184,
      "g":184,
      "b":146,
      "hex":"#b8b892",
      "file_location":"assets/colors_organized/hue_06/brightness_12/2020040414395701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":12,
      "vividness":4,
      "r":185,
      "g":185,
      "b":135,
      "hex":"#b9b987",
      "file_location":"assets/colors_organized/hue_06/brightness_12/2020040414395800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":12,
      "vividness":5,
      "r":184,
      "g":184,
      "b":122,
      "hex":"#b8b87a",
      "file_location":"assets/colors_organized/hue_06/brightness_12/2020040414395801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":12,
      "vividness":6,
      "r":184,
      "g":184,
      "b":110,
      "hex":"#b8b86e",
      "file_location":"assets/colors_organized/hue_06/brightness_12/2020040414395900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":12,
      "vividness":7,
      "r":184,
      "g":184,
      "b":98,
      "hex":"#b8b862",
      "file_location":"assets/colors_organized/hue_06/brightness_12/2020040414395901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":12,
      "vividness":8,
      "r":184,
      "g":184,
      "b":86,
      "hex":"#b8b856",
      "file_location":"assets/colors_organized/hue_06/brightness_12/2020040414400000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":12,
      "vividness":9,
      "r":184,
      "g":184,
      "b":72,
      "hex":"#b8b848",
      "file_location":"assets/colors_organized/hue_06/brightness_12/2020040414400001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":12,
      "vividness":10,
      "r":184,
      "g":184,
      "b":60,
      "hex":"#b8b83c",
      "file_location":"assets/colors_organized/hue_06/brightness_12/2020040414400100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":12,
      "vividness":11,
      "r":184,
      "g":185,
      "b":49,
      "hex":"#b8b931",
      "file_location":"assets/colors_organized/hue_06/brightness_12/2020040414400200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":12,
      "vividness":12,
      "r":184,
      "g":184,
      "b":36,
      "hex":"#b8b824",
      "file_location":"assets/colors_organized/hue_06/brightness_12/2020040414400201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":12,
      "vividness":13,
      "r":184,
      "g":185,
      "b":22,
      "hex":"#b8b916",
      "file_location":"assets/colors_organized/hue_06/brightness_12/2020040414400300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":12,
      "vividness":14,
      "r":185,
      "g":185,
      "b":13,
      "hex":"#b9b90d",
      "file_location":"assets/colors_organized/hue_06/brightness_12/2020040414400301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":13,
      "vividness":1,
      "r":199,
      "g":200,
      "b":186,
      "hex":"#c7c8ba",
      "file_location":"assets/colors_organized/hue_06/brightness_13/2020040414394501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":13,
      "vividness":2,
      "r":199,
      "g":199,
      "b":173,
      "hex":"#c7c7ad",
      "file_location":"assets/colors_organized/hue_06/brightness_13/2020040414394600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":13,
      "vividness":3,
      "r":199,
      "g":200,
      "b":160,
      "hex":"#c7c8a0",
      "file_location":"assets/colors_organized/hue_06/brightness_13/2020040414394601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":13,
      "vividness":4,
      "r":199,
      "g":199,
      "b":145,
      "hex":"#c7c791",
      "file_location":"assets/colors_organized/hue_06/brightness_13/2020040414394700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":13,
      "vividness":5,
      "r":199,
      "g":200,
      "b":134,
      "hex":"#c7c886",
      "file_location":"assets/colors_organized/hue_06/brightness_13/2020040414394701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":13,
      "vividness":6,
      "r":198,
      "g":199,
      "b":119,
      "hex":"#c6c777",
      "file_location":"assets/colors_organized/hue_06/brightness_13/2020040414394800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":13,
      "vividness":7,
      "r":199,
      "g":200,
      "b":106,
      "hex":"#c7c86a",
      "file_location":"assets/colors_organized/hue_06/brightness_13/2020040414394801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":13,
      "vividness":8,
      "r":200,
      "g":199,
      "b":93,
      "hex":"#c8c75d",
      "file_location":"assets/colors_organized/hue_06/brightness_13/2020040414394900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":13,
      "vividness":9,
      "r":200,
      "g":200,
      "b":80,
      "hex":"#c8c850",
      "file_location":"assets/colors_organized/hue_06/brightness_13/2020040414394901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":13,
      "vividness":10,
      "r":199,
      "g":199,
      "b":65,
      "hex":"#c7c741",
      "file_location":"assets/colors_organized/hue_06/brightness_13/2020040414395000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":13,
      "vividness":11,
      "r":200,
      "g":200,
      "b":52,
      "hex":"#c8c834",
      "file_location":"assets/colors_organized/hue_06/brightness_13/2020040414395001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":13,
      "vividness":12,
      "r":199,
      "g":200,
      "b":37,
      "hex":"#c7c825",
      "file_location":"assets/colors_organized/hue_06/brightness_13/2020040414395100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":13,
      "vividness":13,
      "r":200,
      "g":200,
      "b":26,
      "hex":"#c8c81a",
      "file_location":"assets/colors_organized/hue_06/brightness_13/2020040414395101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":13,
      "vividness":14,
      "r":199,
      "g":200,
      "b":11,
      "hex":"#c7c80b",
      "file_location":"assets/colors_organized/hue_06/brightness_13/2020040414395200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":14,
      "vividness":1,
      "r":214,
      "g":215,
      "b":201,
      "hex":"#d6d7c9",
      "file_location":"assets/colors_organized/hue_06/brightness_14/2020040414393500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":14,
      "vividness":2,
      "r":214,
      "g":215,
      "b":184,
      "hex":"#d6d7b8",
      "file_location":"assets/colors_organized/hue_06/brightness_14/2020040414393501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":14,
      "vividness":3,
      "r":214,
      "g":215,
      "b":171,
      "hex":"#d6d7ab",
      "file_location":"assets/colors_organized/hue_06/brightness_14/2020040414393600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":14,
      "vividness":4,
      "r":215,
      "g":214,
      "b":157,
      "hex":"#d7d69d",
      "file_location":"assets/colors_organized/hue_06/brightness_14/2020040414393601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":14,
      "vividness":5,
      "r":214,
      "g":214,
      "b":142,
      "hex":"#d6d68e",
      "file_location":"assets/colors_organized/hue_06/brightness_14/2020040414393700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":14,
      "vividness":6,
      "r":215,
      "g":215,
      "b":129,
      "hex":"#d7d781",
      "file_location":"assets/colors_organized/hue_06/brightness_14/2020040414393701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":14,
      "vividness":7,
      "r":214,
      "g":214,
      "b":114,
      "hex":"#d6d672",
      "file_location":"assets/colors_organized/hue_06/brightness_14/2020040414393800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":14,
      "vividness":8,
      "r":214,
      "g":214,
      "b":100,
      "hex":"#d6d664",
      "file_location":"assets/colors_organized/hue_06/brightness_14/2020040414393801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":14,
      "vividness":9,
      "r":214,
      "g":215,
      "b":85,
      "hex":"#d6d755",
      "file_location":"assets/colors_organized/hue_06/brightness_14/2020040414393802-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":14,
      "vividness":10,
      "r":215,
      "g":214,
      "b":70,
      "hex":"#d7d646",
      "file_location":"assets/colors_organized/hue_06/brightness_14/2020040414393900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":14,
      "vividness":11,
      "r":214,
      "g":214,
      "b":56,
      "hex":"#d6d638",
      "file_location":"assets/colors_organized/hue_06/brightness_14/2020040414393901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":14,
      "vividness":12,
      "r":215,
      "g":215,
      "b":41,
      "hex":"#d7d729",
      "file_location":"assets/colors_organized/hue_06/brightness_14/2020040414394000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":14,
      "vividness":13,
      "r":214,
      "g":215,
      "b":26,
      "hex":"#d6d71a",
      "file_location":"assets/colors_organized/hue_06/brightness_14/2020040414394001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":14,
      "vividness":14,
      "r":213,
      "g":214,
      "b":12,
      "hex":"#d5d60c",
      "file_location":"assets/colors_organized/hue_06/brightness_14/2020040414394100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":15,
      "vividness":1,
      "r":229,
      "g":230,
      "b":214,
      "hex":"#e5e6d6",
      "file_location":"assets/colors_organized/hue_06/brightness_15/2020040414392201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":15,
      "vividness":2,
      "r":229,
      "g":230,
      "b":198,
      "hex":"#e5e6c6",
      "file_location":"assets/colors_organized/hue_06/brightness_15/2020040414392300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":15,
      "vividness":3,
      "r":230,
      "g":229,
      "b":183,
      "hex":"#e6e5b7",
      "file_location":"assets/colors_organized/hue_06/brightness_15/2020040414392301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":15,
      "vividness":4,
      "r":229,
      "g":229,
      "b":167,
      "hex":"#e5e5a7",
      "file_location":"assets/colors_organized/hue_06/brightness_15/2020040414392400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":15,
      "vividness":5,
      "r":229,
      "g":230,
      "b":152,
      "hex":"#e5e698",
      "file_location":"assets/colors_organized/hue_06/brightness_15/2020040414392401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":15,
      "vividness":6,
      "r":229,
      "g":230,
      "b":137,
      "hex":"#e5e689",
      "file_location":"assets/colors_organized/hue_06/brightness_15/2020040414392500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":15,
      "vividness":7,
      "r":230,
      "g":229,
      "b":121,
      "hex":"#e6e579",
      "file_location":"assets/colors_organized/hue_06/brightness_15/2020040414392501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":15,
      "vividness":8,
      "r":229,
      "g":229,
      "b":107,
      "hex":"#e5e56b",
      "file_location":"assets/colors_organized/hue_06/brightness_15/2020040414392600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":15,
      "vividness":9,
      "r":229,
      "g":230,
      "b":92,
      "hex":"#e5e65c",
      "file_location":"assets/colors_organized/hue_06/brightness_15/2020040414392601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":15,
      "vividness":10,
      "r":229,
      "g":230,
      "b":76,
      "hex":"#e5e64c",
      "file_location":"assets/colors_organized/hue_06/brightness_15/2020040414392700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":15,
      "vividness":11,
      "r":230,
      "g":229,
      "b":59,
      "hex":"#e6e53b",
      "file_location":"assets/colors_organized/hue_06/brightness_15/2020040414392701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":15,
      "vividness":12,
      "r":229,
      "g":229,
      "b":43,
      "hex":"#e5e52b",
      "file_location":"assets/colors_organized/hue_06/brightness_15/2020040414392800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":15,
      "vividness":13,
      "r":229,
      "g":230,
      "b":30,
      "hex":"#e5e61e",
      "file_location":"assets/colors_organized/hue_06/brightness_15/2020040414392801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":6,
      "brightness":15,
      "vividness":14,
      "r":229,
      "g":230,
      "b":14,
      "hex":"#e5e60e",
      "file_location":"assets/colors_organized/hue_06/brightness_15/2020040414392900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":1,
      "vividness":1,
      "r":17,
      "g":20,
      "b":13,
      "hex":"#11140d",
      "file_location":"assets/colors_organized/hue_07/brightness_01/2020040415165900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":1,
      "vividness":2,
      "r":18,
      "g":20,
      "b":9,
      "hex":"#121409",
      "file_location":"assets/colors_organized/hue_07/brightness_01/2020040415165901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":1,
      "vividness":3,
      "r":17,
      "g":19,
      "b":6,
      "hex":"#111306",
      "file_location":"assets/colors_organized/hue_07/brightness_01/2020040415170001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":1,
      "vividness":4,
      "r":17,
      "g":20,
      "b":3,
      "hex":"#111403",
      "file_location":"assets/colors_organized/hue_07/brightness_01/2020040415170201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":1,
      "vividness":5,
      "r":15,
      "g":19,
      "b":2,
      "hex":"#0f1302",
      "file_location":"assets/colors_organized/hue_07/brightness_01/2020040415170300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":1,
      "vividness":6,
      "r":15,
      "g":20,
      "b":0,
      "hex":"#0f1400",
      "file_location":"assets/colors_organized/hue_07/brightness_01/2020040415170301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":2,
      "vividness":1,
      "r":34,
      "g":34,
      "b":32,
      "hex":"#222220",
      "file_location":"assets/colors_organized/hue_07/brightness_02/2020040415164401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":2,
      "vividness":2,
      "r":33,
      "g":34,
      "b":28,
      "hex":"#21221c",
      "file_location":"assets/colors_organized/hue_07/brightness_02/2020040415164501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":2,
      "vividness":3,
      "r":33,
      "g":35,
      "b":24,
      "hex":"#212318",
      "file_location":"assets/colors_organized/hue_07/brightness_02/2020040415164600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":2,
      "vividness":4,
      "r":31,
      "g":35,
      "b":21,
      "hex":"#1f2315",
      "file_location":"assets/colors_organized/hue_07/brightness_02/2020040415164601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":2,
      "vividness":5,
      "r":32,
      "g":35,
      "b":18,
      "hex":"#202312",
      "file_location":"assets/colors_organized/hue_07/brightness_02/2020040415164800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":2,
      "vividness":6,
      "r":30,
      "g":35,
      "b":15,
      "hex":"#1e230f",
      "file_location":"assets/colors_organized/hue_07/brightness_02/2020040415164801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":2,
      "vividness":7,
      "r":31,
      "g":34,
      "b":13,
      "hex":"#1f220d",
      "file_location":"assets/colors_organized/hue_07/brightness_02/2020040415164900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":2,
      "vividness":8,
      "r":29,
      "g":35,
      "b":9,
      "hex":"#1d2309",
      "file_location":"assets/colors_organized/hue_07/brightness_02/2020040415165000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":2,
      "vividness":9,
      "r":30,
      "g":34,
      "b":7,
      "hex":"#1e2207",
      "file_location":"assets/colors_organized/hue_07/brightness_02/2020040415165100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":2,
      "vividness":10,
      "r":29,
      "g":34,
      "b":2,
      "hex":"#1d2202",
      "file_location":"assets/colors_organized/hue_07/brightness_02/2020040415165101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":2,
      "vividness":11,
      "r":28,
      "g":35,
      "b":2,
      "hex":"#1c2302",
      "file_location":"assets/colors_organized/hue_07/brightness_02/2020040415165200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":3,
      "vividness":1,
      "r":47,
      "g":50,
      "b":43,
      "hex":"#2f322b",
      "file_location":"assets/colors_organized/hue_07/brightness_03/2020040415163400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":3,
      "vividness":2,
      "r":48,
      "g":50,
      "b":39,
      "hex":"#303227",
      "file_location":"assets/colors_organized/hue_07/brightness_03/2020040415163401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":3,
      "vividness":3,
      "r":47,
      "g":49,
      "b":35,
      "hex":"#2f3123",
      "file_location":"assets/colors_organized/hue_07/brightness_03/2020040415163500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":3,
      "vividness":4,
      "r":46,
      "g":50,
      "b":35,
      "hex":"#2e3223",
      "file_location":"assets/colors_organized/hue_07/brightness_03/2020040415163600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":3,
      "vividness":5,
      "r":45,
      "g":50,
      "b":30,
      "hex":"#2d321e",
      "file_location":"assets/colors_organized/hue_07/brightness_03/2020040415163601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":3,
      "vividness":6,
      "r":44,
      "g":49,
      "b":26,
      "hex":"#2c311a",
      "file_location":"assets/colors_organized/hue_07/brightness_03/2020040415163700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":3,
      "vividness":7,
      "r":44,
      "g":50,
      "b":22,
      "hex":"#2c3216",
      "file_location":"assets/colors_organized/hue_07/brightness_03/2020040415163701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":3,
      "vividness":8,
      "r":43,
      "g":50,
      "b":19,
      "hex":"#2b3213",
      "file_location":"assets/colors_organized/hue_07/brightness_03/2020040415163800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":3,
      "vividness":9,
      "r":42,
      "g":49,
      "b":15,
      "hex":"#2a310f",
      "file_location":"assets/colors_organized/hue_07/brightness_03/2020040415163801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":3,
      "vividness":10,
      "r":42,
      "g":50,
      "b":13,
      "hex":"#2a320d",
      "file_location":"assets/colors_organized/hue_07/brightness_03/2020040415163900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":3,
      "vividness":11,
      "r":42,
      "g":50,
      "b":9,
      "hex":"#2a3209",
      "file_location":"assets/colors_organized/hue_07/brightness_03/2020040415163901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":3,
      "vividness":12,
      "r":41,
      "g":50,
      "b":7,
      "hex":"#293207",
      "file_location":"assets/colors_organized/hue_07/brightness_03/2020040415164000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":3,
      "vividness":13,
      "r":40,
      "g":49,
      "b":2,
      "hex":"#283102",
      "file_location":"assets/colors_organized/hue_07/brightness_03/2020040415164001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":4,
      "vividness":1,
      "r":62,
      "g":65,
      "b":56,
      "hex":"#3e4138",
      "file_location":"assets/colors_organized/hue_07/brightness_04/2020040415162301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":4,
      "vividness":2,
      "r":61,
      "g":65,
      "b":51,
      "hex":"#3d4133",
      "file_location":"assets/colors_organized/hue_07/brightness_04/2020040415162400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":4,
      "vividness":3,
      "r":60,
      "g":65,
      "b":45,
      "hex":"#3c412d",
      "file_location":"assets/colors_organized/hue_07/brightness_04/2020040415162401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":4,
      "vividness":4,
      "r":60,
      "g":65,
      "b":43,
      "hex":"#3c412b",
      "file_location":"assets/colors_organized/hue_07/brightness_04/2020040415162500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":4,
      "vividness":5,
      "r":59,
      "g":65,
      "b":39,
      "hex":"#3b4127",
      "file_location":"assets/colors_organized/hue_07/brightness_04/2020040415162501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":4,
      "vividness":6,
      "r":58,
      "g":65,
      "b":32,
      "hex":"#3a4120",
      "file_location":"assets/colors_organized/hue_07/brightness_04/2020040415162600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":4,
      "vividness":7,
      "r":58,
      "g":66,
      "b":29,
      "hex":"#3a421d",
      "file_location":"assets/colors_organized/hue_07/brightness_04/2020040415162601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":4,
      "vividness":8,
      "r":57,
      "g":65,
      "b":26,
      "hex":"#39411a",
      "file_location":"assets/colors_organized/hue_07/brightness_04/2020040415162700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":4,
      "vividness":9,
      "r":56,
      "g":65,
      "b":22,
      "hex":"#384116",
      "file_location":"assets/colors_organized/hue_07/brightness_04/2020040415162701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":4,
      "vividness":10,
      "r":55,
      "g":64,
      "b":17,
      "hex":"#374011",
      "file_location":"assets/colors_organized/hue_07/brightness_04/2020040415162800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":4,
      "vividness":11,
      "r":52,
      "g":65,
      "b":12,
      "hex":"#34410c",
      "file_location":"assets/colors_organized/hue_07/brightness_04/2020040415162801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":4,
      "vividness":12,
      "r":51,
      "g":65,
      "b":6,
      "hex":"#334106",
      "file_location":"assets/colors_organized/hue_07/brightness_04/2020040415162900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":4,
      "vividness":13,
      "r":53,
      "g":65,
      "b":3,
      "hex":"#354103",
      "file_location":"assets/colors_organized/hue_07/brightness_04/2020040415162901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":5,
      "vividness":1,
      "r":78,
      "g":80,
      "b":69,
      "hex":"#4e5045",
      "file_location":"assets/colors_organized/hue_07/brightness_05/2020040415161201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":5,
      "vividness":2,
      "r":76,
      "g":80,
      "b":65,
      "hex":"#4c5041",
      "file_location":"assets/colors_organized/hue_07/brightness_05/2020040415161300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":5,
      "vividness":3,
      "r":75,
      "g":80,
      "b":58,
      "hex":"#4b503a",
      "file_location":"assets/colors_organized/hue_07/brightness_05/2020040415161301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":5,
      "vividness":4,
      "r":74,
      "g":80,
      "b":52,
      "hex":"#4a5034",
      "file_location":"assets/colors_organized/hue_07/brightness_05/2020040415161400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":5,
      "vividness":5,
      "r":73,
      "g":80,
      "b":47,
      "hex":"#49502f",
      "file_location":"assets/colors_organized/hue_07/brightness_05/2020040415161500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":5,
      "vividness":6,
      "r":72,
      "g":80,
      "b":41,
      "hex":"#485029",
      "file_location":"assets/colors_organized/hue_07/brightness_05/2020040415161501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":5,
      "vividness":7,
      "r":71,
      "g":80,
      "b":37,
      "hex":"#475025",
      "file_location":"assets/colors_organized/hue_07/brightness_05/2020040415161600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":5,
      "vividness":8,
      "r":70,
      "g":80,
      "b":30,
      "hex":"#46501e",
      "file_location":"assets/colors_organized/hue_07/brightness_05/2020040415161601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":5,
      "vividness":9,
      "r":69,
      "g":79,
      "b":26,
      "hex":"#454f1a",
      "file_location":"assets/colors_organized/hue_07/brightness_05/2020040415161700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":5,
      "vividness":10,
      "r":69,
      "g":80,
      "b":22,
      "hex":"#455016",
      "file_location":"assets/colors_organized/hue_07/brightness_05/2020040415161701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":5,
      "vividness":11,
      "r":67,
      "g":79,
      "b":13,
      "hex":"#434f0d",
      "file_location":"assets/colors_organized/hue_07/brightness_05/2020040415161800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":5,
      "vividness":12,
      "r":67,
      "g":80,
      "b":10,
      "hex":"#43500a",
      "file_location":"assets/colors_organized/hue_07/brightness_05/2020040415161801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":5,
      "vividness":13,
      "r":64,
      "g":80,
      "b":7,
      "hex":"#405007",
      "file_location":"assets/colors_organized/hue_07/brightness_05/2020040415161900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":6,
      "vividness":1,
      "r":92,
      "g":95,
      "b":88,
      "hex":"#5c5f58",
      "file_location":"assets/colors_organized/hue_07/brightness_06/2020040415160001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":6,
      "vividness":2,
      "r":91,
      "g":95,
      "b":81,
      "hex":"#5b5f51",
      "file_location":"assets/colors_organized/hue_07/brightness_06/2020040415160100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":6,
      "vividness":3,
      "r":90,
      "g":95,
      "b":75,
      "hex":"#5a5f4b",
      "file_location":"assets/colors_organized/hue_07/brightness_06/2020040415160200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":6,
      "vividness":4,
      "r":89,
      "g":95,
      "b":69,
      "hex":"#595f45",
      "file_location":"assets/colors_organized/hue_07/brightness_06/2020040415160201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":6,
      "vividness":5,
      "r":88,
      "g":95,
      "b":62,
      "hex":"#585f3e",
      "file_location":"assets/colors_organized/hue_07/brightness_06/2020040415160300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":6,
      "vividness":6,
      "r":87,
      "g":95,
      "b":56,
      "hex":"#575f38",
      "file_location":"assets/colors_organized/hue_07/brightness_06/2020040415160301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":6,
      "vividness":7,
      "r":86,
      "g":95,
      "b":50,
      "hex":"#565f32",
      "file_location":"assets/colors_organized/hue_07/brightness_06/2020040415160400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":6,
      "vividness":8,
      "r":85,
      "g":95,
      "b":43,
      "hex":"#555f2b",
      "file_location":"assets/colors_organized/hue_07/brightness_06/2020040415160401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":6,
      "vividness":9,
      "r":84,
      "g":95,
      "b":37,
      "hex":"#545f25",
      "file_location":"assets/colors_organized/hue_07/brightness_06/2020040415160500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":6,
      "vividness":10,
      "r":83,
      "g":95,
      "b":31,
      "hex":"#535f1f",
      "file_location":"assets/colors_organized/hue_07/brightness_06/2020040415160501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":6,
      "vividness":11,
      "r":82,
      "g":95,
      "b":25,
      "hex":"#525f19",
      "file_location":"assets/colors_organized/hue_07/brightness_06/2020040415160600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":6,
      "vividness":12,
      "r":78,
      "g":94,
      "b":19,
      "hex":"#4e5e13",
      "file_location":"assets/colors_organized/hue_07/brightness_06/2020040415160700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":6,
      "vividness":13,
      "r":77,
      "g":95,
      "b":13,
      "hex":"#4d5f0d",
      "file_location":"assets/colors_organized/hue_07/brightness_06/2020040415160701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":6,
      "vividness":14,
      "r":76,
      "g":95,
      "b":6,
      "hex":"#4c5f06",
      "file_location":"assets/colors_organized/hue_07/brightness_06/2020040415160800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":7,
      "vividness":1,
      "r":108,
      "g":109,
      "b":101,
      "hex":"#6c6d65",
      "file_location":"assets/colors_organized/hue_07/brightness_07/2020040415154901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":7,
      "vividness":2,
      "r":107,
      "g":109,
      "b":95,
      "hex":"#6b6d5f",
      "file_location":"assets/colors_organized/hue_07/brightness_07/2020040415155000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":7,
      "vividness":3,
      "r":105,
      "g":110,
      "b":88,
      "hex":"#696e58",
      "file_location":"assets/colors_organized/hue_07/brightness_07/2020040415155001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":7,
      "vividness":4,
      "r":104,
      "g":111,
      "b":80,
      "hex":"#686f50",
      "file_location":"assets/colors_organized/hue_07/brightness_07/2020040415155100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":7,
      "vividness":5,
      "r":102,
      "g":110,
      "b":73,
      "hex":"#666e49",
      "file_location":"assets/colors_organized/hue_07/brightness_07/2020040415155200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":7,
      "vividness":6,
      "r":101,
      "g":110,
      "b":65,
      "hex":"#656e41",
      "file_location":"assets/colors_organized/hue_07/brightness_07/2020040415155201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":7,
      "vividness":7,
      "r":100,
      "g":110,
      "b":58,
      "hex":"#646e3a",
      "file_location":"assets/colors_organized/hue_07/brightness_07/2020040415155300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":7,
      "vividness":8,
      "r":98,
      "g":109,
      "b":49,
      "hex":"#626d31",
      "file_location":"assets/colors_organized/hue_07/brightness_07/2020040415155301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":7,
      "vividness":9,
      "r":95,
      "g":110,
      "b":43,
      "hex":"#5f6e2b",
      "file_location":"assets/colors_organized/hue_07/brightness_07/2020040415155400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":7,
      "vividness":10,
      "r":94,
      "g":110,
      "b":35,
      "hex":"#5e6e23",
      "file_location":"assets/colors_organized/hue_07/brightness_07/2020040415155401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":7,
      "vividness":11,
      "r":93,
      "g":110,
      "b":30,
      "hex":"#5d6e1e",
      "file_location":"assets/colors_organized/hue_07/brightness_07/2020040415155500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":7,
      "vividness":12,
      "r":91,
      "g":110,
      "b":21,
      "hex":"#5b6e15",
      "file_location":"assets/colors_organized/hue_07/brightness_07/2020040415155501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":7,
      "vividness":13,
      "r":90,
      "g":110,
      "b":13,
      "hex":"#5a6e0d",
      "file_location":"assets/colors_organized/hue_07/brightness_07/2020040415155600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":7,
      "vividness":14,
      "r":89,
      "g":110,
      "b":7,
      "hex":"#596e07",
      "file_location":"assets/colors_organized/hue_07/brightness_07/2020040415155601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":8,
      "vividness":1,
      "r":123,
      "g":124,
      "b":116,
      "hex":"#7b7c74",
      "file_location":"assets/colors_organized/hue_07/brightness_08/2020040415153800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":8,
      "vividness":2,
      "r":121,
      "g":124,
      "b":107,
      "hex":"#797c6b",
      "file_location":"assets/colors_organized/hue_07/brightness_08/2020040415153801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":8,
      "vividness":3,
      "r":119,
      "g":125,
      "b":99,
      "hex":"#777d63",
      "file_location":"assets/colors_organized/hue_07/brightness_08/2020040415153900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":8,
      "vividness":4,
      "r":118,
      "g":124,
      "b":90,
      "hex":"#767c5a",
      "file_location":"assets/colors_organized/hue_07/brightness_08/2020040415153901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":8,
      "vividness":5,
      "r":116,
      "g":124,
      "b":83,
      "hex":"#747c53",
      "file_location":"assets/colors_organized/hue_07/brightness_08/2020040415154000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":8,
      "vividness":6,
      "r":114,
      "g":124,
      "b":74,
      "hex":"#727c4a",
      "file_location":"assets/colors_organized/hue_07/brightness_08/2020040415154100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":8,
      "vividness":7,
      "r":113,
      "g":124,
      "b":66,
      "hex":"#717c42",
      "file_location":"assets/colors_organized/hue_07/brightness_08/2020040415154101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":8,
      "vividness":8,
      "r":111,
      "g":123,
      "b":57,
      "hex":"#6f7b39",
      "file_location":"assets/colors_organized/hue_07/brightness_08/2020040415154200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":8,
      "vividness":9,
      "r":108,
      "g":124,
      "b":49,
      "hex":"#6c7c31",
      "file_location":"assets/colors_organized/hue_07/brightness_08/2020040415154201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":8,
      "vividness":10,
      "r":109,
      "g":124,
      "b":41,
      "hex":"#6d7c29",
      "file_location":"assets/colors_organized/hue_07/brightness_08/2020040415154300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":8,
      "vividness":11,
      "r":105,
      "g":124,
      "b":34,
      "hex":"#697c22",
      "file_location":"assets/colors_organized/hue_07/brightness_08/2020040415154400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":8,
      "vividness":12,
      "r":103,
      "g":124,
      "b":23,
      "hex":"#677c17",
      "file_location":"assets/colors_organized/hue_07/brightness_08/2020040415154401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":8,
      "vividness":13,
      "r":102,
      "g":124,
      "b":15,
      "hex":"#667c0f",
      "file_location":"assets/colors_organized/hue_07/brightness_08/2020040415154500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":8,
      "vividness":14,
      "r":101,
      "g":124,
      "b":7,
      "hex":"#657c07",
      "file_location":"assets/colors_organized/hue_07/brightness_08/2020040415154501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":9,
      "vividness":1,
      "r":138,
      "g":139,
      "b":131,
      "hex":"#8a8b83",
      "file_location":"assets/colors_organized/hue_07/brightness_09/2020040415152600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":9,
      "vividness":2,
      "r":136,
      "g":139,
      "b":120,
      "hex":"#888b78",
      "file_location":"assets/colors_organized/hue_07/brightness_09/2020040415152601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":9,
      "vividness":3,
      "r":134,
      "g":138,
      "b":111,
      "hex":"#868a6f",
      "file_location":"assets/colors_organized/hue_07/brightness_09/2020040415152700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":9,
      "vividness":4,
      "r":132,
      "g":140,
      "b":101,
      "hex":"#848c65",
      "file_location":"assets/colors_organized/hue_07/brightness_09/2020040415152800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":9,
      "vividness":5,
      "r":130,
      "g":139,
      "b":92,
      "hex":"#828b5c",
      "file_location":"assets/colors_organized/hue_07/brightness_09/2020040415152801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":9,
      "vividness":6,
      "r":128,
      "g":139,
      "b":83,
      "hex":"#808b53",
      "file_location":"assets/colors_organized/hue_07/brightness_09/2020040415152900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":9,
      "vividness":7,
      "r":127,
      "g":139,
      "b":73,
      "hex":"#7f8b49",
      "file_location":"assets/colors_organized/hue_07/brightness_09/2020040415152901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":9,
      "vividness":8,
      "r":123,
      "g":139,
      "b":64,
      "hex":"#7b8b40",
      "file_location":"assets/colors_organized/hue_07/brightness_09/2020040415153000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":9,
      "vividness":9,
      "r":124,
      "g":139,
      "b":54,
      "hex":"#7c8b36",
      "file_location":"assets/colors_organized/hue_07/brightness_09/2020040415153100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":9,
      "vividness":10,
      "r":120,
      "g":140,
      "b":45,
      "hex":"#788c2d",
      "file_location":"assets/colors_organized/hue_07/brightness_09/2020040415153101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":9,
      "vividness":11,
      "r":118,
      "g":139,
      "b":36,
      "hex":"#768b24",
      "file_location":"assets/colors_organized/hue_07/brightness_09/2020040415153200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":9,
      "vividness":12,
      "r":117,
      "g":140,
      "b":26,
      "hex":"#758c1a",
      "file_location":"assets/colors_organized/hue_07/brightness_09/2020040415153201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":9,
      "vividness":13,
      "r":115,
      "g":139,
      "b":17,
      "hex":"#738b11",
      "file_location":"assets/colors_organized/hue_07/brightness_09/2020040415153300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":9,
      "vividness":14,
      "r":112,
      "g":139,
      "b":8,
      "hex":"#708b08",
      "file_location":"assets/colors_organized/hue_07/brightness_09/2020040415153301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":10,
      "vividness":1,
      "r":152,
      "g":154,
      "b":143,
      "hex":"#989a8f",
      "file_location":"assets/colors_organized/hue_07/brightness_10/2020040415151401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":10,
      "vividness":2,
      "r":151,
      "g":154,
      "b":135,
      "hex":"#979a87",
      "file_location":"assets/colors_organized/hue_07/brightness_10/2020040415151500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":10,
      "vividness":3,
      "r":148,
      "g":155,
      "b":122,
      "hex":"#949b7a",
      "file_location":"assets/colors_organized/hue_07/brightness_10/2020040415151501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":10,
      "vividness":4,
      "r":146,
      "g":154,
      "b":113,
      "hex":"#929a71",
      "file_location":"assets/colors_organized/hue_07/brightness_10/2020040415151600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":10,
      "vividness":5,
      "r":144,
      "g":154,
      "b":102,
      "hex":"#909a66",
      "file_location":"assets/colors_organized/hue_07/brightness_10/2020040415151601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":10,
      "vividness":6,
      "r":142,
      "g":154,
      "b":92,
      "hex":"#8e9a5c",
      "file_location":"assets/colors_organized/hue_07/brightness_10/2020040415151700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":10,
      "vividness":7,
      "r":141,
      "g":154,
      "b":82,
      "hex":"#8d9a52",
      "file_location":"assets/colors_organized/hue_07/brightness_10/2020040415151800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":10,
      "vividness":8,
      "r":139,
      "g":154,
      "b":71,
      "hex":"#8b9a47",
      "file_location":"assets/colors_organized/hue_07/brightness_10/2020040415151801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":10,
      "vividness":9,
      "r":135,
      "g":155,
      "b":60,
      "hex":"#879b3c",
      "file_location":"assets/colors_organized/hue_07/brightness_10/2020040415151900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":10,
      "vividness":10,
      "r":133,
      "g":154,
      "b":49,
      "hex":"#859a31",
      "file_location":"assets/colors_organized/hue_07/brightness_10/2020040415151901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":10,
      "vividness":11,
      "r":132,
      "g":155,
      "b":41,
      "hex":"#849b29",
      "file_location":"assets/colors_organized/hue_07/brightness_10/2020040415152000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":10,
      "vividness":12,
      "r":130,
      "g":154,
      "b":30,
      "hex":"#829a1e",
      "file_location":"assets/colors_organized/hue_07/brightness_10/2020040415152001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":10,
      "vividness":13,
      "r":127,
      "g":155,
      "b":19,
      "hex":"#7f9b13",
      "file_location":"assets/colors_organized/hue_07/brightness_10/2020040415152100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":10,
      "vividness":14,
      "r":125,
      "g":155,
      "b":9,
      "hex":"#7d9b09",
      "file_location":"assets/colors_organized/hue_07/brightness_10/2020040415152101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":11,
      "vividness":1,
      "r":167,
      "g":169,
      "b":158,
      "hex":"#a7a99e",
      "file_location":"assets/colors_organized/hue_07/brightness_11/2020040415150200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":11,
      "vividness":2,
      "r":165,
      "g":168,
      "b":147,
      "hex":"#a5a893",
      "file_location":"assets/colors_organized/hue_07/brightness_11/2020040415150201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":11,
      "vividness":3,
      "r":162,
      "g":169,
      "b":135,
      "hex":"#a2a987",
      "file_location":"assets/colors_organized/hue_07/brightness_11/2020040415150300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":11,
      "vividness":4,
      "r":160,
      "g":169,
      "b":124,
      "hex":"#a0a97c",
      "file_location":"assets/colors_organized/hue_07/brightness_11/2020040415150400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":11,
      "vividness":5,
      "r":158,
      "g":169,
      "b":113,
      "hex":"#9ea971",
      "file_location":"assets/colors_organized/hue_07/brightness_11/2020040415150401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":11,
      "vividness":6,
      "r":156,
      "g":169,
      "b":100,
      "hex":"#9ca964",
      "file_location":"assets/colors_organized/hue_07/brightness_11/2020040415150500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":11,
      "vividness":7,
      "r":152,
      "g":169,
      "b":89,
      "hex":"#98a959",
      "file_location":"assets/colors_organized/hue_07/brightness_11/2020040415150501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":11,
      "vividness":8,
      "r":150,
      "g":169,
      "b":79,
      "hex":"#96a94f",
      "file_location":"assets/colors_organized/hue_07/brightness_11/2020040415150600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":11,
      "vividness":9,
      "r":149,
      "g":170,
      "b":67,
      "hex":"#95aa43",
      "file_location":"assets/colors_organized/hue_07/brightness_11/2020040415150700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":11,
      "vividness":10,
      "r":147,
      "g":170,
      "b":56,
      "hex":"#93aa38",
      "file_location":"assets/colors_organized/hue_07/brightness_11/2020040415150701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":11,
      "vividness":11,
      "r":144,
      "g":168,
      "b":44,
      "hex":"#90a82c",
      "file_location":"assets/colors_organized/hue_07/brightness_11/2020040415150800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":11,
      "vividness":12,
      "r":142,
      "g":170,
      "b":34,
      "hex":"#8eaa22",
      "file_location":"assets/colors_organized/hue_07/brightness_11/2020040415150801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":11,
      "vividness":13,
      "r":140,
      "g":170,
      "b":22,
      "hex":"#8caa16",
      "file_location":"assets/colors_organized/hue_07/brightness_11/2020040415150900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":11,
      "vividness":14,
      "r":138,
      "g":170,
      "b":9,
      "hex":"#8aaa09",
      "file_location":"assets/colors_organized/hue_07/brightness_11/2020040415150901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":12,
      "vividness":1,
      "r":182,
      "g":184,
      "b":171,
      "hex":"#b6b8ab",
      "file_location":"assets/colors_organized/hue_07/brightness_12/2020040415145001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":12,
      "vividness":2,
      "r":179,
      "g":184,
      "b":161,
      "hex":"#b3b8a1",
      "file_location":"assets/colors_organized/hue_07/brightness_12/2020040415145100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":12,
      "vividness":3,
      "r":177,
      "g":185,
      "b":148,
      "hex":"#b1b994",
      "file_location":"assets/colors_organized/hue_07/brightness_12/2020040415145101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":12,
      "vividness":4,
      "r":175,
      "g":185,
      "b":135,
      "hex":"#afb987",
      "file_location":"assets/colors_organized/hue_07/brightness_12/2020040415145200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":12,
      "vividness":5,
      "r":173,
      "g":185,
      "b":123,
      "hex":"#adb97b",
      "file_location":"assets/colors_organized/hue_07/brightness_12/2020040415145300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":12,
      "vividness":6,
      "r":168,
      "g":184,
      "b":109,
      "hex":"#a8b86d",
      "file_location":"assets/colors_organized/hue_07/brightness_12/2020040415145301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":12,
      "vividness":7,
      "r":166,
      "g":184,
      "b":98,
      "hex":"#a6b862",
      "file_location":"assets/colors_organized/hue_07/brightness_12/2020040415145400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":12,
      "vividness":8,
      "r":164,
      "g":184,
      "b":85,
      "hex":"#a4b855",
      "file_location":"assets/colors_organized/hue_07/brightness_12/2020040415145401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":12,
      "vividness":9,
      "r":162,
      "g":184,
      "b":73,
      "hex":"#a2b849",
      "file_location":"assets/colors_organized/hue_07/brightness_12/2020040415145500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":12,
      "vividness":10,
      "r":160,
      "g":184,
      "b":60,
      "hex":"#a0b83c",
      "file_location":"assets/colors_organized/hue_07/brightness_12/2020040415145501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":12,
      "vividness":11,
      "r":157,
      "g":185,
      "b":49,
      "hex":"#9db931",
      "file_location":"assets/colors_organized/hue_07/brightness_12/2020040415145600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":12,
      "vividness":12,
      "r":155,
      "g":185,
      "b":35,
      "hex":"#9bb923",
      "file_location":"assets/colors_organized/hue_07/brightness_12/2020040415145700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":12,
      "vividness":13,
      "r":152,
      "g":184,
      "b":21,
      "hex":"#98b815",
      "file_location":"assets/colors_organized/hue_07/brightness_12/2020040415145701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":12,
      "vividness":14,
      "r":150,
      "g":184,
      "b":12,
      "hex":"#96b80c",
      "file_location":"assets/colors_organized/hue_07/brightness_12/2020040415145800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":13,
      "vividness":1,
      "r":196,
      "g":200,
      "b":186,
      "hex":"#c4c8ba",
      "file_location":"assets/colors_organized/hue_07/brightness_13/2020040415143800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":13,
      "vividness":2,
      "r":195,
      "g":199,
      "b":174,
      "hex":"#c3c7ae",
      "file_location":"assets/colors_organized/hue_07/brightness_13/2020040415143900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":13,
      "vividness":3,
      "r":191,
      "g":199,
      "b":158,
      "hex":"#bfc79e",
      "file_location":"assets/colors_organized/hue_07/brightness_13/2020040415143901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":13,
      "vividness":4,
      "r":189,
      "g":199,
      "b":146,
      "hex":"#bdc792",
      "file_location":"assets/colors_organized/hue_07/brightness_13/2020040415144000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":13,
      "vividness":5,
      "r":187,
      "g":199,
      "b":133,
      "hex":"#bbc785",
      "file_location":"assets/colors_organized/hue_07/brightness_13/2020040415144001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":13,
      "vividness":6,
      "r":182,
      "g":200,
      "b":118,
      "hex":"#b6c876",
      "file_location":"assets/colors_organized/hue_07/brightness_13/2020040415144100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":13,
      "vividness":7,
      "r":180,
      "g":200,
      "b":105,
      "hex":"#b4c869",
      "file_location":"assets/colors_organized/hue_07/brightness_13/2020040415144200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":13,
      "vividness":8,
      "r":178,
      "g":200,
      "b":92,
      "hex":"#b2c85c",
      "file_location":"assets/colors_organized/hue_07/brightness_13/2020040415144300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":13,
      "vividness":9,
      "r":175,
      "g":199,
      "b":79,
      "hex":"#afc74f",
      "file_location":"assets/colors_organized/hue_07/brightness_13/2020040415144301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":13,
      "vividness":10,
      "r":173,
      "g":199,
      "b":64,
      "hex":"#adc740",
      "file_location":"assets/colors_organized/hue_07/brightness_13/2020040415144400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":13,
      "vividness":11,
      "r":170,
      "g":200,
      "b":52,
      "hex":"#aac834",
      "file_location":"assets/colors_organized/hue_07/brightness_13/2020040415144401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":13,
      "vividness":12,
      "r":167,
      "g":199,
      "b":38,
      "hex":"#a7c726",
      "file_location":"assets/colors_organized/hue_07/brightness_13/2020040415144500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":13,
      "vividness":13,
      "r":165,
      "g":199,
      "b":25,
      "hex":"#a5c719",
      "file_location":"assets/colors_organized/hue_07/brightness_13/2020040415144501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":13,
      "vividness":14,
      "r":161,
      "g":200,
      "b":13,
      "hex":"#a1c80d",
      "file_location":"assets/colors_organized/hue_07/brightness_13/2020040415144600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":14,
      "vividness":1,
      "r":212,
      "g":214,
      "b":200,
      "hex":"#d4d6c8",
      "file_location":"assets/colors_organized/hue_07/brightness_14/2020040415142600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":14,
      "vividness":2,
      "r":208,
      "g":215,
      "b":184,
      "hex":"#d0d7b8",
      "file_location":"assets/colors_organized/hue_07/brightness_14/2020040415142601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":14,
      "vividness":3,
      "r":206,
      "g":215,
      "b":172,
      "hex":"#ced7ac",
      "file_location":"assets/colors_organized/hue_07/brightness_14/2020040415142700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":14,
      "vividness":4,
      "r":204,
      "g":215,
      "b":157,
      "hex":"#ccd79d",
      "file_location":"assets/colors_organized/hue_07/brightness_14/2020040415142800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":14,
      "vividness":5,
      "r":201,
      "g":214,
      "b":144,
      "hex":"#c9d690",
      "file_location":"assets/colors_organized/hue_07/brightness_14/2020040415142801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":14,
      "vividness":6,
      "r":197,
      "g":215,
      "b":129,
      "hex":"#c5d781",
      "file_location":"assets/colors_organized/hue_07/brightness_14/2020040415142900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":14,
      "vividness":7,
      "r":194,
      "g":215,
      "b":114,
      "hex":"#c2d772",
      "file_location":"assets/colors_organized/hue_07/brightness_14/2020040415143000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":14,
      "vividness":8,
      "r":191,
      "g":214,
      "b":100,
      "hex":"#bfd664",
      "file_location":"assets/colors_organized/hue_07/brightness_14/2020040415143001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":14,
      "vividness":9,
      "r":188,
      "g":215,
      "b":84,
      "hex":"#bcd754",
      "file_location":"assets/colors_organized/hue_07/brightness_14/2020040415143100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":14,
      "vividness":10,
      "r":185,
      "g":214,
      "b":70,
      "hex":"#b9d646",
      "file_location":"assets/colors_organized/hue_07/brightness_14/2020040415143101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":14,
      "vividness":11,
      "r":183,
      "g":215,
      "b":56,
      "hex":"#b7d738",
      "file_location":"assets/colors_organized/hue_07/brightness_14/2020040415143200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":14,
      "vividness":12,
      "r":180,
      "g":214,
      "b":40,
      "hex":"#b4d628",
      "file_location":"assets/colors_organized/hue_07/brightness_14/2020040415143300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":14,
      "vividness":13,
      "r":176,
      "g":215,
      "b":28,
      "hex":"#b0d71c",
      "file_location":"assets/colors_organized/hue_07/brightness_14/2020040415143301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":14,
      "vividness":14,
      "r":173,
      "g":214,
      "b":12,
      "hex":"#add60c",
      "file_location":"assets/colors_organized/hue_07/brightness_14/2020040415143400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":15,
      "vividness":1,
      "r":227,
      "g":229,
      "b":215,
      "hex":"#e3e5d7",
      "file_location":"assets/colors_organized/hue_07/brightness_15/2020040415141400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":15,
      "vividness":2,
      "r":223,
      "g":230,
      "b":197,
      "hex":"#dfe6c5",
      "file_location":"assets/colors_organized/hue_07/brightness_15/2020040415141401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":15,
      "vividness":3,
      "r":220,
      "g":229,
      "b":182,
      "hex":"#dce5b6",
      "file_location":"assets/colors_organized/hue_07/brightness_15/2020040415141500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":15,
      "vividness":4,
      "r":218,
      "g":230,
      "b":168,
      "hex":"#dae6a8",
      "file_location":"assets/colors_organized/hue_07/brightness_15/2020040415141600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":15,
      "vividness":5,
      "r":215,
      "g":229,
      "b":152,
      "hex":"#d7e598",
      "file_location":"assets/colors_organized/hue_07/brightness_15/2020040415141601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":15,
      "vividness":6,
      "r":211,
      "g":230,
      "b":138,
      "hex":"#d3e68a",
      "file_location":"assets/colors_organized/hue_07/brightness_15/2020040415141700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":15,
      "vividness":7,
      "r":208,
      "g":230,
      "b":122,
      "hex":"#d0e67a",
      "file_location":"assets/colors_organized/hue_07/brightness_15/2020040415141800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":15,
      "vividness":8,
      "r":205,
      "g":229,
      "b":107,
      "hex":"#cde56b",
      "file_location":"assets/colors_organized/hue_07/brightness_15/2020040415141801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":15,
      "vividness":9,
      "r":201,
      "g":230,
      "b":90,
      "hex":"#c9e65a",
      "file_location":"assets/colors_organized/hue_07/brightness_15/2020040415141900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":15,
      "vividness":10,
      "r":199,
      "g":230,
      "b":75,
      "hex":"#c7e64b",
      "file_location":"assets/colors_organized/hue_07/brightness_15/2020040415141901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":15,
      "vividness":11,
      "r":196,
      "g":229,
      "b":60,
      "hex":"#c4e53c",
      "file_location":"assets/colors_organized/hue_07/brightness_15/2020040415142000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":15,
      "vividness":12,
      "r":191,
      "g":229,
      "b":45,
      "hex":"#bfe52d",
      "file_location":"assets/colors_organized/hue_07/brightness_15/2020040415142100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":15,
      "vividness":13,
      "r":189,
      "g":230,
      "b":30,
      "hex":"#bde61e",
      "file_location":"assets/colors_organized/hue_07/brightness_15/2020040415142101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":7,
      "brightness":15,
      "vividness":14,
      "r":186,
      "g":229,
      "b":15,
      "hex":"#bae50f",
      "file_location":"assets/colors_organized/hue_07/brightness_15/2020040415142200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":1,
      "vividness":1,
      "r":17,
      "g":19,
      "b":14,
      "hex":"#11130e",
      "file_location":"assets/colors_organized/hue_08/brightness_01/2020040415201101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":1,
      "vividness":2,
      "r":14,
      "g":20,
      "b":10,
      "hex":"#0e140a",
      "file_location":"assets/colors_organized/hue_08/brightness_01/2020040415201301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":1,
      "vividness":3,
      "r":14,
      "g":20,
      "b":6,
      "hex":"#0e1406",
      "file_location":"assets/colors_organized/hue_08/brightness_01/2020040415201500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":1,
      "vividness":4,
      "r":12,
      "g":20,
      "b":7,
      "hex":"#0c1407",
      "file_location":"assets/colors_organized/hue_08/brightness_01/2020040415201501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":1,
      "vividness":5,
      "r":12,
      "g":19,
      "b":3,
      "hex":"#0c1303",
      "file_location":"assets/colors_organized/hue_08/brightness_01/2020040415201601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":1,
      "vividness":6,
      "r":12,
      "g":20,
      "b":0,
      "hex":"#0c1400",
      "file_location":"assets/colors_organized/hue_08/brightness_01/2020040415201800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":2,
      "vividness":1,
      "r":32,
      "g":35,
      "b":28,
      "hex":"#20231c",
      "file_location":"assets/colors_organized/hue_08/brightness_02/2020040415195900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":2,
      "vividness":2,
      "r":31,
      "g":34,
      "b":25,
      "hex":"#1f2219",
      "file_location":"assets/colors_organized/hue_08/brightness_02/2020040415195901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":2,
      "vividness":3,
      "r":29,
      "g":35,
      "b":23,
      "hex":"#1d2317",
      "file_location":"assets/colors_organized/hue_08/brightness_02/2020040415200000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":2,
      "vividness":4,
      "r":29,
      "g":35,
      "b":21,
      "hex":"#1d2315",
      "file_location":"assets/colors_organized/hue_08/brightness_02/2020040415200100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":2,
      "vividness":5,
      "r":27,
      "g":34,
      "b":18,
      "hex":"#1b2212",
      "file_location":"assets/colors_organized/hue_08/brightness_02/2020040415200101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":2,
      "vividness":6,
      "r":26,
      "g":35,
      "b":14,
      "hex":"#1a230e",
      "file_location":"assets/colors_organized/hue_08/brightness_02/2020040415200200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":2,
      "vividness":7,
      "r":27,
      "g":35,
      "b":12,
      "hex":"#1b230c",
      "file_location":"assets/colors_organized/hue_08/brightness_02/2020040415200201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":2,
      "vividness":8,
      "r":25,
      "g":35,
      "b":10,
      "hex":"#19230a",
      "file_location":"assets/colors_organized/hue_08/brightness_02/2020040415200300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":2,
      "vividness":9,
      "r":22,
      "g":35,
      "b":9,
      "hex":"#162309",
      "file_location":"assets/colors_organized/hue_08/brightness_02/2020040415200301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":2,
      "vividness":10,
      "r":22,
      "g":35,
      "b":7,
      "hex":"#162307",
      "file_location":"assets/colors_organized/hue_08/brightness_02/2020040415200400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":2,
      "vividness":11,
      "r":21,
      "g":35,
      "b":2,
      "hex":"#152302",
      "file_location":"assets/colors_organized/hue_08/brightness_02/2020040415200401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":3,
      "vividness":1,
      "r":47,
      "g":49,
      "b":44,
      "hex":"#2f312c",
      "file_location":"assets/colors_organized/hue_08/brightness_03/2020040415194500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":3,
      "vividness":2,
      "r":46,
      "g":49,
      "b":40,
      "hex":"#2e3128",
      "file_location":"assets/colors_organized/hue_08/brightness_03/2020040415194601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":3,
      "vividness":3,
      "r":43,
      "g":49,
      "b":35,
      "hex":"#2b3123",
      "file_location":"assets/colors_organized/hue_08/brightness_03/2020040415194700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":3,
      "vividness":4,
      "r":43,
      "g":50,
      "b":34,
      "hex":"#2b3222",
      "file_location":"assets/colors_organized/hue_08/brightness_03/2020040415194701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":3,
      "vividness":5,
      "r":41,
      "g":50,
      "b":29,
      "hex":"#29321d",
      "file_location":"assets/colors_organized/hue_08/brightness_03/2020040415194800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":3,
      "vividness":6,
      "r":40,
      "g":50,
      "b":26,
      "hex":"#28321a",
      "file_location":"assets/colors_organized/hue_08/brightness_03/2020040415194900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":3,
      "vividness":7,
      "r":39,
      "g":49,
      "b":22,
      "hex":"#273116",
      "file_location":"assets/colors_organized/hue_08/brightness_03/2020040415194901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":3,
      "vividness":8,
      "r":37,
      "g":50,
      "b":20,
      "hex":"#253214",
      "file_location":"assets/colors_organized/hue_08/brightness_03/2020040415195000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":3,
      "vividness":9,
      "r":35,
      "g":49,
      "b":14,
      "hex":"#23310e",
      "file_location":"assets/colors_organized/hue_08/brightness_03/2020040415195100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":3,
      "vividness":10,
      "r":34,
      "g":50,
      "b":13,
      "hex":"#22320d",
      "file_location":"assets/colors_organized/hue_08/brightness_03/2020040415195101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":3,
      "vividness":11,
      "r":33,
      "g":49,
      "b":10,
      "hex":"#21310a",
      "file_location":"assets/colors_organized/hue_08/brightness_03/2020040415195200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":3,
      "vividness":12,
      "r":31,
      "g":50,
      "b":5,
      "hex":"#1f3205",
      "file_location":"assets/colors_organized/hue_08/brightness_03/2020040415195300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":3,
      "vividness":13,
      "r":30,
      "g":49,
      "b":3,
      "hex":"#1e3103",
      "file_location":"assets/colors_organized/hue_08/brightness_03/2020040415195301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":4,
      "vividness":1,
      "r":63,
      "g":65,
      "b":60,
      "hex":"#3f413c",
      "file_location":"assets/colors_organized/hue_08/brightness_04/2020040415193201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":4,
      "vividness":2,
      "r":59,
      "g":65,
      "b":55,
      "hex":"#3b4137",
      "file_location":"assets/colors_organized/hue_08/brightness_04/2020040415193300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":4,
      "vividness":3,
      "r":58,
      "g":64,
      "b":50,
      "hex":"#3a4032",
      "file_location":"assets/colors_organized/hue_08/brightness_04/2020040415193400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":4,
      "vividness":4,
      "r":58,
      "g":65,
      "b":47,
      "hex":"#3a412f",
      "file_location":"assets/colors_organized/hue_08/brightness_04/2020040415193401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":4,
      "vividness":5,
      "r":56,
      "g":66,
      "b":42,
      "hex":"#38422a",
      "file_location":"assets/colors_organized/hue_08/brightness_04/2020040415193500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":4,
      "vividness":6,
      "r":52,
      "g":65,
      "b":39,
      "hex":"#344127",
      "file_location":"assets/colors_organized/hue_08/brightness_04/2020040415193501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":4,
      "vividness":7,
      "r":52,
      "g":66,
      "b":33,
      "hex":"#344221",
      "file_location":"assets/colors_organized/hue_08/brightness_04/2020040415193600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":4,
      "vividness":8,
      "r":51,
      "g":65,
      "b":30,
      "hex":"#33411e",
      "file_location":"assets/colors_organized/hue_08/brightness_04/2020040415193601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":4,
      "vividness":9,
      "r":49,
      "g":65,
      "b":26,
      "hex":"#31411a",
      "file_location":"assets/colors_organized/hue_08/brightness_04/2020040415193700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":4,
      "vividness":10,
      "r":46,
      "g":65,
      "b":20,
      "hex":"#2e4114",
      "file_location":"assets/colors_organized/hue_08/brightness_04/2020040415193800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":4,
      "vividness":11,
      "r":46,
      "g":66,
      "b":17,
      "hex":"#2e4211",
      "file_location":"assets/colors_organized/hue_08/brightness_04/2020040415193801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":4,
      "vividness":12,
      "r":42,
      "g":65,
      "b":11,
      "hex":"#2a410b",
      "file_location":"assets/colors_organized/hue_08/brightness_04/2020040415193900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":4,
      "vividness":13,
      "r":41,
      "g":65,
      "b":7,
      "hex":"#294107",
      "file_location":"assets/colors_organized/hue_08/brightness_04/2020040415193901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":4,
      "vividness":14,
      "r":39,
      "g":65,
      "b":2,
      "hex":"#274102",
      "file_location":"assets/colors_organized/hue_08/brightness_04/2020040415194000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":5,
      "vividness":1,
      "r":78,
      "g":81,
      "b":74,
      "hex":"#4e514a",
      "file_location":"assets/colors_organized/hue_08/brightness_05/2020040415192001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":5,
      "vividness":2,
      "r":74,
      "g":80,
      "b":68,
      "hex":"#4a5044",
      "file_location":"assets/colors_organized/hue_08/brightness_05/2020040415192100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":5,
      "vividness":3,
      "r":73,
      "g":80,
      "b":64,
      "hex":"#495040",
      "file_location":"assets/colors_organized/hue_08/brightness_05/2020040415192200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":5,
      "vividness":4,
      "r":71,
      "g":81,
      "b":57,
      "hex":"#475139",
      "file_location":"assets/colors_organized/hue_08/brightness_05/2020040415192201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":5,
      "vividness":5,
      "r":69,
      "g":79,
      "b":52,
      "hex":"#454f34",
      "file_location":"assets/colors_organized/hue_08/brightness_05/2020040415192300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":5,
      "vividness":6,
      "r":66,
      "g":80,
      "b":47,
      "hex":"#42502f",
      "file_location":"assets/colors_organized/hue_08/brightness_05/2020040415192301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":5,
      "vividness":7,
      "r":64,
      "g":80,
      "b":41,
      "hex":"#405029",
      "file_location":"assets/colors_organized/hue_08/brightness_05/2020040415192400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":5,
      "vividness":8,
      "r":63,
      "g":80,
      "b":36,
      "hex":"#3f5024",
      "file_location":"assets/colors_organized/hue_08/brightness_05/2020040415192500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":5,
      "vividness":9,
      "r":60,
      "g":80,
      "b":31,
      "hex":"#3c501f",
      "file_location":"assets/colors_organized/hue_08/brightness_05/2020040415192501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":5,
      "vividness":10,
      "r":57,
      "g":80,
      "b":26,
      "hex":"#39501a",
      "file_location":"assets/colors_organized/hue_08/brightness_05/2020040415192600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":5,
      "vividness":11,
      "r":55,
      "g":80,
      "b":22,
      "hex":"#375016",
      "file_location":"assets/colors_organized/hue_08/brightness_05/2020040415192601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":5,
      "vividness":12,
      "r":54,
      "g":80,
      "b":15,
      "hex":"#36500f",
      "file_location":"assets/colors_organized/hue_08/brightness_05/2020040415192700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":5,
      "vividness":13,
      "r":50,
      "g":80,
      "b":8,
      "hex":"#325008",
      "file_location":"assets/colors_organized/hue_08/brightness_05/2020040415192701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":5,
      "vividness":14,
      "r":49,
      "g":79,
      "b":5,
      "hex":"#314f05",
      "file_location":"assets/colors_organized/hue_08/brightness_05/2020040415192800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":6,
      "vividness":1,
      "r":89,
      "g":95,
      "b":81,
      "hex":"#595f51",
      "file_location":"assets/colors_organized/hue_08/brightness_06/2020040415191000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":6,
      "vividness":2,
      "r":86,
      "g":95,
      "b":76,
      "hex":"#565f4c",
      "file_location":"assets/colors_organized/hue_08/brightness_06/2020040415191001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":6,
      "vividness":3,
      "r":83,
      "g":96,
      "b":70,
      "hex":"#536046",
      "file_location":"assets/colors_organized/hue_08/brightness_06/2020040415191100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":6,
      "vividness":4,
      "r":81,
      "g":95,
      "b":62,
      "hex":"#515f3e",
      "file_location":"assets/colors_organized/hue_08/brightness_06/2020040415191101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":6,
      "vividness":5,
      "r":79,
      "g":95,
      "b":56,
      "hex":"#4f5f38",
      "file_location":"assets/colors_organized/hue_08/brightness_06/2020040415191200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":6,
      "vividness":6,
      "r":77,
      "g":94,
      "b":49,
      "hex":"#4d5e31",
      "file_location":"assets/colors_organized/hue_08/brightness_06/2020040415191300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":6,
      "vividness":7,
      "r":75,
      "g":95,
      "b":44,
      "hex":"#4b5f2c",
      "file_location":"assets/colors_organized/hue_08/brightness_06/2020040415191301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":6,
      "vividness":8,
      "r":71,
      "g":95,
      "b":37,
      "hex":"#475f25",
      "file_location":"assets/colors_organized/hue_08/brightness_06/2020040415191400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":6,
      "vividness":9,
      "r":69,
      "g":95,
      "b":30,
      "hex":"#455f1e",
      "file_location":"assets/colors_organized/hue_08/brightness_06/2020040415191401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":6,
      "vividness":10,
      "r":67,
      "g":94,
      "b":23,
      "hex":"#435e17",
      "file_location":"assets/colors_organized/hue_08/brightness_06/2020040415191500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":6,
      "vividness":11,
      "r":63,
      "g":95,
      "b":19,
      "hex":"#3f5f13",
      "file_location":"assets/colors_organized/hue_08/brightness_06/2020040415191501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":6,
      "vividness":12,
      "r":61,
      "g":95,
      "b":11,
      "hex":"#3d5f0b",
      "file_location":"assets/colors_organized/hue_08/brightness_06/2020040415191600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":6,
      "vividness":13,
      "r":59,
      "g":95,
      "b":7,
      "hex":"#3b5f07",
      "file_location":"assets/colors_organized/hue_08/brightness_06/2020040415191601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":7,
      "vividness":1,
      "r":107,
      "g":110,
      "b":101,
      "hex":"#6b6e65",
      "file_location":"assets/colors_organized/hue_08/brightness_07/2020040415185800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":7,
      "vividness":2,
      "r":103,
      "g":110,
      "b":94,
      "hex":"#676e5e",
      "file_location":"assets/colors_organized/hue_08/brightness_07/2020040415185801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":7,
      "vividness":3,
      "r":101,
      "g":111,
      "b":87,
      "hex":"#656f57",
      "file_location":"assets/colors_organized/hue_08/brightness_07/2020040415185900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":7,
      "vividness":4,
      "r":97,
      "g":110,
      "b":80,
      "hex":"#616e50",
      "file_location":"assets/colors_organized/hue_08/brightness_07/2020040415190000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":7,
      "vividness":5,
      "r":94,
      "g":110,
      "b":73,
      "hex":"#5e6e49",
      "file_location":"assets/colors_organized/hue_08/brightness_07/2020040415190001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":7,
      "vividness":6,
      "r":91,
      "g":110,
      "b":65,
      "hex":"#5b6e41",
      "file_location":"assets/colors_organized/hue_08/brightness_07/2020040415190100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":7,
      "vividness":7,
      "r":90,
      "g":110,
      "b":57,
      "hex":"#5a6e39",
      "file_location":"assets/colors_organized/hue_08/brightness_07/2020040415190101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":7,
      "vividness":8,
      "r":86,
      "g":110,
      "b":50,
      "hex":"#566e32",
      "file_location":"assets/colors_organized/hue_08/brightness_07/2020040415190200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":7,
      "vividness":9,
      "r":83,
      "g":110,
      "b":43,
      "hex":"#536e2b",
      "file_location":"assets/colors_organized/hue_08/brightness_07/2020040415190201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":7,
      "vividness":10,
      "r":79,
      "g":109,
      "b":35,
      "hex":"#4f6d23",
      "file_location":"assets/colors_organized/hue_08/brightness_07/2020040415190300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":7,
      "vividness":11,
      "r":77,
      "g":110,
      "b":29,
      "hex":"#4d6e1d",
      "file_location":"assets/colors_organized/hue_08/brightness_07/2020040415190400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":7,
      "vividness":12,
      "r":74,
      "g":110,
      "b":22,
      "hex":"#4a6e16",
      "file_location":"assets/colors_organized/hue_08/brightness_07/2020040415190401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":7,
      "vividness":13,
      "r":70,
      "g":110,
      "b":13,
      "hex":"#466e0d",
      "file_location":"assets/colors_organized/hue_08/brightness_07/2020040415190500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":7,
      "vividness":14,
      "r":67,
      "g":110,
      "b":5,
      "hex":"#436e05",
      "file_location":"assets/colors_organized/hue_08/brightness_07/2020040415190501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":8,
      "vividness":1,
      "r":121,
      "g":124,
      "b":115,
      "hex":"#797c73",
      "file_location":"assets/colors_organized/hue_08/brightness_08/2020040415184601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":8,
      "vividness":2,
      "r":117,
      "g":124,
      "b":108,
      "hex":"#757c6c",
      "file_location":"assets/colors_organized/hue_08/brightness_08/2020040415184700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":8,
      "vividness":3,
      "r":114,
      "g":124,
      "b":99,
      "hex":"#727c63",
      "file_location":"assets/colors_organized/hue_08/brightness_08/2020040415184701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":8,
      "vividness":4,
      "r":110,
      "g":124,
      "b":91,
      "hex":"#6e7c5b",
      "file_location":"assets/colors_organized/hue_08/brightness_08/2020040415184800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":8,
      "vividness":5,
      "r":107,
      "g":124,
      "b":82,
      "hex":"#6b7c52",
      "file_location":"assets/colors_organized/hue_08/brightness_08/2020040415184900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":8,
      "vividness":6,
      "r":104,
      "g":124,
      "b":73,
      "hex":"#687c49",
      "file_location":"assets/colors_organized/hue_08/brightness_08/2020040415184901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":8,
      "vividness":7,
      "r":100,
      "g":124,
      "b":66,
      "hex":"#647c42",
      "file_location":"assets/colors_organized/hue_08/brightness_08/2020040415185000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":8,
      "vividness":8,
      "r":98,
      "g":125,
      "b":58,
      "hex":"#627d3a",
      "file_location":"assets/colors_organized/hue_08/brightness_08/2020040415185001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":8,
      "vividness":9,
      "r":93,
      "g":124,
      "b":48,
      "hex":"#5d7c30",
      "file_location":"assets/colors_organized/hue_08/brightness_08/2020040415185100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":8,
      "vividness":10,
      "r":90,
      "g":124,
      "b":40,
      "hex":"#5a7c28",
      "file_location":"assets/colors_organized/hue_08/brightness_08/2020040415185200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":8,
      "vividness":11,
      "r":88,
      "g":124,
      "b":34,
      "hex":"#587c22",
      "file_location":"assets/colors_organized/hue_08/brightness_08/2020040415185201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":8,
      "vividness":12,
      "r":83,
      "g":124,
      "b":22,
      "hex":"#537c16",
      "file_location":"assets/colors_organized/hue_08/brightness_08/2020040415185300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":8,
      "vividness":13,
      "r":81,
      "g":125,
      "b":14,
      "hex":"#517d0e",
      "file_location":"assets/colors_organized/hue_08/brightness_08/2020040415185301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":8,
      "vividness":14,
      "r":77,
      "g":125,
      "b":7,
      "hex":"#4d7d07",
      "file_location":"assets/colors_organized/hue_08/brightness_08/2020040415185400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":9,
      "vividness":1,
      "r":136,
      "g":139,
      "b":130,
      "hex":"#888b82",
      "file_location":"assets/colors_organized/hue_08/brightness_09/2020040415183401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":9,
      "vividness":2,
      "r":132,
      "g":139,
      "b":121,
      "hex":"#848b79",
      "file_location":"assets/colors_organized/hue_08/brightness_09/2020040415183500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":9,
      "vividness":3,
      "r":129,
      "g":139,
      "b":112,
      "hex":"#818b70",
      "file_location":"assets/colors_organized/hue_08/brightness_09/2020040415183501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":9,
      "vividness":4,
      "r":123,
      "g":139,
      "b":102,
      "hex":"#7b8b66",
      "file_location":"assets/colors_organized/hue_08/brightness_09/2020040415183600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":9,
      "vividness":5,
      "r":121,
      "g":138,
      "b":93,
      "hex":"#798a5d",
      "file_location":"assets/colors_organized/hue_08/brightness_09/2020040415183700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":9,
      "vividness":6,
      "r":116,
      "g":140,
      "b":82,
      "hex":"#748c52",
      "file_location":"assets/colors_organized/hue_08/brightness_09/2020040415183701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":9,
      "vividness":7,
      "r":113,
      "g":139,
      "b":74,
      "hex":"#718b4a",
      "file_location":"assets/colors_organized/hue_08/brightness_09/2020040415183800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":9,
      "vividness":8,
      "r":109,
      "g":139,
      "b":65,
      "hex":"#6d8b41",
      "file_location":"assets/colors_organized/hue_08/brightness_09/2020040415183900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":9,
      "vividness":9,
      "r":106,
      "g":140,
      "b":54,
      "hex":"#6a8c36",
      "file_location":"assets/colors_organized/hue_08/brightness_09/2020040415183901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":9,
      "vividness":10,
      "r":102,
      "g":139,
      "b":44,
      "hex":"#668b2c",
      "file_location":"assets/colors_organized/hue_08/brightness_09/2020040415184000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":9,
      "vividness":11,
      "r":98,
      "g":139,
      "b":37,
      "hex":"#628b25",
      "file_location":"assets/colors_organized/hue_08/brightness_09/2020040415184001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":9,
      "vividness":12,
      "r":95,
      "g":139,
      "b":26,
      "hex":"#5f8b1a",
      "file_location":"assets/colors_organized/hue_08/brightness_09/2020040415184100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":9,
      "vividness":13,
      "r":91,
      "g":139,
      "b":17,
      "hex":"#5b8b11",
      "file_location":"assets/colors_organized/hue_08/brightness_09/2020040415184101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":9,
      "vividness":14,
      "r":87,
      "g":140,
      "b":10,
      "hex":"#578c0a",
      "file_location":"assets/colors_organized/hue_08/brightness_09/2020040415184200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":10,
      "vividness":1,
      "r":151,
      "g":154,
      "b":145,
      "hex":"#979a91",
      "file_location":"assets/colors_organized/hue_08/brightness_10/2020040415182200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":10,
      "vividness":2,
      "r":146,
      "g":154,
      "b":133,
      "hex":"#929a85",
      "file_location":"assets/colors_organized/hue_08/brightness_10/2020040415182201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":10,
      "vividness":3,
      "r":141,
      "g":155,
      "b":122,
      "hex":"#8d9b7a",
      "file_location":"assets/colors_organized/hue_08/brightness_10/2020040415182300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":10,
      "vividness":4,
      "r":138,
      "g":155,
      "b":113,
      "hex":"#8a9b71",
      "file_location":"assets/colors_organized/hue_08/brightness_10/2020040415182400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":10,
      "vividness":5,
      "r":134,
      "g":154,
      "b":103,
      "hex":"#869a67",
      "file_location":"assets/colors_organized/hue_08/brightness_10/2020040415182401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":10,
      "vividness":6,
      "r":130,
      "g":154,
      "b":92,
      "hex":"#829a5c",
      "file_location":"assets/colors_organized/hue_08/brightness_10/2020040415182500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":10,
      "vividness":7,
      "r":124,
      "g":154,
      "b":82,
      "hex":"#7c9a52",
      "file_location":"assets/colors_organized/hue_08/brightness_10/2020040415182501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":10,
      "vividness":8,
      "r":121,
      "g":155,
      "b":71,
      "hex":"#799b47",
      "file_location":"assets/colors_organized/hue_08/brightness_10/2020040415182600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":10,
      "vividness":9,
      "r":117,
      "g":154,
      "b":59,
      "hex":"#759a3b",
      "file_location":"assets/colors_organized/hue_08/brightness_10/2020040415182700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":10,
      "vividness":10,
      "r":113,
      "g":154,
      "b":50,
      "hex":"#719a32",
      "file_location":"assets/colors_organized/hue_08/brightness_10/2020040415182701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":10,
      "vividness":11,
      "r":108,
      "g":155,
      "b":41,
      "hex":"#6c9b29",
      "file_location":"assets/colors_organized/hue_08/brightness_10/2020040415182800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":10,
      "vividness":12,
      "r":104,
      "g":154,
      "b":29,
      "hex":"#689a1d",
      "file_location":"assets/colors_organized/hue_08/brightness_10/2020040415182900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":10,
      "vividness":13,
      "r":101,
      "g":154,
      "b":20,
      "hex":"#659a14",
      "file_location":"assets/colors_organized/hue_08/brightness_10/2020040415182901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":10,
      "vividness":14,
      "r":97,
      "g":155,
      "b":9,
      "hex":"#619b09",
      "file_location":"assets/colors_organized/hue_08/brightness_10/2020040415183000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":11,
      "vividness":1,
      "r":166,
      "g":169,
      "b":158,
      "hex":"#a6a99e",
      "file_location":"assets/colors_organized/hue_08/brightness_11/2020040415180900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":11,
      "vividness":2,
      "r":160,
      "g":170,
      "b":146,
      "hex":"#a0aa92",
      "file_location":"assets/colors_organized/hue_08/brightness_11/2020040415180901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":11,
      "vividness":3,
      "r":155,
      "g":169,
      "b":134,
      "hex":"#9ba986",
      "file_location":"assets/colors_organized/hue_08/brightness_11/2020040415181000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":11,
      "vividness":4,
      "r":152,
      "g":169,
      "b":124,
      "hex":"#98a97c",
      "file_location":"assets/colors_organized/hue_08/brightness_11/2020040415181100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":11,
      "vividness":5,
      "r":146,
      "g":170,
      "b":112,
      "hex":"#92aa70",
      "file_location":"assets/colors_organized/hue_08/brightness_11/2020040415181101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":11,
      "vividness":6,
      "r":142,
      "g":169,
      "b":100,
      "hex":"#8ea964",
      "file_location":"assets/colors_organized/hue_08/brightness_11/2020040415181200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":11,
      "vividness":7,
      "r":138,
      "g":169,
      "b":89,
      "hex":"#8aa959",
      "file_location":"assets/colors_organized/hue_08/brightness_11/2020040415181300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":11,
      "vividness":8,
      "r":133,
      "g":169,
      "b":79,
      "hex":"#85a94f",
      "file_location":"assets/colors_organized/hue_08/brightness_11/2020040415181301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":11,
      "vividness":9,
      "r":129,
      "g":170,
      "b":66,
      "hex":"#81aa42",
      "file_location":"assets/colors_organized/hue_08/brightness_11/2020040415181400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":11,
      "vividness":10,
      "r":123,
      "g":170,
      "b":56,
      "hex":"#7baa38",
      "file_location":"assets/colors_organized/hue_08/brightness_11/2020040415181500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":11,
      "vividness":11,
      "r":119,
      "g":169,
      "b":44,
      "hex":"#77a92c",
      "file_location":"assets/colors_organized/hue_08/brightness_11/2020040415181501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":11,
      "vividness":12,
      "r":115,
      "g":169,
      "b":32,
      "hex":"#73a920",
      "file_location":"assets/colors_organized/hue_08/brightness_11/2020040415181600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":11,
      "vividness":13,
      "r":110,
      "g":170,
      "b":22,
      "hex":"#6eaa16",
      "file_location":"assets/colors_organized/hue_08/brightness_11/2020040415181601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":11,
      "vividness":14,
      "r":105,
      "g":169,
      "b":10,
      "hex":"#69a90a",
      "file_location":"assets/colors_organized/hue_08/brightness_11/2020040415181700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":12,
      "vividness":1,
      "r":178,
      "g":184,
      "b":172,
      "hex":"#b2b8ac",
      "file_location":"assets/colors_organized/hue_08/brightness_12/2020040415175600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":12,
      "vividness":2,
      "r":174,
      "g":184,
      "b":160,
      "hex":"#aeb8a0",
      "file_location":"assets/colors_organized/hue_08/brightness_12/2020040415175601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":12,
      "vividness":3,
      "r":168,
      "g":184,
      "b":147,
      "hex":"#a8b893",
      "file_location":"assets/colors_organized/hue_08/brightness_12/2020040415175700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":12,
      "vividness":4,
      "r":165,
      "g":185,
      "b":136,
      "hex":"#a5b988",
      "file_location":"assets/colors_organized/hue_08/brightness_12/2020040415175800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":12,
      "vividness":5,
      "r":160,
      "g":184,
      "b":122,
      "hex":"#a0b87a",
      "file_location":"assets/colors_organized/hue_08/brightness_12/2020040415175801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":12,
      "vividness":6,
      "r":154,
      "g":184,
      "b":110,
      "hex":"#9ab86e",
      "file_location":"assets/colors_organized/hue_08/brightness_12/2020040415175900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":12,
      "vividness":7,
      "r":150,
      "g":184,
      "b":97,
      "hex":"#96b861",
      "file_location":"assets/colors_organized/hue_08/brightness_12/2020040415180000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":12,
      "vividness":8,
      "r":144,
      "g":184,
      "b":85,
      "hex":"#90b855",
      "file_location":"assets/colors_organized/hue_08/brightness_12/2020040415180001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":12,
      "vividness":9,
      "r":140,
      "g":184,
      "b":73,
      "hex":"#8cb849",
      "file_location":"assets/colors_organized/hue_08/brightness_12/2020040415180100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":12,
      "vividness":10,
      "r":134,
      "g":184,
      "b":59,
      "hex":"#86b83b",
      "file_location":"assets/colors_organized/hue_08/brightness_12/2020040415180101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":12,
      "vividness":11,
      "r":131,
      "g":185,
      "b":48,
      "hex":"#83b930",
      "file_location":"assets/colors_organized/hue_08/brightness_12/2020040415180200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":12,
      "vividness":12,
      "r":124,
      "g":184,
      "b":36,
      "hex":"#7cb824",
      "file_location":"assets/colors_organized/hue_08/brightness_12/2020040415180300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":12,
      "vividness":13,
      "r":120,
      "g":185,
      "b":23,
      "hex":"#78b917",
      "file_location":"assets/colors_organized/hue_08/brightness_12/2020040415180301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":12,
      "vividness":14,
      "r":115,
      "g":184,
      "b":13,
      "hex":"#73b80d",
      "file_location":"assets/colors_organized/hue_08/brightness_12/2020040415180400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":13,
      "vividness":1,
      "r":193,
      "g":199,
      "b":185,
      "hex":"#c1c7b9",
      "file_location":"assets/colors_organized/hue_08/brightness_13/2020040415174300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":13,
      "vividness":2,
      "r":187,
      "g":200,
      "b":174,
      "hex":"#bbc8ae",
      "file_location":"assets/colors_organized/hue_08/brightness_13/2020040415174400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":13,
      "vividness":3,
      "r":183,
      "g":200,
      "b":158,
      "hex":"#b7c89e",
      "file_location":"assets/colors_organized/hue_08/brightness_13/2020040415174401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":13,
      "vividness":4,
      "r":179,
      "g":199,
      "b":146,
      "hex":"#b3c792",
      "file_location":"assets/colors_organized/hue_08/brightness_13/2020040415174500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":13,
      "vividness":5,
      "r":173,
      "g":200,
      "b":133,
      "hex":"#adc885",
      "file_location":"assets/colors_organized/hue_08/brightness_13/2020040415174600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":13,
      "vividness":6,
      "r":167,
      "g":200,
      "b":119,
      "hex":"#a7c877",
      "file_location":"assets/colors_organized/hue_08/brightness_13/2020040415174601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":13,
      "vividness":7,
      "r":163,
      "g":200,
      "b":105,
      "hex":"#a3c869",
      "file_location":"assets/colors_organized/hue_08/brightness_13/2020040415174700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":13,
      "vividness":8,
      "r":157,
      "g":200,
      "b":93,
      "hex":"#9dc85d",
      "file_location":"assets/colors_organized/hue_08/brightness_13/2020040415174800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":13,
      "vividness":9,
      "r":151,
      "g":199,
      "b":79,
      "hex":"#97c74f",
      "file_location":"assets/colors_organized/hue_08/brightness_13/2020040415174801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":13,
      "vividness":10,
      "r":146,
      "g":199,
      "b":65,
      "hex":"#92c741",
      "file_location":"assets/colors_organized/hue_08/brightness_13/2020040415174900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":13,
      "vividness":11,
      "r":141,
      "g":199,
      "b":52,
      "hex":"#8dc734",
      "file_location":"assets/colors_organized/hue_08/brightness_13/2020040415174901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":13,
      "vividness":12,
      "r":135,
      "g":200,
      "b":38,
      "hex":"#87c826",
      "file_location":"assets/colors_organized/hue_08/brightness_13/2020040415175000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":13,
      "vividness":13,
      "r":130,
      "g":200,
      "b":26,
      "hex":"#82c81a",
      "file_location":"assets/colors_organized/hue_08/brightness_13/2020040415175001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":13,
      "vividness":14,
      "r":125,
      "g":200,
      "b":12,
      "hex":"#7dc80c",
      "file_location":"assets/colors_organized/hue_08/brightness_13/2020040415175100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":14,
      "vividness":1,
      "r":208,
      "g":214,
      "b":200,
      "hex":"#d0d6c8",
      "file_location":"assets/colors_organized/hue_08/brightness_14/2020040415173000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":14,
      "vividness":2,
      "r":204,
      "g":215,
      "b":185,
      "hex":"#ccd7b9",
      "file_location":"assets/colors_organized/hue_08/brightness_14/2020040415173100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":14,
      "vividness":3,
      "r":197,
      "g":214,
      "b":170,
      "hex":"#c5d6aa",
      "file_location":"assets/colors_organized/hue_08/brightness_14/2020040415173101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":14,
      "vividness":4,
      "r":191,
      "g":215,
      "b":157,
      "hex":"#bfd79d",
      "file_location":"assets/colors_organized/hue_08/brightness_14/2020040415173200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":14,
      "vividness":5,
      "r":185,
      "g":215,
      "b":143,
      "hex":"#b9d78f",
      "file_location":"assets/colors_organized/hue_08/brightness_14/2020040415173201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":14,
      "vividness":6,
      "r":180,
      "g":214,
      "b":127,
      "hex":"#b4d67f",
      "file_location":"assets/colors_organized/hue_08/brightness_14/2020040415173300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":14,
      "vividness":7,
      "r":174,
      "g":215,
      "b":113,
      "hex":"#aed771",
      "file_location":"assets/colors_organized/hue_08/brightness_14/2020040415173301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":14,
      "vividness":8,
      "r":168,
      "g":215,
      "b":99,
      "hex":"#a8d763",
      "file_location":"assets/colors_organized/hue_08/brightness_14/2020040415173400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":14,
      "vividness":9,
      "r":163,
      "g":214,
      "b":85,
      "hex":"#a3d655",
      "file_location":"assets/colors_organized/hue_08/brightness_14/2020040415173500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":14,
      "vividness":10,
      "r":157,
      "g":214,
      "b":71,
      "hex":"#9dd647",
      "file_location":"assets/colors_organized/hue_08/brightness_14/2020040415173600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":14,
      "vividness":11,
      "r":151,
      "g":215,
      "b":58,
      "hex":"#97d73a",
      "file_location":"assets/colors_organized/hue_08/brightness_14/2020040415173601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":14,
      "vividness":12,
      "r":145,
      "g":215,
      "b":41,
      "hex":"#91d729",
      "file_location":"assets/colors_organized/hue_08/brightness_14/2020040415173700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":14,
      "vividness":13,
      "r":140,
      "g":215,
      "b":27,
      "hex":"#8cd71b",
      "file_location":"assets/colors_organized/hue_08/brightness_14/2020040415173701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":14,
      "vividness":14,
      "r":133,
      "g":214,
      "b":11,
      "hex":"#85d60b",
      "file_location":"assets/colors_organized/hue_08/brightness_14/2020040415173900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":15,
      "vividness":1,
      "r":223,
      "g":230,
      "b":214,
      "hex":"#dfe6d6",
      "file_location":"assets/colors_organized/hue_08/brightness_15/2020040415171801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":15,
      "vividness":2,
      "r":216,
      "g":230,
      "b":197,
      "hex":"#d8e6c5",
      "file_location":"assets/colors_organized/hue_08/brightness_15/2020040415171900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":15,
      "vividness":3,
      "r":212,
      "g":229,
      "b":184,
      "hex":"#d4e5b8",
      "file_location":"assets/colors_organized/hue_08/brightness_15/2020040415172000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":15,
      "vividness":4,
      "r":205,
      "g":229,
      "b":167,
      "hex":"#cde5a7",
      "file_location":"assets/colors_organized/hue_08/brightness_15/2020040415172001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":15,
      "vividness":5,
      "r":198,
      "g":229,
      "b":151,
      "hex":"#c6e597",
      "file_location":"assets/colors_organized/hue_08/brightness_15/2020040415172100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":15,
      "vividness":6,
      "r":193,
      "g":230,
      "b":137,
      "hex":"#c1e689",
      "file_location":"assets/colors_organized/hue_08/brightness_15/2020040415172101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":15,
      "vividness":7,
      "r":186,
      "g":229,
      "b":121,
      "hex":"#bae579",
      "file_location":"assets/colors_organized/hue_08/brightness_15/2020040415172200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":15,
      "vividness":8,
      "r":180,
      "g":230,
      "b":107,
      "hex":"#b4e66b",
      "file_location":"assets/colors_organized/hue_08/brightness_15/2020040415172201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":15,
      "vividness":9,
      "r":173,
      "g":230,
      "b":91,
      "hex":"#ade65b",
      "file_location":"assets/colors_organized/hue_08/brightness_15/2020040415172300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":15,
      "vividness":10,
      "r":169,
      "g":230,
      "b":75,
      "hex":"#a9e64b",
      "file_location":"assets/colors_organized/hue_08/brightness_15/2020040415172301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":15,
      "vividness":11,
      "r":162,
      "g":229,
      "b":61,
      "hex":"#a2e53d",
      "file_location":"assets/colors_organized/hue_08/brightness_15/2020040415172400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":15,
      "vividness":12,
      "r":155,
      "g":229,
      "b":44,
      "hex":"#9be52c",
      "file_location":"assets/colors_organized/hue_08/brightness_15/2020040415172401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":15,
      "vividness":13,
      "r":149,
      "g":230,
      "b":29,
      "hex":"#95e61d",
      "file_location":"assets/colors_organized/hue_08/brightness_15/2020040415172500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":8,
      "brightness":15,
      "vividness":14,
      "r":143,
      "g":230,
      "b":14,
      "hex":"#8fe60e",
      "file_location":"assets/colors_organized/hue_08/brightness_15/2020040415172501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":1,
      "vividness":1,
      "r":18,
      "g":20,
      "b":17,
      "hex":"#121411",
      "file_location":"assets/colors_organized/hue_09/brightness_01/2020040415232500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":1,
      "vividness":2,
      "r":14,
      "g":19,
      "b":12,
      "hex":"#0e130c",
      "file_location":"assets/colors_organized/hue_09/brightness_01/2020040415232601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":1,
      "vividness":3,
      "r":12,
      "g":20,
      "b":9,
      "hex":"#0c1409",
      "file_location":"assets/colors_organized/hue_09/brightness_01/2020040415232701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":1,
      "vividness":4,
      "r":9,
      "g":20,
      "b":6,
      "hex":"#091406",
      "file_location":"assets/colors_organized/hue_09/brightness_01/2020040415232901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":1,
      "vividness":5,
      "r":9,
      "g":20,
      "b":3,
      "hex":"#091403",
      "file_location":"assets/colors_organized/hue_09/brightness_01/2020040415233000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":1,
      "vividness":6,
      "r":6,
      "g":19,
      "b":0,
      "hex":"#061300",
      "file_location":"assets/colors_organized/hue_09/brightness_01/2020040415233100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":2,
      "vividness":1,
      "r":32,
      "g":34,
      "b":29,
      "hex":"#20221d",
      "file_location":"assets/colors_organized/hue_09/brightness_02/2020040415231302-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":2,
      "vividness":2,
      "r":30,
      "g":35,
      "b":28,
      "hex":"#1e231c",
      "file_location":"assets/colors_organized/hue_09/brightness_02/2020040415231400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":2,
      "vividness":3,
      "r":29,
      "g":35,
      "b":25,
      "hex":"#1d2319",
      "file_location":"assets/colors_organized/hue_09/brightness_02/2020040415231500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":2,
      "vividness":4,
      "r":27,
      "g":35,
      "b":22,
      "hex":"#1b2316",
      "file_location":"assets/colors_organized/hue_09/brightness_02/2020040415231501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":2,
      "vividness":5,
      "r":24,
      "g":35,
      "b":18,
      "hex":"#182312",
      "file_location":"assets/colors_organized/hue_09/brightness_02/2020040415231601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":2,
      "vividness":6,
      "r":22,
      "g":35,
      "b":15,
      "hex":"#16230f",
      "file_location":"assets/colors_organized/hue_09/brightness_02/2020040415231700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":2,
      "vividness":7,
      "r":21,
      "g":35,
      "b":12,
      "hex":"#15230c",
      "file_location":"assets/colors_organized/hue_09/brightness_02/2020040415231701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":2,
      "vividness":8,
      "r":21,
      "g":35,
      "b":10,
      "hex":"#15230a",
      "file_location":"assets/colors_organized/hue_09/brightness_02/2020040415231800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":2,
      "vividness":9,
      "r":19,
      "g":35,
      "b":9,
      "hex":"#132309",
      "file_location":"assets/colors_organized/hue_09/brightness_02/2020040415231801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":2,
      "vividness":10,
      "r":18,
      "g":34,
      "b":7,
      "hex":"#122207",
      "file_location":"assets/colors_organized/hue_09/brightness_02/2020040415231900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":2,
      "vividness":11,
      "r":14,
      "g":35,
      "b":2,
      "hex":"#0e2302",
      "file_location":"assets/colors_organized/hue_09/brightness_02/2020040415231901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":3,
      "vividness":1,
      "r":45,
      "g":50,
      "b":43,
      "hex":"#2d322b",
      "file_location":"assets/colors_organized/hue_09/brightness_03/2020040415230201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":3,
      "vividness":2,
      "r":44,
      "g":50,
      "b":40,
      "hex":"#2c3228",
      "file_location":"assets/colors_organized/hue_09/brightness_03/2020040415230300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":3,
      "vividness":3,
      "r":42,
      "g":50,
      "b":35,
      "hex":"#2a3223",
      "file_location":"assets/colors_organized/hue_09/brightness_03/2020040415230400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":3,
      "vividness":4,
      "r":39,
      "g":50,
      "b":34,
      "hex":"#273222",
      "file_location":"assets/colors_organized/hue_09/brightness_03/2020040415230401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":3,
      "vividness":5,
      "r":37,
      "g":50,
      "b":30,
      "hex":"#25321e",
      "file_location":"assets/colors_organized/hue_09/brightness_03/2020040415230500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":3,
      "vividness":6,
      "r":36,
      "g":50,
      "b":27,
      "hex":"#24321b",
      "file_location":"assets/colors_organized/hue_09/brightness_03/2020040415230501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":3,
      "vividness":7,
      "r":34,
      "g":50,
      "b":23,
      "hex":"#223217",
      "file_location":"assets/colors_organized/hue_09/brightness_03/2020040415230600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":3,
      "vividness":8,
      "r":31,
      "g":50,
      "b":20,
      "hex":"#1f3214",
      "file_location":"assets/colors_organized/hue_09/brightness_03/2020040415230601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":3,
      "vividness":9,
      "r":30,
      "g":50,
      "b":15,
      "hex":"#1e320f",
      "file_location":"assets/colors_organized/hue_09/brightness_03/2020040415230700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":3,
      "vividness":10,
      "r":28,
      "g":50,
      "b":12,
      "hex":"#1c320c",
      "file_location":"assets/colors_organized/hue_09/brightness_03/2020040415230701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":3,
      "vividness":11,
      "r":27,
      "g":49,
      "b":10,
      "hex":"#1b310a",
      "file_location":"assets/colors_organized/hue_09/brightness_03/2020040415230800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":3,
      "vividness":12,
      "r":23,
      "g":50,
      "b":7,
      "hex":"#173207",
      "file_location":"assets/colors_organized/hue_09/brightness_03/2020040415230801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":3,
      "vividness":13,
      "r":22,
      "g":50,
      "b":2,
      "hex":"#163202",
      "file_location":"assets/colors_organized/hue_09/brightness_03/2020040415230900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":4,
      "vividness":1,
      "r":62,
      "g":64,
      "b":59,
      "hex":"#3e403b",
      "file_location":"assets/colors_organized/hue_09/brightness_04/2020040415225100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":4,
      "vividness":2,
      "r":58,
      "g":66,
      "b":55,
      "hex":"#3a4237",
      "file_location":"assets/colors_organized/hue_09/brightness_04/2020040415225101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":4,
      "vividness":3,
      "r":57,
      "g":65,
      "b":52,
      "hex":"#394134",
      "file_location":"assets/colors_organized/hue_09/brightness_04/2020040415225200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":4,
      "vividness":4,
      "r":53,
      "g":64,
      "b":47,
      "hex":"#35402f",
      "file_location":"assets/colors_organized/hue_09/brightness_04/2020040415225201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":4,
      "vividness":5,
      "r":51,
      "g":65,
      "b":42,
      "hex":"#33412a",
      "file_location":"assets/colors_organized/hue_09/brightness_04/2020040415225300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":4,
      "vividness":6,
      "r":49,
      "g":65,
      "b":38,
      "hex":"#314126",
      "file_location":"assets/colors_organized/hue_09/brightness_04/2020040415225301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":4,
      "vividness":7,
      "r":45,
      "g":64,
      "b":32,
      "hex":"#2d4020",
      "file_location":"assets/colors_organized/hue_09/brightness_04/2020040415225400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":4,
      "vividness":8,
      "r":43,
      "g":65,
      "b":29,
      "hex":"#2b411d",
      "file_location":"assets/colors_organized/hue_09/brightness_04/2020040415225401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":4,
      "vividness":9,
      "r":40,
      "g":65,
      "b":26,
      "hex":"#28411a",
      "file_location":"assets/colors_organized/hue_09/brightness_04/2020040415225500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":4,
      "vividness":10,
      "r":38,
      "g":65,
      "b":22,
      "hex":"#264116",
      "file_location":"assets/colors_organized/hue_09/brightness_04/2020040415225600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":4,
      "vividness":11,
      "r":36,
      "g":65,
      "b":17,
      "hex":"#244111",
      "file_location":"assets/colors_organized/hue_09/brightness_04/2020040415225601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":4,
      "vividness":12,
      "r":32,
      "g":65,
      "b":12,
      "hex":"#20410c",
      "file_location":"assets/colors_organized/hue_09/brightness_04/2020040415225700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":4,
      "vividness":13,
      "r":30,
      "g":65,
      "b":7,
      "hex":"#1e4107",
      "file_location":"assets/colors_organized/hue_09/brightness_04/2020040415225701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":4,
      "vividness":14,
      "r":27,
      "g":66,
      "b":3,
      "hex":"#1b4203",
      "file_location":"assets/colors_organized/hue_09/brightness_04/2020040415225800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":5,
      "vividness":1,
      "r":75,
      "g":80,
      "b":74,
      "hex":"#4b504a",
      "file_location":"assets/colors_organized/hue_09/brightness_05/2020040415223901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":5,
      "vividness":2,
      "r":72,
      "g":80,
      "b":69,
      "hex":"#485045",
      "file_location":"assets/colors_organized/hue_09/brightness_05/2020040415224000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":5,
      "vividness":3,
      "r":69,
      "g":80,
      "b":64,
      "hex":"#455040",
      "file_location":"assets/colors_organized/hue_09/brightness_05/2020040415224001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":5,
      "vividness":4,
      "r":66,
      "g":80,
      "b":57,
      "hex":"#425039",
      "file_location":"assets/colors_organized/hue_09/brightness_05/2020040415224100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":5,
      "vividness":5,
      "r":64,
      "g":80,
      "b":53,
      "hex":"#405035",
      "file_location":"assets/colors_organized/hue_09/brightness_05/2020040415224101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":5,
      "vividness":6,
      "r":59,
      "g":80,
      "b":47,
      "hex":"#3b502f",
      "file_location":"assets/colors_organized/hue_09/brightness_05/2020040415224200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":5,
      "vividness":7,
      "r":56,
      "g":81,
      "b":42,
      "hex":"#38512a",
      "file_location":"assets/colors_organized/hue_09/brightness_05/2020040415224201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":5,
      "vividness":8,
      "r":53,
      "g":80,
      "b":37,
      "hex":"#355025",
      "file_location":"assets/colors_organized/hue_09/brightness_05/2020040415224300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":5,
      "vividness":9,
      "r":51,
      "g":80,
      "b":32,
      "hex":"#335020",
      "file_location":"assets/colors_organized/hue_09/brightness_05/2020040415224301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":5,
      "vividness":10,
      "r":47,
      "g":80,
      "b":27,
      "hex":"#2f501b",
      "file_location":"assets/colors_organized/hue_09/brightness_05/2020040415224400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":5,
      "vividness":11,
      "r":42,
      "g":80,
      "b":21,
      "hex":"#2a5015",
      "file_location":"assets/colors_organized/hue_09/brightness_05/2020040415224500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":5,
      "vividness":12,
      "r":41,
      "g":80,
      "b":15,
      "hex":"#29500f",
      "file_location":"assets/colors_organized/hue_09/brightness_05/2020040415224501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":5,
      "vividness":13,
      "r":37,
      "g":80,
      "b":9,
      "hex":"#255009",
      "file_location":"assets/colors_organized/hue_09/brightness_05/2020040415224600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":5,
      "vividness":14,
      "r":34,
      "g":80,
      "b":7,
      "hex":"#225007",
      "file_location":"assets/colors_organized/hue_09/brightness_05/2020040415224601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":6,
      "vividness":1,
      "r":90,
      "g":95,
      "b":88,
      "hex":"#5a5f58",
      "file_location":"assets/colors_organized/hue_09/brightness_06/2020040415222800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":6,
      "vividness":2,
      "r":87,
      "g":95,
      "b":82,
      "hex":"#575f52",
      "file_location":"assets/colors_organized/hue_09/brightness_06/2020040415222801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":6,
      "vividness":3,
      "r":82,
      "g":95,
      "b":75,
      "hex":"#525f4b",
      "file_location":"assets/colors_organized/hue_09/brightness_06/2020040415222900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":6,
      "vividness":4,
      "r":79,
      "g":95,
      "b":69,
      "hex":"#4f5f45",
      "file_location":"assets/colors_organized/hue_09/brightness_06/2020040415222901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":6,
      "vividness":5,
      "r":75,
      "g":94,
      "b":62,
      "hex":"#4b5e3e",
      "file_location":"assets/colors_organized/hue_09/brightness_06/2020040415223000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":6,
      "vividness":6,
      "r":71,
      "g":96,
      "b":57,
      "hex":"#476039",
      "file_location":"assets/colors_organized/hue_09/brightness_06/2020040415223100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":6,
      "vividness":7,
      "r":68,
      "g":95,
      "b":50,
      "hex":"#445f32",
      "file_location":"assets/colors_organized/hue_09/brightness_06/2020040415223101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":6,
      "vividness":8,
      "r":65,
      "g":95,
      "b":43,
      "hex":"#415f2b",
      "file_location":"assets/colors_organized/hue_09/brightness_06/2020040415223200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":6,
      "vividness":9,
      "r":60,
      "g":95,
      "b":37,
      "hex":"#3c5f25",
      "file_location":"assets/colors_organized/hue_09/brightness_06/2020040415223201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":6,
      "vividness":10,
      "r":56,
      "g":95,
      "b":30,
      "hex":"#385f1e",
      "file_location":"assets/colors_organized/hue_09/brightness_06/2020040415223300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":6,
      "vividness":11,
      "r":52,
      "g":95,
      "b":24,
      "hex":"#345f18",
      "file_location":"assets/colors_organized/hue_09/brightness_06/2020040415223301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":6,
      "vividness":12,
      "r":48,
      "g":94,
      "b":19,
      "hex":"#305e13",
      "file_location":"assets/colors_organized/hue_09/brightness_06/2020040415223400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":6,
      "vividness":13,
      "r":43,
      "g":95,
      "b":12,
      "hex":"#2b5f0c",
      "file_location":"assets/colors_organized/hue_09/brightness_06/2020040415223401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":6,
      "vividness":14,
      "r":41,
      "g":95,
      "b":7,
      "hex":"#295f07",
      "file_location":"assets/colors_organized/hue_09/brightness_06/2020040415223500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":7,
      "vividness":1,
      "r":104,
      "g":109,
      "b":102,
      "hex":"#686d66",
      "file_location":"assets/colors_organized/hue_09/brightness_07/2020040415221601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":7,
      "vividness":2,
      "r":101,
      "g":109,
      "b":94,
      "hex":"#656d5e",
      "file_location":"assets/colors_organized/hue_09/brightness_07/2020040415221700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":7,
      "vividness":3,
      "r":96,
      "g":110,
      "b":87,
      "hex":"#606e57",
      "file_location":"assets/colors_organized/hue_09/brightness_07/2020040415221800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":7,
      "vividness":4,
      "r":91,
      "g":110,
      "b":80,
      "hex":"#5b6e50",
      "file_location":"assets/colors_organized/hue_09/brightness_07/2020040415221801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":7,
      "vividness":5,
      "r":88,
      "g":110,
      "b":72,
      "hex":"#586e48",
      "file_location":"assets/colors_organized/hue_09/brightness_07/2020040415221900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":7,
      "vividness":6,
      "r":83,
      "g":110,
      "b":65,
      "hex":"#536e41",
      "file_location":"assets/colors_organized/hue_09/brightness_07/2020040415221901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":7,
      "vividness":7,
      "r":80,
      "g":110,
      "b":58,
      "hex":"#506e3a",
      "file_location":"assets/colors_organized/hue_09/brightness_07/2020040415222000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":7,
      "vividness":8,
      "r":74,
      "g":110,
      "b":49,
      "hex":"#4a6e31",
      "file_location":"assets/colors_organized/hue_09/brightness_07/2020040415222001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":7,
      "vividness":9,
      "r":69,
      "g":110,
      "b":42,
      "hex":"#456e2a",
      "file_location":"assets/colors_organized/hue_09/brightness_07/2020040415222100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":7,
      "vividness":10,
      "r":66,
      "g":110,
      "b":35,
      "hex":"#426e23",
      "file_location":"assets/colors_organized/hue_09/brightness_07/2020040415222101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":7,
      "vividness":11,
      "r":61,
      "g":110,
      "b":29,
      "hex":"#3d6e1d",
      "file_location":"assets/colors_organized/hue_09/brightness_07/2020040415222200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":7,
      "vividness":12,
      "r":56,
      "g":110,
      "b":22,
      "hex":"#386e16",
      "file_location":"assets/colors_organized/hue_09/brightness_07/2020040415222201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":7,
      "vividness":13,
      "r":52,
      "g":109,
      "b":12,
      "hex":"#346d0c",
      "file_location":"assets/colors_organized/hue_09/brightness_07/2020040415222300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":7,
      "vividness":14,
      "r":47,
      "g":110,
      "b":6,
      "hex":"#2f6e06",
      "file_location":"assets/colors_organized/hue_09/brightness_07/2020040415222301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":8,
      "vividness":1,
      "r":118,
      "g":123,
      "b":116,
      "hex":"#767b74",
      "file_location":"assets/colors_organized/hue_09/brightness_08/2020040415215800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":8,
      "vividness":2,
      "r":113,
      "g":124,
      "b":108,
      "hex":"#717c6c",
      "file_location":"assets/colors_organized/hue_09/brightness_08/2020040415215801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":8,
      "vividness":3,
      "r":109,
      "g":125,
      "b":99,
      "hex":"#6d7d63",
      "file_location":"assets/colors_organized/hue_09/brightness_08/2020040415215900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":8,
      "vividness":4,
      "r":104,
      "g":123,
      "b":91,
      "hex":"#687b5b",
      "file_location":"assets/colors_organized/hue_09/brightness_08/2020040415215901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":8,
      "vividness":5,
      "r":99,
      "g":124,
      "b":84,
      "hex":"#637c54",
      "file_location":"assets/colors_organized/hue_09/brightness_08/2020040415220000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":8,
      "vividness":6,
      "r":94,
      "g":124,
      "b":74,
      "hex":"#5e7c4a",
      "file_location":"assets/colors_organized/hue_09/brightness_08/2020040415220100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":8,
      "vividness":7,
      "r":89,
      "g":124,
      "b":66,
      "hex":"#597c42",
      "file_location":"assets/colors_organized/hue_09/brightness_08/2020040415220101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":8,
      "vividness":8,
      "r":83,
      "g":124,
      "b":56,
      "hex":"#537c38",
      "file_location":"assets/colors_organized/hue_09/brightness_08/2020040415220200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":8,
      "vividness":9,
      "r":78,
      "g":124,
      "b":49,
      "hex":"#4e7c31",
      "file_location":"assets/colors_organized/hue_09/brightness_08/2020040415220201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":8,
      "vividness":10,
      "r":74,
      "g":123,
      "b":41,
      "hex":"#4a7b29",
      "file_location":"assets/colors_organized/hue_09/brightness_08/2020040415220300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":8,
      "vividness":11,
      "r":69,
      "g":124,
      "b":33,
      "hex":"#457c21",
      "file_location":"assets/colors_organized/hue_09/brightness_08/2020040415220400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":8,
      "vividness":12,
      "r":63,
      "g":124,
      "b":21,
      "hex":"#3f7c15",
      "file_location":"assets/colors_organized/hue_09/brightness_08/2020040415220401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":8,
      "vividness":13,
      "r":58,
      "g":124,
      "b":14,
      "hex":"#3a7c0e",
      "file_location":"assets/colors_organized/hue_09/brightness_08/2020040415220500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":8,
      "vividness":14,
      "r":53,
      "g":124,
      "b":6,
      "hex":"#357c06",
      "file_location":"assets/colors_organized/hue_09/brightness_08/2020040415220501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":9,
      "vividness":1,
      "r":134,
      "g":140,
      "b":130,
      "hex":"#868c82",
      "file_location":"assets/colors_organized/hue_09/brightness_09/2020040415214501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":9,
      "vividness":2,
      "r":128,
      "g":139,
      "b":122,
      "hex":"#808b7a",
      "file_location":"assets/colors_organized/hue_09/brightness_09/2020040415214600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":9,
      "vividness":3,
      "r":123,
      "g":139,
      "b":110,
      "hex":"#7b8b6e",
      "file_location":"assets/colors_organized/hue_09/brightness_09/2020040415214700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":9,
      "vividness":4,
      "r":117,
      "g":139,
      "b":101,
      "hex":"#758b65",
      "file_location":"assets/colors_organized/hue_09/brightness_09/2020040415214701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":9,
      "vividness":5,
      "r":111,
      "g":138,
      "b":93,
      "hex":"#6f8a5d",
      "file_location":"assets/colors_organized/hue_09/brightness_09/2020040415214800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":9,
      "vividness":6,
      "r":106,
      "g":139,
      "b":82,
      "hex":"#6a8b52",
      "file_location":"assets/colors_organized/hue_09/brightness_09/2020040415214900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":9,
      "vividness":7,
      "r":100,
      "g":139,
      "b":74,
      "hex":"#648b4a",
      "file_location":"assets/colors_organized/hue_09/brightness_09/2020040415214901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":9,
      "vividness":8,
      "r":95,
      "g":139,
      "b":64,
      "hex":"#5f8b40",
      "file_location":"assets/colors_organized/hue_09/brightness_09/2020040415215000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":9,
      "vividness":9,
      "r":89,
      "g":139,
      "b":54,
      "hex":"#598b36",
      "file_location":"assets/colors_organized/hue_09/brightness_09/2020040415215100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":9,
      "vividness":10,
      "r":83,
      "g":140,
      "b":46,
      "hex":"#538c2e",
      "file_location":"assets/colors_organized/hue_09/brightness_09/2020040415215101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":9,
      "vividness":11,
      "r":78,
      "g":139,
      "b":36,
      "hex":"#4e8b24",
      "file_location":"assets/colors_organized/hue_09/brightness_09/2020040415215200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":9,
      "vividness":12,
      "r":71,
      "g":139,
      "b":26,
      "hex":"#478b1a",
      "file_location":"assets/colors_organized/hue_09/brightness_09/2020040415215201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":9,
      "vividness":13,
      "r":68,
      "g":139,
      "b":17,
      "hex":"#448b11",
      "file_location":"assets/colors_organized/hue_09/brightness_09/2020040415215300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":9,
      "vividness":14,
      "r":60,
      "g":140,
      "b":9,
      "hex":"#3c8c09",
      "file_location":"assets/colors_organized/hue_09/brightness_09/2020040415215301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":10,
      "vividness":1,
      "r":148,
      "g":154,
      "b":144,
      "hex":"#949a90",
      "file_location":"assets/colors_organized/hue_09/brightness_10/2020040415213301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":10,
      "vividness":2,
      "r":141,
      "g":154,
      "b":134,
      "hex":"#8d9a86",
      "file_location":"assets/colors_organized/hue_09/brightness_10/2020040415213400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":10,
      "vividness":3,
      "r":135,
      "g":154,
      "b":122,
      "hex":"#879a7a",
      "file_location":"assets/colors_organized/hue_09/brightness_10/2020040415213401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":10,
      "vividness":4,
      "r":129,
      "g":154,
      "b":112,
      "hex":"#819a70",
      "file_location":"assets/colors_organized/hue_09/brightness_10/2020040415213500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":10,
      "vividness":5,
      "r":122,
      "g":154,
      "b":104,
      "hex":"#7a9a68",
      "file_location":"assets/colors_organized/hue_09/brightness_10/2020040415213600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":10,
      "vividness":6,
      "r":116,
      "g":155,
      "b":92,
      "hex":"#749b5c",
      "file_location":"assets/colors_organized/hue_09/brightness_10/2020040415213601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":10,
      "vividness":7,
      "r":111,
      "g":154,
      "b":82,
      "hex":"#6f9a52",
      "file_location":"assets/colors_organized/hue_09/brightness_10/2020040415213700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":10,
      "vividness":8,
      "r":105,
      "g":154,
      "b":72,
      "hex":"#699a48",
      "file_location":"assets/colors_organized/hue_09/brightness_10/2020040415213701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":10,
      "vividness":9,
      "r":99,
      "g":154,
      "b":60,
      "hex":"#639a3c",
      "file_location":"assets/colors_organized/hue_09/brightness_10/2020040415213800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":10,
      "vividness":10,
      "r":92,
      "g":155,
      "b":51,
      "hex":"#5c9b33",
      "file_location":"assets/colors_organized/hue_09/brightness_10/2020040415213900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":10,
      "vividness":11,
      "r":86,
      "g":154,
      "b":41,
      "hex":"#569a29",
      "file_location":"assets/colors_organized/hue_09/brightness_10/2020040415213901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":10,
      "vividness":12,
      "r":80,
      "g":154,
      "b":29,
      "hex":"#509a1d",
      "file_location":"assets/colors_organized/hue_09/brightness_10/2020040415214000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":10,
      "vividness":13,
      "r":74,
      "g":154,
      "b":19,
      "hex":"#4a9a13",
      "file_location":"assets/colors_organized/hue_09/brightness_10/2020040415214001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":10,
      "vividness":14,
      "r":67,
      "g":155,
      "b":9,
      "hex":"#439b09",
      "file_location":"assets/colors_organized/hue_09/brightness_10/2020040415214100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":11,
      "vividness":1,
      "r":162,
      "g":170,
      "b":159,
      "hex":"#a2aa9f",
      "file_location":"assets/colors_organized/hue_09/brightness_11/2020040415212000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":11,
      "vividness":2,
      "r":156,
      "g":170,
      "b":147,
      "hex":"#9caa93",
      "file_location":"assets/colors_organized/hue_09/brightness_11/2020040415212001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":11,
      "vividness":3,
      "r":149,
      "g":169,
      "b":134,
      "hex":"#95a986",
      "file_location":"assets/colors_organized/hue_09/brightness_11/2020040415212100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":11,
      "vividness":4,
      "r":142,
      "g":169,
      "b":124,
      "hex":"#8ea97c",
      "file_location":"assets/colors_organized/hue_09/brightness_11/2020040415212200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":11,
      "vividness":5,
      "r":135,
      "g":170,
      "b":112,
      "hex":"#87aa70",
      "file_location":"assets/colors_organized/hue_09/brightness_11/2020040415212201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":11,
      "vividness":6,
      "r":128,
      "g":169,
      "b":99,
      "hex":"#80a963",
      "file_location":"assets/colors_organized/hue_09/brightness_11/2020040415212300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":11,
      "vividness":7,
      "r":122,
      "g":169,
      "b":89,
      "hex":"#7aa959",
      "file_location":"assets/colors_organized/hue_09/brightness_11/2020040415212400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":11,
      "vividness":8,
      "r":115,
      "g":170,
      "b":79,
      "hex":"#73aa4f",
      "file_location":"assets/colors_organized/hue_09/brightness_11/2020040415212401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":11,
      "vividness":9,
      "r":108,
      "g":169,
      "b":66,
      "hex":"#6ca942",
      "file_location":"assets/colors_organized/hue_09/brightness_11/2020040415212500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":11,
      "vividness":10,
      "r":101,
      "g":169,
      "b":56,
      "hex":"#65a938",
      "file_location":"assets/colors_organized/hue_09/brightness_11/2020040415212600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":11,
      "vividness":11,
      "r":95,
      "g":169,
      "b":44,
      "hex":"#5fa92c",
      "file_location":"assets/colors_organized/hue_09/brightness_11/2020040415212601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":11,
      "vividness":12,
      "r":87,
      "g":169,
      "b":33,
      "hex":"#57a921",
      "file_location":"assets/colors_organized/hue_09/brightness_11/2020040415212700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":11,
      "vividness":13,
      "r":81,
      "g":169,
      "b":21,
      "hex":"#51a915",
      "file_location":"assets/colors_organized/hue_09/brightness_11/2020040415212800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":11,
      "vividness":14,
      "r":73,
      "g":169,
      "b":9,
      "hex":"#49a909",
      "file_location":"assets/colors_organized/hue_09/brightness_11/2020040415212900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":12,
      "vividness":1,
      "r":177,
      "g":185,
      "b":172,
      "hex":"#b1b9ac",
      "file_location":"assets/colors_organized/hue_09/brightness_12/2020040415210701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":12,
      "vividness":2,
      "r":169,
      "g":185,
      "b":159,
      "hex":"#a9b99f",
      "file_location":"assets/colors_organized/hue_09/brightness_12/2020040415210800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":12,
      "vividness":3,
      "r":162,
      "g":184,
      "b":146,
      "hex":"#a2b892",
      "file_location":"assets/colors_organized/hue_09/brightness_12/2020040415210900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":12,
      "vividness":4,
      "r":155,
      "g":185,
      "b":135,
      "hex":"#9bb987",
      "file_location":"assets/colors_organized/hue_09/brightness_12/2020040415210901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":12,
      "vividness":5,
      "r":146,
      "g":185,
      "b":122,
      "hex":"#92b97a",
      "file_location":"assets/colors_organized/hue_09/brightness_12/2020040415211000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":12,
      "vividness":6,
      "r":141,
      "g":185,
      "b":110,
      "hex":"#8db96e",
      "file_location":"assets/colors_organized/hue_09/brightness_12/2020040415211100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":12,
      "vividness":7,
      "r":132,
      "g":185,
      "b":97,
      "hex":"#84b961",
      "file_location":"assets/colors_organized/hue_09/brightness_12/2020040415211101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":12,
      "vividness":8,
      "r":124,
      "g":184,
      "b":86,
      "hex":"#7cb856",
      "file_location":"assets/colors_organized/hue_09/brightness_12/2020040415211200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":12,
      "vividness":9,
      "r":118,
      "g":185,
      "b":72,
      "hex":"#76b948",
      "file_location":"assets/colors_organized/hue_09/brightness_12/2020040415211201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":12,
      "vividness":10,
      "r":110,
      "g":184,
      "b":61,
      "hex":"#6eb83d",
      "file_location":"assets/colors_organized/hue_09/brightness_12/2020040415211300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":12,
      "vividness":11,
      "r":102,
      "g":184,
      "b":48,
      "hex":"#66b830",
      "file_location":"assets/colors_organized/hue_09/brightness_12/2020040415211400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":12,
      "vividness":12,
      "r":96,
      "g":185,
      "b":35,
      "hex":"#60b923",
      "file_location":"assets/colors_organized/hue_09/brightness_12/2020040415211401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":12,
      "vividness":13,
      "r":88,
      "g":184,
      "b":22,
      "hex":"#58b816",
      "file_location":"assets/colors_organized/hue_09/brightness_12/2020040415211500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":12,
      "vividness":14,
      "r":81,
      "g":185,
      "b":12,
      "hex":"#51b90c",
      "file_location":"assets/colors_organized/hue_09/brightness_12/2020040415211501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":13,
      "vividness":1,
      "r":191,
      "g":199,
      "b":186,
      "hex":"#bfc7ba",
      "file_location":"assets/colors_organized/hue_09/brightness_13/2020040415205500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":13,
      "vividness":2,
      "r":184,
      "g":200,
      "b":173,
      "hex":"#b8c8ad",
      "file_location":"assets/colors_organized/hue_09/brightness_13/2020040415205501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":13,
      "vividness":3,
      "r":175,
      "g":200,
      "b":160,
      "hex":"#afc8a0",
      "file_location":"assets/colors_organized/hue_09/brightness_13/2020040415205600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":13,
      "vividness":4,
      "r":167,
      "g":200,
      "b":147,
      "hex":"#a7c893",
      "file_location":"assets/colors_organized/hue_09/brightness_13/2020040415205700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":13,
      "vividness":5,
      "r":159,
      "g":200,
      "b":134,
      "hex":"#9fc886",
      "file_location":"assets/colors_organized/hue_09/brightness_13/2020040415205701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":13,
      "vividness":6,
      "r":151,
      "g":200,
      "b":119,
      "hex":"#97c877",
      "file_location":"assets/colors_organized/hue_09/brightness_13/2020040415205800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":13,
      "vividness":7,
      "r":143,
      "g":200,
      "b":106,
      "hex":"#8fc86a",
      "file_location":"assets/colors_organized/hue_09/brightness_13/2020040415205801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":13,
      "vividness":8,
      "r":134,
      "g":200,
      "b":92,
      "hex":"#86c85c",
      "file_location":"assets/colors_organized/hue_09/brightness_13/2020040415205900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":13,
      "vividness":9,
      "r":126,
      "g":200,
      "b":79,
      "hex":"#7ec84f",
      "file_location":"assets/colors_organized/hue_09/brightness_13/2020040415210000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":13,
      "vividness":10,
      "r":118,
      "g":200,
      "b":66,
      "hex":"#76c842",
      "file_location":"assets/colors_organized/hue_09/brightness_13/2020040415210001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":13,
      "vividness":11,
      "r":111,
      "g":199,
      "b":51,
      "hex":"#6fc733",
      "file_location":"assets/colors_organized/hue_09/brightness_13/2020040415210100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":13,
      "vividness":12,
      "r":103,
      "g":199,
      "b":39,
      "hex":"#67c727",
      "file_location":"assets/colors_organized/hue_09/brightness_13/2020040415210101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":13,
      "vividness":13,
      "r":95,
      "g":199,
      "b":26,
      "hex":"#5fc71a",
      "file_location":"assets/colors_organized/hue_09/brightness_13/2020040415210200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":13,
      "vividness":14,
      "r":87,
      "g":199,
      "b":11,
      "hex":"#57c70b",
      "file_location":"assets/colors_organized/hue_09/brightness_13/2020040415210300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":14,
      "vividness":1,
      "r":206,
      "g":214,
      "b":199,
      "hex":"#ced6c7",
      "file_location":"assets/colors_organized/hue_09/brightness_14/2020040415204200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":14,
      "vividness":2,
      "r":198,
      "g":214,
      "b":185,
      "hex":"#c6d6b9",
      "file_location":"assets/colors_organized/hue_09/brightness_14/2020040415204201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":14,
      "vividness":3,
      "r":188,
      "g":215,
      "b":172,
      "hex":"#bcd7ac",
      "file_location":"assets/colors_organized/hue_09/brightness_14/2020040415204300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":14,
      "vividness":4,
      "r":180,
      "g":215,
      "b":157,
      "hex":"#b4d79d",
      "file_location":"assets/colors_organized/hue_09/brightness_14/2020040415204301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":14,
      "vividness":5,
      "r":171,
      "g":214,
      "b":143,
      "hex":"#abd68f",
      "file_location":"assets/colors_organized/hue_09/brightness_14/2020040415204400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":14,
      "vividness":6,
      "r":162,
      "g":215,
      "b":127,
      "hex":"#a2d77f",
      "file_location":"assets/colors_organized/hue_09/brightness_14/2020040415204500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":14,
      "vividness":7,
      "r":154,
      "g":214,
      "b":114,
      "hex":"#9ad672",
      "file_location":"assets/colors_organized/hue_09/brightness_14/2020040415204600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":14,
      "vividness":8,
      "r":146,
      "g":215,
      "b":99,
      "hex":"#92d763",
      "file_location":"assets/colors_organized/hue_09/brightness_14/2020040415204601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":14,
      "vividness":9,
      "r":138,
      "g":215,
      "b":85,
      "hex":"#8ad755",
      "file_location":"assets/colors_organized/hue_09/brightness_14/2020040415204700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":14,
      "vividness":10,
      "r":127,
      "g":214,
      "b":71,
      "hex":"#7fd647",
      "file_location":"assets/colors_organized/hue_09/brightness_14/2020040415204800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":14,
      "vividness":11,
      "r":119,
      "g":215,
      "b":56,
      "hex":"#77d738",
      "file_location":"assets/colors_organized/hue_09/brightness_14/2020040415204801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":14,
      "vividness":12,
      "r":111,
      "g":215,
      "b":42,
      "hex":"#6fd72a",
      "file_location":"assets/colors_organized/hue_09/brightness_14/2020040415204900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":14,
      "vividness":13,
      "r":102,
      "g":214,
      "b":26,
      "hex":"#66d61a",
      "file_location":"assets/colors_organized/hue_09/brightness_14/2020040415204901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":14,
      "vividness":14,
      "r":93,
      "g":214,
      "b":11,
      "hex":"#5dd60b",
      "file_location":"assets/colors_organized/hue_09/brightness_14/2020040415205000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":15,
      "vividness":1,
      "r":221,
      "g":229,
      "b":214,
      "hex":"#dde5d6",
      "file_location":"assets/colors_organized/hue_09/brightness_15/2020040415202800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":15,
      "vividness":2,
      "r":211,
      "g":230,
      "b":198,
      "hex":"#d3e6c6",
      "file_location":"assets/colors_organized/hue_09/brightness_15/2020040415202900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":15,
      "vividness":3,
      "r":202,
      "g":230,
      "b":182,
      "hex":"#cae6b6",
      "file_location":"assets/colors_organized/hue_09/brightness_15/2020040415202901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":15,
      "vividness":4,
      "r":191,
      "g":230,
      "b":167,
      "hex":"#bfe6a7",
      "file_location":"assets/colors_organized/hue_09/brightness_15/2020040415203000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":15,
      "vividness":5,
      "r":183,
      "g":230,
      "b":152,
      "hex":"#b7e698",
      "file_location":"assets/colors_organized/hue_09/brightness_15/2020040415203001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":15,
      "vividness":6,
      "r":174,
      "g":229,
      "b":136,
      "hex":"#aee588",
      "file_location":"assets/colors_organized/hue_09/brightness_15/2020040415203100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":15,
      "vividness":7,
      "r":164,
      "g":230,
      "b":122,
      "hex":"#a4e67a",
      "file_location":"assets/colors_organized/hue_09/brightness_15/2020040415203101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":15,
      "vividness":8,
      "r":156,
      "g":230,
      "b":107,
      "hex":"#9ce66b",
      "file_location":"assets/colors_organized/hue_09/brightness_15/2020040415203200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":15,
      "vividness":9,
      "r":146,
      "g":230,
      "b":92,
      "hex":"#92e65c",
      "file_location":"assets/colors_organized/hue_09/brightness_15/2020040415203300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":15,
      "vividness":10,
      "r":136,
      "g":229,
      "b":76,
      "hex":"#88e54c",
      "file_location":"assets/colors_organized/hue_09/brightness_15/2020040415203301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":15,
      "vividness":11,
      "r":128,
      "g":229,
      "b":61,
      "hex":"#80e53d",
      "file_location":"assets/colors_organized/hue_09/brightness_15/2020040415203400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":15,
      "vividness":12,
      "r":119,
      "g":229,
      "b":44,
      "hex":"#77e52c",
      "file_location":"assets/colors_organized/hue_09/brightness_15/2020040415203401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":15,
      "vividness":13,
      "r":109,
      "g":230,
      "b":29,
      "hex":"#6de61d",
      "file_location":"assets/colors_organized/hue_09/brightness_15/2020040415203500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":9,
      "brightness":15,
      "vividness":14,
      "r":100,
      "g":229,
      "b":14,
      "hex":"#64e50e",
      "file_location":"assets/colors_organized/hue_09/brightness_15/2020040415203501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":1,
      "vividness":1,
      "r":13,
      "g":20,
      "b":13,
      "hex":"#0d140d",
      "file_location":"assets/colors_organized/hue_10/brightness_01/2020040415262301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":1,
      "vividness":2,
      "r":9,
      "g":19,
      "b":10,
      "hex":"#09130a",
      "file_location":"assets/colors_organized/hue_10/brightness_01/2020040415262501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":1,
      "vividness":3,
      "r":6,
      "g":20,
      "b":7,
      "hex":"#061407",
      "file_location":"assets/colors_organized/hue_10/brightness_01/2020040415262700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":1,
      "vividness":4,
      "r":7,
      "g":20,
      "b":3,
      "hex":"#071403",
      "file_location":"assets/colors_organized/hue_10/brightness_01/2020040415262701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":2,
      "vividness":1,
      "r":30,
      "g":35,
      "b":29,
      "hex":"#1e231d",
      "file_location":"assets/colors_organized/hue_10/brightness_02/2020040415261001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":2,
      "vividness":2,
      "r":29,
      "g":34,
      "b":27,
      "hex":"#1d221b",
      "file_location":"assets/colors_organized/hue_10/brightness_02/2020040415261100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":2,
      "vividness":3,
      "r":27,
      "g":35,
      "b":24,
      "hex":"#1b2318",
      "file_location":"assets/colors_organized/hue_10/brightness_02/2020040415261200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":2,
      "vividness":4,
      "r":24,
      "g":34,
      "b":23,
      "hex":"#182217",
      "file_location":"assets/colors_organized/hue_10/brightness_02/2020040415261201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":2,
      "vividness":5,
      "r":22,
      "g":34,
      "b":20,
      "hex":"#162214",
      "file_location":"assets/colors_organized/hue_10/brightness_02/2020040415261300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":2,
      "vividness":6,
      "r":22,
      "g":35,
      "b":18,
      "hex":"#162312",
      "file_location":"assets/colors_organized/hue_10/brightness_02/2020040415261301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":2,
      "vividness":7,
      "r":20,
      "g":35,
      "b":14,
      "hex":"#14230e",
      "file_location":"assets/colors_organized/hue_10/brightness_02/2020040415261400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":2,
      "vividness":8,
      "r":17,
      "g":35,
      "b":13,
      "hex":"#11230d",
      "file_location":"assets/colors_organized/hue_10/brightness_02/2020040415261401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":2,
      "vividness":9,
      "r":15,
      "g":35,
      "b":10,
      "hex":"#0f230a",
      "file_location":"assets/colors_organized/hue_10/brightness_02/2020040415261500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":2,
      "vividness":10,
      "r":12,
      "g":35,
      "b":9,
      "hex":"#0c2309",
      "file_location":"assets/colors_organized/hue_10/brightness_02/2020040415261600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":2,
      "vividness":11,
      "r":12,
      "g":35,
      "b":7,
      "hex":"#0c2307",
      "file_location":"assets/colors_organized/hue_10/brightness_02/2020040415261601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":2,
      "vividness":12,
      "r":9,
      "g":34,
      "b":2,
      "hex":"#092202",
      "file_location":"assets/colors_organized/hue_10/brightness_02/2020040415261700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":2,
      "vividness":13,
      "r":5,
      "g":34,
      "b":3,
      "hex":"#052203",
      "file_location":"assets/colors_organized/hue_10/brightness_02/2020040415261701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":3,
      "vividness":1,
      "r":43,
      "g":50,
      "b":42,
      "hex":"#2b322a",
      "file_location":"assets/colors_organized/hue_10/brightness_03/2020040415260000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":3,
      "vividness":2,
      "r":42,
      "g":50,
      "b":39,
      "hex":"#2a3227",
      "file_location":"assets/colors_organized/hue_10/brightness_03/2020040415260001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":3,
      "vividness":3,
      "r":39,
      "g":50,
      "b":36,
      "hex":"#273224",
      "file_location":"assets/colors_organized/hue_10/brightness_03/2020040415260100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":3,
      "vividness":4,
      "r":36,
      "g":50,
      "b":33,
      "hex":"#243221",
      "file_location":"assets/colors_organized/hue_10/brightness_03/2020040415260101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":3,
      "vividness":5,
      "r":32,
      "g":49,
      "b":30,
      "hex":"#20311e",
      "file_location":"assets/colors_organized/hue_10/brightness_03/2020040415260200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":3,
      "vividness":6,
      "r":30,
      "g":49,
      "b":27,
      "hex":"#1e311b",
      "file_location":"assets/colors_organized/hue_10/brightness_03/2020040415260201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":3,
      "vividness":7,
      "r":27,
      "g":50,
      "b":22,
      "hex":"#1b3216",
      "file_location":"assets/colors_organized/hue_10/brightness_03/2020040415260300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":3,
      "vividness":8,
      "r":26,
      "g":49,
      "b":20,
      "hex":"#1a3114",
      "file_location":"assets/colors_organized/hue_10/brightness_03/2020040415260301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":3,
      "vividness":9,
      "r":22,
      "g":49,
      "b":14,
      "hex":"#16310e",
      "file_location":"assets/colors_organized/hue_10/brightness_03/2020040415260400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":3,
      "vividness":10,
      "r":19,
      "g":49,
      "b":11,
      "hex":"#13310b",
      "file_location":"assets/colors_organized/hue_10/brightness_03/2020040415260401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":3,
      "vividness":11,
      "r":17,
      "g":49,
      "b":8,
      "hex":"#113108",
      "file_location":"assets/colors_organized/hue_10/brightness_03/2020040415260500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":3,
      "vividness":12,
      "r":14,
      "g":50,
      "b":6,
      "hex":"#0e3206",
      "file_location":"assets/colors_organized/hue_10/brightness_03/2020040415260501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":3,
      "vividness":13,
      "r":12,
      "g":50,
      "b":3,
      "hex":"#0c3203",
      "file_location":"assets/colors_organized/hue_10/brightness_03/2020040415260600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":4,
      "vividness":1,
      "r":60,
      "g":65,
      "b":59,
      "hex":"#3c413b",
      "file_location":"assets/colors_organized/hue_10/brightness_04/2020040415254801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":4,
      "vividness":2,
      "r":53,
      "g":65,
      "b":51,
      "hex":"#354133",
      "file_location":"assets/colors_organized/hue_10/brightness_04/2020040415254901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":4,
      "vividness":3,
      "r":50,
      "g":65,
      "b":46,
      "hex":"#32412e",
      "file_location":"assets/colors_organized/hue_10/brightness_04/2020040415255000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":4,
      "vividness":4,
      "r":47,
      "g":65,
      "b":43,
      "hex":"#2f412b",
      "file_location":"assets/colors_organized/hue_10/brightness_04/2020040415255001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":4,
      "vividness":5,
      "r":42,
      "g":65,
      "b":37,
      "hex":"#2a4125",
      "file_location":"assets/colors_organized/hue_10/brightness_04/2020040415255100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":4,
      "vividness":6,
      "r":40,
      "g":65,
      "b":35,
      "hex":"#284123",
      "file_location":"assets/colors_organized/hue_10/brightness_04/2020040415255101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":4,
      "vividness":7,
      "r":37,
      "g":64,
      "b":29,
      "hex":"#25401d",
      "file_location":"assets/colors_organized/hue_10/brightness_04/2020040415255200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":4,
      "vividness":8,
      "r":33,
      "g":65,
      "b":26,
      "hex":"#21411a",
      "file_location":"assets/colors_organized/hue_10/brightness_04/2020040415255201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":4,
      "vividness":9,
      "r":29,
      "g":65,
      "b":21,
      "hex":"#1d4115",
      "file_location":"assets/colors_organized/hue_10/brightness_04/2020040415255300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":4,
      "vividness":10,
      "r":27,
      "g":65,
      "b":18,
      "hex":"#1b4112",
      "file_location":"assets/colors_organized/hue_10/brightness_04/2020040415255301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":4,
      "vividness":11,
      "r":22,
      "g":65,
      "b":12,
      "hex":"#16410c",
      "file_location":"assets/colors_organized/hue_10/brightness_04/2020040415255400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":4,
      "vividness":12,
      "r":20,
      "g":65,
      "b":6,
      "hex":"#144106",
      "file_location":"assets/colors_organized/hue_10/brightness_04/2020040415255401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":4,
      "vividness":13,
      "r":15,
      "g":65,
      "b":2,
      "hex":"#0f4102",
      "file_location":"assets/colors_organized/hue_10/brightness_04/2020040415255500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":5,
      "vividness":1,
      "r":70,
      "g":80,
      "b":69,
      "hex":"#465045",
      "file_location":"assets/colors_organized/hue_10/brightness_05/2020040415253800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":5,
      "vividness":2,
      "r":67,
      "g":80,
      "b":63,
      "hex":"#43503f",
      "file_location":"assets/colors_organized/hue_10/brightness_05/2020040415253801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":5,
      "vividness":3,
      "r":62,
      "g":80,
      "b":58,
      "hex":"#3e503a",
      "file_location":"assets/colors_organized/hue_10/brightness_05/2020040415253900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":5,
      "vividness":4,
      "r":57,
      "g":80,
      "b":52,
      "hex":"#395034",
      "file_location":"assets/colors_organized/hue_10/brightness_05/2020040415253901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":5,
      "vividness":5,
      "r":53,
      "g":80,
      "b":47,
      "hex":"#35502f",
      "file_location":"assets/colors_organized/hue_10/brightness_05/2020040415254000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":5,
      "vividness":6,
      "r":49,
      "g":79,
      "b":41,
      "hex":"#314f29",
      "file_location":"assets/colors_organized/hue_10/brightness_05/2020040415254001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":5,
      "vividness":7,
      "r":44,
      "g":80,
      "b":36,
      "hex":"#2c5024",
      "file_location":"assets/colors_organized/hue_10/brightness_05/2020040415254100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":5,
      "vividness":8,
      "r":41,
      "g":81,
      "b":31,
      "hex":"#29511f",
      "file_location":"assets/colors_organized/hue_10/brightness_05/2020040415254200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":5,
      "vividness":9,
      "r":37,
      "g":80,
      "b":27,
      "hex":"#25501b",
      "file_location":"assets/colors_organized/hue_10/brightness_05/2020040415254201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":5,
      "vividness":10,
      "r":32,
      "g":80,
      "b":22,
      "hex":"#205016",
      "file_location":"assets/colors_organized/hue_10/brightness_05/2020040415254300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":5,
      "vividness":11,
      "r":28,
      "g":80,
      "b":14,
      "hex":"#1c500e",
      "file_location":"assets/colors_organized/hue_10/brightness_05/2020040415254301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":5,
      "vividness":12,
      "r":23,
      "g":80,
      "b":9,
      "hex":"#175009",
      "file_location":"assets/colors_organized/hue_10/brightness_05/2020040415254400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":5,
      "vividness":13,
      "r":19,
      "g":79,
      "b":5,
      "hex":"#134f05",
      "file_location":"assets/colors_organized/hue_10/brightness_05/2020040415254401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":6,
      "vividness":1,
      "r":83,
      "g":95,
      "b":81,
      "hex":"#535f51",
      "file_location":"assets/colors_organized/hue_10/brightness_06/2020040415252601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":6,
      "vividness":2,
      "r":78,
      "g":95,
      "b":76,
      "hex":"#4e5f4c",
      "file_location":"assets/colors_organized/hue_10/brightness_06/2020040415252700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":6,
      "vividness":3,
      "r":73,
      "g":96,
      "b":68,
      "hex":"#496044",
      "file_location":"assets/colors_organized/hue_10/brightness_06/2020040415252701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":6,
      "vividness":4,
      "r":68,
      "g":95,
      "b":62,
      "hex":"#445f3e",
      "file_location":"assets/colors_organized/hue_10/brightness_06/2020040415252800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":6,
      "vividness":5,
      "r":63,
      "g":95,
      "b":56,
      "hex":"#3f5f38",
      "file_location":"assets/colors_organized/hue_10/brightness_06/2020040415252801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":6,
      "vividness":6,
      "r":58,
      "g":96,
      "b":49,
      "hex":"#3a6031",
      "file_location":"assets/colors_organized/hue_10/brightness_06/2020040415252900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":6,
      "vividness":7,
      "r":53,
      "g":96,
      "b":43,
      "hex":"#35602b",
      "file_location":"assets/colors_organized/hue_10/brightness_06/2020040415253000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":6,
      "vividness":8,
      "r":47,
      "g":95,
      "b":37,
      "hex":"#2f5f25",
      "file_location":"assets/colors_organized/hue_10/brightness_06/2020040415253001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":6,
      "vividness":9,
      "r":43,
      "g":95,
      "b":29,
      "hex":"#2b5f1d",
      "file_location":"assets/colors_organized/hue_10/brightness_06/2020040415253100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":6,
      "vividness":10,
      "r":38,
      "g":95,
      "b":24,
      "hex":"#265f18",
      "file_location":"assets/colors_organized/hue_10/brightness_06/2020040415253101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":6,
      "vividness":11,
      "r":33,
      "g":95,
      "b":20,
      "hex":"#215f14",
      "file_location":"assets/colors_organized/hue_10/brightness_06/2020040415253200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":6,
      "vividness":12,
      "r":30,
      "g":95,
      "b":13,
      "hex":"#1e5f0d",
      "file_location":"assets/colors_organized/hue_10/brightness_06/2020040415253201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":6,
      "vividness":13,
      "r":22,
      "g":95,
      "b":6,
      "hex":"#165f06",
      "file_location":"assets/colors_organized/hue_10/brightness_06/2020040415253300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":7,
      "vividness":1,
      "r":98,
      "g":111,
      "b":94,
      "hex":"#626f5e",
      "file_location":"assets/colors_organized/hue_10/brightness_07/2020040415251501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":7,
      "vividness":2,
      "r":92,
      "g":110,
      "b":88,
      "hex":"#5c6e58",
      "file_location":"assets/colors_organized/hue_10/brightness_07/2020040415251600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":7,
      "vividness":3,
      "r":85,
      "g":110,
      "b":80,
      "hex":"#556e50",
      "file_location":"assets/colors_organized/hue_10/brightness_07/2020040415251601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":7,
      "vividness":4,
      "r":80,
      "g":110,
      "b":72,
      "hex":"#506e48",
      "file_location":"assets/colors_organized/hue_10/brightness_07/2020040415251700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":7,
      "vividness":5,
      "r":73,
      "g":110,
      "b":66,
      "hex":"#496e42",
      "file_location":"assets/colors_organized/hue_10/brightness_07/2020040415251701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":7,
      "vividness":6,
      "r":67,
      "g":110,
      "b":57,
      "hex":"#436e39",
      "file_location":"assets/colors_organized/hue_10/brightness_07/2020040415251800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":7,
      "vividness":7,
      "r":62,
      "g":110,
      "b":50,
      "hex":"#3e6e32",
      "file_location":"assets/colors_organized/hue_10/brightness_07/2020040415251801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":7,
      "vividness":8,
      "r":57,
      "g":109,
      "b":43,
      "hex":"#396d2b",
      "file_location":"assets/colors_organized/hue_10/brightness_07/2020040415251900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":7,
      "vividness":9,
      "r":50,
      "g":110,
      "b":36,
      "hex":"#326e24",
      "file_location":"assets/colors_organized/hue_10/brightness_07/2020040415252000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":7,
      "vividness":10,
      "r":45,
      "g":110,
      "b":30,
      "hex":"#2d6e1e",
      "file_location":"assets/colors_organized/hue_10/brightness_07/2020040415252001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":7,
      "vividness":11,
      "r":37,
      "g":110,
      "b":21,
      "hex":"#256e15",
      "file_location":"assets/colors_organized/hue_10/brightness_07/2020040415252100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":7,
      "vividness":12,
      "r":34,
      "g":110,
      "b":12,
      "hex":"#226e0c",
      "file_location":"assets/colors_organized/hue_10/brightness_07/2020040415252101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":7,
      "vividness":13,
      "r":27,
      "g":110,
      "b":6,
      "hex":"#1b6e06",
      "file_location":"assets/colors_organized/hue_10/brightness_07/2020040415252200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":8,
      "vividness":1,
      "r":111,
      "g":124,
      "b":107,
      "hex":"#6f7c6b",
      "file_location":"assets/colors_organized/hue_10/brightness_08/2020040415250400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":8,
      "vividness":2,
      "r":104,
      "g":124,
      "b":99,
      "hex":"#687c63",
      "file_location":"assets/colors_organized/hue_10/brightness_08/2020040415250401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":8,
      "vividness":3,
      "r":98,
      "g":123,
      "b":91,
      "hex":"#627b5b",
      "file_location":"assets/colors_organized/hue_10/brightness_08/2020040415250500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":8,
      "vividness":4,
      "r":91,
      "g":123,
      "b":82,
      "hex":"#5b7b52",
      "file_location":"assets/colors_organized/hue_10/brightness_08/2020040415250501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":8,
      "vividness":5,
      "r":84,
      "g":124,
      "b":74,
      "hex":"#547c4a",
      "file_location":"assets/colors_organized/hue_10/brightness_08/2020040415250600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":8,
      "vividness":6,
      "r":78,
      "g":123,
      "b":66,
      "hex":"#4e7b42",
      "file_location":"assets/colors_organized/hue_10/brightness_08/2020040415250601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":8,
      "vividness":7,
      "r":71,
      "g":123,
      "b":57,
      "hex":"#477b39",
      "file_location":"assets/colors_organized/hue_10/brightness_08/2020040415250700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":8,
      "vividness":8,
      "r":63,
      "g":124,
      "b":47,
      "hex":"#3f7c2f",
      "file_location":"assets/colors_organized/hue_10/brightness_08/2020040415250701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":8,
      "vividness":9,
      "r":57,
      "g":125,
      "b":40,
      "hex":"#397d28",
      "file_location":"assets/colors_organized/hue_10/brightness_08/2020040415250800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":8,
      "vividness":10,
      "r":51,
      "g":124,
      "b":34,
      "hex":"#337c22",
      "file_location":"assets/colors_organized/hue_10/brightness_08/2020040415250900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":8,
      "vividness":11,
      "r":43,
      "g":124,
      "b":22,
      "hex":"#2b7c16",
      "file_location":"assets/colors_organized/hue_10/brightness_08/2020040415250901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":8,
      "vividness":12,
      "r":38,
      "g":124,
      "b":15,
      "hex":"#267c0f",
      "file_location":"assets/colors_organized/hue_10/brightness_08/2020040415251000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":8,
      "vividness":13,
      "r":31,
      "g":124,
      "b":7,
      "hex":"#1f7c07",
      "file_location":"assets/colors_organized/hue_10/brightness_08/2020040415251001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":9,
      "vividness":1,
      "r":132,
      "g":139,
      "b":131,
      "hex":"#848b83",
      "file_location":"assets/colors_organized/hue_10/brightness_09/2020040415245201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":9,
      "vividness":2,
      "r":124,
      "g":139,
      "b":120,
      "hex":"#7c8b78",
      "file_location":"assets/colors_organized/hue_10/brightness_09/2020040415245300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":9,
      "vividness":3,
      "r":116,
      "g":139,
      "b":110,
      "hex":"#748b6e",
      "file_location":"assets/colors_organized/hue_10/brightness_09/2020040415245400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":9,
      "vividness":4,
      "r":109,
      "g":139,
      "b":101,
      "hex":"#6d8b65",
      "file_location":"assets/colors_organized/hue_10/brightness_09/2020040415245401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":9,
      "vividness":5,
      "r":102,
      "g":140,
      "b":93,
      "hex":"#668c5d",
      "file_location":"assets/colors_organized/hue_10/brightness_09/2020040415245500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":9,
      "vividness":6,
      "r":94,
      "g":139,
      "b":82,
      "hex":"#5e8b52",
      "file_location":"assets/colors_organized/hue_10/brightness_09/2020040415245501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":9,
      "vividness":7,
      "r":87,
      "g":139,
      "b":73,
      "hex":"#578b49",
      "file_location":"assets/colors_organized/hue_10/brightness_09/2020040415245600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":9,
      "vividness":8,
      "r":79,
      "g":139,
      "b":65,
      "hex":"#4f8b41",
      "file_location":"assets/colors_organized/hue_10/brightness_09/2020040415245601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":9,
      "vividness":9,
      "r":71,
      "g":139,
      "b":54,
      "hex":"#478b36",
      "file_location":"assets/colors_organized/hue_10/brightness_09/2020040415245700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":9,
      "vividness":10,
      "r":64,
      "g":139,
      "b":46,
      "hex":"#408b2e",
      "file_location":"assets/colors_organized/hue_10/brightness_09/2020040415245701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":9,
      "vividness":11,
      "r":57,
      "g":139,
      "b":37,
      "hex":"#398b25",
      "file_location":"assets/colors_organized/hue_10/brightness_09/2020040415245800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":9,
      "vividness":12,
      "r":50,
      "g":140,
      "b":27,
      "hex":"#328c1b",
      "file_location":"assets/colors_organized/hue_10/brightness_09/2020040415245801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":9,
      "vividness":13,
      "r":41,
      "g":139,
      "b":18,
      "hex":"#298b12",
      "file_location":"assets/colors_organized/hue_10/brightness_09/2020040415245900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":9,
      "vividness":14,
      "r":34,
      "g":139,
      "b":10,
      "hex":"#228b0a",
      "file_location":"assets/colors_organized/hue_10/brightness_09/2020040415245901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":10,
      "vividness":1,
      "r":147,
      "g":155,
      "b":144,
      "hex":"#939b90",
      "file_location":"assets/colors_organized/hue_10/brightness_10/2020040415244101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":10,
      "vividness":2,
      "r":137,
      "g":154,
      "b":135,
      "hex":"#899a87",
      "file_location":"assets/colors_organized/hue_10/brightness_10/2020040415244200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":10,
      "vividness":3,
      "r":129,
      "g":154,
      "b":122,
      "hex":"#819a7a",
      "file_location":"assets/colors_organized/hue_10/brightness_10/2020040415244201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":10,
      "vividness":4,
      "r":121,
      "g":153,
      "b":112,
      "hex":"#799970",
      "file_location":"assets/colors_organized/hue_10/brightness_10/2020040415244300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":10,
      "vividness":5,
      "r":112,
      "g":155,
      "b":102,
      "hex":"#709b66",
      "file_location":"assets/colors_organized/hue_10/brightness_10/2020040415244301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":10,
      "vividness":6,
      "r":104,
      "g":154,
      "b":91,
      "hex":"#689a5b",
      "file_location":"assets/colors_organized/hue_10/brightness_10/2020040415244400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":10,
      "vividness":7,
      "r":97,
      "g":155,
      "b":81,
      "hex":"#619b51",
      "file_location":"assets/colors_organized/hue_10/brightness_10/2020040415244500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":10,
      "vividness":8,
      "r":87,
      "g":155,
      "b":72,
      "hex":"#579b48",
      "file_location":"assets/colors_organized/hue_10/brightness_10/2020040415244501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":10,
      "vividness":9,
      "r":79,
      "g":154,
      "b":61,
      "hex":"#4f9a3d",
      "file_location":"assets/colors_organized/hue_10/brightness_10/2020040415244600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":10,
      "vividness":10,
      "r":72,
      "g":155,
      "b":51,
      "hex":"#489b33",
      "file_location":"assets/colors_organized/hue_10/brightness_10/2020040415244601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":10,
      "vividness":11,
      "r":62,
      "g":155,
      "b":41,
      "hex":"#3e9b29",
      "file_location":"assets/colors_organized/hue_10/brightness_10/2020040415244700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":10,
      "vividness":12,
      "r":54,
      "g":154,
      "b":28,
      "hex":"#369a1c",
      "file_location":"assets/colors_organized/hue_10/brightness_10/2020040415244701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":10,
      "vividness":13,
      "r":47,
      "g":155,
      "b":20,
      "hex":"#2f9b14",
      "file_location":"assets/colors_organized/hue_10/brightness_10/2020040415244800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":10,
      "vividness":14,
      "r":39,
      "g":154,
      "b":9,
      "hex":"#279a09",
      "file_location":"assets/colors_organized/hue_10/brightness_10/2020040415244801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":11,
      "vividness":1,
      "r":159,
      "g":169,
      "b":158,
      "hex":"#9fa99e",
      "file_location":"assets/colors_organized/hue_10/brightness_11/2020040415243100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":11,
      "vividness":2,
      "r":151,
      "g":169,
      "b":147,
      "hex":"#97a993",
      "file_location":"assets/colors_organized/hue_10/brightness_11/2020040415243101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":11,
      "vividness":3,
      "r":142,
      "g":169,
      "b":134,
      "hex":"#8ea986",
      "file_location":"assets/colors_organized/hue_10/brightness_11/2020040415243200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":11,
      "vividness":4,
      "r":133,
      "g":169,
      "b":123,
      "hex":"#85a97b",
      "file_location":"assets/colors_organized/hue_10/brightness_11/2020040415243201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":11,
      "vividness":5,
      "r":124,
      "g":169,
      "b":112,
      "hex":"#7ca970",
      "file_location":"assets/colors_organized/hue_10/brightness_11/2020040415243300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":11,
      "vividness":6,
      "r":114,
      "g":169,
      "b":101,
      "hex":"#72a965",
      "file_location":"assets/colors_organized/hue_10/brightness_11/2020040415243301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":11,
      "vividness":7,
      "r":106,
      "g":169,
      "b":90,
      "hex":"#6aa95a",
      "file_location":"assets/colors_organized/hue_10/brightness_11/2020040415243400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":11,
      "vividness":8,
      "r":96,
      "g":169,
      "b":79,
      "hex":"#60a94f",
      "file_location":"assets/colors_organized/hue_10/brightness_11/2020040415243401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":11,
      "vividness":9,
      "r":88,
      "g":169,
      "b":66,
      "hex":"#58a942",
      "file_location":"assets/colors_organized/hue_10/brightness_11/2020040415243500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":11,
      "vividness":10,
      "r":79,
      "g":169,
      "b":55,
      "hex":"#4fa937",
      "file_location":"assets/colors_organized/hue_10/brightness_11/2020040415243501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":11,
      "vividness":11,
      "r":69,
      "g":169,
      "b":43,
      "hex":"#45a92b",
      "file_location":"assets/colors_organized/hue_10/brightness_11/2020040415243600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":11,
      "vividness":12,
      "r":61,
      "g":169,
      "b":32,
      "hex":"#3da920",
      "file_location":"assets/colors_organized/hue_10/brightness_11/2020040415243601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":11,
      "vividness":13,
      "r":51,
      "g":169,
      "b":21,
      "hex":"#33a915",
      "file_location":"assets/colors_organized/hue_10/brightness_11/2020040415243700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":11,
      "vividness":14,
      "r":43,
      "g":169,
      "b":8,
      "hex":"#2ba908",
      "file_location":"assets/colors_organized/hue_10/brightness_11/2020040415243701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":12,
      "vividness":1,
      "r":174,
      "g":184,
      "b":173,
      "hex":"#aeb8ad",
      "file_location":"assets/colors_organized/hue_10/brightness_12/2020040415241901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":12,
      "vividness":2,
      "r":164,
      "g":184,
      "b":159,
      "hex":"#a4b89f",
      "file_location":"assets/colors_organized/hue_10/brightness_12/2020040415242000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":12,
      "vividness":3,
      "r":154,
      "g":184,
      "b":146,
      "hex":"#9ab892",
      "file_location":"assets/colors_organized/hue_10/brightness_12/2020040415242001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":12,
      "vividness":4,
      "r":145,
      "g":185,
      "b":135,
      "hex":"#91b987",
      "file_location":"assets/colors_organized/hue_10/brightness_12/2020040415242100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":12,
      "vividness":5,
      "r":135,
      "g":185,
      "b":122,
      "hex":"#87b97a",
      "file_location":"assets/colors_organized/hue_10/brightness_12/2020040415242101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":12,
      "vividness":6,
      "r":124,
      "g":184,
      "b":110,
      "hex":"#7cb86e",
      "file_location":"assets/colors_organized/hue_10/brightness_12/2020040415242200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":12,
      "vividness":7,
      "r":115,
      "g":185,
      "b":97,
      "hex":"#73b961",
      "file_location":"assets/colors_organized/hue_10/brightness_12/2020040415242201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":12,
      "vividness":8,
      "r":104,
      "g":184,
      "b":85,
      "hex":"#68b855",
      "file_location":"assets/colors_organized/hue_10/brightness_12/2020040415242300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":12,
      "vividness":9,
      "r":96,
      "g":184,
      "b":72,
      "hex":"#60b848",
      "file_location":"assets/colors_organized/hue_10/brightness_12/2020040415242301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":12,
      "vividness":10,
      "r":86,
      "g":184,
      "b":61,
      "hex":"#56b83d",
      "file_location":"assets/colors_organized/hue_10/brightness_12/2020040415242400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":12,
      "vividness":11,
      "r":76,
      "g":184,
      "b":47,
      "hex":"#4cb82f",
      "file_location":"assets/colors_organized/hue_10/brightness_12/2020040415242401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":12,
      "vividness":12,
      "r":66,
      "g":184,
      "b":36,
      "hex":"#42b824",
      "file_location":"assets/colors_organized/hue_10/brightness_12/2020040415242500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":12,
      "vividness":13,
      "r":55,
      "g":184,
      "b":22,
      "hex":"#37b816",
      "file_location":"assets/colors_organized/hue_10/brightness_12/2020040415242501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":12,
      "vividness":14,
      "r":46,
      "g":184,
      "b":11,
      "hex":"#2eb80b",
      "file_location":"assets/colors_organized/hue_10/brightness_12/2020040415242600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":13,
      "vividness":1,
      "r":188,
      "g":200,
      "b":186,
      "hex":"#bcc8ba",
      "file_location":"assets/colors_organized/hue_10/brightness_13/2020040415240701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":13,
      "vividness":2,
      "r":177,
      "g":200,
      "b":172,
      "hex":"#b1c8ac",
      "file_location":"assets/colors_organized/hue_10/brightness_13/2020040415240800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":13,
      "vividness":3,
      "r":167,
      "g":199,
      "b":158,
      "hex":"#a7c79e",
      "file_location":"assets/colors_organized/hue_10/brightness_13/2020040415240900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":13,
      "vividness":4,
      "r":157,
      "g":200,
      "b":146,
      "hex":"#9dc892",
      "file_location":"assets/colors_organized/hue_10/brightness_13/2020040415240901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":13,
      "vividness":5,
      "r":147,
      "g":199,
      "b":133,
      "hex":"#93c785",
      "file_location":"assets/colors_organized/hue_10/brightness_13/2020040415241000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":13,
      "vividness":6,
      "r":135,
      "g":200,
      "b":118,
      "hex":"#87c876",
      "file_location":"assets/colors_organized/hue_10/brightness_13/2020040415241001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":13,
      "vividness":7,
      "r":124,
      "g":199,
      "b":106,
      "hex":"#7cc76a",
      "file_location":"assets/colors_organized/hue_10/brightness_13/2020040415241100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":13,
      "vividness":8,
      "r":114,
      "g":200,
      "b":93,
      "hex":"#72c85d",
      "file_location":"assets/colors_organized/hue_10/brightness_13/2020040415241101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":13,
      "vividness":9,
      "r":102,
      "g":200,
      "b":79,
      "hex":"#66c84f",
      "file_location":"assets/colors_organized/hue_10/brightness_13/2020040415241200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":13,
      "vividness":10,
      "r":92,
      "g":200,
      "b":65,
      "hex":"#5cc841",
      "file_location":"assets/colors_organized/hue_10/brightness_13/2020040415241201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":13,
      "vividness":11,
      "r":82,
      "g":200,
      "b":52,
      "hex":"#52c834",
      "file_location":"assets/colors_organized/hue_10/brightness_13/2020040415241300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":13,
      "vividness":12,
      "r":71,
      "g":200,
      "b":38,
      "hex":"#47c826",
      "file_location":"assets/colors_organized/hue_10/brightness_13/2020040415241301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":13,
      "vividness":13,
      "r":59,
      "g":200,
      "b":26,
      "hex":"#3bc81a",
      "file_location":"assets/colors_organized/hue_10/brightness_13/2020040415241400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":13,
      "vividness":14,
      "r":49,
      "g":199,
      "b":13,
      "hex":"#31c70d",
      "file_location":"assets/colors_organized/hue_10/brightness_13/2020040415241500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":14,
      "vividness":1,
      "r":204,
      "g":215,
      "b":201,
      "hex":"#ccd7c9",
      "file_location":"assets/colors_organized/hue_10/brightness_14/2020040415235601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":14,
      "vividness":2,
      "r":191,
      "g":214,
      "b":185,
      "hex":"#bfd6b9",
      "file_location":"assets/colors_organized/hue_10/brightness_14/2020040415235700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":14,
      "vividness":3,
      "r":179,
      "g":215,
      "b":171,
      "hex":"#b3d7ab",
      "file_location":"assets/colors_organized/hue_10/brightness_14/2020040415235701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":14,
      "vividness":4,
      "r":167,
      "g":215,
      "b":157,
      "hex":"#a7d79d",
      "file_location":"assets/colors_organized/hue_10/brightness_14/2020040415235800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":14,
      "vividness":5,
      "r":157,
      "g":214,
      "b":143,
      "hex":"#9dd68f",
      "file_location":"assets/colors_organized/hue_10/brightness_14/2020040415235801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":14,
      "vividness":6,
      "r":145,
      "g":215,
      "b":127,
      "hex":"#91d77f",
      "file_location":"assets/colors_organized/hue_10/brightness_14/2020040415235900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":14,
      "vividness":7,
      "r":134,
      "g":215,
      "b":113,
      "hex":"#86d771",
      "file_location":"assets/colors_organized/hue_10/brightness_14/2020040415240000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":14,
      "vividness":8,
      "r":122,
      "g":215,
      "b":100,
      "hex":"#7ad764",
      "file_location":"assets/colors_organized/hue_10/brightness_14/2020040415240001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":14,
      "vividness":9,
      "r":112,
      "g":214,
      "b":86,
      "hex":"#70d656",
      "file_location":"assets/colors_organized/hue_10/brightness_14/2020040415240100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":14,
      "vividness":10,
      "r":100,
      "g":215,
      "b":70,
      "hex":"#64d746",
      "file_location":"assets/colors_organized/hue_10/brightness_14/2020040415240101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":14,
      "vividness":11,
      "r":87,
      "g":215,
      "b":56,
      "hex":"#57d738",
      "file_location":"assets/colors_organized/hue_10/brightness_14/2020040415240200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":14,
      "vividness":12,
      "r":77,
      "g":215,
      "b":42,
      "hex":"#4dd72a",
      "file_location":"assets/colors_organized/hue_10/brightness_14/2020040415240201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":14,
      "vividness":13,
      "r":64,
      "g":214,
      "b":28,
      "hex":"#40d61c",
      "file_location":"assets/colors_organized/hue_10/brightness_14/2020040415240300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":14,
      "vividness":14,
      "r":53,
      "g":214,
      "b":12,
      "hex":"#35d60c",
      "file_location":"assets/colors_organized/hue_10/brightness_14/2020040415240400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":15,
      "vividness":1,
      "r":217,
      "g":230,
      "b":213,
      "hex":"#d9e6d5",
      "file_location":"assets/colors_organized/hue_10/brightness_15/2020040415234501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":15,
      "vividness":2,
      "r":205,
      "g":230,
      "b":198,
      "hex":"#cde6c6",
      "file_location":"assets/colors_organized/hue_10/brightness_15/2020040415234600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":15,
      "vividness":3,
      "r":192,
      "g":230,
      "b":183,
      "hex":"#c0e6b7",
      "file_location":"assets/colors_organized/hue_10/brightness_15/2020040415234601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":15,
      "vividness":4,
      "r":180,
      "g":230,
      "b":169,
      "hex":"#b4e6a9",
      "file_location":"assets/colors_organized/hue_10/brightness_15/2020040415234700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":15,
      "vividness":5,
      "r":168,
      "g":229,
      "b":152,
      "hex":"#a8e598",
      "file_location":"assets/colors_organized/hue_10/brightness_15/2020040415234701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":15,
      "vividness":6,
      "r":156,
      "g":229,
      "b":137,
      "hex":"#9ce589",
      "file_location":"assets/colors_organized/hue_10/brightness_15/2020040415234800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":15,
      "vividness":7,
      "r":142,
      "g":229,
      "b":122,
      "hex":"#8ee57a",
      "file_location":"assets/colors_organized/hue_10/brightness_15/2020040415234801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":15,
      "vividness":8,
      "r":131,
      "g":229,
      "b":106,
      "hex":"#83e56a",
      "file_location":"assets/colors_organized/hue_10/brightness_15/2020040415234900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":15,
      "vividness":9,
      "r":119,
      "g":230,
      "b":91,
      "hex":"#77e65b",
      "file_location":"assets/colors_organized/hue_10/brightness_15/2020040415234901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":15,
      "vividness":10,
      "r":106,
      "g":229,
      "b":76,
      "hex":"#6ae54c",
      "file_location":"assets/colors_organized/hue_10/brightness_15/2020040415235000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":15,
      "vividness":11,
      "r":94,
      "g":230,
      "b":60,
      "hex":"#5ee63c",
      "file_location":"assets/colors_organized/hue_10/brightness_15/2020040415235001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":15,
      "vividness":12,
      "r":81,
      "g":230,
      "b":44,
      "hex":"#51e62c",
      "file_location":"assets/colors_organized/hue_10/brightness_15/2020040415235100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":15,
      "vividness":13,
      "r":69,
      "g":230,
      "b":30,
      "hex":"#45e61e",
      "file_location":"assets/colors_organized/hue_10/brightness_15/2020040415235101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":10,
      "brightness":15,
      "vividness":14,
      "r":57,
      "g":230,
      "b":14,
      "hex":"#39e60e",
      "file_location":"assets/colors_organized/hue_10/brightness_15/2020040415235200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":1,
      "vividness":1,
      "r":14,
      "g":19,
      "b":13,
      "hex":"#0e130d",
      "file_location":"assets/colors_organized/hue_11/brightness_01/2020040415291901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":1,
      "vividness":2,
      "r":2,
      "g":19,
      "b":1,
      "hex":"#021301",
      "file_location":"assets/colors_organized/hue_11/brightness_01/2020040415292301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":1,
      "vividness":3,
      "r":0,
      "g":20,
      "b":0,
      "hex":"#001400",
      "file_location":"assets/colors_organized/hue_11/brightness_01/2020040415292401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":2,
      "vividness":1,
      "r":32,
      "g":34,
      "b":31,
      "hex":"#20221f",
      "file_location":"assets/colors_organized/hue_11/brightness_02/2020040415290701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":2,
      "vividness":2,
      "r":29,
      "g":34,
      "b":28,
      "hex":"#1d221c",
      "file_location":"assets/colors_organized/hue_11/brightness_02/2020040415290800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":2,
      "vividness":3,
      "r":28,
      "g":35,
      "b":28,
      "hex":"#1c231c",
      "file_location":"assets/colors_organized/hue_11/brightness_02/2020040415290801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":2,
      "vividness":4,
      "r":24,
      "g":34,
      "b":25,
      "hex":"#182219",
      "file_location":"assets/colors_organized/hue_11/brightness_02/2020040415290900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":2,
      "vividness":5,
      "r":23,
      "g":35,
      "b":23,
      "hex":"#172317",
      "file_location":"assets/colors_organized/hue_11/brightness_02/2020040415290901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":2,
      "vividness":6,
      "r":21,
      "g":35,
      "b":22,
      "hex":"#152316",
      "file_location":"assets/colors_organized/hue_11/brightness_02/2020040415291000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":2,
      "vividness":7,
      "r":18,
      "g":35,
      "b":17,
      "hex":"#122311",
      "file_location":"assets/colors_organized/hue_11/brightness_02/2020040415291001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":2,
      "vividness":8,
      "r":13,
      "g":35,
      "b":14,
      "hex":"#0d230e",
      "file_location":"assets/colors_organized/hue_11/brightness_02/2020040415291100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":2,
      "vividness":9,
      "r":12,
      "g":34,
      "b":13,
      "hex":"#0c220d",
      "file_location":"assets/colors_organized/hue_11/brightness_02/2020040415291101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":2,
      "vividness":10,
      "r":9,
      "g":35,
      "b":10,
      "hex":"#09230a",
      "file_location":"assets/colors_organized/hue_11/brightness_02/2020040415291200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":2,
      "vividness":11,
      "r":6,
      "g":35,
      "b":7,
      "hex":"#062307",
      "file_location":"assets/colors_organized/hue_11/brightness_02/2020040415291300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":2,
      "vividness":12,
      "r":1,
      "g":35,
      "b":2,
      "hex":"#012302",
      "file_location":"assets/colors_organized/hue_11/brightness_02/2020040415291301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":3,
      "vividness":1,
      "r":45,
      "g":50,
      "b":46,
      "hex":"#2d322e",
      "file_location":"assets/colors_organized/hue_11/brightness_03/2020040415285601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":3,
      "vividness":2,
      "r":43,
      "g":50,
      "b":43,
      "hex":"#2b322b",
      "file_location":"assets/colors_organized/hue_11/brightness_03/2020040415285700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":3,
      "vividness":3,
      "r":39,
      "g":49,
      "b":40,
      "hex":"#273128",
      "file_location":"assets/colors_organized/hue_11/brightness_03/2020040415285701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":3,
      "vividness":4,
      "r":36,
      "g":50,
      "b":35,
      "hex":"#243223",
      "file_location":"assets/colors_organized/hue_11/brightness_03/2020040415285702-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":3,
      "vividness":5,
      "r":33,
      "g":50,
      "b":34,
      "hex":"#213222",
      "file_location":"assets/colors_organized/hue_11/brightness_03/2020040415285800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":3,
      "vividness":6,
      "r":28,
      "g":50,
      "b":29,
      "hex":"#1c321d",
      "file_location":"assets/colors_organized/hue_11/brightness_03/2020040415285801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":3,
      "vividness":7,
      "r":26,
      "g":50,
      "b":26,
      "hex":"#1a321a",
      "file_location":"assets/colors_organized/hue_11/brightness_03/2020040415285900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":3,
      "vividness":8,
      "r":23,
      "g":49,
      "b":22,
      "hex":"#173116",
      "file_location":"assets/colors_organized/hue_11/brightness_03/2020040415285901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":3,
      "vividness":9,
      "r":19,
      "g":50,
      "b":19,
      "hex":"#133213",
      "file_location":"assets/colors_organized/hue_11/brightness_03/2020040415290000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":3,
      "vividness":10,
      "r":14,
      "g":50,
      "b":14,
      "hex":"#0e320e",
      "file_location":"assets/colors_organized/hue_11/brightness_03/2020040415290001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":3,
      "vividness":11,
      "r":12,
      "g":50,
      "b":13,
      "hex":"#0c320d",
      "file_location":"assets/colors_organized/hue_11/brightness_03/2020040415290100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":3,
      "vividness":12,
      "r":9,
      "g":50,
      "b":10,
      "hex":"#09320a",
      "file_location":"assets/colors_organized/hue_11/brightness_03/2020040415290101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":3,
      "vividness":13,
      "r":7,
      "g":50,
      "b":7,
      "hex":"#073207",
      "file_location":"assets/colors_organized/hue_11/brightness_03/2020040415290200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":3,
      "vividness":14,
      "r":2,
      "g":50,
      "b":2,
      "hex":"#023202",
      "file_location":"assets/colors_organized/hue_11/brightness_03/2020040415290300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":4,
      "vividness":1,
      "r":55,
      "g":65,
      "b":56,
      "hex":"#374138",
      "file_location":"assets/colors_organized/hue_11/brightness_04/2020040415284600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":4,
      "vividness":2,
      "r":51,
      "g":65,
      "b":50,
      "hex":"#334132",
      "file_location":"assets/colors_organized/hue_11/brightness_04/2020040415284601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":4,
      "vividness":3,
      "r":46,
      "g":65,
      "b":46,
      "hex":"#2e412e",
      "file_location":"assets/colors_organized/hue_11/brightness_04/2020040415284700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":4,
      "vividness":4,
      "r":43,
      "g":65,
      "b":42,
      "hex":"#2b412a",
      "file_location":"assets/colors_organized/hue_11/brightness_04/2020040415284701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":4,
      "vividness":5,
      "r":39,
      "g":65,
      "b":38,
      "hex":"#274126",
      "file_location":"assets/colors_organized/hue_11/brightness_04/2020040415284800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":4,
      "vividness":6,
      "r":34,
      "g":65,
      "b":34,
      "hex":"#224122",
      "file_location":"assets/colors_organized/hue_11/brightness_04/2020040415284801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":4,
      "vividness":7,
      "r":29,
      "g":65,
      "b":29,
      "hex":"#1d411d",
      "file_location":"assets/colors_organized/hue_11/brightness_04/2020040415284900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":4,
      "vividness":8,
      "r":27,
      "g":65,
      "b":26,
      "hex":"#1b411a",
      "file_location":"assets/colors_organized/hue_11/brightness_04/2020040415284901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":4,
      "vividness":9,
      "r":22,
      "g":65,
      "b":22,
      "hex":"#164116",
      "file_location":"assets/colors_organized/hue_11/brightness_04/2020040415285000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":4,
      "vividness":10,
      "r":17,
      "g":65,
      "b":17,
      "hex":"#114111",
      "file_location":"assets/colors_organized/hue_11/brightness_04/2020040415285001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":4,
      "vividness":11,
      "r":12,
      "g":65,
      "b":13,
      "hex":"#0c410d",
      "file_location":"assets/colors_organized/hue_11/brightness_04/2020040415285100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":4,
      "vividness":12,
      "r":6,
      "g":65,
      "b":7,
      "hex":"#064107",
      "file_location":"assets/colors_organized/hue_11/brightness_04/2020040415285101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":4,
      "vividness":13,
      "r":3,
      "g":65,
      "b":2,
      "hex":"#034102",
      "file_location":"assets/colors_organized/hue_11/brightness_04/2020040415285200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":5,
      "vividness":1,
      "r":73,
      "g":80,
      "b":73,
      "hex":"#495049",
      "file_location":"assets/colors_organized/hue_11/brightness_05/2020040415283500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":5,
      "vividness":2,
      "r":68,
      "g":80,
      "b":68,
      "hex":"#445044",
      "file_location":"assets/colors_organized/hue_11/brightness_05/2020040415283501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":5,
      "vividness":3,
      "r":63,
      "g":80,
      "b":64,
      "hex":"#3f5040",
      "file_location":"assets/colors_organized/hue_11/brightness_05/2020040415283600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":5,
      "vividness":4,
      "r":58,
      "g":80,
      "b":57,
      "hex":"#3a5039",
      "file_location":"assets/colors_organized/hue_11/brightness_05/2020040415283601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":5,
      "vividness":5,
      "r":52,
      "g":81,
      "b":53,
      "hex":"#345135",
      "file_location":"assets/colors_organized/hue_11/brightness_05/2020040415283700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":5,
      "vividness":6,
      "r":46,
      "g":80,
      "b":47,
      "hex":"#2e502f",
      "file_location":"assets/colors_organized/hue_11/brightness_05/2020040415283701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":5,
      "vividness":7,
      "r":42,
      "g":80,
      "b":41,
      "hex":"#2a5029",
      "file_location":"assets/colors_organized/hue_11/brightness_05/2020040415283800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":5,
      "vividness":8,
      "r":37,
      "g":80,
      "b":37,
      "hex":"#255025",
      "file_location":"assets/colors_organized/hue_11/brightness_05/2020040415283801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":5,
      "vividness":9,
      "r":32,
      "g":80,
      "b":32,
      "hex":"#205020",
      "file_location":"assets/colors_organized/hue_11/brightness_05/2020040415283900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":5,
      "vividness":10,
      "r":27,
      "g":80,
      "b":26,
      "hex":"#1b501a",
      "file_location":"assets/colors_organized/hue_11/brightness_05/2020040415283901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":5,
      "vividness":11,
      "r":21,
      "g":80,
      "b":22,
      "hex":"#155016",
      "file_location":"assets/colors_organized/hue_11/brightness_05/2020040415284000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":5,
      "vividness":12,
      "r":15,
      "g":80,
      "b":14,
      "hex":"#0f500e",
      "file_location":"assets/colors_organized/hue_11/brightness_05/2020040415284001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":5,
      "vividness":13,
      "r":9,
      "g":80,
      "b":10,
      "hex":"#09500a",
      "file_location":"assets/colors_organized/hue_11/brightness_05/2020040415284100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":5,
      "vividness":14,
      "r":7,
      "g":80,
      "b":7,
      "hex":"#075007",
      "file_location":"assets/colors_organized/hue_11/brightness_05/2020040415284101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":6,
      "vividness":1,
      "r":88,
      "g":95,
      "b":88,
      "hex":"#585f58",
      "file_location":"assets/colors_organized/hue_11/brightness_06/2020040415282401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":6,
      "vividness":2,
      "r":81,
      "g":95,
      "b":82,
      "hex":"#515f52",
      "file_location":"assets/colors_organized/hue_11/brightness_06/2020040415282500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":6,
      "vividness":3,
      "r":76,
      "g":95,
      "b":76,
      "hex":"#4c5f4c",
      "file_location":"assets/colors_organized/hue_11/brightness_06/2020040415282501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":6,
      "vividness":4,
      "r":69,
      "g":95,
      "b":68,
      "hex":"#455f44",
      "file_location":"assets/colors_organized/hue_11/brightness_06/2020040415282600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":6,
      "vividness":5,
      "r":61,
      "g":95,
      "b":62,
      "hex":"#3d5f3e",
      "file_location":"assets/colors_organized/hue_11/brightness_06/2020040415282601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":6,
      "vividness":6,
      "r":57,
      "g":95,
      "b":56,
      "hex":"#395f38",
      "file_location":"assets/colors_organized/hue_11/brightness_06/2020040415282700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":6,
      "vividness":7,
      "r":49,
      "g":95,
      "b":49,
      "hex":"#315f31",
      "file_location":"assets/colors_organized/hue_11/brightness_06/2020040415282701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":6,
      "vividness":8,
      "r":43,
      "g":96,
      "b":44,
      "hex":"#2b602c",
      "file_location":"assets/colors_organized/hue_11/brightness_06/2020040415282800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":6,
      "vividness":9,
      "r":37,
      "g":95,
      "b":37,
      "hex":"#255f25",
      "file_location":"assets/colors_organized/hue_11/brightness_06/2020040415282801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":6,
      "vividness":10,
      "r":30,
      "g":95,
      "b":31,
      "hex":"#1e5f1f",
      "file_location":"assets/colors_organized/hue_11/brightness_06/2020040415282900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":6,
      "vividness":11,
      "r":24,
      "g":95,
      "b":25,
      "hex":"#185f19",
      "file_location":"assets/colors_organized/hue_11/brightness_06/2020040415282901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":6,
      "vividness":12,
      "r":19,
      "g":95,
      "b":20,
      "hex":"#135f14",
      "file_location":"assets/colors_organized/hue_11/brightness_06/2020040415283000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":6,
      "vividness":13,
      "r":12,
      "g":95,
      "b":13,
      "hex":"#0c5f0d",
      "file_location":"assets/colors_organized/hue_11/brightness_06/2020040415283001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":6,
      "vividness":14,
      "r":6,
      "g":94,
      "b":7,
      "hex":"#065e07",
      "file_location":"assets/colors_organized/hue_11/brightness_06/2020040415283100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":7,
      "vividness":1,
      "r":103,
      "g":110,
      "b":102,
      "hex":"#676e66",
      "file_location":"assets/colors_organized/hue_11/brightness_07/2020040415281301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":7,
      "vividness":2,
      "r":96,
      "g":110,
      "b":95,
      "hex":"#606e5f",
      "file_location":"assets/colors_organized/hue_11/brightness_07/2020040415281400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":7,
      "vividness":3,
      "r":88,
      "g":110,
      "b":89,
      "hex":"#586e59",
      "file_location":"assets/colors_organized/hue_11/brightness_07/2020040415281401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":7,
      "vividness":4,
      "r":79,
      "g":110,
      "b":79,
      "hex":"#4f6e4f",
      "file_location":"assets/colors_organized/hue_11/brightness_07/2020040415281500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":7,
      "vividness":5,
      "r":73,
      "g":111,
      "b":74,
      "hex":"#496f4a",
      "file_location":"assets/colors_organized/hue_11/brightness_07/2020040415281501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":7,
      "vividness":6,
      "r":65,
      "g":111,
      "b":65,
      "hex":"#416f41",
      "file_location":"assets/colors_organized/hue_11/brightness_07/2020040415281600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":7,
      "vividness":7,
      "r":57,
      "g":110,
      "b":58,
      "hex":"#396e3a",
      "file_location":"assets/colors_organized/hue_11/brightness_07/2020040415281601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":7,
      "vividness":8,
      "r":50,
      "g":110,
      "b":50,
      "hex":"#326e32",
      "file_location":"assets/colors_organized/hue_11/brightness_07/2020040415281700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":7,
      "vividness":9,
      "r":43,
      "g":110,
      "b":43,
      "hex":"#2b6e2b",
      "file_location":"assets/colors_organized/hue_11/brightness_07/2020040415281701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":7,
      "vividness":10,
      "r":36,
      "g":110,
      "b":35,
      "hex":"#246e23",
      "file_location":"assets/colors_organized/hue_11/brightness_07/2020040415281800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":7,
      "vividness":11,
      "r":29,
      "g":111,
      "b":29,
      "hex":"#1d6f1d",
      "file_location":"assets/colors_organized/hue_11/brightness_07/2020040415281801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":7,
      "vividness":12,
      "r":21,
      "g":109,
      "b":22,
      "hex":"#156d16",
      "file_location":"assets/colors_organized/hue_11/brightness_07/2020040415281802-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":7,
      "vividness":13,
      "r":13,
      "g":110,
      "b":13,
      "hex":"#0d6e0d",
      "file_location":"assets/colors_organized/hue_11/brightness_07/2020040415281900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":7,
      "vividness":14,
      "r":7,
      "g":109,
      "b":7,
      "hex":"#076d07",
      "file_location":"assets/colors_organized/hue_11/brightness_07/2020040415281901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":8,
      "vividness":1,
      "r":117,
      "g":124,
      "b":116,
      "hex":"#757c74",
      "file_location":"assets/colors_organized/hue_11/brightness_08/2020040415280201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":8,
      "vividness":2,
      "r":107,
      "g":124,
      "b":108,
      "hex":"#6b7c6c",
      "file_location":"assets/colors_organized/hue_11/brightness_08/2020040415280300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":8,
      "vividness":3,
      "r":99,
      "g":125,
      "b":100,
      "hex":"#637d64",
      "file_location":"assets/colors_organized/hue_11/brightness_08/2020040415280301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":8,
      "vividness":4,
      "r":90,
      "g":124,
      "b":91,
      "hex":"#5a7c5b",
      "file_location":"assets/colors_organized/hue_11/brightness_08/2020040415280400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":8,
      "vividness":5,
      "r":83,
      "g":124,
      "b":82,
      "hex":"#537c52",
      "file_location":"assets/colors_organized/hue_11/brightness_08/2020040415280401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":8,
      "vividness":6,
      "r":74,
      "g":124,
      "b":73,
      "hex":"#4a7c49",
      "file_location":"assets/colors_organized/hue_11/brightness_08/2020040415280500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":8,
      "vividness":7,
      "r":66,
      "g":124,
      "b":66,
      "hex":"#427c42",
      "file_location":"assets/colors_organized/hue_11/brightness_08/2020040415280501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":8,
      "vividness":8,
      "r":57,
      "g":124,
      "b":57,
      "hex":"#397c39",
      "file_location":"assets/colors_organized/hue_11/brightness_08/2020040415280600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":8,
      "vividness":9,
      "r":48,
      "g":124,
      "b":49,
      "hex":"#307c31",
      "file_location":"assets/colors_organized/hue_11/brightness_08/2020040415280601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":8,
      "vividness":10,
      "r":40,
      "g":124,
      "b":39,
      "hex":"#287c27",
      "file_location":"assets/colors_organized/hue_11/brightness_08/2020040415280700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":8,
      "vividness":11,
      "r":34,
      "g":124,
      "b":34,
      "hex":"#227c22",
      "file_location":"assets/colors_organized/hue_11/brightness_08/2020040415280701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":8,
      "vividness":12,
      "r":22,
      "g":124,
      "b":22,
      "hex":"#167c16",
      "file_location":"assets/colors_organized/hue_11/brightness_08/2020040415280800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":8,
      "vividness":13,
      "r":15,
      "g":124,
      "b":15,
      "hex":"#0f7c0f",
      "file_location":"assets/colors_organized/hue_11/brightness_08/2020040415280801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":8,
      "vividness":14,
      "r":6,
      "g":123,
      "b":6,
      "hex":"#067b06",
      "file_location":"assets/colors_organized/hue_11/brightness_08/2020040415280900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":9,
      "vividness":1,
      "r":129,
      "g":139,
      "b":130,
      "hex":"#818b82",
      "file_location":"assets/colors_organized/hue_11/brightness_09/2020040415274400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":9,
      "vividness":2,
      "r":121,
      "g":140,
      "b":121,
      "hex":"#798c79",
      "file_location":"assets/colors_organized/hue_11/brightness_09/2020040415274500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":9,
      "vividness":3,
      "r":110,
      "g":139,
      "b":111,
      "hex":"#6e8b6f",
      "file_location":"assets/colors_organized/hue_11/brightness_09/2020040415274501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":9,
      "vividness":4,
      "r":102,
      "g":140,
      "b":103,
      "hex":"#668c67",
      "file_location":"assets/colors_organized/hue_11/brightness_09/2020040415274600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":9,
      "vividness":5,
      "r":92,
      "g":139,
      "b":93,
      "hex":"#5c8b5d",
      "file_location":"assets/colors_organized/hue_11/brightness_09/2020040415274601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":9,
      "vividness":6,
      "r":82,
      "g":140,
      "b":82,
      "hex":"#528c52",
      "file_location":"assets/colors_organized/hue_11/brightness_09/2020040415274700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":9,
      "vividness":7,
      "r":74,
      "g":139,
      "b":73,
      "hex":"#4a8b49",
      "file_location":"assets/colors_organized/hue_11/brightness_09/2020040415274701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":9,
      "vividness":8,
      "r":65,
      "g":139,
      "b":64,
      "hex":"#418b40",
      "file_location":"assets/colors_organized/hue_11/brightness_09/2020040415274800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":9,
      "vividness":9,
      "r":54,
      "g":139,
      "b":54,
      "hex":"#368b36",
      "file_location":"assets/colors_organized/hue_11/brightness_09/2020040415274900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":9,
      "vividness":10,
      "r":45,
      "g":139,
      "b":45,
      "hex":"#2d8b2d",
      "file_location":"assets/colors_organized/hue_11/brightness_09/2020040415274901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":9,
      "vividness":11,
      "r":37,
      "g":139,
      "b":37,
      "hex":"#258b25",
      "file_location":"assets/colors_organized/hue_11/brightness_09/2020040415275000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":9,
      "vividness":12,
      "r":27,
      "g":139,
      "b":27,
      "hex":"#1b8b1b",
      "file_location":"assets/colors_organized/hue_11/brightness_09/2020040415275001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":9,
      "vividness":13,
      "r":17,
      "g":139,
      "b":18,
      "hex":"#118b12",
      "file_location":"assets/colors_organized/hue_11/brightness_09/2020040415275100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":9,
      "vividness":14,
      "r":10,
      "g":139,
      "b":10,
      "hex":"#0a8b0a",
      "file_location":"assets/colors_organized/hue_11/brightness_09/2020040415275101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":10,
      "vividness":1,
      "r":144,
      "g":154,
      "b":145,
      "hex":"#909a91",
      "file_location":"assets/colors_organized/hue_11/brightness_10/2020040415273300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":10,
      "vividness":2,
      "r":133,
      "g":155,
      "b":134,
      "hex":"#859b86",
      "file_location":"assets/colors_organized/hue_11/brightness_10/2020040415273301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":10,
      "vividness":3,
      "r":123,
      "g":154,
      "b":123,
      "hex":"#7b9a7b",
      "file_location":"assets/colors_organized/hue_11/brightness_10/2020040415273400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":10,
      "vividness":4,
      "r":113,
      "g":154,
      "b":112,
      "hex":"#719a70",
      "file_location":"assets/colors_organized/hue_11/brightness_10/2020040415273401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":10,
      "vividness":5,
      "r":102,
      "g":155,
      "b":103,
      "hex":"#669b67",
      "file_location":"assets/colors_organized/hue_11/brightness_10/2020040415273500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":10,
      "vividness":6,
      "r":92,
      "g":154,
      "b":91,
      "hex":"#5c9a5b",
      "file_location":"assets/colors_organized/hue_11/brightness_10/2020040415273501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":10,
      "vividness":7,
      "r":82,
      "g":154,
      "b":81,
      "hex":"#529a51",
      "file_location":"assets/colors_organized/hue_11/brightness_10/2020040415273600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":10,
      "vividness":8,
      "r":71,
      "g":154,
      "b":72,
      "hex":"#479a48",
      "file_location":"assets/colors_organized/hue_11/brightness_10/2020040415273601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":10,
      "vividness":9,
      "r":60,
      "g":154,
      "b":60,
      "hex":"#3c9a3c",
      "file_location":"assets/colors_organized/hue_11/brightness_10/2020040415273700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":10,
      "vividness":10,
      "r":51,
      "g":153,
      "b":51,
      "hex":"#339933",
      "file_location":"assets/colors_organized/hue_11/brightness_10/2020040415273701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":10,
      "vividness":11,
      "r":40,
      "g":154,
      "b":40,
      "hex":"#289a28",
      "file_location":"assets/colors_organized/hue_11/brightness_10/2020040415273800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":10,
      "vividness":12,
      "r":30,
      "g":154,
      "b":30,
      "hex":"#1e9a1e",
      "file_location":"assets/colors_organized/hue_11/brightness_10/2020040415273801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":10,
      "vividness":13,
      "r":20,
      "g":154,
      "b":19,
      "hex":"#149a13",
      "file_location":"assets/colors_organized/hue_11/brightness_10/2020040415273900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":10,
      "vividness":14,
      "r":8,
      "g":154,
      "b":9,
      "hex":"#089a09",
      "file_location":"assets/colors_organized/hue_11/brightness_10/2020040415274000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":11,
      "vividness":1,
      "r":158,
      "g":170,
      "b":158,
      "hex":"#9eaa9e",
      "file_location":"assets/colors_organized/hue_11/brightness_11/2020040415272201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":11,
      "vividness":2,
      "r":147,
      "g":169,
      "b":148,
      "hex":"#93a994",
      "file_location":"assets/colors_organized/hue_11/brightness_11/2020040415272300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":11,
      "vividness":3,
      "r":135,
      "g":169,
      "b":136,
      "hex":"#87a988",
      "file_location":"assets/colors_organized/hue_11/brightness_11/2020040415272301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":11,
      "vividness":4,
      "r":123,
      "g":169,
      "b":123,
      "hex":"#7ba97b",
      "file_location":"assets/colors_organized/hue_11/brightness_11/2020040415272400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":11,
      "vividness":5,
      "r":112,
      "g":170,
      "b":112,
      "hex":"#70aa70",
      "file_location":"assets/colors_organized/hue_11/brightness_11/2020040415272401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":11,
      "vividness":6,
      "r":100,
      "g":170,
      "b":100,
      "hex":"#64aa64",
      "file_location":"assets/colors_organized/hue_11/brightness_11/2020040415272500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":11,
      "vividness":7,
      "r":90,
      "g":169,
      "b":90,
      "hex":"#5aa95a",
      "file_location":"assets/colors_organized/hue_11/brightness_11/2020040415272501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":11,
      "vividness":8,
      "r":79,
      "g":169,
      "b":79,
      "hex":"#4fa94f",
      "file_location":"assets/colors_organized/hue_11/brightness_11/2020040415272600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":11,
      "vividness":9,
      "r":67,
      "g":169,
      "b":67,
      "hex":"#43a943",
      "file_location":"assets/colors_organized/hue_11/brightness_11/2020040415272601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":11,
      "vividness":10,
      "r":55,
      "g":169,
      "b":55,
      "hex":"#37a937",
      "file_location":"assets/colors_organized/hue_11/brightness_11/2020040415272602-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":11,
      "vividness":11,
      "r":45,
      "g":169,
      "b":45,
      "hex":"#2da92d",
      "file_location":"assets/colors_organized/hue_11/brightness_11/2020040415272700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":11,
      "vividness":12,
      "r":33,
      "g":169,
      "b":33,
      "hex":"#21a921",
      "file_location":"assets/colors_organized/hue_11/brightness_11/2020040415272701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":11,
      "vividness":13,
      "r":21,
      "g":169,
      "b":21,
      "hex":"#15a915",
      "file_location":"assets/colors_organized/hue_11/brightness_11/2020040415272800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":11,
      "vividness":14,
      "r":9,
      "g":169,
      "b":9,
      "hex":"#09a909",
      "file_location":"assets/colors_organized/hue_11/brightness_11/2020040415272801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":12,
      "vividness":1,
      "r":172,
      "g":184,
      "b":172,
      "hex":"#acb8ac",
      "file_location":"assets/colors_organized/hue_11/brightness_12/2020040415271100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":12,
      "vividness":2,
      "r":160,
      "g":184,
      "b":160,
      "hex":"#a0b8a0",
      "file_location":"assets/colors_organized/hue_11/brightness_12/2020040415271101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":12,
      "vividness":3,
      "r":147,
      "g":185,
      "b":148,
      "hex":"#93b994",
      "file_location":"assets/colors_organized/hue_11/brightness_12/2020040415271200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":12,
      "vividness":4,
      "r":135,
      "g":185,
      "b":136,
      "hex":"#87b988",
      "file_location":"assets/colors_organized/hue_11/brightness_12/2020040415271300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":12,
      "vividness":5,
      "r":123,
      "g":184,
      "b":124,
      "hex":"#7bb87c",
      "file_location":"assets/colors_organized/hue_11/brightness_12/2020040415271301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":12,
      "vividness":6,
      "r":110,
      "g":184,
      "b":109,
      "hex":"#6eb86d",
      "file_location":"assets/colors_organized/hue_11/brightness_12/2020040415271400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":12,
      "vividness":7,
      "r":99,
      "g":185,
      "b":98,
      "hex":"#63b962",
      "file_location":"assets/colors_organized/hue_11/brightness_12/2020040415271401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":12,
      "vividness":8,
      "r":87,
      "g":185,
      "b":86,
      "hex":"#57b956",
      "file_location":"assets/colors_organized/hue_11/brightness_12/2020040415271500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":12,
      "vividness":9,
      "r":72,
      "g":184,
      "b":72,
      "hex":"#48b848",
      "file_location":"assets/colors_organized/hue_11/brightness_12/2020040415271501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":12,
      "vividness":10,
      "r":60,
      "g":184,
      "b":60,
      "hex":"#3cb83c",
      "file_location":"assets/colors_organized/hue_11/brightness_12/2020040415271600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":12,
      "vividness":11,
      "r":48,
      "g":184,
      "b":48,
      "hex":"#30b830",
      "file_location":"assets/colors_organized/hue_11/brightness_12/2020040415271601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":12,
      "vividness":12,
      "r":36,
      "g":184,
      "b":36,
      "hex":"#24b824",
      "file_location":"assets/colors_organized/hue_11/brightness_12/2020040415271700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":12,
      "vividness":13,
      "r":22,
      "g":184,
      "b":21,
      "hex":"#16b815",
      "file_location":"assets/colors_organized/hue_11/brightness_12/2020040415271701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":12,
      "vividness":14,
      "r":12,
      "g":184,
      "b":12,
      "hex":"#0cb80c",
      "file_location":"assets/colors_organized/hue_11/brightness_12/2020040415271800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":13,
      "vividness":1,
      "r":186,
      "g":200,
      "b":185,
      "hex":"#bac8b9",
      "file_location":"assets/colors_organized/hue_11/brightness_13/2020040415270001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":13,
      "vividness":2,
      "r":174,
      "g":200,
      "b":173,
      "hex":"#aec8ad",
      "file_location":"assets/colors_organized/hue_11/brightness_13/2020040415270100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":13,
      "vividness":3,
      "r":159,
      "g":200,
      "b":160,
      "hex":"#9fc8a0",
      "file_location":"assets/colors_organized/hue_11/brightness_13/2020040415270101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":13,
      "vividness":4,
      "r":146,
      "g":199,
      "b":145,
      "hex":"#92c791",
      "file_location":"assets/colors_organized/hue_11/brightness_13/2020040415270200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":13,
      "vividness":5,
      "r":133,
      "g":200,
      "b":133,
      "hex":"#85c885",
      "file_location":"assets/colors_organized/hue_11/brightness_13/2020040415270201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":13,
      "vividness":6,
      "r":118,
      "g":200,
      "b":118,
      "hex":"#76c876",
      "file_location":"assets/colors_organized/hue_11/brightness_13/2020040415270300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":13,
      "vividness":7,
      "r":106,
      "g":200,
      "b":106,
      "hex":"#6ac86a",
      "file_location":"assets/colors_organized/hue_11/brightness_13/2020040415270301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":13,
      "vividness":8,
      "r":92,
      "g":199,
      "b":93,
      "hex":"#5cc75d",
      "file_location":"assets/colors_organized/hue_11/brightness_13/2020040415270400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":13,
      "vividness":9,
      "r":78,
      "g":200,
      "b":79,
      "hex":"#4ec84f",
      "file_location":"assets/colors_organized/hue_11/brightness_13/2020040415270401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":13,
      "vividness":10,
      "r":65,
      "g":199,
      "b":66,
      "hex":"#41c742",
      "file_location":"assets/colors_organized/hue_11/brightness_13/2020040415270500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":13,
      "vividness":11,
      "r":52,
      "g":200,
      "b":52,
      "hex":"#34c834",
      "file_location":"assets/colors_organized/hue_11/brightness_13/2020040415270501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":13,
      "vividness":12,
      "r":39,
      "g":199,
      "b":39,
      "hex":"#27c727",
      "file_location":"assets/colors_organized/hue_11/brightness_13/2020040415270600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":13,
      "vividness":13,
      "r":26,
      "g":200,
      "b":27,
      "hex":"#1ac81b",
      "file_location":"assets/colors_organized/hue_11/brightness_13/2020040415270601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":13,
      "vividness":14,
      "r":13,
      "g":199,
      "b":12,
      "hex":"#0dc70c",
      "file_location":"assets/colors_organized/hue_11/brightness_13/2020040415270700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":14,
      "vividness":1,
      "r":201,
      "g":215,
      "b":200,
      "hex":"#c9d7c8",
      "file_location":"assets/colors_organized/hue_11/brightness_14/2020040415264901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":14,
      "vividness":2,
      "r":185,
      "g":214,
      "b":184,
      "hex":"#b9d6b8",
      "file_location":"assets/colors_organized/hue_11/brightness_14/2020040415265000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":14,
      "vividness":3,
      "r":172,
      "g":215,
      "b":172,
      "hex":"#acd7ac",
      "file_location":"assets/colors_organized/hue_11/brightness_14/2020040415265001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":14,
      "vividness":4,
      "r":157,
      "g":215,
      "b":157,
      "hex":"#9dd79d",
      "file_location":"assets/colors_organized/hue_11/brightness_14/2020040415265100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":14,
      "vividness":5,
      "r":143,
      "g":215,
      "b":142,
      "hex":"#8fd78e",
      "file_location":"assets/colors_organized/hue_11/brightness_14/2020040415265101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":14,
      "vividness":6,
      "r":129,
      "g":215,
      "b":128,
      "hex":"#81d780",
      "file_location":"assets/colors_organized/hue_11/brightness_14/2020040415265200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":14,
      "vividness":7,
      "r":114,
      "g":214,
      "b":115,
      "hex":"#72d673",
      "file_location":"assets/colors_organized/hue_11/brightness_14/2020040415265201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":14,
      "vividness":8,
      "r":100,
      "g":214,
      "b":100,
      "hex":"#64d664",
      "file_location":"assets/colors_organized/hue_11/brightness_14/2020040415265300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":14,
      "vividness":9,
      "r":85,
      "g":214,
      "b":85,
      "hex":"#55d655",
      "file_location":"assets/colors_organized/hue_11/brightness_14/2020040415265301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":14,
      "vividness":10,
      "r":71,
      "g":214,
      "b":70,
      "hex":"#47d646",
      "file_location":"assets/colors_organized/hue_11/brightness_14/2020040415265400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":14,
      "vividness":11,
      "r":56,
      "g":214,
      "b":57,
      "hex":"#38d639",
      "file_location":"assets/colors_organized/hue_11/brightness_14/2020040415265401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":14,
      "vividness":12,
      "r":41,
      "g":215,
      "b":42,
      "hex":"#29d72a",
      "file_location":"assets/colors_organized/hue_11/brightness_14/2020040415265500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":14,
      "vividness":13,
      "r":28,
      "g":214,
      "b":27,
      "hex":"#1cd61b",
      "file_location":"assets/colors_organized/hue_11/brightness_14/2020040415265600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":14,
      "vividness":14,
      "r":12,
      "g":215,
      "b":12,
      "hex":"#0cd70c",
      "file_location":"assets/colors_organized/hue_11/brightness_14/2020040415265601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":15,
      "vividness":1,
      "r":215,
      "g":229,
      "b":214,
      "hex":"#d7e5d6",
      "file_location":"assets/colors_organized/hue_11/brightness_15/2020040415263801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":15,
      "vividness":2,
      "r":199,
      "g":230,
      "b":199,
      "hex":"#c7e6c7",
      "file_location":"assets/colors_organized/hue_11/brightness_15/2020040415263900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":15,
      "vividness":3,
      "r":182,
      "g":229,
      "b":183,
      "hex":"#b6e5b7",
      "file_location":"assets/colors_organized/hue_11/brightness_15/2020040415263901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":15,
      "vividness":4,
      "r":168,
      "g":229,
      "b":169,
      "hex":"#a8e5a9",
      "file_location":"assets/colors_organized/hue_11/brightness_15/2020040415264000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":15,
      "vividness":5,
      "r":153,
      "g":230,
      "b":152,
      "hex":"#99e698",
      "file_location":"assets/colors_organized/hue_11/brightness_15/2020040415264001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":15,
      "vividness":6,
      "r":136,
      "g":230,
      "b":136,
      "hex":"#88e688",
      "file_location":"assets/colors_organized/hue_11/brightness_15/2020040415264100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":15,
      "vividness":7,
      "r":122,
      "g":230,
      "b":121,
      "hex":"#7ae679",
      "file_location":"assets/colors_organized/hue_11/brightness_15/2020040415264101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":15,
      "vividness":8,
      "r":107,
      "g":230,
      "b":106,
      "hex":"#6be66a",
      "file_location":"assets/colors_organized/hue_11/brightness_15/2020040415264200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":15,
      "vividness":9,
      "r":91,
      "g":229,
      "b":90,
      "hex":"#5be55a",
      "file_location":"assets/colors_organized/hue_11/brightness_15/2020040415264201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":15,
      "vividness":10,
      "r":76,
      "g":229,
      "b":76,
      "hex":"#4ce54c",
      "file_location":"assets/colors_organized/hue_11/brightness_15/2020040415264300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":15,
      "vividness":11,
      "r":60,
      "g":230,
      "b":61,
      "hex":"#3ce63d",
      "file_location":"assets/colors_organized/hue_11/brightness_15/2020040415264400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":15,
      "vividness":12,
      "r":44,
      "g":230,
      "b":45,
      "hex":"#2ce62d",
      "file_location":"assets/colors_organized/hue_11/brightness_15/2020040415264401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":15,
      "vividness":13,
      "r":29,
      "g":230,
      "b":30,
      "hex":"#1de61e",
      "file_location":"assets/colors_organized/hue_11/brightness_15/2020040415264500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":11,
      "brightness":15,
      "vividness":14,
      "r":15,
      "g":230,
      "b":15,
      "hex":"#0fe60f",
      "file_location":"assets/colors_organized/hue_11/brightness_15/2020040415264501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":1,
      "vividness":1,
      "r":15,
      "g":19,
      "b":18,
      "hex":"#0f1312",
      "file_location":"assets/colors_organized/hue_12/brightness_01/2020040415330801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":1,
      "vividness":2,
      "r":13,
      "g":19,
      "b":15,
      "hex":"#0d130f",
      "file_location":"assets/colors_organized/hue_12/brightness_01/2020040415330900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":1,
      "vividness":3,
      "r":9,
      "g":20,
      "b":12,
      "hex":"#09140c",
      "file_location":"assets/colors_organized/hue_12/brightness_01/2020040415331001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":1,
      "vividness":4,
      "r":6,
      "g":19,
      "b":9,
      "hex":"#061309",
      "file_location":"assets/colors_organized/hue_12/brightness_01/2020040415331101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":1,
      "vividness":5,
      "r":2,
      "g":20,
      "b":6,
      "hex":"#021406",
      "file_location":"assets/colors_organized/hue_12/brightness_01/2020040415331202-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":1,
      "vividness":6,
      "r":0,
      "g":19,
      "b":7,
      "hex":"#001307",
      "file_location":"assets/colors_organized/hue_12/brightness_01/2020040415331301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":2,
      "vividness":1,
      "r":31,
      "g":35,
      "b":34,
      "hex":"#1f2322",
      "file_location":"assets/colors_organized/hue_12/brightness_02/2020040415325601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":2,
      "vividness":2,
      "r":30,
      "g":35,
      "b":31,
      "hex":"#1e231f",
      "file_location":"assets/colors_organized/hue_12/brightness_02/2020040415325602-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":2,
      "vividness":3,
      "r":28,
      "g":34,
      "b":30,
      "hex":"#1c221e",
      "file_location":"assets/colors_organized/hue_12/brightness_02/2020040415325700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":2,
      "vividness":4,
      "r":25,
      "g":35,
      "b":27,
      "hex":"#19231b",
      "file_location":"assets/colors_organized/hue_12/brightness_02/2020040415325701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":2,
      "vividness":5,
      "r":23,
      "g":35,
      "b":25,
      "hex":"#172319",
      "file_location":"assets/colors_organized/hue_12/brightness_02/2020040415325800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":2,
      "vividness":6,
      "r":17,
      "g":35,
      "b":21,
      "hex":"#112315",
      "file_location":"assets/colors_organized/hue_12/brightness_02/2020040415325900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":2,
      "vividness":7,
      "r":14,
      "g":35,
      "b":20,
      "hex":"#0e2314",
      "file_location":"assets/colors_organized/hue_12/brightness_02/2020040415325901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":2,
      "vividness":8,
      "r":12,
      "g":35,
      "b":17,
      "hex":"#0c2311",
      "file_location":"assets/colors_organized/hue_12/brightness_02/2020040415330000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":2,
      "vividness":9,
      "r":10,
      "g":35,
      "b":14,
      "hex":"#0a230e",
      "file_location":"assets/colors_organized/hue_12/brightness_02/2020040415330001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":2,
      "vividness":10,
      "r":10,
      "g":35,
      "b":13,
      "hex":"#0a230d",
      "file_location":"assets/colors_organized/hue_12/brightness_02/2020040415330100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":2,
      "vividness":11,
      "r":7,
      "g":35,
      "b":13,
      "hex":"#07230d",
      "file_location":"assets/colors_organized/hue_12/brightness_02/2020040415330101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":2,
      "vividness":12,
      "r":2,
      "g":34,
      "b":10,
      "hex":"#02220a",
      "file_location":"assets/colors_organized/hue_12/brightness_02/2020040415330200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":2,
      "vividness":13,
      "r":2,
      "g":35,
      "b":6,
      "hex":"#022306",
      "file_location":"assets/colors_organized/hue_12/brightness_02/2020040415330201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":3,
      "vividness":1,
      "r":40,
      "g":50,
      "b":42,
      "hex":"#28322a",
      "file_location":"assets/colors_organized/hue_12/brightness_03/2020040415324600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":3,
      "vividness":2,
      "r":36,
      "g":49,
      "b":39,
      "hex":"#243127",
      "file_location":"assets/colors_organized/hue_12/brightness_03/2020040415324601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":3,
      "vividness":3,
      "r":33,
      "g":49,
      "b":36,
      "hex":"#213124",
      "file_location":"assets/colors_organized/hue_12/brightness_03/2020040415324700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":3,
      "vividness":4,
      "r":29,
      "g":50,
      "b":33,
      "hex":"#1d3221",
      "file_location":"assets/colors_organized/hue_12/brightness_03/2020040415324701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":3,
      "vividness":5,
      "r":26,
      "g":49,
      "b":29,
      "hex":"#1a311d",
      "file_location":"assets/colors_organized/hue_12/brightness_03/2020040415324800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":3,
      "vividness":6,
      "r":22,
      "g":50,
      "b":27,
      "hex":"#16321b",
      "file_location":"assets/colors_organized/hue_12/brightness_03/2020040415324801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":3,
      "vividness":7,
      "r":20,
      "g":50,
      "b":26,
      "hex":"#14321a",
      "file_location":"assets/colors_organized/hue_12/brightness_03/2020040415324900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":3,
      "vividness":8,
      "r":15,
      "g":49,
      "b":22,
      "hex":"#0f3116",
      "file_location":"assets/colors_organized/hue_12/brightness_03/2020040415324901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":3,
      "vividness":9,
      "r":13,
      "g":50,
      "b":19,
      "hex":"#0d3213",
      "file_location":"assets/colors_organized/hue_12/brightness_03/2020040415325000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":3,
      "vividness":10,
      "r":9,
      "g":50,
      "b":18,
      "hex":"#093212",
      "file_location":"assets/colors_organized/hue_12/brightness_03/2020040415325001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":3,
      "vividness":11,
      "r":6,
      "g":50,
      "b":15,
      "hex":"#06320f",
      "file_location":"assets/colors_organized/hue_12/brightness_03/2020040415325100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":3,
      "vividness":12,
      "r":2,
      "g":50,
      "b":12,
      "hex":"#02320c",
      "file_location":"assets/colors_organized/hue_12/brightness_03/2020040415325101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":4,
      "vividness":1,
      "r":60,
      "g":65,
      "b":61,
      "hex":"#3c413d",
      "file_location":"assets/colors_organized/hue_12/brightness_04/2020040415323400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":4,
      "vividness":2,
      "r":55,
      "g":65,
      "b":57,
      "hex":"#374139",
      "file_location":"assets/colors_organized/hue_12/brightness_04/2020040415323401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":4,
      "vividness":3,
      "r":52,
      "g":66,
      "b":53,
      "hex":"#344235",
      "file_location":"assets/colors_organized/hue_12/brightness_04/2020040415323500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":4,
      "vividness":4,
      "r":47,
      "g":65,
      "b":51,
      "hex":"#2f4133",
      "file_location":"assets/colors_organized/hue_12/brightness_04/2020040415323501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":4,
      "vividness":5,
      "r":42,
      "g":65,
      "b":45,
      "hex":"#2a412d",
      "file_location":"assets/colors_organized/hue_12/brightness_04/2020040415323600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":4,
      "vividness":6,
      "r":38,
      "g":66,
      "b":44,
      "hex":"#26422c",
      "file_location":"assets/colors_organized/hue_12/brightness_04/2020040415323601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":4,
      "vividness":7,
      "r":33,
      "g":65,
      "b":41,
      "hex":"#214129",
      "file_location":"assets/colors_organized/hue_12/brightness_04/2020040415323700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":4,
      "vividness":8,
      "r":30,
      "g":64,
      "b":37,
      "hex":"#1e4025",
      "file_location":"assets/colors_organized/hue_12/brightness_04/2020040415323800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":4,
      "vividness":9,
      "r":26,
      "g":65,
      "b":34,
      "hex":"#1a4122",
      "file_location":"assets/colors_organized/hue_12/brightness_04/2020040415323801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":4,
      "vividness":10,
      "r":21,
      "g":65,
      "b":30,
      "hex":"#15411e",
      "file_location":"assets/colors_organized/hue_12/brightness_04/2020040415323900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":4,
      "vividness":11,
      "r":17,
      "g":65,
      "b":27,
      "hex":"#11411b",
      "file_location":"assets/colors_organized/hue_12/brightness_04/2020040415323901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":4,
      "vividness":12,
      "r":12,
      "g":65,
      "b":23,
      "hex":"#0c4117",
      "file_location":"assets/colors_organized/hue_12/brightness_04/2020040415324000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":4,
      "vividness":13,
      "r":6,
      "g":65,
      "b":19,
      "hex":"#064113",
      "file_location":"assets/colors_organized/hue_12/brightness_04/2020040415324001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":4,
      "vividness":14,
      "r":2,
      "g":64,
      "b":13,
      "hex":"#02400d",
      "file_location":"assets/colors_organized/hue_12/brightness_04/2020040415324100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":5,
      "vividness":1,
      "r":69,
      "g":81,
      "b":71,
      "hex":"#455147",
      "file_location":"assets/colors_organized/hue_12/brightness_05/2020040415322301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":5,
      "vividness":2,
      "r":64,
      "g":80,
      "b":67,
      "hex":"#405043",
      "file_location":"assets/colors_organized/hue_12/brightness_05/2020040415322400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":5,
      "vividness":3,
      "r":57,
      "g":80,
      "b":62,
      "hex":"#39503e",
      "file_location":"assets/colors_organized/hue_12/brightness_05/2020040415322401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":5,
      "vividness":4,
      "r":52,
      "g":80,
      "b":57,
      "hex":"#345039",
      "file_location":"assets/colors_organized/hue_12/brightness_05/2020040415322500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":5,
      "vividness":5,
      "r":47,
      "g":80,
      "b":53,
      "hex":"#2f5035",
      "file_location":"assets/colors_organized/hue_12/brightness_05/2020040415322600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":5,
      "vividness":6,
      "r":41,
      "g":80,
      "b":49,
      "hex":"#295031",
      "file_location":"assets/colors_organized/hue_12/brightness_05/2020040415322601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":5,
      "vividness":7,
      "r":36,
      "g":80,
      "b":45,
      "hex":"#24502d",
      "file_location":"assets/colors_organized/hue_12/brightness_05/2020040415322700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":5,
      "vividness":8,
      "r":32,
      "g":80,
      "b":40,
      "hex":"#205028",
      "file_location":"assets/colors_organized/hue_12/brightness_05/2020040415322701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":5,
      "vividness":9,
      "r":27,
      "g":80,
      "b":36,
      "hex":"#1b5024",
      "file_location":"assets/colors_organized/hue_12/brightness_05/2020040415322800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":5,
      "vividness":10,
      "r":21,
      "g":80,
      "b":34,
      "hex":"#155022",
      "file_location":"assets/colors_organized/hue_12/brightness_05/2020040415322801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":5,
      "vividness":11,
      "r":13,
      "g":80,
      "b":27,
      "hex":"#0d501b",
      "file_location":"assets/colors_organized/hue_12/brightness_05/2020040415322900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":5,
      "vividness":12,
      "r":9,
      "g":80,
      "b":22,
      "hex":"#095016",
      "file_location":"assets/colors_organized/hue_12/brightness_05/2020040415322901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":5,
      "vividness":13,
      "r":6,
      "g":80,
      "b":19,
      "hex":"#065013",
      "file_location":"assets/colors_organized/hue_12/brightness_05/2020040415323000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":6,
      "vividness":1,
      "r":88,
      "g":94,
      "b":90,
      "hex":"#585e5a",
      "file_location":"assets/colors_organized/hue_12/brightness_06/2020040415321200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":6,
      "vividness":2,
      "r":82,
      "g":95,
      "b":85,
      "hex":"#525f55",
      "file_location":"assets/colors_organized/hue_12/brightness_06/2020040415321201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":6,
      "vividness":3,
      "r":76,
      "g":94,
      "b":78,
      "hex":"#4c5e4e",
      "file_location":"assets/colors_organized/hue_12/brightness_06/2020040415321300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":6,
      "vividness":4,
      "r":70,
      "g":95,
      "b":74,
      "hex":"#465f4a",
      "file_location":"assets/colors_organized/hue_12/brightness_06/2020040415321301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":6,
      "vividness":5,
      "r":62,
      "g":95,
      "b":68,
      "hex":"#3e5f44",
      "file_location":"assets/colors_organized/hue_12/brightness_06/2020040415321400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":6,
      "vividness":6,
      "r":56,
      "g":95,
      "b":64,
      "hex":"#385f40",
      "file_location":"assets/colors_organized/hue_12/brightness_06/2020040415321401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":6,
      "vividness":7,
      "r":49,
      "g":95,
      "b":58,
      "hex":"#315f3a",
      "file_location":"assets/colors_organized/hue_12/brightness_06/2020040415321500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":6,
      "vividness":8,
      "r":44,
      "g":95,
      "b":54,
      "hex":"#2c5f36",
      "file_location":"assets/colors_organized/hue_12/brightness_06/2020040415321501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":6,
      "vividness":9,
      "r":37,
      "g":95,
      "b":47,
      "hex":"#255f2f",
      "file_location":"assets/colors_organized/hue_12/brightness_06/2020040415321600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":6,
      "vividness":10,
      "r":31,
      "g":95,
      "b":43,
      "hex":"#1f5f2b",
      "file_location":"assets/colors_organized/hue_12/brightness_06/2020040415321601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":6,
      "vividness":11,
      "r":24,
      "g":95,
      "b":39,
      "hex":"#185f27",
      "file_location":"assets/colors_organized/hue_12/brightness_06/2020040415321700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":6,
      "vividness":12,
      "r":19,
      "g":95,
      "b":33,
      "hex":"#135f21",
      "file_location":"assets/colors_organized/hue_12/brightness_06/2020040415321800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":6,
      "vividness":13,
      "r":13,
      "g":95,
      "b":29,
      "hex":"#0d5f1d",
      "file_location":"assets/colors_organized/hue_12/brightness_06/2020040415321801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":6,
      "vividness":14,
      "r":7,
      "g":94,
      "b":23,
      "hex":"#075e17",
      "file_location":"assets/colors_organized/hue_12/brightness_06/2020040415321900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":7,
      "vividness":1,
      "r":101,
      "g":111,
      "b":103,
      "hex":"#656f67",
      "file_location":"assets/colors_organized/hue_12/brightness_07/2020040415320100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":7,
      "vividness":2,
      "r":94,
      "g":110,
      "b":97,
      "hex":"#5e6e61",
      "file_location":"assets/colors_organized/hue_12/brightness_07/2020040415320101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":7,
      "vividness":3,
      "r":87,
      "g":110,
      "b":92,
      "hex":"#576e5c",
      "file_location":"assets/colors_organized/hue_12/brightness_07/2020040415320200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":7,
      "vividness":4,
      "r":80,
      "g":110,
      "b":86,
      "hex":"#506e56",
      "file_location":"assets/colors_organized/hue_12/brightness_07/2020040415320201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":7,
      "vividness":5,
      "r":73,
      "g":110,
      "b":79,
      "hex":"#496e4f",
      "file_location":"assets/colors_organized/hue_12/brightness_07/2020040415320300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":7,
      "vividness":6,
      "r":66,
      "g":110,
      "b":74,
      "hex":"#426e4a",
      "file_location":"assets/colors_organized/hue_12/brightness_07/2020040415320301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":7,
      "vividness":7,
      "r":57,
      "g":110,
      "b":68,
      "hex":"#396e44",
      "file_location":"assets/colors_organized/hue_12/brightness_07/2020040415320400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":7,
      "vividness":8,
      "r":51,
      "g":110,
      "b":62,
      "hex":"#336e3e",
      "file_location":"assets/colors_organized/hue_12/brightness_07/2020040415320401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":7,
      "vividness":9,
      "r":43,
      "g":110,
      "b":56,
      "hex":"#2b6e38",
      "file_location":"assets/colors_organized/hue_12/brightness_07/2020040415320500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":7,
      "vividness":10,
      "r":36,
      "g":110,
      "b":51,
      "hex":"#246e33",
      "file_location":"assets/colors_organized/hue_12/brightness_07/2020040415320501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":7,
      "vividness":11,
      "r":29,
      "g":110,
      "b":44,
      "hex":"#1d6e2c",
      "file_location":"assets/colors_organized/hue_12/brightness_07/2020040415320600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":7,
      "vividness":12,
      "r":22,
      "g":109,
      "b":38,
      "hex":"#166d26",
      "file_location":"assets/colors_organized/hue_12/brightness_07/2020040415320601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":7,
      "vividness":13,
      "r":12,
      "g":110,
      "b":33,
      "hex":"#0c6e21",
      "file_location":"assets/colors_organized/hue_12/brightness_07/2020040415320700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":7,
      "vividness":14,
      "r":6,
      "g":109,
      "b":26,
      "hex":"#066d1a",
      "file_location":"assets/colors_organized/hue_12/brightness_07/2020040415320701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":8,
      "vividness":1,
      "r":115,
      "g":125,
      "b":117,
      "hex":"#737d75",
      "file_location":"assets/colors_organized/hue_12/brightness_08/2020040415314901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":8,
      "vividness":2,
      "r":108,
      "g":124,
      "b":111,
      "hex":"#6c7c6f",
      "file_location":"assets/colors_organized/hue_12/brightness_08/2020040415315000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":8,
      "vividness":3,
      "r":99,
      "g":124,
      "b":103,
      "hex":"#637c67",
      "file_location":"assets/colors_organized/hue_12/brightness_08/2020040415315001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":8,
      "vividness":4,
      "r":90,
      "g":124,
      "b":97,
      "hex":"#5a7c61",
      "file_location":"assets/colors_organized/hue_12/brightness_08/2020040415315100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":8,
      "vividness":5,
      "r":83,
      "g":124,
      "b":90,
      "hex":"#537c5a",
      "file_location":"assets/colors_organized/hue_12/brightness_08/2020040415315200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":8,
      "vividness":6,
      "r":73,
      "g":124,
      "b":83,
      "hex":"#497c53",
      "file_location":"assets/colors_organized/hue_12/brightness_08/2020040415315201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":8,
      "vividness":7,
      "r":66,
      "g":123,
      "b":78,
      "hex":"#427b4e",
      "file_location":"assets/colors_organized/hue_12/brightness_08/2020040415315300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":8,
      "vividness":8,
      "r":57,
      "g":124,
      "b":71,
      "hex":"#397c47",
      "file_location":"assets/colors_organized/hue_12/brightness_08/2020040415315301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":8,
      "vividness":9,
      "r":48,
      "g":123,
      "b":64,
      "hex":"#307b40",
      "file_location":"assets/colors_organized/hue_12/brightness_08/2020040415315400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":8,
      "vividness":10,
      "r":41,
      "g":123,
      "b":57,
      "hex":"#297b39",
      "file_location":"assets/colors_organized/hue_12/brightness_08/2020040415315401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":8,
      "vividness":11,
      "r":33,
      "g":123,
      "b":49,
      "hex":"#217b31",
      "file_location":"assets/colors_organized/hue_12/brightness_08/2020040415315500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":8,
      "vividness":12,
      "r":22,
      "g":123,
      "b":43,
      "hex":"#167b2b",
      "file_location":"assets/colors_organized/hue_12/brightness_08/2020040415315501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":8,
      "vividness":13,
      "r":14,
      "g":124,
      "b":37,
      "hex":"#0e7c25",
      "file_location":"assets/colors_organized/hue_12/brightness_08/2020040415315600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":8,
      "vividness":14,
      "r":6,
      "g":124,
      "b":30,
      "hex":"#067c1e",
      "file_location":"assets/colors_organized/hue_12/brightness_08/2020040415315601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":9,
      "vividness":1,
      "r":130,
      "g":140,
      "b":132,
      "hex":"#828c84",
      "file_location":"assets/colors_organized/hue_12/brightness_09/2020040415313900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":9,
      "vividness":2,
      "r":121,
      "g":139,
      "b":123,
      "hex":"#798b7b",
      "file_location":"assets/colors_organized/hue_12/brightness_09/2020040415313901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":9,
      "vividness":3,
      "r":111,
      "g":139,
      "b":117,
      "hex":"#6f8b75",
      "file_location":"assets/colors_organized/hue_12/brightness_09/2020040415313902-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":9,
      "vividness":4,
      "r":103,
      "g":139,
      "b":111,
      "hex":"#678b6f",
      "file_location":"assets/colors_organized/hue_12/brightness_09/2020040415314000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":9,
      "vividness":5,
      "r":93,
      "g":139,
      "b":102,
      "hex":"#5d8b66",
      "file_location":"assets/colors_organized/hue_12/brightness_09/2020040415314001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":9,
      "vividness":6,
      "r":82,
      "g":139,
      "b":94,
      "hex":"#528b5e",
      "file_location":"assets/colors_organized/hue_12/brightness_09/2020040415314100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":9,
      "vividness":7,
      "r":73,
      "g":140,
      "b":87,
      "hex":"#498c57",
      "file_location":"assets/colors_organized/hue_12/brightness_09/2020040415314200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":9,
      "vividness":8,
      "r":65,
      "g":139,
      "b":80,
      "hex":"#418b50",
      "file_location":"assets/colors_organized/hue_12/brightness_09/2020040415314201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":9,
      "vividness":9,
      "r":54,
      "g":139,
      "b":71,
      "hex":"#368b47",
      "file_location":"assets/colors_organized/hue_12/brightness_09/2020040415314202-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":9,
      "vividness":10,
      "r":46,
      "g":140,
      "b":66,
      "hex":"#2e8c42",
      "file_location":"assets/colors_organized/hue_12/brightness_09/2020040415314300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":9,
      "vividness":11,
      "r":36,
      "g":139,
      "b":56,
      "hex":"#248b38",
      "file_location":"assets/colors_organized/hue_12/brightness_09/2020040415314301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":9,
      "vividness":12,
      "r":26,
      "g":139,
      "b":49,
      "hex":"#1a8b31",
      "file_location":"assets/colors_organized/hue_12/brightness_09/2020040415314400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":9,
      "vividness":13,
      "r":18,
      "g":139,
      "b":42,
      "hex":"#128b2a",
      "file_location":"assets/colors_organized/hue_12/brightness_09/2020040415314500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":9,
      "vividness":14,
      "r":9,
      "g":139,
      "b":33,
      "hex":"#098b21",
      "file_location":"assets/colors_organized/hue_12/brightness_09/2020040415314501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":10,
      "vividness":1,
      "r":144,
      "g":154,
      "b":146,
      "hex":"#909a92",
      "file_location":"assets/colors_organized/hue_12/brightness_10/2020040415312800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":10,
      "vividness":2,
      "r":133,
      "g":154,
      "b":137,
      "hex":"#859a89",
      "file_location":"assets/colors_organized/hue_12/brightness_10/2020040415312801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":10,
      "vividness":3,
      "r":122,
      "g":154,
      "b":130,
      "hex":"#7a9a82",
      "file_location":"assets/colors_organized/hue_12/brightness_10/2020040415312900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":10,
      "vividness":4,
      "r":113,
      "g":154,
      "b":120,
      "hex":"#719a78",
      "file_location":"assets/colors_organized/hue_12/brightness_10/2020040415312901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":10,
      "vividness":5,
      "r":103,
      "g":154,
      "b":113,
      "hex":"#679a71",
      "file_location":"assets/colors_organized/hue_12/brightness_10/2020040415313000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":10,
      "vividness":6,
      "r":92,
      "g":154,
      "b":105,
      "hex":"#5c9a69",
      "file_location":"assets/colors_organized/hue_12/brightness_10/2020040415313001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":10,
      "vividness":7,
      "r":81,
      "g":155,
      "b":96,
      "hex":"#519b60",
      "file_location":"assets/colors_organized/hue_12/brightness_10/2020040415313100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":10,
      "vividness":8,
      "r":72,
      "g":154,
      "b":88,
      "hex":"#489a58",
      "file_location":"assets/colors_organized/hue_12/brightness_10/2020040415313101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":10,
      "vividness":9,
      "r":61,
      "g":155,
      "b":81,
      "hex":"#3d9b51",
      "file_location":"assets/colors_organized/hue_12/brightness_10/2020040415313200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":10,
      "vividness":10,
      "r":51,
      "g":154,
      "b":71,
      "hex":"#339a47",
      "file_location":"assets/colors_organized/hue_12/brightness_10/2020040415313201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":10,
      "vividness":11,
      "r":41,
      "g":154,
      "b":62,
      "hex":"#299a3e",
      "file_location":"assets/colors_organized/hue_12/brightness_10/2020040415313300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":10,
      "vividness":12,
      "r":30,
      "g":155,
      "b":55,
      "hex":"#1e9b37",
      "file_location":"assets/colors_organized/hue_12/brightness_10/2020040415313301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":10,
      "vividness":13,
      "r":19,
      "g":155,
      "b":47,
      "hex":"#139b2f",
      "file_location":"assets/colors_organized/hue_12/brightness_10/2020040415313400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":10,
      "vividness":14,
      "r":10,
      "g":155,
      "b":38,
      "hex":"#0a9b26",
      "file_location":"assets/colors_organized/hue_12/brightness_10/2020040415313401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":11,
      "vividness":1,
      "r":158,
      "g":170,
      "b":160,
      "hex":"#9eaaa0",
      "file_location":"assets/colors_organized/hue_12/brightness_11/2020040415311701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":11,
      "vividness":2,
      "r":147,
      "g":170,
      "b":152,
      "hex":"#93aa98",
      "file_location":"assets/colors_organized/hue_12/brightness_11/2020040415311800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":11,
      "vividness":3,
      "r":135,
      "g":169,
      "b":142,
      "hex":"#87a98e",
      "file_location":"assets/colors_organized/hue_12/brightness_11/2020040415311801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":11,
      "vividness":4,
      "r":124,
      "g":170,
      "b":134,
      "hex":"#7caa86",
      "file_location":"assets/colors_organized/hue_12/brightness_11/2020040415311900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":11,
      "vividness":5,
      "r":112,
      "g":169,
      "b":124,
      "hex":"#70a97c",
      "file_location":"assets/colors_organized/hue_12/brightness_11/2020040415311901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":11,
      "vividness":6,
      "r":101,
      "g":170,
      "b":115,
      "hex":"#65aa73",
      "file_location":"assets/colors_organized/hue_12/brightness_11/2020040415312000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":11,
      "vividness":7,
      "r":89,
      "g":169,
      "b":106,
      "hex":"#59a96a",
      "file_location":"assets/colors_organized/hue_12/brightness_11/2020040415312001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":11,
      "vividness":8,
      "r":79,
      "g":169,
      "b":97,
      "hex":"#4fa961",
      "file_location":"assets/colors_organized/hue_12/brightness_11/2020040415312100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":11,
      "vividness":9,
      "r":66,
      "g":169,
      "b":86,
      "hex":"#42a956",
      "file_location":"assets/colors_organized/hue_12/brightness_11/2020040415312101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":11,
      "vividness":10,
      "r":55,
      "g":170,
      "b":79,
      "hex":"#37aa4f",
      "file_location":"assets/colors_organized/hue_12/brightness_11/2020040415312102-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":11,
      "vividness":11,
      "r":45,
      "g":170,
      "b":70,
      "hex":"#2daa46",
      "file_location":"assets/colors_organized/hue_12/brightness_11/2020040415312200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":11,
      "vividness":12,
      "r":33,
      "g":169,
      "b":59,
      "hex":"#21a93b",
      "file_location":"assets/colors_organized/hue_12/brightness_11/2020040415312300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":11,
      "vividness":13,
      "r":22,
      "g":169,
      "b":52,
      "hex":"#16a934",
      "file_location":"assets/colors_organized/hue_12/brightness_11/2020040415312301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":11,
      "vividness":14,
      "r":9,
      "g":169,
      "b":43,
      "hex":"#09a92b",
      "file_location":"assets/colors_organized/hue_12/brightness_11/2020040415312400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":12,
      "vividness":1,
      "r":172,
      "g":184,
      "b":174,
      "hex":"#acb8ae",
      "file_location":"assets/colors_organized/hue_12/brightness_12/2020040415310401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":12,
      "vividness":2,
      "r":160,
      "g":185,
      "b":164,
      "hex":"#a0b9a4",
      "file_location":"assets/colors_organized/hue_12/brightness_12/2020040415310402-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":12,
      "vividness":3,
      "r":148,
      "g":184,
      "b":156,
      "hex":"#94b89c",
      "file_location":"assets/colors_organized/hue_12/brightness_12/2020040415310500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":12,
      "vividness":4,
      "r":136,
      "g":184,
      "b":146,
      "hex":"#88b892",
      "file_location":"assets/colors_organized/hue_12/brightness_12/2020040415310501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":12,
      "vividness":5,
      "r":122,
      "g":184,
      "b":135,
      "hex":"#7ab887",
      "file_location":"assets/colors_organized/hue_12/brightness_12/2020040415310600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":12,
      "vividness":6,
      "r":110,
      "g":184,
      "b":125,
      "hex":"#6eb87d",
      "file_location":"assets/colors_organized/hue_12/brightness_12/2020040415310601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":12,
      "vividness":7,
      "r":97,
      "g":184,
      "b":115,
      "hex":"#61b873",
      "file_location":"assets/colors_organized/hue_12/brightness_12/2020040415310700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":12,
      "vividness":8,
      "r":86,
      "g":185,
      "b":105,
      "hex":"#56b969",
      "file_location":"assets/colors_organized/hue_12/brightness_12/2020040415310701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":12,
      "vividness":9,
      "r":72,
      "g":185,
      "b":97,
      "hex":"#48b961",
      "file_location":"assets/colors_organized/hue_12/brightness_12/2020040415310800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":12,
      "vividness":10,
      "r":60,
      "g":184,
      "b":86,
      "hex":"#3cb856",
      "file_location":"assets/colors_organized/hue_12/brightness_12/2020040415310801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":12,
      "vividness":11,
      "r":48,
      "g":184,
      "b":76,
      "hex":"#30b84c",
      "file_location":"assets/colors_organized/hue_12/brightness_12/2020040415310900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":12,
      "vividness":12,
      "r":35,
      "g":184,
      "b":66,
      "hex":"#23b842",
      "file_location":"assets/colors_organized/hue_12/brightness_12/2020040415311000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":12,
      "vividness":13,
      "r":23,
      "g":184,
      "b":55,
      "hex":"#17b837",
      "file_location":"assets/colors_organized/hue_12/brightness_12/2020040415311001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":12,
      "vividness":14,
      "r":12,
      "g":184,
      "b":46,
      "hex":"#0cb82e",
      "file_location":"assets/colors_organized/hue_12/brightness_12/2020040415311100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":13,
      "vividness":1,
      "r":186,
      "g":200,
      "b":187,
      "hex":"#bac8bb",
      "file_location":"assets/colors_organized/hue_12/brightness_13/2020040415305301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":13,
      "vividness":2,
      "r":172,
      "g":200,
      "b":178,
      "hex":"#acc8b2",
      "file_location":"assets/colors_organized/hue_12/brightness_13/2020040415305400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":13,
      "vividness":3,
      "r":159,
      "g":200,
      "b":168,
      "hex":"#9fc8a8",
      "file_location":"assets/colors_organized/hue_12/brightness_13/2020040415305401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":13,
      "vividness":4,
      "r":147,
      "g":200,
      "b":158,
      "hex":"#93c89e",
      "file_location":"assets/colors_organized/hue_12/brightness_13/2020040415305500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":13,
      "vividness":5,
      "r":133,
      "g":200,
      "b":146,
      "hex":"#85c892",
      "file_location":"assets/colors_organized/hue_12/brightness_13/2020040415305501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":13,
      "vividness":6,
      "r":119,
      "g":200,
      "b":134,
      "hex":"#77c886",
      "file_location":"assets/colors_organized/hue_12/brightness_13/2020040415305600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":13,
      "vividness":7,
      "r":106,
      "g":200,
      "b":124,
      "hex":"#6ac87c",
      "file_location":"assets/colors_organized/hue_12/brightness_13/2020040415305601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":13,
      "vividness":8,
      "r":94,
      "g":199,
      "b":114,
      "hex":"#5ec772",
      "file_location":"assets/colors_organized/hue_12/brightness_13/2020040415305700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":13,
      "vividness":9,
      "r":78,
      "g":199,
      "b":102,
      "hex":"#4ec766",
      "file_location":"assets/colors_organized/hue_12/brightness_13/2020040415305701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":13,
      "vividness":10,
      "r":66,
      "g":199,
      "b":92,
      "hex":"#42c75c",
      "file_location":"assets/colors_organized/hue_12/brightness_13/2020040415305800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":13,
      "vividness":11,
      "r":52,
      "g":199,
      "b":82,
      "hex":"#34c752",
      "file_location":"assets/colors_organized/hue_12/brightness_13/2020040415305801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":13,
      "vividness":12,
      "r":39,
      "g":200,
      "b":71,
      "hex":"#27c847",
      "file_location":"assets/colors_organized/hue_12/brightness_13/2020040415305900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":13,
      "vividness":13,
      "r":27,
      "g":199,
      "b":61,
      "hex":"#1bc73d",
      "file_location":"assets/colors_organized/hue_12/brightness_13/2020040415305901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":13,
      "vividness":14,
      "r":12,
      "g":199,
      "b":48,
      "hex":"#0cc730",
      "file_location":"assets/colors_organized/hue_12/brightness_13/2020040415310000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":14,
      "vividness":1,
      "r":201,
      "g":214,
      "b":204,
      "hex":"#c9d6cc",
      "file_location":"assets/colors_organized/hue_12/brightness_14/2020040415304300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":14,
      "vividness":2,
      "r":185,
      "g":215,
      "b":191,
      "hex":"#b9d7bf",
      "file_location":"assets/colors_organized/hue_12/brightness_14/2020040415304301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":14,
      "vividness":3,
      "r":171,
      "g":215,
      "b":180,
      "hex":"#abd7b4",
      "file_location":"assets/colors_organized/hue_12/brightness_14/2020040415304400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":14,
      "vividness":4,
      "r":157,
      "g":215,
      "b":167,
      "hex":"#9dd7a7",
      "file_location":"assets/colors_organized/hue_12/brightness_14/2020040415304401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":14,
      "vividness":5,
      "r":144,
      "g":215,
      "b":157,
      "hex":"#90d79d",
      "file_location":"assets/colors_organized/hue_12/brightness_14/2020040415304500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":14,
      "vividness":6,
      "r":128,
      "g":215,
      "b":146,
      "hex":"#80d792",
      "file_location":"assets/colors_organized/hue_12/brightness_14/2020040415304501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":14,
      "vividness":7,
      "r":113,
      "g":214,
      "b":134,
      "hex":"#71d686",
      "file_location":"assets/colors_organized/hue_12/brightness_14/2020040415304600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":14,
      "vividness":8,
      "r":100,
      "g":215,
      "b":122,
      "hex":"#64d77a",
      "file_location":"assets/colors_organized/hue_12/brightness_14/2020040415304601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":14,
      "vividness":9,
      "r":84,
      "g":215,
      "b":111,
      "hex":"#54d76f",
      "file_location":"assets/colors_organized/hue_12/brightness_14/2020040415304700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":14,
      "vividness":10,
      "r":70,
      "g":214,
      "b":100,
      "hex":"#46d664",
      "file_location":"assets/colors_organized/hue_12/brightness_14/2020040415304701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":14,
      "vividness":11,
      "r":56,
      "g":215,
      "b":87,
      "hex":"#38d757",
      "file_location":"assets/colors_organized/hue_12/brightness_14/2020040415304702-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":14,
      "vividness":12,
      "r":42,
      "g":214,
      "b":76,
      "hex":"#2ad64c",
      "file_location":"assets/colors_organized/hue_12/brightness_14/2020040415304800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":14,
      "vividness":13,
      "r":27,
      "g":214,
      "b":65,
      "hex":"#1bd641",
      "file_location":"assets/colors_organized/hue_12/brightness_14/2020040415304801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":14,
      "vividness":14,
      "r":13,
      "g":215,
      "b":54,
      "hex":"#0dd736",
      "file_location":"assets/colors_organized/hue_12/brightness_14/2020040415304900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":15,
      "vividness":1,
      "r":214,
      "g":230,
      "b":217,
      "hex":"#d6e6d9",
      "file_location":"assets/colors_organized/hue_12/brightness_15/2020040415293601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":15,
      "vividness":2,
      "r":197,
      "g":229,
      "b":205,
      "hex":"#c5e5cd",
      "file_location":"assets/colors_organized/hue_12/brightness_15/2020040415293700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":15,
      "vividness":3,
      "r":183,
      "g":229,
      "b":192,
      "hex":"#b7e5c0",
      "file_location":"assets/colors_organized/hue_12/brightness_15/2020040415293701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":15,
      "vividness":4,
      "r":168,
      "g":230,
      "b":181,
      "hex":"#a8e6b5",
      "file_location":"assets/colors_organized/hue_12/brightness_15/2020040415293800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":15,
      "vividness":5,
      "r":151,
      "g":229,
      "b":167,
      "hex":"#97e5a7",
      "file_location":"assets/colors_organized/hue_12/brightness_15/2020040415293801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":15,
      "vividness":6,
      "r":137,
      "g":229,
      "b":156,
      "hex":"#89e59c",
      "file_location":"assets/colors_organized/hue_12/brightness_15/2020040415293900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":15,
      "vividness":7,
      "r":121,
      "g":229,
      "b":143,
      "hex":"#79e58f",
      "file_location":"assets/colors_organized/hue_12/brightness_15/2020040415293901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":15,
      "vividness":8,
      "r":108,
      "g":230,
      "b":131,
      "hex":"#6ce683",
      "file_location":"assets/colors_organized/hue_12/brightness_15/2020040415294000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":15,
      "vividness":9,
      "r":91,
      "g":229,
      "b":118,
      "hex":"#5be576",
      "file_location":"assets/colors_organized/hue_12/brightness_15/2020040415294001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":15,
      "vividness":10,
      "r":76,
      "g":230,
      "b":106,
      "hex":"#4ce66a",
      "file_location":"assets/colors_organized/hue_12/brightness_15/2020040415294100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":15,
      "vividness":11,
      "r":59,
      "g":229,
      "b":94,
      "hex":"#3be55e",
      "file_location":"assets/colors_organized/hue_12/brightness_15/2020040415302900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":12,
      "brightness":15,
      "vividness":12,
      "r":43,
      "g":230,
      "b":81,
      "hex":"#2be651",
      "file_location":"assets/colors_organized/hue_12/brightness_15/x.jpg"
   },
   {
      "hue":12,
      "brightness":15,
      "vividness":13,
      "r":29,
      "g":229,
      "b":69,
      "hex":"#1de545",
      "file_location":"assets/colors_organized/hue_12/brightness_15/xx.jpg"
   },
   {
      "hue":12,
      "brightness":15,
      "vividness":14,
      "r":14,
      "g":230,
      "b":57,
      "hex":"#0ee639",
      "file_location":"assets/colors_organized/hue_12/brightness_15/xxx.jpg"
   },
   {
      "hue":13,
      "brightness":1,
      "vividness":1,
      "r":13,
      "g":19,
      "b":17,
      "hex":"#0d1311",
      "file_location":"assets/colors_organized/hue_13/brightness_01/2020040415360001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":1,
      "vividness":2,
      "r":7,
      "g":20,
      "b":13,
      "hex":"#07140d",
      "file_location":"assets/colors_organized/hue_13/brightness_01/2020040415360300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":1,
      "vividness":3,
      "r":2,
      "g":19,
      "b":9,
      "hex":"#021309",
      "file_location":"assets/colors_organized/hue_13/brightness_01/2020040415360401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":2,
      "vividness":1,
      "r":24,
      "g":35,
      "b":29,
      "hex":"#18231d",
      "file_location":"assets/colors_organized/hue_13/brightness_02/2020040415355000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":2,
      "vividness":2,
      "r":22,
      "g":35,
      "b":26,
      "hex":"#16231a",
      "file_location":"assets/colors_organized/hue_13/brightness_02/2020040415355001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":2,
      "vividness":3,
      "r":17,
      "g":34,
      "b":24,
      "hex":"#112218",
      "file_location":"assets/colors_organized/hue_13/brightness_02/2020040415355101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":2,
      "vividness":4,
      "r":14,
      "g":34,
      "b":22,
      "hex":"#0e2216",
      "file_location":"assets/colors_organized/hue_13/brightness_02/2020040415355200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":2,
      "vividness":5,
      "r":12,
      "g":34,
      "b":21,
      "hex":"#0c2215",
      "file_location":"assets/colors_organized/hue_13/brightness_02/2020040415355201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":2,
      "vividness":6,
      "r":9,
      "g":35,
      "b":22,
      "hex":"#092316",
      "file_location":"assets/colors_organized/hue_13/brightness_02/2020040415355300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":2,
      "vividness":7,
      "r":10,
      "g":34,
      "b":20,
      "hex":"#0a2214",
      "file_location":"assets/colors_organized/hue_13/brightness_02/2020040415355301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":2,
      "vividness":8,
      "r":6,
      "g":35,
      "b":17,
      "hex":"#062311",
      "file_location":"assets/colors_organized/hue_13/brightness_02/2020040415355400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":2,
      "vividness":9,
      "r":2,
      "g":35,
      "b":14,
      "hex":"#02230e",
      "file_location":"assets/colors_organized/hue_13/brightness_02/2020040415355401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":3,
      "vividness":1,
      "r":45,
      "g":49,
      "b":48,
      "hex":"#2d3130",
      "file_location":"assets/colors_organized/hue_13/brightness_03/2020040415353701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":3,
      "vividness":2,
      "r":43,
      "g":49,
      "b":45,
      "hex":"#2b312d",
      "file_location":"assets/colors_organized/hue_13/brightness_03/2020040415353800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":3,
      "vividness":3,
      "r":39,
      "g":50,
      "b":44,
      "hex":"#27322c",
      "file_location":"assets/colors_organized/hue_13/brightness_03/2020040415353900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":3,
      "vividness":4,
      "r":36,
      "g":49,
      "b":40,
      "hex":"#243128",
      "file_location":"assets/colors_organized/hue_13/brightness_03/2020040415353901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":3,
      "vividness":5,
      "r":34,
      "g":50,
      "b":40,
      "hex":"#223228",
      "file_location":"assets/colors_organized/hue_13/brightness_03/2020040415354000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":3,
      "vividness":6,
      "r":29,
      "g":49,
      "b":37,
      "hex":"#1d3125",
      "file_location":"assets/colors_organized/hue_13/brightness_03/2020040415354001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":3,
      "vividness":7,
      "r":27,
      "g":49,
      "b":36,
      "hex":"#1b3124",
      "file_location":"assets/colors_organized/hue_13/brightness_03/2020040415354100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":3,
      "vividness":8,
      "r":23,
      "g":50,
      "b":33,
      "hex":"#173221",
      "file_location":"assets/colors_organized/hue_13/brightness_03/2020040415354101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":3,
      "vividness":9,
      "r":19,
      "g":50,
      "b":32,
      "hex":"#133220",
      "file_location":"assets/colors_organized/hue_13/brightness_03/2020040415354200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":3,
      "vividness":10,
      "r":15,
      "g":50,
      "b":30,
      "hex":"#0f321e",
      "file_location":"assets/colors_organized/hue_13/brightness_03/2020040415354201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":3,
      "vividness":11,
      "r":12,
      "g":50,
      "b":27,
      "hex":"#0c321b",
      "file_location":"assets/colors_organized/hue_13/brightness_03/2020040415354300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":3,
      "vividness":12,
      "r":10,
      "g":50,
      "b":26,
      "hex":"#0a321a",
      "file_location":"assets/colors_organized/hue_13/brightness_03/2020040415354301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":3,
      "vividness":13,
      "r":7,
      "g":50,
      "b":23,
      "hex":"#073217",
      "file_location":"assets/colors_organized/hue_13/brightness_03/2020040415354400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":3,
      "vividness":14,
      "r":3,
      "g":49,
      "b":21,
      "hex":"#033115",
      "file_location":"assets/colors_organized/hue_13/brightness_03/2020040415354401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":4,
      "vividness":1,
      "r":52,
      "g":65,
      "b":56,
      "hex":"#344138",
      "file_location":"assets/colors_organized/hue_13/brightness_04/2020040415352800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":4,
      "vividness":2,
      "r":47,
      "g":65,
      "b":53,
      "hex":"#2f4135",
      "file_location":"assets/colors_organized/hue_13/brightness_04/2020040415352801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":4,
      "vividness":3,
      "r":43,
      "g":65,
      "b":52,
      "hex":"#2b4134",
      "file_location":"assets/colors_organized/hue_13/brightness_04/2020040415352900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":4,
      "vividness":4,
      "r":38,
      "g":65,
      "b":48,
      "hex":"#264130",
      "file_location":"assets/colors_organized/hue_13/brightness_04/2020040415352901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":4,
      "vividness":5,
      "r":33,
      "g":64,
      "b":46,
      "hex":"#21402e",
      "file_location":"assets/colors_organized/hue_13/brightness_04/2020040415353000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":4,
      "vividness":6,
      "r":30,
      "g":65,
      "b":43,
      "hex":"#1e412b",
      "file_location":"assets/colors_organized/hue_13/brightness_04/2020040415353001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":4,
      "vividness":7,
      "r":27,
      "g":65,
      "b":40,
      "hex":"#1b4128",
      "file_location":"assets/colors_organized/hue_13/brightness_04/2020040415353100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":4,
      "vividness":8,
      "r":21,
      "g":65,
      "b":38,
      "hex":"#154126",
      "file_location":"assets/colors_organized/hue_13/brightness_04/2020040415353101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":4,
      "vividness":9,
      "r":18,
      "g":64,
      "b":35,
      "hex":"#124023",
      "file_location":"assets/colors_organized/hue_13/brightness_04/2020040415353200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":4,
      "vividness":10,
      "r":12,
      "g":66,
      "b":34,
      "hex":"#0c4222",
      "file_location":"assets/colors_organized/hue_13/brightness_04/2020040415353201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":4,
      "vividness":11,
      "r":7,
      "g":64,
      "b":31,
      "hex":"#07401f",
      "file_location":"assets/colors_organized/hue_13/brightness_04/2020040415353300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":4,
      "vividness":12,
      "r":3,
      "g":65,
      "b":28,
      "hex":"#03411c",
      "file_location":"assets/colors_organized/hue_13/brightness_04/2020040415353301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":5,
      "vividness":1,
      "r":74,
      "g":80,
      "b":76,
      "hex":"#4a504c",
      "file_location":"assets/colors_organized/hue_13/brightness_05/2020040415351600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":5,
      "vividness":2,
      "r":69,
      "g":80,
      "b":72,
      "hex":"#455048",
      "file_location":"assets/colors_organized/hue_13/brightness_05/2020040415351601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":5,
      "vividness":3,
      "r":64,
      "g":80,
      "b":70,
      "hex":"#405046",
      "file_location":"assets/colors_organized/hue_13/brightness_05/2020040415351700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":5,
      "vividness":4,
      "r":58,
      "g":80,
      "b":67,
      "hex":"#3a5043",
      "file_location":"assets/colors_organized/hue_13/brightness_05/2020040415351701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":5,
      "vividness":5,
      "r":53,
      "g":80,
      "b":63,
      "hex":"#35503f",
      "file_location":"assets/colors_organized/hue_13/brightness_05/2020040415351800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":5,
      "vividness":6,
      "r":47,
      "g":80,
      "b":59,
      "hex":"#2f503b",
      "file_location":"assets/colors_organized/hue_13/brightness_05/2020040415351801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":5,
      "vividness":7,
      "r":42,
      "g":80,
      "b":57,
      "hex":"#2a5039",
      "file_location":"assets/colors_organized/hue_13/brightness_05/2020040415351900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":5,
      "vividness":8,
      "r":36,
      "g":80,
      "b":53,
      "hex":"#245035",
      "file_location":"assets/colors_organized/hue_13/brightness_05/2020040415351901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":5,
      "vividness":9,
      "r":31,
      "g":80,
      "b":50,
      "hex":"#1f5032",
      "file_location":"assets/colors_organized/hue_13/brightness_05/2020040415352000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":5,
      "vividness":10,
      "r":26,
      "g":80,
      "b":48,
      "hex":"#1a5030",
      "file_location":"assets/colors_organized/hue_13/brightness_05/2020040415352001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":5,
      "vividness":11,
      "r":22,
      "g":79,
      "b":44,
      "hex":"#164f2c",
      "file_location":"assets/colors_organized/hue_13/brightness_05/2020040415352100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":5,
      "vividness":12,
      "r":14,
      "g":81,
      "b":40,
      "hex":"#0e5128",
      "file_location":"assets/colors_organized/hue_13/brightness_05/2020040415352101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":5,
      "vividness":13,
      "r":9,
      "g":80,
      "b":38,
      "hex":"#095026",
      "file_location":"assets/colors_organized/hue_13/brightness_05/2020040415352200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":5,
      "vividness":14,
      "r":7,
      "g":80,
      "b":35,
      "hex":"#075023",
      "file_location":"assets/colors_organized/hue_13/brightness_05/2020040415352201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":6,
      "vividness":1,
      "r":82,
      "g":95,
      "b":86,
      "hex":"#525f56",
      "file_location":"assets/colors_organized/hue_13/brightness_06/2020040415350501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":6,
      "vividness":2,
      "r":75,
      "g":95,
      "b":83,
      "hex":"#4b5f53",
      "file_location":"assets/colors_organized/hue_13/brightness_06/2020040415350600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":6,
      "vividness":3,
      "r":68,
      "g":95,
      "b":78,
      "hex":"#445f4e",
      "file_location":"assets/colors_organized/hue_13/brightness_06/2020040415350601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":6,
      "vividness":4,
      "r":62,
      "g":95,
      "b":76,
      "hex":"#3e5f4c",
      "file_location":"assets/colors_organized/hue_13/brightness_06/2020040415350700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":6,
      "vividness":5,
      "r":57,
      "g":95,
      "b":72,
      "hex":"#395f48",
      "file_location":"assets/colors_organized/hue_13/brightness_06/2020040415350701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":6,
      "vividness":6,
      "r":49,
      "g":95,
      "b":67,
      "hex":"#315f43",
      "file_location":"assets/colors_organized/hue_13/brightness_06/2020040415350800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":6,
      "vividness":7,
      "r":44,
      "g":95,
      "b":64,
      "hex":"#2c5f40",
      "file_location":"assets/colors_organized/hue_13/brightness_06/2020040415350801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":6,
      "vividness":8,
      "r":38,
      "g":95,
      "b":60,
      "hex":"#265f3c",
      "file_location":"assets/colors_organized/hue_13/brightness_06/2020040415350900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":6,
      "vividness":9,
      "r":30,
      "g":94,
      "b":57,
      "hex":"#1e5e39",
      "file_location":"assets/colors_organized/hue_13/brightness_06/2020040415350901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":6,
      "vividness":10,
      "r":24,
      "g":95,
      "b":53,
      "hex":"#185f35",
      "file_location":"assets/colors_organized/hue_13/brightness_06/2020040415351000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":6,
      "vividness":11,
      "r":19,
      "g":95,
      "b":48,
      "hex":"#135f30",
      "file_location":"assets/colors_organized/hue_13/brightness_06/2020040415351001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":6,
      "vividness":12,
      "r":12,
      "g":94,
      "b":45,
      "hex":"#0c5e2d",
      "file_location":"assets/colors_organized/hue_13/brightness_06/2020040415351100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":6,
      "vividness":13,
      "r":6,
      "g":95,
      "b":41,
      "hex":"#065f29",
      "file_location":"assets/colors_organized/hue_13/brightness_06/2020040415351101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":7,
      "vividness":1,
      "r":101,
      "g":110,
      "b":105,
      "hex":"#656e69",
      "file_location":"assets/colors_organized/hue_13/brightness_07/2020040415345400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":7,
      "vividness":2,
      "r":94,
      "g":110,
      "b":100,
      "hex":"#5e6e64",
      "file_location":"assets/colors_organized/hue_13/brightness_07/2020040415345401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":7,
      "vividness":3,
      "r":88,
      "g":110,
      "b":97,
      "hex":"#586e61",
      "file_location":"assets/colors_organized/hue_13/brightness_07/2020040415345500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":7,
      "vividness":4,
      "r":79,
      "g":110,
      "b":92,
      "hex":"#4f6e5c",
      "file_location":"assets/colors_organized/hue_13/brightness_07/2020040415345501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":7,
      "vividness":5,
      "r":72,
      "g":110,
      "b":87,
      "hex":"#486e57",
      "file_location":"assets/colors_organized/hue_13/brightness_07/2020040415345600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":7,
      "vividness":6,
      "r":66,
      "g":110,
      "b":83,
      "hex":"#426e53",
      "file_location":"assets/colors_organized/hue_13/brightness_07/2020040415345601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":7,
      "vividness":7,
      "r":57,
      "g":111,
      "b":79,
      "hex":"#396f4f",
      "file_location":"assets/colors_organized/hue_13/brightness_07/2020040415345700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":7,
      "vividness":8,
      "r":50,
      "g":110,
      "b":74,
      "hex":"#326e4a",
      "file_location":"assets/colors_organized/hue_13/brightness_07/2020040415345701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":7,
      "vividness":9,
      "r":43,
      "g":110,
      "b":69,
      "hex":"#2b6e45",
      "file_location":"assets/colors_organized/hue_13/brightness_07/2020040415345800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":7,
      "vividness":10,
      "r":35,
      "g":111,
      "b":65,
      "hex":"#236f41",
      "file_location":"assets/colors_organized/hue_13/brightness_07/2020040415345801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":7,
      "vividness":11,
      "r":30,
      "g":109,
      "b":61,
      "hex":"#1e6d3d",
      "file_location":"assets/colors_organized/hue_13/brightness_07/2020040415345900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":7,
      "vividness":12,
      "r":21,
      "g":110,
      "b":56,
      "hex":"#156e38",
      "file_location":"assets/colors_organized/hue_13/brightness_07/2020040415345901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":7,
      "vividness":13,
      "r":12,
      "g":110,
      "b":51,
      "hex":"#0c6e33",
      "file_location":"assets/colors_organized/hue_13/brightness_07/2020040415350000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":7,
      "vividness":14,
      "r":6,
      "g":110,
      "b":47,
      "hex":"#066e2f",
      "file_location":"assets/colors_organized/hue_13/brightness_07/2020040415350001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":8,
      "vividness":1,
      "r":115,
      "g":124,
      "b":119,
      "hex":"#737c77",
      "file_location":"assets/colors_organized/hue_13/brightness_08/2020040415344300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":8,
      "vividness":2,
      "r":108,
      "g":124,
      "b":114,
      "hex":"#6c7c72",
      "file_location":"assets/colors_organized/hue_13/brightness_08/2020040415344301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":8,
      "vividness":3,
      "r":100,
      "g":124,
      "b":110,
      "hex":"#647c6e",
      "file_location":"assets/colors_organized/hue_13/brightness_08/2020040415344400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":8,
      "vividness":4,
      "r":91,
      "g":124,
      "b":103,
      "hex":"#5b7c67",
      "file_location":"assets/colors_organized/hue_13/brightness_08/2020040415344401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":8,
      "vividness":5,
      "r":82,
      "g":125,
      "b":98,
      "hex":"#527d62",
      "file_location":"assets/colors_organized/hue_13/brightness_08/2020040415344500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":8,
      "vividness":6,
      "r":73,
      "g":124,
      "b":93,
      "hex":"#497c5d",
      "file_location":"assets/colors_organized/hue_13/brightness_08/2020040415344501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":8,
      "vividness":7,
      "r":67,
      "g":124,
      "b":89,
      "hex":"#437c59",
      "file_location":"assets/colors_organized/hue_13/brightness_08/2020040415344600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":8,
      "vividness":8,
      "r":57,
      "g":124,
      "b":83,
      "hex":"#397c53",
      "file_location":"assets/colors_organized/hue_13/brightness_08/2020040415344601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":8,
      "vividness":9,
      "r":48,
      "g":124,
      "b":78,
      "hex":"#307c4e",
      "file_location":"assets/colors_organized/hue_13/brightness_08/2020040415344700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":8,
      "vividness":10,
      "r":40,
      "g":124,
      "b":74,
      "hex":"#287c4a",
      "file_location":"assets/colors_organized/hue_13/brightness_08/2020040415344701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":8,
      "vividness":11,
      "r":34,
      "g":124,
      "b":70,
      "hex":"#227c46",
      "file_location":"assets/colors_organized/hue_13/brightness_08/2020040415344800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":8,
      "vividness":12,
      "r":23,
      "g":124,
      "b":64,
      "hex":"#177c40",
      "file_location":"assets/colors_organized/hue_13/brightness_08/2020040415344801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":8,
      "vividness":13,
      "r":15,
      "g":124,
      "b":59,
      "hex":"#0f7c3b",
      "file_location":"assets/colors_organized/hue_13/brightness_08/2020040415344900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":8,
      "vividness":14,
      "r":7,
      "g":124,
      "b":54,
      "hex":"#077c36",
      "file_location":"assets/colors_organized/hue_13/brightness_08/2020040415344901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":9,
      "vividness":1,
      "r":130,
      "g":139,
      "b":134,
      "hex":"#828b86",
      "file_location":"assets/colors_organized/hue_13/brightness_09/2020040415343201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":9,
      "vividness":2,
      "r":121,
      "g":139,
      "b":127,
      "hex":"#798b7f",
      "file_location":"assets/colors_organized/hue_13/brightness_09/2020040415343300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":9,
      "vividness":3,
      "r":111,
      "g":139,
      "b":124,
      "hex":"#6f8b7c",
      "file_location":"assets/colors_organized/hue_13/brightness_09/2020040415343301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":9,
      "vividness":4,
      "r":101,
      "g":139,
      "b":116,
      "hex":"#658b74",
      "file_location":"assets/colors_organized/hue_13/brightness_09/2020040415343400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":9,
      "vividness":5,
      "r":93,
      "g":139,
      "b":111,
      "hex":"#5d8b6f",
      "file_location":"assets/colors_organized/hue_13/brightness_09/2020040415343401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":9,
      "vividness":6,
      "r":82,
      "g":139,
      "b":106,
      "hex":"#528b6a",
      "file_location":"assets/colors_organized/hue_13/brightness_09/2020040415343500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":9,
      "vividness":7,
      "r":73,
      "g":140,
      "b":99,
      "hex":"#498c63",
      "file_location":"assets/colors_organized/hue_13/brightness_09/2020040415343501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":9,
      "vividness":8,
      "r":64,
      "g":140,
      "b":94,
      "hex":"#408c5e",
      "file_location":"assets/colors_organized/hue_13/brightness_09/2020040415343600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":9,
      "vividness":9,
      "r":55,
      "g":139,
      "b":89,
      "hex":"#378b59",
      "file_location":"assets/colors_organized/hue_13/brightness_09/2020040415343601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":9,
      "vividness":10,
      "r":46,
      "g":139,
      "b":84,
      "hex":"#2e8b54",
      "file_location":"assets/colors_organized/hue_13/brightness_09/2020040415343700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":9,
      "vividness":11,
      "r":37,
      "g":138,
      "b":78,
      "hex":"#258a4e",
      "file_location":"assets/colors_organized/hue_13/brightness_09/2020040415343701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":9,
      "vividness":12,
      "r":26,
      "g":140,
      "b":71,
      "hex":"#1a8c47",
      "file_location":"assets/colors_organized/hue_13/brightness_09/2020040415343800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":9,
      "vividness":13,
      "r":18,
      "g":140,
      "b":67,
      "hex":"#128c43",
      "file_location":"assets/colors_organized/hue_13/brightness_09/2020040415343801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":9,
      "vividness":14,
      "r":8,
      "g":139,
      "b":59,
      "hex":"#088b3b",
      "file_location":"assets/colors_organized/hue_13/brightness_09/2020040415343900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":10,
      "vividness":1,
      "r":143,
      "g":154,
      "b":148,
      "hex":"#8f9a94",
      "file_location":"assets/colors_organized/hue_13/brightness_10/2020040415341901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":10,
      "vividness":2,
      "r":134,
      "g":154,
      "b":142,
      "hex":"#869a8e",
      "file_location":"assets/colors_organized/hue_13/brightness_10/2020040415341902-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":10,
      "vividness":3,
      "r":123,
      "g":154,
      "b":136,
      "hex":"#7b9a88",
      "file_location":"assets/colors_organized/hue_13/brightness_10/2020040415342000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":10,
      "vividness":4,
      "r":112,
      "g":154,
      "b":130,
      "hex":"#709a82",
      "file_location":"assets/colors_organized/hue_13/brightness_10/2020040415342100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":10,
      "vividness":5,
      "r":103,
      "g":154,
      "b":123,
      "hex":"#679a7b",
      "file_location":"assets/colors_organized/hue_13/brightness_10/2020040415342101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":10,
      "vividness":6,
      "r":92,
      "g":154,
      "b":117,
      "hex":"#5c9a75",
      "file_location":"assets/colors_organized/hue_13/brightness_10/2020040415342200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":10,
      "vividness":7,
      "r":82,
      "g":155,
      "b":110,
      "hex":"#529b6e",
      "file_location":"assets/colors_organized/hue_13/brightness_10/2020040415342201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":10,
      "vividness":8,
      "r":72,
      "g":154,
      "b":105,
      "hex":"#489a69",
      "file_location":"assets/colors_organized/hue_13/brightness_10/2020040415342300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":10,
      "vividness":9,
      "r":61,
      "g":154,
      "b":99,
      "hex":"#3d9a63",
      "file_location":"assets/colors_organized/hue_13/brightness_10/2020040415342301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":10,
      "vividness":10,
      "r":50,
      "g":154,
      "b":91,
      "hex":"#329a5b",
      "file_location":"assets/colors_organized/hue_13/brightness_10/2020040415342400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":10,
      "vividness":11,
      "r":40,
      "g":154,
      "b":85,
      "hex":"#289a55",
      "file_location":"assets/colors_organized/hue_13/brightness_10/2020040415342500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":10,
      "vividness":12,
      "r":29,
      "g":153,
      "b":80,
      "hex":"#1d9950",
      "file_location":"assets/colors_organized/hue_13/brightness_10/2020040415342600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":10,
      "vividness":13,
      "r":19,
      "g":155,
      "b":73,
      "hex":"#139b49",
      "file_location":"assets/colors_organized/hue_13/brightness_10/2020040415342700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":10,
      "vividness":14,
      "r":10,
      "g":154,
      "b":67,
      "hex":"#0a9a43",
      "file_location":"assets/colors_organized/hue_13/brightness_10/2020040415342800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":11,
      "vividness":1,
      "r":158,
      "g":169,
      "b":161,
      "hex":"#9ea9a1",
      "file_location":"assets/colors_organized/hue_13/brightness_11/2020040415340800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":11,
      "vividness":2,
      "r":147,
      "g":169,
      "b":156,
      "hex":"#93a99c",
      "file_location":"assets/colors_organized/hue_13/brightness_11/2020040415340801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":11,
      "vividness":3,
      "r":135,
      "g":170,
      "b":150,
      "hex":"#87aa96",
      "file_location":"assets/colors_organized/hue_13/brightness_11/2020040415340900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":11,
      "vividness":4,
      "r":124,
      "g":170,
      "b":142,
      "hex":"#7caa8e",
      "file_location":"assets/colors_organized/hue_13/brightness_11/2020040415340901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":11,
      "vividness":5,
      "r":112,
      "g":169,
      "b":134,
      "hex":"#70a986",
      "file_location":"assets/colors_organized/hue_13/brightness_11/2020040415341000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":11,
      "vividness":6,
      "r":101,
      "g":169,
      "b":128,
      "hex":"#65a980",
      "file_location":"assets/colors_organized/hue_13/brightness_11/2020040415341001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":11,
      "vividness":7,
      "r":90,
      "g":169,
      "b":122,
      "hex":"#5aa97a",
      "file_location":"assets/colors_organized/hue_13/brightness_11/2020040415341100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":11,
      "vividness":8,
      "r":79,
      "g":169,
      "b":115,
      "hex":"#4fa973",
      "file_location":"assets/colors_organized/hue_13/brightness_11/2020040415341101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":11,
      "vividness":9,
      "r":68,
      "g":170,
      "b":107,
      "hex":"#44aa6b",
      "file_location":"assets/colors_organized/hue_13/brightness_11/2020040415341200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":11,
      "vividness":10,
      "r":55,
      "g":169,
      "b":100,
      "hex":"#37a964",
      "file_location":"assets/colors_organized/hue_13/brightness_11/2020040415341201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":11,
      "vividness":11,
      "r":44,
      "g":168,
      "b":95,
      "hex":"#2ca85f",
      "file_location":"assets/colors_organized/hue_13/brightness_11/2020040415341300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":11,
      "vividness":12,
      "r":33,
      "g":169,
      "b":87,
      "hex":"#21a957",
      "file_location":"assets/colors_organized/hue_13/brightness_11/2020040415341301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":11,
      "vividness":13,
      "r":21,
      "g":169,
      "b":81,
      "hex":"#15a951",
      "file_location":"assets/colors_organized/hue_13/brightness_11/2020040415341400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":11,
      "vividness":14,
      "r":10,
      "g":170,
      "b":74,
      "hex":"#0aaa4a",
      "file_location":"assets/colors_organized/hue_13/brightness_11/2020040415341401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":12,
      "vividness":1,
      "r":172,
      "g":185,
      "b":176,
      "hex":"#acb9b0",
      "file_location":"assets/colors_organized/hue_13/brightness_12/2020040415335601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":12,
      "vividness":2,
      "r":160,
      "g":184,
      "b":168,
      "hex":"#a0b8a8",
      "file_location":"assets/colors_organized/hue_13/brightness_12/2020040415335700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":12,
      "vividness":3,
      "r":147,
      "g":185,
      "b":162,
      "hex":"#93b9a2",
      "file_location":"assets/colors_organized/hue_13/brightness_12/2020040415335701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":12,
      "vividness":4,
      "r":135,
      "g":184,
      "b":155,
      "hex":"#87b89b",
      "file_location":"assets/colors_organized/hue_13/brightness_12/2020040415335800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":12,
      "vividness":5,
      "r":123,
      "g":185,
      "b":148,
      "hex":"#7bb994",
      "file_location":"assets/colors_organized/hue_13/brightness_12/2020040415335801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":12,
      "vividness":6,
      "r":109,
      "g":185,
      "b":139,
      "hex":"#6db98b",
      "file_location":"assets/colors_organized/hue_13/brightness_12/2020040415335900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":12,
      "vividness":7,
      "r":97,
      "g":184,
      "b":131,
      "hex":"#61b883",
      "file_location":"assets/colors_organized/hue_13/brightness_12/2020040415335901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":12,
      "vividness":8,
      "r":86,
      "g":184,
      "b":125,
      "hex":"#56b87d",
      "file_location":"assets/colors_organized/hue_13/brightness_12/2020040415340000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":12,
      "vividness":9,
      "r":73,
      "g":184,
      "b":118,
      "hex":"#49b876",
      "file_location":"assets/colors_organized/hue_13/brightness_12/2020040415340001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":12,
      "vividness":10,
      "r":60,
      "g":184,
      "b":111,
      "hex":"#3cb86f",
      "file_location":"assets/colors_organized/hue_13/brightness_12/2020040415340100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":12,
      "vividness":11,
      "r":48,
      "g":184,
      "b":102,
      "hex":"#30b866",
      "file_location":"assets/colors_organized/hue_13/brightness_12/2020040415340200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":12,
      "vividness":12,
      "r":36,
      "g":184,
      "b":96,
      "hex":"#24b860",
      "file_location":"assets/colors_organized/hue_13/brightness_12/2020040415340201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":12,
      "vividness":13,
      "r":23,
      "g":184,
      "b":88,
      "hex":"#17b858",
      "file_location":"assets/colors_organized/hue_13/brightness_12/2020040415340300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":12,
      "vividness":14,
      "r":12,
      "g":185,
      "b":80,
      "hex":"#0cb950",
      "file_location":"assets/colors_organized/hue_13/brightness_12/2020040415340301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":13,
      "vividness":1,
      "r":187,
      "g":200,
      "b":191,
      "hex":"#bbc8bf",
      "file_location":"assets/colors_organized/hue_13/brightness_13/2020040415334600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":13,
      "vividness":2,
      "r":173,
      "g":200,
      "b":183,
      "hex":"#adc8b7",
      "file_location":"assets/colors_organized/hue_13/brightness_13/2020040415334601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":13,
      "vividness":3,
      "r":160,
      "g":200,
      "b":176,
      "hex":"#a0c8b0",
      "file_location":"assets/colors_organized/hue_13/brightness_13/2020040415334700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":13,
      "vividness":4,
      "r":146,
      "g":200,
      "b":168,
      "hex":"#92c8a8",
      "file_location":"assets/colors_organized/hue_13/brightness_13/2020040415334701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":13,
      "vividness":5,
      "r":133,
      "g":200,
      "b":159,
      "hex":"#85c89f",
      "file_location":"assets/colors_organized/hue_13/brightness_13/2020040415334800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":13,
      "vividness":6,
      "r":120,
      "g":199,
      "b":151,
      "hex":"#78c797",
      "file_location":"assets/colors_organized/hue_13/brightness_13/2020040415334801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":13,
      "vividness":7,
      "r":106,
      "g":199,
      "b":144,
      "hex":"#6ac790",
      "file_location":"assets/colors_organized/hue_13/brightness_13/2020040415334900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":13,
      "vividness":8,
      "r":93,
      "g":199,
      "b":135,
      "hex":"#5dc787",
      "file_location":"assets/colors_organized/hue_13/brightness_13/2020040415334901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":13,
      "vividness":9,
      "r":78,
      "g":200,
      "b":127,
      "hex":"#4ec87f",
      "file_location":"assets/colors_organized/hue_13/brightness_13/2020040415335000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":13,
      "vividness":10,
      "r":66,
      "g":199,
      "b":118,
      "hex":"#42c776",
      "file_location":"assets/colors_organized/hue_13/brightness_13/2020040415335001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":13,
      "vividness":11,
      "r":52,
      "g":199,
      "b":110,
      "hex":"#34c76e",
      "file_location":"assets/colors_organized/hue_13/brightness_13/2020040415335100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":13,
      "vividness":12,
      "r":38,
      "g":199,
      "b":103,
      "hex":"#26c767",
      "file_location":"assets/colors_organized/hue_13/brightness_13/2020040415335101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":13,
      "vividness":13,
      "r":27,
      "g":200,
      "b":95,
      "hex":"#1bc85f",
      "file_location":"assets/colors_organized/hue_13/brightness_13/2020040415335200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":13,
      "vividness":14,
      "r":13,
      "g":200,
      "b":87,
      "hex":"#0dc857",
      "file_location":"assets/colors_organized/hue_13/brightness_13/2020040415335201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":14,
      "vividness":1,
      "r":201,
      "g":214,
      "b":205,
      "hex":"#c9d6cd",
      "file_location":"assets/colors_organized/hue_13/brightness_14/2020040415333500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":14,
      "vividness":2,
      "r":186,
      "g":215,
      "b":197,
      "hex":"#bad7c5",
      "file_location":"assets/colors_organized/hue_13/brightness_14/2020040415333501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":14,
      "vividness":3,
      "r":171,
      "g":215,
      "b":188,
      "hex":"#abd7bc",
      "file_location":"assets/colors_organized/hue_13/brightness_14/2020040415333600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":14,
      "vividness":4,
      "r":157,
      "g":214,
      "b":179,
      "hex":"#9dd6b3",
      "file_location":"assets/colors_organized/hue_13/brightness_14/2020040415333601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":14,
      "vividness":5,
      "r":143,
      "g":214,
      "b":170,
      "hex":"#8fd6aa",
      "file_location":"assets/colors_organized/hue_13/brightness_14/2020040415333700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":14,
      "vividness":6,
      "r":128,
      "g":215,
      "b":164,
      "hex":"#80d7a4",
      "file_location":"assets/colors_organized/hue_13/brightness_14/2020040415333701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":14,
      "vividness":7,
      "r":113,
      "g":214,
      "b":154,
      "hex":"#71d69a",
      "file_location":"assets/colors_organized/hue_13/brightness_14/2020040415333800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":14,
      "vividness":8,
      "r":99,
      "g":214,
      "b":145,
      "hex":"#63d691",
      "file_location":"assets/colors_organized/hue_13/brightness_14/2020040415333801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":14,
      "vividness":9,
      "r":84,
      "g":215,
      "b":137,
      "hex":"#54d789",
      "file_location":"assets/colors_organized/hue_13/brightness_14/2020040415333900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":14,
      "vividness":10,
      "r":71,
      "g":215,
      "b":128,
      "hex":"#47d780",
      "file_location":"assets/colors_organized/hue_13/brightness_14/2020040415333901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":14,
      "vividness":11,
      "r":56,
      "g":214,
      "b":119,
      "hex":"#38d677",
      "file_location":"assets/colors_organized/hue_13/brightness_14/2020040415334000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":14,
      "vividness":12,
      "r":42,
      "g":215,
      "b":110,
      "hex":"#2ad76e",
      "file_location":"assets/colors_organized/hue_13/brightness_14/2020040415334001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":14,
      "vividness":13,
      "r":28,
      "g":215,
      "b":102,
      "hex":"#1cd766",
      "file_location":"assets/colors_organized/hue_13/brightness_14/2020040415334100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":14,
      "vividness":14,
      "r":12,
      "g":214,
      "b":94,
      "hex":"#0cd65e",
      "file_location":"assets/colors_organized/hue_13/brightness_14/2020040415334200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":15,
      "vividness":1,
      "r":214,
      "g":230,
      "b":220,
      "hex":"#d6e6dc",
      "file_location":"assets/colors_organized/hue_13/brightness_15/2020040415332401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":15,
      "vividness":2,
      "r":198,
      "g":229,
      "b":211,
      "hex":"#c6e5d3",
      "file_location":"assets/colors_organized/hue_13/brightness_15/2020040415332500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":15,
      "vividness":3,
      "r":183,
      "g":229,
      "b":201,
      "hex":"#b7e5c9",
      "file_location":"assets/colors_organized/hue_13/brightness_15/2020040415332501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":15,
      "vividness":4,
      "r":168,
      "g":230,
      "b":193,
      "hex":"#a8e6c1",
      "file_location":"assets/colors_organized/hue_13/brightness_15/2020040415332600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":15,
      "vividness":5,
      "r":152,
      "g":229,
      "b":183,
      "hex":"#98e5b7",
      "file_location":"assets/colors_organized/hue_13/brightness_15/2020040415332601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":15,
      "vividness":6,
      "r":137,
      "g":230,
      "b":175,
      "hex":"#89e6af",
      "file_location":"assets/colors_organized/hue_13/brightness_15/2020040415332700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":15,
      "vividness":7,
      "r":121,
      "g":230,
      "b":165,
      "hex":"#79e6a5",
      "file_location":"assets/colors_organized/hue_13/brightness_15/2020040415332701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":15,
      "vividness":8,
      "r":107,
      "g":229,
      "b":156,
      "hex":"#6be59c",
      "file_location":"assets/colors_organized/hue_13/brightness_15/2020040415332800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":15,
      "vividness":9,
      "r":91,
      "g":230,
      "b":147,
      "hex":"#5be693",
      "file_location":"assets/colors_organized/hue_13/brightness_15/2020040415332801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":15,
      "vividness":10,
      "r":76,
      "g":229,
      "b":138,
      "hex":"#4ce58a",
      "file_location":"assets/colors_organized/hue_13/brightness_15/2020040415332900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":15,
      "vividness":11,
      "r":60,
      "g":229,
      "b":128,
      "hex":"#3ce580",
      "file_location":"assets/colors_organized/hue_13/brightness_15/2020040415332901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":15,
      "vividness":12,
      "r":44,
      "g":230,
      "b":119,
      "hex":"#2ce677",
      "file_location":"assets/colors_organized/hue_13/brightness_15/2020040415333000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":15,
      "vividness":13,
      "r":30,
      "g":229,
      "b":110,
      "hex":"#1ee56e",
      "file_location":"assets/colors_organized/hue_13/brightness_15/2020040415333001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":13,
      "brightness":15,
      "vividness":14,
      "r":14,
      "g":229,
      "b":99,
      "hex":"#0ee563",
      "file_location":"assets/colors_organized/hue_13/brightness_15/2020040415333100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":1,
      "vividness":1,
      "r":18,
      "g":20,
      "b":19,
      "hex":"#121413",
      "file_location":"assets/colors_organized/hue_14/brightness_01/2020040415385601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":1,
      "vividness":2,
      "r":9,
      "g":20,
      "b":14,
      "hex":"#09140e",
      "file_location":"assets/colors_organized/hue_14/brightness_01/2020040415385900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":1,
      "vividness":3,
      "r":7,
      "g":19,
      "b":15,
      "hex":"#07130f",
      "file_location":"assets/colors_organized/hue_14/brightness_01/2020040415390001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":1,
      "vividness":4,
      "r":3,
      "g":20,
      "b":12,
      "hex":"#03140c",
      "file_location":"assets/colors_organized/hue_14/brightness_01/2020040415390200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":1,
      "vividness":5,
      "r":0,
      "g":20,
      "b":13,
      "hex":"#00140d",
      "file_location":"assets/colors_organized/hue_14/brightness_01/2020040415390400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":2,
      "vividness":1,
      "r":29,
      "g":35,
      "b":33,
      "hex":"#1d2321",
      "file_location":"assets/colors_organized/hue_14/brightness_02/2020040415384500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":2,
      "vividness":2,
      "r":28,
      "g":34,
      "b":32,
      "hex":"#1c2220",
      "file_location":"assets/colors_organized/hue_14/brightness_02/2020040415384501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":2,
      "vividness":3,
      "r":24,
      "g":35,
      "b":31,
      "hex":"#18231f",
      "file_location":"assets/colors_organized/hue_14/brightness_02/2020040415384600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":2,
      "vividness":4,
      "r":22,
      "g":35,
      "b":28,
      "hex":"#16231c",
      "file_location":"assets/colors_organized/hue_14/brightness_02/2020040415384601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":2,
      "vividness":5,
      "r":22,
      "g":34,
      "b":30,
      "hex":"#16221e",
      "file_location":"assets/colors_organized/hue_14/brightness_02/2020040415384700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":2,
      "vividness":6,
      "r":18,
      "g":35,
      "b":27,
      "hex":"#12231b",
      "file_location":"assets/colors_organized/hue_14/brightness_02/2020040415384701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":2,
      "vividness":7,
      "r":15,
      "g":35,
      "b":26,
      "hex":"#0f231a",
      "file_location":"assets/colors_organized/hue_14/brightness_02/2020040415384800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":2,
      "vividness":8,
      "r":13,
      "g":34,
      "b":27,
      "hex":"#0d221b",
      "file_location":"assets/colors_organized/hue_14/brightness_02/2020040415384801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":2,
      "vividness":9,
      "r":9,
      "g":35,
      "b":24,
      "hex":"#092318",
      "file_location":"assets/colors_organized/hue_14/brightness_02/2020040415384900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":2,
      "vividness":10,
      "r":7,
      "g":35,
      "b":23,
      "hex":"#072317",
      "file_location":"assets/colors_organized/hue_14/brightness_02/2020040415385000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":2,
      "vividness":11,
      "r":3,
      "g":35,
      "b":22,
      "hex":"#032316",
      "file_location":"assets/colors_organized/hue_14/brightness_02/2020040415385100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":2,
      "vividness":12,
      "r":2,
      "g":34,
      "b":21,
      "hex":"#022215",
      "file_location":"assets/colors_organized/hue_14/brightness_02/2020040415385101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":3,
      "vividness":1,
      "r":43,
      "g":49,
      "b":47,
      "hex":"#2b312f",
      "file_location":"assets/colors_organized/hue_14/brightness_03/2020040415383301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":3,
      "vividness":2,
      "r":39,
      "g":50,
      "b":46,
      "hex":"#27322e",
      "file_location":"assets/colors_organized/hue_14/brightness_03/2020040415383400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":3,
      "vividness":3,
      "r":35,
      "g":50,
      "b":43,
      "hex":"#23322b",
      "file_location":"assets/colors_organized/hue_14/brightness_03/2020040415383401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":3,
      "vividness":4,
      "r":33,
      "g":50,
      "b":42,
      "hex":"#21322a",
      "file_location":"assets/colors_organized/hue_14/brightness_03/2020040415383500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":3,
      "vividness":5,
      "r":30,
      "g":50,
      "b":41,
      "hex":"#1e3229",
      "file_location":"assets/colors_organized/hue_14/brightness_03/2020040415383501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":3,
      "vividness":6,
      "r":27,
      "g":50,
      "b":40,
      "hex":"#1b3228",
      "file_location":"assets/colors_organized/hue_14/brightness_03/2020040415383600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":3,
      "vividness":7,
      "r":22,
      "g":50,
      "b":38,
      "hex":"#163226",
      "file_location":"assets/colors_organized/hue_14/brightness_03/2020040415383700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":3,
      "vividness":8,
      "r":19,
      "g":49,
      "b":37,
      "hex":"#133125",
      "file_location":"assets/colors_organized/hue_14/brightness_03/2020040415383701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":3,
      "vividness":9,
      "r":13,
      "g":49,
      "b":35,
      "hex":"#0d3123",
      "file_location":"assets/colors_organized/hue_14/brightness_03/2020040415383800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":3,
      "vividness":10,
      "r":9,
      "g":50,
      "b":34,
      "hex":"#093222",
      "file_location":"assets/colors_organized/hue_14/brightness_03/2020040415383900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":3,
      "vividness":11,
      "r":7,
      "g":50,
      "b":31,
      "hex":"#07321f",
      "file_location":"assets/colors_organized/hue_14/brightness_03/2020040415383901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":3,
      "vividness":12,
      "r":2,
      "g":49,
      "b":29,
      "hex":"#02311d",
      "file_location":"assets/colors_organized/hue_14/brightness_03/2020040415384000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":4,
      "vividness":1,
      "r":59,
      "g":65,
      "b":63,
      "hex":"#3b413f",
      "file_location":"assets/colors_organized/hue_14/brightness_04/2020040415382101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":4,
      "vividness":2,
      "r":56,
      "g":65,
      "b":60,
      "hex":"#38413c",
      "file_location":"assets/colors_organized/hue_14/brightness_04/2020040415382200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":4,
      "vividness":3,
      "r":52,
      "g":65,
      "b":58,
      "hex":"#34413a",
      "file_location":"assets/colors_organized/hue_14/brightness_04/2020040415382300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":4,
      "vividness":4,
      "r":46,
      "g":66,
      "b":57,
      "hex":"#2e4239",
      "file_location":"assets/colors_organized/hue_14/brightness_04/2020040415382301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":4,
      "vividness":5,
      "r":42,
      "g":65,
      "b":55,
      "hex":"#2a4137",
      "file_location":"assets/colors_organized/hue_14/brightness_04/2020040415382400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":4,
      "vividness":6,
      "r":39,
      "g":65,
      "b":54,
      "hex":"#274136",
      "file_location":"assets/colors_organized/hue_14/brightness_04/2020040415382401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":4,
      "vividness":7,
      "r":33,
      "g":65,
      "b":52,
      "hex":"#214134",
      "file_location":"assets/colors_organized/hue_14/brightness_04/2020040415382500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":4,
      "vividness":8,
      "r":29,
      "g":65,
      "b":51,
      "hex":"#1d4133",
      "file_location":"assets/colors_organized/hue_14/brightness_04/2020040415382501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":4,
      "vividness":9,
      "r":27,
      "g":66,
      "b":48,
      "hex":"#1b4230",
      "file_location":"assets/colors_organized/hue_14/brightness_04/2020040415382600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":4,
      "vividness":10,
      "r":22,
      "g":65,
      "b":46,
      "hex":"#16412e",
      "file_location":"assets/colors_organized/hue_14/brightness_04/2020040415382700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":4,
      "vividness":11,
      "r":17,
      "g":64,
      "b":44,
      "hex":"#11402c",
      "file_location":"assets/colors_organized/hue_14/brightness_04/2020040415382701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":4,
      "vividness":12,
      "r":12,
      "g":66,
      "b":43,
      "hex":"#0c422b",
      "file_location":"assets/colors_organized/hue_14/brightness_04/2020040415382800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":4,
      "vividness":13,
      "r":7,
      "g":65,
      "b":41,
      "hex":"#074129",
      "file_location":"assets/colors_organized/hue_14/brightness_04/2020040415382801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":4,
      "vividness":14,
      "r":2,
      "g":64,
      "b":39,
      "hex":"#024027",
      "file_location":"assets/colors_organized/hue_14/brightness_04/2020040415382900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":5,
      "vividness":1,
      "r":74,
      "g":80,
      "b":78,
      "hex":"#4a504e",
      "file_location":"assets/colors_organized/hue_14/brightness_05/2020040415381001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":5,
      "vividness":2,
      "r":69,
      "g":80,
      "b":74,
      "hex":"#45504a",
      "file_location":"assets/colors_organized/hue_14/brightness_05/2020040415381100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":5,
      "vividness":3,
      "r":63,
      "g":80,
      "b":72,
      "hex":"#3f5048",
      "file_location":"assets/colors_organized/hue_14/brightness_05/2020040415381101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":5,
      "vividness":4,
      "r":57,
      "g":80,
      "b":70,
      "hex":"#395046",
      "file_location":"assets/colors_organized/hue_14/brightness_05/2020040415381200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":5,
      "vividness":5,
      "r":52,
      "g":80,
      "b":68,
      "hex":"#345044",
      "file_location":"assets/colors_organized/hue_14/brightness_05/2020040415381201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":5,
      "vividness":6,
      "r":48,
      "g":80,
      "b":67,
      "hex":"#305043",
      "file_location":"assets/colors_organized/hue_14/brightness_05/2020040415381300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":5,
      "vividness":7,
      "r":42,
      "g":80,
      "b":65,
      "hex":"#2a5041",
      "file_location":"assets/colors_organized/hue_14/brightness_05/2020040415381301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":5,
      "vividness":8,
      "r":37,
      "g":80,
      "b":63,
      "hex":"#25503f",
      "file_location":"assets/colors_organized/hue_14/brightness_05/2020040415381400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":5,
      "vividness":9,
      "r":31,
      "g":80,
      "b":59,
      "hex":"#1f503b",
      "file_location":"assets/colors_organized/hue_14/brightness_05/2020040415381401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":5,
      "vividness":10,
      "r":26,
      "g":80,
      "b":57,
      "hex":"#1a5039",
      "file_location":"assets/colors_organized/hue_14/brightness_05/2020040415381500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":5,
      "vividness":11,
      "r":22,
      "g":80,
      "b":56,
      "hex":"#165038",
      "file_location":"assets/colors_organized/hue_14/brightness_05/2020040415381501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":5,
      "vividness":12,
      "r":14,
      "g":80,
      "b":53,
      "hex":"#0e5035",
      "file_location":"assets/colors_organized/hue_14/brightness_05/2020040415381600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":5,
      "vividness":13,
      "r":10,
      "g":80,
      "b":52,
      "hex":"#0a5034",
      "file_location":"assets/colors_organized/hue_14/brightness_05/2020040415381700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":5,
      "vividness":14,
      "r":6,
      "g":79,
      "b":49,
      "hex":"#064f31",
      "file_location":"assets/colors_organized/hue_14/brightness_05/2020040415381701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":6,
      "vividness":1,
      "r":88,
      "g":94,
      "b":92,
      "hex":"#585e5c",
      "file_location":"assets/colors_organized/hue_14/brightness_06/2020040415375801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":6,
      "vividness":2,
      "r":82,
      "g":94,
      "b":90,
      "hex":"#525e5a",
      "file_location":"assets/colors_organized/hue_14/brightness_06/2020040415375900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":6,
      "vividness":3,
      "r":75,
      "g":95,
      "b":86,
      "hex":"#4b5f56",
      "file_location":"assets/colors_organized/hue_14/brightness_06/2020040415380000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":6,
      "vividness":4,
      "r":69,
      "g":95,
      "b":84,
      "hex":"#455f54",
      "file_location":"assets/colors_organized/hue_14/brightness_06/2020040415380001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":6,
      "vividness":5,
      "r":63,
      "g":95,
      "b":82,
      "hex":"#3f5f52",
      "file_location":"assets/colors_organized/hue_14/brightness_06/2020040415380100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":6,
      "vividness":6,
      "r":57,
      "g":96,
      "b":78,
      "hex":"#39604e",
      "file_location":"assets/colors_organized/hue_14/brightness_06/2020040415380101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":6,
      "vividness":7,
      "r":50,
      "g":94,
      "b":77,
      "hex":"#325e4d",
      "file_location":"assets/colors_organized/hue_14/brightness_06/2020040415380200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":6,
      "vividness":8,
      "r":43,
      "g":95,
      "b":73,
      "hex":"#2b5f49",
      "file_location":"assets/colors_organized/hue_14/brightness_06/2020040415380201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":6,
      "vividness":9,
      "r":37,
      "g":95,
      "b":71,
      "hex":"#255f47",
      "file_location":"assets/colors_organized/hue_14/brightness_06/2020040415380300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":6,
      "vividness":10,
      "r":31,
      "g":95,
      "b":69,
      "hex":"#1f5f45",
      "file_location":"assets/colors_organized/hue_14/brightness_06/2020040415380400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":6,
      "vividness":11,
      "r":25,
      "g":95,
      "b":67,
      "hex":"#195f43",
      "file_location":"assets/colors_organized/hue_14/brightness_06/2020040415380401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":6,
      "vividness":12,
      "r":20,
      "g":95,
      "b":64,
      "hex":"#145f40",
      "file_location":"assets/colors_organized/hue_14/brightness_06/2020040415380500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":6,
      "vividness":13,
      "r":11,
      "g":95,
      "b":61,
      "hex":"#0b5f3d",
      "file_location":"assets/colors_organized/hue_14/brightness_06/2020040415380501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":6,
      "vividness":14,
      "r":7,
      "g":94,
      "b":59,
      "hex":"#075e3b",
      "file_location":"assets/colors_organized/hue_14/brightness_06/2020040415380600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":7,
      "vividness":1,
      "r":101,
      "g":110,
      "b":107,
      "hex":"#656e6b",
      "file_location":"assets/colors_organized/hue_14/brightness_07/2020040415374601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":7,
      "vividness":2,
      "r":95,
      "g":110,
      "b":103,
      "hex":"#5f6e67",
      "file_location":"assets/colors_organized/hue_14/brightness_07/2020040415374700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":7,
      "vividness":3,
      "r":88,
      "g":109,
      "b":100,
      "hex":"#586d64",
      "file_location":"assets/colors_organized/hue_14/brightness_07/2020040415374800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":7,
      "vividness":4,
      "r":80,
      "g":110,
      "b":98,
      "hex":"#506e62",
      "file_location":"assets/colors_organized/hue_14/brightness_07/2020040415374801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":7,
      "vividness":5,
      "r":73,
      "g":109,
      "b":95,
      "hex":"#496d5f",
      "file_location":"assets/colors_organized/hue_14/brightness_07/2020040415374900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":7,
      "vividness":6,
      "r":66,
      "g":111,
      "b":91,
      "hex":"#426f5b",
      "file_location":"assets/colors_organized/hue_14/brightness_07/2020040415374901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":7,
      "vividness":7,
      "r":57,
      "g":111,
      "b":88,
      "hex":"#396f58",
      "file_location":"assets/colors_organized/hue_14/brightness_07/2020040415375000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":7,
      "vividness":8,
      "r":50,
      "g":110,
      "b":85,
      "hex":"#326e55",
      "file_location":"assets/colors_organized/hue_14/brightness_07/2020040415375100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":7,
      "vividness":9,
      "r":44,
      "g":110,
      "b":83,
      "hex":"#2c6e53",
      "file_location":"assets/colors_organized/hue_14/brightness_07/2020040415375101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":7,
      "vividness":10,
      "r":35,
      "g":110,
      "b":79,
      "hex":"#236e4f",
      "file_location":"assets/colors_organized/hue_14/brightness_07/2020040415375200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":7,
      "vividness":11,
      "r":29,
      "g":110,
      "b":77,
      "hex":"#1d6e4d",
      "file_location":"assets/colors_organized/hue_14/brightness_07/2020040415375201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":7,
      "vividness":12,
      "r":22,
      "g":109,
      "b":74,
      "hex":"#166d4a",
      "file_location":"assets/colors_organized/hue_14/brightness_07/2020040415375300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":7,
      "vividness":13,
      "r":12,
      "g":110,
      "b":71,
      "hex":"#0c6e47",
      "file_location":"assets/colors_organized/hue_14/brightness_07/2020040415375301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":7,
      "vividness":14,
      "r":7,
      "g":110,
      "b":67,
      "hex":"#076e43",
      "file_location":"assets/colors_organized/hue_14/brightness_07/2020040415375400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":8,
      "vividness":1,
      "r":115,
      "g":124,
      "b":121,
      "hex":"#737c79",
      "file_location":"assets/colors_organized/hue_14/brightness_08/2020040415373600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":8,
      "vividness":2,
      "r":107,
      "g":124,
      "b":118,
      "hex":"#6b7c76",
      "file_location":"assets/colors_organized/hue_14/brightness_08/2020040415373601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":8,
      "vividness":3,
      "r":98,
      "g":124,
      "b":113,
      "hex":"#627c71",
      "file_location":"assets/colors_organized/hue_14/brightness_08/2020040415373700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":8,
      "vividness":4,
      "r":90,
      "g":124,
      "b":110,
      "hex":"#5a7c6e",
      "file_location":"assets/colors_organized/hue_14/brightness_08/2020040415373701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":8,
      "vividness":5,
      "r":83,
      "g":124,
      "b":108,
      "hex":"#537c6c",
      "file_location":"assets/colors_organized/hue_14/brightness_08/2020040415373800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":8,
      "vividness":6,
      "r":73,
      "g":125,
      "b":103,
      "hex":"#497d67",
      "file_location":"assets/colors_organized/hue_14/brightness_08/2020040415373801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":8,
      "vividness":7,
      "r":66,
      "g":124,
      "b":100,
      "hex":"#427c64",
      "file_location":"assets/colors_organized/hue_14/brightness_08/2020040415373900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":8,
      "vividness":8,
      "r":58,
      "g":124,
      "b":97,
      "hex":"#3a7c61",
      "file_location":"assets/colors_organized/hue_14/brightness_08/2020040415373901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":8,
      "vividness":9,
      "r":49,
      "g":124,
      "b":94,
      "hex":"#317c5e",
      "file_location":"assets/colors_organized/hue_14/brightness_08/2020040415374000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":8,
      "vividness":10,
      "r":40,
      "g":124,
      "b":90,
      "hex":"#287c5a",
      "file_location":"assets/colors_organized/hue_14/brightness_08/2020040415374001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":8,
      "vividness":11,
      "r":34,
      "g":124,
      "b":88,
      "hex":"#227c58",
      "file_location":"assets/colors_organized/hue_14/brightness_08/2020040415374100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":8,
      "vividness":12,
      "r":22,
      "g":124,
      "b":84,
      "hex":"#167c54",
      "file_location":"assets/colors_organized/hue_14/brightness_08/2020040415374101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":8,
      "vividness":13,
      "r":14,
      "g":123,
      "b":81,
      "hex":"#0e7b51",
      "file_location":"assets/colors_organized/hue_14/brightness_08/2020040415374200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":8,
      "vividness":14,
      "r":7,
      "g":124,
      "b":79,
      "hex":"#077c4f",
      "file_location":"assets/colors_organized/hue_14/brightness_08/2020040415374201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":9,
      "vividness":1,
      "r":130,
      "g":139,
      "b":136,
      "hex":"#828b88",
      "file_location":"assets/colors_organized/hue_14/brightness_09/2020040415372500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":9,
      "vividness":2,
      "r":120,
      "g":140,
      "b":131,
      "hex":"#788c83",
      "file_location":"assets/colors_organized/hue_14/brightness_09/2020040415372501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":9,
      "vividness":3,
      "r":111,
      "g":139,
      "b":127,
      "hex":"#6f8b7f",
      "file_location":"assets/colors_organized/hue_14/brightness_09/2020040415372600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":9,
      "vividness":4,
      "r":101,
      "g":139,
      "b":124,
      "hex":"#658b7c",
      "file_location":"assets/colors_organized/hue_14/brightness_09/2020040415372601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":9,
      "vividness":5,
      "r":92,
      "g":139,
      "b":121,
      "hex":"#5c8b79",
      "file_location":"assets/colors_organized/hue_14/brightness_09/2020040415372700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":9,
      "vividness":6,
      "r":84,
      "g":139,
      "b":116,
      "hex":"#548b74",
      "file_location":"assets/colors_organized/hue_14/brightness_09/2020040415372701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":9,
      "vividness":7,
      "r":74,
      "g":140,
      "b":113,
      "hex":"#4a8c71",
      "file_location":"assets/colors_organized/hue_14/brightness_09/2020040415372800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":9,
      "vividness":8,
      "r":65,
      "g":140,
      "b":110,
      "hex":"#418c6e",
      "file_location":"assets/colors_organized/hue_14/brightness_09/2020040415372801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":9,
      "vividness":9,
      "r":54,
      "g":139,
      "b":106,
      "hex":"#368b6a",
      "file_location":"assets/colors_organized/hue_14/brightness_09/2020040415372900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":9,
      "vividness":10,
      "r":45,
      "g":139,
      "b":102,
      "hex":"#2d8b66",
      "file_location":"assets/colors_organized/hue_14/brightness_09/2020040415373000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":9,
      "vividness":11,
      "r":37,
      "g":139,
      "b":99,
      "hex":"#258b63",
      "file_location":"assets/colors_organized/hue_14/brightness_09/2020040415373001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":9,
      "vividness":12,
      "r":26,
      "g":139,
      "b":95,
      "hex":"#1a8b5f",
      "file_location":"assets/colors_organized/hue_14/brightness_09/2020040415373002-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":9,
      "vividness":13,
      "r":17,
      "g":139,
      "b":90,
      "hex":"#118b5a",
      "file_location":"assets/colors_organized/hue_14/brightness_09/2020040415373100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":9,
      "vividness":14,
      "r":8,
      "g":139,
      "b":87,
      "hex":"#088b57",
      "file_location":"assets/colors_organized/hue_14/brightness_09/2020040415373101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":10,
      "vividness":1,
      "r":144,
      "g":155,
      "b":151,
      "hex":"#909b97",
      "file_location":"assets/colors_organized/hue_14/brightness_10/2020040415371301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":10,
      "vividness":2,
      "r":134,
      "g":154,
      "b":145,
      "hex":"#869a91",
      "file_location":"assets/colors_organized/hue_14/brightness_10/2020040415371400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":10,
      "vividness":3,
      "r":122,
      "g":154,
      "b":141,
      "hex":"#7a9a8d",
      "file_location":"assets/colors_organized/hue_14/brightness_10/2020040415371401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":10,
      "vividness":4,
      "r":113,
      "g":154,
      "b":138,
      "hex":"#719a8a",
      "file_location":"assets/colors_organized/hue_14/brightness_10/2020040415371500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":10,
      "vividness":5,
      "r":103,
      "g":154,
      "b":135,
      "hex":"#679a87",
      "file_location":"assets/colors_organized/hue_14/brightness_10/2020040415371501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":10,
      "vividness":6,
      "r":92,
      "g":154,
      "b":129,
      "hex":"#5c9a81",
      "file_location":"assets/colors_organized/hue_14/brightness_10/2020040415371502-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":10,
      "vividness":7,
      "r":81,
      "g":154,
      "b":124,
      "hex":"#519a7c",
      "file_location":"assets/colors_organized/hue_14/brightness_10/2020040415371600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":10,
      "vividness":8,
      "r":71,
      "g":155,
      "b":121,
      "hex":"#479b79",
      "file_location":"assets/colors_organized/hue_14/brightness_10/2020040415371700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":10,
      "vividness":9,
      "r":60,
      "g":154,
      "b":117,
      "hex":"#3c9a75",
      "file_location":"assets/colors_organized/hue_14/brightness_10/2020040415371701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":10,
      "vividness":10,
      "r":49,
      "g":154,
      "b":113,
      "hex":"#319a71",
      "file_location":"assets/colors_organized/hue_14/brightness_10/2020040415371800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":10,
      "vividness":11,
      "r":41,
      "g":154,
      "b":108,
      "hex":"#299a6c",
      "file_location":"assets/colors_organized/hue_14/brightness_10/2020040415371801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":10,
      "vividness":12,
      "r":30,
      "g":154,
      "b":104,
      "hex":"#1e9a68",
      "file_location":"assets/colors_organized/hue_14/brightness_10/2020040415371900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":10,
      "vividness":13,
      "r":20,
      "g":154,
      "b":101,
      "hex":"#149a65",
      "file_location":"assets/colors_organized/hue_14/brightness_10/2020040415371901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":10,
      "vividness":14,
      "r":9,
      "g":154,
      "b":97,
      "hex":"#099a61",
      "file_location":"assets/colors_organized/hue_14/brightness_10/2020040415372100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":11,
      "vividness":1,
      "r":158,
      "g":169,
      "b":165,
      "hex":"#9ea9a5",
      "file_location":"assets/colors_organized/hue_14/brightness_11/2020040415370301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":11,
      "vividness":2,
      "r":148,
      "g":169,
      "b":160,
      "hex":"#94a9a0",
      "file_location":"assets/colors_organized/hue_14/brightness_11/2020040415370302-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":11,
      "vividness":3,
      "r":135,
      "g":169,
      "b":155,
      "hex":"#87a99b",
      "file_location":"assets/colors_organized/hue_14/brightness_11/2020040415370400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":11,
      "vividness":4,
      "r":125,
      "g":169,
      "b":152,
      "hex":"#7da998",
      "file_location":"assets/colors_organized/hue_14/brightness_11/2020040415370401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":11,
      "vividness":5,
      "r":114,
      "g":169,
      "b":146,
      "hex":"#72a992",
      "file_location":"assets/colors_organized/hue_14/brightness_11/2020040415370500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":11,
      "vividness":6,
      "r":101,
      "g":170,
      "b":142,
      "hex":"#65aa8e",
      "file_location":"assets/colors_organized/hue_14/brightness_11/2020040415370501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":11,
      "vividness":7,
      "r":90,
      "g":169,
      "b":138,
      "hex":"#5aa98a",
      "file_location":"assets/colors_organized/hue_14/brightness_11/2020040415370600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":11,
      "vividness":8,
      "r":79,
      "g":169,
      "b":133,
      "hex":"#4fa985",
      "file_location":"assets/colors_organized/hue_14/brightness_11/2020040415370601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":11,
      "vividness":9,
      "r":67,
      "g":169,
      "b":129,
      "hex":"#43a981",
      "file_location":"assets/colors_organized/hue_14/brightness_11/2020040415370700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":11,
      "vividness":10,
      "r":56,
      "g":169,
      "b":123,
      "hex":"#38a97b",
      "file_location":"assets/colors_organized/hue_14/brightness_11/2020040415370701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":11,
      "vividness":11,
      "r":45,
      "g":169,
      "b":119,
      "hex":"#2da977",
      "file_location":"assets/colors_organized/hue_14/brightness_11/2020040415370800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":11,
      "vividness":12,
      "r":32,
      "g":169,
      "b":115,
      "hex":"#20a973",
      "file_location":"assets/colors_organized/hue_14/brightness_11/2020040415370801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":11,
      "vividness":13,
      "r":21,
      "g":169,
      "b":109,
      "hex":"#15a96d",
      "file_location":"assets/colors_organized/hue_14/brightness_11/2020040415370900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":11,
      "vividness":14,
      "r":9,
      "g":169,
      "b":105,
      "hex":"#09a969",
      "file_location":"assets/colors_organized/hue_14/brightness_11/2020040415370901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":12,
      "vividness":1,
      "r":172,
      "g":185,
      "b":178,
      "hex":"#acb9b2",
      "file_location":"assets/colors_organized/hue_14/brightness_12/2020040415365200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":12,
      "vividness":2,
      "r":161,
      "g":184,
      "b":174,
      "hex":"#a1b8ae",
      "file_location":"assets/colors_organized/hue_14/brightness_12/2020040415365201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":12,
      "vividness":3,
      "r":147,
      "g":183,
      "b":169,
      "hex":"#93b7a9",
      "file_location":"assets/colors_organized/hue_14/brightness_12/2020040415365300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":12,
      "vividness":4,
      "r":135,
      "g":184,
      "b":163,
      "hex":"#87b8a3",
      "file_location":"assets/colors_organized/hue_14/brightness_12/2020040415365301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":12,
      "vividness":5,
      "r":122,
      "g":184,
      "b":159,
      "hex":"#7ab89f",
      "file_location":"assets/colors_organized/hue_14/brightness_12/2020040415365400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":12,
      "vividness":6,
      "r":110,
      "g":185,
      "b":155,
      "hex":"#6eb99b",
      "file_location":"assets/colors_organized/hue_14/brightness_12/2020040415365401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":12,
      "vividness":7,
      "r":97,
      "g":184,
      "b":149,
      "hex":"#61b895",
      "file_location":"assets/colors_organized/hue_14/brightness_12/2020040415365500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":12,
      "vividness":8,
      "r":86,
      "g":184,
      "b":145,
      "hex":"#56b891",
      "file_location":"assets/colors_organized/hue_14/brightness_12/2020040415365501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":12,
      "vividness":9,
      "r":73,
      "g":184,
      "b":141,
      "hex":"#49b88d",
      "file_location":"assets/colors_organized/hue_14/brightness_12/2020040415365600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":12,
      "vividness":10,
      "r":61,
      "g":185,
      "b":135,
      "hex":"#3db987",
      "file_location":"assets/colors_organized/hue_14/brightness_12/2020040415365601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":12,
      "vividness":11,
      "r":47,
      "g":184,
      "b":130,
      "hex":"#2fb882",
      "file_location":"assets/colors_organized/hue_14/brightness_12/2020040415365602-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":12,
      "vividness":12,
      "r":35,
      "g":185,
      "b":124,
      "hex":"#23b97c",
      "file_location":"assets/colors_organized/hue_14/brightness_12/2020040415365700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":12,
      "vividness":13,
      "r":22,
      "g":185,
      "b":120,
      "hex":"#16b978",
      "file_location":"assets/colors_organized/hue_14/brightness_12/2020040415365701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":12,
      "vividness":14,
      "r":13,
      "g":184,
      "b":114,
      "hex":"#0db872",
      "file_location":"assets/colors_organized/hue_14/brightness_12/2020040415365800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":13,
      "vividness":1,
      "r":187,
      "g":199,
      "b":195,
      "hex":"#bbc7c3",
      "file_location":"assets/colors_organized/hue_14/brightness_13/2020040415364200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":13,
      "vividness":2,
      "r":173,
      "g":199,
      "b":188,
      "hex":"#adc7bc",
      "file_location":"assets/colors_organized/hue_14/brightness_13/2020040415364201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":13,
      "vividness":3,
      "r":159,
      "g":200,
      "b":184,
      "hex":"#9fc8b8",
      "file_location":"assets/colors_organized/hue_14/brightness_13/2020040415364202-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":13,
      "vividness":4,
      "r":146,
      "g":200,
      "b":177,
      "hex":"#92c8b1",
      "file_location":"assets/colors_organized/hue_14/brightness_13/2020040415364300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":13,
      "vividness":5,
      "r":134,
      "g":200,
      "b":173,
      "hex":"#86c8ad",
      "file_location":"assets/colors_organized/hue_14/brightness_13/2020040415364301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":13,
      "vividness":6,
      "r":119,
      "g":200,
      "b":167,
      "hex":"#77c8a7",
      "file_location":"assets/colors_organized/hue_14/brightness_13/2020040415364400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":13,
      "vividness":7,
      "r":105,
      "g":199,
      "b":162,
      "hex":"#69c7a2",
      "file_location":"assets/colors_organized/hue_14/brightness_13/2020040415364401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":13,
      "vividness":8,
      "r":93,
      "g":200,
      "b":158,
      "hex":"#5dc89e",
      "file_location":"assets/colors_organized/hue_14/brightness_13/2020040415364500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":13,
      "vividness":9,
      "r":78,
      "g":200,
      "b":151,
      "hex":"#4ec897",
      "file_location":"assets/colors_organized/hue_14/brightness_13/2020040415364501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":13,
      "vividness":10,
      "r":65,
      "g":199,
      "b":146,
      "hex":"#41c792",
      "file_location":"assets/colors_organized/hue_14/brightness_13/2020040415364600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":13,
      "vividness":11,
      "r":52,
      "g":200,
      "b":142,
      "hex":"#34c88e",
      "file_location":"assets/colors_organized/hue_14/brightness_13/2020040415364601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":13,
      "vividness":12,
      "r":39,
      "g":199,
      "b":135,
      "hex":"#27c787",
      "file_location":"assets/colors_organized/hue_14/brightness_13/2020040415364700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":13,
      "vividness":13,
      "r":26,
      "g":200,
      "b":129,
      "hex":"#1ac881",
      "file_location":"assets/colors_organized/hue_14/brightness_13/2020040415364701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":13,
      "vividness":14,
      "r":12,
      "g":200,
      "b":124,
      "hex":"#0cc87c",
      "file_location":"assets/colors_organized/hue_14/brightness_13/2020040415364800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":14,
      "vividness":1,
      "r":200,
      "g":215,
      "b":208,
      "hex":"#c8d7d0",
      "file_location":"assets/colors_organized/hue_14/brightness_14/2020040415363100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":14,
      "vividness":2,
      "r":184,
      "g":214,
      "b":202,
      "hex":"#b8d6ca",
      "file_location":"assets/colors_organized/hue_14/brightness_14/2020040415363101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":14,
      "vividness":3,
      "r":172,
      "g":215,
      "b":198,
      "hex":"#acd7c6",
      "file_location":"assets/colors_organized/hue_14/brightness_14/2020040415363102-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":14,
      "vividness":4,
      "r":157,
      "g":215,
      "b":191,
      "hex":"#9dd7bf",
      "file_location":"assets/colors_organized/hue_14/brightness_14/2020040415363200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":14,
      "vividness":5,
      "r":144,
      "g":215,
      "b":185,
      "hex":"#90d7b9",
      "file_location":"assets/colors_organized/hue_14/brightness_14/2020040415363201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":14,
      "vividness":6,
      "r":129,
      "g":215,
      "b":180,
      "hex":"#81d7b4",
      "file_location":"assets/colors_organized/hue_14/brightness_14/2020040415363300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":14,
      "vividness":7,
      "r":114,
      "g":215,
      "b":175,
      "hex":"#72d7af",
      "file_location":"assets/colors_organized/hue_14/brightness_14/2020040415363301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":14,
      "vividness":8,
      "r":99,
      "g":215,
      "b":168,
      "hex":"#63d7a8",
      "file_location":"assets/colors_organized/hue_14/brightness_14/2020040415363400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":14,
      "vividness":9,
      "r":84,
      "g":215,
      "b":163,
      "hex":"#54d7a3",
      "file_location":"assets/colors_organized/hue_14/brightness_14/2020040415363401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":14,
      "vividness":10,
      "r":71,
      "g":215,
      "b":156,
      "hex":"#47d79c",
      "file_location":"assets/colors_organized/hue_14/brightness_14/2020040415363500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":14,
      "vividness":11,
      "r":56,
      "g":215,
      "b":151,
      "hex":"#38d797",
      "file_location":"assets/colors_organized/hue_14/brightness_14/2020040415363501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":14,
      "vividness":12,
      "r":41,
      "g":215,
      "b":144,
      "hex":"#29d790",
      "file_location":"assets/colors_organized/hue_14/brightness_14/2020040415363600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":14,
      "vividness":13,
      "r":27,
      "g":215,
      "b":139,
      "hex":"#1bd78b",
      "file_location":"assets/colors_organized/hue_14/brightness_14/2020040415363601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":14,
      "vividness":14,
      "r":12,
      "g":215,
      "b":134,
      "hex":"#0cd786",
      "file_location":"assets/colors_organized/hue_14/brightness_14/2020040415363700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":15,
      "vividness":1,
      "r":214,
      "g":229,
      "b":222,
      "hex":"#d6e5de",
      "file_location":"assets/colors_organized/hue_14/brightness_15/2020040415362000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":15,
      "vividness":2,
      "r":198,
      "g":230,
      "b":217,
      "hex":"#c6e6d9",
      "file_location":"assets/colors_organized/hue_14/brightness_15/2020040415362001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":15,
      "vividness":3,
      "r":183,
      "g":230,
      "b":212,
      "hex":"#b7e6d4",
      "file_location":"assets/colors_organized/hue_14/brightness_15/2020040415362100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":15,
      "vividness":4,
      "r":167,
      "g":229,
      "b":204,
      "hex":"#a7e5cc",
      "file_location":"assets/colors_organized/hue_14/brightness_15/2020040415362101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":15,
      "vividness":5,
      "r":153,
      "g":230,
      "b":198,
      "hex":"#99e6c6",
      "file_location":"assets/colors_organized/hue_14/brightness_15/2020040415362200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":15,
      "vividness":6,
      "r":138,
      "g":230,
      "b":193,
      "hex":"#8ae6c1",
      "file_location":"assets/colors_organized/hue_14/brightness_15/2020040415362201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":15,
      "vividness":7,
      "r":122,
      "g":229,
      "b":185,
      "hex":"#7ae5b9",
      "file_location":"assets/colors_organized/hue_14/brightness_15/2020040415362300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":15,
      "vividness":8,
      "r":107,
      "g":229,
      "b":180,
      "hex":"#6be5b4",
      "file_location":"assets/colors_organized/hue_14/brightness_15/2020040415362301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":15,
      "vividness":9,
      "r":91,
      "g":230,
      "b":175,
      "hex":"#5be6af",
      "file_location":"assets/colors_organized/hue_14/brightness_15/2020040415362302-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":15,
      "vividness":10,
      "r":76,
      "g":230,
      "b":168,
      "hex":"#4ce6a8",
      "file_location":"assets/colors_organized/hue_14/brightness_15/2020040415362400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":15,
      "vividness":11,
      "r":60,
      "g":229,
      "b":162,
      "hex":"#3ce5a2",
      "file_location":"assets/colors_organized/hue_14/brightness_15/2020040415362401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":15,
      "vividness":12,
      "r":44,
      "g":230,
      "b":155,
      "hex":"#2ce69b",
      "file_location":"assets/colors_organized/hue_14/brightness_15/2020040415362500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":15,
      "vividness":13,
      "r":29,
      "g":231,
      "b":149,
      "hex":"#1de795",
      "file_location":"assets/colors_organized/hue_14/brightness_15/2020040415362501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":14,
      "brightness":15,
      "vividness":14,
      "r":13,
      "g":230,
      "b":143,
      "hex":"#0de68f",
      "file_location":"assets/colors_organized/hue_14/brightness_15/2020040415362600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":1,
      "vividness":1,
      "r":14,
      "g":20,
      "b":20,
      "hex":"#0e1414",
      "file_location":"assets/colors_organized/hue_15/brightness_01/2020040415431101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":1,
      "vividness":2,
      "r":11,
      "g":20,
      "b":19,
      "hex":"#0b1413",
      "file_location":"assets/colors_organized/hue_15/brightness_01/2020040415431200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":1,
      "vividness":3,
      "r":9,
      "g":19,
      "b":18,
      "hex":"#091312",
      "file_location":"assets/colors_organized/hue_15/brightness_01/2020040415431301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":1,
      "vividness":4,
      "r":7,
      "g":19,
      "b":17,
      "hex":"#071311",
      "file_location":"assets/colors_organized/hue_15/brightness_01/2020040415431401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":1,
      "vividness":5,
      "r":3,
      "g":19,
      "b":18,
      "hex":"#031312",
      "file_location":"assets/colors_organized/hue_15/brightness_01/2020040415431601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":1,
      "vividness":6,
      "r":3,
      "g":20,
      "b":14,
      "hex":"#03140e",
      "file_location":"assets/colors_organized/hue_15/brightness_01/2020040415431700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":1,
      "vividness":7,
      "r":0,
      "g":20,
      "b":15,
      "hex":"#00140f",
      "file_location":"assets/colors_organized/hue_15/brightness_01/2020040415431701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":2,
      "vividness":1,
      "r":26,
      "g":35,
      "b":34,
      "hex":"#1a2322",
      "file_location":"assets/colors_organized/hue_15/brightness_02/2020040415430001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":2,
      "vividness":2,
      "r":24,
      "g":34,
      "b":33,
      "hex":"#182221",
      "file_location":"assets/colors_organized/hue_15/brightness_02/2020040415430100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":2,
      "vividness":3,
      "r":23,
      "g":35,
      "b":31,
      "hex":"#17231f",
      "file_location":"assets/colors_organized/hue_15/brightness_02/2020040415430101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":2,
      "vividness":4,
      "r":22,
      "g":34,
      "b":32,
      "hex":"#162220",
      "file_location":"assets/colors_organized/hue_15/brightness_02/2020040415430200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":2,
      "vividness":5,
      "r":18,
      "g":34,
      "b":31,
      "hex":"#12221f",
      "file_location":"assets/colors_organized/hue_15/brightness_02/2020040415430300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":2,
      "vividness":6,
      "r":14,
      "g":35,
      "b":30,
      "hex":"#0e231e",
      "file_location":"assets/colors_organized/hue_15/brightness_02/2020040415430301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":2,
      "vividness":7,
      "r":12,
      "g":34,
      "b":31,
      "hex":"#0c221f",
      "file_location":"assets/colors_organized/hue_15/brightness_02/2020040415430400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":2,
      "vividness":8,
      "r":9,
      "g":34,
      "b":30,
      "hex":"#09221e",
      "file_location":"assets/colors_organized/hue_15/brightness_02/2020040415430401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":2,
      "vividness":9,
      "r":6,
      "g":35,
      "b":30,
      "hex":"#06231e",
      "file_location":"assets/colors_organized/hue_15/brightness_02/2020040415430501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":2,
      "vividness":10,
      "r":2,
      "g":34,
      "b":29,
      "hex":"#02221d",
      "file_location":"assets/colors_organized/hue_15/brightness_02/2020040415430600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":2,
      "vividness":11,
      "r":2,
      "g":35,
      "b":28,
      "hex":"#02231c",
      "file_location":"assets/colors_organized/hue_15/brightness_02/2020040415430601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":3,
      "vividness":1,
      "r":39,
      "g":49,
      "b":48,
      "hex":"#273130",
      "file_location":"assets/colors_organized/hue_15/brightness_03/2020040415425000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":3,
      "vividness":2,
      "r":35,
      "g":50,
      "b":47,
      "hex":"#23322f",
      "file_location":"assets/colors_organized/hue_15/brightness_03/2020040415425100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":3,
      "vividness":3,
      "r":33,
      "g":49,
      "b":46,
      "hex":"#21312e",
      "file_location":"assets/colors_organized/hue_15/brightness_03/2020040415425101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":3,
      "vividness":4,
      "r":29,
      "g":50,
      "b":45,
      "hex":"#1d322d",
      "file_location":"assets/colors_organized/hue_15/brightness_03/2020040415425200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":3,
      "vividness":5,
      "r":27,
      "g":50,
      "b":44,
      "hex":"#1b322c",
      "file_location":"assets/colors_organized/hue_15/brightness_03/2020040415425201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":3,
      "vividness":6,
      "r":23,
      "g":50,
      "b":43,
      "hex":"#17322b",
      "file_location":"assets/colors_organized/hue_15/brightness_03/2020040415425300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":3,
      "vividness":7,
      "r":19,
      "g":50,
      "b":44,
      "hex":"#13322c",
      "file_location":"assets/colors_organized/hue_15/brightness_03/2020040415425301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":3,
      "vividness":8,
      "r":14,
      "g":49,
      "b":42,
      "hex":"#0e312a",
      "file_location":"assets/colors_organized/hue_15/brightness_03/2020040415425400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":3,
      "vividness":9,
      "r":13,
      "g":50,
      "b":42,
      "hex":"#0d322a",
      "file_location":"assets/colors_organized/hue_15/brightness_03/2020040415425401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":3,
      "vividness":10,
      "r":10,
      "g":50,
      "b":42,
      "hex":"#0a322a",
      "file_location":"assets/colors_organized/hue_15/brightness_03/2020040415425500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":3,
      "vividness":11,
      "r":7,
      "g":50,
      "b":41,
      "hex":"#073229",
      "file_location":"assets/colors_organized/hue_15/brightness_03/2020040415425501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":3,
      "vividness":12,
      "r":2,
      "g":49,
      "b":39,
      "hex":"#023127",
      "file_location":"assets/colors_organized/hue_15/brightness_03/2020040415425600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":4,
      "vividness":1,
      "r":55,
      "g":66,
      "b":62,
      "hex":"#37423e",
      "file_location":"assets/colors_organized/hue_15/brightness_04/2020040415423901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":4,
      "vividness":2,
      "r":52,
      "g":64,
      "b":60,
      "hex":"#34403c",
      "file_location":"assets/colors_organized/hue_15/brightness_04/2020040415424000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":4,
      "vividness":3,
      "r":46,
      "g":65,
      "b":61,
      "hex":"#2e413d",
      "file_location":"assets/colors_organized/hue_15/brightness_04/2020040415424001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":4,
      "vividness":4,
      "r":43,
      "g":66,
      "b":60,
      "hex":"#2b423c",
      "file_location":"assets/colors_organized/hue_15/brightness_04/2020040415424100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":4,
      "vividness":5,
      "r":38,
      "g":65,
      "b":58,
      "hex":"#26413a",
      "file_location":"assets/colors_organized/hue_15/brightness_04/2020040415424101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":4,
      "vividness":6,
      "r":34,
      "g":65,
      "b":59,
      "hex":"#22413b",
      "file_location":"assets/colors_organized/hue_15/brightness_04/2020040415424200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":4,
      "vividness":7,
      "r":29,
      "g":64,
      "b":57,
      "hex":"#1d4039",
      "file_location":"assets/colors_organized/hue_15/brightness_04/2020040415424201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":4,
      "vividness":8,
      "r":25,
      "g":65,
      "b":56,
      "hex":"#194138",
      "file_location":"assets/colors_organized/hue_15/brightness_04/2020040415424300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":4,
      "vividness":9,
      "r":22,
      "g":65,
      "b":56,
      "hex":"#164138",
      "file_location":"assets/colors_organized/hue_15/brightness_04/2020040415424301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":4,
      "vividness":10,
      "r":18,
      "g":65,
      "b":55,
      "hex":"#124137",
      "file_location":"assets/colors_organized/hue_15/brightness_04/2020040415424400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":4,
      "vividness":11,
      "r":13,
      "g":65,
      "b":53,
      "hex":"#0d4135",
      "file_location":"assets/colors_organized/hue_15/brightness_04/2020040415424401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":4,
      "vividness":12,
      "r":7,
      "g":65,
      "b":53,
      "hex":"#074135",
      "file_location":"assets/colors_organized/hue_15/brightness_04/2020040415424500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":4,
      "vividness":13,
      "r":3,
      "g":65,
      "b":52,
      "hex":"#034134",
      "file_location":"assets/colors_organized/hue_15/brightness_04/2020040415424501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":5,
      "vividness":1,
      "r":69,
      "g":79,
      "b":78,
      "hex":"#454f4e",
      "file_location":"assets/colors_organized/hue_15/brightness_05/2020040415422900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":5,
      "vividness":2,
      "r":64,
      "g":80,
      "b":77,
      "hex":"#40504d",
      "file_location":"assets/colors_organized/hue_15/brightness_05/2020040415422901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":5,
      "vividness":3,
      "r":58,
      "g":81,
      "b":75,
      "hex":"#3a514b",
      "file_location":"assets/colors_organized/hue_15/brightness_05/2020040415423000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":5,
      "vividness":4,
      "r":53,
      "g":80,
      "b":73,
      "hex":"#355049",
      "file_location":"assets/colors_organized/hue_15/brightness_05/2020040415423001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":5,
      "vividness":5,
      "r":47,
      "g":80,
      "b":73,
      "hex":"#2f5049",
      "file_location":"assets/colors_organized/hue_15/brightness_05/2020040415423100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":5,
      "vividness":6,
      "r":40,
      "g":80,
      "b":71,
      "hex":"#285047",
      "file_location":"assets/colors_organized/hue_15/brightness_05/2020040415423101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":5,
      "vividness":7,
      "r":37,
      "g":80,
      "b":71,
      "hex":"#255047",
      "file_location":"assets/colors_organized/hue_15/brightness_05/2020040415423200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":5,
      "vividness":8,
      "r":32,
      "g":79,
      "b":69,
      "hex":"#204f45",
      "file_location":"assets/colors_organized/hue_15/brightness_05/2020040415423201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":5,
      "vividness":9,
      "r":27,
      "g":81,
      "b":68,
      "hex":"#1b5144",
      "file_location":"assets/colors_organized/hue_15/brightness_05/2020040415423300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":5,
      "vividness":10,
      "r":22,
      "g":80,
      "b":68,
      "hex":"#165044",
      "file_location":"assets/colors_organized/hue_15/brightness_05/2020040415423301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":5,
      "vividness":11,
      "r":14,
      "g":80,
      "b":66,
      "hex":"#0e5042",
      "file_location":"assets/colors_organized/hue_15/brightness_05/2020040415423400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":5,
      "vividness":12,
      "r":9,
      "g":80,
      "b":66,
      "hex":"#095042",
      "file_location":"assets/colors_organized/hue_15/brightness_05/2020040415423401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":5,
      "vividness":13,
      "r":6,
      "g":80,
      "b":65,
      "hex":"#065041",
      "file_location":"assets/colors_organized/hue_15/brightness_05/2020040415423500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":6,
      "vividness":1,
      "r":89,
      "g":95,
      "b":93,
      "hex":"#595f5d",
      "file_location":"assets/colors_organized/hue_15/brightness_06/2020040415421800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":6,
      "vividness":2,
      "r":83,
      "g":95,
      "b":93,
      "hex":"#535f5d",
      "file_location":"assets/colors_organized/hue_15/brightness_06/2020040415421801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":6,
      "vividness":3,
      "r":76,
      "g":95,
      "b":91,
      "hex":"#4c5f5b",
      "file_location":"assets/colors_organized/hue_15/brightness_06/2020040415421900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":6,
      "vividness":4,
      "r":68,
      "g":95,
      "b":90,
      "hex":"#445f5a",
      "file_location":"assets/colors_organized/hue_15/brightness_06/2020040415421901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":6,
      "vividness":5,
      "r":62,
      "g":95,
      "b":88,
      "hex":"#3e5f58",
      "file_location":"assets/colors_organized/hue_15/brightness_06/2020040415422000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":6,
      "vividness":6,
      "r":55,
      "g":95,
      "b":86,
      "hex":"#375f56",
      "file_location":"assets/colors_organized/hue_15/brightness_06/2020040415422001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":6,
      "vividness":7,
      "r":49,
      "g":95,
      "b":85,
      "hex":"#315f55",
      "file_location":"assets/colors_organized/hue_15/brightness_06/2020040415422100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":6,
      "vividness":8,
      "r":43,
      "g":95,
      "b":83,
      "hex":"#2b5f53",
      "file_location":"assets/colors_organized/hue_15/brightness_06/2020040415422101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":6,
      "vividness":9,
      "r":37,
      "g":95,
      "b":83,
      "hex":"#255f53",
      "file_location":"assets/colors_organized/hue_15/brightness_06/2020040415422200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":6,
      "vividness":10,
      "r":31,
      "g":95,
      "b":81,
      "hex":"#1f5f51",
      "file_location":"assets/colors_organized/hue_15/brightness_06/2020040415422201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":6,
      "vividness":11,
      "r":24,
      "g":95,
      "b":81,
      "hex":"#185f51",
      "file_location":"assets/colors_organized/hue_15/brightness_06/2020040415422300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":6,
      "vividness":12,
      "r":20,
      "g":94,
      "b":79,
      "hex":"#145e4f",
      "file_location":"assets/colors_organized/hue_15/brightness_06/2020040415422301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":6,
      "vividness":13,
      "r":12,
      "g":95,
      "b":77,
      "hex":"#0c5f4d",
      "file_location":"assets/colors_organized/hue_15/brightness_06/2020040415422302-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":6,
      "vividness":14,
      "r":6,
      "g":95,
      "b":77,
      "hex":"#065f4d",
      "file_location":"assets/colors_organized/hue_15/brightness_06/2020040415422400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":7,
      "vividness":1,
      "r":95,
      "g":110,
      "b":107,
      "hex":"#5f6e6b",
      "file_location":"assets/colors_organized/hue_15/brightness_07/2020040415420702-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":7,
      "vividness":2,
      "r":88,
      "g":111,
      "b":105,
      "hex":"#586f69",
      "file_location":"assets/colors_organized/hue_15/brightness_07/2020040415420800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":7,
      "vividness":3,
      "r":79,
      "g":110,
      "b":104,
      "hex":"#4f6e68",
      "file_location":"assets/colors_organized/hue_15/brightness_07/2020040415420900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":7,
      "vividness":4,
      "r":73,
      "g":110,
      "b":102,
      "hex":"#496e66",
      "file_location":"assets/colors_organized/hue_15/brightness_07/2020040415420901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":7,
      "vividness":5,
      "r":67,
      "g":110,
      "b":101,
      "hex":"#436e65",
      "file_location":"assets/colors_organized/hue_15/brightness_07/2020040415421000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":7,
      "vividness":6,
      "r":58,
      "g":110,
      "b":98,
      "hex":"#3a6e62",
      "file_location":"assets/colors_organized/hue_15/brightness_07/2020040415421001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":7,
      "vividness":7,
      "r":50,
      "g":110,
      "b":98,
      "hex":"#326e62",
      "file_location":"assets/colors_organized/hue_15/brightness_07/2020040415421100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":7,
      "vividness":8,
      "r":43,
      "g":109,
      "b":95,
      "hex":"#2b6d5f",
      "file_location":"assets/colors_organized/hue_15/brightness_07/2020040415421101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":7,
      "vividness":9,
      "r":36,
      "g":110,
      "b":95,
      "hex":"#246e5f",
      "file_location":"assets/colors_organized/hue_15/brightness_07/2020040415421200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":7,
      "vividness":10,
      "r":29,
      "g":110,
      "b":93,
      "hex":"#1d6e5d",
      "file_location":"assets/colors_organized/hue_15/brightness_07/2020040415421201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":7,
      "vividness":11,
      "r":21,
      "g":110,
      "b":92,
      "hex":"#156e5c",
      "file_location":"assets/colors_organized/hue_15/brightness_07/2020040415421300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":7,
      "vividness":12,
      "r":12,
      "g":109,
      "b":89,
      "hex":"#0c6d59",
      "file_location":"assets/colors_organized/hue_15/brightness_07/2020040415421301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":7,
      "vividness":13,
      "r":7,
      "g":110,
      "b":89,
      "hex":"#076e59",
      "file_location":"assets/colors_organized/hue_15/brightness_07/2020040415421400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":8,
      "vividness":1,
      "r":115,
      "g":124,
      "b":123,
      "hex":"#737c7b",
      "file_location":"assets/colors_organized/hue_15/brightness_08/2020040415415201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":8,
      "vividness":2,
      "r":108,
      "g":124,
      "b":121,
      "hex":"#6c7c79",
      "file_location":"assets/colors_organized/hue_15/brightness_08/2020040415415300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":8,
      "vividness":3,
      "r":99,
      "g":124,
      "b":120,
      "hex":"#637c78",
      "file_location":"assets/colors_organized/hue_15/brightness_08/2020040415415301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":8,
      "vividness":4,
      "r":91,
      "g":124,
      "b":117,
      "hex":"#5b7c75",
      "file_location":"assets/colors_organized/hue_15/brightness_08/2020040415415400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":8,
      "vividness":5,
      "r":83,
      "g":124,
      "b":116,
      "hex":"#537c74",
      "file_location":"assets/colors_organized/hue_15/brightness_08/2020040415415401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":8,
      "vividness":6,
      "r":74,
      "g":124,
      "b":113,
      "hex":"#4a7c71",
      "file_location":"assets/colors_organized/hue_15/brightness_08/2020040415415500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":8,
      "vividness":7,
      "r":66,
      "g":124,
      "b":112,
      "hex":"#427c70",
      "file_location":"assets/colors_organized/hue_15/brightness_08/2020040415415501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":8,
      "vividness":8,
      "r":58,
      "g":124,
      "b":112,
      "hex":"#3a7c70",
      "file_location":"assets/colors_organized/hue_15/brightness_08/2020040415415600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":8,
      "vividness":9,
      "r":48,
      "g":125,
      "b":109,
      "hex":"#307d6d",
      "file_location":"assets/colors_organized/hue_15/brightness_08/2020040415415601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":8,
      "vividness":10,
      "r":41,
      "g":124,
      "b":108,
      "hex":"#297c6c",
      "file_location":"assets/colors_organized/hue_15/brightness_08/2020040415415700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":8,
      "vividness":11,
      "r":33,
      "g":124,
      "b":106,
      "hex":"#217c6a",
      "file_location":"assets/colors_organized/hue_15/brightness_08/2020040415415800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":8,
      "vividness":12,
      "r":22,
      "g":124,
      "b":103,
      "hex":"#167c67",
      "file_location":"assets/colors_organized/hue_15/brightness_08/2020040415415801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":8,
      "vividness":13,
      "r":15,
      "g":124,
      "b":103,
      "hex":"#0f7c67",
      "file_location":"assets/colors_organized/hue_15/brightness_08/2020040415415900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":8,
      "vividness":14,
      "r":6,
      "g":124,
      "b":100,
      "hex":"#067c64",
      "file_location":"assets/colors_organized/hue_15/brightness_08/2020040415420000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":9,
      "vividness":1,
      "r":129,
      "g":139,
      "b":138,
      "hex":"#818b8a",
      "file_location":"assets/colors_organized/hue_15/brightness_09/2020040415414101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":9,
      "vividness":2,
      "r":120,
      "g":139,
      "b":135,
      "hex":"#788b87",
      "file_location":"assets/colors_organized/hue_15/brightness_09/2020040415414200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":9,
      "vividness":3,
      "r":110,
      "g":139,
      "b":134,
      "hex":"#6e8b86",
      "file_location":"assets/colors_organized/hue_15/brightness_09/2020040415414201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":9,
      "vividness":4,
      "r":102,
      "g":139,
      "b":132,
      "hex":"#668b84",
      "file_location":"assets/colors_organized/hue_15/brightness_09/2020040415414300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":9,
      "vividness":5,
      "r":92,
      "g":139,
      "b":129,
      "hex":"#5c8b81",
      "file_location":"assets/colors_organized/hue_15/brightness_09/2020040415414301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":9,
      "vividness":6,
      "r":83,
      "g":139,
      "b":128,
      "hex":"#538b80",
      "file_location":"assets/colors_organized/hue_15/brightness_09/2020040415414400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":9,
      "vividness":7,
      "r":73,
      "g":139,
      "b":125,
      "hex":"#498b7d",
      "file_location":"assets/colors_organized/hue_15/brightness_09/2020040415414401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":9,
      "vividness":8,
      "r":65,
      "g":139,
      "b":124,
      "hex":"#418b7c",
      "file_location":"assets/colors_organized/hue_15/brightness_09/2020040415414500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":9,
      "vividness":9,
      "r":55,
      "g":139,
      "b":123,
      "hex":"#378b7b",
      "file_location":"assets/colors_organized/hue_15/brightness_09/2020040415414600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":9,
      "vividness":10,
      "r":46,
      "g":139,
      "b":121,
      "hex":"#2e8b79",
      "file_location":"assets/colors_organized/hue_15/brightness_09/2020040415414601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":9,
      "vividness":11,
      "r":36,
      "g":139,
      "b":118,
      "hex":"#248b76",
      "file_location":"assets/colors_organized/hue_15/brightness_09/2020040415414700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":9,
      "vividness":12,
      "r":26,
      "g":140,
      "b":117,
      "hex":"#1a8c75",
      "file_location":"assets/colors_organized/hue_15/brightness_09/2020040415414701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":9,
      "vividness":13,
      "r":17,
      "g":139,
      "b":114,
      "hex":"#118b72",
      "file_location":"assets/colors_organized/hue_15/brightness_09/2020040415414702-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":9,
      "vividness":14,
      "r":9,
      "g":139,
      "b":113,
      "hex":"#098b71",
      "file_location":"assets/colors_organized/hue_15/brightness_09/2020040415414800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":10,
      "vividness":1,
      "r":144,
      "g":154,
      "b":153,
      "hex":"#909a99",
      "file_location":"assets/colors_organized/hue_15/brightness_10/2020040415413001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":10,
      "vividness":2,
      "r":134,
      "g":155,
      "b":150,
      "hex":"#869b96",
      "file_location":"assets/colors_organized/hue_15/brightness_10/2020040415413100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":10,
      "vividness":3,
      "r":123,
      "g":154,
      "b":148,
      "hex":"#7b9a94",
      "file_location":"assets/colors_organized/hue_15/brightness_10/2020040415413101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":10,
      "vividness":4,
      "r":113,
      "g":154,
      "b":146,
      "hex":"#719a92",
      "file_location":"assets/colors_organized/hue_15/brightness_10/2020040415413200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":10,
      "vividness":5,
      "r":103,
      "g":154,
      "b":145,
      "hex":"#679a91",
      "file_location":"assets/colors_organized/hue_15/brightness_10/2020040415413201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":10,
      "vividness":6,
      "r":92,
      "g":154,
      "b":141,
      "hex":"#5c9a8d",
      "file_location":"assets/colors_organized/hue_15/brightness_10/2020040415413300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":10,
      "vividness":7,
      "r":82,
      "g":154,
      "b":140,
      "hex":"#529a8c",
      "file_location":"assets/colors_organized/hue_15/brightness_10/2020040415413301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":10,
      "vividness":8,
      "r":72,
      "g":155,
      "b":139,
      "hex":"#489b8b",
      "file_location":"assets/colors_organized/hue_15/brightness_10/2020040415413400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":10,
      "vividness":9,
      "r":61,
      "g":154,
      "b":136,
      "hex":"#3d9a88",
      "file_location":"assets/colors_organized/hue_15/brightness_10/2020040415413500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":10,
      "vividness":10,
      "r":51,
      "g":154,
      "b":133,
      "hex":"#339a85",
      "file_location":"assets/colors_organized/hue_15/brightness_10/2020040415413501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":10,
      "vividness":11,
      "r":41,
      "g":155,
      "b":132,
      "hex":"#299b84",
      "file_location":"assets/colors_organized/hue_15/brightness_10/2020040415413600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":10,
      "vividness":12,
      "r":30,
      "g":154,
      "b":130,
      "hex":"#1e9a82",
      "file_location":"assets/colors_organized/hue_15/brightness_10/2020040415413601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":10,
      "vividness":13,
      "r":20,
      "g":154,
      "b":127,
      "hex":"#149a7f",
      "file_location":"assets/colors_organized/hue_15/brightness_10/2020040415413700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":10,
      "vividness":14,
      "r":10,
      "g":155,
      "b":126,
      "hex":"#0a9b7e",
      "file_location":"assets/colors_organized/hue_15/brightness_10/2020040415413701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":11,
      "vividness":1,
      "r":159,
      "g":169,
      "b":168,
      "hex":"#9fa9a8",
      "file_location":"assets/colors_organized/hue_15/brightness_11/2020040415412000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":11,
      "vividness":2,
      "r":147,
      "g":169,
      "b":166,
      "hex":"#93a9a6",
      "file_location":"assets/colors_organized/hue_15/brightness_11/2020040415412001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":11,
      "vividness":3,
      "r":134,
      "g":169,
      "b":162,
      "hex":"#86a9a2",
      "file_location":"assets/colors_organized/hue_15/brightness_11/2020040415412100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":11,
      "vividness":4,
      "r":124,
      "g":170,
      "b":160,
      "hex":"#7caaa0",
      "file_location":"assets/colors_organized/hue_15/brightness_11/2020040415412101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":11,
      "vividness":5,
      "r":113,
      "g":169,
      "b":158,
      "hex":"#71a99e",
      "file_location":"assets/colors_organized/hue_15/brightness_11/2020040415412200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":11,
      "vividness":6,
      "r":101,
      "g":169,
      "b":156,
      "hex":"#65a99c",
      "file_location":"assets/colors_organized/hue_15/brightness_11/2020040415412201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":11,
      "vividness":7,
      "r":91,
      "g":169,
      "b":153,
      "hex":"#5ba999",
      "file_location":"assets/colors_organized/hue_15/brightness_11/2020040415412300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":11,
      "vividness":8,
      "r":78,
      "g":169,
      "b":151,
      "hex":"#4ea997",
      "file_location":"assets/colors_organized/hue_15/brightness_11/2020040415412301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":11,
      "vividness":9,
      "r":66,
      "g":169,
      "b":148,
      "hex":"#42a994",
      "file_location":"assets/colors_organized/hue_15/brightness_11/2020040415412400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":11,
      "vividness":10,
      "r":56,
      "g":170,
      "b":147,
      "hex":"#38aa93",
      "file_location":"assets/colors_organized/hue_15/brightness_11/2020040415412401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":11,
      "vividness":11,
      "r":45,
      "g":169,
      "b":143,
      "hex":"#2da98f",
      "file_location":"assets/colors_organized/hue_15/brightness_11/2020040415412500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":11,
      "vividness":12,
      "r":33,
      "g":170,
      "b":142,
      "hex":"#21aa8e",
      "file_location":"assets/colors_organized/hue_15/brightness_11/2020040415412501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":11,
      "vividness":13,
      "r":21,
      "g":170,
      "b":140,
      "hex":"#15aa8c",
      "file_location":"assets/colors_organized/hue_15/brightness_11/2020040415412600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":11,
      "vividness":14,
      "r":10,
      "g":169,
      "b":137,
      "hex":"#0aa989",
      "file_location":"assets/colors_organized/hue_15/brightness_11/2020040415412601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":12,
      "vividness":1,
      "r":172,
      "g":184,
      "b":182,
      "hex":"#acb8b6",
      "file_location":"assets/colors_organized/hue_15/brightness_12/2020040415411000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":12,
      "vividness":2,
      "r":159,
      "g":184,
      "b":178,
      "hex":"#9fb8b2",
      "file_location":"assets/colors_organized/hue_15/brightness_12/2020040415411001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":12,
      "vividness":3,
      "r":147,
      "g":184,
      "b":177,
      "hex":"#93b8b1",
      "file_location":"assets/colors_organized/hue_15/brightness_12/2020040415411002-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":12,
      "vividness":4,
      "r":134,
      "g":184,
      "b":173,
      "hex":"#86b8ad",
      "file_location":"assets/colors_organized/hue_15/brightness_12/2020040415411100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":12,
      "vividness":5,
      "r":122,
      "g":184,
      "b":171,
      "hex":"#7ab8ab",
      "file_location":"assets/colors_organized/hue_15/brightness_12/2020040415411101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":12,
      "vividness":6,
      "r":110,
      "g":184,
      "b":169,
      "hex":"#6eb8a9",
      "file_location":"assets/colors_organized/hue_15/brightness_12/2020040415411200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":12,
      "vividness":7,
      "r":97,
      "g":184,
      "b":167,
      "hex":"#61b8a7",
      "file_location":"assets/colors_organized/hue_15/brightness_12/2020040415411201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":12,
      "vividness":8,
      "r":85,
      "g":184,
      "b":163,
      "hex":"#55b8a3",
      "file_location":"assets/colors_organized/hue_15/brightness_12/2020040415411300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":12,
      "vividness":9,
      "r":72,
      "g":184,
      "b":162,
      "hex":"#48b8a2",
      "file_location":"assets/colors_organized/hue_15/brightness_12/2020040415411301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":12,
      "vividness":10,
      "r":60,
      "g":184,
      "b":160,
      "hex":"#3cb8a0",
      "file_location":"assets/colors_organized/hue_15/brightness_12/2020040415411400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":12,
      "vividness":11,
      "r":48,
      "g":185,
      "b":157,
      "hex":"#30b99d",
      "file_location":"assets/colors_organized/hue_15/brightness_12/2020040415411401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":12,
      "vividness":12,
      "r":35,
      "g":184,
      "b":154,
      "hex":"#23b89a",
      "file_location":"assets/colors_organized/hue_15/brightness_12/2020040415411500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":12,
      "vividness":13,
      "r":23,
      "g":184,
      "b":151,
      "hex":"#17b897",
      "file_location":"assets/colors_organized/hue_15/brightness_12/2020040415411501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":12,
      "vividness":14,
      "r":13,
      "g":184,
      "b":150,
      "hex":"#0db896",
      "file_location":"assets/colors_organized/hue_15/brightness_12/2020040415411600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":13,
      "vividness":1,
      "r":185,
      "g":200,
      "b":197,
      "hex":"#b9c8c5",
      "file_location":"assets/colors_organized/hue_15/brightness_13/2020040415405901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":13,
      "vividness":2,
      "r":173,
      "g":200,
      "b":195,
      "hex":"#adc8c3",
      "file_location":"assets/colors_organized/hue_15/brightness_13/2020040415410000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":13,
      "vividness":3,
      "r":158,
      "g":199,
      "b":191,
      "hex":"#9ec7bf",
      "file_location":"assets/colors_organized/hue_15/brightness_13/2020040415410001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":13,
      "vividness":4,
      "r":146,
      "g":200,
      "b":187,
      "hex":"#92c8bb",
      "file_location":"assets/colors_organized/hue_15/brightness_13/2020040415410100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":13,
      "vividness":5,
      "r":133,
      "g":199,
      "b":185,
      "hex":"#85c7b9",
      "file_location":"assets/colors_organized/hue_15/brightness_13/2020040415410101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":13,
      "vividness":6,
      "r":118,
      "g":199,
      "b":182,
      "hex":"#76c7b6",
      "file_location":"assets/colors_organized/hue_15/brightness_13/2020040415410102-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":13,
      "vividness":7,
      "r":106,
      "g":199,
      "b":180,
      "hex":"#6ac7b4",
      "file_location":"assets/colors_organized/hue_15/brightness_13/2020040415410200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":13,
      "vividness":8,
      "r":92,
      "g":200,
      "b":177,
      "hex":"#5cc8b1",
      "file_location":"assets/colors_organized/hue_15/brightness_13/2020040415410201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":13,
      "vividness":9,
      "r":80,
      "g":200,
      "b":175,
      "hex":"#50c8af",
      "file_location":"assets/colors_organized/hue_15/brightness_13/2020040415410300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":13,
      "vividness":10,
      "r":65,
      "g":199,
      "b":172,
      "hex":"#41c7ac",
      "file_location":"assets/colors_organized/hue_15/brightness_13/2020040415410301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":13,
      "vividness":11,
      "r":52,
      "g":199,
      "b":170,
      "hex":"#34c7aa",
      "file_location":"assets/colors_organized/hue_15/brightness_13/2020040415410400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":13,
      "vividness":12,
      "r":39,
      "g":200,
      "b":167,
      "hex":"#27c8a7",
      "file_location":"assets/colors_organized/hue_15/brightness_13/2020040415410401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":13,
      "vividness":13,
      "r":26,
      "g":200,
      "b":165,
      "hex":"#1ac8a5",
      "file_location":"assets/colors_organized/hue_15/brightness_13/2020040415410500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":13,
      "vividness":14,
      "r":11,
      "g":200,
      "b":162,
      "hex":"#0bc8a2",
      "file_location":"assets/colors_organized/hue_15/brightness_13/2020040415410501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":14,
      "vividness":1,
      "r":200,
      "g":215,
      "b":212,
      "hex":"#c8d7d4",
      "file_location":"assets/colors_organized/hue_15/brightness_14/2020040415404901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":14,
      "vividness":2,
      "r":185,
      "g":214,
      "b":209,
      "hex":"#b9d6d1",
      "file_location":"assets/colors_organized/hue_15/brightness_14/2020040415405000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":14,
      "vividness":3,
      "r":171,
      "g":214,
      "b":205,
      "hex":"#abd6cd",
      "file_location":"assets/colors_organized/hue_15/brightness_14/2020040415405001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":14,
      "vividness":4,
      "r":157,
      "g":215,
      "b":203,
      "hex":"#9dd7cb",
      "file_location":"assets/colors_organized/hue_15/brightness_14/2020040415405100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":14,
      "vividness":5,
      "r":143,
      "g":215,
      "b":201,
      "hex":"#8fd7c9",
      "file_location":"assets/colors_organized/hue_15/brightness_14/2020040415405101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":14,
      "vividness":6,
      "r":128,
      "g":215,
      "b":198,
      "hex":"#80d7c6",
      "file_location":"assets/colors_organized/hue_15/brightness_14/2020040415405200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":14,
      "vividness":7,
      "r":113,
      "g":215,
      "b":194,
      "hex":"#71d7c2",
      "file_location":"assets/colors_organized/hue_15/brightness_14/2020040415405201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":14,
      "vividness":8,
      "r":99,
      "g":215,
      "b":192,
      "hex":"#63d7c0",
      "file_location":"assets/colors_organized/hue_15/brightness_14/2020040415405300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":14,
      "vividness":9,
      "r":85,
      "g":215,
      "b":189,
      "hex":"#55d7bd",
      "file_location":"assets/colors_organized/hue_15/brightness_14/2020040415405301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":14,
      "vividness":10,
      "r":70,
      "g":215,
      "b":184,
      "hex":"#46d7b8",
      "file_location":"assets/colors_organized/hue_15/brightness_14/2020040415405302-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":14,
      "vividness":11,
      "r":56,
      "g":215,
      "b":183,
      "hex":"#38d7b7",
      "file_location":"assets/colors_organized/hue_15/brightness_14/2020040415405400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":14,
      "vividness":12,
      "r":41,
      "g":215,
      "b":180,
      "hex":"#29d7b4",
      "file_location":"assets/colors_organized/hue_15/brightness_14/2020040415405401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":14,
      "vividness":13,
      "r":26,
      "g":215,
      "b":177,
      "hex":"#1ad7b1",
      "file_location":"assets/colors_organized/hue_15/brightness_14/2020040415405500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":14,
      "vividness":14,
      "r":12,
      "g":214,
      "b":174,
      "hex":"#0cd6ae",
      "file_location":"assets/colors_organized/hue_15/brightness_14/2020040415405501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":15,
      "vividness":1,
      "r":215,
      "g":230,
      "b":227,
      "hex":"#d7e6e3",
      "file_location":"assets/colors_organized/hue_15/brightness_15/2020040415403801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":15,
      "vividness":2,
      "r":199,
      "g":230,
      "b":224,
      "hex":"#c7e6e0",
      "file_location":"assets/colors_organized/hue_15/brightness_15/2020040415403900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":15,
      "vividness":3,
      "r":183,
      "g":230,
      "b":220,
      "hex":"#b7e6dc",
      "file_location":"assets/colors_organized/hue_15/brightness_15/2020040415403901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":15,
      "vividness":4,
      "r":168,
      "g":230,
      "b":217,
      "hex":"#a8e6d9",
      "file_location":"assets/colors_organized/hue_15/brightness_15/2020040415404000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":15,
      "vividness":5,
      "r":152,
      "g":230,
      "b":214,
      "hex":"#98e6d6",
      "file_location":"assets/colors_organized/hue_15/brightness_15/2020040415404001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":15,
      "vividness":6,
      "r":137,
      "g":230,
      "b":211,
      "hex":"#89e6d3",
      "file_location":"assets/colors_organized/hue_15/brightness_15/2020040415404100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":15,
      "vividness":7,
      "r":122,
      "g":229,
      "b":209,
      "hex":"#7ae5d1",
      "file_location":"assets/colors_organized/hue_15/brightness_15/2020040415404200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":15,
      "vividness":8,
      "r":107,
      "g":229,
      "b":206,
      "hex":"#6be5ce",
      "file_location":"assets/colors_organized/hue_15/brightness_15/2020040415404201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":15,
      "vividness":9,
      "r":91,
      "g":230,
      "b":201,
      "hex":"#5be6c9",
      "file_location":"assets/colors_organized/hue_15/brightness_15/2020040415404300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":15,
      "vividness":10,
      "r":76,
      "g":229,
      "b":198,
      "hex":"#4ce5c6",
      "file_location":"assets/colors_organized/hue_15/brightness_15/2020040415404301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":15,
      "vividness":11,
      "r":60,
      "g":230,
      "b":194,
      "hex":"#3ce6c2",
      "file_location":"assets/colors_organized/hue_15/brightness_15/2020040415404400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":15,
      "vividness":12,
      "r":44,
      "g":230,
      "b":193,
      "hex":"#2ce6c1",
      "file_location":"assets/colors_organized/hue_15/brightness_15/2020040415404401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":15,
      "vividness":13,
      "r":29,
      "g":230,
      "b":188,
      "hex":"#1de6bc",
      "file_location":"assets/colors_organized/hue_15/brightness_15/2020040415404500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":15,
      "brightness":15,
      "vividness":14,
      "r":14,
      "g":229,
      "b":187,
      "hex":"#0ee5bb",
      "file_location":"assets/colors_organized/hue_15/brightness_15/2020040415404501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":1,
      "vividness":1,
      "r":10,
      "g":20,
      "b":21,
      "hex":"#0a1415",
      "file_location":"assets/colors_organized/hue_16/brightness_01/2020040415460501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":1,
      "vividness":2,
      "r":6,
      "g":20,
      "b":20,
      "hex":"#061414",
      "file_location":"assets/colors_organized/hue_16/brightness_01/2020040415460601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":1,
      "vividness":3,
      "r":1,
      "g":19,
      "b":19,
      "hex":"#011313",
      "file_location":"assets/colors_organized/hue_16/brightness_01/2020040415460800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":1,
      "vividness":4,
      "r":0,
      "g":20,
      "b":19,
      "hex":"#001413",
      "file_location":"assets/colors_organized/hue_16/brightness_01/2020040415460802-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":2,
      "vividness":1,
      "r":29,
      "g":35,
      "b":35,
      "hex":"#1d2323",
      "file_location":"assets/colors_organized/hue_16/brightness_02/2020040415455201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":2,
      "vividness":2,
      "r":25,
      "g":35,
      "b":36,
      "hex":"#192324",
      "file_location":"assets/colors_organized/hue_16/brightness_02/2020040415455301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":2,
      "vividness":3,
      "r":23,
      "g":35,
      "b":35,
      "hex":"#172323",
      "file_location":"assets/colors_organized/hue_16/brightness_02/2020040415455400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":2,
      "vividness":4,
      "r":21,
      "g":35,
      "b":35,
      "hex":"#152323",
      "file_location":"assets/colors_organized/hue_16/brightness_02/2020040415455401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":2,
      "vividness":5,
      "r":16,
      "g":34,
      "b":34,
      "hex":"#102222",
      "file_location":"assets/colors_organized/hue_16/brightness_02/2020040415455500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":2,
      "vividness":6,
      "r":15,
      "g":35,
      "b":34,
      "hex":"#0f2322",
      "file_location":"assets/colors_organized/hue_16/brightness_02/2020040415455501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":2,
      "vividness":7,
      "r":13,
      "g":34,
      "b":35,
      "hex":"#0d2223",
      "file_location":"assets/colors_organized/hue_16/brightness_02/2020040415455600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":2,
      "vividness":8,
      "r":9,
      "g":35,
      "b":34,
      "hex":"#092322",
      "file_location":"assets/colors_organized/hue_16/brightness_02/2020040415455601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":2,
      "vividness":9,
      "r":6,
      "g":34,
      "b":35,
      "hex":"#062223",
      "file_location":"assets/colors_organized/hue_16/brightness_02/2020040415455701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":2,
      "vividness":10,
      "r":3,
      "g":35,
      "b":34,
      "hex":"#032322",
      "file_location":"assets/colors_organized/hue_16/brightness_02/2020040415455800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":3,
      "vividness":1,
      "r":45,
      "g":49,
      "b":50,
      "hex":"#2d3132",
      "file_location":"assets/colors_organized/hue_16/brightness_03/2020040415454101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":3,
      "vividness":2,
      "r":41,
      "g":50,
      "b":49,
      "hex":"#293231",
      "file_location":"assets/colors_organized/hue_16/brightness_03/2020040415454102-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":3,
      "vividness":3,
      "r":40,
      "g":50,
      "b":51,
      "hex":"#283233",
      "file_location":"assets/colors_organized/hue_16/brightness_03/2020040415454200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":3,
      "vividness":4,
      "r":35,
      "g":49,
      "b":49,
      "hex":"#233131",
      "file_location":"assets/colors_organized/hue_16/brightness_03/2020040415454300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":3,
      "vividness":5,
      "r":34,
      "g":50,
      "b":50,
      "hex":"#223232",
      "file_location":"assets/colors_organized/hue_16/brightness_03/2020040415454301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":3,
      "vividness":6,
      "r":30,
      "g":50,
      "b":49,
      "hex":"#1e3231",
      "file_location":"assets/colors_organized/hue_16/brightness_03/2020040415454400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":3,
      "vividness":7,
      "r":26,
      "g":50,
      "b":50,
      "hex":"#1a3232",
      "file_location":"assets/colors_organized/hue_16/brightness_03/2020040415454401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":3,
      "vividness":8,
      "r":21,
      "g":50,
      "b":48,
      "hex":"#153230",
      "file_location":"assets/colors_organized/hue_16/brightness_03/2020040415454500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":3,
      "vividness":9,
      "r":20,
      "g":50,
      "b":50,
      "hex":"#143232",
      "file_location":"assets/colors_organized/hue_16/brightness_03/2020040415454501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":3,
      "vividness":10,
      "r":14,
      "g":50,
      "b":50,
      "hex":"#0e3232",
      "file_location":"assets/colors_organized/hue_16/brightness_03/2020040415454600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":3,
      "vividness":11,
      "r":11,
      "g":50,
      "b":49,
      "hex":"#0b3231",
      "file_location":"assets/colors_organized/hue_16/brightness_03/2020040415454601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":3,
      "vividness":12,
      "r":10,
      "g":50,
      "b":50,
      "hex":"#0a3232",
      "file_location":"assets/colors_organized/hue_16/brightness_03/2020040415454700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":3,
      "vividness":13,
      "r":6,
      "g":50,
      "b":49,
      "hex":"#063231",
      "file_location":"assets/colors_organized/hue_16/brightness_03/2020040415454701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":3,
      "vividness":14,
      "r":1,
      "g":49,
      "b":49,
      "hex":"#013131",
      "file_location":"assets/colors_organized/hue_16/brightness_03/2020040415454800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":4,
      "vividness":1,
      "r":59,
      "g":65,
      "b":65,
      "hex":"#3b4141",
      "file_location":"assets/colors_organized/hue_16/brightness_04/2020040415453001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":4,
      "vividness":2,
      "r":55,
      "g":65,
      "b":64,
      "hex":"#374140",
      "file_location":"assets/colors_organized/hue_16/brightness_04/2020040415453100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":4,
      "vividness":3,
      "r":51,
      "g":65,
      "b":65,
      "hex":"#334141",
      "file_location":"assets/colors_organized/hue_16/brightness_04/2020040415453101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":4,
      "vividness":4,
      "r":46,
      "g":64,
      "b":64,
      "hex":"#2e4040",
      "file_location":"assets/colors_organized/hue_16/brightness_04/2020040415453200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":4,
      "vividness":5,
      "r":43,
      "g":64,
      "b":65,
      "hex":"#2b4041",
      "file_location":"assets/colors_organized/hue_16/brightness_04/2020040415453201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":4,
      "vividness":6,
      "r":39,
      "g":65,
      "b":64,
      "hex":"#274140",
      "file_location":"assets/colors_organized/hue_16/brightness_04/2020040415453300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":4,
      "vividness":7,
      "r":33,
      "g":65,
      "b":64,
      "hex":"#214140",
      "file_location":"assets/colors_organized/hue_16/brightness_04/2020040415453301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":4,
      "vividness":8,
      "r":29,
      "g":65,
      "b":65,
      "hex":"#1d4141",
      "file_location":"assets/colors_organized/hue_16/brightness_04/2020040415453400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":4,
      "vividness":9,
      "r":26,
      "g":65,
      "b":64,
      "hex":"#1a4140",
      "file_location":"assets/colors_organized/hue_16/brightness_04/2020040415453401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":4,
      "vividness":10,
      "r":21,
      "g":65,
      "b":64,
      "hex":"#154140",
      "file_location":"assets/colors_organized/hue_16/brightness_04/2020040415453500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":4,
      "vividness":11,
      "r":17,
      "g":65,
      "b":65,
      "hex":"#114141",
      "file_location":"assets/colors_organized/hue_16/brightness_04/2020040415453501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":4,
      "vividness":12,
      "r":13,
      "g":64,
      "b":65,
      "hex":"#0d4041",
      "file_location":"assets/colors_organized/hue_16/brightness_04/2020040415453600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":4,
      "vividness":13,
      "r":6,
      "g":64,
      "b":65,
      "hex":"#064041",
      "file_location":"assets/colors_organized/hue_16/brightness_04/2020040415453601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":4,
      "vividness":14,
      "r":3,
      "g":65,
      "b":64,
      "hex":"#034140",
      "file_location":"assets/colors_organized/hue_16/brightness_04/2020040415453700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":5,
      "vividness":1,
      "r":74,
      "g":80,
      "b":80,
      "hex":"#4a5050",
      "file_location":"assets/colors_organized/hue_16/brightness_05/2020040415451901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":5,
      "vividness":2,
      "r":69,
      "g":81,
      "b":81,
      "hex":"#455151",
      "file_location":"assets/colors_organized/hue_16/brightness_05/2020040415452000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":5,
      "vividness":3,
      "r":64,
      "g":80,
      "b":80,
      "hex":"#405050",
      "file_location":"assets/colors_organized/hue_16/brightness_05/2020040415452001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":5,
      "vividness":4,
      "r":58,
      "g":79,
      "b":80,
      "hex":"#3a4f50",
      "file_location":"assets/colors_organized/hue_16/brightness_05/2020040415452100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":5,
      "vividness":5,
      "r":52,
      "g":81,
      "b":79,
      "hex":"#34514f",
      "file_location":"assets/colors_organized/hue_16/brightness_05/2020040415452101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":5,
      "vividness":6,
      "r":48,
      "g":80,
      "b":79,
      "hex":"#30504f",
      "file_location":"assets/colors_organized/hue_16/brightness_05/2020040415452200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":5,
      "vividness":7,
      "r":41,
      "g":80,
      "b":79,
      "hex":"#29504f",
      "file_location":"assets/colors_organized/hue_16/brightness_05/2020040415452300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":5,
      "vividness":8,
      "r":36,
      "g":80,
      "b":79,
      "hex":"#24504f",
      "file_location":"assets/colors_organized/hue_16/brightness_05/2020040415452301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":5,
      "vividness":9,
      "r":31,
      "g":79,
      "b":79,
      "hex":"#1f4f4f",
      "file_location":"assets/colors_organized/hue_16/brightness_05/2020040415452400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":5,
      "vividness":10,
      "r":26,
      "g":80,
      "b":80,
      "hex":"#1a5050",
      "file_location":"assets/colors_organized/hue_16/brightness_05/2020040415452401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":5,
      "vividness":11,
      "r":21,
      "g":79,
      "b":80,
      "hex":"#154f50",
      "file_location":"assets/colors_organized/hue_16/brightness_05/2020040415452500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":5,
      "vividness":12,
      "r":14,
      "g":80,
      "b":79,
      "hex":"#0e504f",
      "file_location":"assets/colors_organized/hue_16/brightness_05/2020040415452501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":5,
      "vividness":13,
      "r":10,
      "g":80,
      "b":80,
      "hex":"#0a5050",
      "file_location":"assets/colors_organized/hue_16/brightness_05/2020040415452600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":5,
      "vividness":14,
      "r":6,
      "g":80,
      "b":79,
      "hex":"#06504f",
      "file_location":"assets/colors_organized/hue_16/brightness_05/2020040415452601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":6,
      "vividness":1,
      "r":87,
      "g":96,
      "b":95,
      "hex":"#57605f",
      "file_location":"assets/colors_organized/hue_16/brightness_06/2020040415450801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":6,
      "vividness":2,
      "r":81,
      "g":95,
      "b":95,
      "hex":"#515f5f",
      "file_location":"assets/colors_organized/hue_16/brightness_06/2020040415450900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":6,
      "vividness":3,
      "r":75,
      "g":95,
      "b":94,
      "hex":"#4b5f5e",
      "file_location":"assets/colors_organized/hue_16/brightness_06/2020040415450901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":6,
      "vividness":4,
      "r":69,
      "g":95,
      "b":94,
      "hex":"#455f5e",
      "file_location":"assets/colors_organized/hue_16/brightness_06/2020040415451000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":6,
      "vividness":5,
      "r":63,
      "g":95,
      "b":94,
      "hex":"#3f5f5e",
      "file_location":"assets/colors_organized/hue_16/brightness_06/2020040415451001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":6,
      "vividness":6,
      "r":56,
      "g":95,
      "b":94,
      "hex":"#385f5e",
      "file_location":"assets/colors_organized/hue_16/brightness_06/2020040415451100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":6,
      "vividness":7,
      "r":49,
      "g":95,
      "b":95,
      "hex":"#315f5f",
      "file_location":"assets/colors_organized/hue_16/brightness_06/2020040415451200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":6,
      "vividness":8,
      "r":43,
      "g":94,
      "b":95,
      "hex":"#2b5e5f",
      "file_location":"assets/colors_organized/hue_16/brightness_06/2020040415451201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":6,
      "vividness":9,
      "r":37,
      "g":95,
      "b":96,
      "hex":"#255f60",
      "file_location":"assets/colors_organized/hue_16/brightness_06/2020040415451300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":6,
      "vividness":10,
      "r":31,
      "g":95,
      "b":95,
      "hex":"#1f5f5f",
      "file_location":"assets/colors_organized/hue_16/brightness_06/2020040415451301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":6,
      "vividness":11,
      "r":25,
      "g":95,
      "b":95,
      "hex":"#195f5f",
      "file_location":"assets/colors_organized/hue_16/brightness_06/2020040415451400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":6,
      "vividness":12,
      "r":19,
      "g":95,
      "b":95,
      "hex":"#135f5f",
      "file_location":"assets/colors_organized/hue_16/brightness_06/2020040415451401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":6,
      "vividness":13,
      "r":13,
      "g":94,
      "b":95,
      "hex":"#0d5e5f",
      "file_location":"assets/colors_organized/hue_16/brightness_06/2020040415451500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":6,
      "vividness":14,
      "r":6,
      "g":94,
      "b":95,
      "hex":"#065e5f",
      "file_location":"assets/colors_organized/hue_16/brightness_06/2020040415451501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":7,
      "vividness":1,
      "r":101,
      "g":110,
      "b":109,
      "hex":"#656e6d",
      "file_location":"assets/colors_organized/hue_16/brightness_07/2020040415445601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":7,
      "vividness":2,
      "r":94,
      "g":110,
      "b":109,
      "hex":"#5e6e6d",
      "file_location":"assets/colors_organized/hue_16/brightness_07/2020040415445700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":7,
      "vividness":3,
      "r":88,
      "g":109,
      "b":110,
      "hex":"#586d6e",
      "file_location":"assets/colors_organized/hue_16/brightness_07/2020040415445701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":7,
      "vividness":4,
      "r":80,
      "g":110,
      "b":110,
      "hex":"#506e6e",
      "file_location":"assets/colors_organized/hue_16/brightness_07/2020040415445800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":7,
      "vividness":5,
      "r":72,
      "g":111,
      "b":110,
      "hex":"#486f6e",
      "file_location":"assets/colors_organized/hue_16/brightness_07/2020040415445801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":7,
      "vividness":6,
      "r":66,
      "g":110,
      "b":109,
      "hex":"#426e6d",
      "file_location":"assets/colors_organized/hue_16/brightness_07/2020040415445802-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":7,
      "vividness":7,
      "r":58,
      "g":109,
      "b":110,
      "hex":"#3a6d6e",
      "file_location":"assets/colors_organized/hue_16/brightness_07/2020040415445900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":7,
      "vividness":8,
      "r":50,
      "g":110,
      "b":110,
      "hex":"#326e6e",
      "file_location":"assets/colors_organized/hue_16/brightness_07/2020040415445901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":7,
      "vividness":9,
      "r":44,
      "g":110,
      "b":109,
      "hex":"#2c6e6d",
      "file_location":"assets/colors_organized/hue_16/brightness_07/2020040415450000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":7,
      "vividness":10,
      "r":36,
      "g":110,
      "b":109,
      "hex":"#246e6d",
      "file_location":"assets/colors_organized/hue_16/brightness_07/2020040415450001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":7,
      "vividness":11,
      "r":30,
      "g":110,
      "b":109,
      "hex":"#1e6e6d",
      "file_location":"assets/colors_organized/hue_16/brightness_07/2020040415450100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":7,
      "vividness":12,
      "r":21,
      "g":109,
      "b":110,
      "hex":"#156d6e",
      "file_location":"assets/colors_organized/hue_16/brightness_07/2020040415450101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":7,
      "vividness":13,
      "r":12,
      "g":110,
      "b":109,
      "hex":"#0c6e6d",
      "file_location":"assets/colors_organized/hue_16/brightness_07/2020040415450200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":7,
      "vividness":14,
      "r":6,
      "g":110,
      "b":109,
      "hex":"#066e6d",
      "file_location":"assets/colors_organized/hue_16/brightness_07/2020040415450201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":8,
      "vividness":1,
      "r":108,
      "g":124,
      "b":124,
      "hex":"#6c7c7c",
      "file_location":"assets/colors_organized/hue_16/brightness_08/2020040415444601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":8,
      "vividness":2,
      "r":98,
      "g":124,
      "b":123,
      "hex":"#627c7b",
      "file_location":"assets/colors_organized/hue_16/brightness_08/2020040415444700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":8,
      "vividness":3,
      "r":90,
      "g":124,
      "b":123,
      "hex":"#5a7c7b",
      "file_location":"assets/colors_organized/hue_16/brightness_08/2020040415444701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":8,
      "vividness":4,
      "r":83,
      "g":125,
      "b":124,
      "hex":"#537d7c",
      "file_location":"assets/colors_organized/hue_16/brightness_08/2020040415444800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":8,
      "vividness":5,
      "r":74,
      "g":124,
      "b":123,
      "hex":"#4a7c7b",
      "file_location":"assets/colors_organized/hue_16/brightness_08/2020040415444801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":8,
      "vividness":6,
      "r":66,
      "g":124,
      "b":125,
      "hex":"#427c7d",
      "file_location":"assets/colors_organized/hue_16/brightness_08/2020040415444900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":8,
      "vividness":7,
      "r":58,
      "g":124,
      "b":123,
      "hex":"#3a7c7b",
      "file_location":"assets/colors_organized/hue_16/brightness_08/2020040415444901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":8,
      "vividness":8,
      "r":48,
      "g":124,
      "b":124,
      "hex":"#307c7c",
      "file_location":"assets/colors_organized/hue_16/brightness_08/2020040415445000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":8,
      "vividness":9,
      "r":40,
      "g":124,
      "b":124,
      "hex":"#287c7c",
      "file_location":"assets/colors_organized/hue_16/brightness_08/2020040415445001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":8,
      "vividness":10,
      "r":34,
      "g":124,
      "b":124,
      "hex":"#227c7c",
      "file_location":"assets/colors_organized/hue_16/brightness_08/2020040415445100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":8,
      "vividness":11,
      "r":22,
      "g":125,
      "b":124,
      "hex":"#167d7c",
      "file_location":"assets/colors_organized/hue_16/brightness_08/2020040415445101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":8,
      "vividness":12,
      "r":14,
      "g":124,
      "b":123,
      "hex":"#0e7c7b",
      "file_location":"assets/colors_organized/hue_16/brightness_08/2020040415445200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":8,
      "vividness":13,
      "r":6,
      "g":124,
      "b":124,
      "hex":"#067c7c",
      "file_location":"assets/colors_organized/hue_16/brightness_08/2020040415445201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":9,
      "vividness":1,
      "r":121,
      "g":139,
      "b":139,
      "hex":"#798b8b",
      "file_location":"assets/colors_organized/hue_16/brightness_09/2020040415443600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":9,
      "vividness":2,
      "r":111,
      "g":139,
      "b":140,
      "hex":"#6f8b8c",
      "file_location":"assets/colors_organized/hue_16/brightness_09/2020040415443601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":9,
      "vividness":3,
      "r":101,
      "g":140,
      "b":139,
      "hex":"#658c8b",
      "file_location":"assets/colors_organized/hue_16/brightness_09/2020040415443700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":9,
      "vividness":4,
      "r":93,
      "g":139,
      "b":139,
      "hex":"#5d8b8b",
      "file_location":"assets/colors_organized/hue_16/brightness_09/2020040415443701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":9,
      "vividness":5,
      "r":83,
      "g":139,
      "b":138,
      "hex":"#538b8a",
      "file_location":"assets/colors_organized/hue_16/brightness_09/2020040415443800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":9,
      "vividness":6,
      "r":74,
      "g":140,
      "b":139,
      "hex":"#4a8c8b",
      "file_location":"assets/colors_organized/hue_16/brightness_09/2020040415443801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":9,
      "vividness":7,
      "r":65,
      "g":139,
      "b":138,
      "hex":"#418b8a",
      "file_location":"assets/colors_organized/hue_16/brightness_09/2020040415443900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":9,
      "vividness":8,
      "r":55,
      "g":139,
      "b":139,
      "hex":"#378b8b",
      "file_location":"assets/colors_organized/hue_16/brightness_09/2020040415443901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":9,
      "vividness":9,
      "r":45,
      "g":139,
      "b":139,
      "hex":"#2d8b8b",
      "file_location":"assets/colors_organized/hue_16/brightness_09/2020040415444000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":9,
      "vividness":10,
      "r":36,
      "g":139,
      "b":138,
      "hex":"#248b8a",
      "file_location":"assets/colors_organized/hue_16/brightness_09/2020040415444001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":9,
      "vividness":11,
      "r":26,
      "g":138,
      "b":139,
      "hex":"#1a8a8b",
      "file_location":"assets/colors_organized/hue_16/brightness_09/2020040415444100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":9,
      "vividness":12,
      "r":17,
      "g":140,
      "b":138,
      "hex":"#118c8a",
      "file_location":"assets/colors_organized/hue_16/brightness_09/2020040415444101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":9,
      "vividness":13,
      "r":9,
      "g":139,
      "b":139,
      "hex":"#098b8b",
      "file_location":"assets/colors_organized/hue_16/brightness_09/2020040415444200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":10,
      "vividness":1,
      "r":144,
      "g":154,
      "b":155,
      "hex":"#909a9b",
      "file_location":"assets/colors_organized/hue_16/brightness_10/2020040415442501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":10,
      "vividness":2,
      "r":134,
      "g":154,
      "b":153,
      "hex":"#869a99",
      "file_location":"assets/colors_organized/hue_16/brightness_10/2020040415442600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":10,
      "vividness":3,
      "r":123,
      "g":155,
      "b":154,
      "hex":"#7b9b9a",
      "file_location":"assets/colors_organized/hue_16/brightness_10/2020040415442601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":10,
      "vividness":4,
      "r":113,
      "g":155,
      "b":154,
      "hex":"#719b9a",
      "file_location":"assets/colors_organized/hue_16/brightness_10/2020040415442700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":10,
      "vividness":5,
      "r":103,
      "g":154,
      "b":155,
      "hex":"#679a9b",
      "file_location":"assets/colors_organized/hue_16/brightness_10/2020040415442701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":10,
      "vividness":6,
      "r":93,
      "g":155,
      "b":154,
      "hex":"#5d9b9a",
      "file_location":"assets/colors_organized/hue_16/brightness_10/2020040415442800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":10,
      "vividness":7,
      "r":83,
      "g":155,
      "b":154,
      "hex":"#539b9a",
      "file_location":"assets/colors_organized/hue_16/brightness_10/2020040415442801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":10,
      "vividness":8,
      "r":73,
      "g":154,
      "b":155,
      "hex":"#499a9b",
      "file_location":"assets/colors_organized/hue_16/brightness_10/2020040415442900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":10,
      "vividness":9,
      "r":60,
      "g":154,
      "b":154,
      "hex":"#3c9a9a",
      "file_location":"assets/colors_organized/hue_16/brightness_10/2020040415442901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":10,
      "vividness":10,
      "r":50,
      "g":154,
      "b":153,
      "hex":"#329a99",
      "file_location":"assets/colors_organized/hue_16/brightness_10/2020040415443000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":10,
      "vividness":11,
      "r":40,
      "g":154,
      "b":154,
      "hex":"#289a9a",
      "file_location":"assets/colors_organized/hue_16/brightness_10/2020040415443001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":10,
      "vividness":12,
      "r":30,
      "g":154,
      "b":154,
      "hex":"#1e9a9a",
      "file_location":"assets/colors_organized/hue_16/brightness_10/2020040415443100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":10,
      "vividness":13,
      "r":20,
      "g":154,
      "b":155,
      "hex":"#149a9b",
      "file_location":"assets/colors_organized/hue_16/brightness_10/2020040415443101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":10,
      "vividness":14,
      "r":10,
      "g":154,
      "b":154,
      "hex":"#0a9a9a",
      "file_location":"assets/colors_organized/hue_16/brightness_10/2020040415443200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":11,
      "vividness":1,
      "r":158,
      "g":170,
      "b":170,
      "hex":"#9eaaaa",
      "file_location":"assets/colors_organized/hue_16/brightness_11/2020040415441401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":11,
      "vividness":2,
      "r":148,
      "g":169,
      "b":170,
      "hex":"#94a9aa",
      "file_location":"assets/colors_organized/hue_16/brightness_11/2020040415441500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":11,
      "vividness":3,
      "r":135,
      "g":169,
      "b":170,
      "hex":"#87a9aa",
      "file_location":"assets/colors_organized/hue_16/brightness_11/2020040415441501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":11,
      "vividness":4,
      "r":124,
      "g":170,
      "b":170,
      "hex":"#7caaaa",
      "file_location":"assets/colors_organized/hue_16/brightness_11/2020040415441600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":11,
      "vividness":5,
      "r":113,
      "g":169,
      "b":168,
      "hex":"#71a9a8",
      "file_location":"assets/colors_organized/hue_16/brightness_11/2020040415441601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":11,
      "vividness":6,
      "r":101,
      "g":169,
      "b":168,
      "hex":"#65a9a8",
      "file_location":"assets/colors_organized/hue_16/brightness_11/2020040415441700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":11,
      "vividness":7,
      "r":89,
      "g":169,
      "b":168,
      "hex":"#59a9a8",
      "file_location":"assets/colors_organized/hue_16/brightness_11/2020040415441701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":11,
      "vividness":8,
      "r":79,
      "g":169,
      "b":169,
      "hex":"#4fa9a9",
      "file_location":"assets/colors_organized/hue_16/brightness_11/2020040415441800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":11,
      "vividness":9,
      "r":67,
      "g":170,
      "b":169,
      "hex":"#43aaa9",
      "file_location":"assets/colors_organized/hue_16/brightness_11/2020040415441900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":11,
      "vividness":10,
      "r":55,
      "g":169,
      "b":169,
      "hex":"#37a9a9",
      "file_location":"assets/colors_organized/hue_16/brightness_11/2020040415441901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":11,
      "vividness":11,
      "r":45,
      "g":169,
      "b":169,
      "hex":"#2da9a9",
      "file_location":"assets/colors_organized/hue_16/brightness_11/2020040415442000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":11,
      "vividness":12,
      "r":34,
      "g":170,
      "b":170,
      "hex":"#22aaaa",
      "file_location":"assets/colors_organized/hue_16/brightness_11/2020040415442001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":11,
      "vividness":13,
      "r":21,
      "g":169,
      "b":169,
      "hex":"#15a9a9",
      "file_location":"assets/colors_organized/hue_16/brightness_11/2020040415442100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":11,
      "vividness":14,
      "r":9,
      "g":169,
      "b":169,
      "hex":"#09a9a9",
      "file_location":"assets/colors_organized/hue_16/brightness_11/2020040415442101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":12,
      "vividness":1,
      "r":173,
      "g":185,
      "b":185,
      "hex":"#adb9b9",
      "file_location":"assets/colors_organized/hue_16/brightness_12/2020040415440400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":12,
      "vividness":2,
      "r":160,
      "g":184,
      "b":184,
      "hex":"#a0b8b8",
      "file_location":"assets/colors_organized/hue_16/brightness_12/2020040415440401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":12,
      "vividness":3,
      "r":146,
      "g":185,
      "b":184,
      "hex":"#92b9b8",
      "file_location":"assets/colors_organized/hue_16/brightness_12/2020040415440402-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":12,
      "vividness":4,
      "r":135,
      "g":185,
      "b":184,
      "hex":"#87b9b8",
      "file_location":"assets/colors_organized/hue_16/brightness_12/2020040415440500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":12,
      "vividness":5,
      "r":123,
      "g":185,
      "b":184,
      "hex":"#7bb9b8",
      "file_location":"assets/colors_organized/hue_16/brightness_12/2020040415440501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":12,
      "vividness":6,
      "r":110,
      "g":184,
      "b":183,
      "hex":"#6eb8b7",
      "file_location":"assets/colors_organized/hue_16/brightness_12/2020040415440600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":12,
      "vividness":7,
      "r":98,
      "g":184,
      "b":183,
      "hex":"#62b8b7",
      "file_location":"assets/colors_organized/hue_16/brightness_12/2020040415440601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":12,
      "vividness":8,
      "r":86,
      "g":184,
      "b":183,
      "hex":"#56b8b7",
      "file_location":"assets/colors_organized/hue_16/brightness_12/2020040415440700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":12,
      "vividness":9,
      "r":72,
      "g":184,
      "b":185,
      "hex":"#48b8b9",
      "file_location":"assets/colors_organized/hue_16/brightness_12/2020040415440701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":12,
      "vividness":10,
      "r":60,
      "g":184,
      "b":184,
      "hex":"#3cb8b8",
      "file_location":"assets/colors_organized/hue_16/brightness_12/2020040415440800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":12,
      "vividness":11,
      "r":49,
      "g":185,
      "b":185,
      "hex":"#31b9b9",
      "file_location":"assets/colors_organized/hue_16/brightness_12/2020040415440801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":12,
      "vividness":12,
      "r":36,
      "g":184,
      "b":184,
      "hex":"#24b8b8",
      "file_location":"assets/colors_organized/hue_16/brightness_12/2020040415440900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":12,
      "vividness":13,
      "r":22,
      "g":185,
      "b":184,
      "hex":"#16b9b8",
      "file_location":"assets/colors_organized/hue_16/brightness_12/2020040415441000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":12,
      "vividness":14,
      "r":12,
      "g":184,
      "b":184,
      "hex":"#0cb8b8",
      "file_location":"assets/colors_organized/hue_16/brightness_12/2020040415441001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":13,
      "vividness":1,
      "r":185,
      "g":199,
      "b":199,
      "hex":"#b9c7c7",
      "file_location":"assets/colors_organized/hue_16/brightness_13/2020040415435400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":13,
      "vividness":2,
      "r":174,
      "g":200,
      "b":199,
      "hex":"#aec8c7",
      "file_location":"assets/colors_organized/hue_16/brightness_13/2020040415435401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":13,
      "vividness":3,
      "r":159,
      "g":199,
      "b":199,
      "hex":"#9fc7c7",
      "file_location":"assets/colors_organized/hue_16/brightness_13/2020040415435500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":13,
      "vividness":4,
      "r":146,
      "g":200,
      "b":200,
      "hex":"#92c8c8",
      "file_location":"assets/colors_organized/hue_16/brightness_13/2020040415435501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":13,
      "vividness":5,
      "r":134,
      "g":200,
      "b":199,
      "hex":"#86c8c7",
      "file_location":"assets/colors_organized/hue_16/brightness_13/2020040415435600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":13,
      "vividness":6,
      "r":119,
      "g":199,
      "b":198,
      "hex":"#77c7c6",
      "file_location":"assets/colors_organized/hue_16/brightness_13/2020040415435601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":13,
      "vividness":7,
      "r":105,
      "g":199,
      "b":199,
      "hex":"#69c7c7",
      "file_location":"assets/colors_organized/hue_16/brightness_13/2020040415435602-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":13,
      "vividness":8,
      "r":94,
      "g":200,
      "b":200,
      "hex":"#5ec8c8",
      "file_location":"assets/colors_organized/hue_16/brightness_13/2020040415435700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":13,
      "vividness":9,
      "r":79,
      "g":199,
      "b":198,
      "hex":"#4fc7c6",
      "file_location":"assets/colors_organized/hue_16/brightness_13/2020040415435701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":13,
      "vividness":10,
      "r":65,
      "g":199,
      "b":198,
      "hex":"#41c7c6",
      "file_location":"assets/colors_organized/hue_16/brightness_13/2020040415435800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":13,
      "vividness":11,
      "r":52,
      "g":200,
      "b":200,
      "hex":"#34c8c8",
      "file_location":"assets/colors_organized/hue_16/brightness_13/2020040415435801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":13,
      "vividness":12,
      "r":37,
      "g":200,
      "b":199,
      "hex":"#25c8c7",
      "file_location":"assets/colors_organized/hue_16/brightness_13/2020040415435900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":13,
      "vividness":13,
      "r":26,
      "g":200,
      "b":199,
      "hex":"#1ac8c7",
      "file_location":"assets/colors_organized/hue_16/brightness_13/2020040415435901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":13,
      "vividness":14,
      "r":12,
      "g":200,
      "b":199,
      "hex":"#0cc8c7",
      "file_location":"assets/colors_organized/hue_16/brightness_13/2020040415440000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":14,
      "vividness":1,
      "r":200,
      "g":214,
      "b":214,
      "hex":"#c8d6d6",
      "file_location":"assets/colors_organized/hue_16/brightness_14/2020040415434301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":14,
      "vividness":2,
      "r":185,
      "g":215,
      "b":215,
      "hex":"#b9d7d7",
      "file_location":"assets/colors_organized/hue_16/brightness_14/2020040415434400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":14,
      "vividness":3,
      "r":171,
      "g":215,
      "b":214,
      "hex":"#abd7d6",
      "file_location":"assets/colors_organized/hue_16/brightness_14/2020040415434401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":14,
      "vividness":4,
      "r":156,
      "g":214,
      "b":215,
      "hex":"#9cd6d7",
      "file_location":"assets/colors_organized/hue_16/brightness_14/2020040415434500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":14,
      "vividness":5,
      "r":143,
      "g":215,
      "b":214,
      "hex":"#8fd7d6",
      "file_location":"assets/colors_organized/hue_16/brightness_14/2020040415434501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":14,
      "vividness":6,
      "r":129,
      "g":215,
      "b":214,
      "hex":"#81d7d6",
      "file_location":"assets/colors_organized/hue_16/brightness_14/2020040415434600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":14,
      "vividness":7,
      "r":114,
      "g":214,
      "b":214,
      "hex":"#72d6d6",
      "file_location":"assets/colors_organized/hue_16/brightness_14/2020040415434601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":14,
      "vividness":8,
      "r":100,
      "g":214,
      "b":214,
      "hex":"#64d6d6",
      "file_location":"assets/colors_organized/hue_16/brightness_14/2020040415434700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":14,
      "vividness":9,
      "r":85,
      "g":215,
      "b":215,
      "hex":"#55d7d7",
      "file_location":"assets/colors_organized/hue_16/brightness_14/2020040415434701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":14,
      "vividness":10,
      "r":71,
      "g":215,
      "b":215,
      "hex":"#47d7d7",
      "file_location":"assets/colors_organized/hue_16/brightness_14/2020040415434800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":14,
      "vividness":11,
      "r":56,
      "g":214,
      "b":215,
      "hex":"#38d6d7",
      "file_location":"assets/colors_organized/hue_16/brightness_14/2020040415434801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":14,
      "vividness":12,
      "r":41,
      "g":215,
      "b":214,
      "hex":"#29d7d6",
      "file_location":"assets/colors_organized/hue_16/brightness_14/2020040415434900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":14,
      "vividness":13,
      "r":27,
      "g":215,
      "b":214,
      "hex":"#1bd7d6",
      "file_location":"assets/colors_organized/hue_16/brightness_14/2020040415434901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":14,
      "vividness":14,
      "r":12,
      "g":214,
      "b":214,
      "hex":"#0cd6d6",
      "file_location":"assets/colors_organized/hue_16/brightness_14/2020040415435000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":15,
      "vividness":1,
      "r":214,
      "g":230,
      "b":229,
      "hex":"#d6e6e5",
      "file_location":"assets/colors_organized/hue_16/brightness_15/2020040415433301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":15,
      "vividness":2,
      "r":198,
      "g":230,
      "b":229,
      "hex":"#c6e6e5",
      "file_location":"assets/colors_organized/hue_16/brightness_15/2020040415433400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":15,
      "vividness":3,
      "r":184,
      "g":230,
      "b":230,
      "hex":"#b8e6e6",
      "file_location":"assets/colors_organized/hue_16/brightness_15/2020040415433401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":15,
      "vividness":4,
      "r":168,
      "g":230,
      "b":229,
      "hex":"#a8e6e5",
      "file_location":"assets/colors_organized/hue_16/brightness_15/2020040415433500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":15,
      "vividness":5,
      "r":151,
      "g":229,
      "b":229,
      "hex":"#97e5e5",
      "file_location":"assets/colors_organized/hue_16/brightness_15/2020040415433501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":15,
      "vividness":6,
      "r":138,
      "g":230,
      "b":229,
      "hex":"#8ae6e5",
      "file_location":"assets/colors_organized/hue_16/brightness_15/2020040415433600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":15,
      "vividness":7,
      "r":121,
      "g":229,
      "b":229,
      "hex":"#79e5e5",
      "file_location":"assets/colors_organized/hue_16/brightness_15/2020040415433601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":15,
      "vividness":8,
      "r":107,
      "g":229,
      "b":230,
      "hex":"#6be5e6",
      "file_location":"assets/colors_organized/hue_16/brightness_15/2020040415433700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":15,
      "vividness":9,
      "r":91,
      "g":229,
      "b":229,
      "hex":"#5be5e5",
      "file_location":"assets/colors_organized/hue_16/brightness_15/2020040415433701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":15,
      "vividness":10,
      "r":76,
      "g":230,
      "b":230,
      "hex":"#4ce6e6",
      "file_location":"assets/colors_organized/hue_16/brightness_15/2020040415433800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":15,
      "vividness":11,
      "r":60,
      "g":230,
      "b":229,
      "hex":"#3ce6e5",
      "file_location":"assets/colors_organized/hue_16/brightness_15/2020040415433801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":15,
      "vividness":12,
      "r":44,
      "g":230,
      "b":229,
      "hex":"#2ce6e5",
      "file_location":"assets/colors_organized/hue_16/brightness_15/2020040415433900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":15,
      "vividness":13,
      "r":30,
      "g":230,
      "b":230,
      "hex":"#1ee6e6",
      "file_location":"assets/colors_organized/hue_16/brightness_15/2020040415433901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":16,
      "brightness":15,
      "vividness":14,
      "r":14,
      "g":230,
      "b":229,
      "hex":"#0ee6e5",
      "file_location":"assets/colors_organized/hue_16/brightness_15/2020040415433902-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":1,
      "vividness":1,
      "r":12,
      "g":17,
      "b":20,
      "hex":"#0c1114",
      "file_location":"assets/colors_organized/hue_17/brightness_01/2020040416170002-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":1,
      "vividness":2,
      "r":9,
      "g":17,
      "b":19,
      "hex":"#091113",
      "file_location":"assets/colors_organized/hue_17/brightness_01/2020040416170100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":1,
      "vividness":3,
      "r":6,
      "g":17,
      "b":19,
      "hex":"#061113",
      "file_location":"assets/colors_organized/hue_17/brightness_01/2020040416170200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":1,
      "vividness":4,
      "r":2,
      "g":18,
      "b":18,
      "hex":"#021212",
      "file_location":"assets/colors_organized/hue_17/brightness_01/2020040416170301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":1,
      "vividness":5,
      "r":1,
      "g":15,
      "b":18,
      "hex":"#010f12",
      "file_location":"assets/colors_organized/hue_17/brightness_01/2020040416170400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":1,
      "vividness":6,
      "r":0,
      "g":15,
      "b":20,
      "hex":"#000f14",
      "file_location":"assets/colors_organized/hue_17/brightness_01/2020040416170401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":2,
      "vividness":1,
      "r":32,
      "g":33,
      "b":35,
      "hex":"#202123",
      "file_location":"assets/colors_organized/hue_17/brightness_02/2020040416164801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":2,
      "vividness":2,
      "r":29,
      "g":33,
      "b":34,
      "hex":"#1d2122",
      "file_location":"assets/colors_organized/hue_17/brightness_02/2020040416164900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":2,
      "vividness":3,
      "r":28,
      "g":34,
      "b":34,
      "hex":"#1c2222",
      "file_location":"assets/colors_organized/hue_17/brightness_02/2020040416164901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":2,
      "vividness":4,
      "r":24,
      "g":34,
      "b":35,
      "hex":"#182223",
      "file_location":"assets/colors_organized/hue_17/brightness_02/2020040416165000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":2,
      "vividness":5,
      "r":22,
      "g":32,
      "b":34,
      "hex":"#162022",
      "file_location":"assets/colors_organized/hue_17/brightness_02/2020040416165001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":2,
      "vividness":6,
      "r":18,
      "g":32,
      "b":35,
      "hex":"#122023",
      "file_location":"assets/colors_organized/hue_17/brightness_02/2020040416165101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":2,
      "vividness":7,
      "r":15,
      "g":30,
      "b":35,
      "hex":"#0f1e23",
      "file_location":"assets/colors_organized/hue_17/brightness_02/2020040416165200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":2,
      "vividness":8,
      "r":13,
      "g":31,
      "b":35,
      "hex":"#0d1f23",
      "file_location":"assets/colors_organized/hue_17/brightness_02/2020040416165201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":2,
      "vividness":9,
      "r":9,
      "g":30,
      "b":35,
      "hex":"#091e23",
      "file_location":"assets/colors_organized/hue_17/brightness_02/2020040416165300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":2,
      "vividness":10,
      "r":6,
      "g":30,
      "b":34,
      "hex":"#061e22",
      "file_location":"assets/colors_organized/hue_17/brightness_02/2020040416165400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":2,
      "vividness":11,
      "r":2,
      "g":30,
      "b":34,
      "hex":"#021e22",
      "file_location":"assets/colors_organized/hue_17/brightness_02/2020040416165401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":2,
      "vividness":12,
      "r":3,
      "g":28,
      "b":35,
      "hex":"#031c23",
      "file_location":"assets/colors_organized/hue_17/brightness_02/2020040416165500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":3,
      "vividness":1,
      "r":42,
      "g":47,
      "b":50,
      "hex":"#2a2f32",
      "file_location":"assets/colors_organized/hue_17/brightness_03/2020040416163900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":3,
      "vividness":2,
      "r":39,
      "g":47,
      "b":49,
      "hex":"#272f31",
      "file_location":"assets/colors_organized/hue_17/brightness_03/2020040416163901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":3,
      "vividness":3,
      "r":36,
      "g":47,
      "b":49,
      "hex":"#242f31",
      "file_location":"assets/colors_organized/hue_17/brightness_03/2020040416164000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":3,
      "vividness":4,
      "r":34,
      "g":45,
      "b":49,
      "hex":"#222d31",
      "file_location":"assets/colors_organized/hue_17/brightness_03/2020040416164001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":3,
      "vividness":5,
      "r":30,
      "g":45,
      "b":50,
      "hex":"#1e2d32",
      "file_location":"assets/colors_organized/hue_17/brightness_03/2020040416164100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":3,
      "vividness":6,
      "r":26,
      "g":45,
      "b":49,
      "hex":"#1a2d31",
      "file_location":"assets/colors_organized/hue_17/brightness_03/2020040416164101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":3,
      "vividness":7,
      "r":23,
      "g":44,
      "b":49,
      "hex":"#172c31",
      "file_location":"assets/colors_organized/hue_17/brightness_03/2020040416164200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":3,
      "vividness":8,
      "r":19,
      "g":44,
      "b":49,
      "hex":"#132c31",
      "file_location":"assets/colors_organized/hue_17/brightness_03/2020040416164201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":3,
      "vividness":9,
      "r":14,
      "g":43,
      "b":49,
      "hex":"#0e2b31",
      "file_location":"assets/colors_organized/hue_17/brightness_03/2020040416164300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":3,
      "vividness":10,
      "r":13,
      "g":42,
      "b":50,
      "hex":"#0d2a32",
      "file_location":"assets/colors_organized/hue_17/brightness_03/2020040416164301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":3,
      "vividness":11,
      "r":9,
      "g":42,
      "b":49,
      "hex":"#092a31",
      "file_location":"assets/colors_organized/hue_17/brightness_03/2020040416164400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":3,
      "vividness":12,
      "r":6,
      "g":40,
      "b":49,
      "hex":"#062831",
      "file_location":"assets/colors_organized/hue_17/brightness_03/2020040416164401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":3,
      "vividness":13,
      "r":3,
      "g":40,
      "b":49,
      "hex":"#032831",
      "file_location":"assets/colors_organized/hue_17/brightness_03/2020040416164500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":4,
      "vividness":1,
      "r":60,
      "g":64,
      "b":65,
      "hex":"#3c4041",
      "file_location":"assets/colors_organized/hue_17/brightness_04/2020040416162800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":4,
      "vividness":2,
      "r":55,
      "g":63,
      "b":65,
      "hex":"#373f41",
      "file_location":"assets/colors_organized/hue_17/brightness_04/2020040416162801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":4,
      "vividness":3,
      "r":52,
      "g":62,
      "b":64,
      "hex":"#343e40",
      "file_location":"assets/colors_organized/hue_17/brightness_04/2020040416162802-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":4,
      "vividness":4,
      "r":47,
      "g":61,
      "b":64,
      "hex":"#2f3d40",
      "file_location":"assets/colors_organized/hue_17/brightness_04/2020040416162900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":4,
      "vividness":5,
      "r":42,
      "g":60,
      "b":64,
      "hex":"#2a3c40",
      "file_location":"assets/colors_organized/hue_17/brightness_04/2020040416162901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":4,
      "vividness":6,
      "r":38,
      "g":59,
      "b":64,
      "hex":"#263b40",
      "file_location":"assets/colors_organized/hue_17/brightness_04/2020040416163000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":4,
      "vividness":7,
      "r":34,
      "g":59,
      "b":64,
      "hex":"#223b40",
      "file_location":"assets/colors_organized/hue_17/brightness_04/2020040416163001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":4,
      "vividness":8,
      "r":29,
      "g":58,
      "b":64,
      "hex":"#1d3a40",
      "file_location":"assets/colors_organized/hue_17/brightness_04/2020040416163100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":4,
      "vividness":9,
      "r":27,
      "g":57,
      "b":65,
      "hex":"#1b3941",
      "file_location":"assets/colors_organized/hue_17/brightness_04/2020040416163200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":4,
      "vividness":10,
      "r":21,
      "g":55,
      "b":64,
      "hex":"#153740",
      "file_location":"assets/colors_organized/hue_17/brightness_04/2020040416163201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":4,
      "vividness":11,
      "r":18,
      "g":55,
      "b":64,
      "hex":"#123740",
      "file_location":"assets/colors_organized/hue_17/brightness_04/2020040416163300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":4,
      "vividness":12,
      "r":12,
      "g":55,
      "b":64,
      "hex":"#0c3740",
      "file_location":"assets/colors_organized/hue_17/brightness_04/2020040416163301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":4,
      "vividness":13,
      "r":6,
      "g":52,
      "b":65,
      "hex":"#063441",
      "file_location":"assets/colors_organized/hue_17/brightness_04/2020040416163400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":4,
      "vividness":14,
      "r":3,
      "g":53,
      "b":64,
      "hex":"#033540",
      "file_location":"assets/colors_organized/hue_17/brightness_04/2020040416163401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":5,
      "vividness":1,
      "r":73,
      "g":79,
      "b":79,
      "hex":"#494f4f",
      "file_location":"assets/colors_organized/hue_17/brightness_05/2020040416161800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":5,
      "vividness":2,
      "r":68,
      "g":78,
      "b":79,
      "hex":"#444e4f",
      "file_location":"assets/colors_organized/hue_17/brightness_05/2020040416161801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":5,
      "vividness":3,
      "r":65,
      "g":76,
      "b":80,
      "hex":"#414c50",
      "file_location":"assets/colors_organized/hue_17/brightness_05/2020040416161802-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":5,
      "vividness":4,
      "r":57,
      "g":75,
      "b":79,
      "hex":"#394b4f",
      "file_location":"assets/colors_organized/hue_17/brightness_05/2020040416161900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":5,
      "vividness":5,
      "r":53,
      "g":74,
      "b":79,
      "hex":"#354a4f",
      "file_location":"assets/colors_organized/hue_17/brightness_05/2020040416161901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":5,
      "vividness":6,
      "r":48,
      "g":73,
      "b":80,
      "hex":"#304950",
      "file_location":"assets/colors_organized/hue_17/brightness_05/2020040416162000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":5,
      "vividness":7,
      "r":42,
      "g":72,
      "b":80,
      "hex":"#2a4850",
      "file_location":"assets/colors_organized/hue_17/brightness_05/2020040416162001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":5,
      "vividness":8,
      "r":37,
      "g":71,
      "b":80,
      "hex":"#254750",
      "file_location":"assets/colors_organized/hue_17/brightness_05/2020040416162100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":5,
      "vividness":9,
      "r":32,
      "g":70,
      "b":79,
      "hex":"#20464f",
      "file_location":"assets/colors_organized/hue_17/brightness_05/2020040416162101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":5,
      "vividness":10,
      "r":26,
      "g":68,
      "b":80,
      "hex":"#1a4450",
      "file_location":"assets/colors_organized/hue_17/brightness_05/2020040416162200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":5,
      "vividness":11,
      "r":21,
      "g":67,
      "b":80,
      "hex":"#154350",
      "file_location":"assets/colors_organized/hue_17/brightness_05/2020040416162201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":5,
      "vividness":12,
      "r":15,
      "g":67,
      "b":80,
      "hex":"#0f4350",
      "file_location":"assets/colors_organized/hue_17/brightness_05/2020040416162300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":5,
      "vividness":13,
      "r":10,
      "g":66,
      "b":79,
      "hex":"#0a424f",
      "file_location":"assets/colors_organized/hue_17/brightness_05/2020040416162301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":5,
      "vividness":14,
      "r":7,
      "g":65,
      "b":79,
      "hex":"#07414f",
      "file_location":"assets/colors_organized/hue_17/brightness_05/2020040416162400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":6,
      "vividness":1,
      "r":88,
      "g":93,
      "b":96,
      "hex":"#585d60",
      "file_location":"assets/colors_organized/hue_17/brightness_06/2020040416160701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":6,
      "vividness":2,
      "r":82,
      "g":92,
      "b":94,
      "hex":"#525c5e",
      "file_location":"assets/colors_organized/hue_17/brightness_06/2020040416160800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":6,
      "vividness":3,
      "r":76,
      "g":91,
      "b":94,
      "hex":"#4c5b5e",
      "file_location":"assets/colors_organized/hue_17/brightness_06/2020040416160801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":6,
      "vividness":4,
      "r":69,
      "g":90,
      "b":95,
      "hex":"#455a5f",
      "file_location":"assets/colors_organized/hue_17/brightness_06/2020040416160900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":6,
      "vividness":5,
      "r":63,
      "g":88,
      "b":95,
      "hex":"#3f585f",
      "file_location":"assets/colors_organized/hue_17/brightness_06/2020040416160901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":6,
      "vividness":6,
      "r":57,
      "g":87,
      "b":95,
      "hex":"#39575f",
      "file_location":"assets/colors_organized/hue_17/brightness_06/2020040416161000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":6,
      "vividness":7,
      "r":49,
      "g":86,
      "b":94,
      "hex":"#31565e",
      "file_location":"assets/colors_organized/hue_17/brightness_06/2020040416161001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":6,
      "vividness":8,
      "r":44,
      "g":84,
      "b":94,
      "hex":"#2c545e",
      "file_location":"assets/colors_organized/hue_17/brightness_06/2020040416161100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":6,
      "vividness":9,
      "r":37,
      "g":83,
      "b":96,
      "hex":"#255360",
      "file_location":"assets/colors_organized/hue_17/brightness_06/2020040416161101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":6,
      "vividness":10,
      "r":30,
      "g":82,
      "b":95,
      "hex":"#1e525f",
      "file_location":"assets/colors_organized/hue_17/brightness_06/2020040416161200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":6,
      "vividness":11,
      "r":25,
      "g":81,
      "b":94,
      "hex":"#19515e",
      "file_location":"assets/colors_organized/hue_17/brightness_06/2020040416161201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":6,
      "vividness":12,
      "r":20,
      "g":79,
      "b":95,
      "hex":"#144f5f",
      "file_location":"assets/colors_organized/hue_17/brightness_06/2020040416161300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":6,
      "vividness":13,
      "r":12,
      "g":78,
      "b":94,
      "hex":"#0c4e5e",
      "file_location":"assets/colors_organized/hue_17/brightness_06/2020040416161301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":6,
      "vividness":14,
      "r":6,
      "g":77,
      "b":95,
      "hex":"#064d5f",
      "file_location":"assets/colors_organized/hue_17/brightness_06/2020040416161400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":7,
      "vividness":1,
      "r":102,
      "g":107,
      "b":110,
      "hex":"#666b6e",
      "file_location":"assets/colors_organized/hue_17/brightness_07/2020040416155701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":7,
      "vividness":2,
      "r":96,
      "g":107,
      "b":109,
      "hex":"#606b6d",
      "file_location":"assets/colors_organized/hue_17/brightness_07/2020040416155800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":7,
      "vividness":3,
      "r":87,
      "g":105,
      "b":109,
      "hex":"#57696d",
      "file_location":"assets/colors_organized/hue_17/brightness_07/2020040416155801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":7,
      "vividness":4,
      "r":79,
      "g":104,
      "b":109,
      "hex":"#4f686d",
      "file_location":"assets/colors_organized/hue_17/brightness_07/2020040416155900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":7,
      "vividness":5,
      "r":73,
      "g":102,
      "b":110,
      "hex":"#49666e",
      "file_location":"assets/colors_organized/hue_17/brightness_07/2020040416155901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":7,
      "vividness":6,
      "r":66,
      "g":100,
      "b":109,
      "hex":"#42646d",
      "file_location":"assets/colors_organized/hue_17/brightness_07/2020040416160000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":7,
      "vividness":7,
      "r":57,
      "g":100,
      "b":109,
      "hex":"#39646d",
      "file_location":"assets/colors_organized/hue_17/brightness_07/2020040416160001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":7,
      "vividness":8,
      "r":50,
      "g":98,
      "b":110,
      "hex":"#32626e",
      "file_location":"assets/colors_organized/hue_17/brightness_07/2020040416160100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":7,
      "vividness":9,
      "r":44,
      "g":96,
      "b":110,
      "hex":"#2c606e",
      "file_location":"assets/colors_organized/hue_17/brightness_07/2020040416160101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":7,
      "vividness":10,
      "r":36,
      "g":95,
      "b":109,
      "hex":"#245f6d",
      "file_location":"assets/colors_organized/hue_17/brightness_07/2020040416160102-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":7,
      "vividness":11,
      "r":30,
      "g":93,
      "b":110,
      "hex":"#1e5d6e",
      "file_location":"assets/colors_organized/hue_17/brightness_07/2020040416160200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":7,
      "vividness":12,
      "r":21,
      "g":92,
      "b":110,
      "hex":"#155c6e",
      "file_location":"assets/colors_organized/hue_17/brightness_07/2020040416160201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":7,
      "vividness":13,
      "r":13,
      "g":90,
      "b":110,
      "hex":"#0d5a6e",
      "file_location":"assets/colors_organized/hue_17/brightness_07/2020040416160300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":7,
      "vividness":14,
      "r":7,
      "g":89,
      "b":110,
      "hex":"#07596e",
      "file_location":"assets/colors_organized/hue_17/brightness_07/2020040416160301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":8,
      "vividness":1,
      "r":115,
      "g":123,
      "b":125,
      "hex":"#737b7d",
      "file_location":"assets/colors_organized/hue_17/brightness_08/2020040416154800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":8,
      "vividness":2,
      "r":107,
      "g":121,
      "b":124,
      "hex":"#6b797c",
      "file_location":"assets/colors_organized/hue_17/brightness_08/2020040416154801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":8,
      "vividness":3,
      "r":98,
      "g":119,
      "b":124,
      "hex":"#62777c",
      "file_location":"assets/colors_organized/hue_17/brightness_08/2020040416154802-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":8,
      "vividness":4,
      "r":90,
      "g":118,
      "b":122,
      "hex":"#5a767a",
      "file_location":"assets/colors_organized/hue_17/brightness_08/2020040416154900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":8,
      "vividness":5,
      "r":83,
      "g":116,
      "b":123,
      "hex":"#53747b",
      "file_location":"assets/colors_organized/hue_17/brightness_08/2020040416154901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":8,
      "vividness":6,
      "r":74,
      "g":114,
      "b":124,
      "hex":"#4a727c",
      "file_location":"assets/colors_organized/hue_17/brightness_08/2020040416155000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":8,
      "vividness":7,
      "r":65,
      "g":113,
      "b":123,
      "hex":"#41717b",
      "file_location":"assets/colors_organized/hue_17/brightness_08/2020040416155001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":8,
      "vividness":8,
      "r":57,
      "g":111,
      "b":123,
      "hex":"#396f7b",
      "file_location":"assets/colors_organized/hue_17/brightness_08/2020040416155100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":8,
      "vividness":9,
      "r":50,
      "g":109,
      "b":125,
      "hex":"#326d7d",
      "file_location":"assets/colors_organized/hue_17/brightness_08/2020040416155101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":8,
      "vividness":10,
      "r":41,
      "g":108,
      "b":124,
      "hex":"#296c7c",
      "file_location":"assets/colors_organized/hue_17/brightness_08/2020040416155200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":8,
      "vividness":11,
      "r":33,
      "g":106,
      "b":123,
      "hex":"#216a7b",
      "file_location":"assets/colors_organized/hue_17/brightness_08/2020040416155201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":8,
      "vividness":12,
      "r":23,
      "g":104,
      "b":125,
      "hex":"#17687d",
      "file_location":"assets/colors_organized/hue_17/brightness_08/2020040416155300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":8,
      "vividness":13,
      "r":15,
      "g":103,
      "b":123,
      "hex":"#0f677b",
      "file_location":"assets/colors_organized/hue_17/brightness_08/2020040416155301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":8,
      "vividness":14,
      "r":6,
      "g":101,
      "b":123,
      "hex":"#06657b",
      "file_location":"assets/colors_organized/hue_17/brightness_08/2020040416155302-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":9,
      "vividness":1,
      "r":130,
      "g":138,
      "b":140,
      "hex":"#828a8c",
      "file_location":"assets/colors_organized/hue_17/brightness_09/2020040416153601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":9,
      "vividness":2,
      "r":121,
      "g":136,
      "b":139,
      "hex":"#79888b",
      "file_location":"assets/colors_organized/hue_17/brightness_09/2020040416153602-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":9,
      "vividness":3,
      "r":111,
      "g":135,
      "b":139,
      "hex":"#6f878b",
      "file_location":"assets/colors_organized/hue_17/brightness_09/2020040416153700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":9,
      "vividness":4,
      "r":102,
      "g":133,
      "b":138,
      "hex":"#66858a",
      "file_location":"assets/colors_organized/hue_17/brightness_09/2020040416153701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":9,
      "vividness":5,
      "r":92,
      "g":130,
      "b":139,
      "hex":"#5c828b",
      "file_location":"assets/colors_organized/hue_17/brightness_09/2020040416153800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":9,
      "vividness":6,
      "r":82,
      "g":129,
      "b":139,
      "hex":"#52818b",
      "file_location":"assets/colors_organized/hue_17/brightness_09/2020040416153801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":9,
      "vividness":7,
      "r":74,
      "g":126,
      "b":139,
      "hex":"#4a7e8b",
      "file_location":"assets/colors_organized/hue_17/brightness_09/2020040416153900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":9,
      "vividness":8,
      "r":65,
      "g":124,
      "b":138,
      "hex":"#417c8a",
      "file_location":"assets/colors_organized/hue_17/brightness_09/2020040416153901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":9,
      "vividness":9,
      "r":54,
      "g":123,
      "b":139,
      "hex":"#367b8b",
      "file_location":"assets/colors_organized/hue_17/brightness_09/2020040416154000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":9,
      "vividness":10,
      "r":45,
      "g":120,
      "b":139,
      "hex":"#2d788b",
      "file_location":"assets/colors_organized/hue_17/brightness_09/2020040416154001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":9,
      "vividness":11,
      "r":37,
      "g":119,
      "b":140,
      "hex":"#25778c",
      "file_location":"assets/colors_organized/hue_17/brightness_09/2020040416154002-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":9,
      "vividness":12,
      "r":26,
      "g":117,
      "b":138,
      "hex":"#1a758a",
      "file_location":"assets/colors_organized/hue_17/brightness_09/2020040416154100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":9,
      "vividness":13,
      "r":17,
      "g":116,
      "b":139,
      "hex":"#11748b",
      "file_location":"assets/colors_organized/hue_17/brightness_09/2020040416154101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":9,
      "vividness":14,
      "r":9,
      "g":113,
      "b":138,
      "hex":"#09718a",
      "file_location":"assets/colors_organized/hue_17/brightness_09/2020040416154200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":10,
      "vividness":1,
      "r":144,
      "g":152,
      "b":154,
      "hex":"#90989a",
      "file_location":"assets/colors_organized/hue_17/brightness_10/2020040416152601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":10,
      "vividness":2,
      "r":135,
      "g":150,
      "b":155,
      "hex":"#87969b",
      "file_location":"assets/colors_organized/hue_17/brightness_10/2020040416152700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":10,
      "vividness":3,
      "r":123,
      "g":148,
      "b":153,
      "hex":"#7b9499",
      "file_location":"assets/colors_organized/hue_17/brightness_10/2020040416152701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":10,
      "vividness":4,
      "r":113,
      "g":146,
      "b":153,
      "hex":"#719299",
      "file_location":"assets/colors_organized/hue_17/brightness_10/2020040416152702-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":10,
      "vividness":5,
      "r":102,
      "g":145,
      "b":154,
      "hex":"#66919a",
      "file_location":"assets/colors_organized/hue_17/brightness_10/2020040416152800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":10,
      "vividness":6,
      "r":92,
      "g":142,
      "b":153,
      "hex":"#5c8e99",
      "file_location":"assets/colors_organized/hue_17/brightness_10/2020040416152801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":10,
      "vividness":7,
      "r":82,
      "g":140,
      "b":154,
      "hex":"#528c9a",
      "file_location":"assets/colors_organized/hue_17/brightness_10/2020040416152900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":10,
      "vividness":8,
      "r":71,
      "g":138,
      "b":154,
      "hex":"#478a9a",
      "file_location":"assets/colors_organized/hue_17/brightness_10/2020040416152901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":10,
      "vividness":9,
      "r":61,
      "g":136,
      "b":155,
      "hex":"#3d889b",
      "file_location":"assets/colors_organized/hue_17/brightness_10/2020040416153000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":10,
      "vividness":10,
      "r":49,
      "g":134,
      "b":154,
      "hex":"#31869a",
      "file_location":"assets/colors_organized/hue_17/brightness_10/2020040416153001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":10,
      "vividness":11,
      "r":40,
      "g":132,
      "b":153,
      "hex":"#288499",
      "file_location":"assets/colors_organized/hue_17/brightness_10/2020040416153002-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":10,
      "vividness":12,
      "r":30,
      "g":130,
      "b":154,
      "hex":"#1e829a",
      "file_location":"assets/colors_organized/hue_17/brightness_10/2020040416153100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":10,
      "vividness":13,
      "r":19,
      "g":127,
      "b":155,
      "hex":"#137f9b",
      "file_location":"assets/colors_organized/hue_17/brightness_10/2020040416153101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":10,
      "vividness":14,
      "r":10,
      "g":125,
      "b":154,
      "hex":"#0a7d9a",
      "file_location":"assets/colors_organized/hue_17/brightness_10/2020040416153200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":11,
      "vividness":1,
      "r":158,
      "g":168,
      "b":169,
      "hex":"#9ea8a9",
      "file_location":"assets/colors_organized/hue_17/brightness_11/2020040416150701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":11,
      "vividness":2,
      "r":147,
      "g":165,
      "b":169,
      "hex":"#93a5a9",
      "file_location":"assets/colors_organized/hue_17/brightness_11/2020040416150800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":11,
      "vividness":3,
      "r":135,
      "g":162,
      "b":169,
      "hex":"#87a2a9",
      "file_location":"assets/colors_organized/hue_17/brightness_11/2020040416150801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":11,
      "vividness":4,
      "r":123,
      "g":160,
      "b":169,
      "hex":"#7ba0a9",
      "file_location":"assets/colors_organized/hue_17/brightness_11/2020040416150802-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":11,
      "vividness":5,
      "r":112,
      "g":159,
      "b":169,
      "hex":"#709fa9",
      "file_location":"assets/colors_organized/hue_17/brightness_11/2020040416150900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":11,
      "vividness":6,
      "r":100,
      "g":156,
      "b":169,
      "hex":"#649ca9",
      "file_location":"assets/colors_organized/hue_17/brightness_11/2020040416150901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":11,
      "vividness":7,
      "r":90,
      "g":153,
      "b":170,
      "hex":"#5a99aa",
      "file_location":"assets/colors_organized/hue_17/brightness_11/2020040416151000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":11,
      "vividness":8,
      "r":79,
      "g":152,
      "b":169,
      "hex":"#4f98a9",
      "file_location":"assets/colors_organized/hue_17/brightness_11/2020040416151001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":11,
      "vividness":9,
      "r":67,
      "g":149,
      "b":170,
      "hex":"#4395aa",
      "file_location":"assets/colors_organized/hue_17/brightness_11/2020040416151100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":11,
      "vividness":10,
      "r":55,
      "g":147,
      "b":168,
      "hex":"#3793a8",
      "file_location":"assets/colors_organized/hue_17/brightness_11/2020040416151101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":11,
      "vividness":11,
      "r":44,
      "g":144,
      "b":168,
      "hex":"#2c90a8",
      "file_location":"assets/colors_organized/hue_17/brightness_11/2020040416151200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":11,
      "vividness":12,
      "r":33,
      "g":143,
      "b":170,
      "hex":"#218faa",
      "file_location":"assets/colors_organized/hue_17/brightness_11/2020040416151201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":11,
      "vividness":13,
      "r":21,
      "g":141,
      "b":168,
      "hex":"#158da8",
      "file_location":"assets/colors_organized/hue_17/brightness_11/2020040416151202-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":11,
      "vividness":14,
      "r":9,
      "g":138,
      "b":169,
      "hex":"#098aa9",
      "file_location":"assets/colors_organized/hue_17/brightness_11/2020040416151300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":12,
      "vividness":1,
      "r":172,
      "g":182,
      "b":184,
      "hex":"#acb6b8",
      "file_location":"assets/colors_organized/hue_17/brightness_12/2020040417432200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":12,
      "vividness":2,
      "r":160,
      "g":179,
      "b":185,
      "hex":"#a0b3b9",
      "file_location":"assets/colors_organized/hue_17/brightness_12/2020040417432201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":12,
      "vividness":3,
      "r":147,
      "g":178,
      "b":183,
      "hex":"#93b2b7",
      "file_location":"assets/colors_organized/hue_17/brightness_12/2020040417432300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":12,
      "vividness":4,
      "r":136,
      "g":174,
      "b":183,
      "hex":"#88aeb7",
      "file_location":"assets/colors_organized/hue_17/brightness_12/2020040417432301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":12,
      "vividness":5,
      "r":123,
      "g":173,
      "b":184,
      "hex":"#7badb8",
      "file_location":"assets/colors_organized/hue_17/brightness_12/2020040417432302-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":12,
      "vividness":6,
      "r":110,
      "g":169,
      "b":183,
      "hex":"#6ea9b7",
      "file_location":"assets/colors_organized/hue_17/brightness_12/2020040417432400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":12,
      "vividness":7,
      "r":98,
      "g":167,
      "b":183,
      "hex":"#62a7b7",
      "file_location":"assets/colors_organized/hue_17/brightness_12/2020040417432401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":12,
      "vividness":8,
      "r":86,
      "g":164,
      "b":184,
      "hex":"#56a4b8",
      "file_location":"assets/colors_organized/hue_17/brightness_12/2020040417432500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":12,
      "vividness":9,
      "r":72,
      "g":163,
      "b":184,
      "hex":"#48a3b8",
      "file_location":"assets/colors_organized/hue_17/brightness_12/2020040417432501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":12,
      "vividness":10,
      "r":60,
      "g":160,
      "b":184,
      "hex":"#3ca0b8",
      "file_location":"assets/colors_organized/hue_17/brightness_12/2020040417432502-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":12,
      "vividness":11,
      "r":48,
      "g":158,
      "b":185,
      "hex":"#309eb9",
      "file_location":"assets/colors_organized/hue_17/brightness_12/2020040417432600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":12,
      "vividness":12,
      "r":36,
      "g":155,
      "b":185,
      "hex":"#249bb9",
      "file_location":"assets/colors_organized/hue_17/brightness_12/2020040417432601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":12,
      "vividness":13,
      "r":22,
      "g":152,
      "b":184,
      "hex":"#1698b8",
      "file_location":"assets/colors_organized/hue_17/brightness_12/2020040417432700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":12,
      "vividness":14,
      "r":12,
      "g":151,
      "b":184,
      "hex":"#0c97b8",
      "file_location":"assets/colors_organized/hue_17/brightness_12/2020040417432701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":13,
      "vividness":1,
      "r":187,
      "g":197,
      "b":199,
      "hex":"#bbc5c7",
      "file_location":"assets/colors_organized/hue_17/brightness_13/2020040417430701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":13,
      "vividness":2,
      "r":174,
      "g":195,
      "b":200,
      "hex":"#aec3c8",
      "file_location":"assets/colors_organized/hue_17/brightness_13/2020040417430800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":13,
      "vividness":3,
      "r":159,
      "g":192,
      "b":199,
      "hex":"#9fc0c7",
      "file_location":"assets/colors_organized/hue_17/brightness_13/2020040417430801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":13,
      "vividness":4,
      "r":146,
      "g":188,
      "b":200,
      "hex":"#92bcc8",
      "file_location":"assets/colors_organized/hue_17/brightness_13/2020040417430900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":13,
      "vividness":5,
      "r":133,
      "g":187,
      "b":199,
      "hex":"#85bbc7",
      "file_location":"assets/colors_organized/hue_17/brightness_13/2020040417430901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":13,
      "vividness":6,
      "r":118,
      "g":184,
      "b":200,
      "hex":"#76b8c8",
      "file_location":"assets/colors_organized/hue_17/brightness_13/2020040417431000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":13,
      "vividness":7,
      "r":105,
      "g":180,
      "b":199,
      "hex":"#69b4c7",
      "file_location":"assets/colors_organized/hue_17/brightness_13/2020040417431001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":13,
      "vividness":8,
      "r":92,
      "g":179,
      "b":199,
      "hex":"#5cb3c7",
      "file_location":"assets/colors_organized/hue_17/brightness_13/2020040417431100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":13,
      "vividness":9,
      "r":79,
      "g":175,
      "b":199,
      "hex":"#4fafc7",
      "file_location":"assets/colors_organized/hue_17/brightness_13/2020040417431101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":13,
      "vividness":10,
      "r":65,
      "g":173,
      "b":199,
      "hex":"#41adc7",
      "file_location":"assets/colors_organized/hue_17/brightness_13/2020040417431200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":13,
      "vividness":11,
      "r":52,
      "g":170,
      "b":198,
      "hex":"#34aac6",
      "file_location":"assets/colors_organized/hue_17/brightness_13/2020040417431201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":13,
      "vividness":12,
      "r":38,
      "g":167,
      "b":199,
      "hex":"#26a7c7",
      "file_location":"assets/colors_organized/hue_17/brightness_13/2020040417431202-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":13,
      "vividness":13,
      "r":27,
      "g":166,
      "b":199,
      "hex":"#1ba6c7",
      "file_location":"assets/colors_organized/hue_17/brightness_13/2020040417431300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":13,
      "vividness":14,
      "r":13,
      "g":163,
      "b":200,
      "hex":"#0da3c8",
      "file_location":"assets/colors_organized/hue_17/brightness_13/2020040417431301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":14,
      "vividness":1,
      "r":201,
      "g":212,
      "b":214,
      "hex":"#c9d4d6",
      "file_location":"assets/colors_organized/hue_17/brightness_14/2020040417425701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":14,
      "vividness":2,
      "r":185,
      "g":208,
      "b":214,
      "hex":"#b9d0d6",
      "file_location":"assets/colors_organized/hue_17/brightness_14/2020040417425800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":14,
      "vividness":3,
      "r":171,
      "g":205,
      "b":214,
      "hex":"#abcdd6",
      "file_location":"assets/colors_organized/hue_17/brightness_14/2020040417425801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":14,
      "vividness":4,
      "r":157,
      "g":204,
      "b":214,
      "hex":"#9dccd6",
      "file_location":"assets/colors_organized/hue_17/brightness_14/2020040417425900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":14,
      "vividness":5,
      "r":143,
      "g":201,
      "b":215,
      "hex":"#8fc9d7",
      "file_location":"assets/colors_organized/hue_17/brightness_14/2020040417425901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":14,
      "vividness":6,
      "r":129,
      "g":198,
      "b":214,
      "hex":"#81c6d6",
      "file_location":"assets/colors_organized/hue_17/brightness_14/2020040417430000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":14,
      "vividness":7,
      "r":114,
      "g":195,
      "b":214,
      "hex":"#72c3d6",
      "file_location":"assets/colors_organized/hue_17/brightness_14/2020040417430001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":14,
      "vividness":8,
      "r":100,
      "g":192,
      "b":215,
      "hex":"#64c0d7",
      "file_location":"assets/colors_organized/hue_17/brightness_14/2020040417430100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":14,
      "vividness":9,
      "r":84,
      "g":188,
      "b":213,
      "hex":"#54bcd5",
      "file_location":"assets/colors_organized/hue_17/brightness_14/2020040417430101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":14,
      "vividness":10,
      "r":70,
      "g":185,
      "b":214,
      "hex":"#46b9d6",
      "file_location":"assets/colors_organized/hue_17/brightness_14/2020040417430102-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":14,
      "vividness":11,
      "r":56,
      "g":183,
      "b":215,
      "hex":"#38b7d7",
      "file_location":"assets/colors_organized/hue_17/brightness_14/2020040417430200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":14,
      "vividness":12,
      "r":42,
      "g":181,
      "b":214,
      "hex":"#2ab5d6",
      "file_location":"assets/colors_organized/hue_17/brightness_14/2020040417430201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":14,
      "vividness":13,
      "r":28,
      "g":178,
      "b":215,
      "hex":"#1cb2d7",
      "file_location":"assets/colors_organized/hue_17/brightness_14/2020040417430300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":14,
      "vividness":14,
      "r":12,
      "g":174,
      "b":213,
      "hex":"#0caed5",
      "file_location":"assets/colors_organized/hue_17/brightness_14/2020040417430301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":15,
      "vividness":1,
      "r":215,
      "g":226,
      "b":228,
      "hex":"#d7e2e4",
      "file_location":"assets/colors_organized/hue_17/brightness_15/2020040417421100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":15,
      "vividness":2,
      "r":199,
      "g":224,
      "b":229,
      "hex":"#c7e0e5",
      "file_location":"assets/colors_organized/hue_17/brightness_15/2020040417421200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":15,
      "vividness":3,
      "r":183,
      "g":220,
      "b":229,
      "hex":"#b7dce5",
      "file_location":"assets/colors_organized/hue_17/brightness_15/2020040417421300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":15,
      "vividness":4,
      "r":168,
      "g":218,
      "b":229,
      "hex":"#a8dae5",
      "file_location":"assets/colors_organized/hue_17/brightness_15/2020040417421400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":15,
      "vividness":5,
      "r":153,
      "g":215,
      "b":230,
      "hex":"#99d7e6",
      "file_location":"assets/colors_organized/hue_17/brightness_15/2020040417421700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":15,
      "vividness":6,
      "r":136,
      "g":212,
      "b":228,
      "hex":"#88d4e4",
      "file_location":"assets/colors_organized/hue_17/brightness_15/2020040417421701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":15,
      "vividness":7,
      "r":122,
      "g":209,
      "b":229,
      "hex":"#7ad1e5",
      "file_location":"assets/colors_organized/hue_17/brightness_15/2020040417421800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":15,
      "vividness":8,
      "r":107,
      "g":205,
      "b":230,
      "hex":"#6bcde6",
      "file_location":"assets/colors_organized/hue_17/brightness_15/2020040417421801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":15,
      "vividness":9,
      "r":91,
      "g":202,
      "b":230,
      "hex":"#5bcae6",
      "file_location":"assets/colors_organized/hue_17/brightness_15/2020040417421900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":15,
      "vividness":10,
      "r":75,
      "g":198,
      "b":229,
      "hex":"#4bc6e5",
      "file_location":"assets/colors_organized/hue_17/brightness_15/2020040417421901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":15,
      "vividness":11,
      "r":61,
      "g":195,
      "b":230,
      "hex":"#3dc3e6",
      "file_location":"assets/colors_organized/hue_17/brightness_15/2020040417422000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":15,
      "vividness":12,
      "r":44,
      "g":192,
      "b":228,
      "hex":"#2cc0e4",
      "file_location":"assets/colors_organized/hue_17/brightness_15/2020040417422001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":15,
      "vividness":13,
      "r":30,
      "g":189,
      "b":229,
      "hex":"#1ebde5",
      "file_location":"assets/colors_organized/hue_17/brightness_15/2020040417422100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":17,
      "brightness":15,
      "vividness":14,
      "r":15,
      "g":186,
      "b":229,
      "hex":"#0fbae5",
      "file_location":"assets/colors_organized/hue_17/brightness_15/2020040417422101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":1,
      "vividness":1,
      "r":14,
      "g":18,
      "b":19,
      "hex":"#0e1213",
      "file_location":"assets/colors_organized/hue_18/brightness_01/2020040416194801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":1,
      "vividness":2,
      "r":10,
      "g":15,
      "b":19,
      "hex":"#0a0f13",
      "file_location":"assets/colors_organized/hue_18/brightness_01/2020040416195000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":1,
      "vividness":3,
      "r":6,
      "g":15,
      "b":20,
      "hex":"#060f14",
      "file_location":"assets/colors_organized/hue_18/brightness_01/2020040416195100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":1,
      "vividness":4,
      "r":6,
      "g":13,
      "b":19,
      "hex":"#060d13",
      "file_location":"assets/colors_organized/hue_18/brightness_01/2020040416195101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":1,
      "vividness":5,
      "r":2,
      "g":13,
      "b":19,
      "hex":"#020d13",
      "file_location":"assets/colors_organized/hue_18/brightness_01/2020040416195200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":1,
      "vividness":6,
      "r":0,
      "g":12,
      "b":20,
      "hex":"#000c14",
      "file_location":"assets/colors_organized/hue_18/brightness_01/2020040416195300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":2,
      "vividness":1,
      "r":28,
      "g":32,
      "b":35,
      "hex":"#1c2023",
      "file_location":"assets/colors_organized/hue_18/brightness_02/2020040416193801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":2,
      "vividness":2,
      "r":25,
      "g":30,
      "b":34,
      "hex":"#191e22",
      "file_location":"assets/colors_organized/hue_18/brightness_02/2020040416193900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":2,
      "vividness":3,
      "r":22,
      "g":29,
      "b":35,
      "hex":"#161d23",
      "file_location":"assets/colors_organized/hue_18/brightness_02/2020040416193901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":2,
      "vividness":4,
      "r":21,
      "g":30,
      "b":35,
      "hex":"#151e23",
      "file_location":"assets/colors_organized/hue_18/brightness_02/2020040416194000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":2,
      "vividness":5,
      "r":17,
      "g":28,
      "b":34,
      "hex":"#111c22",
      "file_location":"assets/colors_organized/hue_18/brightness_02/2020040416194001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":2,
      "vividness":6,
      "r":14,
      "g":27,
      "b":35,
      "hex":"#0e1b23",
      "file_location":"assets/colors_organized/hue_18/brightness_02/2020040416194100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":2,
      "vividness":7,
      "r":12,
      "g":27,
      "b":34,
      "hex":"#0c1b22",
      "file_location":"assets/colors_organized/hue_18/brightness_02/2020040416194101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":2,
      "vividness":8,
      "r":10,
      "g":24,
      "b":33,
      "hex":"#0a1821",
      "file_location":"assets/colors_organized/hue_18/brightness_02/2020040416194200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":2,
      "vividness":9,
      "r":9,
      "g":23,
      "b":34,
      "hex":"#091722",
      "file_location":"assets/colors_organized/hue_18/brightness_02/2020040416194201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":2,
      "vividness":10,
      "r":6,
      "g":22,
      "b":35,
      "hex":"#061623",
      "file_location":"assets/colors_organized/hue_18/brightness_02/2020040416194300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":2,
      "vividness":11,
      "r":3,
      "g":23,
      "b":34,
      "hex":"#031722",
      "file_location":"assets/colors_organized/hue_18/brightness_02/2020040416194301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":2,
      "vividness":12,
      "r":2,
      "g":21,
      "b":35,
      "hex":"#021523",
      "file_location":"assets/colors_organized/hue_18/brightness_02/2020040416194400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":3,
      "vividness":1,
      "r":46,
      "g":47,
      "b":49,
      "hex":"#2e2f31",
      "file_location":"assets/colors_organized/hue_18/brightness_03/2020040416192800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":3,
      "vividness":2,
      "r":43,
      "g":47,
      "b":50,
      "hex":"#2b2f32",
      "file_location":"assets/colors_organized/hue_18/brightness_03/2020040416192801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":3,
      "vividness":3,
      "r":40,
      "g":45,
      "b":49,
      "hex":"#282d31",
      "file_location":"assets/colors_organized/hue_18/brightness_03/2020040416192900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":3,
      "vividness":4,
      "r":35,
      "g":44,
      "b":49,
      "hex":"#232c31",
      "file_location":"assets/colors_organized/hue_18/brightness_03/2020040416192901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":3,
      "vividness":5,
      "r":34,
      "g":43,
      "b":50,
      "hex":"#222b32",
      "file_location":"assets/colors_organized/hue_18/brightness_03/2020040416193000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":3,
      "vividness":6,
      "r":29,
      "g":42,
      "b":50,
      "hex":"#1d2a32",
      "file_location":"assets/colors_organized/hue_18/brightness_03/2020040416193001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":3,
      "vividness":7,
      "r":27,
      "g":40,
      "b":49,
      "hex":"#1b2831",
      "file_location":"assets/colors_organized/hue_18/brightness_03/2020040416193100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":3,
      "vividness":8,
      "r":22,
      "g":39,
      "b":49,
      "hex":"#162731",
      "file_location":"assets/colors_organized/hue_18/brightness_03/2020040416193101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":3,
      "vividness":9,
      "r":20,
      "g":38,
      "b":50,
      "hex":"#142632",
      "file_location":"assets/colors_organized/hue_18/brightness_03/2020040416193102-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":3,
      "vividness":10,
      "r":14,
      "g":36,
      "b":49,
      "hex":"#0e2431",
      "file_location":"assets/colors_organized/hue_18/brightness_03/2020040416193200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":3,
      "vividness":11,
      "r":13,
      "g":35,
      "b":49,
      "hex":"#0d2331",
      "file_location":"assets/colors_organized/hue_18/brightness_03/2020040416193201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":3,
      "vividness":12,
      "r":10,
      "g":33,
      "b":49,
      "hex":"#0a2131",
      "file_location":"assets/colors_organized/hue_18/brightness_03/2020040416193300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":3,
      "vividness":13,
      "r":6,
      "g":32,
      "b":49,
      "hex":"#062031",
      "file_location":"assets/colors_organized/hue_18/brightness_03/2020040416193301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":3,
      "vividness":14,
      "r":2,
      "g":31,
      "b":49,
      "hex":"#021f31",
      "file_location":"assets/colors_organized/hue_18/brightness_03/2020040416193400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":4,
      "vividness":1,
      "r":59,
      "g":63,
      "b":66,
      "hex":"#3b3f42",
      "file_location":"assets/colors_organized/hue_18/brightness_04/2020040416191800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":4,
      "vividness":2,
      "r":56,
      "g":61,
      "b":65,
      "hex":"#383d41",
      "file_location":"assets/colors_organized/hue_18/brightness_04/2020040416191801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":4,
      "vividness":3,
      "r":51,
      "g":58,
      "b":64,
      "hex":"#333a40",
      "file_location":"assets/colors_organized/hue_18/brightness_04/2020040416191900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":4,
      "vividness":4,
      "r":47,
      "g":58,
      "b":64,
      "hex":"#2f3a40",
      "file_location":"assets/colors_organized/hue_18/brightness_04/2020040416191901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":4,
      "vividness":5,
      "r":43,
      "g":56,
      "b":65,
      "hex":"#2b3841",
      "file_location":"assets/colors_organized/hue_18/brightness_04/2020040416192000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":4,
      "vividness":6,
      "r":37,
      "g":54,
      "b":64,
      "hex":"#253640",
      "file_location":"assets/colors_organized/hue_18/brightness_04/2020040416192001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":4,
      "vividness":7,
      "r":33,
      "g":53,
      "b":64,
      "hex":"#213540",
      "file_location":"assets/colors_organized/hue_18/brightness_04/2020040416192100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":4,
      "vividness":8,
      "r":29,
      "g":51,
      "b":65,
      "hex":"#1d3341",
      "file_location":"assets/colors_organized/hue_18/brightness_04/2020040416192101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":4,
      "vividness":9,
      "r":27,
      "g":48,
      "b":65,
      "hex":"#1b3041",
      "file_location":"assets/colors_organized/hue_18/brightness_04/2020040416192102-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":4,
      "vividness":10,
      "r":21,
      "g":47,
      "b":64,
      "hex":"#152f40",
      "file_location":"assets/colors_organized/hue_18/brightness_04/2020040416192200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":4,
      "vividness":11,
      "r":17,
      "g":46,
      "b":64,
      "hex":"#112e40",
      "file_location":"assets/colors_organized/hue_18/brightness_04/2020040416192201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":4,
      "vividness":12,
      "r":13,
      "g":44,
      "b":65,
      "hex":"#0d2c41",
      "file_location":"assets/colors_organized/hue_18/brightness_04/2020040416192300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":4,
      "vividness":13,
      "r":6,
      "g":42,
      "b":64,
      "hex":"#062a40",
      "file_location":"assets/colors_organized/hue_18/brightness_04/2020040416192301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":4,
      "vividness":14,
      "r":2,
      "g":39,
      "b":65,
      "hex":"#022741",
      "file_location":"assets/colors_organized/hue_18/brightness_04/2020040416192400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":5,
      "vividness":1,
      "r":74,
      "g":78,
      "b":81,
      "hex":"#4a4e51",
      "file_location":"assets/colors_organized/hue_18/brightness_05/2020040416190800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":5,
      "vividness":2,
      "r":68,
      "g":76,
      "b":79,
      "hex":"#444c4f",
      "file_location":"assets/colors_organized/hue_18/brightness_05/2020040416190801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":5,
      "vividness":3,
      "r":64,
      "g":73,
      "b":80,
      "hex":"#404950",
      "file_location":"assets/colors_organized/hue_18/brightness_05/2020040416190900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":5,
      "vividness":4,
      "r":58,
      "g":71,
      "b":80,
      "hex":"#3a4750",
      "file_location":"assets/colors_organized/hue_18/brightness_05/2020040416190901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":5,
      "vividness":5,
      "r":52,
      "g":69,
      "b":79,
      "hex":"#34454f",
      "file_location":"assets/colors_organized/hue_18/brightness_05/2020040416191000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":5,
      "vividness":6,
      "r":48,
      "g":67,
      "b":81,
      "hex":"#304351",
      "file_location":"assets/colors_organized/hue_18/brightness_05/2020040416191001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":5,
      "vividness":7,
      "r":42,
      "g":65,
      "b":79,
      "hex":"#2a414f",
      "file_location":"assets/colors_organized/hue_18/brightness_05/2020040416191002-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":5,
      "vividness":8,
      "r":36,
      "g":63,
      "b":80,
      "hex":"#243f50",
      "file_location":"assets/colors_organized/hue_18/brightness_05/2020040416191100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":5,
      "vividness":9,
      "r":31,
      "g":59,
      "b":80,
      "hex":"#1f3b50",
      "file_location":"assets/colors_organized/hue_18/brightness_05/2020040416191101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":5,
      "vividness":10,
      "r":27,
      "g":58,
      "b":79,
      "hex":"#1b3a4f",
      "file_location":"assets/colors_organized/hue_18/brightness_05/2020040416191200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":5,
      "vividness":11,
      "r":21,
      "g":55,
      "b":80,
      "hex":"#153750",
      "file_location":"assets/colors_organized/hue_18/brightness_05/2020040416191201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":5,
      "vividness":12,
      "r":14,
      "g":54,
      "b":80,
      "hex":"#0e3650",
      "file_location":"assets/colors_organized/hue_18/brightness_05/2020040416191300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":5,
      "vividness":13,
      "r":10,
      "g":51,
      "b":79,
      "hex":"#0a334f",
      "file_location":"assets/colors_organized/hue_18/brightness_05/2020040416191301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":5,
      "vividness":14,
      "r":6,
      "g":50,
      "b":79,
      "hex":"#06324f",
      "file_location":"assets/colors_organized/hue_18/brightness_05/2020040416191400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":6,
      "vividness":1,
      "r":88,
      "g":92,
      "b":95,
      "hex":"#585c5f",
      "file_location":"assets/colors_organized/hue_18/brightness_06/2020040416185800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":6,
      "vividness":2,
      "r":81,
      "g":90,
      "b":95,
      "hex":"#515a5f",
      "file_location":"assets/colors_organized/hue_18/brightness_06/2020040416185801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":6,
      "vividness":3,
      "r":76,
      "g":86,
      "b":95,
      "hex":"#4c565f",
      "file_location":"assets/colors_organized/hue_18/brightness_06/2020040416185900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":6,
      "vividness":4,
      "r":68,
      "g":85,
      "b":95,
      "hex":"#44555f",
      "file_location":"assets/colors_organized/hue_18/brightness_06/2020040416185901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":6,
      "vividness":5,
      "r":63,
      "g":82,
      "b":96,
      "hex":"#3f5260",
      "file_location":"assets/colors_organized/hue_18/brightness_06/2020040416190000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":6,
      "vividness":6,
      "r":56,
      "g":79,
      "b":95,
      "hex":"#384f5f",
      "file_location":"assets/colors_organized/hue_18/brightness_06/2020040416190001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":6,
      "vividness":7,
      "r":50,
      "g":77,
      "b":94,
      "hex":"#324d5e",
      "file_location":"assets/colors_organized/hue_18/brightness_06/2020040416190002-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":6,
      "vividness":8,
      "r":43,
      "g":74,
      "b":94,
      "hex":"#2b4a5e",
      "file_location":"assets/colors_organized/hue_18/brightness_06/2020040416190100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":6,
      "vividness":9,
      "r":37,
      "g":72,
      "b":94,
      "hex":"#25485e",
      "file_location":"assets/colors_organized/hue_18/brightness_06/2020040416190101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":6,
      "vividness":10,
      "r":31,
      "g":68,
      "b":94,
      "hex":"#1f445e",
      "file_location":"assets/colors_organized/hue_18/brightness_06/2020040416190200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":6,
      "vividness":11,
      "r":23,
      "g":67,
      "b":94,
      "hex":"#17435e",
      "file_location":"assets/colors_organized/hue_18/brightness_06/2020040416190201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":6,
      "vividness":12,
      "r":19,
      "g":64,
      "b":95,
      "hex":"#13405f",
      "file_location":"assets/colors_organized/hue_18/brightness_06/2020040416190300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":6,
      "vividness":13,
      "r":13,
      "g":62,
      "b":95,
      "hex":"#0d3e5f",
      "file_location":"assets/colors_organized/hue_18/brightness_06/2020040416190301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":6,
      "vividness":14,
      "r":6,
      "g":58,
      "b":95,
      "hex":"#063a5f",
      "file_location":"assets/colors_organized/hue_18/brightness_06/2020040416190400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":7,
      "vividness":1,
      "r":95,
      "g":104,
      "b":109,
      "hex":"#5f686d",
      "file_location":"assets/colors_organized/hue_18/brightness_07/2020040416184800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":7,
      "vividness":2,
      "r":88,
      "g":101,
      "b":110,
      "hex":"#58656e",
      "file_location":"assets/colors_organized/hue_18/brightness_07/2020040416184801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":7,
      "vividness":3,
      "r":80,
      "g":98,
      "b":110,
      "hex":"#50626e",
      "file_location":"assets/colors_organized/hue_18/brightness_07/2020040416184900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":7,
      "vividness":4,
      "r":73,
      "g":95,
      "b":109,
      "hex":"#495f6d",
      "file_location":"assets/colors_organized/hue_18/brightness_07/2020040416184901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":7,
      "vividness":5,
      "r":65,
      "g":92,
      "b":109,
      "hex":"#415c6d",
      "file_location":"assets/colors_organized/hue_18/brightness_07/2020040416185000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":7,
      "vividness":6,
      "r":58,
      "g":89,
      "b":110,
      "hex":"#3a596e",
      "file_location":"assets/colors_organized/hue_18/brightness_07/2020040416185001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":7,
      "vividness":7,
      "r":50,
      "g":86,
      "b":110,
      "hex":"#32566e",
      "file_location":"assets/colors_organized/hue_18/brightness_07/2020040416185100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":7,
      "vividness":8,
      "r":43,
      "g":83,
      "b":109,
      "hex":"#2b536d",
      "file_location":"assets/colors_organized/hue_18/brightness_07/2020040416185101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":7,
      "vividness":9,
      "r":35,
      "g":80,
      "b":109,
      "hex":"#23506d",
      "file_location":"assets/colors_organized/hue_18/brightness_07/2020040416185200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":7,
      "vividness":10,
      "r":30,
      "g":76,
      "b":109,
      "hex":"#1e4c6d",
      "file_location":"assets/colors_organized/hue_18/brightness_07/2020040416185201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":7,
      "vividness":11,
      "r":21,
      "g":73,
      "b":110,
      "hex":"#15496e",
      "file_location":"assets/colors_organized/hue_18/brightness_07/2020040416185300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":7,
      "vividness":12,
      "r":13,
      "g":71,
      "b":109,
      "hex":"#0d476d",
      "file_location":"assets/colors_organized/hue_18/brightness_07/2020040416185301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":7,
      "vividness":13,
      "r":6,
      "g":68,
      "b":109,
      "hex":"#06446d",
      "file_location":"assets/colors_organized/hue_18/brightness_07/2020040416185400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":8,
      "vividness":1,
      "r":116,
      "g":121,
      "b":124,
      "hex":"#74797c",
      "file_location":"assets/colors_organized/hue_18/brightness_08/2020040416183700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":8,
      "vividness":2,
      "r":108,
      "g":119,
      "b":125,
      "hex":"#6c777d",
      "file_location":"assets/colors_organized/hue_18/brightness_08/2020040416183701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":8,
      "vividness":3,
      "r":100,
      "g":114,
      "b":123,
      "hex":"#64727b",
      "file_location":"assets/colors_organized/hue_18/brightness_08/2020040416183800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":8,
      "vividness":4,
      "r":91,
      "g":110,
      "b":124,
      "hex":"#5b6e7c",
      "file_location":"assets/colors_organized/hue_18/brightness_08/2020040416183801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":8,
      "vividness":5,
      "r":82,
      "g":108,
      "b":123,
      "hex":"#526c7b",
      "file_location":"assets/colors_organized/hue_18/brightness_08/2020040416183900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":8,
      "vividness":6,
      "r":73,
      "g":104,
      "b":124,
      "hex":"#49687c",
      "file_location":"assets/colors_organized/hue_18/brightness_08/2020040416183901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":8,
      "vividness":7,
      "r":65,
      "g":101,
      "b":123,
      "hex":"#41657b",
      "file_location":"assets/colors_organized/hue_18/brightness_08/2020040416184000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":8,
      "vividness":8,
      "r":58,
      "g":98,
      "b":124,
      "hex":"#3a627c",
      "file_location":"assets/colors_organized/hue_18/brightness_08/2020040416184001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":8,
      "vividness":9,
      "r":49,
      "g":94,
      "b":125,
      "hex":"#315e7d",
      "file_location":"assets/colors_organized/hue_18/brightness_08/2020040416184100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":8,
      "vividness":10,
      "r":41,
      "g":91,
      "b":124,
      "hex":"#295b7c",
      "file_location":"assets/colors_organized/hue_18/brightness_08/2020040416184101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":8,
      "vividness":11,
      "r":34,
      "g":88,
      "b":124,
      "hex":"#22587c",
      "file_location":"assets/colors_organized/hue_18/brightness_08/2020040416184200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":8,
      "vividness":12,
      "r":22,
      "g":84,
      "b":123,
      "hex":"#16547b",
      "file_location":"assets/colors_organized/hue_18/brightness_08/2020040416184201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":8,
      "vividness":13,
      "r":14,
      "g":81,
      "b":124,
      "hex":"#0e517c",
      "file_location":"assets/colors_organized/hue_18/brightness_08/2020040416184300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":8,
      "vividness":14,
      "r":6,
      "g":79,
      "b":124,
      "hex":"#064f7c",
      "file_location":"assets/colors_organized/hue_18/brightness_08/2020040416184301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":9,
      "vividness":1,
      "r":131,
      "g":136,
      "b":139,
      "hex":"#83888b",
      "file_location":"assets/colors_organized/hue_18/brightness_09/2020040416182601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":9,
      "vividness":2,
      "r":122,
      "g":133,
      "b":139,
      "hex":"#7a858b",
      "file_location":"assets/colors_organized/hue_18/brightness_09/2020040416182700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":9,
      "vividness":3,
      "r":110,
      "g":128,
      "b":138,
      "hex":"#6e808a",
      "file_location":"assets/colors_organized/hue_18/brightness_09/2020040416182701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":9,
      "vividness":4,
      "r":102,
      "g":124,
      "b":138,
      "hex":"#667c8a",
      "file_location":"assets/colors_organized/hue_18/brightness_09/2020040416182800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":9,
      "vividness":5,
      "r":93,
      "g":122,
      "b":140,
      "hex":"#5d7a8c",
      "file_location":"assets/colors_organized/hue_18/brightness_09/2020040416182801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":9,
      "vividness":6,
      "r":82,
      "g":117,
      "b":139,
      "hex":"#52758b",
      "file_location":"assets/colors_organized/hue_18/brightness_09/2020040416182900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":9,
      "vividness":7,
      "r":73,
      "g":113,
      "b":139,
      "hex":"#49718b",
      "file_location":"assets/colors_organized/hue_18/brightness_09/2020040416182901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":9,
      "vividness":8,
      "r":65,
      "g":110,
      "b":139,
      "hex":"#416e8b",
      "file_location":"assets/colors_organized/hue_18/brightness_09/2020040416183000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":9,
      "vividness":9,
      "r":55,
      "g":105,
      "b":138,
      "hex":"#37698a",
      "file_location":"assets/colors_organized/hue_18/brightness_09/2020040416183001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":9,
      "vividness":10,
      "r":46,
      "g":102,
      "b":139,
      "hex":"#2e668b",
      "file_location":"assets/colors_organized/hue_18/brightness_09/2020040416183100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":9,
      "vividness":11,
      "r":36,
      "g":98,
      "b":139,
      "hex":"#24628b",
      "file_location":"assets/colors_organized/hue_18/brightness_09/2020040416183101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":9,
      "vividness":12,
      "r":26,
      "g":94,
      "b":139,
      "hex":"#1a5e8b",
      "file_location":"assets/colors_organized/hue_18/brightness_09/2020040416183200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":9,
      "vividness":13,
      "r":17,
      "g":91,
      "b":138,
      "hex":"#115b8a",
      "file_location":"assets/colors_organized/hue_18/brightness_09/2020040416183201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":9,
      "vividness":14,
      "r":10,
      "g":87,
      "b":139,
      "hex":"#0a578b",
      "file_location":"assets/colors_organized/hue_18/brightness_09/2020040416183300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":10,
      "vividness":1,
      "r":145,
      "g":150,
      "b":154,
      "hex":"#91969a",
      "file_location":"assets/colors_organized/hue_18/brightness_10/2020040416181501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":10,
      "vividness":2,
      "r":133,
      "g":146,
      "b":154,
      "hex":"#85929a",
      "file_location":"assets/colors_organized/hue_18/brightness_10/2020040416181600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":10,
      "vividness":3,
      "r":123,
      "g":143,
      "b":154,
      "hex":"#7b8f9a",
      "file_location":"assets/colors_organized/hue_18/brightness_10/2020040416181601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":10,
      "vividness":4,
      "r":112,
      "g":138,
      "b":153,
      "hex":"#708a99",
      "file_location":"assets/colors_organized/hue_18/brightness_10/2020040416181700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":10,
      "vividness":5,
      "r":103,
      "g":134,
      "b":154,
      "hex":"#67869a",
      "file_location":"assets/colors_organized/hue_18/brightness_10/2020040416181701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":10,
      "vividness":6,
      "r":92,
      "g":130,
      "b":153,
      "hex":"#5c8299",
      "file_location":"assets/colors_organized/hue_18/brightness_10/2020040416181800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":10,
      "vividness":7,
      "r":81,
      "g":125,
      "b":154,
      "hex":"#517d9a",
      "file_location":"assets/colors_organized/hue_18/brightness_10/2020040416181801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":10,
      "vividness":8,
      "r":72,
      "g":121,
      "b":154,
      "hex":"#48799a",
      "file_location":"assets/colors_organized/hue_18/brightness_10/2020040416181900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":10,
      "vividness":9,
      "r":61,
      "g":117,
      "b":154,
      "hex":"#3d759a",
      "file_location":"assets/colors_organized/hue_18/brightness_10/2020040416181901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":10,
      "vividness":10,
      "r":50,
      "g":113,
      "b":154,
      "hex":"#32719a",
      "file_location":"assets/colors_organized/hue_18/brightness_10/2020040416182000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":10,
      "vividness":11,
      "r":41,
      "g":109,
      "b":154,
      "hex":"#296d9a",
      "file_location":"assets/colors_organized/hue_18/brightness_10/2020040416182001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":10,
      "vividness":12,
      "r":29,
      "g":105,
      "b":154,
      "hex":"#1d699a",
      "file_location":"assets/colors_organized/hue_18/brightness_10/2020040416182100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":10,
      "vividness":13,
      "r":20,
      "g":101,
      "b":154,
      "hex":"#14659a",
      "file_location":"assets/colors_organized/hue_18/brightness_10/2020040416182101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":10,
      "vividness":14,
      "r":10,
      "g":96,
      "b":153,
      "hex":"#0a6099",
      "file_location":"assets/colors_organized/hue_18/brightness_10/2020040416182200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":11,
      "vividness":1,
      "r":158,
      "g":166,
      "b":169,
      "hex":"#9ea6a9",
      "file_location":"assets/colors_organized/hue_18/brightness_11/2020040416180500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":11,
      "vividness":2,
      "r":147,
      "g":160,
      "b":169,
      "hex":"#93a0a9",
      "file_location":"assets/colors_organized/hue_18/brightness_11/2020040416180501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":11,
      "vividness":3,
      "r":134,
      "g":156,
      "b":169,
      "hex":"#869ca9",
      "file_location":"assets/colors_organized/hue_18/brightness_11/2020040416180600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":11,
      "vividness":4,
      "r":124,
      "g":151,
      "b":168,
      "hex":"#7c97a8",
      "file_location":"assets/colors_organized/hue_18/brightness_11/2020040416180601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":11,
      "vividness":5,
      "r":112,
      "g":147,
      "b":169,
      "hex":"#7093a9",
      "file_location":"assets/colors_organized/hue_18/brightness_11/2020040416180700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":11,
      "vividness":6,
      "r":101,
      "g":143,
      "b":168,
      "hex":"#658fa8",
      "file_location":"assets/colors_organized/hue_18/brightness_11/2020040416180701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":11,
      "vividness":7,
      "r":89,
      "g":138,
      "b":170,
      "hex":"#598aaa",
      "file_location":"assets/colors_organized/hue_18/brightness_11/2020040416180800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":11,
      "vividness":8,
      "r":79,
      "g":133,
      "b":169,
      "hex":"#4f85a9",
      "file_location":"assets/colors_organized/hue_18/brightness_11/2020040416180801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":11,
      "vividness":9,
      "r":66,
      "g":130,
      "b":168,
      "hex":"#4282a8",
      "file_location":"assets/colors_organized/hue_18/brightness_11/2020040416180900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":11,
      "vividness":10,
      "r":56,
      "g":124,
      "b":169,
      "hex":"#387ca9",
      "file_location":"assets/colors_organized/hue_18/brightness_11/2020040416180901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":11,
      "vividness":11,
      "r":45,
      "g":118,
      "b":169,
      "hex":"#2d76a9",
      "file_location":"assets/colors_organized/hue_18/brightness_11/2020040416181000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":11,
      "vividness":12,
      "r":34,
      "g":115,
      "b":170,
      "hex":"#2273aa",
      "file_location":"assets/colors_organized/hue_18/brightness_11/2020040416181001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":11,
      "vividness":13,
      "r":21,
      "g":110,
      "b":168,
      "hex":"#156ea8",
      "file_location":"assets/colors_organized/hue_18/brightness_11/2020040416181100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":11,
      "vividness":14,
      "r":10,
      "g":105,
      "b":169,
      "hex":"#0a69a9",
      "file_location":"assets/colors_organized/hue_18/brightness_11/2020040416181101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":12,
      "vividness":1,
      "r":172,
      "g":179,
      "b":185,
      "hex":"#acb3b9",
      "file_location":"assets/colors_organized/hue_18/brightness_12/2020040416175500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":12,
      "vividness":2,
      "r":160,
      "g":174,
      "b":183,
      "hex":"#a0aeb7",
      "file_location":"assets/colors_organized/hue_18/brightness_12/2020040416175501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":12,
      "vividness":3,
      "r":147,
      "g":169,
      "b":183,
      "hex":"#93a9b7",
      "file_location":"assets/colors_organized/hue_18/brightness_12/2020040416175600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":12,
      "vividness":4,
      "r":136,
      "g":164,
      "b":185,
      "hex":"#88a4b9",
      "file_location":"assets/colors_organized/hue_18/brightness_12/2020040416175601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":12,
      "vividness":5,
      "r":123,
      "g":161,
      "b":184,
      "hex":"#7ba1b8",
      "file_location":"assets/colors_organized/hue_18/brightness_12/2020040416175700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":12,
      "vividness":6,
      "r":110,
      "g":155,
      "b":184,
      "hex":"#6e9bb8",
      "file_location":"assets/colors_organized/hue_18/brightness_12/2020040416175701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":12,
      "vividness":7,
      "r":97,
      "g":150,
      "b":184,
      "hex":"#6196b8",
      "file_location":"assets/colors_organized/hue_18/brightness_12/2020040416175800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":12,
      "vividness":8,
      "r":86,
      "g":146,
      "b":183,
      "hex":"#5692b7",
      "file_location":"assets/colors_organized/hue_18/brightness_12/2020040416175801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":12,
      "vividness":9,
      "r":73,
      "g":140,
      "b":183,
      "hex":"#498cb7",
      "file_location":"assets/colors_organized/hue_18/brightness_12/2020040416175900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":12,
      "vividness":10,
      "r":61,
      "g":135,
      "b":184,
      "hex":"#3d87b8",
      "file_location":"assets/colors_organized/hue_18/brightness_12/2020040416175901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":12,
      "vividness":11,
      "r":49,
      "g":130,
      "b":185,
      "hex":"#3182b9",
      "file_location":"assets/colors_organized/hue_18/brightness_12/2020040416180000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":12,
      "vividness":12,
      "r":35,
      "g":125,
      "b":185,
      "hex":"#237db9",
      "file_location":"assets/colors_organized/hue_18/brightness_12/2020040416180001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":12,
      "vividness":13,
      "r":22,
      "g":120,
      "b":183,
      "hex":"#1678b7",
      "file_location":"assets/colors_organized/hue_18/brightness_12/2020040416180002-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":12,
      "vividness":14,
      "r":12,
      "g":115,
      "b":184,
      "hex":"#0c73b8",
      "file_location":"assets/colors_organized/hue_18/brightness_12/2020040416180100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":13,
      "vividness":1,
      "r":186,
      "g":195,
      "b":200,
      "hex":"#bac3c8",
      "file_location":"assets/colors_organized/hue_18/brightness_13/2020040416174500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":13,
      "vividness":2,
      "r":172,
      "g":189,
      "b":199,
      "hex":"#acbdc7",
      "file_location":"assets/colors_organized/hue_18/brightness_13/2020040416174501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":13,
      "vividness":3,
      "r":160,
      "g":183,
      "b":199,
      "hex":"#a0b7c7",
      "file_location":"assets/colors_organized/hue_18/brightness_13/2020040416174600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":13,
      "vividness":4,
      "r":146,
      "g":178,
      "b":199,
      "hex":"#92b2c7",
      "file_location":"assets/colors_organized/hue_18/brightness_13/2020040416174601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":13,
      "vividness":5,
      "r":133,
      "g":173,
      "b":199,
      "hex":"#85adc7",
      "file_location":"assets/colors_organized/hue_18/brightness_13/2020040416174700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":13,
      "vividness":6,
      "r":118,
      "g":167,
      "b":199,
      "hex":"#76a7c7",
      "file_location":"assets/colors_organized/hue_18/brightness_13/2020040416174701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":13,
      "vividness":7,
      "r":107,
      "g":163,
      "b":200,
      "hex":"#6ba3c8",
      "file_location":"assets/colors_organized/hue_18/brightness_13/2020040416174702-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":13,
      "vividness":8,
      "r":93,
      "g":158,
      "b":200,
      "hex":"#5d9ec8",
      "file_location":"assets/colors_organized/hue_18/brightness_13/2020040416174800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":13,
      "vividness":9,
      "r":79,
      "g":151,
      "b":199,
      "hex":"#4f97c7",
      "file_location":"assets/colors_organized/hue_18/brightness_13/2020040416174801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":13,
      "vividness":10,
      "r":65,
      "g":146,
      "b":199,
      "hex":"#4192c7",
      "file_location":"assets/colors_organized/hue_18/brightness_13/2020040416174900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":13,
      "vividness":11,
      "r":52,
      "g":141,
      "b":199,
      "hex":"#348dc7",
      "file_location":"assets/colors_organized/hue_18/brightness_13/2020040416174901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":13,
      "vividness":12,
      "r":38,
      "g":136,
      "b":199,
      "hex":"#2688c7",
      "file_location":"assets/colors_organized/hue_18/brightness_13/2020040416175000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":13,
      "vividness":13,
      "r":26,
      "g":131,
      "b":199,
      "hex":"#1a83c7",
      "file_location":"assets/colors_organized/hue_18/brightness_13/2020040416175001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":13,
      "vividness":14,
      "r":13,
      "g":125,
      "b":199,
      "hex":"#0d7dc7",
      "file_location":"assets/colors_organized/hue_18/brightness_13/2020040416175100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":14,
      "vividness":1,
      "r":200,
      "g":209,
      "b":214,
      "hex":"#c8d1d6",
      "file_location":"assets/colors_organized/hue_18/brightness_14/2020040416173401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":14,
      "vividness":2,
      "r":185,
      "g":203,
      "b":213,
      "hex":"#b9cbd5",
      "file_location":"assets/colors_organized/hue_18/brightness_14/2020040416173500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":14,
      "vividness":3,
      "r":171,
      "g":198,
      "b":215,
      "hex":"#abc6d7",
      "file_location":"assets/colors_organized/hue_18/brightness_14/2020040416173501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":14,
      "vividness":4,
      "r":157,
      "g":192,
      "b":214,
      "hex":"#9dc0d6",
      "file_location":"assets/colors_organized/hue_18/brightness_14/2020040416173600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":14,
      "vividness":5,
      "r":142,
      "g":186,
      "b":215,
      "hex":"#8ebad7",
      "file_location":"assets/colors_organized/hue_18/brightness_14/2020040416173601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":14,
      "vividness":6,
      "r":128,
      "g":181,
      "b":215,
      "hex":"#80b5d7",
      "file_location":"assets/colors_organized/hue_18/brightness_14/2020040416173602-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":14,
      "vividness":7,
      "r":113,
      "g":175,
      "b":214,
      "hex":"#71afd6",
      "file_location":"assets/colors_organized/hue_18/brightness_14/2020040416173700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":14,
      "vividness":8,
      "r":100,
      "g":168,
      "b":215,
      "hex":"#64a8d7",
      "file_location":"assets/colors_organized/hue_18/brightness_14/2020040416173701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":14,
      "vividness":9,
      "r":86,
      "g":163,
      "b":215,
      "hex":"#56a3d7",
      "file_location":"assets/colors_organized/hue_18/brightness_14/2020040416173800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":14,
      "vividness":10,
      "r":71,
      "g":157,
      "b":214,
      "hex":"#479dd6",
      "file_location":"assets/colors_organized/hue_18/brightness_14/2020040416173801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":14,
      "vividness":11,
      "r":56,
      "g":152,
      "b":213,
      "hex":"#3898d5",
      "file_location":"assets/colors_organized/hue_18/brightness_14/2020040416173900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":14,
      "vividness":12,
      "r":41,
      "g":146,
      "b":214,
      "hex":"#2992d6",
      "file_location":"assets/colors_organized/hue_18/brightness_14/2020040416173901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":14,
      "vividness":13,
      "r":28,
      "g":140,
      "b":214,
      "hex":"#1c8cd6",
      "file_location":"assets/colors_organized/hue_18/brightness_14/2020040416174000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":14,
      "vividness":14,
      "r":13,
      "g":134,
      "b":215,
      "hex":"#0d86d7",
      "file_location":"assets/colors_organized/hue_18/brightness_14/2020040416174001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":15,
      "vividness":1,
      "r":214,
      "g":223,
      "b":228,
      "hex":"#d6dfe4",
      "file_location":"assets/colors_organized/hue_18/brightness_15/2020040416172401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":15,
      "vividness":2,
      "r":198,
      "g":218,
      "b":229,
      "hex":"#c6dae5",
      "file_location":"assets/colors_organized/hue_18/brightness_15/2020040416172402-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":15,
      "vividness":3,
      "r":183,
      "g":212,
      "b":230,
      "hex":"#b7d4e6",
      "file_location":"assets/colors_organized/hue_18/brightness_15/2020040416172500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":15,
      "vividness":4,
      "r":168,
      "g":206,
      "b":229,
      "hex":"#a8cee5",
      "file_location":"assets/colors_organized/hue_18/brightness_15/2020040416172501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":15,
      "vividness":5,
      "r":152,
      "g":199,
      "b":229,
      "hex":"#98c7e5",
      "file_location":"assets/colors_organized/hue_18/brightness_15/2020040416172600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":15,
      "vividness":6,
      "r":137,
      "g":193,
      "b":230,
      "hex":"#89c1e6",
      "file_location":"assets/colors_organized/hue_18/brightness_15/2020040416172601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":15,
      "vividness":7,
      "r":122,
      "g":187,
      "b":229,
      "hex":"#7abbe5",
      "file_location":"assets/colors_organized/hue_18/brightness_15/2020040416172700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":15,
      "vividness":8,
      "r":107,
      "g":181,
      "b":230,
      "hex":"#6bb5e6",
      "file_location":"assets/colors_organized/hue_18/brightness_15/2020040416172701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":15,
      "vividness":9,
      "r":92,
      "g":174,
      "b":230,
      "hex":"#5caee6",
      "file_location":"assets/colors_organized/hue_18/brightness_15/2020040416172800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":15,
      "vividness":10,
      "r":76,
      "g":169,
      "b":230,
      "hex":"#4ca9e6",
      "file_location":"assets/colors_organized/hue_18/brightness_15/2020040416172801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":15,
      "vividness":11,
      "r":60,
      "g":161,
      "b":229,
      "hex":"#3ca1e5",
      "file_location":"assets/colors_organized/hue_18/brightness_15/2020040416172900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":15,
      "vividness":12,
      "r":44,
      "g":156,
      "b":228,
      "hex":"#2c9ce4",
      "file_location":"assets/colors_organized/hue_18/brightness_15/2020040416172901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":15,
      "vividness":13,
      "r":30,
      "g":149,
      "b":229,
      "hex":"#1e95e5",
      "file_location":"assets/colors_organized/hue_18/brightness_15/2020040416173000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":18,
      "brightness":15,
      "vividness":14,
      "r":15,
      "g":143,
      "b":230,
      "hex":"#0f8fe6",
      "file_location":"assets/colors_organized/hue_18/brightness_15/2020040416173001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":1,
      "vividness":1,
      "r":18,
      "g":18,
      "b":20,
      "hex":"#121214",
      "file_location":"assets/colors_organized/hue_19/brightness_01/2020040416231700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":1,
      "vividness":2,
      "r":11,
      "g":14,
      "b":19,
      "hex":"#0b0e13",
      "file_location":"assets/colors_organized/hue_19/brightness_01/2020040416231801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":1,
      "vividness":3,
      "r":9,
      "g":12,
      "b":19,
      "hex":"#090c13",
      "file_location":"assets/colors_organized/hue_19/brightness_01/2020040416231900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":1,
      "vividness":4,
      "r":7,
      "g":10,
      "b":19,
      "hex":"#070a13",
      "file_location":"assets/colors_organized/hue_19/brightness_01/2020040416232100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":1,
      "vividness":5,
      "r":3,
      "g":10,
      "b":20,
      "hex":"#030a14",
      "file_location":"assets/colors_organized/hue_19/brightness_01/2020040416232101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":1,
      "vividness":6,
      "r":0,
      "g":7,
      "b":20,
      "hex":"#000714",
      "file_location":"assets/colors_organized/hue_19/brightness_01/2020040416232201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":2,
      "vividness":1,
      "r":30,
      "g":31,
      "b":35,
      "hex":"#1e1f23",
      "file_location":"assets/colors_organized/hue_19/brightness_02/2020040416230700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":2,
      "vividness":2,
      "r":27,
      "g":31,
      "b":34,
      "hex":"#1b1f22",
      "file_location":"assets/colors_organized/hue_19/brightness_02/2020040416230701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":2,
      "vividness":3,
      "r":23,
      "g":28,
      "b":34,
      "hex":"#171c22",
      "file_location":"assets/colors_organized/hue_19/brightness_02/2020040416230800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":2,
      "vividness":4,
      "r":22,
      "g":26,
      "b":35,
      "hex":"#161a23",
      "file_location":"assets/colors_organized/hue_19/brightness_02/2020040416230801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":2,
      "vividness":5,
      "r":18,
      "g":25,
      "b":35,
      "hex":"#121923",
      "file_location":"assets/colors_organized/hue_19/brightness_02/2020040416230900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":2,
      "vividness":6,
      "r":14,
      "g":22,
      "b":33,
      "hex":"#0e1621",
      "file_location":"assets/colors_organized/hue_19/brightness_02/2020040416230901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":2,
      "vividness":7,
      "r":12,
      "g":22,
      "b":34,
      "hex":"#0c1622",
      "file_location":"assets/colors_organized/hue_19/brightness_02/2020040416231000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":2,
      "vividness":8,
      "r":9,
      "g":21,
      "b":33,
      "hex":"#091521",
      "file_location":"assets/colors_organized/hue_19/brightness_02/2020040416231001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":2,
      "vividness":9,
      "r":10,
      "g":19,
      "b":34,
      "hex":"#0a1322",
      "file_location":"assets/colors_organized/hue_19/brightness_02/2020040416231100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":2,
      "vividness":10,
      "r":6,
      "g":18,
      "b":34,
      "hex":"#061222",
      "file_location":"assets/colors_organized/hue_19/brightness_02/2020040416231101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":2,
      "vividness":11,
      "r":2,
      "g":15,
      "b":34,
      "hex":"#020f22",
      "file_location":"assets/colors_organized/hue_19/brightness_02/2020040416231102-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":3,
      "vividness":1,
      "r":42,
      "g":46,
      "b":49,
      "hex":"#2a2e31",
      "file_location":"assets/colors_organized/hue_19/brightness_03/2020040416225700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":3,
      "vividness":2,
      "r":39,
      "g":44,
      "b":50,
      "hex":"#272c32",
      "file_location":"assets/colors_organized/hue_19/brightness_03/2020040416225701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":3,
      "vividness":3,
      "r":35,
      "g":42,
      "b":50,
      "hex":"#232a32",
      "file_location":"assets/colors_organized/hue_19/brightness_03/2020040416225800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":3,
      "vividness":4,
      "r":33,
      "g":40,
      "b":50,
      "hex":"#212832",
      "file_location":"assets/colors_organized/hue_19/brightness_03/2020040416225801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":3,
      "vividness":5,
      "r":30,
      "g":38,
      "b":49,
      "hex":"#1e2631",
      "file_location":"assets/colors_organized/hue_19/brightness_03/2020040416225900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":3,
      "vividness":6,
      "r":26,
      "g":36,
      "b":48,
      "hex":"#1a2430",
      "file_location":"assets/colors_organized/hue_19/brightness_03/2020040416225901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":3,
      "vividness":7,
      "r":22,
      "g":34,
      "b":50,
      "hex":"#162232",
      "file_location":"assets/colors_organized/hue_19/brightness_03/2020040416230000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":3,
      "vividness":8,
      "r":19,
      "g":32,
      "b":49,
      "hex":"#132031",
      "file_location":"assets/colors_organized/hue_19/brightness_03/2020040416230001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":3,
      "vividness":9,
      "r":14,
      "g":29,
      "b":48,
      "hex":"#0e1d30",
      "file_location":"assets/colors_organized/hue_19/brightness_03/2020040416230100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":3,
      "vividness":10,
      "r":13,
      "g":28,
      "b":49,
      "hex":"#0d1c31",
      "file_location":"assets/colors_organized/hue_19/brightness_03/2020040416230101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":3,
      "vividness":11,
      "r":9,
      "g":27,
      "b":49,
      "hex":"#091b31",
      "file_location":"assets/colors_organized/hue_19/brightness_03/2020040416230102-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":3,
      "vividness":12,
      "r":7,
      "g":23,
      "b":49,
      "hex":"#071731",
      "file_location":"assets/colors_organized/hue_19/brightness_03/2020040416230200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":3,
      "vividness":13,
      "r":2,
      "g":22,
      "b":49,
      "hex":"#021631",
      "file_location":"assets/colors_organized/hue_19/brightness_03/2020040416230201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":4,
      "vividness":1,
      "r":60,
      "g":61,
      "b":65,
      "hex":"#3c3d41",
      "file_location":"assets/colors_organized/hue_19/brightness_04/2020040416224601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":4,
      "vividness":2,
      "r":55,
      "g":58,
      "b":65,
      "hex":"#373a41",
      "file_location":"assets/colors_organized/hue_19/brightness_04/2020040416224700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":4,
      "vividness":3,
      "r":52,
      "g":56,
      "b":65,
      "hex":"#343841",
      "file_location":"assets/colors_organized/hue_19/brightness_04/2020040416224701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":4,
      "vividness":4,
      "r":46,
      "g":54,
      "b":65,
      "hex":"#2e3641",
      "file_location":"assets/colors_organized/hue_19/brightness_04/2020040416224800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":4,
      "vividness":5,
      "r":42,
      "g":52,
      "b":64,
      "hex":"#2a3440",
      "file_location":"assets/colors_organized/hue_19/brightness_04/2020040416224801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":4,
      "vividness":6,
      "r":39,
      "g":48,
      "b":65,
      "hex":"#273041",
      "file_location":"assets/colors_organized/hue_19/brightness_04/2020040416224900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":4,
      "vividness":7,
      "r":33,
      "g":46,
      "b":65,
      "hex":"#212e41",
      "file_location":"assets/colors_organized/hue_19/brightness_04/2020040416224901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":4,
      "vividness":8,
      "r":29,
      "g":44,
      "b":65,
      "hex":"#1d2c41",
      "file_location":"assets/colors_organized/hue_19/brightness_04/2020040416225000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":4,
      "vividness":9,
      "r":26,
      "g":41,
      "b":64,
      "hex":"#1a2940",
      "file_location":"assets/colors_organized/hue_19/brightness_04/2020040416225001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":4,
      "vividness":10,
      "r":21,
      "g":38,
      "b":64,
      "hex":"#152640",
      "file_location":"assets/colors_organized/hue_19/brightness_04/2020040416225100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":4,
      "vividness":11,
      "r":16,
      "g":35,
      "b":65,
      "hex":"#102341",
      "file_location":"assets/colors_organized/hue_19/brightness_04/2020040416225101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":4,
      "vividness":12,
      "r":13,
      "g":34,
      "b":65,
      "hex":"#0d2241",
      "file_location":"assets/colors_organized/hue_19/brightness_04/2020040416225200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":4,
      "vividness":13,
      "r":6,
      "g":30,
      "b":64,
      "hex":"#061e40",
      "file_location":"assets/colors_organized/hue_19/brightness_04/2020040416225201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":4,
      "vividness":14,
      "r":2,
      "g":28,
      "b":65,
      "hex":"#021c41",
      "file_location":"assets/colors_organized/hue_19/brightness_04/2020040416225300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":5,
      "vividness":1,
      "r":75,
      "g":76,
      "b":80,
      "hex":"#4b4c50",
      "file_location":"assets/colors_organized/hue_19/brightness_05/2020040416223601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":5,
      "vividness":2,
      "r":68,
      "g":73,
      "b":79,
      "hex":"#44494f",
      "file_location":"assets/colors_organized/hue_19/brightness_05/2020040416223700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":5,
      "vividness":3,
      "r":63,
      "g":70,
      "b":80,
      "hex":"#3f4650",
      "file_location":"assets/colors_organized/hue_19/brightness_05/2020040416223701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":5,
      "vividness":4,
      "r":58,
      "g":68,
      "b":80,
      "hex":"#3a4450",
      "file_location":"assets/colors_organized/hue_19/brightness_05/2020040416223800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":5,
      "vividness":5,
      "r":52,
      "g":64,
      "b":80,
      "hex":"#344050",
      "file_location":"assets/colors_organized/hue_19/brightness_05/2020040416223801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":5,
      "vividness":6,
      "r":47,
      "g":60,
      "b":79,
      "hex":"#2f3c4f",
      "file_location":"assets/colors_organized/hue_19/brightness_05/2020040416223900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":5,
      "vividness":7,
      "r":41,
      "g":57,
      "b":80,
      "hex":"#293950",
      "file_location":"assets/colors_organized/hue_19/brightness_05/2020040416223901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":5,
      "vividness":8,
      "r":36,
      "g":53,
      "b":79,
      "hex":"#24354f",
      "file_location":"assets/colors_organized/hue_19/brightness_05/2020040416224000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":5,
      "vividness":9,
      "r":31,
      "g":50,
      "b":80,
      "hex":"#1f3250",
      "file_location":"assets/colors_organized/hue_19/brightness_05/2020040416224001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":5,
      "vividness":10,
      "r":27,
      "g":47,
      "b":80,
      "hex":"#1b2f50",
      "file_location":"assets/colors_organized/hue_19/brightness_05/2020040416224100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":5,
      "vividness":11,
      "r":21,
      "g":43,
      "b":80,
      "hex":"#152b50",
      "file_location":"assets/colors_organized/hue_19/brightness_05/2020040416224101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":5,
      "vividness":12,
      "r":15,
      "g":40,
      "b":80,
      "hex":"#0f2850",
      "file_location":"assets/colors_organized/hue_19/brightness_05/2020040416224200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":5,
      "vividness":13,
      "r":9,
      "g":38,
      "b":80,
      "hex":"#092650",
      "file_location":"assets/colors_organized/hue_19/brightness_05/2020040416224201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":5,
      "vividness":14,
      "r":6,
      "g":35,
      "b":79,
      "hex":"#06234f",
      "file_location":"assets/colors_organized/hue_19/brightness_05/2020040416224300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":6,
      "vividness":1,
      "r":87,
      "g":91,
      "b":94,
      "hex":"#575b5e",
      "file_location":"assets/colors_organized/hue_19/brightness_06/2020040416222601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":6,
      "vividness":2,
      "r":82,
      "g":86,
      "b":95,
      "hex":"#52565f",
      "file_location":"assets/colors_organized/hue_19/brightness_06/2020040416222602-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":6,
      "vividness":3,
      "r":75,
      "g":83,
      "b":94,
      "hex":"#4b535e",
      "file_location":"assets/colors_organized/hue_19/brightness_06/2020040416222700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":6,
      "vividness":4,
      "r":70,
      "g":79,
      "b":96,
      "hex":"#464f60",
      "file_location":"assets/colors_organized/hue_19/brightness_06/2020040416222701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":6,
      "vividness":5,
      "r":63,
      "g":76,
      "b":95,
      "hex":"#3f4c5f",
      "file_location":"assets/colors_organized/hue_19/brightness_06/2020040416222800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":6,
      "vividness":6,
      "r":56,
      "g":72,
      "b":95,
      "hex":"#38485f",
      "file_location":"assets/colors_organized/hue_19/brightness_06/2020040416222801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":6,
      "vividness":7,
      "r":50,
      "g":67,
      "b":93,
      "hex":"#32435d",
      "file_location":"assets/colors_organized/hue_19/brightness_06/2020040416222900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":6,
      "vividness":8,
      "r":43,
      "g":64,
      "b":95,
      "hex":"#2b405f",
      "file_location":"assets/colors_organized/hue_19/brightness_06/2020040416222901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":6,
      "vividness":9,
      "r":37,
      "g":60,
      "b":94,
      "hex":"#253c5e",
      "file_location":"assets/colors_organized/hue_19/brightness_06/2020040416223000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":6,
      "vividness":10,
      "r":31,
      "g":57,
      "b":94,
      "hex":"#1f395e",
      "file_location":"assets/colors_organized/hue_19/brightness_06/2020040416223001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":6,
      "vividness":11,
      "r":24,
      "g":53,
      "b":95,
      "hex":"#18355f",
      "file_location":"assets/colors_organized/hue_19/brightness_06/2020040416223100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":6,
      "vividness":12,
      "r":20,
      "g":48,
      "b":95,
      "hex":"#14305f",
      "file_location":"assets/colors_organized/hue_19/brightness_06/2020040416223101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":6,
      "vividness":13,
      "r":13,
      "g":45,
      "b":94,
      "hex":"#0d2d5e",
      "file_location":"assets/colors_organized/hue_19/brightness_06/2020040416223200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":6,
      "vividness":14,
      "r":6,
      "g":41,
      "b":95,
      "hex":"#06295f",
      "file_location":"assets/colors_organized/hue_19/brightness_06/2020040416223201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":7,
      "vividness":1,
      "r":102,
      "g":105,
      "b":110,
      "hex":"#66696e",
      "file_location":"assets/colors_organized/hue_19/brightness_07/2020040416221501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":7,
      "vividness":2,
      "r":94,
      "g":101,
      "b":109,
      "hex":"#5e656d",
      "file_location":"assets/colors_organized/hue_19/brightness_07/2020040416221600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":7,
      "vividness":3,
      "r":88,
      "g":96,
      "b":109,
      "hex":"#58606d",
      "file_location":"assets/colors_organized/hue_19/brightness_07/2020040416221601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":7,
      "vividness":4,
      "r":79,
      "g":92,
      "b":109,
      "hex":"#4f5c6d",
      "file_location":"assets/colors_organized/hue_19/brightness_07/2020040416221700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":7,
      "vividness":5,
      "r":73,
      "g":88,
      "b":109,
      "hex":"#49586d",
      "file_location":"assets/colors_organized/hue_19/brightness_07/2020040416221701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":7,
      "vividness":6,
      "r":66,
      "g":83,
      "b":109,
      "hex":"#42536d",
      "file_location":"assets/colors_organized/hue_19/brightness_07/2020040416221800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":7,
      "vividness":7,
      "r":58,
      "g":79,
      "b":110,
      "hex":"#3a4f6e",
      "file_location":"assets/colors_organized/hue_19/brightness_07/2020040416221801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":7,
      "vividness":8,
      "r":50,
      "g":74,
      "b":110,
      "hex":"#324a6e",
      "file_location":"assets/colors_organized/hue_19/brightness_07/2020040416221900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":7,
      "vividness":9,
      "r":43,
      "g":71,
      "b":110,
      "hex":"#2b476e",
      "file_location":"assets/colors_organized/hue_19/brightness_07/2020040416221901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":7,
      "vividness":10,
      "r":35,
      "g":66,
      "b":110,
      "hex":"#23426e",
      "file_location":"assets/colors_organized/hue_19/brightness_07/2020040416222000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":7,
      "vividness":11,
      "r":29,
      "g":61,
      "b":110,
      "hex":"#1d3d6e",
      "file_location":"assets/colors_organized/hue_19/brightness_07/2020040416222001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":7,
      "vividness":12,
      "r":21,
      "g":57,
      "b":109,
      "hex":"#15396d",
      "file_location":"assets/colors_organized/hue_19/brightness_07/2020040416222100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":7,
      "vividness":13,
      "r":13,
      "g":52,
      "b":111,
      "hex":"#0d346f",
      "file_location":"assets/colors_organized/hue_19/brightness_07/2020040416222101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":7,
      "vividness":14,
      "r":6,
      "g":47,
      "b":109,
      "hex":"#062f6d",
      "file_location":"assets/colors_organized/hue_19/brightness_07/2020040416222200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":8,
      "vividness":1,
      "r":116,
      "g":119,
      "b":124,
      "hex":"#74777c",
      "file_location":"assets/colors_organized/hue_19/brightness_08/2020040416220500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":8,
      "vividness":2,
      "r":107,
      "g":114,
      "b":124,
      "hex":"#6b727c",
      "file_location":"assets/colors_organized/hue_19/brightness_08/2020040416220501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":8,
      "vividness":3,
      "r":100,
      "g":109,
      "b":124,
      "hex":"#646d7c",
      "file_location":"assets/colors_organized/hue_19/brightness_08/2020040416220600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":8,
      "vividness":4,
      "r":91,
      "g":104,
      "b":123,
      "hex":"#5b687b",
      "file_location":"assets/colors_organized/hue_19/brightness_08/2020040416220601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":8,
      "vividness":5,
      "r":83,
      "g":99,
      "b":124,
      "hex":"#53637c",
      "file_location":"assets/colors_organized/hue_19/brightness_08/2020040416220700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":8,
      "vividness":6,
      "r":73,
      "g":94,
      "b":123,
      "hex":"#495e7b",
      "file_location":"assets/colors_organized/hue_19/brightness_08/2020040416220701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":8,
      "vividness":7,
      "r":66,
      "g":89,
      "b":123,
      "hex":"#42597b",
      "file_location":"assets/colors_organized/hue_19/brightness_08/2020040416220800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":8,
      "vividness":8,
      "r":57,
      "g":85,
      "b":124,
      "hex":"#39557c",
      "file_location":"assets/colors_organized/hue_19/brightness_08/2020040416220801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":8,
      "vividness":9,
      "r":48,
      "g":79,
      "b":125,
      "hex":"#304f7d",
      "file_location":"assets/colors_organized/hue_19/brightness_08/2020040416220900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":8,
      "vividness":10,
      "r":41,
      "g":74,
      "b":125,
      "hex":"#294a7d",
      "file_location":"assets/colors_organized/hue_19/brightness_08/2020040416220901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":8,
      "vividness":11,
      "r":34,
      "g":69,
      "b":123,
      "hex":"#22457b",
      "file_location":"assets/colors_organized/hue_19/brightness_08/2020040416221000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":8,
      "vividness":12,
      "r":22,
      "g":64,
      "b":124,
      "hex":"#16407c",
      "file_location":"assets/colors_organized/hue_19/brightness_08/2020040416221001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":8,
      "vividness":13,
      "r":15,
      "g":59,
      "b":124,
      "hex":"#0f3b7c",
      "file_location":"assets/colors_organized/hue_19/brightness_08/2020040416221100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":8,
      "vividness":14,
      "r":6,
      "g":53,
      "b":123,
      "hex":"#06357b",
      "file_location":"assets/colors_organized/hue_19/brightness_08/2020040416221101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":9,
      "vividness":1,
      "r":129,
      "g":134,
      "b":138,
      "hex":"#81868a",
      "file_location":"assets/colors_organized/hue_19/brightness_09/2020040416215401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":9,
      "vividness":2,
      "r":120,
      "g":128,
      "b":139,
      "hex":"#78808b",
      "file_location":"assets/colors_organized/hue_19/brightness_09/2020040416215500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":9,
      "vividness":3,
      "r":111,
      "g":123,
      "b":139,
      "hex":"#6f7b8b",
      "file_location":"assets/colors_organized/hue_19/brightness_09/2020040416215501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":9,
      "vividness":4,
      "r":102,
      "g":117,
      "b":140,
      "hex":"#66758c",
      "file_location":"assets/colors_organized/hue_19/brightness_09/2020040416215600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":9,
      "vividness":5,
      "r":94,
      "g":111,
      "b":139,
      "hex":"#5e6f8b",
      "file_location":"assets/colors_organized/hue_19/brightness_09/2020040416215601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":9,
      "vividness":6,
      "r":83,
      "g":106,
      "b":138,
      "hex":"#536a8a",
      "file_location":"assets/colors_organized/hue_19/brightness_09/2020040416215700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":9,
      "vividness":7,
      "r":75,
      "g":100,
      "b":140,
      "hex":"#4b648c",
      "file_location":"assets/colors_organized/hue_19/brightness_09/2020040416215701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":9,
      "vividness":8,
      "r":64,
      "g":95,
      "b":139,
      "hex":"#405f8b",
      "file_location":"assets/colors_organized/hue_19/brightness_09/2020040416215800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":9,
      "vividness":9,
      "r":55,
      "g":88,
      "b":139,
      "hex":"#37588b",
      "file_location":"assets/colors_organized/hue_19/brightness_09/2020040416215801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":9,
      "vividness":10,
      "r":46,
      "g":83,
      "b":138,
      "hex":"#2e538a",
      "file_location":"assets/colors_organized/hue_19/brightness_09/2020040416215900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":9,
      "vividness":11,
      "r":36,
      "g":78,
      "b":138,
      "hex":"#244e8a",
      "file_location":"assets/colors_organized/hue_19/brightness_09/2020040416215901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":9,
      "vividness":12,
      "r":27,
      "g":72,
      "b":139,
      "hex":"#1b488b",
      "file_location":"assets/colors_organized/hue_19/brightness_09/2020040416220000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":9,
      "vividness":13,
      "r":17,
      "g":67,
      "b":138,
      "hex":"#11438a",
      "file_location":"assets/colors_organized/hue_19/brightness_09/2020040416220001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":9,
      "vividness":14,
      "r":9,
      "g":60,
      "b":139,
      "hex":"#093c8b",
      "file_location":"assets/colors_organized/hue_19/brightness_09/2020040416220100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":10,
      "vividness":1,
      "r":144,
      "g":149,
      "b":155,
      "hex":"#90959b",
      "file_location":"assets/colors_organized/hue_19/brightness_10/2020040416214501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":10,
      "vividness":2,
      "r":134,
      "g":142,
      "b":153,
      "hex":"#868e99",
      "file_location":"assets/colors_organized/hue_19/brightness_10/2020040416214600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":10,
      "vividness":3,
      "r":123,
      "g":136,
      "b":153,
      "hex":"#7b8899",
      "file_location":"assets/colors_organized/hue_19/brightness_10/2020040416214601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":10,
      "vividness":4,
      "r":113,
      "g":131,
      "b":155,
      "hex":"#71839b",
      "file_location":"assets/colors_organized/hue_19/brightness_10/2020040416214602-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":10,
      "vividness":5,
      "r":102,
      "g":123,
      "b":154,
      "hex":"#667b9a",
      "file_location":"assets/colors_organized/hue_19/brightness_10/2020040416214700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":10,
      "vividness":6,
      "r":92,
      "g":118,
      "b":155,
      "hex":"#5c769b",
      "file_location":"assets/colors_organized/hue_19/brightness_10/2020040416214701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":10,
      "vividness":7,
      "r":82,
      "g":111,
      "b":153,
      "hex":"#526f99",
      "file_location":"assets/colors_organized/hue_19/brightness_10/2020040416214800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":10,
      "vividness":8,
      "r":72,
      "g":106,
      "b":154,
      "hex":"#486a9a",
      "file_location":"assets/colors_organized/hue_19/brightness_10/2020040416214801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":10,
      "vividness":9,
      "r":60,
      "g":99,
      "b":154,
      "hex":"#3c639a",
      "file_location":"assets/colors_organized/hue_19/brightness_10/2020040416214802-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":10,
      "vividness":10,
      "r":51,
      "g":92,
      "b":154,
      "hex":"#335c9a",
      "file_location":"assets/colors_organized/hue_19/brightness_10/2020040416214900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":10,
      "vividness":11,
      "r":41,
      "g":86,
      "b":154,
      "hex":"#29569a",
      "file_location":"assets/colors_organized/hue_19/brightness_10/2020040416214901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":10,
      "vividness":12,
      "r":30,
      "g":79,
      "b":155,
      "hex":"#1e4f9b",
      "file_location":"assets/colors_organized/hue_19/brightness_10/2020040416215000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":10,
      "vividness":13,
      "r":19,
      "g":74,
      "b":154,
      "hex":"#134a9a",
      "file_location":"assets/colors_organized/hue_19/brightness_10/2020040416215001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":10,
      "vividness":14,
      "r":10,
      "g":67,
      "b":154,
      "hex":"#0a439a",
      "file_location":"assets/colors_organized/hue_19/brightness_10/2020040416215100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":11,
      "vividness":1,
      "r":158,
      "g":163,
      "b":169,
      "hex":"#9ea3a9",
      "file_location":"assets/colors_organized/hue_19/brightness_11/02.jpg"
   },
   {
      "hue":19,
      "brightness":11,
      "vividness":2,
      "r":147,
      "g":157,
      "b":169,
      "hex":"#939da9",
      "file_location":"assets/colors_organized/hue_19/brightness_11/03.jpg"
   },
   {
      "hue":19,
      "brightness":11,
      "vividness":3,
      "r":134,
      "g":149,
      "b":168,
      "hex":"#8695a8",
      "file_location":"assets/colors_organized/hue_19/brightness_11/04.jpg"
   },
   {
      "hue":19,
      "brightness":11,
      "vividness":4,
      "r":125,
      "g":142,
      "b":168,
      "hex":"#7d8ea8",
      "file_location":"assets/colors_organized/hue_19/brightness_11/05.jpg"
   },
   {
      "hue":19,
      "brightness":11,
      "vividness":5,
      "r":112,
      "g":135,
      "b":169,
      "hex":"#7087a9",
      "file_location":"assets/colors_organized/hue_19/brightness_11/06.jpg"
   },
   {
      "hue":19,
      "brightness":11,
      "vividness":6,
      "r":100,
      "g":129,
      "b":169,
      "hex":"#6481a9",
      "file_location":"assets/colors_organized/hue_19/brightness_11/07.jpg"
   },
   {
      "hue":19,
      "brightness":11,
      "vividness":7,
      "r":90,
      "g":122,
      "b":169,
      "hex":"#5a7aa9",
      "file_location":"assets/colors_organized/hue_19/brightness_11/08.jpg"
   },
   {
      "hue":19,
      "brightness":11,
      "vividness":8,
      "r":78,
      "g":115,
      "b":168,
      "hex":"#4e73a8",
      "file_location":"assets/colors_organized/hue_19/brightness_11/09.jpg"
   },
   {
      "hue":19,
      "brightness":11,
      "vividness":9,
      "r":67,
      "g":109,
      "b":169,
      "hex":"#436da9",
      "file_location":"assets/colors_organized/hue_19/brightness_11/2020040416205400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":11,
      "vividness":10,
      "r":56,
      "g":101,
      "b":169,
      "hex":"#3865a9",
      "file_location":"assets/colors_organized/hue_19/brightness_11/2020040416205800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":11,
      "vividness":11,
      "r":45,
      "g":94,
      "b":170,
      "hex":"#2d5eaa",
      "file_location":"assets/colors_organized/hue_19/brightness_11/2020040416205801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":11,
      "vividness":12,
      "r":33,
      "g":88,
      "b":170,
      "hex":"#2158aa",
      "file_location":"assets/colors_organized/hue_19/brightness_11/2020040416205900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":11,
      "vividness":13,
      "r":21,
      "g":81,
      "b":169,
      "hex":"#1551a9",
      "file_location":"assets/colors_organized/hue_19/brightness_11/2020040416205901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":11,
      "vividness":14,
      "r":9,
      "g":74,
      "b":168,
      "hex":"#094aa8",
      "file_location":"assets/colors_organized/hue_19/brightness_11/2020040416210000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":12,
      "vividness":1,
      "r":172,
      "g":177,
      "b":183,
      "hex":"#acb1b7",
      "file_location":"assets/colors_organized/hue_19/brightness_12/2020040416204001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":12,
      "vividness":2,
      "r":160,
      "g":169,
      "b":184,
      "hex":"#a0a9b8",
      "file_location":"assets/colors_organized/hue_19/brightness_12/2020040416204100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":12,
      "vividness":3,
      "r":147,
      "g":162,
      "b":183,
      "hex":"#93a2b7",
      "file_location":"assets/colors_organized/hue_19/brightness_12/2020040416204101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":12,
      "vividness":4,
      "r":135,
      "g":154,
      "b":184,
      "hex":"#879ab8",
      "file_location":"assets/colors_organized/hue_19/brightness_12/2020040416204200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":12,
      "vividness":5,
      "r":123,
      "g":147,
      "b":183,
      "hex":"#7b93b7",
      "file_location":"assets/colors_organized/hue_19/brightness_12/2020040416204201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":12,
      "vividness":6,
      "r":109,
      "g":140,
      "b":184,
      "hex":"#6d8cb8",
      "file_location":"assets/colors_organized/hue_19/brightness_12/2020040416204300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":12,
      "vividness":7,
      "r":99,
      "g":132,
      "b":185,
      "hex":"#6384b9",
      "file_location":"assets/colors_organized/hue_19/brightness_12/2020040416204301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":12,
      "vividness":8,
      "r":86,
      "g":125,
      "b":184,
      "hex":"#567db8",
      "file_location":"assets/colors_organized/hue_19/brightness_12/2020040416204302-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":12,
      "vividness":9,
      "r":73,
      "g":118,
      "b":185,
      "hex":"#4976b9",
      "file_location":"assets/colors_organized/hue_19/brightness_12/2020040416204400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":12,
      "vividness":10,
      "r":61,
      "g":111,
      "b":184,
      "hex":"#3d6fb8",
      "file_location":"assets/colors_organized/hue_19/brightness_12/2020040416204401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":12,
      "vividness":11,
      "r":48,
      "g":103,
      "b":185,
      "hex":"#3067b9",
      "file_location":"assets/colors_organized/hue_19/brightness_12/2020040416204500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":12,
      "vividness":12,
      "r":36,
      "g":96,
      "b":184,
      "hex":"#2460b8",
      "file_location":"assets/colors_organized/hue_19/brightness_12/2020040416204501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":12,
      "vividness":13,
      "r":22,
      "g":88,
      "b":184,
      "hex":"#1658b8",
      "file_location":"assets/colors_organized/hue_19/brightness_12/2020040416204600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":12,
      "vividness":14,
      "r":12,
      "g":81,
      "b":184,
      "hex":"#0c51b8",
      "file_location":"assets/colors_organized/hue_19/brightness_12/2020040416204601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":13,
      "vividness":1,
      "r":187,
      "g":191,
      "b":200,
      "hex":"#bbbfc8",
      "file_location":"assets/colors_organized/hue_19/brightness_13/2020040416203100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":13,
      "vividness":2,
      "r":174,
      "g":183,
      "b":200,
      "hex":"#aeb7c8",
      "file_location":"assets/colors_organized/hue_19/brightness_13/2020040416203101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":13,
      "vividness":3,
      "r":159,
      "g":175,
      "b":200,
      "hex":"#9fafc8",
      "file_location":"assets/colors_organized/hue_19/brightness_13/2020040416203102-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":13,
      "vividness":4,
      "r":147,
      "g":167,
      "b":200,
      "hex":"#93a7c8",
      "file_location":"assets/colors_organized/hue_19/brightness_13/2020040416203200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":13,
      "vividness":5,
      "r":134,
      "g":159,
      "b":199,
      "hex":"#869fc7",
      "file_location":"assets/colors_organized/hue_19/brightness_13/2020040416203201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":13,
      "vividness":6,
      "r":119,
      "g":151,
      "b":198,
      "hex":"#7797c6",
      "file_location":"assets/colors_organized/hue_19/brightness_13/2020040416203300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":13,
      "vividness":7,
      "r":106,
      "g":143,
      "b":198,
      "hex":"#6a8fc6",
      "file_location":"assets/colors_organized/hue_19/brightness_13/2020040416203301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":13,
      "vividness":8,
      "r":92,
      "g":136,
      "b":199,
      "hex":"#5c88c7",
      "file_location":"assets/colors_organized/hue_19/brightness_13/2020040416203400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":13,
      "vividness":9,
      "r":79,
      "g":127,
      "b":199,
      "hex":"#4f7fc7",
      "file_location":"assets/colors_organized/hue_19/brightness_13/2020040416203401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":13,
      "vividness":10,
      "r":66,
      "g":119,
      "b":199,
      "hex":"#4277c7",
      "file_location":"assets/colors_organized/hue_19/brightness_13/2020040416203500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":13,
      "vividness":11,
      "r":54,
      "g":111,
      "b":200,
      "hex":"#366fc8",
      "file_location":"assets/colors_organized/hue_19/brightness_13/2020040416203501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":13,
      "vividness":12,
      "r":39,
      "g":103,
      "b":200,
      "hex":"#2767c8",
      "file_location":"assets/colors_organized/hue_19/brightness_13/2020040416203502-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":13,
      "vividness":13,
      "r":26,
      "g":95,
      "b":199,
      "hex":"#1a5fc7",
      "file_location":"assets/colors_organized/hue_19/brightness_13/2020040416203600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":13,
      "vividness":14,
      "r":13,
      "g":86,
      "b":199,
      "hex":"#0d56c7",
      "file_location":"assets/colors_organized/hue_19/brightness_13/2020040416203601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":14,
      "vividness":1,
      "r":201,
      "g":205,
      "b":214,
      "hex":"#c9cdd6",
      "file_location":"assets/colors_organized/hue_19/brightness_14/2020040416202100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":14,
      "vividness":2,
      "r":185,
      "g":198,
      "b":214,
      "hex":"#b9c6d6",
      "file_location":"assets/colors_organized/hue_19/brightness_14/2020040416202101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":14,
      "vividness":3,
      "r":171,
      "g":188,
      "b":214,
      "hex":"#abbcd6",
      "file_location":"assets/colors_organized/hue_19/brightness_14/2020040416202200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":14,
      "vividness":4,
      "r":157,
      "g":180,
      "b":214,
      "hex":"#9db4d6",
      "file_location":"assets/colors_organized/hue_19/brightness_14/2020040416202201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":14,
      "vividness":5,
      "r":143,
      "g":172,
      "b":214,
      "hex":"#8facd6",
      "file_location":"assets/colors_organized/hue_19/brightness_14/2020040416202300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":14,
      "vividness":6,
      "r":128,
      "g":164,
      "b":214,
      "hex":"#80a4d6",
      "file_location":"assets/colors_organized/hue_19/brightness_14/2020040416202301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":14,
      "vividness":7,
      "r":114,
      "g":154,
      "b":215,
      "hex":"#729ad7",
      "file_location":"assets/colors_organized/hue_19/brightness_14/2020040416202302-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":14,
      "vividness":8,
      "r":100,
      "g":145,
      "b":213,
      "hex":"#6491d5",
      "file_location":"assets/colors_organized/hue_19/brightness_14/2020040416202400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":14,
      "vividness":9,
      "r":84,
      "g":137,
      "b":213,
      "hex":"#5489d5",
      "file_location":"assets/colors_organized/hue_19/brightness_14/2020040416202401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":14,
      "vividness":10,
      "r":71,
      "g":128,
      "b":215,
      "hex":"#4780d7",
      "file_location":"assets/colors_organized/hue_19/brightness_14/2020040416202500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":14,
      "vividness":11,
      "r":57,
      "g":120,
      "b":215,
      "hex":"#3978d7",
      "file_location":"assets/colors_organized/hue_19/brightness_14/2020040416202501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":14,
      "vividness":12,
      "r":42,
      "g":111,
      "b":214,
      "hex":"#2a6fd6",
      "file_location":"assets/colors_organized/hue_19/brightness_14/2020040416202600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":14,
      "vividness":13,
      "r":28,
      "g":101,
      "b":214,
      "hex":"#1c65d6",
      "file_location":"assets/colors_organized/hue_19/brightness_14/2020040416202601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":14,
      "vividness":14,
      "r":11,
      "g":94,
      "b":214,
      "hex":"#0b5ed6",
      "file_location":"assets/colors_organized/hue_19/brightness_14/2020040416202700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":15,
      "vividness":1,
      "r":214,
      "g":221,
      "b":229,
      "hex":"#d6dde5",
      "file_location":"assets/colors_organized/hue_19/brightness_15/2020040416200900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":15,
      "vividness":2,
      "r":199,
      "g":212,
      "b":229,
      "hex":"#c7d4e5",
      "file_location":"assets/colors_organized/hue_19/brightness_15/2020040416200901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":15,
      "vividness":3,
      "r":182,
      "g":202,
      "b":229,
      "hex":"#b6cae5",
      "file_location":"assets/colors_organized/hue_19/brightness_15/2020040416201000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":15,
      "vividness":4,
      "r":168,
      "g":192,
      "b":228,
      "hex":"#a8c0e4",
      "file_location":"assets/colors_organized/hue_19/brightness_15/2020040416201001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":15,
      "vividness":5,
      "r":152,
      "g":183,
      "b":229,
      "hex":"#98b7e5",
      "file_location":"assets/colors_organized/hue_19/brightness_15/2020040416201002-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":15,
      "vividness":6,
      "r":137,
      "g":174,
      "b":229,
      "hex":"#89aee5",
      "file_location":"assets/colors_organized/hue_19/brightness_15/2020040416201100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":15,
      "vividness":7,
      "r":122,
      "g":166,
      "b":229,
      "hex":"#7aa6e5",
      "file_location":"assets/colors_organized/hue_19/brightness_15/2020040416201101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":15,
      "vividness":8,
      "r":107,
      "g":157,
      "b":230,
      "hex":"#6b9de6",
      "file_location":"assets/colors_organized/hue_19/brightness_15/2020040416201200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":15,
      "vividness":9,
      "r":90,
      "g":146,
      "b":229,
      "hex":"#5a92e5",
      "file_location":"assets/colors_organized/hue_19/brightness_15/2020040416201201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":15,
      "vividness":10,
      "r":76,
      "g":137,
      "b":228,
      "hex":"#4c89e4",
      "file_location":"assets/colors_organized/hue_19/brightness_15/2020040416201300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":15,
      "vividness":11,
      "r":60,
      "g":128,
      "b":229,
      "hex":"#3c80e5",
      "file_location":"assets/colors_organized/hue_19/brightness_15/2020040416201301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":15,
      "vividness":12,
      "r":45,
      "g":118,
      "b":229,
      "hex":"#2d76e5",
      "file_location":"assets/colors_organized/hue_19/brightness_15/2020040416201302-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":15,
      "vividness":13,
      "r":29,
      "g":110,
      "b":228,
      "hex":"#1d6ee4",
      "file_location":"assets/colors_organized/hue_19/brightness_15/2020040416201400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":19,
      "brightness":15,
      "vividness":14,
      "r":15,
      "g":100,
      "b":229,
      "hex":"#0f64e5",
      "file_location":"assets/colors_organized/hue_19/brightness_15/2020040416201401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":1,
      "vividness":1,
      "r":12,
      "g":13,
      "b":18,
      "hex":"#0c0d12",
      "file_location":"assets/colors_organized/hue_20/brightness_01/2020040416260501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":1,
      "vividness":2,
      "r":10,
      "g":10,
      "b":20,
      "hex":"#0a0a14",
      "file_location":"assets/colors_organized/hue_20/brightness_01/2020040416260700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":1,
      "vividness":3,
      "r":7,
      "g":7,
      "b":19,
      "hex":"#070713",
      "file_location":"assets/colors_organized/hue_20/brightness_01/2020040416260801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":1,
      "vividness":4,
      "r":3,
      "g":7,
      "b":19,
      "hex":"#030713",
      "file_location":"assets/colors_organized/hue_20/brightness_01/2020040416260900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":2,
      "vividness":1,
      "r":26,
      "g":29,
      "b":34,
      "hex":"#1a1d22",
      "file_location":"assets/colors_organized/hue_20/brightness_02/2020040416255400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":2,
      "vividness":2,
      "r":24,
      "g":27,
      "b":34,
      "hex":"#181b22",
      "file_location":"assets/colors_organized/hue_20/brightness_02/2020040416255401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":2,
      "vividness":3,
      "r":22,
      "g":25,
      "b":34,
      "hex":"#161922",
      "file_location":"assets/colors_organized/hue_20/brightness_02/2020040416255500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":2,
      "vividness":4,
      "r":21,
      "g":23,
      "b":35,
      "hex":"#151723",
      "file_location":"assets/colors_organized/hue_20/brightness_02/2020040416255501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":2,
      "vividness":5,
      "r":18,
      "g":22,
      "b":34,
      "hex":"#121622",
      "file_location":"assets/colors_organized/hue_20/brightness_02/2020040416255600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":2,
      "vividness":6,
      "r":14,
      "g":20,
      "b":34,
      "hex":"#0e1422",
      "file_location":"assets/colors_organized/hue_20/brightness_02/2020040416255601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":2,
      "vividness":7,
      "r":12,
      "g":18,
      "b":34,
      "hex":"#0c1222",
      "file_location":"assets/colors_organized/hue_20/brightness_02/2020040416255700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":2,
      "vividness":8,
      "r":9,
      "g":14,
      "b":34,
      "hex":"#090e22",
      "file_location":"assets/colors_organized/hue_20/brightness_02/2020040416255701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":2,
      "vividness":9,
      "r":10,
      "g":12,
      "b":33,
      "hex":"#0a0c21",
      "file_location":"assets/colors_organized/hue_20/brightness_02/2020040416255800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":2,
      "vividness":10,
      "r":6,
      "g":12,
      "b":34,
      "hex":"#060c22",
      "file_location":"assets/colors_organized/hue_20/brightness_02/2020040416255801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":2,
      "vividness":11,
      "r":2,
      "g":9,
      "b":35,
      "hex":"#020923",
      "file_location":"assets/colors_organized/hue_20/brightness_02/2020040416255900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":2,
      "vividness":12,
      "r":2,
      "g":6,
      "b":35,
      "hex":"#020623",
      "file_location":"assets/colors_organized/hue_20/brightness_02/2020040416260000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":3,
      "vividness":1,
      "r":46,
      "g":47,
      "b":51,
      "hex":"#2e2f33",
      "file_location":"assets/colors_organized/hue_20/brightness_03/2020040416254200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":3,
      "vividness":2,
      "r":43,
      "g":44,
      "b":49,
      "hex":"#2b2c31",
      "file_location":"assets/colors_organized/hue_20/brightness_03/2020040416254201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":3,
      "vividness":3,
      "r":39,
      "g":42,
      "b":49,
      "hex":"#272a31",
      "file_location":"assets/colors_organized/hue_20/brightness_03/2020040416254300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":3,
      "vividness":4,
      "r":35,
      "g":39,
      "b":50,
      "hex":"#232732",
      "file_location":"assets/colors_organized/hue_20/brightness_03/2020040416254301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":3,
      "vividness":5,
      "r":34,
      "g":36,
      "b":49,
      "hex":"#222431",
      "file_location":"assets/colors_organized/hue_20/brightness_03/2020040416254400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":3,
      "vividness":6,
      "r":30,
      "g":33,
      "b":50,
      "hex":"#1e2132",
      "file_location":"assets/colors_organized/hue_20/brightness_03/2020040416254500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":3,
      "vividness":7,
      "r":25,
      "g":30,
      "b":49,
      "hex":"#191e31",
      "file_location":"assets/colors_organized/hue_20/brightness_03/2020040416254501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":3,
      "vividness":8,
      "r":23,
      "g":28,
      "b":50,
      "hex":"#171c32",
      "file_location":"assets/colors_organized/hue_20/brightness_03/2020040416254600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":3,
      "vividness":9,
      "r":20,
      "g":26,
      "b":50,
      "hex":"#141a32",
      "file_location":"assets/colors_organized/hue_20/brightness_03/2020040416254601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":3,
      "vividness":10,
      "r":15,
      "g":22,
      "b":50,
      "hex":"#0f1632",
      "file_location":"assets/colors_organized/hue_20/brightness_03/2020040416254700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":3,
      "vividness":11,
      "r":13,
      "g":20,
      "b":49,
      "hex":"#0d1431",
      "file_location":"assets/colors_organized/hue_20/brightness_03/2020040416254701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":3,
      "vividness":12,
      "r":9,
      "g":18,
      "b":49,
      "hex":"#091231",
      "file_location":"assets/colors_organized/hue_20/brightness_03/2020040416254800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":3,
      "vividness":13,
      "r":6,
      "g":14,
      "b":50,
      "hex":"#060e32",
      "file_location":"assets/colors_organized/hue_20/brightness_03/2020040416254801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":3,
      "vividness":14,
      "r":3,
      "g":13,
      "b":49,
      "hex":"#030d31",
      "file_location":"assets/colors_organized/hue_20/brightness_03/2020040416254900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":4,
      "vividness":1,
      "r":51,
      "g":53,
      "b":65,
      "hex":"#333541",
      "file_location":"assets/colors_organized/hue_20/brightness_04/2020040416253201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":4,
      "vividness":2,
      "r":47,
      "g":50,
      "b":65,
      "hex":"#2f3241",
      "file_location":"assets/colors_organized/hue_20/brightness_04/2020040416253300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":4,
      "vividness":3,
      "r":41,
      "g":46,
      "b":65,
      "hex":"#292e41",
      "file_location":"assets/colors_organized/hue_20/brightness_04/2020040416253301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":4,
      "vividness":4,
      "r":38,
      "g":43,
      "b":63,
      "hex":"#262b3f",
      "file_location":"assets/colors_organized/hue_20/brightness_04/2020040416253400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":4,
      "vividness":5,
      "r":34,
      "g":40,
      "b":66,
      "hex":"#222842",
      "file_location":"assets/colors_organized/hue_20/brightness_04/2020040416253401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":4,
      "vividness":6,
      "r":30,
      "g":37,
      "b":65,
      "hex":"#1e2541",
      "file_location":"assets/colors_organized/hue_20/brightness_04/2020040416253500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":4,
      "vividness":7,
      "r":27,
      "g":33,
      "b":65,
      "hex":"#1b2141",
      "file_location":"assets/colors_organized/hue_20/brightness_04/2020040416253501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":4,
      "vividness":8,
      "r":21,
      "g":29,
      "b":65,
      "hex":"#151d41",
      "file_location":"assets/colors_organized/hue_20/brightness_04/2020040416253600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":4,
      "vividness":9,
      "r":17,
      "g":26,
      "b":65,
      "hex":"#111a41",
      "file_location":"assets/colors_organized/hue_20/brightness_04/2020040416253601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":4,
      "vividness":10,
      "r":11,
      "g":23,
      "b":65,
      "hex":"#0b1741",
      "file_location":"assets/colors_organized/hue_20/brightness_04/2020040416253700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":4,
      "vividness":11,
      "r":7,
      "g":20,
      "b":65,
      "hex":"#071441",
      "file_location":"assets/colors_organized/hue_20/brightness_04/2020040416253701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":4,
      "vividness":12,
      "r":3,
      "g":15,
      "b":65,
      "hex":"#030f41",
      "file_location":"assets/colors_organized/hue_20/brightness_04/2020040416253800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":5,
      "vividness":1,
      "r":74,
      "g":75,
      "b":80,
      "hex":"#4a4b50",
      "file_location":"assets/colors_organized/hue_20/brightness_05/2020040416252001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":5,
      "vividness":2,
      "r":68,
      "g":71,
      "b":80,
      "hex":"#444750",
      "file_location":"assets/colors_organized/hue_20/brightness_05/2020040416252100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":5,
      "vividness":3,
      "r":63,
      "g":67,
      "b":79,
      "hex":"#3f434f",
      "file_location":"assets/colors_organized/hue_20/brightness_05/2020040416252101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":5,
      "vividness":4,
      "r":57,
      "g":63,
      "b":79,
      "hex":"#393f4f",
      "file_location":"assets/colors_organized/hue_20/brightness_05/2020040416252200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":5,
      "vividness":5,
      "r":53,
      "g":58,
      "b":80,
      "hex":"#353a50",
      "file_location":"assets/colors_organized/hue_20/brightness_05/2020040416252201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":5,
      "vividness":6,
      "r":48,
      "g":54,
      "b":80,
      "hex":"#303650",
      "file_location":"assets/colors_organized/hue_20/brightness_05/2020040416252300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":5,
      "vividness":7,
      "r":41,
      "g":50,
      "b":79,
      "hex":"#29324f",
      "file_location":"assets/colors_organized/hue_20/brightness_05/2020040416252301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":5,
      "vividness":8,
      "r":36,
      "g":44,
      "b":80,
      "hex":"#242c50",
      "file_location":"assets/colors_organized/hue_20/brightness_05/2020040416252400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":5,
      "vividness":9,
      "r":31,
      "g":40,
      "b":79,
      "hex":"#1f284f",
      "file_location":"assets/colors_organized/hue_20/brightness_05/2020040416252401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":5,
      "vividness":10,
      "r":25,
      "g":37,
      "b":79,
      "hex":"#19254f",
      "file_location":"assets/colors_organized/hue_20/brightness_05/2020040416252500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":5,
      "vividness":11,
      "r":21,
      "g":34,
      "b":79,
      "hex":"#15224f",
      "file_location":"assets/colors_organized/hue_20/brightness_05/2020040416252501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":5,
      "vividness":12,
      "r":15,
      "g":28,
      "b":80,
      "hex":"#0f1c50",
      "file_location":"assets/colors_organized/hue_20/brightness_05/2020040416252600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":5,
      "vividness":13,
      "r":10,
      "g":22,
      "b":80,
      "hex":"#0a1650",
      "file_location":"assets/colors_organized/hue_20/brightness_05/2020040416252601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":5,
      "vividness":14,
      "r":5,
      "g":20,
      "b":79,
      "hex":"#05144f",
      "file_location":"assets/colors_organized/hue_20/brightness_05/2020040416252700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":6,
      "vividness":1,
      "r":87,
      "g":90,
      "b":95,
      "hex":"#575a5f",
      "file_location":"assets/colors_organized/hue_20/brightness_06/2020040416251000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":6,
      "vividness":2,
      "r":81,
      "g":83,
      "b":95,
      "hex":"#51535f",
      "file_location":"assets/colors_organized/hue_20/brightness_06/2020040416251001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":6,
      "vividness":3,
      "r":76,
      "g":79,
      "b":94,
      "hex":"#4c4f5e",
      "file_location":"assets/colors_organized/hue_20/brightness_06/2020040416251100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":6,
      "vividness":4,
      "r":69,
      "g":74,
      "b":94,
      "hex":"#454a5e",
      "file_location":"assets/colors_organized/hue_20/brightness_06/2020040416251101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":6,
      "vividness":5,
      "r":63,
      "g":69,
      "b":95,
      "hex":"#3f455f",
      "file_location":"assets/colors_organized/hue_20/brightness_06/2020040416251200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":6,
      "vividness":6,
      "r":57,
      "g":63,
      "b":95,
      "hex":"#393f5f",
      "file_location":"assets/colors_organized/hue_20/brightness_06/2020040416251201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":6,
      "vividness":7,
      "r":49,
      "g":59,
      "b":95,
      "hex":"#313b5f",
      "file_location":"assets/colors_organized/hue_20/brightness_06/2020040416251300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":6,
      "vividness":8,
      "r":44,
      "g":53,
      "b":94,
      "hex":"#2c355e",
      "file_location":"assets/colors_organized/hue_20/brightness_06/2020040416251301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":6,
      "vividness":9,
      "r":38,
      "g":49,
      "b":95,
      "hex":"#26315f",
      "file_location":"assets/colors_organized/hue_20/brightness_06/2020040416251400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":6,
      "vividness":10,
      "r":30,
      "g":43,
      "b":95,
      "hex":"#1e2b5f",
      "file_location":"assets/colors_organized/hue_20/brightness_06/2020040416251401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":6,
      "vividness":11,
      "r":24,
      "g":39,
      "b":94,
      "hex":"#18275e",
      "file_location":"assets/colors_organized/hue_20/brightness_06/2020040416251500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":6,
      "vividness":12,
      "r":19,
      "g":33,
      "b":94,
      "hex":"#13215e",
      "file_location":"assets/colors_organized/hue_20/brightness_06/2020040416251501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":6,
      "vividness":13,
      "r":12,
      "g":30,
      "b":94,
      "hex":"#0c1e5e",
      "file_location":"assets/colors_organized/hue_20/brightness_06/2020040416251600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":6,
      "vividness":14,
      "r":6,
      "g":23,
      "b":95,
      "hex":"#06175f",
      "file_location":"assets/colors_organized/hue_20/brightness_06/2020040416251601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":7,
      "vividness":1,
      "r":101,
      "g":104,
      "b":109,
      "hex":"#65686d",
      "file_location":"assets/colors_organized/hue_20/brightness_07/2020040416245901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":7,
      "vividness":2,
      "r":94,
      "g":98,
      "b":109,
      "hex":"#5e626d",
      "file_location":"assets/colors_organized/hue_20/brightness_07/2020040416250000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":7,
      "vividness":3,
      "r":87,
      "g":92,
      "b":111,
      "hex":"#575c6f",
      "file_location":"assets/colors_organized/hue_20/brightness_07/2020040416250001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":7,
      "vividness":4,
      "r":80,
      "g":86,
      "b":110,
      "hex":"#50566e",
      "file_location":"assets/colors_organized/hue_20/brightness_07/2020040416250100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":7,
      "vividness":5,
      "r":73,
      "g":80,
      "b":109,
      "hex":"#49506d",
      "file_location":"assets/colors_organized/hue_20/brightness_07/2020040416250101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":7,
      "vividness":6,
      "r":66,
      "g":74,
      "b":110,
      "hex":"#424a6e",
      "file_location":"assets/colors_organized/hue_20/brightness_07/2020040416250200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":7,
      "vividness":7,
      "r":57,
      "g":69,
      "b":111,
      "hex":"#39456f",
      "file_location":"assets/colors_organized/hue_20/brightness_07/2020040416250300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":7,
      "vividness":8,
      "r":50,
      "g":62,
      "b":110,
      "hex":"#323e6e",
      "file_location":"assets/colors_organized/hue_20/brightness_07/2020040416250301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":7,
      "vividness":9,
      "r":44,
      "g":57,
      "b":110,
      "hex":"#2c396e",
      "file_location":"assets/colors_organized/hue_20/brightness_07/2020040416250400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":7,
      "vividness":10,
      "r":36,
      "g":51,
      "b":110,
      "hex":"#24336e",
      "file_location":"assets/colors_organized/hue_20/brightness_07/2020040416250401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":7,
      "vividness":11,
      "r":29,
      "g":44,
      "b":109,
      "hex":"#1d2c6d",
      "file_location":"assets/colors_organized/hue_20/brightness_07/2020040416250500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":7,
      "vividness":12,
      "r":22,
      "g":39,
      "b":109,
      "hex":"#16276d",
      "file_location":"assets/colors_organized/hue_20/brightness_07/2020040416250501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":7,
      "vividness":13,
      "r":12,
      "g":34,
      "b":110,
      "hex":"#0c226e",
      "file_location":"assets/colors_organized/hue_20/brightness_07/2020040416250600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":7,
      "vividness":14,
      "r":6,
      "g":27,
      "b":110,
      "hex":"#061b6e",
      "file_location":"assets/colors_organized/hue_20/brightness_07/2020040416250601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":8,
      "vividness":1,
      "r":115,
      "g":118,
      "b":123,
      "hex":"#73767b",
      "file_location":"assets/colors_organized/hue_20/brightness_08/2020040416244801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":8,
      "vividness":2,
      "r":107,
      "g":111,
      "b":123,
      "hex":"#6b6f7b",
      "file_location":"assets/colors_organized/hue_20/brightness_08/2020040416244900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":8,
      "vividness":3,
      "r":99,
      "g":104,
      "b":124,
      "hex":"#63687c",
      "file_location":"assets/colors_organized/hue_20/brightness_08/2020040416244901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":8,
      "vividness":4,
      "r":91,
      "g":98,
      "b":124,
      "hex":"#5b627c",
      "file_location":"assets/colors_organized/hue_20/brightness_08/2020040416245000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":8,
      "vividness":5,
      "r":82,
      "g":91,
      "b":124,
      "hex":"#525b7c",
      "file_location":"assets/colors_organized/hue_20/brightness_08/2020040416245001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":8,
      "vividness":6,
      "r":74,
      "g":83,
      "b":124,
      "hex":"#4a537c",
      "file_location":"assets/colors_organized/hue_20/brightness_08/2020040416245100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":8,
      "vividness":7,
      "r":65,
      "g":78,
      "b":123,
      "hex":"#414e7b",
      "file_location":"assets/colors_organized/hue_20/brightness_08/2020040416245101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":8,
      "vividness":8,
      "r":58,
      "g":71,
      "b":124,
      "hex":"#3a477c",
      "file_location":"assets/colors_organized/hue_20/brightness_08/2020040416245200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":8,
      "vividness":9,
      "r":48,
      "g":64,
      "b":123,
      "hex":"#30407b",
      "file_location":"assets/colors_organized/hue_20/brightness_08/2020040416245201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":8,
      "vividness":10,
      "r":40,
      "g":58,
      "b":124,
      "hex":"#283a7c",
      "file_location":"assets/colors_organized/hue_20/brightness_08/2020040416245300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":8,
      "vividness":11,
      "r":33,
      "g":51,
      "b":123,
      "hex":"#21337b",
      "file_location":"assets/colors_organized/hue_20/brightness_08/2020040416245400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":8,
      "vividness":12,
      "r":22,
      "g":43,
      "b":124,
      "hex":"#162b7c",
      "file_location":"assets/colors_organized/hue_20/brightness_08/2020040416245401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":8,
      "vividness":13,
      "r":13,
      "g":37,
      "b":123,
      "hex":"#0d257b",
      "file_location":"assets/colors_organized/hue_20/brightness_08/2020040416245500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":8,
      "vividness":14,
      "r":6,
      "g":31,
      "b":124,
      "hex":"#061f7c",
      "file_location":"assets/colors_organized/hue_20/brightness_08/2020040416245501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":9,
      "vividness":1,
      "r":129,
      "g":132,
      "b":139,
      "hex":"#81848b",
      "file_location":"assets/colors_organized/hue_20/brightness_09/2020040416243701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":9,
      "vividness":2,
      "r":121,
      "g":124,
      "b":139,
      "hex":"#797c8b",
      "file_location":"assets/colors_organized/hue_20/brightness_09/2020040416243800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":9,
      "vividness":3,
      "r":111,
      "g":117,
      "b":139,
      "hex":"#6f758b",
      "file_location":"assets/colors_organized/hue_20/brightness_09/2020040416243801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":9,
      "vividness":4,
      "r":101,
      "g":110,
      "b":139,
      "hex":"#656e8b",
      "file_location":"assets/colors_organized/hue_20/brightness_09/2020040416243900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":9,
      "vividness":5,
      "r":92,
      "g":102,
      "b":138,
      "hex":"#5c668a",
      "file_location":"assets/colors_organized/hue_20/brightness_09/2020040416243901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":9,
      "vividness":6,
      "r":82,
      "g":95,
      "b":139,
      "hex":"#525f8b",
      "file_location":"assets/colors_organized/hue_20/brightness_09/2020040416244000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":9,
      "vividness":7,
      "r":74,
      "g":87,
      "b":139,
      "hex":"#4a578b",
      "file_location":"assets/colors_organized/hue_20/brightness_09/2020040416244001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":9,
      "vividness":8,
      "r":65,
      "g":80,
      "b":139,
      "hex":"#41508b",
      "file_location":"assets/colors_organized/hue_20/brightness_09/2020040416244100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":9,
      "vividness":9,
      "r":54,
      "g":72,
      "b":138,
      "hex":"#36488a",
      "file_location":"assets/colors_organized/hue_20/brightness_09/2020040416244101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":9,
      "vividness":10,
      "r":46,
      "g":66,
      "b":139,
      "hex":"#2e428b",
      "file_location":"assets/colors_organized/hue_20/brightness_09/2020040416244200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":9,
      "vividness":11,
      "r":36,
      "g":57,
      "b":140,
      "hex":"#24398c",
      "file_location":"assets/colors_organized/hue_20/brightness_09/2020040416244201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":9,
      "vividness":12,
      "r":26,
      "g":50,
      "b":140,
      "hex":"#1a328c",
      "file_location":"assets/colors_organized/hue_20/brightness_09/2020040416244300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":9,
      "vividness":13,
      "r":17,
      "g":41,
      "b":139,
      "hex":"#11298b",
      "file_location":"assets/colors_organized/hue_20/brightness_09/2020040416244301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":9,
      "vividness":14,
      "r":10,
      "g":35,
      "b":138,
      "hex":"#0a238a",
      "file_location":"assets/colors_organized/hue_20/brightness_09/2020040416244400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":10,
      "vividness":1,
      "r":143,
      "g":146,
      "b":153,
      "hex":"#8f9299",
      "file_location":"assets/colors_organized/hue_20/brightness_10/2020040416242701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":10,
      "vividness":2,
      "r":135,
      "g":138,
      "b":155,
      "hex":"#878a9b",
      "file_location":"assets/colors_organized/hue_20/brightness_10/2020040416242702-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":10,
      "vividness":3,
      "r":123,
      "g":131,
      "b":154,
      "hex":"#7b839a",
      "file_location":"assets/colors_organized/hue_20/brightness_10/2020040416242800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":10,
      "vividness":4,
      "r":112,
      "g":121,
      "b":154,
      "hex":"#70799a",
      "file_location":"assets/colors_organized/hue_20/brightness_10/2020040416242801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":10,
      "vividness":5,
      "r":104,
      "g":113,
      "b":154,
      "hex":"#68719a",
      "file_location":"assets/colors_organized/hue_20/brightness_10/2020040416242900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":10,
      "vividness":6,
      "r":92,
      "g":106,
      "b":155,
      "hex":"#5c6a9b",
      "file_location":"assets/colors_organized/hue_20/brightness_10/2020040416242901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":10,
      "vividness":7,
      "r":82,
      "g":97,
      "b":154,
      "hex":"#52619a",
      "file_location":"assets/colors_organized/hue_20/brightness_10/2020040416243000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":10,
      "vividness":8,
      "r":71,
      "g":89,
      "b":155,
      "hex":"#47599b",
      "file_location":"assets/colors_organized/hue_20/brightness_10/2020040416243001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":10,
      "vividness":9,
      "r":61,
      "g":81,
      "b":154,
      "hex":"#3d519a",
      "file_location":"assets/colors_organized/hue_20/brightness_10/2020040416243100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":10,
      "vividness":10,
      "r":51,
      "g":72,
      "b":155,
      "hex":"#33489b",
      "file_location":"assets/colors_organized/hue_20/brightness_10/2020040416243101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":10,
      "vividness":11,
      "r":39,
      "g":63,
      "b":153,
      "hex":"#273f99",
      "file_location":"assets/colors_organized/hue_20/brightness_10/2020040416243200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":10,
      "vividness":12,
      "r":29,
      "g":54,
      "b":154,
      "hex":"#1d369a",
      "file_location":"assets/colors_organized/hue_20/brightness_10/2020040416243201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":10,
      "vividness":13,
      "r":20,
      "g":47,
      "b":154,
      "hex":"#142f9a",
      "file_location":"assets/colors_organized/hue_20/brightness_10/2020040416243300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":10,
      "vividness":14,
      "r":9,
      "g":38,
      "b":155,
      "hex":"#09269b",
      "file_location":"assets/colors_organized/hue_20/brightness_10/2020040416243400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":11,
      "vividness":1,
      "r":158,
      "g":161,
      "b":170,
      "hex":"#9ea1aa",
      "file_location":"assets/colors_organized/hue_20/brightness_11/2020040416241601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":11,
      "vividness":2,
      "r":146,
      "g":151,
      "b":170,
      "hex":"#9297aa",
      "file_location":"assets/colors_organized/hue_20/brightness_11/2020040416241700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":11,
      "vividness":3,
      "r":135,
      "g":142,
      "b":170,
      "hex":"#878eaa",
      "file_location":"assets/colors_organized/hue_20/brightness_11/2020040416241701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":11,
      "vividness":4,
      "r":123,
      "g":133,
      "b":168,
      "hex":"#7b85a8",
      "file_location":"assets/colors_organized/hue_20/brightness_11/2020040416241800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":11,
      "vividness":5,
      "r":113,
      "g":124,
      "b":169,
      "hex":"#717ca9",
      "file_location":"assets/colors_organized/hue_20/brightness_11/2020040416241801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":11,
      "vividness":6,
      "r":100,
      "g":116,
      "b":168,
      "hex":"#6474a8",
      "file_location":"assets/colors_organized/hue_20/brightness_11/2020040416241900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":11,
      "vividness":7,
      "r":90,
      "g":106,
      "b":168,
      "hex":"#5a6aa8",
      "file_location":"assets/colors_organized/hue_20/brightness_11/2020040416241901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":11,
      "vividness":8,
      "r":79,
      "g":97,
      "b":169,
      "hex":"#4f61a9",
      "file_location":"assets/colors_organized/hue_20/brightness_11/2020040416242000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":11,
      "vividness":9,
      "r":67,
      "g":88,
      "b":169,
      "hex":"#4358a9",
      "file_location":"assets/colors_organized/hue_20/brightness_11/2020040416242001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":11,
      "vividness":10,
      "r":55,
      "g":79,
      "b":169,
      "hex":"#374fa9",
      "file_location":"assets/colors_organized/hue_20/brightness_11/2020040416242002-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":11,
      "vividness":11,
      "r":45,
      "g":70,
      "b":170,
      "hex":"#2d46aa",
      "file_location":"assets/colors_organized/hue_20/brightness_11/2020040416242100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":11,
      "vividness":12,
      "r":34,
      "g":60,
      "b":170,
      "hex":"#223caa",
      "file_location":"assets/colors_organized/hue_20/brightness_11/2020040416242101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":11,
      "vividness":13,
      "r":21,
      "g":52,
      "b":169,
      "hex":"#1534a9",
      "file_location":"assets/colors_organized/hue_20/brightness_11/2020040416242200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":11,
      "vividness":14,
      "r":9,
      "g":43,
      "b":169,
      "hex":"#092ba9",
      "file_location":"assets/colors_organized/hue_20/brightness_11/2020040416242201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":12,
      "vividness":1,
      "r":172,
      "g":175,
      "b":184,
      "hex":"#acafb8",
      "file_location":"assets/colors_organized/hue_20/brightness_12/2020040416240501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":12,
      "vividness":2,
      "r":159,
      "g":164,
      "b":184,
      "hex":"#9fa4b8",
      "file_location":"assets/colors_organized/hue_20/brightness_12/2020040416240600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":12,
      "vividness":3,
      "r":148,
      "g":155,
      "b":184,
      "hex":"#949bb8",
      "file_location":"assets/colors_organized/hue_20/brightness_12/2020040416240601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":12,
      "vividness":4,
      "r":136,
      "g":145,
      "b":184,
      "hex":"#8891b8",
      "file_location":"assets/colors_organized/hue_20/brightness_12/2020040416240700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":12,
      "vividness":5,
      "r":123,
      "g":135,
      "b":183,
      "hex":"#7b87b7",
      "file_location":"assets/colors_organized/hue_20/brightness_12/2020040416240701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":12,
      "vividness":6,
      "r":110,
      "g":125,
      "b":184,
      "hex":"#6e7db8",
      "file_location":"assets/colors_organized/hue_20/brightness_12/2020040416240702-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":12,
      "vividness":7,
      "r":98,
      "g":115,
      "b":184,
      "hex":"#6273b8",
      "file_location":"assets/colors_organized/hue_20/brightness_12/2020040416240800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":12,
      "vividness":8,
      "r":86,
      "g":105,
      "b":184,
      "hex":"#5669b8",
      "file_location":"assets/colors_organized/hue_20/brightness_12/2020040416240900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":12,
      "vividness":9,
      "r":72,
      "g":96,
      "b":184,
      "hex":"#4860b8",
      "file_location":"assets/colors_organized/hue_20/brightness_12/2020040416240901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":12,
      "vividness":10,
      "r":60,
      "g":86,
      "b":183,
      "hex":"#3c56b7",
      "file_location":"assets/colors_organized/hue_20/brightness_12/2020040416241000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":12,
      "vividness":11,
      "r":48,
      "g":76,
      "b":185,
      "hex":"#304cb9",
      "file_location":"assets/colors_organized/hue_20/brightness_12/2020040416241001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":12,
      "vividness":12,
      "r":35,
      "g":66,
      "b":183,
      "hex":"#2342b7",
      "file_location":"assets/colors_organized/hue_20/brightness_12/2020040416241100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":12,
      "vividness":13,
      "r":22,
      "g":55,
      "b":184,
      "hex":"#1637b8",
      "file_location":"assets/colors_organized/hue_20/brightness_12/2020040416241101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":12,
      "vividness":14,
      "r":12,
      "g":45,
      "b":184,
      "hex":"#0c2db8",
      "file_location":"assets/colors_organized/hue_20/brightness_12/2020040416241200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":13,
      "vividness":1,
      "r":186,
      "g":188,
      "b":200,
      "hex":"#babcc8",
      "file_location":"assets/colors_organized/hue_20/brightness_13/2020040416235401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":13,
      "vividness":2,
      "r":173,
      "g":178,
      "b":198,
      "hex":"#adb2c6",
      "file_location":"assets/colors_organized/hue_20/brightness_13/2020040416235500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":13,
      "vividness":3,
      "r":158,
      "g":167,
      "b":198,
      "hex":"#9ea7c6",
      "file_location":"assets/colors_organized/hue_20/brightness_13/2020040416235501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":13,
      "vividness":4,
      "r":146,
      "g":158,
      "b":200,
      "hex":"#929ec8",
      "file_location":"assets/colors_organized/hue_20/brightness_13/2020040416235600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":13,
      "vividness":5,
      "r":133,
      "g":146,
      "b":199,
      "hex":"#8592c7",
      "file_location":"assets/colors_organized/hue_20/brightness_13/2020040416235601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":13,
      "vividness":6,
      "r":120,
      "g":135,
      "b":200,
      "hex":"#7887c8",
      "file_location":"assets/colors_organized/hue_20/brightness_13/2020040416235700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":13,
      "vividness":7,
      "r":106,
      "g":126,
      "b":199,
      "hex":"#6a7ec7",
      "file_location":"assets/colors_organized/hue_20/brightness_13/2020040416235800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":13,
      "vividness":8,
      "r":92,
      "g":114,
      "b":199,
      "hex":"#5c72c7",
      "file_location":"assets/colors_organized/hue_20/brightness_13/2020040416235801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":13,
      "vividness":9,
      "r":79,
      "g":103,
      "b":199,
      "hex":"#4f67c7",
      "file_location":"assets/colors_organized/hue_20/brightness_13/2020040416235900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":13,
      "vividness":10,
      "r":66,
      "g":93,
      "b":200,
      "hex":"#425dc8",
      "file_location":"assets/colors_organized/hue_20/brightness_13/2020040416235901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":13,
      "vividness":11,
      "r":52,
      "g":81,
      "b":199,
      "hex":"#3451c7",
      "file_location":"assets/colors_organized/hue_20/brightness_13/2020040416240000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":13,
      "vividness":12,
      "r":38,
      "g":71,
      "b":200,
      "hex":"#2647c8",
      "file_location":"assets/colors_organized/hue_20/brightness_13/2020040416240001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":13,
      "vividness":13,
      "r":27,
      "g":60,
      "b":199,
      "hex":"#1b3cc7",
      "file_location":"assets/colors_organized/hue_20/brightness_13/2020040416240100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":13,
      "vividness":14,
      "r":13,
      "g":50,
      "b":200,
      "hex":"#0d32c8",
      "file_location":"assets/colors_organized/hue_20/brightness_13/2020040416240101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":14,
      "vividness":1,
      "r":200,
      "g":204,
      "b":215,
      "hex":"#c8ccd7",
      "file_location":"assets/colors_organized/hue_20/brightness_14/2020040416234400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":14,
      "vividness":2,
      "r":185,
      "g":191,
      "b":215,
      "hex":"#b9bfd7",
      "file_location":"assets/colors_organized/hue_20/brightness_14/2020040416234401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":14,
      "vividness":3,
      "r":171,
      "g":179,
      "b":215,
      "hex":"#abb3d7",
      "file_location":"assets/colors_organized/hue_20/brightness_14/2020040416234500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":14,
      "vividness":4,
      "r":157,
      "g":168,
      "b":214,
      "hex":"#9da8d6",
      "file_location":"assets/colors_organized/hue_20/brightness_14/2020040416234501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":14,
      "vividness":5,
      "r":142,
      "g":157,
      "b":214,
      "hex":"#8e9dd6",
      "file_location":"assets/colors_organized/hue_20/brightness_14/2020040416234600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":14,
      "vividness":6,
      "r":128,
      "g":145,
      "b":214,
      "hex":"#8091d6",
      "file_location":"assets/colors_organized/hue_20/brightness_14/2020040416234601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":14,
      "vividness":7,
      "r":113,
      "g":134,
      "b":213,
      "hex":"#7186d5",
      "file_location":"assets/colors_organized/hue_20/brightness_14/2020040416234700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":14,
      "vividness":8,
      "r":99,
      "g":122,
      "b":215,
      "hex":"#637ad7",
      "file_location":"assets/colors_organized/hue_20/brightness_14/2020040416234701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":14,
      "vividness":9,
      "r":84,
      "g":111,
      "b":214,
      "hex":"#546fd6",
      "file_location":"assets/colors_organized/hue_20/brightness_14/2020040416234800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":14,
      "vividness":10,
      "r":70,
      "g":100,
      "b":214,
      "hex":"#4664d6",
      "file_location":"assets/colors_organized/hue_20/brightness_14/2020040416234801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":14,
      "vividness":11,
      "r":57,
      "g":88,
      "b":213,
      "hex":"#3958d5",
      "file_location":"assets/colors_organized/hue_20/brightness_14/2020040416234900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":14,
      "vividness":12,
      "r":41,
      "g":77,
      "b":215,
      "hex":"#294dd7",
      "file_location":"assets/colors_organized/hue_20/brightness_14/2020040416234901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":14,
      "vividness":13,
      "r":28,
      "g":65,
      "b":215,
      "hex":"#1c41d7",
      "file_location":"assets/colors_organized/hue_20/brightness_14/2020040416235000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":14,
      "vividness":14,
      "r":12,
      "g":54,
      "b":214,
      "hex":"#0c36d6",
      "file_location":"assets/colors_organized/hue_20/brightness_14/2020040416235001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":15,
      "vividness":1,
      "r":214,
      "g":218,
      "b":229,
      "hex":"#d6dae5",
      "file_location":"assets/colors_organized/hue_20/brightness_15/2020040416233301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":15,
      "vividness":2,
      "r":198,
      "g":206,
      "b":229,
      "hex":"#c6cee5",
      "file_location":"assets/colors_organized/hue_20/brightness_15/2020040416233400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":15,
      "vividness":3,
      "r":183,
      "g":193,
      "b":229,
      "hex":"#b7c1e5",
      "file_location":"assets/colors_organized/hue_20/brightness_15/2020040416233500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":15,
      "vividness":4,
      "r":168,
      "g":180,
      "b":230,
      "hex":"#a8b4e6",
      "file_location":"assets/colors_organized/hue_20/brightness_15/2020040416233501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":15,
      "vividness":5,
      "r":152,
      "g":168,
      "b":228,
      "hex":"#98a8e4",
      "file_location":"assets/colors_organized/hue_20/brightness_15/2020040416233502-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":15,
      "vividness":6,
      "r":137,
      "g":157,
      "b":230,
      "hex":"#899de6",
      "file_location":"assets/colors_organized/hue_20/brightness_15/2020040416233600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":15,
      "vividness":7,
      "r":122,
      "g":144,
      "b":230,
      "hex":"#7a90e6",
      "file_location":"assets/colors_organized/hue_20/brightness_15/2020040416233601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":15,
      "vividness":8,
      "r":107,
      "g":131,
      "b":229,
      "hex":"#6b83e5",
      "file_location":"assets/colors_organized/hue_20/brightness_15/2020040416233700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":15,
      "vividness":9,
      "r":91,
      "g":119,
      "b":229,
      "hex":"#5b77e5",
      "file_location":"assets/colors_organized/hue_20/brightness_15/2020040416233701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":15,
      "vividness":10,
      "r":76,
      "g":108,
      "b":229,
      "hex":"#4c6ce5",
      "file_location":"assets/colors_organized/hue_20/brightness_15/2020040416233800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":15,
      "vividness":11,
      "r":60,
      "g":94,
      "b":228,
      "hex":"#3c5ee4",
      "file_location":"assets/colors_organized/hue_20/brightness_15/2020040416233801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":15,
      "vividness":12,
      "r":45,
      "g":83,
      "b":230,
      "hex":"#2d53e6",
      "file_location":"assets/colors_organized/hue_20/brightness_15/2020040416233900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":15,
      "vividness":13,
      "r":30,
      "g":70,
      "b":230,
      "hex":"#1e46e6",
      "file_location":"assets/colors_organized/hue_20/brightness_15/2020040416233901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":20,
      "brightness":15,
      "vividness":14,
      "r":14,
      "g":58,
      "b":229,
      "hex":"#0e3ae5",
      "file_location":"assets/colors_organized/hue_20/brightness_15/2020040416234000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":1,
      "vividness":1,
      "r":14,
      "g":15,
      "b":19,
      "hex":"#0e0f13",
      "file_location":"assets/colors_organized/hue_21/brightness_01/2020040416300501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":1,
      "vividness":2,
      "r":1,
      "g":3,
      "b":18,
      "hex":"#010312",
      "file_location":"assets/colors_organized/hue_21/brightness_01/2020040416301000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":1,
      "vividness":3,
      "r":0,
      "g":0,
      "b":20,
      "hex":"#000014",
      "file_location":"assets/colors_organized/hue_21/brightness_01/2020040416301002-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":2,
      "vividness":1,
      "r":32,
      "g":32,
      "b":34,
      "hex":"#202022",
      "file_location":"assets/colors_organized/hue_21/brightness_02/2020040416295301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":2,
      "vividness":2,
      "r":29,
      "g":30,
      "b":34,
      "hex":"#1d1e22",
      "file_location":"assets/colors_organized/hue_21/brightness_02/2020040416295400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":2,
      "vividness":3,
      "r":27,
      "g":28,
      "b":33,
      "hex":"#1b1c21",
      "file_location":"assets/colors_organized/hue_21/brightness_02/2020040416295401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":2,
      "vividness":4,
      "r":25,
      "g":25,
      "b":35,
      "hex":"#191923",
      "file_location":"assets/colors_organized/hue_21/brightness_02/2020040416295500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":2,
      "vividness":5,
      "r":23,
      "g":23,
      "b":35,
      "hex":"#171723",
      "file_location":"assets/colors_organized/hue_21/brightness_02/2020040416295501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":2,
      "vividness":6,
      "r":22,
      "g":22,
      "b":34,
      "hex":"#161622",
      "file_location":"assets/colors_organized/hue_21/brightness_02/2020040416295600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":2,
      "vividness":7,
      "r":16,
      "g":18,
      "b":33,
      "hex":"#101221",
      "file_location":"assets/colors_organized/hue_21/brightness_02/2020040416295601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":2,
      "vividness":8,
      "r":14,
      "g":15,
      "b":35,
      "hex":"#0e0f23",
      "file_location":"assets/colors_organized/hue_21/brightness_02/2020040416295700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":2,
      "vividness":9,
      "r":12,
      "g":13,
      "b":34,
      "hex":"#0c0d22",
      "file_location":"assets/colors_organized/hue_21/brightness_02/2020040416295701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":2,
      "vividness":10,
      "r":9,
      "g":9,
      "b":33,
      "hex":"#090921",
      "file_location":"assets/colors_organized/hue_21/brightness_02/2020040416295800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":2,
      "vividness":11,
      "r":7,
      "g":7,
      "b":35,
      "hex":"#070723",
      "file_location":"assets/colors_organized/hue_21/brightness_02/2020040416295900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":2,
      "vividness":12,
      "r":2,
      "g":3,
      "b":34,
      "hex":"#020322",
      "file_location":"assets/colors_organized/hue_21/brightness_02/2020040416295901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":3,
      "vividness":1,
      "r":46,
      "g":45,
      "b":50,
      "hex":"#2e2d32",
      "file_location":"assets/colors_organized/hue_21/brightness_03/2020040416294300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":3,
      "vividness":2,
      "r":42,
      "g":43,
      "b":48,
      "hex":"#2a2b30",
      "file_location":"assets/colors_organized/hue_21/brightness_03/2020040416294301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":3,
      "vividness":3,
      "r":40,
      "g":40,
      "b":50,
      "hex":"#282832",
      "file_location":"assets/colors_organized/hue_21/brightness_03/2020040416294400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":3,
      "vividness":4,
      "r":36,
      "g":35,
      "b":49,
      "hex":"#242331",
      "file_location":"assets/colors_organized/hue_21/brightness_03/2020040416294401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":3,
      "vividness":5,
      "r":34,
      "g":33,
      "b":49,
      "hex":"#222131",
      "file_location":"assets/colors_organized/hue_21/brightness_03/2020040416294500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":3,
      "vividness":6,
      "r":29,
      "g":30,
      "b":50,
      "hex":"#1d1e32",
      "file_location":"assets/colors_organized/hue_21/brightness_03/2020040416294501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":3,
      "vividness":7,
      "r":26,
      "g":27,
      "b":48,
      "hex":"#1a1b30",
      "file_location":"assets/colors_organized/hue_21/brightness_03/2020040416294600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":3,
      "vividness":8,
      "r":23,
      "g":23,
      "b":49,
      "hex":"#171731",
      "file_location":"assets/colors_organized/hue_21/brightness_03/2020040416294601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":3,
      "vividness":9,
      "r":19,
      "g":20,
      "b":50,
      "hex":"#131432",
      "file_location":"assets/colors_organized/hue_21/brightness_03/2020040416294700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":3,
      "vividness":10,
      "r":14,
      "g":14,
      "b":48,
      "hex":"#0e0e30",
      "file_location":"assets/colors_organized/hue_21/brightness_03/2020040416294701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":3,
      "vividness":11,
      "r":13,
      "g":13,
      "b":49,
      "hex":"#0d0d31",
      "file_location":"assets/colors_organized/hue_21/brightness_03/2020040416294800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":3,
      "vividness":12,
      "r":10,
      "g":9,
      "b":49,
      "hex":"#0a0931",
      "file_location":"assets/colors_organized/hue_21/brightness_03/2020040416294801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":3,
      "vividness":13,
      "r":5,
      "g":7,
      "b":48,
      "hex":"#050730",
      "file_location":"assets/colors_organized/hue_21/brightness_03/2020040416294900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":3,
      "vividness":14,
      "r":2,
      "g":3,
      "b":49,
      "hex":"#020331",
      "file_location":"assets/colors_organized/hue_21/brightness_03/2020040416295000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":4,
      "vividness":1,
      "r":59,
      "g":60,
      "b":65,
      "hex":"#3b3c41",
      "file_location":"assets/colors_organized/hue_21/brightness_04/2020040416293201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":4,
      "vividness":2,
      "r":56,
      "g":56,
      "b":66,
      "hex":"#383842",
      "file_location":"assets/colors_organized/hue_21/brightness_04/2020040416293300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":4,
      "vividness":3,
      "r":52,
      "g":51,
      "b":65,
      "hex":"#343341",
      "file_location":"assets/colors_organized/hue_21/brightness_04/2020040416293301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":4,
      "vividness":4,
      "r":46,
      "g":47,
      "b":65,
      "hex":"#2e2f41",
      "file_location":"assets/colors_organized/hue_21/brightness_04/2020040416293400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":4,
      "vividness":5,
      "r":42,
      "g":43,
      "b":64,
      "hex":"#2a2b40",
      "file_location":"assets/colors_organized/hue_21/brightness_04/2020040416293401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":4,
      "vividness":6,
      "r":39,
      "g":39,
      "b":65,
      "hex":"#272741",
      "file_location":"assets/colors_organized/hue_21/brightness_04/2020040416293500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":4,
      "vividness":7,
      "r":33,
      "g":34,
      "b":65,
      "hex":"#212241",
      "file_location":"assets/colors_organized/hue_21/brightness_04/2020040416293501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":4,
      "vividness":8,
      "r":30,
      "g":30,
      "b":66,
      "hex":"#1e1e42",
      "file_location":"assets/colors_organized/hue_21/brightness_04/2020040416293600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":4,
      "vividness":9,
      "r":27,
      "g":27,
      "b":65,
      "hex":"#1b1b41",
      "file_location":"assets/colors_organized/hue_21/brightness_04/2020040416293601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":4,
      "vividness":10,
      "r":20,
      "g":21,
      "b":65,
      "hex":"#141541",
      "file_location":"assets/colors_organized/hue_21/brightness_04/2020040416293700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":4,
      "vividness":11,
      "r":17,
      "g":18,
      "b":66,
      "hex":"#111242",
      "file_location":"assets/colors_organized/hue_21/brightness_04/2020040416293701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":4,
      "vividness":12,
      "r":12,
      "g":12,
      "b":64,
      "hex":"#0c0c40",
      "file_location":"assets/colors_organized/hue_21/brightness_04/2020040416293800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":4,
      "vividness":13,
      "r":6,
      "g":7,
      "b":64,
      "hex":"#060740",
      "file_location":"assets/colors_organized/hue_21/brightness_04/2020040416293801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":4,
      "vividness":14,
      "r":3,
      "g":3,
      "b":65,
      "hex":"#030341",
      "file_location":"assets/colors_organized/hue_21/brightness_04/2020040416293900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":5,
      "vividness":1,
      "r":73,
      "g":74,
      "b":79,
      "hex":"#494a4f",
      "file_location":"assets/colors_organized/hue_21/brightness_05/2020040416292201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":5,
      "vividness":2,
      "r":69,
      "g":69,
      "b":79,
      "hex":"#45454f",
      "file_location":"assets/colors_organized/hue_21/brightness_05/2020040416292300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":5,
      "vividness":3,
      "r":65,
      "g":64,
      "b":80,
      "hex":"#414050",
      "file_location":"assets/colors_organized/hue_21/brightness_05/2020040416292301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":5,
      "vividness":4,
      "r":57,
      "g":58,
      "b":79,
      "hex":"#393a4f",
      "file_location":"assets/colors_organized/hue_21/brightness_05/2020040416292400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":5,
      "vividness":5,
      "r":53,
      "g":53,
      "b":81,
      "hex":"#353551",
      "file_location":"assets/colors_organized/hue_21/brightness_05/2020040416292401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":5,
      "vividness":6,
      "r":47,
      "g":48,
      "b":79,
      "hex":"#2f304f",
      "file_location":"assets/colors_organized/hue_21/brightness_05/2020040416292500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":5,
      "vividness":7,
      "r":42,
      "g":42,
      "b":80,
      "hex":"#2a2a50",
      "file_location":"assets/colors_organized/hue_21/brightness_05/2020040416292501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":5,
      "vividness":8,
      "r":35,
      "g":36,
      "b":80,
      "hex":"#232450",
      "file_location":"assets/colors_organized/hue_21/brightness_05/2020040416292600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":5,
      "vividness":9,
      "r":31,
      "g":32,
      "b":80,
      "hex":"#1f2050",
      "file_location":"assets/colors_organized/hue_21/brightness_05/2020040416292601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":5,
      "vividness":10,
      "r":27,
      "g":27,
      "b":81,
      "hex":"#1b1b51",
      "file_location":"assets/colors_organized/hue_21/brightness_05/2020040416292700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":5,
      "vividness":11,
      "r":21,
      "g":22,
      "b":79,
      "hex":"#15164f",
      "file_location":"assets/colors_organized/hue_21/brightness_05/2020040416292701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":5,
      "vividness":12,
      "r":15,
      "g":14,
      "b":80,
      "hex":"#0f0e50",
      "file_location":"assets/colors_organized/hue_21/brightness_05/2020040416292800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":5,
      "vividness":13,
      "r":10,
      "g":10,
      "b":80,
      "hex":"#0a0a50",
      "file_location":"assets/colors_organized/hue_21/brightness_05/2020040416292801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":5,
      "vividness":14,
      "r":7,
      "g":7,
      "b":81,
      "hex":"#070751",
      "file_location":"assets/colors_organized/hue_21/brightness_05/2020040416292900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":6,
      "vividness":1,
      "r":88,
      "g":89,
      "b":94,
      "hex":"#58595e",
      "file_location":"assets/colors_organized/hue_21/brightness_06/2020040416291201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":6,
      "vividness":2,
      "r":82,
      "g":82,
      "b":94,
      "hex":"#52525e",
      "file_location":"assets/colors_organized/hue_21/brightness_06/2020040416291300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":6,
      "vividness":3,
      "r":75,
      "g":76,
      "b":94,
      "hex":"#4b4c5e",
      "file_location":"assets/colors_organized/hue_21/brightness_06/2020040416291301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":6,
      "vividness":4,
      "r":69,
      "g":69,
      "b":95,
      "hex":"#45455f",
      "file_location":"assets/colors_organized/hue_21/brightness_06/2020040416291400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":6,
      "vividness":5,
      "r":62,
      "g":63,
      "b":94,
      "hex":"#3e3f5e",
      "file_location":"assets/colors_organized/hue_21/brightness_06/2020040416291401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":6,
      "vividness":6,
      "r":57,
      "g":57,
      "b":95,
      "hex":"#39395f",
      "file_location":"assets/colors_organized/hue_21/brightness_06/2020040416291500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":6,
      "vividness":7,
      "r":49,
      "g":50,
      "b":96,
      "hex":"#313260",
      "file_location":"assets/colors_organized/hue_21/brightness_06/2020040416291501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":6,
      "vividness":8,
      "r":43,
      "g":43,
      "b":95,
      "hex":"#2b2b5f",
      "file_location":"assets/colors_organized/hue_21/brightness_06/2020040416291600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":6,
      "vividness":9,
      "r":37,
      "g":38,
      "b":95,
      "hex":"#25265f",
      "file_location":"assets/colors_organized/hue_21/brightness_06/2020040416291601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":6,
      "vividness":10,
      "r":31,
      "g":31,
      "b":95,
      "hex":"#1f1f5f",
      "file_location":"assets/colors_organized/hue_21/brightness_06/2020040416291700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":6,
      "vividness":11,
      "r":25,
      "g":25,
      "b":95,
      "hex":"#19195f",
      "file_location":"assets/colors_organized/hue_21/brightness_06/2020040416291701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":6,
      "vividness":12,
      "r":20,
      "g":19,
      "b":95,
      "hex":"#14135f",
      "file_location":"assets/colors_organized/hue_21/brightness_06/2020040416291800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":6,
      "vividness":13,
      "r":12,
      "g":13,
      "b":95,
      "hex":"#0c0d5f",
      "file_location":"assets/colors_organized/hue_21/brightness_06/2020040416291801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":6,
      "vividness":14,
      "r":7,
      "g":7,
      "b":95,
      "hex":"#07075f",
      "file_location":"assets/colors_organized/hue_21/brightness_06/2020040416291802-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":7,
      "vividness":1,
      "r":102,
      "g":102,
      "b":110,
      "hex":"#66666e",
      "file_location":"assets/colors_organized/hue_21/brightness_07/2020040416290201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":7,
      "vividness":2,
      "r":96,
      "g":95,
      "b":109,
      "hex":"#605f6d",
      "file_location":"assets/colors_organized/hue_21/brightness_07/2020040416290300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":7,
      "vividness":3,
      "r":87,
      "g":88,
      "b":109,
      "hex":"#57586d",
      "file_location":"assets/colors_organized/hue_21/brightness_07/2020040416290301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":7,
      "vividness":4,
      "r":79,
      "g":80,
      "b":110,
      "hex":"#4f506e",
      "file_location":"assets/colors_organized/hue_21/brightness_07/2020040416290400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":7,
      "vividness":5,
      "r":73,
      "g":73,
      "b":109,
      "hex":"#49496d",
      "file_location":"assets/colors_organized/hue_21/brightness_07/2020040416290401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":7,
      "vividness":6,
      "r":65,
      "g":66,
      "b":110,
      "hex":"#41426e",
      "file_location":"assets/colors_organized/hue_21/brightness_07/2020040416290500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":7,
      "vividness":7,
      "r":58,
      "g":58,
      "b":110,
      "hex":"#3a3a6e",
      "file_location":"assets/colors_organized/hue_21/brightness_07/2020040416290501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":7,
      "vividness":8,
      "r":50,
      "g":50,
      "b":110,
      "hex":"#32326e",
      "file_location":"assets/colors_organized/hue_21/brightness_07/2020040416290600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":7,
      "vividness":9,
      "r":43,
      "g":44,
      "b":109,
      "hex":"#2b2c6d",
      "file_location":"assets/colors_organized/hue_21/brightness_07/2020040416290601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":7,
      "vividness":10,
      "r":36,
      "g":36,
      "b":110,
      "hex":"#24246e",
      "file_location":"assets/colors_organized/hue_21/brightness_07/2020040416290700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":7,
      "vividness":11,
      "r":29,
      "g":30,
      "b":110,
      "hex":"#1d1e6e",
      "file_location":"assets/colors_organized/hue_21/brightness_07/2020040416290701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":7,
      "vividness":12,
      "r":22,
      "g":22,
      "b":110,
      "hex":"#16166e",
      "file_location":"assets/colors_organized/hue_21/brightness_07/2020040416290800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":7,
      "vividness":13,
      "r":13,
      "g":13,
      "b":111,
      "hex":"#0d0d6f",
      "file_location":"assets/colors_organized/hue_21/brightness_07/2020040416290801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":7,
      "vividness":14,
      "r":5,
      "g":7,
      "b":110,
      "hex":"#05076e",
      "file_location":"assets/colors_organized/hue_21/brightness_07/2020040416290900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":8,
      "vividness":1,
      "r":116,
      "g":116,
      "b":124,
      "hex":"#74747c",
      "file_location":"assets/colors_organized/hue_21/brightness_08/2020040416285001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":8,
      "vividness":2,
      "r":109,
      "g":108,
      "b":124,
      "hex":"#6d6c7c",
      "file_location":"assets/colors_organized/hue_21/brightness_08/2020040416285100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":8,
      "vividness":3,
      "r":99,
      "g":99,
      "b":123,
      "hex":"#63637b",
      "file_location":"assets/colors_organized/hue_21/brightness_08/2020040416285101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":8,
      "vividness":4,
      "r":91,
      "g":92,
      "b":123,
      "hex":"#5b5c7b",
      "file_location":"assets/colors_organized/hue_21/brightness_08/2020040416285200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":8,
      "vividness":5,
      "r":84,
      "g":83,
      "b":123,
      "hex":"#54537b",
      "file_location":"assets/colors_organized/hue_21/brightness_08/2020040416285300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":8,
      "vividness":6,
      "r":74,
      "g":74,
      "b":124,
      "hex":"#4a4a7c",
      "file_location":"assets/colors_organized/hue_21/brightness_08/2020040416285301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":8,
      "vividness":7,
      "r":65,
      "g":66,
      "b":123,
      "hex":"#41427b",
      "file_location":"assets/colors_organized/hue_21/brightness_08/2020040416285400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":8,
      "vividness":8,
      "r":57,
      "g":58,
      "b":123,
      "hex":"#393a7b",
      "file_location":"assets/colors_organized/hue_21/brightness_08/2020040416285401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":8,
      "vividness":9,
      "r":49,
      "g":48,
      "b":124,
      "hex":"#31307c",
      "file_location":"assets/colors_organized/hue_21/brightness_08/2020040416285500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":8,
      "vividness":10,
      "r":40,
      "g":41,
      "b":124,
      "hex":"#28297c",
      "file_location":"assets/colors_organized/hue_21/brightness_08/2020040416285501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":8,
      "vividness":11,
      "r":34,
      "g":34,
      "b":124,
      "hex":"#22227c",
      "file_location":"assets/colors_organized/hue_21/brightness_08/2020040416285600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":8,
      "vividness":12,
      "r":23,
      "g":22,
      "b":124,
      "hex":"#17167c",
      "file_location":"assets/colors_organized/hue_21/brightness_08/2020040416285601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":8,
      "vividness":13,
      "r":14,
      "g":14,
      "b":124,
      "hex":"#0e0e7c",
      "file_location":"assets/colors_organized/hue_21/brightness_08/2020040416285700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":8,
      "vividness":14,
      "r":6,
      "g":7,
      "b":125,
      "hex":"#06077d",
      "file_location":"assets/colors_organized/hue_21/brightness_08/2020040416285701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":9,
      "vividness":1,
      "r":130,
      "g":130,
      "b":138,
      "hex":"#82828a",
      "file_location":"assets/colors_organized/hue_21/brightness_09/2020040416283900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":9,
      "vividness":2,
      "r":120,
      "g":121,
      "b":139,
      "hex":"#78798b",
      "file_location":"assets/colors_organized/hue_21/brightness_09/2020040416283901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":9,
      "vividness":3,
      "r":111,
      "g":111,
      "b":139,
      "hex":"#6f6f8b",
      "file_location":"assets/colors_organized/hue_21/brightness_09/2020040416284000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":9,
      "vividness":4,
      "r":102,
      "g":102,
      "b":140,
      "hex":"#66668c",
      "file_location":"assets/colors_organized/hue_21/brightness_09/2020040416284001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":9,
      "vividness":5,
      "r":92,
      "g":93,
      "b":139,
      "hex":"#5c5d8b",
      "file_location":"assets/colors_organized/hue_21/brightness_09/2020040416284100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":9,
      "vividness":6,
      "r":82,
      "g":83,
      "b":139,
      "hex":"#52538b",
      "file_location":"assets/colors_organized/hue_21/brightness_09/2020040416284200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":9,
      "vividness":7,
      "r":74,
      "g":73,
      "b":139,
      "hex":"#4a498b",
      "file_location":"assets/colors_organized/hue_21/brightness_09/2020040416284300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":9,
      "vividness":8,
      "r":65,
      "g":65,
      "b":139,
      "hex":"#41418b",
      "file_location":"assets/colors_organized/hue_21/brightness_09/2020040416284301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":9,
      "vividness":9,
      "r":54,
      "g":55,
      "b":138,
      "hex":"#36378a",
      "file_location":"assets/colors_organized/hue_21/brightness_09/2020040416284400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":9,
      "vividness":10,
      "r":45,
      "g":46,
      "b":139,
      "hex":"#2d2e8b",
      "file_location":"assets/colors_organized/hue_21/brightness_09/2020040416284401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":9,
      "vividness":11,
      "r":37,
      "g":36,
      "b":138,
      "hex":"#25248a",
      "file_location":"assets/colors_organized/hue_21/brightness_09/2020040416284500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":9,
      "vividness":12,
      "r":26,
      "g":26,
      "b":138,
      "hex":"#1a1a8a",
      "file_location":"assets/colors_organized/hue_21/brightness_09/2020040416284501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":9,
      "vividness":13,
      "r":17,
      "g":17,
      "b":139,
      "hex":"#11118b",
      "file_location":"assets/colors_organized/hue_21/brightness_09/2020040416284600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":9,
      "vividness":14,
      "r":9,
      "g":9,
      "b":139,
      "hex":"#09098b",
      "file_location":"assets/colors_organized/hue_21/brightness_09/2020040416284700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":10,
      "vividness":1,
      "r":144,
      "g":144,
      "b":154,
      "hex":"#90909a",
      "file_location":"assets/colors_organized/hue_21/brightness_10/2020040416282801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":10,
      "vividness":2,
      "r":133,
      "g":134,
      "b":154,
      "hex":"#85869a",
      "file_location":"assets/colors_organized/hue_21/brightness_10/2020040416282802-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":10,
      "vividness":3,
      "r":122,
      "g":123,
      "b":154,
      "hex":"#7a7b9a",
      "file_location":"assets/colors_organized/hue_21/brightness_10/2020040416282900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":10,
      "vividness":4,
      "r":114,
      "g":113,
      "b":155,
      "hex":"#72719b",
      "file_location":"assets/colors_organized/hue_21/brightness_10/2020040416283000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":10,
      "vividness":5,
      "r":103,
      "g":103,
      "b":155,
      "hex":"#67679b",
      "file_location":"assets/colors_organized/hue_21/brightness_10/2020040416283001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":10,
      "vividness":6,
      "r":92,
      "g":92,
      "b":154,
      "hex":"#5c5c9a",
      "file_location":"assets/colors_organized/hue_21/brightness_10/2020040416283100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":10,
      "vividness":7,
      "r":82,
      "g":82,
      "b":154,
      "hex":"#52529a",
      "file_location":"assets/colors_organized/hue_21/brightness_10/2020040416283101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":10,
      "vividness":8,
      "r":71,
      "g":72,
      "b":154,
      "hex":"#47489a",
      "file_location":"assets/colors_organized/hue_21/brightness_10/2020040416283200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":10,
      "vividness":9,
      "r":60,
      "g":61,
      "b":154,
      "hex":"#3c3d9a",
      "file_location":"assets/colors_organized/hue_21/brightness_10/2020040416283201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":10,
      "vividness":10,
      "r":49,
      "g":51,
      "b":154,
      "hex":"#31339a",
      "file_location":"assets/colors_organized/hue_21/brightness_10/2020040416283300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":10,
      "vividness":11,
      "r":40,
      "g":40,
      "b":154,
      "hex":"#28289a",
      "file_location":"assets/colors_organized/hue_21/brightness_10/2020040416283301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":10,
      "vividness":12,
      "r":30,
      "g":30,
      "b":154,
      "hex":"#1e1e9a",
      "file_location":"assets/colors_organized/hue_21/brightness_10/2020040416283400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":10,
      "vividness":13,
      "r":20,
      "g":20,
      "b":154,
      "hex":"#14149a",
      "file_location":"assets/colors_organized/hue_21/brightness_10/2020040416283401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":10,
      "vividness":14,
      "r":9,
      "g":10,
      "b":154,
      "hex":"#090a9a",
      "file_location":"assets/colors_organized/hue_21/brightness_10/2020040416283500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":11,
      "vividness":1,
      "r":158,
      "g":158,
      "b":168,
      "hex":"#9e9ea8",
      "file_location":"assets/colors_organized/hue_21/brightness_11/2020040416281800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":11,
      "vividness":2,
      "r":147,
      "g":148,
      "b":169,
      "hex":"#9394a9",
      "file_location":"assets/colors_organized/hue_21/brightness_11/2020040416281801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":11,
      "vividness":3,
      "r":135,
      "g":135,
      "b":169,
      "hex":"#8787a9",
      "file_location":"assets/colors_organized/hue_21/brightness_11/2020040416281900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":11,
      "vividness":4,
      "r":123,
      "g":124,
      "b":170,
      "hex":"#7b7caa",
      "file_location":"assets/colors_organized/hue_21/brightness_11/2020040416281901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":11,
      "vividness":5,
      "r":112,
      "g":113,
      "b":169,
      "hex":"#7071a9",
      "file_location":"assets/colors_organized/hue_21/brightness_11/2020040416282000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":11,
      "vividness":6,
      "r":101,
      "g":102,
      "b":169,
      "hex":"#6566a9",
      "file_location":"assets/colors_organized/hue_21/brightness_11/2020040416282001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":11,
      "vividness":7,
      "r":89,
      "g":90,
      "b":170,
      "hex":"#595aaa",
      "file_location":"assets/colors_organized/hue_21/brightness_11/2020040416282100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":11,
      "vividness":8,
      "r":79,
      "g":79,
      "b":169,
      "hex":"#4f4fa9",
      "file_location":"assets/colors_organized/hue_21/brightness_11/2020040416282101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":11,
      "vividness":9,
      "r":67,
      "g":66,
      "b":168,
      "hex":"#4342a8",
      "file_location":"assets/colors_organized/hue_21/brightness_11/2020040416282200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":11,
      "vividness":10,
      "r":55,
      "g":55,
      "b":169,
      "hex":"#3737a9",
      "file_location":"assets/colors_organized/hue_21/brightness_11/2020040416282201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":11,
      "vividness":11,
      "r":45,
      "g":45,
      "b":169,
      "hex":"#2d2da9",
      "file_location":"assets/colors_organized/hue_21/brightness_11/2020040416282300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":11,
      "vividness":12,
      "r":34,
      "g":33,
      "b":169,
      "hex":"#2221a9",
      "file_location":"assets/colors_organized/hue_21/brightness_11/2020040416282301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":11,
      "vividness":13,
      "r":21,
      "g":21,
      "b":169,
      "hex":"#1515a9",
      "file_location":"assets/colors_organized/hue_21/brightness_11/2020040416282400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":11,
      "vividness":14,
      "r":10,
      "g":10,
      "b":170,
      "hex":"#0a0aaa",
      "file_location":"assets/colors_organized/hue_21/brightness_11/2020040416282401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":12,
      "vividness":1,
      "r":173,
      "g":173,
      "b":185,
      "hex":"#adadb9",
      "file_location":"assets/colors_organized/hue_21/brightness_12/2020040416280801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":12,
      "vividness":2,
      "r":160,
      "g":160,
      "b":184,
      "hex":"#a0a0b8",
      "file_location":"assets/colors_organized/hue_21/brightness_12/2020040416280802-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":12,
      "vividness":3,
      "r":147,
      "g":147,
      "b":183,
      "hex":"#9393b7",
      "file_location":"assets/colors_organized/hue_21/brightness_12/2020040416280900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":12,
      "vividness":4,
      "r":135,
      "g":136,
      "b":184,
      "hex":"#8788b8",
      "file_location":"assets/colors_organized/hue_21/brightness_12/2020040416280901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":12,
      "vividness":5,
      "r":123,
      "g":123,
      "b":185,
      "hex":"#7b7bb9",
      "file_location":"assets/colors_organized/hue_21/brightness_12/2020040416281000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":12,
      "vividness":6,
      "r":110,
      "g":110,
      "b":184,
      "hex":"#6e6eb8",
      "file_location":"assets/colors_organized/hue_21/brightness_12/2020040416281001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":12,
      "vividness":7,
      "r":98,
      "g":98,
      "b":184,
      "hex":"#6262b8",
      "file_location":"assets/colors_organized/hue_21/brightness_12/2020040416281100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":12,
      "vividness":8,
      "r":86,
      "g":86,
      "b":184,
      "hex":"#5656b8",
      "file_location":"assets/colors_organized/hue_21/brightness_12/2020040416281101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":12,
      "vividness":9,
      "r":73,
      "g":73,
      "b":185,
      "hex":"#4949b9",
      "file_location":"assets/colors_organized/hue_21/brightness_12/2020040416281200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":12,
      "vividness":10,
      "r":61,
      "g":61,
      "b":185,
      "hex":"#3d3db9",
      "file_location":"assets/colors_organized/hue_21/brightness_12/2020040416281201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":12,
      "vividness":11,
      "r":49,
      "g":48,
      "b":184,
      "hex":"#3130b8",
      "file_location":"assets/colors_organized/hue_21/brightness_12/2020040416281300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":12,
      "vividness":12,
      "r":35,
      "g":35,
      "b":183,
      "hex":"#2323b7",
      "file_location":"assets/colors_organized/hue_21/brightness_12/2020040416281301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":12,
      "vividness":13,
      "r":23,
      "g":22,
      "b":185,
      "hex":"#1716b9",
      "file_location":"assets/colors_organized/hue_21/brightness_12/2020040416281400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":12,
      "vividness":14,
      "r":12,
      "g":12,
      "b":184,
      "hex":"#0c0cb8",
      "file_location":"assets/colors_organized/hue_21/brightness_12/2020040416281401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":13,
      "vividness":1,
      "r":187,
      "g":186,
      "b":200,
      "hex":"#bbbac8",
      "file_location":"assets/colors_organized/hue_21/brightness_13/2020040416275800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":13,
      "vividness":2,
      "r":173,
      "g":173,
      "b":199,
      "hex":"#adadc7",
      "file_location":"assets/colors_organized/hue_21/brightness_13/2020040416275900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":13,
      "vividness":3,
      "r":160,
      "g":159,
      "b":199,
      "hex":"#a09fc7",
      "file_location":"assets/colors_organized/hue_21/brightness_13/2020040416275901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":13,
      "vividness":4,
      "r":146,
      "g":146,
      "b":200,
      "hex":"#9292c8",
      "file_location":"assets/colors_organized/hue_21/brightness_13/2020040416275902-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":13,
      "vividness":5,
      "r":133,
      "g":134,
      "b":199,
      "hex":"#8586c7",
      "file_location":"assets/colors_organized/hue_21/brightness_13/2020040416280000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":13,
      "vividness":6,
      "r":118,
      "g":119,
      "b":199,
      "hex":"#7677c7",
      "file_location":"assets/colors_organized/hue_21/brightness_13/2020040416280001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":13,
      "vividness":7,
      "r":106,
      "g":107,
      "b":200,
      "hex":"#6a6bc8",
      "file_location":"assets/colors_organized/hue_21/brightness_13/2020040416280100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":13,
      "vividness":8,
      "r":92,
      "g":93,
      "b":199,
      "hex":"#5c5dc7",
      "file_location":"assets/colors_organized/hue_21/brightness_13/2020040416280101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":13,
      "vividness":9,
      "r":79,
      "g":79,
      "b":199,
      "hex":"#4f4fc7",
      "file_location":"assets/colors_organized/hue_21/brightness_13/2020040416280200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":13,
      "vividness":10,
      "r":66,
      "g":66,
      "b":200,
      "hex":"#4242c8",
      "file_location":"assets/colors_organized/hue_21/brightness_13/2020040416280201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":13,
      "vividness":11,
      "r":52,
      "g":52,
      "b":198,
      "hex":"#3434c6",
      "file_location":"assets/colors_organized/hue_21/brightness_13/2020040416280300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":13,
      "vividness":12,
      "r":39,
      "g":39,
      "b":199,
      "hex":"#2727c7",
      "file_location":"assets/colors_organized/hue_21/brightness_13/2020040416280301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":13,
      "vividness":13,
      "r":26,
      "g":26,
      "b":198,
      "hex":"#1a1ac6",
      "file_location":"assets/colors_organized/hue_21/brightness_13/2020040416280400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":13,
      "vividness":14,
      "r":13,
      "g":13,
      "b":199,
      "hex":"#0d0dc7",
      "file_location":"assets/colors_organized/hue_21/brightness_13/2020040416280401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":14,
      "vividness":1,
      "r":201,
      "g":200,
      "b":214,
      "hex":"#c9c8d6",
      "file_location":"assets/colors_organized/hue_21/brightness_14/2020040416274801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":14,
      "vividness":2,
      "r":185,
      "g":186,
      "b":216,
      "hex":"#b9bad8",
      "file_location":"assets/colors_organized/hue_21/brightness_14/2020040416274900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":14,
      "vividness":3,
      "r":170,
      "g":171,
      "b":215,
      "hex":"#aaabd7",
      "file_location":"assets/colors_organized/hue_21/brightness_14/2020040416274901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":14,
      "vividness":4,
      "r":157,
      "g":158,
      "b":215,
      "hex":"#9d9ed7",
      "file_location":"assets/colors_organized/hue_21/brightness_14/2020040416274902-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":14,
      "vividness":5,
      "r":143,
      "g":143,
      "b":215,
      "hex":"#8f8fd7",
      "file_location":"assets/colors_organized/hue_21/brightness_14/2020040416275000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":14,
      "vividness":6,
      "r":128,
      "g":128,
      "b":214,
      "hex":"#8080d6",
      "file_location":"assets/colors_organized/hue_21/brightness_14/2020040416275001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":14,
      "vividness":7,
      "r":114,
      "g":114,
      "b":214,
      "hex":"#7272d6",
      "file_location":"assets/colors_organized/hue_21/brightness_14/2020040416275100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":14,
      "vividness":8,
      "r":100,
      "g":100,
      "b":214,
      "hex":"#6464d6",
      "file_location":"assets/colors_organized/hue_21/brightness_14/2020040416275101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":14,
      "vividness":9,
      "r":85,
      "g":85,
      "b":215,
      "hex":"#5555d7",
      "file_location":"assets/colors_organized/hue_21/brightness_14/2020040416275200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":14,
      "vividness":10,
      "r":70,
      "g":71,
      "b":215,
      "hex":"#4647d7",
      "file_location":"assets/colors_organized/hue_21/brightness_14/2020040416275201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":14,
      "vividness":11,
      "r":57,
      "g":57,
      "b":215,
      "hex":"#3939d7",
      "file_location":"assets/colors_organized/hue_21/brightness_14/2020040416275300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":14,
      "vividness":12,
      "r":41,
      "g":41,
      "b":213,
      "hex":"#2929d5",
      "file_location":"assets/colors_organized/hue_21/brightness_14/2020040416275301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":14,
      "vividness":13,
      "r":28,
      "g":28,
      "b":214,
      "hex":"#1c1cd6",
      "file_location":"assets/colors_organized/hue_21/brightness_14/2020040416275400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":14,
      "vividness":14,
      "r":12,
      "g":13,
      "b":215,
      "hex":"#0c0dd7",
      "file_location":"assets/colors_organized/hue_21/brightness_14/2020040416275401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":15,
      "vividness":1,
      "r":215,
      "g":214,
      "b":228,
      "hex":"#d7d6e4",
      "file_location":"assets/colors_organized/hue_21/brightness_15/2020040416273800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":15,
      "vividness":2,
      "r":198,
      "g":199,
      "b":229,
      "hex":"#c6c7e5",
      "file_location":"assets/colors_organized/hue_21/brightness_15/2020040416273801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":15,
      "vividness":3,
      "r":182,
      "g":183,
      "b":229,
      "hex":"#b6b7e5",
      "file_location":"assets/colors_organized/hue_21/brightness_15/2020040416273900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":15,
      "vividness":4,
      "r":168,
      "g":168,
      "b":230,
      "hex":"#a8a8e6",
      "file_location":"assets/colors_organized/hue_21/brightness_15/2020040416273901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":15,
      "vividness":5,
      "r":153,
      "g":152,
      "b":230,
      "hex":"#9998e6",
      "file_location":"assets/colors_organized/hue_21/brightness_15/2020040416274000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":15,
      "vividness":6,
      "r":137,
      "g":138,
      "b":230,
      "hex":"#898ae6",
      "file_location":"assets/colors_organized/hue_21/brightness_15/2020040416274001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":15,
      "vividness":7,
      "r":121,
      "g":122,
      "b":230,
      "hex":"#797ae6",
      "file_location":"assets/colors_organized/hue_21/brightness_15/2020040416274100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":15,
      "vividness":8,
      "r":107,
      "g":107,
      "b":229,
      "hex":"#6b6be5",
      "file_location":"assets/colors_organized/hue_21/brightness_15/2020040416274101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":15,
      "vividness":9,
      "r":92,
      "g":91,
      "b":229,
      "hex":"#5c5be5",
      "file_location":"assets/colors_organized/hue_21/brightness_15/2020040416274200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":15,
      "vividness":10,
      "r":75,
      "g":76,
      "b":229,
      "hex":"#4b4ce5",
      "file_location":"assets/colors_organized/hue_21/brightness_15/2020040416274201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":15,
      "vividness":11,
      "r":60,
      "g":61,
      "b":229,
      "hex":"#3c3de5",
      "file_location":"assets/colors_organized/hue_21/brightness_15/2020040416274300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":15,
      "vividness":12,
      "r":45,
      "g":45,
      "b":229,
      "hex":"#2d2de5",
      "file_location":"assets/colors_organized/hue_21/brightness_15/2020040416274301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":15,
      "vividness":13,
      "r":30,
      "g":29,
      "b":229,
      "hex":"#1e1de5",
      "file_location":"assets/colors_organized/hue_21/brightness_15/2020040416274400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":21,
      "brightness":15,
      "vividness":14,
      "r":14,
      "g":15,
      "b":229,
      "hex":"#0e0fe5",
      "file_location":"assets/colors_organized/hue_21/brightness_15/2020040416274401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":1,
      "vividness":1,
      "r":17,
      "g":15,
      "b":20,
      "hex":"#110f14",
      "file_location":"assets/colors_organized/hue_22/brightness_01/2020040416345201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":1,
      "vividness":2,
      "r":14,
      "g":13,
      "b":19,
      "hex":"#0e0d13",
      "file_location":"assets/colors_organized/hue_22/brightness_01/2020040416345300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":1,
      "vividness":3,
      "r":13,
      "g":10,
      "b":19,
      "hex":"#0d0a13",
      "file_location":"assets/colors_organized/hue_22/brightness_01/2020040416345400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":1,
      "vividness":4,
      "r":9,
      "g":7,
      "b":20,
      "hex":"#090714",
      "file_location":"assets/colors_organized/hue_22/brightness_01/2020040416345500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":1,
      "vividness":5,
      "r":6,
      "g":3,
      "b":20,
      "hex":"#060314",
      "file_location":"assets/colors_organized/hue_22/brightness_01/2020040416345601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":1,
      "vividness":6,
      "r":7,
      "g":0,
      "b":20,
      "hex":"#070014",
      "file_location":"assets/colors_organized/hue_22/brightness_01/2020040416345701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":2,
      "vividness":1,
      "r":33,
      "g":31,
      "b":34,
      "hex":"#211f22",
      "file_location":"assets/colors_organized/hue_22/brightness_02/2020040416344100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":2,
      "vividness":2,
      "r":30,
      "g":29,
      "b":34,
      "hex":"#1e1d22",
      "file_location":"assets/colors_organized/hue_22/brightness_02/2020040416344101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":2,
      "vividness":3,
      "r":29,
      "g":28,
      "b":34,
      "hex":"#1d1c22",
      "file_location":"assets/colors_organized/hue_22/brightness_02/2020040416344200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":2,
      "vividness":4,
      "r":26,
      "g":24,
      "b":35,
      "hex":"#1a1823",
      "file_location":"assets/colors_organized/hue_22/brightness_02/2020040416344201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":2,
      "vividness":5,
      "r":24,
      "g":22,
      "b":35,
      "hex":"#181623",
      "file_location":"assets/colors_organized/hue_22/brightness_02/2020040416344300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":2,
      "vividness":6,
      "r":21,
      "g":18,
      "b":35,
      "hex":"#151223",
      "file_location":"assets/colors_organized/hue_22/brightness_02/2020040416344302-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":2,
      "vividness":7,
      "r":19,
      "g":14,
      "b":34,
      "hex":"#130e22",
      "file_location":"assets/colors_organized/hue_22/brightness_02/2020040416344400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":2,
      "vividness":8,
      "r":18,
      "g":13,
      "b":35,
      "hex":"#120d23",
      "file_location":"assets/colors_organized/hue_22/brightness_02/2020040416344401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":2,
      "vividness":9,
      "r":14,
      "g":10,
      "b":35,
      "hex":"#0e0a23",
      "file_location":"assets/colors_organized/hue_22/brightness_02/2020040416344500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":2,
      "vividness":10,
      "r":12,
      "g":10,
      "b":34,
      "hex":"#0c0a22",
      "file_location":"assets/colors_organized/hue_22/brightness_02/2020040416344501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":2,
      "vividness":11,
      "r":12,
      "g":6,
      "b":34,
      "hex":"#0c0622",
      "file_location":"assets/colors_organized/hue_22/brightness_02/2020040416344600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":2,
      "vividness":12,
      "r":9,
      "g":2,
      "b":35,
      "hex":"#090223",
      "file_location":"assets/colors_organized/hue_22/brightness_02/2020040416344601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":2,
      "vividness":13,
      "r":6,
      "g":3,
      "b":34,
      "hex":"#060322",
      "file_location":"assets/colors_organized/hue_22/brightness_02/2020040416344700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":3,
      "vividness":1,
      "r":44,
      "g":43,
      "b":49,
      "hex":"#2c2b31",
      "file_location":"assets/colors_organized/hue_22/brightness_03/2020040416343100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":3,
      "vividness":2,
      "r":41,
      "g":39,
      "b":50,
      "hex":"#292732",
      "file_location":"assets/colors_organized/hue_22/brightness_03/2020040416343101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":3,
      "vividness":3,
      "r":38,
      "g":36,
      "b":49,
      "hex":"#262431",
      "file_location":"assets/colors_organized/hue_22/brightness_03/2020040416343200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":3,
      "vividness":4,
      "r":36,
      "g":33,
      "b":50,
      "hex":"#242132",
      "file_location":"assets/colors_organized/hue_22/brightness_03/2020040416343201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":3,
      "vividness":5,
      "r":33,
      "g":30,
      "b":49,
      "hex":"#211e31",
      "file_location":"assets/colors_organized/hue_22/brightness_03/2020040416343300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":3,
      "vividness":6,
      "r":30,
      "g":26,
      "b":49,
      "hex":"#1e1a31",
      "file_location":"assets/colors_organized/hue_22/brightness_03/2020040416343301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":3,
      "vividness":7,
      "r":27,
      "g":23,
      "b":50,
      "hex":"#1b1732",
      "file_location":"assets/colors_organized/hue_22/brightness_03/2020040416343400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":3,
      "vividness":8,
      "r":26,
      "g":19,
      "b":50,
      "hex":"#1a1332",
      "file_location":"assets/colors_organized/hue_22/brightness_03/2020040416343401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":3,
      "vividness":9,
      "r":22,
      "g":15,
      "b":49,
      "hex":"#160f31",
      "file_location":"assets/colors_organized/hue_22/brightness_03/2020040416343500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":3,
      "vividness":10,
      "r":19,
      "g":13,
      "b":49,
      "hex":"#130d31",
      "file_location":"assets/colors_organized/hue_22/brightness_03/2020040416343501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":3,
      "vividness":11,
      "r":17,
      "g":9,
      "b":50,
      "hex":"#110932",
      "file_location":"assets/colors_organized/hue_22/brightness_03/2020040416343600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":3,
      "vividness":12,
      "r":14,
      "g":7,
      "b":49,
      "hex":"#0e0731",
      "file_location":"assets/colors_organized/hue_22/brightness_03/2020040416343601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":3,
      "vividness":13,
      "r":12,
      "g":3,
      "b":50,
      "hex":"#0c0332",
      "file_location":"assets/colors_organized/hue_22/brightness_03/2020040416343700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":4,
      "vividness":1,
      "r":60,
      "g":59,
      "b":64,
      "hex":"#3c3b40",
      "file_location":"assets/colors_organized/hue_22/brightness_04/2020040416341900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":4,
      "vividness":2,
      "r":57,
      "g":56,
      "b":64,
      "hex":"#393840",
      "file_location":"assets/colors_organized/hue_22/brightness_04/2020040416341901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":4,
      "vividness":3,
      "r":54,
      "g":52,
      "b":65,
      "hex":"#363441",
      "file_location":"assets/colors_organized/hue_22/brightness_04/2020040416342000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":4,
      "vividness":4,
      "r":50,
      "g":47,
      "b":64,
      "hex":"#322f40",
      "file_location":"assets/colors_organized/hue_22/brightness_04/2020040416342001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":4,
      "vividness":5,
      "r":46,
      "g":43,
      "b":64,
      "hex":"#2e2b40",
      "file_location":"assets/colors_organized/hue_22/brightness_04/2020040416342100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":4,
      "vividness":6,
      "r":43,
      "g":39,
      "b":64,
      "hex":"#2b2740",
      "file_location":"assets/colors_organized/hue_22/brightness_04/2020040416342101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":4,
      "vividness":7,
      "r":39,
      "g":34,
      "b":66,
      "hex":"#272242",
      "file_location":"assets/colors_organized/hue_22/brightness_04/2020040416342200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":4,
      "vividness":8,
      "r":37,
      "g":30,
      "b":64,
      "hex":"#251e40",
      "file_location":"assets/colors_organized/hue_22/brightness_04/2020040416342201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":4,
      "vividness":9,
      "r":33,
      "g":27,
      "b":65,
      "hex":"#211b41",
      "file_location":"assets/colors_organized/hue_22/brightness_04/2020040416342300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":4,
      "vividness":10,
      "r":29,
      "g":22,
      "b":64,
      "hex":"#1d1640",
      "file_location":"assets/colors_organized/hue_22/brightness_04/2020040416342301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":4,
      "vividness":11,
      "r":27,
      "g":18,
      "b":65,
      "hex":"#1b1241",
      "file_location":"assets/colors_organized/hue_22/brightness_04/2020040416342400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":4,
      "vividness":12,
      "r":22,
      "g":12,
      "b":64,
      "hex":"#160c40",
      "file_location":"assets/colors_organized/hue_22/brightness_04/2020040416342401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":4,
      "vividness":13,
      "r":20,
      "g":6,
      "b":65,
      "hex":"#140641",
      "file_location":"assets/colors_organized/hue_22/brightness_04/2020040416342500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":4,
      "vividness":14,
      "r":14,
      "g":2,
      "b":64,
      "hex":"#0e0240",
      "file_location":"assets/colors_organized/hue_22/brightness_04/2020040416342501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":5,
      "vividness":1,
      "r":75,
      "g":74,
      "b":80,
      "hex":"#4b4a50",
      "file_location":"assets/colors_organized/hue_22/brightness_05/2020040416340800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":5,
      "vividness":2,
      "r":71,
      "g":69,
      "b":80,
      "hex":"#474550",
      "file_location":"assets/colors_organized/hue_22/brightness_05/2020040416340801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":5,
      "vividness":3,
      "r":67,
      "g":65,
      "b":79,
      "hex":"#43414f",
      "file_location":"assets/colors_organized/hue_22/brightness_05/2020040416340900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":5,
      "vividness":4,
      "r":63,
      "g":58,
      "b":80,
      "hex":"#3f3a50",
      "file_location":"assets/colors_organized/hue_22/brightness_05/2020040416341000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":5,
      "vividness":5,
      "r":57,
      "g":53,
      "b":80,
      "hex":"#393550",
      "file_location":"assets/colors_organized/hue_22/brightness_05/2020040416341001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":5,
      "vividness":6,
      "r":53,
      "g":48,
      "b":80,
      "hex":"#353050",
      "file_location":"assets/colors_organized/hue_22/brightness_05/2020040416341100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":5,
      "vividness":7,
      "r":49,
      "g":41,
      "b":80,
      "hex":"#312950",
      "file_location":"assets/colors_organized/hue_22/brightness_05/2020040416341101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":5,
      "vividness":8,
      "r":44,
      "g":37,
      "b":79,
      "hex":"#2c254f",
      "file_location":"assets/colors_organized/hue_22/brightness_05/2020040416341200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":5,
      "vividness":9,
      "r":40,
      "g":32,
      "b":81,
      "hex":"#282051",
      "file_location":"assets/colors_organized/hue_22/brightness_05/2020040416341201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":5,
      "vividness":10,
      "r":37,
      "g":27,
      "b":80,
      "hex":"#251b50",
      "file_location":"assets/colors_organized/hue_22/brightness_05/2020040416341300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":5,
      "vividness":11,
      "r":33,
      "g":22,
      "b":80,
      "hex":"#211650",
      "file_location":"assets/colors_organized/hue_22/brightness_05/2020040416341301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":5,
      "vividness":12,
      "r":27,
      "g":15,
      "b":79,
      "hex":"#1b0f4f",
      "file_location":"assets/colors_organized/hue_22/brightness_05/2020040416341400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":5,
      "vividness":13,
      "r":22,
      "g":9,
      "b":79,
      "hex":"#16094f",
      "file_location":"assets/colors_organized/hue_22/brightness_05/2020040416341401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":5,
      "vividness":14,
      "r":19,
      "g":7,
      "b":79,
      "hex":"#13074f",
      "file_location":"assets/colors_organized/hue_22/brightness_05/2020040416341500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":6,
      "vividness":1,
      "r":89,
      "g":88,
      "b":94,
      "hex":"#59585e",
      "file_location":"assets/colors_organized/hue_22/brightness_06/2020040416335701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":6,
      "vividness":2,
      "r":84,
      "g":82,
      "b":95,
      "hex":"#54525f",
      "file_location":"assets/colors_organized/hue_22/brightness_06/2020040416335800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":6,
      "vividness":3,
      "r":79,
      "g":76,
      "b":95,
      "hex":"#4f4c5f",
      "file_location":"assets/colors_organized/hue_22/brightness_06/2020040416335801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":6,
      "vividness":4,
      "r":73,
      "g":69,
      "b":94,
      "hex":"#49455e",
      "file_location":"assets/colors_organized/hue_22/brightness_06/2020040416335900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":6,
      "vividness":5,
      "r":68,
      "g":63,
      "b":95,
      "hex":"#443f5f",
      "file_location":"assets/colors_organized/hue_22/brightness_06/2020040416335901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":6,
      "vividness":6,
      "r":63,
      "g":57,
      "b":95,
      "hex":"#3f395f",
      "file_location":"assets/colors_organized/hue_22/brightness_06/2020040416340000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":6,
      "vividness":7,
      "r":58,
      "g":49,
      "b":94,
      "hex":"#3a315e",
      "file_location":"assets/colors_organized/hue_22/brightness_06/2020040416340001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":6,
      "vividness":8,
      "r":53,
      "g":43,
      "b":95,
      "hex":"#352b5f",
      "file_location":"assets/colors_organized/hue_22/brightness_06/2020040416340100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":6,
      "vividness":9,
      "r":48,
      "g":37,
      "b":95,
      "hex":"#30255f",
      "file_location":"assets/colors_organized/hue_22/brightness_06/2020040416340101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":6,
      "vividness":10,
      "r":43,
      "g":31,
      "b":95,
      "hex":"#2b1f5f",
      "file_location":"assets/colors_organized/hue_22/brightness_06/2020040416340200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":6,
      "vividness":11,
      "r":38,
      "g":25,
      "b":95,
      "hex":"#26195f",
      "file_location":"assets/colors_organized/hue_22/brightness_06/2020040416340201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":6,
      "vividness":12,
      "r":33,
      "g":19,
      "b":94,
      "hex":"#21135e",
      "file_location":"assets/colors_organized/hue_22/brightness_06/2020040416340300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":6,
      "vividness":13,
      "r":30,
      "g":12,
      "b":94,
      "hex":"#1e0c5e",
      "file_location":"assets/colors_organized/hue_22/brightness_06/2020040416340301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":6,
      "vividness":14,
      "r":22,
      "g":6,
      "b":94,
      "hex":"#16065e",
      "file_location":"assets/colors_organized/hue_22/brightness_06/2020040416340400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":7,
      "vividness":1,
      "r":103,
      "g":102,
      "b":110,
      "hex":"#67666e",
      "file_location":"assets/colors_organized/hue_22/brightness_07/2020040416334700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":7,
      "vividness":2,
      "r":97,
      "g":95,
      "b":109,
      "hex":"#615f6d",
      "file_location":"assets/colors_organized/hue_22/brightness_07/2020040416334701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":7,
      "vividness":3,
      "r":92,
      "g":89,
      "b":110,
      "hex":"#5c596e",
      "file_location":"assets/colors_organized/hue_22/brightness_07/2020040416334800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":7,
      "vividness":4,
      "r":85,
      "g":80,
      "b":110,
      "hex":"#55506e",
      "file_location":"assets/colors_organized/hue_22/brightness_07/2020040416334801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":7,
      "vividness":5,
      "r":79,
      "g":73,
      "b":109,
      "hex":"#4f496d",
      "file_location":"assets/colors_organized/hue_22/brightness_07/2020040416334900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":7,
      "vividness":6,
      "r":73,
      "g":66,
      "b":110,
      "hex":"#49426e",
      "file_location":"assets/colors_organized/hue_22/brightness_07/2020040416334901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":7,
      "vividness":7,
      "r":68,
      "g":58,
      "b":110,
      "hex":"#443a6e",
      "file_location":"assets/colors_organized/hue_22/brightness_07/2020040416335000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":7,
      "vividness":8,
      "r":62,
      "g":51,
      "b":111,
      "hex":"#3e336f",
      "file_location":"assets/colors_organized/hue_22/brightness_07/2020040416335001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":7,
      "vividness":9,
      "r":56,
      "g":44,
      "b":110,
      "hex":"#382c6e",
      "file_location":"assets/colors_organized/hue_22/brightness_07/2020040416335100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":7,
      "vividness":10,
      "r":49,
      "g":36,
      "b":108,
      "hex":"#31246c",
      "file_location":"assets/colors_organized/hue_22/brightness_07/2020040416335101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":7,
      "vividness":11,
      "r":44,
      "g":29,
      "b":110,
      "hex":"#2c1d6e",
      "file_location":"assets/colors_organized/hue_22/brightness_07/2020040416335200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":7,
      "vividness":12,
      "r":38,
      "g":22,
      "b":110,
      "hex":"#26166e",
      "file_location":"assets/colors_organized/hue_22/brightness_07/2020040416335201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":7,
      "vividness":13,
      "r":33,
      "g":13,
      "b":110,
      "hex":"#210d6e",
      "file_location":"assets/colors_organized/hue_22/brightness_07/2020040416335300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":7,
      "vividness":14,
      "r":25,
      "g":7,
      "b":109,
      "hex":"#19076d",
      "file_location":"assets/colors_organized/hue_22/brightness_07/2020040416335301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":8,
      "vividness":1,
      "r":117,
      "g":116,
      "b":124,
      "hex":"#75747c",
      "file_location":"assets/colors_organized/hue_22/brightness_08/02.jpg"
   },
   {
      "hue":22,
      "brightness":8,
      "vividness":2,
      "r":111,
      "g":109,
      "b":123,
      "hex":"#6f6d7b",
      "file_location":"assets/colors_organized/hue_22/brightness_08/03.jpg"
   },
   {
      "hue":22,
      "brightness":8,
      "vividness":3,
      "r":103,
      "g":99,
      "b":124,
      "hex":"#67637c",
      "file_location":"assets/colors_organized/hue_22/brightness_08/04.jpg"
   },
   {
      "hue":22,
      "brightness":8,
      "vividness":4,
      "r":98,
      "g":91,
      "b":124,
      "hex":"#625b7c",
      "file_location":"assets/colors_organized/hue_22/brightness_08/05.jpg"
   },
   {
      "hue":22,
      "brightness":8,
      "vividness":5,
      "r":91,
      "g":83,
      "b":124,
      "hex":"#5b537c",
      "file_location":"assets/colors_organized/hue_22/brightness_08/2020040416314301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":8,
      "vividness":6,
      "r":84,
      "g":74,
      "b":124,
      "hex":"#544a7c",
      "file_location":"assets/colors_organized/hue_22/brightness_08/2020040416333900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":8,
      "vividness":7,
      "r":77,
      "g":66,
      "b":124,
      "hex":"#4d427c",
      "file_location":"assets/colors_organized/hue_22/brightness_08/2020040416333901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":8,
      "vividness":8,
      "r":70,
      "g":58,
      "b":124,
      "hex":"#463a7c",
      "file_location":"assets/colors_organized/hue_22/brightness_08/2020040416334000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":8,
      "vividness":9,
      "r":63,
      "g":49,
      "b":124,
      "hex":"#3f317c",
      "file_location":"assets/colors_organized/hue_22/brightness_08/2020040416334001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":8,
      "vividness":10,
      "r":58,
      "g":40,
      "b":124,
      "hex":"#3a287c",
      "file_location":"assets/colors_organized/hue_22/brightness_08/2020040416334100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":8,
      "vividness":11,
      "r":50,
      "g":34,
      "b":123,
      "hex":"#32227b",
      "file_location":"assets/colors_organized/hue_22/brightness_08/2020040416334101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":8,
      "vividness":12,
      "r":43,
      "g":22,
      "b":123,
      "hex":"#2b167b",
      "file_location":"assets/colors_organized/hue_22/brightness_08/2020040416334200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":8,
      "vividness":13,
      "r":38,
      "g":14,
      "b":124,
      "hex":"#260e7c",
      "file_location":"assets/colors_organized/hue_22/brightness_08/2020040416334201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":8,
      "vividness":14,
      "r":30,
      "g":7,
      "b":124,
      "hex":"#1e077c",
      "file_location":"assets/colors_organized/hue_22/brightness_08/2020040416334300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":9,
      "vividness":1,
      "r":132,
      "g":131,
      "b":139,
      "hex":"#84838b",
      "file_location":"assets/colors_organized/hue_22/brightness_09/2020040416313001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":9,
      "vividness":2,
      "r":124,
      "g":121,
      "b":140,
      "hex":"#7c798c",
      "file_location":"assets/colors_organized/hue_22/brightness_09/2020040416313100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":9,
      "vividness":3,
      "r":117,
      "g":111,
      "b":139,
      "hex":"#756f8b",
      "file_location":"assets/colors_organized/hue_22/brightness_09/2020040416313101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":9,
      "vividness":4,
      "r":109,
      "g":101,
      "b":138,
      "hex":"#6d658a",
      "file_location":"assets/colors_organized/hue_22/brightness_09/2020040416313200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":9,
      "vividness":5,
      "r":102,
      "g":93,
      "b":140,
      "hex":"#665d8c",
      "file_location":"assets/colors_organized/hue_22/brightness_09/2020040416313201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":9,
      "vividness":6,
      "r":94,
      "g":83,
      "b":139,
      "hex":"#5e538b",
      "file_location":"assets/colors_organized/hue_22/brightness_09/2020040416313300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":9,
      "vividness":7,
      "r":86,
      "g":74,
      "b":138,
      "hex":"#564a8a",
      "file_location":"assets/colors_organized/hue_22/brightness_09/2020040416313301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":9,
      "vividness":8,
      "r":79,
      "g":66,
      "b":138,
      "hex":"#4f428a",
      "file_location":"assets/colors_organized/hue_22/brightness_09/2020040416313400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":9,
      "vividness":9,
      "r":72,
      "g":54,
      "b":138,
      "hex":"#48368a",
      "file_location":"assets/colors_organized/hue_22/brightness_09/2020040416313401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":9,
      "vividness":10,
      "r":65,
      "g":46,
      "b":138,
      "hex":"#412e8a",
      "file_location":"assets/colors_organized/hue_22/brightness_09/2020040416313500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":9,
      "vividness":11,
      "r":57,
      "g":36,
      "b":139,
      "hex":"#39248b",
      "file_location":"assets/colors_organized/hue_22/brightness_09/2020040416313501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":9,
      "vividness":12,
      "r":49,
      "g":27,
      "b":138,
      "hex":"#311b8a",
      "file_location":"assets/colors_organized/hue_22/brightness_09/2020040416313600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":9,
      "vividness":13,
      "r":42,
      "g":18,
      "b":140,
      "hex":"#2a128c",
      "file_location":"assets/colors_organized/hue_22/brightness_09/2020040416313601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":9,
      "vividness":14,
      "r":35,
      "g":10,
      "b":138,
      "hex":"#230a8a",
      "file_location":"assets/colors_organized/hue_22/brightness_09/2020040416313700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":10,
      "vividness":1,
      "r":146,
      "g":144,
      "b":155,
      "hex":"#92909b",
      "file_location":"assets/colors_organized/hue_22/brightness_10/2020040416311900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":10,
      "vividness":2,
      "r":138,
      "g":135,
      "b":154,
      "hex":"#8a879a",
      "file_location":"assets/colors_organized/hue_22/brightness_10/2020040416311901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":10,
      "vividness":3,
      "r":130,
      "g":123,
      "b":154,
      "hex":"#827b9a",
      "file_location":"assets/colors_organized/hue_22/brightness_10/2020040416312000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":10,
      "vividness":4,
      "r":121,
      "g":113,
      "b":154,
      "hex":"#79719a",
      "file_location":"assets/colors_organized/hue_22/brightness_10/2020040416312001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":10,
      "vividness":5,
      "r":113,
      "g":103,
      "b":155,
      "hex":"#71679b",
      "file_location":"assets/colors_organized/hue_22/brightness_10/2020040416312100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":10,
      "vividness":6,
      "r":104,
      "g":92,
      "b":154,
      "hex":"#685c9a",
      "file_location":"assets/colors_organized/hue_22/brightness_10/2020040416312101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":10,
      "vividness":7,
      "r":98,
      "g":82,
      "b":153,
      "hex":"#625299",
      "file_location":"assets/colors_organized/hue_22/brightness_10/2020040416312200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":10,
      "vividness":8,
      "r":87,
      "g":72,
      "b":153,
      "hex":"#574899",
      "file_location":"assets/colors_organized/hue_22/brightness_10/2020040416312201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":10,
      "vividness":9,
      "r":80,
      "g":61,
      "b":153,
      "hex":"#503d99",
      "file_location":"assets/colors_organized/hue_22/brightness_10/2020040416312300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":10,
      "vividness":10,
      "r":72,
      "g":51,
      "b":154,
      "hex":"#48339a",
      "file_location":"assets/colors_organized/hue_22/brightness_10/2020040416312301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":10,
      "vividness":11,
      "r":63,
      "g":40,
      "b":154,
      "hex":"#3f289a",
      "file_location":"assets/colors_organized/hue_22/brightness_10/2020040416312400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":10,
      "vividness":12,
      "r":54,
      "g":30,
      "b":154,
      "hex":"#361e9a",
      "file_location":"assets/colors_organized/hue_22/brightness_10/2020040416312401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":10,
      "vividness":13,
      "r":46,
      "g":20,
      "b":155,
      "hex":"#2e149b",
      "file_location":"assets/colors_organized/hue_22/brightness_10/2020040416312500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":10,
      "vividness":14,
      "r":39,
      "g":10,
      "b":154,
      "hex":"#270a9a",
      "file_location":"assets/colors_organized/hue_22/brightness_10/2020040416312600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":11,
      "vividness":1,
      "r":160,
      "g":158,
      "b":169,
      "hex":"#a09ea9",
      "file_location":"assets/colors_organized/hue_22/brightness_11/2020040416310900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":11,
      "vividness":2,
      "r":151,
      "g":148,
      "b":169,
      "hex":"#9794a9",
      "file_location":"assets/colors_organized/hue_22/brightness_11/2020040416310901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":11,
      "vividness":3,
      "r":142,
      "g":135,
      "b":169,
      "hex":"#8e87a9",
      "file_location":"assets/colors_organized/hue_22/brightness_11/2020040416311000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":11,
      "vividness":4,
      "r":133,
      "g":124,
      "b":169,
      "hex":"#857ca9",
      "file_location":"assets/colors_organized/hue_22/brightness_11/2020040416311001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":11,
      "vividness":5,
      "r":124,
      "g":113,
      "b":169,
      "hex":"#7c71a9",
      "file_location":"assets/colors_organized/hue_22/brightness_11/2020040416311002-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":11,
      "vividness":6,
      "r":114,
      "g":101,
      "b":170,
      "hex":"#7265aa",
      "file_location":"assets/colors_organized/hue_22/brightness_11/2020040416311100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":11,
      "vividness":7,
      "r":105,
      "g":91,
      "b":170,
      "hex":"#695baa",
      "file_location":"assets/colors_organized/hue_22/brightness_11/2020040416311101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":11,
      "vividness":8,
      "r":98,
      "g":79,
      "b":169,
      "hex":"#624fa9",
      "file_location":"assets/colors_organized/hue_22/brightness_11/2020040416311200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":11,
      "vividness":9,
      "r":88,
      "g":67,
      "b":170,
      "hex":"#5843aa",
      "file_location":"assets/colors_organized/hue_22/brightness_11/2020040416311201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":11,
      "vividness":10,
      "r":78,
      "g":55,
      "b":169,
      "hex":"#4e37a9",
      "file_location":"assets/colors_organized/hue_22/brightness_11/2020040416311300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":11,
      "vividness":11,
      "r":69,
      "g":45,
      "b":169,
      "hex":"#452da9",
      "file_location":"assets/colors_organized/hue_22/brightness_11/2020040416311301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":11,
      "vividness":12,
      "r":60,
      "g":34,
      "b":169,
      "hex":"#3c22a9",
      "file_location":"assets/colors_organized/hue_22/brightness_11/2020040416311400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":11,
      "vividness":13,
      "r":51,
      "g":21,
      "b":169,
      "hex":"#3315a9",
      "file_location":"assets/colors_organized/hue_22/brightness_11/2020040416311401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":11,
      "vividness":14,
      "r":42,
      "g":10,
      "b":169,
      "hex":"#2a0aa9",
      "file_location":"assets/colors_organized/hue_22/brightness_11/2020040416311500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":12,
      "vividness":1,
      "r":174,
      "g":172,
      "b":185,
      "hex":"#aeacb9",
      "file_location":"assets/colors_organized/hue_22/brightness_12/2020040416305900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":12,
      "vividness":2,
      "r":164,
      "g":160,
      "b":183,
      "hex":"#a4a0b7",
      "file_location":"assets/colors_organized/hue_22/brightness_12/2020040416305901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":12,
      "vividness":3,
      "r":155,
      "g":147,
      "b":184,
      "hex":"#9b93b8",
      "file_location":"assets/colors_organized/hue_22/brightness_12/2020040416310000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":12,
      "vividness":4,
      "r":145,
      "g":135,
      "b":185,
      "hex":"#9187b9",
      "file_location":"assets/colors_organized/hue_22/brightness_12/2020040416310001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":12,
      "vividness":5,
      "r":135,
      "g":124,
      "b":184,
      "hex":"#877cb8",
      "file_location":"assets/colors_organized/hue_22/brightness_12/2020040416310100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":12,
      "vividness":6,
      "r":124,
      "g":110,
      "b":185,
      "hex":"#7c6eb9",
      "file_location":"assets/colors_organized/hue_22/brightness_12/2020040416310101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":12,
      "vividness":7,
      "r":114,
      "g":99,
      "b":184,
      "hex":"#7263b8",
      "file_location":"assets/colors_organized/hue_22/brightness_12/2020040416310200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":12,
      "vividness":8,
      "r":104,
      "g":86,
      "b":185,
      "hex":"#6856b9",
      "file_location":"assets/colors_organized/hue_22/brightness_12/2020040416310201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":12,
      "vividness":9,
      "r":95,
      "g":73,
      "b":184,
      "hex":"#5f49b8",
      "file_location":"assets/colors_organized/hue_22/brightness_12/2020040416310300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":12,
      "vividness":10,
      "r":85,
      "g":61,
      "b":183,
      "hex":"#553db7",
      "file_location":"assets/colors_organized/hue_22/brightness_12/2020040416310301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":12,
      "vividness":11,
      "r":75,
      "g":49,
      "b":184,
      "hex":"#4b31b8",
      "file_location":"assets/colors_organized/hue_22/brightness_12/2020040416310302-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":12,
      "vividness":12,
      "r":66,
      "g":36,
      "b":184,
      "hex":"#4224b8",
      "file_location":"assets/colors_organized/hue_22/brightness_12/2020040416310400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":12,
      "vividness":13,
      "r":55,
      "g":23,
      "b":184,
      "hex":"#3717b8",
      "file_location":"assets/colors_organized/hue_22/brightness_12/2020040416310401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":12,
      "vividness":14,
      "r":45,
      "g":13,
      "b":184,
      "hex":"#2d0db8",
      "file_location":"assets/colors_organized/hue_22/brightness_12/2020040416310500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":13,
      "vividness":1,
      "r":188,
      "g":186,
      "b":199,
      "hex":"#bcbac7",
      "file_location":"assets/colors_organized/hue_22/brightness_13/2020040416304900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":13,
      "vividness":2,
      "r":178,
      "g":174,
      "b":199,
      "hex":"#b2aec7",
      "file_location":"assets/colors_organized/hue_22/brightness_13/2020040416304901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":13,
      "vividness":3,
      "r":167,
      "g":159,
      "b":200,
      "hex":"#a79fc8",
      "file_location":"assets/colors_organized/hue_22/brightness_13/2020040416305000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":13,
      "vividness":4,
      "r":157,
      "g":147,
      "b":200,
      "hex":"#9d93c8",
      "file_location":"assets/colors_organized/hue_22/brightness_13/2020040416305001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":13,
      "vividness":5,
      "r":146,
      "g":134,
      "b":200,
      "hex":"#9286c8",
      "file_location":"assets/colors_organized/hue_22/brightness_13/2020040416305100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":13,
      "vividness":6,
      "r":134,
      "g":120,
      "b":199,
      "hex":"#8678c7",
      "file_location":"assets/colors_organized/hue_22/brightness_13/2020040416305101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":13,
      "vividness":7,
      "r":125,
      "g":106,
      "b":198,
      "hex":"#7d6ac6",
      "file_location":"assets/colors_organized/hue_22/brightness_13/2020040416305200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":13,
      "vividness":8,
      "r":114,
      "g":92,
      "b":200,
      "hex":"#725cc8",
      "file_location":"assets/colors_organized/hue_22/brightness_13/2020040416305201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":13,
      "vividness":9,
      "r":103,
      "g":79,
      "b":199,
      "hex":"#674fc7",
      "file_location":"assets/colors_organized/hue_22/brightness_13/2020040416305300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":13,
      "vividness":10,
      "r":93,
      "g":66,
      "b":199,
      "hex":"#5d42c7",
      "file_location":"assets/colors_organized/hue_22/brightness_13/2020040416305301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":13,
      "vividness":11,
      "r":82,
      "g":52,
      "b":198,
      "hex":"#5234c6",
      "file_location":"assets/colors_organized/hue_22/brightness_13/2020040416305302-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":13,
      "vividness":12,
      "r":71,
      "g":39,
      "b":200,
      "hex":"#4727c8",
      "file_location":"assets/colors_organized/hue_22/brightness_13/2020040416305400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":13,
      "vividness":13,
      "r":60,
      "g":26,
      "b":200,
      "hex":"#3c1ac8",
      "file_location":"assets/colors_organized/hue_22/brightness_13/2020040416305401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":13,
      "vividness":14,
      "r":49,
      "g":13,
      "b":199,
      "hex":"#310dc7",
      "file_location":"assets/colors_organized/hue_22/brightness_13/2020040416305500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":14,
      "vividness":1,
      "r":203,
      "g":201,
      "b":214,
      "hex":"#cbc9d6",
      "file_location":"assets/colors_organized/hue_22/brightness_14/2020040416303900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":14,
      "vividness":2,
      "r":190,
      "g":185,
      "b":215,
      "hex":"#beb9d7",
      "file_location":"assets/colors_organized/hue_22/brightness_14/2020040416303901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":14,
      "vividness":3,
      "r":180,
      "g":171,
      "b":214,
      "hex":"#b4abd6",
      "file_location":"assets/colors_organized/hue_22/brightness_14/2020040416304000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":14,
      "vividness":4,
      "r":168,
      "g":157,
      "b":215,
      "hex":"#a89dd7",
      "file_location":"assets/colors_organized/hue_22/brightness_14/2020040416304001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":14,
      "vividness":5,
      "r":156,
      "g":143,
      "b":213,
      "hex":"#9c8fd5",
      "file_location":"assets/colors_organized/hue_22/brightness_14/2020040416304100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":14,
      "vividness":6,
      "r":144,
      "g":129,
      "b":214,
      "hex":"#9081d6",
      "file_location":"assets/colors_organized/hue_22/brightness_14/2020040416304101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":14,
      "vividness":7,
      "r":134,
      "g":113,
      "b":214,
      "hex":"#8671d6",
      "file_location":"assets/colors_organized/hue_22/brightness_14/2020040416304200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":14,
      "vividness":8,
      "r":123,
      "g":100,
      "b":214,
      "hex":"#7b64d6",
      "file_location":"assets/colors_organized/hue_22/brightness_14/2020040416304201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":14,
      "vividness":9,
      "r":110,
      "g":85,
      "b":213,
      "hex":"#6e55d5",
      "file_location":"assets/colors_organized/hue_22/brightness_14/2020040416304202-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":14,
      "vividness":10,
      "r":100,
      "g":71,
      "b":215,
      "hex":"#6447d7",
      "file_location":"assets/colors_organized/hue_22/brightness_14/2020040416304300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":14,
      "vividness":11,
      "r":88,
      "g":56,
      "b":215,
      "hex":"#5838d7",
      "file_location":"assets/colors_organized/hue_22/brightness_14/2020040416304301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":14,
      "vividness":12,
      "r":76,
      "g":42,
      "b":214,
      "hex":"#4c2ad6",
      "file_location":"assets/colors_organized/hue_22/brightness_14/2020040416304400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":14,
      "vividness":13,
      "r":64,
      "g":28,
      "b":214,
      "hex":"#401cd6",
      "file_location":"assets/colors_organized/hue_22/brightness_14/2020040416304401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":14,
      "vividness":14,
      "r":54,
      "g":13,
      "b":215,
      "hex":"#360dd7",
      "file_location":"assets/colors_organized/hue_22/brightness_14/2020040416304500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":15,
      "vividness":1,
      "r":217,
      "g":215,
      "b":229,
      "hex":"#d9d7e5",
      "file_location":"assets/colors_organized/hue_22/brightness_15/2020040416302900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":15,
      "vividness":2,
      "r":205,
      "g":198,
      "b":229,
      "hex":"#cdc6e5",
      "file_location":"assets/colors_organized/hue_22/brightness_15/2020040416302901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":15,
      "vividness":3,
      "r":192,
      "g":183,
      "b":228,
      "hex":"#c0b7e4",
      "file_location":"assets/colors_organized/hue_22/brightness_15/2020040416303000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":15,
      "vividness":4,
      "r":180,
      "g":168,
      "b":230,
      "hex":"#b4a8e6",
      "file_location":"assets/colors_organized/hue_22/brightness_15/2020040416303001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":15,
      "vividness":5,
      "r":169,
      "g":152,
      "b":230,
      "hex":"#a998e6",
      "file_location":"assets/colors_organized/hue_22/brightness_15/2020040416303100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":15,
      "vividness":6,
      "r":156,
      "g":137,
      "b":229,
      "hex":"#9c89e5",
      "file_location":"assets/colors_organized/hue_22/brightness_15/2020040416303101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":15,
      "vividness":7,
      "r":143,
      "g":121,
      "b":229,
      "hex":"#8f79e5",
      "file_location":"assets/colors_organized/hue_22/brightness_15/2020040416303200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":15,
      "vividness":8,
      "r":131,
      "g":107,
      "b":229,
      "hex":"#836be5",
      "file_location":"assets/colors_organized/hue_22/brightness_15/2020040416303201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":15,
      "vividness":9,
      "r":119,
      "g":91,
      "b":228,
      "hex":"#775be4",
      "file_location":"assets/colors_organized/hue_22/brightness_15/2020040416303202-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":15,
      "vividness":10,
      "r":107,
      "g":76,
      "b":229,
      "hex":"#6b4ce5",
      "file_location":"assets/colors_organized/hue_22/brightness_15/2020040416303300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":15,
      "vividness":11,
      "r":94,
      "g":61,
      "b":230,
      "hex":"#5e3de6",
      "file_location":"assets/colors_organized/hue_22/brightness_15/2020040416303301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":15,
      "vividness":12,
      "r":81,
      "g":45,
      "b":229,
      "hex":"#512de5",
      "file_location":"assets/colors_organized/hue_22/brightness_15/2020040416303400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":15,
      "vividness":13,
      "r":70,
      "g":29,
      "b":229,
      "hex":"#461de5",
      "file_location":"assets/colors_organized/hue_22/brightness_15/2020040416303401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":22,
      "brightness":15,
      "vividness":14,
      "r":58,
      "g":15,
      "b":229,
      "hex":"#3a0fe5",
      "file_location":"assets/colors_organized/hue_22/brightness_15/2020040416303500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":1,
      "vividness":1,
      "r":18,
      "g":13,
      "b":20,
      "hex":"#120d14",
      "file_location":"assets/colors_organized/hue_23/brightness_01/2020040416374501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":1,
      "vividness":2,
      "r":13,
      "g":7,
      "b":19,
      "hex":"#0d0713",
      "file_location":"assets/colors_organized/hue_23/brightness_01/2020040416374800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":1,
      "vividness":3,
      "r":10,
      "g":3,
      "b":19,
      "hex":"#0a0313",
      "file_location":"assets/colors_organized/hue_23/brightness_01/2020040416374900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":2,
      "vividness":1,
      "r":31,
      "g":30,
      "b":35,
      "hex":"#1f1e23",
      "file_location":"assets/colors_organized/hue_23/brightness_02/2020040416373401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":2,
      "vividness":2,
      "r":30,
      "g":27,
      "b":34,
      "hex":"#1e1b22",
      "file_location":"assets/colors_organized/hue_23/brightness_02/2020040416373500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":2,
      "vividness":3,
      "r":30,
      "g":24,
      "b":34,
      "hex":"#1e1822",
      "file_location":"assets/colors_organized/hue_23/brightness_02/2020040416373501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":2,
      "vividness":4,
      "r":26,
      "g":23,
      "b":34,
      "hex":"#1a1722",
      "file_location":"assets/colors_organized/hue_23/brightness_02/2020040416373502-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":2,
      "vividness":5,
      "r":25,
      "g":21,
      "b":35,
      "hex":"#191523",
      "file_location":"assets/colors_organized/hue_23/brightness_02/2020040416373600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":2,
      "vividness":6,
      "r":25,
      "g":18,
      "b":34,
      "hex":"#191222",
      "file_location":"assets/colors_organized/hue_23/brightness_02/2020040416373601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":2,
      "vividness":7,
      "r":22,
      "g":14,
      "b":35,
      "hex":"#160e23",
      "file_location":"assets/colors_organized/hue_23/brightness_02/2020040416373700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":2,
      "vividness":8,
      "r":22,
      "g":13,
      "b":34,
      "hex":"#160d22",
      "file_location":"assets/colors_organized/hue_23/brightness_02/2020040416373701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":2,
      "vividness":9,
      "r":22,
      "g":10,
      "b":34,
      "hex":"#160a22",
      "file_location":"assets/colors_organized/hue_23/brightness_02/2020040416373800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":2,
      "vividness":10,
      "r":19,
      "g":9,
      "b":34,
      "hex":"#130922",
      "file_location":"assets/colors_organized/hue_23/brightness_02/2020040416373801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":2,
      "vividness":11,
      "r":17,
      "g":7,
      "b":34,
      "hex":"#110722",
      "file_location":"assets/colors_organized/hue_23/brightness_02/2020040416373900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":2,
      "vividness":12,
      "r":14,
      "g":3,
      "b":35,
      "hex":"#0e0323",
      "file_location":"assets/colors_organized/hue_23/brightness_02/2020040416373901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":3,
      "vividness":1,
      "r":48,
      "g":46,
      "b":51,
      "hex":"#302e33",
      "file_location":"assets/colors_organized/hue_23/brightness_03/2020040416372400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":3,
      "vividness":2,
      "r":45,
      "g":42,
      "b":49,
      "hex":"#2d2a31",
      "file_location":"assets/colors_organized/hue_23/brightness_03/2020040416372401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":3,
      "vividness":3,
      "r":43,
      "g":40,
      "b":49,
      "hex":"#2b2831",
      "file_location":"assets/colors_organized/hue_23/brightness_03/2020040416372500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":3,
      "vividness":4,
      "r":42,
      "g":36,
      "b":50,
      "hex":"#2a2432",
      "file_location":"assets/colors_organized/hue_23/brightness_03/2020040416372501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":3,
      "vividness":5,
      "r":40,
      "g":33,
      "b":49,
      "hex":"#282131",
      "file_location":"assets/colors_organized/hue_23/brightness_03/2020040416372600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":3,
      "vividness":6,
      "r":37,
      "g":29,
      "b":50,
      "hex":"#251d32",
      "file_location":"assets/colors_organized/hue_23/brightness_03/2020040416372601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":3,
      "vividness":7,
      "r":36,
      "g":26,
      "b":50,
      "hex":"#241a32",
      "file_location":"assets/colors_organized/hue_23/brightness_03/2020040416372700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":3,
      "vividness":8,
      "r":33,
      "g":23,
      "b":50,
      "hex":"#211732",
      "file_location":"assets/colors_organized/hue_23/brightness_03/2020040416372701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":3,
      "vividness":9,
      "r":31,
      "g":20,
      "b":50,
      "hex":"#1f1432",
      "file_location":"assets/colors_organized/hue_23/brightness_03/2020040416372800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":3,
      "vividness":10,
      "r":30,
      "g":14,
      "b":50,
      "hex":"#1e0e32",
      "file_location":"assets/colors_organized/hue_23/brightness_03/2020040416372801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":3,
      "vividness":11,
      "r":27,
      "g":13,
      "b":49,
      "hex":"#1b0d31",
      "file_location":"assets/colors_organized/hue_23/brightness_03/2020040416372900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":3,
      "vividness":12,
      "r":26,
      "g":10,
      "b":49,
      "hex":"#1a0a31",
      "file_location":"assets/colors_organized/hue_23/brightness_03/2020040416372901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":3,
      "vividness":13,
      "r":22,
      "g":7,
      "b":50,
      "hex":"#160732",
      "file_location":"assets/colors_organized/hue_23/brightness_03/2020040416372902-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":3,
      "vividness":14,
      "r":22,
      "g":3,
      "b":49,
      "hex":"#160331",
      "file_location":"assets/colors_organized/hue_23/brightness_03/2020040416373000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":4,
      "vividness":1,
      "r":61,
      "g":60,
      "b":65,
      "hex":"#3d3c41",
      "file_location":"assets/colors_organized/hue_23/brightness_04/2020040416371400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":4,
      "vividness":2,
      "r":59,
      "g":56,
      "b":65,
      "hex":"#3b3841",
      "file_location":"assets/colors_organized/hue_23/brightness_04/2020040416371401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":4,
      "vividness":3,
      "r":56,
      "g":52,
      "b":66,
      "hex":"#383442",
      "file_location":"assets/colors_organized/hue_23/brightness_04/2020040416371500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":4,
      "vividness":4,
      "r":54,
      "g":47,
      "b":65,
      "hex":"#362f41",
      "file_location":"assets/colors_organized/hue_23/brightness_04/2020040416371501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":4,
      "vividness":5,
      "r":52,
      "g":43,
      "b":64,
      "hex":"#342b40",
      "file_location":"assets/colors_organized/hue_23/brightness_04/2020040416371600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":4,
      "vividness":6,
      "r":49,
      "g":39,
      "b":64,
      "hex":"#312740",
      "file_location":"assets/colors_organized/hue_23/brightness_04/2020040416371601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":4,
      "vividness":7,
      "r":45,
      "g":34,
      "b":64,
      "hex":"#2d2240",
      "file_location":"assets/colors_organized/hue_23/brightness_04/2020040416371700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":4,
      "vividness":8,
      "r":44,
      "g":30,
      "b":65,
      "hex":"#2c1e41",
      "file_location":"assets/colors_organized/hue_23/brightness_04/2020040416371701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":4,
      "vividness":9,
      "r":41,
      "g":26,
      "b":65,
      "hex":"#291a41",
      "file_location":"assets/colors_organized/hue_23/brightness_04/2020040416371800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":4,
      "vividness":10,
      "r":39,
      "g":22,
      "b":66,
      "hex":"#271642",
      "file_location":"assets/colors_organized/hue_23/brightness_04/2020040416371801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":4,
      "vividness":11,
      "r":35,
      "g":18,
      "b":63,
      "hex":"#23123f",
      "file_location":"assets/colors_organized/hue_23/brightness_04/2020040416371900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":4,
      "vividness":12,
      "r":33,
      "g":13,
      "b":64,
      "hex":"#210d40",
      "file_location":"assets/colors_organized/hue_23/brightness_04/2020040416371901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":4,
      "vividness":13,
      "r":30,
      "g":6,
      "b":64,
      "hex":"#1e0640",
      "file_location":"assets/colors_organized/hue_23/brightness_04/2020040416372000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":4,
      "vividness":14,
      "r":27,
      "g":3,
      "b":65,
      "hex":"#1b0341",
      "file_location":"assets/colors_organized/hue_23/brightness_04/2020040416372001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":5,
      "vividness":1,
      "r":72,
      "g":69,
      "b":80,
      "hex":"#484550",
      "file_location":"assets/colors_organized/hue_23/brightness_05/2020040416370401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":5,
      "vividness":2,
      "r":70,
      "g":63,
      "b":79,
      "hex":"#463f4f",
      "file_location":"assets/colors_organized/hue_23/brightness_05/2020040416370500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":5,
      "vividness":3,
      "r":67,
      "g":58,
      "b":79,
      "hex":"#433a4f",
      "file_location":"assets/colors_organized/hue_23/brightness_05/2020040416370600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":5,
      "vividness":4,
      "r":63,
      "g":53,
      "b":80,
      "hex":"#3f3550",
      "file_location":"assets/colors_organized/hue_23/brightness_05/2020040416370601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":5,
      "vividness":5,
      "r":59,
      "g":48,
      "b":80,
      "hex":"#3b3050",
      "file_location":"assets/colors_organized/hue_23/brightness_05/2020040416370700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":5,
      "vividness":6,
      "r":56,
      "g":42,
      "b":78,
      "hex":"#382a4e",
      "file_location":"assets/colors_organized/hue_23/brightness_05/2020040416370701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":5,
      "vividness":7,
      "r":54,
      "g":37,
      "b":81,
      "hex":"#362551",
      "file_location":"assets/colors_organized/hue_23/brightness_05/2020040416370702-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":5,
      "vividness":8,
      "r":50,
      "g":32,
      "b":80,
      "hex":"#322050",
      "file_location":"assets/colors_organized/hue_23/brightness_05/2020040416370800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":5,
      "vividness":9,
      "r":47,
      "g":27,
      "b":80,
      "hex":"#2f1b50",
      "file_location":"assets/colors_organized/hue_23/brightness_05/2020040416370801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":5,
      "vividness":10,
      "r":43,
      "g":22,
      "b":79,
      "hex":"#2b164f",
      "file_location":"assets/colors_organized/hue_23/brightness_05/2020040416370900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":5,
      "vividness":11,
      "r":41,
      "g":15,
      "b":80,
      "hex":"#290f50",
      "file_location":"assets/colors_organized/hue_23/brightness_05/2020040416370901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":5,
      "vividness":12,
      "r":37,
      "g":10,
      "b":79,
      "hex":"#250a4f",
      "file_location":"assets/colors_organized/hue_23/brightness_05/2020040416371000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":5,
      "vividness":13,
      "r":34,
      "g":6,
      "b":80,
      "hex":"#220650",
      "file_location":"assets/colors_organized/hue_23/brightness_05/2020040416371001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":6,
      "vividness":1,
      "r":91,
      "g":88,
      "b":95,
      "hex":"#5b585f",
      "file_location":"assets/colors_organized/hue_23/brightness_06/2020040416365400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":6,
      "vividness":2,
      "r":86,
      "g":82,
      "b":96,
      "hex":"#565260",
      "file_location":"assets/colors_organized/hue_23/brightness_06/2020040416365401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":6,
      "vividness":3,
      "r":83,
      "g":76,
      "b":94,
      "hex":"#534c5e",
      "file_location":"assets/colors_organized/hue_23/brightness_06/2020040416365500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":6,
      "vividness":4,
      "r":79,
      "g":69,
      "b":94,
      "hex":"#4f455e",
      "file_location":"assets/colors_organized/hue_23/brightness_06/2020040416365501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":6,
      "vividness":5,
      "r":76,
      "g":62,
      "b":95,
      "hex":"#4c3e5f",
      "file_location":"assets/colors_organized/hue_23/brightness_06/2020040416365600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":6,
      "vividness":6,
      "r":71,
      "g":57,
      "b":93,
      "hex":"#47395d",
      "file_location":"assets/colors_organized/hue_23/brightness_06/2020040416365601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":6,
      "vividness":7,
      "r":67,
      "g":50,
      "b":94,
      "hex":"#43325e",
      "file_location":"assets/colors_organized/hue_23/brightness_06/2020040416365700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":6,
      "vividness":8,
      "r":63,
      "g":43,
      "b":94,
      "hex":"#3f2b5e",
      "file_location":"assets/colors_organized/hue_23/brightness_06/2020040416365701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":6,
      "vividness":9,
      "r":59,
      "g":38,
      "b":95,
      "hex":"#3b265f",
      "file_location":"assets/colors_organized/hue_23/brightness_06/2020040416365800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":6,
      "vividness":10,
      "r":57,
      "g":31,
      "b":96,
      "hex":"#391f60",
      "file_location":"assets/colors_organized/hue_23/brightness_06/2020040416365801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":6,
      "vividness":11,
      "r":52,
      "g":25,
      "b":94,
      "hex":"#34195e",
      "file_location":"assets/colors_organized/hue_23/brightness_06/2020040416365900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":6,
      "vividness":12,
      "r":48,
      "g":20,
      "b":95,
      "hex":"#30145f",
      "file_location":"assets/colors_organized/hue_23/brightness_06/2020040416365901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":6,
      "vividness":13,
      "r":44,
      "g":12,
      "b":95,
      "hex":"#2c0c5f",
      "file_location":"assets/colors_organized/hue_23/brightness_06/2020040416370000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":6,
      "vividness":14,
      "r":41,
      "g":7,
      "b":94,
      "hex":"#29075e",
      "file_location":"assets/colors_organized/hue_23/brightness_06/2020040416370001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":7,
      "vividness":1,
      "r":105,
      "g":102,
      "b":109,
      "hex":"#69666d",
      "file_location":"assets/colors_organized/hue_23/brightness_07/2020040416364301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":7,
      "vividness":2,
      "r":101,
      "g":95,
      "b":109,
      "hex":"#655f6d",
      "file_location":"assets/colors_organized/hue_23/brightness_07/2020040416364302-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":7,
      "vividness":3,
      "r":96,
      "g":88,
      "b":111,
      "hex":"#60586f",
      "file_location":"assets/colors_organized/hue_23/brightness_07/2020040416364400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":7,
      "vividness":4,
      "r":91,
      "g":80,
      "b":110,
      "hex":"#5b506e",
      "file_location":"assets/colors_organized/hue_23/brightness_07/2020040416364500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":7,
      "vividness":5,
      "r":88,
      "g":74,
      "b":110,
      "hex":"#584a6e",
      "file_location":"assets/colors_organized/hue_23/brightness_07/2020040416364501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":7,
      "vividness":6,
      "r":83,
      "g":66,
      "b":110,
      "hex":"#53426e",
      "file_location":"assets/colors_organized/hue_23/brightness_07/2020040416364600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":7,
      "vividness":7,
      "r":78,
      "g":58,
      "b":109,
      "hex":"#4e3a6d",
      "file_location":"assets/colors_organized/hue_23/brightness_07/2020040416364601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":7,
      "vividness":8,
      "r":74,
      "g":50,
      "b":110,
      "hex":"#4a326e",
      "file_location":"assets/colors_organized/hue_23/brightness_07/2020040416364700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":7,
      "vividness":9,
      "r":70,
      "g":43,
      "b":110,
      "hex":"#462b6e",
      "file_location":"assets/colors_organized/hue_23/brightness_07/2020040416364701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":7,
      "vividness":10,
      "r":66,
      "g":36,
      "b":110,
      "hex":"#42246e",
      "file_location":"assets/colors_organized/hue_23/brightness_07/2020040416364800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":7,
      "vividness":11,
      "r":61,
      "g":30,
      "b":110,
      "hex":"#3d1e6e",
      "file_location":"assets/colors_organized/hue_23/brightness_07/2020040416364801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":7,
      "vividness":12,
      "r":56,
      "g":22,
      "b":109,
      "hex":"#38166d",
      "file_location":"assets/colors_organized/hue_23/brightness_07/2020040416364900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":7,
      "vividness":13,
      "r":50,
      "g":13,
      "b":109,
      "hex":"#320d6d",
      "file_location":"assets/colors_organized/hue_23/brightness_07/2020040416364901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":7,
      "vividness":14,
      "r":48,
      "g":7,
      "b":111,
      "hex":"#30076f",
      "file_location":"assets/colors_organized/hue_23/brightness_07/2020040416365000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":8,
      "vividness":1,
      "r":119,
      "g":116,
      "b":123,
      "hex":"#77747b",
      "file_location":"assets/colors_organized/hue_23/brightness_08/2020040416363301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":8,
      "vividness":2,
      "r":114,
      "g":107,
      "b":123,
      "hex":"#726b7b",
      "file_location":"assets/colors_organized/hue_23/brightness_08/2020040416363302-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":8,
      "vividness":3,
      "r":109,
      "g":99,
      "b":124,
      "hex":"#6d637c",
      "file_location":"assets/colors_organized/hue_23/brightness_08/2020040416363400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":8,
      "vividness":4,
      "r":104,
      "g":90,
      "b":123,
      "hex":"#685a7b",
      "file_location":"assets/colors_organized/hue_23/brightness_08/2020040416363401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":8,
      "vividness":5,
      "r":99,
      "g":82,
      "b":124,
      "hex":"#63527c",
      "file_location":"assets/colors_organized/hue_23/brightness_08/2020040416363500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":8,
      "vividness":6,
      "r":94,
      "g":74,
      "b":125,
      "hex":"#5e4a7d",
      "file_location":"assets/colors_organized/hue_23/brightness_08/2020040416363501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":8,
      "vividness":7,
      "r":89,
      "g":65,
      "b":123,
      "hex":"#59417b",
      "file_location":"assets/colors_organized/hue_23/brightness_08/2020040416363600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":8,
      "vividness":8,
      "r":84,
      "g":57,
      "b":124,
      "hex":"#54397c",
      "file_location":"assets/colors_organized/hue_23/brightness_08/2020040416363601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":8,
      "vividness":9,
      "r":79,
      "g":49,
      "b":123,
      "hex":"#4f317b",
      "file_location":"assets/colors_organized/hue_23/brightness_08/2020040416363700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":8,
      "vividness":10,
      "r":74,
      "g":40,
      "b":124,
      "hex":"#4a287c",
      "file_location":"assets/colors_organized/hue_23/brightness_08/2020040416363701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":8,
      "vividness":11,
      "r":68,
      "g":33,
      "b":123,
      "hex":"#44217b",
      "file_location":"assets/colors_organized/hue_23/brightness_08/2020040416363800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":8,
      "vividness":12,
      "r":63,
      "g":22,
      "b":124,
      "hex":"#3f167c",
      "file_location":"assets/colors_organized/hue_23/brightness_08/2020040416363801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":8,
      "vividness":13,
      "r":58,
      "g":15,
      "b":123,
      "hex":"#3a0f7b",
      "file_location":"assets/colors_organized/hue_23/brightness_08/2020040416363900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":8,
      "vividness":14,
      "r":54,
      "g":6,
      "b":124,
      "hex":"#36067c",
      "file_location":"assets/colors_organized/hue_23/brightness_08/2020040416363901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":9,
      "vividness":1,
      "r":133,
      "g":130,
      "b":139,
      "hex":"#85828b",
      "file_location":"assets/colors_organized/hue_23/brightness_09/2020040416362201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":9,
      "vividness":2,
      "r":128,
      "g":121,
      "b":139,
      "hex":"#80798b",
      "file_location":"assets/colors_organized/hue_23/brightness_09/2020040416362300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":9,
      "vividness":3,
      "r":124,
      "g":111,
      "b":139,
      "hex":"#7c6f8b",
      "file_location":"assets/colors_organized/hue_23/brightness_09/2020040416362301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":9,
      "vividness":4,
      "r":117,
      "g":103,
      "b":139,
      "hex":"#75678b",
      "file_location":"assets/colors_organized/hue_23/brightness_09/2020040416362400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":9,
      "vividness":5,
      "r":110,
      "g":93,
      "b":138,
      "hex":"#6e5d8a",
      "file_location":"assets/colors_organized/hue_23/brightness_09/2020040416362401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":9,
      "vividness":6,
      "r":106,
      "g":83,
      "b":139,
      "hex":"#6a538b",
      "file_location":"assets/colors_organized/hue_23/brightness_09/2020040416362500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":9,
      "vividness":7,
      "r":100,
      "g":74,
      "b":139,
      "hex":"#644a8b",
      "file_location":"assets/colors_organized/hue_23/brightness_09/2020040416362501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":9,
      "vividness":8,
      "r":95,
      "g":65,
      "b":139,
      "hex":"#5f418b",
      "file_location":"assets/colors_organized/hue_23/brightness_09/2020040416362600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":9,
      "vividness":9,
      "r":88,
      "g":54,
      "b":138,
      "hex":"#58368a",
      "file_location":"assets/colors_organized/hue_23/brightness_09/2020040416362601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":9,
      "vividness":10,
      "r":82,
      "g":45,
      "b":138,
      "hex":"#522d8a",
      "file_location":"assets/colors_organized/hue_23/brightness_09/2020040416362700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":9,
      "vividness":11,
      "r":78,
      "g":37,
      "b":139,
      "hex":"#4e258b",
      "file_location":"assets/colors_organized/hue_23/brightness_09/2020040416362701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":9,
      "vividness":12,
      "r":71,
      "g":27,
      "b":140,
      "hex":"#471b8c",
      "file_location":"assets/colors_organized/hue_23/brightness_09/2020040416362800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":9,
      "vividness":13,
      "r":67,
      "g":17,
      "b":138,
      "hex":"#43118a",
      "file_location":"assets/colors_organized/hue_23/brightness_09/2020040416362801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":9,
      "vividness":14,
      "r":61,
      "g":10,
      "b":139,
      "hex":"#3d0a8b",
      "file_location":"assets/colors_organized/hue_23/brightness_09/2020040416362900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":10,
      "vividness":1,
      "r":147,
      "g":144,
      "b":153,
      "hex":"#939099",
      "file_location":"assets/colors_organized/hue_23/brightness_10/2020040416361201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":10,
      "vividness":2,
      "r":142,
      "g":134,
      "b":155,
      "hex":"#8e869b",
      "file_location":"assets/colors_organized/hue_23/brightness_10/2020040416361300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":10,
      "vividness":3,
      "r":136,
      "g":123,
      "b":153,
      "hex":"#887b99",
      "file_location":"assets/colors_organized/hue_23/brightness_10/2020040416361301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":10,
      "vividness":4,
      "r":130,
      "g":113,
      "b":155,
      "hex":"#82719b",
      "file_location":"assets/colors_organized/hue_23/brightness_10/2020040416361302-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":10,
      "vividness":5,
      "r":123,
      "g":103,
      "b":154,
      "hex":"#7b679a",
      "file_location":"assets/colors_organized/hue_23/brightness_10/2020040416361400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":10,
      "vividness":6,
      "r":116,
      "g":92,
      "b":154,
      "hex":"#745c9a",
      "file_location":"assets/colors_organized/hue_23/brightness_10/2020040416361401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":10,
      "vividness":7,
      "r":112,
      "g":82,
      "b":154,
      "hex":"#70529a",
      "file_location":"assets/colors_organized/hue_23/brightness_10/2020040416361500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":10,
      "vividness":8,
      "r":105,
      "g":72,
      "b":153,
      "hex":"#694899",
      "file_location":"assets/colors_organized/hue_23/brightness_10/2020040416361501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":10,
      "vividness":9,
      "r":98,
      "g":61,
      "b":154,
      "hex":"#623d9a",
      "file_location":"assets/colors_organized/hue_23/brightness_10/2020040416361600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":10,
      "vividness":10,
      "r":92,
      "g":51,
      "b":155,
      "hex":"#5c339b",
      "file_location":"assets/colors_organized/hue_23/brightness_10/2020040416361601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":10,
      "vividness":11,
      "r":85,
      "g":41,
      "b":154,
      "hex":"#55299a",
      "file_location":"assets/colors_organized/hue_23/brightness_10/2020040416361700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":10,
      "vividness":12,
      "r":80,
      "g":30,
      "b":155,
      "hex":"#501e9b",
      "file_location":"assets/colors_organized/hue_23/brightness_10/2020040416361800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":10,
      "vividness":13,
      "r":73,
      "g":20,
      "b":154,
      "hex":"#49149a",
      "file_location":"assets/colors_organized/hue_23/brightness_10/2020040416361801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":10,
      "vividness":14,
      "r":67,
      "g":10,
      "b":153,
      "hex":"#430a99",
      "file_location":"assets/colors_organized/hue_23/brightness_10/2020040416361900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":11,
      "vividness":1,
      "r":162,
      "g":159,
      "b":170,
      "hex":"#a29faa",
      "file_location":"assets/colors_organized/hue_23/brightness_11/2020040416360201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":11,
      "vividness":2,
      "r":156,
      "g":147,
      "b":168,
      "hex":"#9c93a8",
      "file_location":"assets/colors_organized/hue_23/brightness_11/2020040416360300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":11,
      "vividness":3,
      "r":149,
      "g":135,
      "b":170,
      "hex":"#9587aa",
      "file_location":"assets/colors_organized/hue_23/brightness_11/2020040416360301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":11,
      "vividness":4,
      "r":141,
      "g":124,
      "b":168,
      "hex":"#8d7ca8",
      "file_location":"assets/colors_organized/hue_23/brightness_11/2020040416360400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":11,
      "vividness":5,
      "r":134,
      "g":113,
      "b":169,
      "hex":"#8671a9",
      "file_location":"assets/colors_organized/hue_23/brightness_11/2020040416360401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":11,
      "vividness":6,
      "r":128,
      "g":101,
      "b":168,
      "hex":"#8065a8",
      "file_location":"assets/colors_organized/hue_23/brightness_11/2020040416360402-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":11,
      "vividness":7,
      "r":121,
      "g":90,
      "b":168,
      "hex":"#795aa8",
      "file_location":"assets/colors_organized/hue_23/brightness_11/2020040416360500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":11,
      "vividness":8,
      "r":115,
      "g":79,
      "b":169,
      "hex":"#734fa9",
      "file_location":"assets/colors_organized/hue_23/brightness_11/2020040416360501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":11,
      "vividness":9,
      "r":108,
      "g":67,
      "b":169,
      "hex":"#6c43a9",
      "file_location":"assets/colors_organized/hue_23/brightness_11/2020040416360600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":11,
      "vividness":10,
      "r":100,
      "g":56,
      "b":169,
      "hex":"#6438a9",
      "file_location":"assets/colors_organized/hue_23/brightness_11/2020040416360601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":11,
      "vividness":11,
      "r":95,
      "g":45,
      "b":170,
      "hex":"#5f2daa",
      "file_location":"assets/colors_organized/hue_23/brightness_11/2020040416360700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":11,
      "vividness":12,
      "r":87,
      "g":33,
      "b":170,
      "hex":"#5721aa",
      "file_location":"assets/colors_organized/hue_23/brightness_11/2020040416360701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":11,
      "vividness":13,
      "r":81,
      "g":21,
      "b":169,
      "hex":"#5115a9",
      "file_location":"assets/colors_organized/hue_23/brightness_11/2020040416360800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":11,
      "vividness":14,
      "r":74,
      "g":10,
      "b":169,
      "hex":"#4a0aa9",
      "file_location":"assets/colors_organized/hue_23/brightness_11/2020040416360801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":12,
      "vividness":1,
      "r":176,
      "g":173,
      "b":184,
      "hex":"#b0adb8",
      "file_location":"assets/colors_organized/hue_23/brightness_12/2020040416355201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":12,
      "vividness":2,
      "r":169,
      "g":161,
      "b":184,
      "hex":"#a9a1b8",
      "file_location":"assets/colors_organized/hue_23/brightness_12/2020040416355300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":12,
      "vividness":3,
      "r":162,
      "g":148,
      "b":184,
      "hex":"#a294b8",
      "file_location":"assets/colors_organized/hue_23/brightness_12/2020040416355301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":12,
      "vividness":4,
      "r":155,
      "g":135,
      "b":184,
      "hex":"#9b87b8",
      "file_location":"assets/colors_organized/hue_23/brightness_12/2020040416355400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":12,
      "vividness":5,
      "r":147,
      "g":123,
      "b":183,
      "hex":"#937bb7",
      "file_location":"assets/colors_organized/hue_23/brightness_12/2020040416355401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":12,
      "vividness":6,
      "r":140,
      "g":110,
      "b":184,
      "hex":"#8c6eb8",
      "file_location":"assets/colors_organized/hue_23/brightness_12/2020040416355500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":12,
      "vividness":7,
      "r":132,
      "g":98,
      "b":184,
      "hex":"#8462b8",
      "file_location":"assets/colors_organized/hue_23/brightness_12/2020040416355501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":12,
      "vividness":8,
      "r":124,
      "g":87,
      "b":183,
      "hex":"#7c57b7",
      "file_location":"assets/colors_organized/hue_23/brightness_12/2020040416355600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":12,
      "vividness":9,
      "r":117,
      "g":73,
      "b":184,
      "hex":"#7549b8",
      "file_location":"assets/colors_organized/hue_23/brightness_12/2020040416355601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":12,
      "vividness":10,
      "r":109,
      "g":61,
      "b":183,
      "hex":"#6d3db7",
      "file_location":"assets/colors_organized/hue_23/brightness_12/2020040416355602-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":12,
      "vividness":11,
      "r":102,
      "g":48,
      "b":185,
      "hex":"#6630b9",
      "file_location":"assets/colors_organized/hue_23/brightness_12/2020040416355700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":12,
      "vividness":12,
      "r":96,
      "g":36,
      "b":184,
      "hex":"#6024b8",
      "file_location":"assets/colors_organized/hue_23/brightness_12/2020040416355701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":12,
      "vividness":13,
      "r":87,
      "g":22,
      "b":184,
      "hex":"#5716b8",
      "file_location":"assets/colors_organized/hue_23/brightness_12/2020040416355800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":12,
      "vividness":14,
      "r":81,
      "g":13,
      "b":184,
      "hex":"#510db8",
      "file_location":"assets/colors_organized/hue_23/brightness_12/2020040416355801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":13,
      "vividness":1,
      "r":190,
      "g":186,
      "b":200,
      "hex":"#bebac8",
      "file_location":"assets/colors_organized/hue_23/brightness_13/2020040416354201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":13,
      "vividness":2,
      "r":183,
      "g":173,
      "b":198,
      "hex":"#b7adc6",
      "file_location":"assets/colors_organized/hue_23/brightness_13/2020040416354300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":13,
      "vividness":3,
      "r":176,
      "g":160,
      "b":199,
      "hex":"#b0a0c7",
      "file_location":"assets/colors_organized/hue_23/brightness_13/2020040416354301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":13,
      "vividness":4,
      "r":167,
      "g":147,
      "b":200,
      "hex":"#a793c8",
      "file_location":"assets/colors_organized/hue_23/brightness_13/2020040416354400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":13,
      "vividness":5,
      "r":158,
      "g":133,
      "b":199,
      "hex":"#9e85c7",
      "file_location":"assets/colors_organized/hue_23/brightness_13/2020040416354401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":13,
      "vividness":6,
      "r":151,
      "g":120,
      "b":200,
      "hex":"#9778c8",
      "file_location":"assets/colors_organized/hue_23/brightness_13/2020040416354500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":13,
      "vividness":7,
      "r":143,
      "g":106,
      "b":199,
      "hex":"#8f6ac7",
      "file_location":"assets/colors_organized/hue_23/brightness_13/2020040416354501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":13,
      "vividness":8,
      "r":135,
      "g":94,
      "b":200,
      "hex":"#875ec8",
      "file_location":"assets/colors_organized/hue_23/brightness_13/2020040416354600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":13,
      "vividness":9,
      "r":127,
      "g":79,
      "b":199,
      "hex":"#7f4fc7",
      "file_location":"assets/colors_organized/hue_23/brightness_13/2020040416354601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":13,
      "vividness":10,
      "r":119,
      "g":66,
      "b":200,
      "hex":"#7742c8",
      "file_location":"assets/colors_organized/hue_23/brightness_13/2020040416354700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":13,
      "vividness":11,
      "r":111,
      "g":53,
      "b":199,
      "hex":"#6f35c7",
      "file_location":"assets/colors_organized/hue_23/brightness_13/2020040416354701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":13,
      "vividness":12,
      "r":103,
      "g":39,
      "b":198,
      "hex":"#6727c6",
      "file_location":"assets/colors_organized/hue_23/brightness_13/2020040416354702-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":13,
      "vividness":13,
      "r":95,
      "g":27,
      "b":200,
      "hex":"#5f1bc8",
      "file_location":"assets/colors_organized/hue_23/brightness_13/2020040416354800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":13,
      "vividness":14,
      "r":87,
      "g":12,
      "b":200,
      "hex":"#570cc8",
      "file_location":"assets/colors_organized/hue_23/brightness_13/2020040416354801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":14,
      "vividness":1,
      "r":205,
      "g":201,
      "b":215,
      "hex":"#cdc9d7",
      "file_location":"assets/colors_organized/hue_23/brightness_14/2020040416353300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":14,
      "vividness":2,
      "r":198,
      "g":185,
      "b":213,
      "hex":"#c6b9d5",
      "file_location":"assets/colors_organized/hue_23/brightness_14/2020040416353301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":14,
      "vividness":3,
      "r":188,
      "g":171,
      "b":215,
      "hex":"#bcabd7",
      "file_location":"assets/colors_organized/hue_23/brightness_14/2020040416353302-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":14,
      "vividness":4,
      "r":181,
      "g":157,
      "b":215,
      "hex":"#b59dd7",
      "file_location":"assets/colors_organized/hue_23/brightness_14/2020040416353400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":14,
      "vividness":5,
      "r":171,
      "g":144,
      "b":215,
      "hex":"#ab90d7",
      "file_location":"assets/colors_organized/hue_23/brightness_14/2020040416353401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":14,
      "vividness":6,
      "r":162,
      "g":128,
      "b":214,
      "hex":"#a280d6",
      "file_location":"assets/colors_organized/hue_23/brightness_14/2020040416353500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":14,
      "vividness":7,
      "r":154,
      "g":114,
      "b":213,
      "hex":"#9a72d5",
      "file_location":"assets/colors_organized/hue_23/brightness_14/2020040416353501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":14,
      "vividness":8,
      "r":144,
      "g":100,
      "b":215,
      "hex":"#9064d7",
      "file_location":"assets/colors_organized/hue_23/brightness_14/2020040416353600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":14,
      "vividness":9,
      "r":136,
      "g":85,
      "b":214,
      "hex":"#8855d6",
      "file_location":"assets/colors_organized/hue_23/brightness_14/2020040416353601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":14,
      "vividness":10,
      "r":128,
      "g":71,
      "b":214,
      "hex":"#8047d6",
      "file_location":"assets/colors_organized/hue_23/brightness_14/2020040416353700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":14,
      "vividness":11,
      "r":120,
      "g":56,
      "b":213,
      "hex":"#7838d5",
      "file_location":"assets/colors_organized/hue_23/brightness_14/2020040416353701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":14,
      "vividness":12,
      "r":110,
      "g":42,
      "b":215,
      "hex":"#6e2ad7",
      "file_location":"assets/colors_organized/hue_23/brightness_14/2020040416353800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":14,
      "vividness":13,
      "r":102,
      "g":27,
      "b":215,
      "hex":"#661bd7",
      "file_location":"assets/colors_organized/hue_23/brightness_14/2020040416353801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":14,
      "vividness":14,
      "r":94,
      "g":13,
      "b":214,
      "hex":"#5e0dd6",
      "file_location":"assets/colors_organized/hue_23/brightness_14/2020040416353900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":15,
      "vividness":1,
      "r":221,
      "g":215,
      "b":229,
      "hex":"#ddd7e5",
      "file_location":"assets/colors_organized/hue_23/brightness_15/2020040416352201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":15,
      "vividness":2,
      "r":212,
      "g":199,
      "b":229,
      "hex":"#d4c7e5",
      "file_location":"assets/colors_organized/hue_23/brightness_15/2020040416352300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":15,
      "vividness":3,
      "r":201,
      "g":184,
      "b":229,
      "hex":"#c9b8e5",
      "file_location":"assets/colors_organized/hue_23/brightness_15/2020040416352301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":15,
      "vividness":4,
      "r":192,
      "g":168,
      "b":228,
      "hex":"#c0a8e4",
      "file_location":"assets/colors_organized/hue_23/brightness_15/2020040416352302-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":15,
      "vividness":5,
      "r":183,
      "g":152,
      "b":228,
      "hex":"#b798e4",
      "file_location":"assets/colors_organized/hue_23/brightness_15/2020040416352400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":15,
      "vividness":6,
      "r":174,
      "g":137,
      "b":230,
      "hex":"#ae89e6",
      "file_location":"assets/colors_organized/hue_23/brightness_15/2020040416352401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":15,
      "vividness":7,
      "r":165,
      "g":122,
      "b":229,
      "hex":"#a57ae5",
      "file_location":"assets/colors_organized/hue_23/brightness_15/2020040416352500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":15,
      "vividness":8,
      "r":157,
      "g":107,
      "b":230,
      "hex":"#9d6be6",
      "file_location":"assets/colors_organized/hue_23/brightness_15/2020040416352501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":15,
      "vividness":9,
      "r":145,
      "g":91,
      "b":229,
      "hex":"#915be5",
      "file_location":"assets/colors_organized/hue_23/brightness_15/2020040416352600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":15,
      "vividness":10,
      "r":137,
      "g":76,
      "b":229,
      "hex":"#894ce5",
      "file_location":"assets/colors_organized/hue_23/brightness_15/2020040416352601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":15,
      "vividness":11,
      "r":128,
      "g":61,
      "b":229,
      "hex":"#803de5",
      "file_location":"assets/colors_organized/hue_23/brightness_15/2020040416352700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":15,
      "vividness":12,
      "r":119,
      "g":45,
      "b":230,
      "hex":"#772de6",
      "file_location":"assets/colors_organized/hue_23/brightness_15/2020040416352701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":15,
      "vividness":13,
      "r":110,
      "g":29,
      "b":228,
      "hex":"#6e1de4",
      "file_location":"assets/colors_organized/hue_23/brightness_15/2020040416352702-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":23,
      "brightness":15,
      "vividness":14,
      "r":100,
      "g":15,
      "b":230,
      "hex":"#640fe6",
      "file_location":"assets/colors_organized/hue_23/brightness_15/2020040416352800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":1,
      "vividness":1,
      "r":19,
      "g":17,
      "b":20,
      "hex":"#131114",
      "file_location":"assets/colors_organized/hue_24/brightness_01/2020040416404001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":1,
      "vividness":2,
      "r":15,
      "g":9,
      "b":19,
      "hex":"#0f0913",
      "file_location":"assets/colors_organized/hue_24/brightness_01/2020040416404201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":1,
      "vividness":3,
      "r":14,
      "g":6,
      "b":19,
      "hex":"#0e0613",
      "file_location":"assets/colors_organized/hue_24/brightness_01/2020040416404301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":1,
      "vividness":4,
      "r":11,
      "g":2,
      "b":19,
      "hex":"#0b0213",
      "file_location":"assets/colors_organized/hue_24/brightness_01/2020040416404402-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":1,
      "vividness":5,
      "r":13,
      "g":0,
      "b":20,
      "hex":"#0d0014",
      "file_location":"assets/colors_organized/hue_24/brightness_01/2020040416404600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":2,
      "vividness":1,
      "r":32,
      "g":30,
      "b":35,
      "hex":"#201e23",
      "file_location":"assets/colors_organized/hue_24/brightness_02/2020040416403100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":2,
      "vividness":2,
      "r":31,
      "g":28,
      "b":35,
      "hex":"#1f1c23",
      "file_location":"assets/colors_organized/hue_24/brightness_02/2020040416403101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":2,
      "vividness":3,
      "r":29,
      "g":23,
      "b":35,
      "hex":"#1d1723",
      "file_location":"assets/colors_organized/hue_24/brightness_02/2020040416403201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":2,
      "vividness":4,
      "r":29,
      "g":21,
      "b":34,
      "hex":"#1d1522",
      "file_location":"assets/colors_organized/hue_24/brightness_02/2020040416403202-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":2,
      "vividness":5,
      "r":26,
      "g":17,
      "b":34,
      "hex":"#1a1122",
      "file_location":"assets/colors_organized/hue_24/brightness_02/2020040416403300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":2,
      "vividness":6,
      "r":26,
      "g":14,
      "b":34,
      "hex":"#1a0e22",
      "file_location":"assets/colors_organized/hue_24/brightness_02/2020040416403301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":2,
      "vividness":7,
      "r":26,
      "g":12,
      "b":35,
      "hex":"#1a0c23",
      "file_location":"assets/colors_organized/hue_24/brightness_02/2020040416403400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":2,
      "vividness":8,
      "r":24,
      "g":10,
      "b":35,
      "hex":"#180a23",
      "file_location":"assets/colors_organized/hue_24/brightness_02/2020040416403401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":2,
      "vividness":9,
      "r":22,
      "g":6,
      "b":33,
      "hex":"#160621",
      "file_location":"assets/colors_organized/hue_24/brightness_02/2020040416403501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":2,
      "vividness":10,
      "r":22,
      "g":3,
      "b":35,
      "hex":"#160323",
      "file_location":"assets/colors_organized/hue_24/brightness_02/2020040416403600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":3,
      "vividness":1,
      "r":46,
      "g":43,
      "b":50,
      "hex":"#2e2b32",
      "file_location":"assets/colors_organized/hue_24/brightness_03/2020040416402101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":3,
      "vividness":2,
      "r":45,
      "g":39,
      "b":49,
      "hex":"#2d2731",
      "file_location":"assets/colors_organized/hue_24/brightness_03/2020040416402200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":3,
      "vividness":3,
      "r":44,
      "g":36,
      "b":51,
      "hex":"#2c2433",
      "file_location":"assets/colors_organized/hue_24/brightness_03/2020040416402201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":3,
      "vividness":4,
      "r":42,
      "g":34,
      "b":49,
      "hex":"#2a2231",
      "file_location":"assets/colors_organized/hue_24/brightness_03/2020040416402300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":3,
      "vividness":5,
      "r":41,
      "g":29,
      "b":49,
      "hex":"#291d31",
      "file_location":"assets/colors_organized/hue_24/brightness_03/2020040416402301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":3,
      "vividness":6,
      "r":40,
      "g":26,
      "b":49,
      "hex":"#281a31",
      "file_location":"assets/colors_organized/hue_24/brightness_03/2020040416402400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":3,
      "vividness":7,
      "r":38,
      "g":22,
      "b":49,
      "hex":"#261631",
      "file_location":"assets/colors_organized/hue_24/brightness_03/2020040416402401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":3,
      "vividness":8,
      "r":36,
      "g":20,
      "b":49,
      "hex":"#241431",
      "file_location":"assets/colors_organized/hue_24/brightness_03/2020040416402402-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":3,
      "vividness":9,
      "r":36,
      "g":14,
      "b":50,
      "hex":"#240e32",
      "file_location":"assets/colors_organized/hue_24/brightness_03/2020040416402500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":3,
      "vividness":10,
      "r":34,
      "g":12,
      "b":50,
      "hex":"#220c32",
      "file_location":"assets/colors_organized/hue_24/brightness_03/2020040416402501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":3,
      "vividness":11,
      "r":34,
      "g":9,
      "b":49,
      "hex":"#220931",
      "file_location":"assets/colors_organized/hue_24/brightness_03/2020040416402600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":3,
      "vividness":12,
      "r":32,
      "g":7,
      "b":49,
      "hex":"#200731",
      "file_location":"assets/colors_organized/hue_24/brightness_03/2020040416402601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":3,
      "vividness":13,
      "r":30,
      "g":2,
      "b":50,
      "hex":"#1e0232",
      "file_location":"assets/colors_organized/hue_24/brightness_03/2020040416402700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":4,
      "vividness":1,
      "r":62,
      "g":60,
      "b":65,
      "hex":"#3e3c41",
      "file_location":"assets/colors_organized/hue_24/brightness_04/2020040416401100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":4,
      "vividness":2,
      "r":61,
      "g":55,
      "b":65,
      "hex":"#3d3741",
      "file_location":"assets/colors_organized/hue_24/brightness_04/2020040416401101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":4,
      "vividness":3,
      "r":58,
      "g":52,
      "b":64,
      "hex":"#3a3440",
      "file_location":"assets/colors_organized/hue_24/brightness_04/2020040416401200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":4,
      "vividness":4,
      "r":58,
      "g":47,
      "b":64,
      "hex":"#3a2f40",
      "file_location":"assets/colors_organized/hue_24/brightness_04/2020040416401201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":4,
      "vividness":5,
      "r":56,
      "g":42,
      "b":65,
      "hex":"#382a41",
      "file_location":"assets/colors_organized/hue_24/brightness_04/2020040416401300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":4,
      "vividness":6,
      "r":53,
      "g":39,
      "b":64,
      "hex":"#352740",
      "file_location":"assets/colors_organized/hue_24/brightness_04/2020040416401301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":4,
      "vividness":7,
      "r":53,
      "g":34,
      "b":64,
      "hex":"#352240",
      "file_location":"assets/colors_organized/hue_24/brightness_04/2020040416401400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":4,
      "vividness":8,
      "r":51,
      "g":29,
      "b":65,
      "hex":"#331d41",
      "file_location":"assets/colors_organized/hue_24/brightness_04/2020040416401401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":4,
      "vividness":9,
      "r":48,
      "g":26,
      "b":64,
      "hex":"#301a40",
      "file_location":"assets/colors_organized/hue_24/brightness_04/2020040416401500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":4,
      "vividness":10,
      "r":47,
      "g":22,
      "b":64,
      "hex":"#2f1640",
      "file_location":"assets/colors_organized/hue_24/brightness_04/2020040416401501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":4,
      "vividness":11,
      "r":45,
      "g":17,
      "b":65,
      "hex":"#2d1141",
      "file_location":"assets/colors_organized/hue_24/brightness_04/2020040416401600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":4,
      "vividness":12,
      "r":43,
      "g":13,
      "b":65,
      "hex":"#2b0d41",
      "file_location":"assets/colors_organized/hue_24/brightness_04/2020040416401601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":4,
      "vividness":13,
      "r":42,
      "g":7,
      "b":65,
      "hex":"#2a0741",
      "file_location":"assets/colors_organized/hue_24/brightness_04/2020040416401700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":4,
      "vividness":14,
      "r":39,
      "g":3,
      "b":65,
      "hex":"#270341",
      "file_location":"assets/colors_organized/hue_24/brightness_04/2020040416401701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":5,
      "vividness":1,
      "r":77,
      "g":75,
      "b":80,
      "hex":"#4d4b50",
      "file_location":"assets/colors_organized/hue_24/brightness_05/2020040416400101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":5,
      "vividness":2,
      "r":75,
      "g":69,
      "b":79,
      "hex":"#4b454f",
      "file_location":"assets/colors_organized/hue_24/brightness_05/2020040416400200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":5,
      "vividness":3,
      "r":72,
      "g":64,
      "b":79,
      "hex":"#48404f",
      "file_location":"assets/colors_organized/hue_24/brightness_05/2020040416400201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":5,
      "vividness":4,
      "r":71,
      "g":57,
      "b":80,
      "hex":"#473950",
      "file_location":"assets/colors_organized/hue_24/brightness_05/2020040416400202-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":5,
      "vividness":5,
      "r":69,
      "g":53,
      "b":80,
      "hex":"#453550",
      "file_location":"assets/colors_organized/hue_24/brightness_05/2020040416400300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":5,
      "vividness":6,
      "r":67,
      "g":48,
      "b":80,
      "hex":"#433050",
      "file_location":"assets/colors_organized/hue_24/brightness_05/2020040416400400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":5,
      "vividness":7,
      "r":64,
      "g":42,
      "b":80,
      "hex":"#402a50",
      "file_location":"assets/colors_organized/hue_24/brightness_05/2020040416400401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":5,
      "vividness":8,
      "r":62,
      "g":37,
      "b":79,
      "hex":"#3e254f",
      "file_location":"assets/colors_organized/hue_24/brightness_05/2020040416400402-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":5,
      "vividness":9,
      "r":59,
      "g":31,
      "b":79,
      "hex":"#3b1f4f",
      "file_location":"assets/colors_organized/hue_24/brightness_05/2020040416400500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":5,
      "vividness":10,
      "r":57,
      "g":27,
      "b":79,
      "hex":"#391b4f",
      "file_location":"assets/colors_organized/hue_24/brightness_05/2020040416400501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":5,
      "vividness":11,
      "r":55,
      "g":22,
      "b":79,
      "hex":"#37164f",
      "file_location":"assets/colors_organized/hue_24/brightness_05/2020040416400600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":5,
      "vividness":12,
      "r":54,
      "g":15,
      "b":80,
      "hex":"#360f50",
      "file_location":"assets/colors_organized/hue_24/brightness_05/2020040416400601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":5,
      "vividness":13,
      "r":51,
      "g":10,
      "b":80,
      "hex":"#330a50",
      "file_location":"assets/colors_organized/hue_24/brightness_05/2020040416400700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":5,
      "vividness":14,
      "r":49,
      "g":7,
      "b":79,
      "hex":"#31074f",
      "file_location":"assets/colors_organized/hue_24/brightness_05/2020040416400701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":6,
      "vividness":1,
      "r":90,
      "g":82,
      "b":95,
      "hex":"#5a525f",
      "file_location":"assets/colors_organized/hue_24/brightness_06/2020040416395100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":6,
      "vividness":2,
      "r":87,
      "g":75,
      "b":95,
      "hex":"#574b5f",
      "file_location":"assets/colors_organized/hue_24/brightness_06/2020040416395101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":6,
      "vividness":3,
      "r":83,
      "g":69,
      "b":94,
      "hex":"#53455e",
      "file_location":"assets/colors_organized/hue_24/brightness_06/2020040416395200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":6,
      "vividness":4,
      "r":82,
      "g":63,
      "b":95,
      "hex":"#523f5f",
      "file_location":"assets/colors_organized/hue_24/brightness_06/2020040416395201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":6,
      "vividness":5,
      "r":79,
      "g":57,
      "b":95,
      "hex":"#4f395f",
      "file_location":"assets/colors_organized/hue_24/brightness_06/2020040416395300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":6,
      "vividness":6,
      "r":77,
      "g":50,
      "b":95,
      "hex":"#4d325f",
      "file_location":"assets/colors_organized/hue_24/brightness_06/2020040416395301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":6,
      "vividness":7,
      "r":73,
      "g":43,
      "b":93,
      "hex":"#492b5d",
      "file_location":"assets/colors_organized/hue_24/brightness_06/2020040416395400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":6,
      "vividness":8,
      "r":71,
      "g":38,
      "b":95,
      "hex":"#47265f",
      "file_location":"assets/colors_organized/hue_24/brightness_06/2020040416395401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":6,
      "vividness":9,
      "r":69,
      "g":30,
      "b":95,
      "hex":"#451e5f",
      "file_location":"assets/colors_organized/hue_24/brightness_06/2020040416395500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":6,
      "vividness":10,
      "r":66,
      "g":25,
      "b":95,
      "hex":"#42195f",
      "file_location":"assets/colors_organized/hue_24/brightness_06/2020040416395501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":6,
      "vividness":11,
      "r":63,
      "g":19,
      "b":94,
      "hex":"#3f135e",
      "file_location":"assets/colors_organized/hue_24/brightness_06/2020040416395600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":6,
      "vividness":12,
      "r":62,
      "g":13,
      "b":95,
      "hex":"#3e0d5f",
      "file_location":"assets/colors_organized/hue_24/brightness_06/2020040416395601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":6,
      "vividness":13,
      "r":59,
      "g":6,
      "b":94,
      "hex":"#3b065e",
      "file_location":"assets/colors_organized/hue_24/brightness_06/2020040416395700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":7,
      "vividness":1,
      "r":107,
      "g":102,
      "b":109,
      "hex":"#6b666d",
      "file_location":"assets/colors_organized/hue_24/brightness_07/2020040416394000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":7,
      "vividness":2,
      "r":103,
      "g":95,
      "b":110,
      "hex":"#675f6e",
      "file_location":"assets/colors_organized/hue_24/brightness_07/2020040416394001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":7,
      "vividness":3,
      "r":101,
      "g":87,
      "b":110,
      "hex":"#65576e",
      "file_location":"assets/colors_organized/hue_24/brightness_07/2020040416394100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":7,
      "vividness":4,
      "r":97,
      "g":81,
      "b":110,
      "hex":"#61516e",
      "file_location":"assets/colors_organized/hue_24/brightness_07/2020040416394101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":7,
      "vividness":5,
      "r":95,
      "g":73,
      "b":111,
      "hex":"#5f496f",
      "file_location":"assets/colors_organized/hue_24/brightness_07/2020040416394200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":7,
      "vividness":6,
      "r":92,
      "g":66,
      "b":111,
      "hex":"#5c426f",
      "file_location":"assets/colors_organized/hue_24/brightness_07/2020040416394201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":7,
      "vividness":7,
      "r":88,
      "g":58,
      "b":110,
      "hex":"#583a6e",
      "file_location":"assets/colors_organized/hue_24/brightness_07/2020040416394300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":7,
      "vividness":8,
      "r":86,
      "g":50,
      "b":111,
      "hex":"#56326f",
      "file_location":"assets/colors_organized/hue_24/brightness_07/2020040416394400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":7,
      "vividness":9,
      "r":83,
      "g":44,
      "b":109,
      "hex":"#532c6d",
      "file_location":"assets/colors_organized/hue_24/brightness_07/2020040416394401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":7,
      "vividness":10,
      "r":79,
      "g":35,
      "b":110,
      "hex":"#4f236e",
      "file_location":"assets/colors_organized/hue_24/brightness_07/2020040416394500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":7,
      "vividness":11,
      "r":77,
      "g":30,
      "b":110,
      "hex":"#4d1e6e",
      "file_location":"assets/colors_organized/hue_24/brightness_07/2020040416394501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":7,
      "vividness":12,
      "r":74,
      "g":21,
      "b":109,
      "hex":"#4a156d",
      "file_location":"assets/colors_organized/hue_24/brightness_07/2020040416394600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":7,
      "vividness":13,
      "r":70,
      "g":13,
      "b":110,
      "hex":"#460d6e",
      "file_location":"assets/colors_organized/hue_24/brightness_07/2020040416394601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":7,
      "vividness":14,
      "r":68,
      "g":7,
      "b":110,
      "hex":"#44076e",
      "file_location":"assets/colors_organized/hue_24/brightness_07/2020040416394700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":8,
      "vividness":1,
      "r":121,
      "g":116,
      "b":123,
      "hex":"#79747b",
      "file_location":"assets/colors_organized/hue_24/brightness_08/2020040416391801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":8,
      "vividness":2,
      "r":119,
      "g":108,
      "b":124,
      "hex":"#776c7c",
      "file_location":"assets/colors_organized/hue_24/brightness_08/2020040416391900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":8,
      "vividness":3,
      "r":113,
      "g":99,
      "b":124,
      "hex":"#71637c",
      "file_location":"assets/colors_organized/hue_24/brightness_08/2020040416391901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":8,
      "vividness":4,
      "r":111,
      "g":92,
      "b":124,
      "hex":"#6f5c7c",
      "file_location":"assets/colors_organized/hue_24/brightness_08/2020040416392000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":8,
      "vividness":5,
      "r":108,
      "g":83,
      "b":123,
      "hex":"#6c537b",
      "file_location":"assets/colors_organized/hue_24/brightness_08/2020040416392001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":8,
      "vividness":6,
      "r":103,
      "g":73,
      "b":123,
      "hex":"#67497b",
      "file_location":"assets/colors_organized/hue_24/brightness_08/2020040416392100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":8,
      "vividness":7,
      "r":101,
      "g":66,
      "b":124,
      "hex":"#65427c",
      "file_location":"assets/colors_organized/hue_24/brightness_08/2020040416392101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":8,
      "vividness":8,
      "r":98,
      "g":57,
      "b":123,
      "hex":"#62397b",
      "file_location":"assets/colors_organized/hue_24/brightness_08/2020040416392200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":8,
      "vividness":9,
      "r":93,
      "g":49,
      "b":124,
      "hex":"#5d317c",
      "file_location":"assets/colors_organized/hue_24/brightness_08/2020040416392201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":8,
      "vividness":10,
      "r":90,
      "g":41,
      "b":123,
      "hex":"#5a297b",
      "file_location":"assets/colors_organized/hue_24/brightness_08/2020040416392300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":8,
      "vividness":11,
      "r":88,
      "g":33,
      "b":124,
      "hex":"#58217c",
      "file_location":"assets/colors_organized/hue_24/brightness_08/2020040416392301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":8,
      "vividness":12,
      "r":83,
      "g":23,
      "b":123,
      "hex":"#53177b",
      "file_location":"assets/colors_organized/hue_24/brightness_08/2020040416392400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":8,
      "vividness":13,
      "r":81,
      "g":15,
      "b":123,
      "hex":"#510f7b",
      "file_location":"assets/colors_organized/hue_24/brightness_08/2020040416392600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":8,
      "vividness":14,
      "r":77,
      "g":6,
      "b":124,
      "hex":"#4d067c",
      "file_location":"assets/colors_organized/hue_24/brightness_08/x.jpg"
   },
   {
      "hue":24,
      "brightness":9,
      "vividness":1,
      "r":136,
      "g":131,
      "b":138,
      "hex":"#88838a",
      "file_location":"assets/colors_organized/hue_24/brightness_09/2020040416390800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":9,
      "vividness":2,
      "r":132,
      "g":121,
      "b":138,
      "hex":"#84798a",
      "file_location":"assets/colors_organized/hue_24/brightness_09/2020040416390801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":9,
      "vividness":3,
      "r":127,
      "g":111,
      "b":138,
      "hex":"#7f6f8a",
      "file_location":"assets/colors_organized/hue_24/brightness_09/2020040416390900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":9,
      "vividness":4,
      "r":124,
      "g":102,
      "b":140,
      "hex":"#7c668c",
      "file_location":"assets/colors_organized/hue_24/brightness_09/2020040416390901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":9,
      "vividness":5,
      "r":120,
      "g":93,
      "b":138,
      "hex":"#785d8a",
      "file_location":"assets/colors_organized/hue_24/brightness_09/2020040416391000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":9,
      "vividness":6,
      "r":116,
      "g":83,
      "b":138,
      "hex":"#74538a",
      "file_location":"assets/colors_organized/hue_24/brightness_09/2020040416391001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":9,
      "vividness":7,
      "r":113,
      "g":74,
      "b":139,
      "hex":"#714a8b",
      "file_location":"assets/colors_organized/hue_24/brightness_09/2020040416391100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":9,
      "vividness":8,
      "r":109,
      "g":66,
      "b":138,
      "hex":"#6d428a",
      "file_location":"assets/colors_organized/hue_24/brightness_09/2020040416391101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":9,
      "vividness":9,
      "r":107,
      "g":55,
      "b":140,
      "hex":"#6b378c",
      "file_location":"assets/colors_organized/hue_24/brightness_09/2020040416391200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":9,
      "vividness":10,
      "r":101,
      "g":46,
      "b":139,
      "hex":"#652e8b",
      "file_location":"assets/colors_organized/hue_24/brightness_09/2020040416391201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":9,
      "vividness":11,
      "r":98,
      "g":37,
      "b":140,
      "hex":"#62258c",
      "file_location":"assets/colors_organized/hue_24/brightness_09/2020040416391300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":9,
      "vividness":12,
      "r":95,
      "g":27,
      "b":140,
      "hex":"#5f1b8c",
      "file_location":"assets/colors_organized/hue_24/brightness_09/2020040416391301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":9,
      "vividness":13,
      "r":91,
      "g":17,
      "b":138,
      "hex":"#5b118a",
      "file_location":"assets/colors_organized/hue_24/brightness_09/2020040416391400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":9,
      "vividness":14,
      "r":86,
      "g":9,
      "b":139,
      "hex":"#56098b",
      "file_location":"assets/colors_organized/hue_24/brightness_09/2020040416391401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":10,
      "vividness":1,
      "r":150,
      "g":144,
      "b":154,
      "hex":"#96909a",
      "file_location":"assets/colors_organized/hue_24/brightness_10/2020040416385700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":10,
      "vividness":2,
      "r":146,
      "g":134,
      "b":154,
      "hex":"#92869a",
      "file_location":"assets/colors_organized/hue_24/brightness_10/2020040416385701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":10,
      "vividness":3,
      "r":142,
      "g":123,
      "b":153,
      "hex":"#8e7b99",
      "file_location":"assets/colors_organized/hue_24/brightness_10/2020040416385800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":10,
      "vividness":4,
      "r":138,
      "g":113,
      "b":153,
      "hex":"#8a7199",
      "file_location":"assets/colors_organized/hue_24/brightness_10/2020040416385801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":10,
      "vividness":5,
      "r":133,
      "g":103,
      "b":153,
      "hex":"#856799",
      "file_location":"assets/colors_organized/hue_24/brightness_10/2020040416385900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":10,
      "vividness":6,
      "r":130,
      "g":91,
      "b":154,
      "hex":"#825b9a",
      "file_location":"assets/colors_organized/hue_24/brightness_10/2020040416390000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":10,
      "vividness":7,
      "r":125,
      "g":82,
      "b":154,
      "hex":"#7d529a",
      "file_location":"assets/colors_organized/hue_24/brightness_10/2020040416390001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":10,
      "vividness":8,
      "r":121,
      "g":72,
      "b":154,
      "hex":"#79489a",
      "file_location":"assets/colors_organized/hue_24/brightness_10/2020040416390100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":10,
      "vividness":9,
      "r":116,
      "g":61,
      "b":154,
      "hex":"#743d9a",
      "file_location":"assets/colors_organized/hue_24/brightness_10/2020040416390101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":10,
      "vividness":10,
      "r":113,
      "g":50,
      "b":154,
      "hex":"#71329a",
      "file_location":"assets/colors_organized/hue_24/brightness_10/2020040416390200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":10,
      "vividness":11,
      "r":109,
      "g":41,
      "b":154,
      "hex":"#6d299a",
      "file_location":"assets/colors_organized/hue_24/brightness_10/2020040416390201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":10,
      "vividness":12,
      "r":104,
      "g":30,
      "b":153,
      "hex":"#681e99",
      "file_location":"assets/colors_organized/hue_24/brightness_10/2020040416390300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":10,
      "vividness":13,
      "r":101,
      "g":19,
      "b":153,
      "hex":"#651399",
      "file_location":"assets/colors_organized/hue_24/brightness_10/2020040416390301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":10,
      "vividness":14,
      "r":97,
      "g":10,
      "b":153,
      "hex":"#610a99",
      "file_location":"assets/colors_organized/hue_24/brightness_10/2020040416390400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":11,
      "vividness":1,
      "r":164,
      "g":158,
      "b":168,
      "hex":"#a49ea8",
      "file_location":"assets/colors_organized/hue_24/brightness_11/2020040416384501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":11,
      "vividness":2,
      "r":161,
      "g":147,
      "b":170,
      "hex":"#a193aa",
      "file_location":"assets/colors_organized/hue_24/brightness_11/2020040416384600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":11,
      "vividness":3,
      "r":156,
      "g":135,
      "b":168,
      "hex":"#9c87a8",
      "file_location":"assets/colors_organized/hue_24/brightness_11/2020040416384700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":11,
      "vividness":4,
      "r":151,
      "g":124,
      "b":169,
      "hex":"#977ca9",
      "file_location":"assets/colors_organized/hue_24/brightness_11/2020040416384701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":11,
      "vividness":5,
      "r":147,
      "g":114,
      "b":169,
      "hex":"#9372a9",
      "file_location":"assets/colors_organized/hue_24/brightness_11/2020040416384800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":11,
      "vividness":6,
      "r":142,
      "g":101,
      "b":169,
      "hex":"#8e65a9",
      "file_location":"assets/colors_organized/hue_24/brightness_11/2020040416384801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":11,
      "vividness":7,
      "r":138,
      "g":89,
      "b":168,
      "hex":"#8a59a8",
      "file_location":"assets/colors_organized/hue_24/brightness_11/2020040416384900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":11,
      "vividness":8,
      "r":133,
      "g":78,
      "b":169,
      "hex":"#854ea9",
      "file_location":"assets/colors_organized/hue_24/brightness_11/2020040416384901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":11,
      "vividness":9,
      "r":129,
      "g":66,
      "b":170,
      "hex":"#8142aa",
      "file_location":"assets/colors_organized/hue_24/brightness_11/2020040416385000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":11,
      "vividness":10,
      "r":124,
      "g":56,
      "b":169,
      "hex":"#7c38a9",
      "file_location":"assets/colors_organized/hue_24/brightness_11/2020040416385100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":11,
      "vividness":11,
      "r":119,
      "g":45,
      "b":170,
      "hex":"#772daa",
      "file_location":"assets/colors_organized/hue_24/brightness_11/2020040416385101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":11,
      "vividness":12,
      "r":115,
      "g":33,
      "b":169,
      "hex":"#7321a9",
      "file_location":"assets/colors_organized/hue_24/brightness_11/2020040416385200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":11,
      "vividness":13,
      "r":110,
      "g":22,
      "b":170,
      "hex":"#6e16aa",
      "file_location":"assets/colors_organized/hue_24/brightness_11/2020040416385201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":11,
      "vividness":14,
      "r":106,
      "g":10,
      "b":169,
      "hex":"#6a0aa9",
      "file_location":"assets/colors_organized/hue_24/brightness_11/2020040416385300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":12,
      "vividness":1,
      "r":179,
      "g":173,
      "b":185,
      "hex":"#b3adb9",
      "file_location":"assets/colors_organized/hue_24/brightness_12/2020040416383401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":12,
      "vividness":2,
      "r":174,
      "g":160,
      "b":185,
      "hex":"#aea0b9",
      "file_location":"assets/colors_organized/hue_24/brightness_12/2020040416383500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":12,
      "vividness":3,
      "r":169,
      "g":147,
      "b":185,
      "hex":"#a993b9",
      "file_location":"assets/colors_organized/hue_24/brightness_12/2020040416383501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":12,
      "vividness":4,
      "r":163,
      "g":135,
      "b":184,
      "hex":"#a387b8",
      "file_location":"assets/colors_organized/hue_24/brightness_12/2020040416383600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":12,
      "vividness":5,
      "r":161,
      "g":123,
      "b":184,
      "hex":"#a17bb8",
      "file_location":"assets/colors_organized/hue_24/brightness_12/2020040416383601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":12,
      "vividness":6,
      "r":154,
      "g":111,
      "b":183,
      "hex":"#9a6fb7",
      "file_location":"assets/colors_organized/hue_24/brightness_12/2020040416383700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":12,
      "vividness":7,
      "r":150,
      "g":98,
      "b":183,
      "hex":"#9662b7",
      "file_location":"assets/colors_organized/hue_24/brightness_12/2020040416383800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":12,
      "vividness":8,
      "r":146,
      "g":86,
      "b":184,
      "hex":"#9256b8",
      "file_location":"assets/colors_organized/hue_24/brightness_12/2020040416383801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":12,
      "vividness":9,
      "r":140,
      "g":74,
      "b":184,
      "hex":"#8c4ab8",
      "file_location":"assets/colors_organized/hue_24/brightness_12/2020040416383900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":12,
      "vividness":10,
      "r":135,
      "g":61,
      "b":184,
      "hex":"#873db8",
      "file_location":"assets/colors_organized/hue_24/brightness_12/2020040416383901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":12,
      "vividness":11,
      "r":130,
      "g":48,
      "b":184,
      "hex":"#8230b8",
      "file_location":"assets/colors_organized/hue_24/brightness_12/2020040416384000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":12,
      "vividness":12,
      "r":124,
      "g":36,
      "b":184,
      "hex":"#7c24b8",
      "file_location":"assets/colors_organized/hue_24/brightness_12/2020040416384001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":12,
      "vividness":13,
      "r":119,
      "g":23,
      "b":183,
      "hex":"#7717b7",
      "file_location":"assets/colors_organized/hue_24/brightness_12/2020040416384100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":12,
      "vividness":14,
      "r":115,
      "g":13,
      "b":183,
      "hex":"#730db7",
      "file_location":"assets/colors_organized/hue_24/brightness_12/2020040416384101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":13,
      "vividness":1,
      "r":194,
      "g":186,
      "b":199,
      "hex":"#c2bac7",
      "file_location":"assets/colors_organized/hue_24/brightness_13/2020040416382400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":13,
      "vividness":2,
      "r":188,
      "g":174,
      "b":199,
      "hex":"#bcaec7",
      "file_location":"assets/colors_organized/hue_24/brightness_13/2020040416382401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":13,
      "vividness":3,
      "r":184,
      "g":159,
      "b":199,
      "hex":"#b89fc7",
      "file_location":"assets/colors_organized/hue_24/brightness_13/2020040416382500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":13,
      "vividness":4,
      "r":179,
      "g":146,
      "b":199,
      "hex":"#b392c7",
      "file_location":"assets/colors_organized/hue_24/brightness_13/2020040416382501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":13,
      "vividness":5,
      "r":174,
      "g":133,
      "b":199,
      "hex":"#ae85c7",
      "file_location":"assets/colors_organized/hue_24/brightness_13/2020040416382600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":13,
      "vividness":6,
      "r":166,
      "g":119,
      "b":199,
      "hex":"#a677c7",
      "file_location":"assets/colors_organized/hue_24/brightness_13/2020040416382601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":13,
      "vividness":7,
      "r":161,
      "g":106,
      "b":199,
      "hex":"#a16ac7",
      "file_location":"assets/colors_organized/hue_24/brightness_13/2020040416382700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":13,
      "vividness":8,
      "r":156,
      "g":93,
      "b":198,
      "hex":"#9c5dc6",
      "file_location":"assets/colors_organized/hue_24/brightness_13/2020040416382701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":13,
      "vividness":9,
      "r":150,
      "g":79,
      "b":199,
      "hex":"#964fc7",
      "file_location":"assets/colors_organized/hue_24/brightness_13/2020040416382800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":13,
      "vividness":10,
      "r":146,
      "g":66,
      "b":199,
      "hex":"#9242c7",
      "file_location":"assets/colors_organized/hue_24/brightness_13/2020040416382801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":13,
      "vividness":11,
      "r":141,
      "g":53,
      "b":199,
      "hex":"#8d35c7",
      "file_location":"assets/colors_organized/hue_24/brightness_13/2020040416382900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":13,
      "vividness":12,
      "r":135,
      "g":39,
      "b":199,
      "hex":"#8727c7",
      "file_location":"assets/colors_organized/hue_24/brightness_13/2020040416382901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":13,
      "vividness":13,
      "r":130,
      "g":26,
      "b":199,
      "hex":"#821ac7",
      "file_location":"assets/colors_organized/hue_24/brightness_13/2020040416383000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":13,
      "vividness":14,
      "r":124,
      "g":12,
      "b":198,
      "hex":"#7c0cc6",
      "file_location":"assets/colors_organized/hue_24/brightness_13/2020040416383001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":14,
      "vividness":1,
      "r":208,
      "g":200,
      "b":215,
      "hex":"#d0c8d7",
      "file_location":"assets/colors_organized/hue_24/brightness_14/2020040416381301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":14,
      "vividness":2,
      "r":204,
      "g":185,
      "b":213,
      "hex":"#ccb9d5",
      "file_location":"assets/colors_organized/hue_24/brightness_14/2020040416381400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":14,
      "vividness":3,
      "r":197,
      "g":172,
      "b":214,
      "hex":"#c5acd6",
      "file_location":"assets/colors_organized/hue_24/brightness_14/2020040416381401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":14,
      "vividness":4,
      "r":191,
      "g":158,
      "b":215,
      "hex":"#bf9ed7",
      "file_location":"assets/colors_organized/hue_24/brightness_14/2020040416381500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":14,
      "vividness":5,
      "r":185,
      "g":144,
      "b":214,
      "hex":"#b990d6",
      "file_location":"assets/colors_organized/hue_24/brightness_14/2020040416381501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":14,
      "vividness":6,
      "r":181,
      "g":129,
      "b":214,
      "hex":"#b581d6",
      "file_location":"assets/colors_organized/hue_24/brightness_14/2020040416381600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":14,
      "vividness":7,
      "r":174,
      "g":114,
      "b":214,
      "hex":"#ae72d6",
      "file_location":"assets/colors_organized/hue_24/brightness_14/2020040416381601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":14,
      "vividness":8,
      "r":168,
      "g":99,
      "b":215,
      "hex":"#a863d7",
      "file_location":"assets/colors_organized/hue_24/brightness_14/2020040416381700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":14,
      "vividness":9,
      "r":164,
      "g":85,
      "b":213,
      "hex":"#a455d5",
      "file_location":"assets/colors_organized/hue_24/brightness_14/2020040416381701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":14,
      "vividness":10,
      "r":156,
      "g":71,
      "b":214,
      "hex":"#9c47d6",
      "file_location":"assets/colors_organized/hue_24/brightness_14/2020040416381800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":14,
      "vividness":11,
      "r":151,
      "g":57,
      "b":215,
      "hex":"#9739d7",
      "file_location":"assets/colors_organized/hue_24/brightness_14/2020040416381801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":14,
      "vividness":12,
      "r":145,
      "g":41,
      "b":214,
      "hex":"#9129d6",
      "file_location":"assets/colors_organized/hue_24/brightness_14/2020040416381900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":14,
      "vividness":13,
      "r":139,
      "g":27,
      "b":213,
      "hex":"#8b1bd5",
      "file_location":"assets/colors_organized/hue_24/brightness_14/2020040416381901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":14,
      "vividness":14,
      "r":134,
      "g":13,
      "b":214,
      "hex":"#860dd6",
      "file_location":"assets/colors_organized/hue_24/brightness_14/2020040416382000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":15,
      "vividness":1,
      "r":223,
      "g":215,
      "b":230,
      "hex":"#dfd7e6",
      "file_location":"assets/colors_organized/hue_24/brightness_15/2020040416380301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":15,
      "vividness":2,
      "r":218,
      "g":199,
      "b":229,
      "hex":"#dac7e5",
      "file_location":"assets/colors_organized/hue_24/brightness_15/2020040416380400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":15,
      "vividness":3,
      "r":211,
      "g":184,
      "b":229,
      "hex":"#d3b8e5",
      "file_location":"assets/colors_organized/hue_24/brightness_15/2020040416380401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":15,
      "vividness":4,
      "r":206,
      "g":168,
      "b":229,
      "hex":"#cea8e5",
      "file_location":"assets/colors_organized/hue_24/brightness_15/2020040416380500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":15,
      "vividness":5,
      "r":199,
      "g":152,
      "b":230,
      "hex":"#c798e6",
      "file_location":"assets/colors_organized/hue_24/brightness_15/2020040416380501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":15,
      "vividness":6,
      "r":192,
      "g":137,
      "b":230,
      "hex":"#c089e6",
      "file_location":"assets/colors_organized/hue_24/brightness_15/2020040416380600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":15,
      "vividness":7,
      "r":185,
      "g":122,
      "b":229,
      "hex":"#b97ae5",
      "file_location":"assets/colors_organized/hue_24/brightness_15/2020040416380601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":15,
      "vividness":8,
      "r":181,
      "g":107,
      "b":230,
      "hex":"#b56be6",
      "file_location":"assets/colors_organized/hue_24/brightness_15/2020040416380700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":15,
      "vividness":9,
      "r":174,
      "g":92,
      "b":229,
      "hex":"#ae5ce5",
      "file_location":"assets/colors_organized/hue_24/brightness_15/2020040416380701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":15,
      "vividness":10,
      "r":169,
      "g":76,
      "b":229,
      "hex":"#a94ce5",
      "file_location":"assets/colors_organized/hue_24/brightness_15/2020040416380800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":15,
      "vividness":11,
      "r":162,
      "g":61,
      "b":229,
      "hex":"#a23de5",
      "file_location":"assets/colors_organized/hue_24/brightness_15/2020040416380801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":15,
      "vividness":12,
      "r":156,
      "g":44,
      "b":230,
      "hex":"#9c2ce6",
      "file_location":"assets/colors_organized/hue_24/brightness_15/2020040416380900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":15,
      "vividness":13,
      "r":148,
      "g":30,
      "b":230,
      "hex":"#941ee6",
      "file_location":"assets/colors_organized/hue_24/brightness_15/2020040416380901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":24,
      "brightness":15,
      "vividness":14,
      "r":143,
      "g":14,
      "b":229,
      "hex":"#8f0ee5",
      "file_location":"assets/colors_organized/hue_24/brightness_15/2020040416381000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":1,
      "vividness":1,
      "r":20,
      "g":15,
      "b":21,
      "hex":"#140f15",
      "file_location":"assets/colors_organized/hue_25/brightness_01/2020040416433500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":1,
      "vividness":2,
      "r":19,
      "g":12,
      "b":19,
      "hex":"#130c13",
      "file_location":"assets/colors_organized/hue_25/brightness_01/2020040416433501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":1,
      "vividness":3,
      "r":17,
      "g":9,
      "b":20,
      "hex":"#110914",
      "file_location":"assets/colors_organized/hue_25/brightness_01/2020040416433700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":1,
      "vividness":4,
      "r":17,
      "g":6,
      "b":20,
      "hex":"#110614",
      "file_location":"assets/colors_organized/hue_25/brightness_01/2020040416433800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":1,
      "vividness":5,
      "r":17,
      "g":3,
      "b":20,
      "hex":"#110314",
      "file_location":"assets/colors_organized/hue_25/brightness_01/2020040416433901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":1,
      "vividness":6,
      "r":14,
      "g":3,
      "b":19,
      "hex":"#0e0313",
      "file_location":"assets/colors_organized/hue_25/brightness_01/2020040416433902-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":1,
      "vividness":7,
      "r":14,
      "g":0,
      "b":18,
      "hex":"#0e0012",
      "file_location":"assets/colors_organized/hue_25/brightness_01/2020040416434000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":2,
      "vividness":1,
      "r":33,
      "g":28,
      "b":34,
      "hex":"#211c22",
      "file_location":"assets/colors_organized/hue_25/brightness_02/2020040416432500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":2,
      "vividness":2,
      "r":34,
      "g":24,
      "b":33,
      "hex":"#221821",
      "file_location":"assets/colors_organized/hue_25/brightness_02/2020040416432501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":2,
      "vividness":3,
      "r":31,
      "g":23,
      "b":34,
      "hex":"#1f1722",
      "file_location":"assets/colors_organized/hue_25/brightness_02/2020040416432502-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":2,
      "vividness":4,
      "r":32,
      "g":21,
      "b":35,
      "hex":"#201523",
      "file_location":"assets/colors_organized/hue_25/brightness_02/2020040416432600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":2,
      "vividness":5,
      "r":32,
      "g":18,
      "b":35,
      "hex":"#201223",
      "file_location":"assets/colors_organized/hue_25/brightness_02/2020040416432601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":2,
      "vividness":6,
      "r":30,
      "g":15,
      "b":34,
      "hex":"#1e0f22",
      "file_location":"assets/colors_organized/hue_25/brightness_02/2020040416432700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":2,
      "vividness":7,
      "r":30,
      "g":12,
      "b":34,
      "hex":"#1e0c22",
      "file_location":"assets/colors_organized/hue_25/brightness_02/2020040416432800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":2,
      "vividness":8,
      "r":29,
      "g":9,
      "b":34,
      "hex":"#1d0922",
      "file_location":"assets/colors_organized/hue_25/brightness_02/2020040416432801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":2,
      "vividness":9,
      "r":30,
      "g":7,
      "b":35,
      "hex":"#1e0723",
      "file_location":"assets/colors_organized/hue_25/brightness_02/2020040416432900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":2,
      "vividness":10,
      "r":29,
      "g":2,
      "b":33,
      "hex":"#1d0221",
      "file_location":"assets/colors_organized/hue_25/brightness_02/2020040416432901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":2,
      "vividness":11,
      "r":28,
      "g":3,
      "b":35,
      "hex":"#1c0323",
      "file_location":"assets/colors_organized/hue_25/brightness_02/2020040416433000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":3,
      "vividness":1,
      "r":48,
      "g":46,
      "b":49,
      "hex":"#302e31",
      "file_location":"assets/colors_organized/hue_25/brightness_03/2020040416431401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":3,
      "vividness":2,
      "r":48,
      "g":43,
      "b":50,
      "hex":"#302b32",
      "file_location":"assets/colors_organized/hue_25/brightness_03/2020040416431500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":3,
      "vividness":3,
      "r":47,
      "g":39,
      "b":50,
      "hex":"#2f2732",
      "file_location":"assets/colors_organized/hue_25/brightness_03/2020040416431501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":3,
      "vividness":4,
      "r":46,
      "g":35,
      "b":49,
      "hex":"#2e2331",
      "file_location":"assets/colors_organized/hue_25/brightness_03/2020040416431600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":3,
      "vividness":5,
      "r":46,
      "g":33,
      "b":50,
      "hex":"#2e2132",
      "file_location":"assets/colors_organized/hue_25/brightness_03/2020040416431601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":3,
      "vividness":6,
      "r":45,
      "g":30,
      "b":49,
      "hex":"#2d1e31",
      "file_location":"assets/colors_organized/hue_25/brightness_03/2020040416431602-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":3,
      "vividness":7,
      "r":45,
      "g":27,
      "b":49,
      "hex":"#2d1b31",
      "file_location":"assets/colors_organized/hue_25/brightness_03/2020040416431700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":3,
      "vividness":8,
      "r":43,
      "g":23,
      "b":50,
      "hex":"#2b1732",
      "file_location":"assets/colors_organized/hue_25/brightness_03/2020040416431701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":3,
      "vividness":9,
      "r":43,
      "g":20,
      "b":49,
      "hex":"#2b1431",
      "file_location":"assets/colors_organized/hue_25/brightness_03/2020040416431800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":3,
      "vividness":10,
      "r":42,
      "g":15,
      "b":50,
      "hex":"#2a0f32",
      "file_location":"assets/colors_organized/hue_25/brightness_03/2020040416431801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":3,
      "vividness":11,
      "r":40,
      "g":12,
      "b":50,
      "hex":"#280c32",
      "file_location":"assets/colors_organized/hue_25/brightness_03/2020040416431900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":3,
      "vividness":12,
      "r":42,
      "g":10,
      "b":49,
      "hex":"#2a0a31",
      "file_location":"assets/colors_organized/hue_25/brightness_03/2020040416431901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":3,
      "vividness":13,
      "r":40,
      "g":7,
      "b":50,
      "hex":"#280732",
      "file_location":"assets/colors_organized/hue_25/brightness_03/2020040416432000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":3,
      "vividness":14,
      "r":40,
      "g":2,
      "b":49,
      "hex":"#280231",
      "file_location":"assets/colors_organized/hue_25/brightness_03/2020040416432001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":4,
      "vividness":1,
      "r":64,
      "g":59,
      "b":65,
      "hex":"#403b41",
      "file_location":"assets/colors_organized/hue_25/brightness_04/2020040416430200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":4,
      "vividness":2,
      "r":63,
      "g":56,
      "b":64,
      "hex":"#3f3840",
      "file_location":"assets/colors_organized/hue_25/brightness_04/2020040416430201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":4,
      "vividness":3,
      "r":62,
      "g":51,
      "b":65,
      "hex":"#3e3341",
      "file_location":"assets/colors_organized/hue_25/brightness_04/2020040416430202-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":4,
      "vividness":4,
      "r":60,
      "g":47,
      "b":65,
      "hex":"#3c2f41",
      "file_location":"assets/colors_organized/hue_25/brightness_04/2020040416430300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":4,
      "vividness":5,
      "r":60,
      "g":42,
      "b":64,
      "hex":"#3c2a40",
      "file_location":"assets/colors_organized/hue_25/brightness_04/2020040416430301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":4,
      "vividness":6,
      "r":59,
      "g":39,
      "b":66,
      "hex":"#3b2742",
      "file_location":"assets/colors_organized/hue_25/brightness_04/2020040416430400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":4,
      "vividness":7,
      "r":59,
      "g":34,
      "b":64,
      "hex":"#3b2240",
      "file_location":"assets/colors_organized/hue_25/brightness_04/2020040416430401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":4,
      "vividness":8,
      "r":57,
      "g":30,
      "b":65,
      "hex":"#391e41",
      "file_location":"assets/colors_organized/hue_25/brightness_04/2020040416430500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":4,
      "vividness":9,
      "r":57,
      "g":27,
      "b":65,
      "hex":"#391b41",
      "file_location":"assets/colors_organized/hue_25/brightness_04/2020040416430501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":4,
      "vividness":10,
      "r":55,
      "g":22,
      "b":65,
      "hex":"#371641",
      "file_location":"assets/colors_organized/hue_25/brightness_04/2020040416430600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":4,
      "vividness":11,
      "r":55,
      "g":17,
      "b":64,
      "hex":"#371140",
      "file_location":"assets/colors_organized/hue_25/brightness_04/2020040416430601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":4,
      "vividness":12,
      "r":53,
      "g":13,
      "b":65,
      "hex":"#350d41",
      "file_location":"assets/colors_organized/hue_25/brightness_04/2020040416430700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":4,
      "vividness":13,
      "r":52,
      "g":7,
      "b":64,
      "hex":"#340740",
      "file_location":"assets/colors_organized/hue_25/brightness_04/2020040416430701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":4,
      "vividness":14,
      "r":53,
      "g":3,
      "b":66,
      "hex":"#350342",
      "file_location":"assets/colors_organized/hue_25/brightness_04/2020040416430800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":5,
      "vividness":1,
      "r":79,
      "g":74,
      "b":80,
      "hex":"#4f4a50",
      "file_location":"assets/colors_organized/hue_25/brightness_05/2020040416425200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":5,
      "vividness":2,
      "r":77,
      "g":69,
      "b":80,
      "hex":"#4d4550",
      "file_location":"assets/colors_organized/hue_25/brightness_05/2020040416425201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":5,
      "vividness":3,
      "r":76,
      "g":63,
      "b":80,
      "hex":"#4c3f50",
      "file_location":"assets/colors_organized/hue_25/brightness_05/2020040416425300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":5,
      "vividness":4,
      "r":75,
      "g":57,
      "b":79,
      "hex":"#4b394f",
      "file_location":"assets/colors_organized/hue_25/brightness_05/2020040416425301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":5,
      "vividness":5,
      "r":73,
      "g":53,
      "b":80,
      "hex":"#493550",
      "file_location":"assets/colors_organized/hue_25/brightness_05/2020040416425400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":5,
      "vividness":6,
      "r":73,
      "g":48,
      "b":80,
      "hex":"#493050",
      "file_location":"assets/colors_organized/hue_25/brightness_05/2020040416425401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":5,
      "vividness":7,
      "r":72,
      "g":42,
      "b":80,
      "hex":"#482a50",
      "file_location":"assets/colors_organized/hue_25/brightness_05/2020040416425500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":5,
      "vividness":8,
      "r":70,
      "g":37,
      "b":80,
      "hex":"#462550",
      "file_location":"assets/colors_organized/hue_25/brightness_05/2020040416425501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":5,
      "vividness":9,
      "r":69,
      "g":31,
      "b":80,
      "hex":"#451f50",
      "file_location":"assets/colors_organized/hue_25/brightness_05/2020040416425600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":5,
      "vividness":10,
      "r":69,
      "g":26,
      "b":79,
      "hex":"#451a4f",
      "file_location":"assets/colors_organized/hue_25/brightness_05/2020040416425601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":5,
      "vividness":11,
      "r":67,
      "g":22,
      "b":79,
      "hex":"#43164f",
      "file_location":"assets/colors_organized/hue_25/brightness_05/2020040416425700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":5,
      "vividness":12,
      "r":67,
      "g":15,
      "b":81,
      "hex":"#430f51",
      "file_location":"assets/colors_organized/hue_25/brightness_05/2020040416425701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":5,
      "vividness":13,
      "r":65,
      "g":9,
      "b":80,
      "hex":"#410950",
      "file_location":"assets/colors_organized/hue_25/brightness_05/2020040416425702-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":5,
      "vividness":14,
      "r":64,
      "g":6,
      "b":80,
      "hex":"#400650",
      "file_location":"assets/colors_organized/hue_25/brightness_05/2020040416425800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":6,
      "vividness":1,
      "r":93,
      "g":88,
      "b":95,
      "hex":"#5d585f",
      "file_location":"assets/colors_organized/hue_25/brightness_06/2020040416424201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":6,
      "vividness":2,
      "r":92,
      "g":81,
      "b":95,
      "hex":"#5c515f",
      "file_location":"assets/colors_organized/hue_25/brightness_06/2020040416424300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":6,
      "vividness":3,
      "r":90,
      "g":75,
      "b":94,
      "hex":"#5a4b5e",
      "file_location":"assets/colors_organized/hue_25/brightness_06/2020040416424301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":6,
      "vividness":4,
      "r":89,
      "g":69,
      "b":94,
      "hex":"#59455e",
      "file_location":"assets/colors_organized/hue_25/brightness_06/2020040416424302-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":6,
      "vividness":5,
      "r":88,
      "g":63,
      "b":95,
      "hex":"#583f5f",
      "file_location":"assets/colors_organized/hue_25/brightness_06/2020040416424400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":6,
      "vividness":6,
      "r":87,
      "g":57,
      "b":95,
      "hex":"#57395f",
      "file_location":"assets/colors_organized/hue_25/brightness_06/2020040416424401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":6,
      "vividness":7,
      "r":86,
      "g":50,
      "b":94,
      "hex":"#56325e",
      "file_location":"assets/colors_organized/hue_25/brightness_06/2020040416424500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":6,
      "vividness":8,
      "r":83,
      "g":43,
      "b":95,
      "hex":"#532b5f",
      "file_location":"assets/colors_organized/hue_25/brightness_06/2020040416424501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":6,
      "vividness":9,
      "r":83,
      "g":38,
      "b":95,
      "hex":"#53265f",
      "file_location":"assets/colors_organized/hue_25/brightness_06/2020040416424600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":6,
      "vividness":10,
      "r":81,
      "g":31,
      "b":94,
      "hex":"#511f5e",
      "file_location":"assets/colors_organized/hue_25/brightness_06/2020040416424601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":6,
      "vividness":11,
      "r":80,
      "g":24,
      "b":95,
      "hex":"#50185f",
      "file_location":"assets/colors_organized/hue_25/brightness_06/2020040416424700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":6,
      "vividness":12,
      "r":78,
      "g":20,
      "b":96,
      "hex":"#4e1460",
      "file_location":"assets/colors_organized/hue_25/brightness_06/2020040416424701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":6,
      "vividness":13,
      "r":77,
      "g":12,
      "b":94,
      "hex":"#4d0c5e",
      "file_location":"assets/colors_organized/hue_25/brightness_06/2020040416424800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":6,
      "vividness":14,
      "r":77,
      "g":7,
      "b":95,
      "hex":"#4d075f",
      "file_location":"assets/colors_organized/hue_25/brightness_06/2020040416424801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":7,
      "vividness":1,
      "r":108,
      "g":103,
      "b":110,
      "hex":"#6c676e",
      "file_location":"assets/colors_organized/hue_25/brightness_07/2020040416423201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":7,
      "vividness":2,
      "r":107,
      "g":95,
      "b":109,
      "hex":"#6b5f6d",
      "file_location":"assets/colors_organized/hue_25/brightness_07/2020040416423202-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":7,
      "vividness":3,
      "r":105,
      "g":87,
      "b":109,
      "hex":"#69576d",
      "file_location":"assets/colors_organized/hue_25/brightness_07/2020040416423300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":7,
      "vividness":4,
      "r":103,
      "g":80,
      "b":109,
      "hex":"#67506d",
      "file_location":"assets/colors_organized/hue_25/brightness_07/2020040416423301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":7,
      "vividness":5,
      "r":101,
      "g":74,
      "b":109,
      "hex":"#654a6d",
      "file_location":"assets/colors_organized/hue_25/brightness_07/2020040416423400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":7,
      "vividness":6,
      "r":101,
      "g":65,
      "b":109,
      "hex":"#65416d",
      "file_location":"assets/colors_organized/hue_25/brightness_07/2020040416423401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":7,
      "vividness":7,
      "r":98,
      "g":58,
      "b":110,
      "hex":"#623a6e",
      "file_location":"assets/colors_organized/hue_25/brightness_07/2020040416423500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":7,
      "vividness":8,
      "r":98,
      "g":50,
      "b":110,
      "hex":"#62326e",
      "file_location":"assets/colors_organized/hue_25/brightness_07/2020040416423501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":7,
      "vividness":9,
      "r":96,
      "g":44,
      "b":110,
      "hex":"#602c6e",
      "file_location":"assets/colors_organized/hue_25/brightness_07/2020040416423600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":7,
      "vividness":10,
      "r":94,
      "g":36,
      "b":110,
      "hex":"#5e246e",
      "file_location":"assets/colors_organized/hue_25/brightness_07/2020040416423601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":7,
      "vividness":11,
      "r":93,
      "g":30,
      "b":109,
      "hex":"#5d1e6d",
      "file_location":"assets/colors_organized/hue_25/brightness_07/2020040416423700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":7,
      "vividness":12,
      "r":92,
      "g":22,
      "b":110,
      "hex":"#5c166e",
      "file_location":"assets/colors_organized/hue_25/brightness_07/2020040416423701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":7,
      "vividness":13,
      "r":90,
      "g":12,
      "b":111,
      "hex":"#5a0c6f",
      "file_location":"assets/colors_organized/hue_25/brightness_07/2020040416423800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":7,
      "vividness":14,
      "r":89,
      "g":6,
      "b":110,
      "hex":"#59066e",
      "file_location":"assets/colors_organized/hue_25/brightness_07/2020040416423801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":8,
      "vividness":1,
      "r":123,
      "g":116,
      "b":124,
      "hex":"#7b747c",
      "file_location":"assets/colors_organized/hue_25/brightness_08/2020040416422101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":8,
      "vividness":2,
      "r":121,
      "g":108,
      "b":125,
      "hex":"#796c7d",
      "file_location":"assets/colors_organized/hue_25/brightness_08/2020040416422102-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":8,
      "vividness":3,
      "r":119,
      "g":99,
      "b":124,
      "hex":"#77637c",
      "file_location":"assets/colors_organized/hue_25/brightness_08/2020040416422200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":8,
      "vividness":4,
      "r":118,
      "g":91,
      "b":124,
      "hex":"#765b7c",
      "file_location":"assets/colors_organized/hue_25/brightness_08/2020040416422201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":8,
      "vividness":5,
      "r":115,
      "g":83,
      "b":124,
      "hex":"#73537c",
      "file_location":"assets/colors_organized/hue_25/brightness_08/2020040416422300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":8,
      "vividness":6,
      "r":113,
      "g":73,
      "b":123,
      "hex":"#71497b",
      "file_location":"assets/colors_organized/hue_25/brightness_08/2020040416422301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":8,
      "vividness":7,
      "r":113,
      "g":65,
      "b":123,
      "hex":"#71417b",
      "file_location":"assets/colors_organized/hue_25/brightness_08/2020040416422400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":8,
      "vividness":8,
      "r":110,
      "g":58,
      "b":124,
      "hex":"#6e3a7c",
      "file_location":"assets/colors_organized/hue_25/brightness_08/2020040416422500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":8,
      "vividness":9,
      "r":109,
      "g":49,
      "b":123,
      "hex":"#6d317b",
      "file_location":"assets/colors_organized/hue_25/brightness_08/2020040416422501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":8,
      "vividness":10,
      "r":108,
      "g":40,
      "b":123,
      "hex":"#6c287b",
      "file_location":"assets/colors_organized/hue_25/brightness_08/2020040416422600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":8,
      "vividness":11,
      "r":106,
      "g":33,
      "b":123,
      "hex":"#6a217b",
      "file_location":"assets/colors_organized/hue_25/brightness_08/2020040416422601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":8,
      "vividness":12,
      "r":103,
      "g":22,
      "b":124,
      "hex":"#67167c",
      "file_location":"assets/colors_organized/hue_25/brightness_08/2020040416422700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":8,
      "vividness":13,
      "r":102,
      "g":14,
      "b":124,
      "hex":"#660e7c",
      "file_location":"assets/colors_organized/hue_25/brightness_08/2020040416422701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":8,
      "vividness":14,
      "r":101,
      "g":6,
      "b":124,
      "hex":"#65067c",
      "file_location":"assets/colors_organized/hue_25/brightness_08/2020040416422800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":9,
      "vividness":1,
      "r":138,
      "g":131,
      "b":139,
      "hex":"#8a838b",
      "file_location":"assets/colors_organized/hue_25/brightness_09/2020040416420900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":9,
      "vividness":2,
      "r":136,
      "g":122,
      "b":139,
      "hex":"#887a8b",
      "file_location":"assets/colors_organized/hue_25/brightness_09/2020040416420901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":9,
      "vividness":3,
      "r":134,
      "g":111,
      "b":139,
      "hex":"#866f8b",
      "file_location":"assets/colors_organized/hue_25/brightness_09/2020040416421000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":9,
      "vividness":4,
      "r":132,
      "g":102,
      "b":138,
      "hex":"#84668a",
      "file_location":"assets/colors_organized/hue_25/brightness_09/2020040416421001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":9,
      "vividness":5,
      "r":130,
      "g":92,
      "b":139,
      "hex":"#825c8b",
      "file_location":"assets/colors_organized/hue_25/brightness_09/2020040416421100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":9,
      "vividness":6,
      "r":128,
      "g":83,
      "b":138,
      "hex":"#80538a",
      "file_location":"assets/colors_organized/hue_25/brightness_09/2020040416421101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":9,
      "vividness":7,
      "r":126,
      "g":74,
      "b":140,
      "hex":"#7e4a8c",
      "file_location":"assets/colors_organized/hue_25/brightness_09/2020040416421200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":9,
      "vividness":8,
      "r":123,
      "g":65,
      "b":139,
      "hex":"#7b418b",
      "file_location":"assets/colors_organized/hue_25/brightness_09/2020040416421201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":9,
      "vividness":9,
      "r":123,
      "g":55,
      "b":140,
      "hex":"#7b378c",
      "file_location":"assets/colors_organized/hue_25/brightness_09/2020040416421300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":9,
      "vividness":10,
      "r":121,
      "g":46,
      "b":139,
      "hex":"#792e8b",
      "file_location":"assets/colors_organized/hue_25/brightness_09/2020040416421301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":9,
      "vividness":11,
      "r":119,
      "g":37,
      "b":139,
      "hex":"#77258b",
      "file_location":"assets/colors_organized/hue_25/brightness_09/2020040416421400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":9,
      "vividness":12,
      "r":116,
      "g":26,
      "b":139,
      "hex":"#741a8b",
      "file_location":"assets/colors_organized/hue_25/brightness_09/2020040416421401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":9,
      "vividness":13,
      "r":115,
      "g":17,
      "b":138,
      "hex":"#73118a",
      "file_location":"assets/colors_organized/hue_25/brightness_09/2020040416421500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":9,
      "vividness":14,
      "r":112,
      "g":10,
      "b":138,
      "hex":"#700a8a",
      "file_location":"assets/colors_organized/hue_25/brightness_09/2020040416421501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":10,
      "vividness":1,
      "r":152,
      "g":144,
      "b":155,
      "hex":"#98909b",
      "file_location":"assets/colors_organized/hue_25/brightness_10/2020040416415901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":10,
      "vividness":2,
      "r":149,
      "g":134,
      "b":153,
      "hex":"#958699",
      "file_location":"assets/colors_organized/hue_25/brightness_10/2020040416420000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":10,
      "vividness":3,
      "r":148,
      "g":123,
      "b":153,
      "hex":"#947b99",
      "file_location":"assets/colors_organized/hue_25/brightness_10/2020040416420001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":10,
      "vividness":4,
      "r":146,
      "g":114,
      "b":155,
      "hex":"#92729b",
      "file_location":"assets/colors_organized/hue_25/brightness_10/2020040416420002-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":10,
      "vividness":5,
      "r":143,
      "g":103,
      "b":155,
      "hex":"#8f679b",
      "file_location":"assets/colors_organized/hue_25/brightness_10/2020040416420100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":10,
      "vividness":6,
      "r":142,
      "g":92,
      "b":155,
      "hex":"#8e5c9b",
      "file_location":"assets/colors_organized/hue_25/brightness_10/2020040416420101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":10,
      "vividness":7,
      "r":140,
      "g":83,
      "b":154,
      "hex":"#8c539a",
      "file_location":"assets/colors_organized/hue_25/brightness_10/2020040416420200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":10,
      "vividness":8,
      "r":137,
      "g":72,
      "b":154,
      "hex":"#89489a",
      "file_location":"assets/colors_organized/hue_25/brightness_10/2020040416420201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":10,
      "vividness":9,
      "r":136,
      "g":61,
      "b":154,
      "hex":"#883d9a",
      "file_location":"assets/colors_organized/hue_25/brightness_10/2020040416420300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":10,
      "vividness":10,
      "r":133,
      "g":50,
      "b":154,
      "hex":"#85329a",
      "file_location":"assets/colors_organized/hue_25/brightness_10/2020040416420301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":10,
      "vividness":11,
      "r":131,
      "g":41,
      "b":154,
      "hex":"#83299a",
      "file_location":"assets/colors_organized/hue_25/brightness_10/2020040416420400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":10,
      "vividness":12,
      "r":130,
      "g":30,
      "b":154,
      "hex":"#821e9a",
      "file_location":"assets/colors_organized/hue_25/brightness_10/2020040416420401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":10,
      "vividness":13,
      "r":127,
      "g":19,
      "b":154,
      "hex":"#7f139a",
      "file_location":"assets/colors_organized/hue_25/brightness_10/2020040416420500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":10,
      "vividness":14,
      "r":124,
      "g":9,
      "b":154,
      "hex":"#7c099a",
      "file_location":"assets/colors_organized/hue_25/brightness_10/2020040416420501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":11,
      "vividness":1,
      "r":166,
      "g":158,
      "b":169,
      "hex":"#a69ea9",
      "file_location":"assets/colors_organized/hue_25/brightness_11/2020040416414901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":11,
      "vividness":2,
      "r":165,
      "g":147,
      "b":169,
      "hex":"#a593a9",
      "file_location":"assets/colors_organized/hue_25/brightness_11/2020040416415000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":11,
      "vividness":3,
      "r":162,
      "g":135,
      "b":168,
      "hex":"#a287a8",
      "file_location":"assets/colors_organized/hue_25/brightness_11/2020040416415001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":11,
      "vividness":4,
      "r":160,
      "g":124,
      "b":168,
      "hex":"#a07ca8",
      "file_location":"assets/colors_organized/hue_25/brightness_11/2020040416415100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":11,
      "vividness":5,
      "r":158,
      "g":113,
      "b":168,
      "hex":"#9e71a8",
      "file_location":"assets/colors_organized/hue_25/brightness_11/2020040416415101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":11,
      "vividness":6,
      "r":156,
      "g":101,
      "b":169,
      "hex":"#9c65a9",
      "file_location":"assets/colors_organized/hue_25/brightness_11/2020040416415200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":11,
      "vividness":7,
      "r":153,
      "g":90,
      "b":169,
      "hex":"#995aa9",
      "file_location":"assets/colors_organized/hue_25/brightness_11/2020040416415201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":11,
      "vividness":8,
      "r":152,
      "g":79,
      "b":169,
      "hex":"#984fa9",
      "file_location":"assets/colors_organized/hue_25/brightness_11/2020040416415202-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":11,
      "vividness":9,
      "r":149,
      "g":67,
      "b":169,
      "hex":"#9543a9",
      "file_location":"assets/colors_organized/hue_25/brightness_11/2020040416415300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":11,
      "vividness":10,
      "r":146,
      "g":56,
      "b":169,
      "hex":"#9238a9",
      "file_location":"assets/colors_organized/hue_25/brightness_11/2020040416415301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":11,
      "vividness":11,
      "r":145,
      "g":45,
      "b":169,
      "hex":"#912da9",
      "file_location":"assets/colors_organized/hue_25/brightness_11/2020040416415400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":11,
      "vividness":12,
      "r":141,
      "g":33,
      "b":168,
      "hex":"#8d21a8",
      "file_location":"assets/colors_organized/hue_25/brightness_11/2020040416415401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":11,
      "vividness":13,
      "r":140,
      "g":22,
      "b":170,
      "hex":"#8c16aa",
      "file_location":"assets/colors_organized/hue_25/brightness_11/2020040416415500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":11,
      "vividness":14,
      "r":138,
      "g":10,
      "b":169,
      "hex":"#8a0aa9",
      "file_location":"assets/colors_organized/hue_25/brightness_11/2020040416415501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":12,
      "vividness":1,
      "r":183,
      "g":173,
      "b":184,
      "hex":"#b7adb8",
      "file_location":"assets/colors_organized/hue_25/brightness_12/2020040416413901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":12,
      "vividness":2,
      "r":179,
      "g":161,
      "b":185,
      "hex":"#b3a1b9",
      "file_location":"assets/colors_organized/hue_25/brightness_12/2020040416414000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":12,
      "vividness":3,
      "r":177,
      "g":147,
      "b":183,
      "hex":"#b193b7",
      "file_location":"assets/colors_organized/hue_25/brightness_12/2020040416414001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":12,
      "vividness":4,
      "r":173,
      "g":135,
      "b":184,
      "hex":"#ad87b8",
      "file_location":"assets/colors_organized/hue_25/brightness_12/2020040416414100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":12,
      "vividness":5,
      "r":173,
      "g":123,
      "b":184,
      "hex":"#ad7bb8",
      "file_location":"assets/colors_organized/hue_25/brightness_12/2020040416414101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":12,
      "vividness":6,
      "r":168,
      "g":110,
      "b":184,
      "hex":"#a86eb8",
      "file_location":"assets/colors_organized/hue_25/brightness_12/2020040416414102-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":12,
      "vividness":7,
      "r":167,
      "g":97,
      "b":183,
      "hex":"#a761b7",
      "file_location":"assets/colors_organized/hue_25/brightness_12/2020040416414200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":12,
      "vividness":8,
      "r":163,
      "g":85,
      "b":184,
      "hex":"#a355b8",
      "file_location":"assets/colors_organized/hue_25/brightness_12/2020040416414201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":12,
      "vividness":9,
      "r":161,
      "g":73,
      "b":183,
      "hex":"#a149b7",
      "file_location":"assets/colors_organized/hue_25/brightness_12/2020040416414300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":12,
      "vividness":10,
      "r":160,
      "g":60,
      "b":184,
      "hex":"#a03cb8",
      "file_location":"assets/colors_organized/hue_25/brightness_12/2020040416414400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":12,
      "vividness":11,
      "r":156,
      "g":48,
      "b":183,
      "hex":"#9c30b7",
      "file_location":"assets/colors_organized/hue_25/brightness_12/2020040416414401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":12,
      "vividness":12,
      "r":155,
      "g":35,
      "b":184,
      "hex":"#9b23b8",
      "file_location":"assets/colors_organized/hue_25/brightness_12/2020040416414500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":12,
      "vividness":13,
      "r":153,
      "g":23,
      "b":185,
      "hex":"#9917b9",
      "file_location":"assets/colors_organized/hue_25/brightness_12/2020040416414501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":12,
      "vividness":14,
      "r":150,
      "g":12,
      "b":183,
      "hex":"#960cb7",
      "file_location":"assets/colors_organized/hue_25/brightness_12/2020040416414600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":13,
      "vividness":1,
      "r":197,
      "g":186,
      "b":200,
      "hex":"#c5bac8",
      "file_location":"assets/colors_organized/hue_25/brightness_13/2020040416413000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":13,
      "vividness":2,
      "r":194,
      "g":174,
      "b":199,
      "hex":"#c2aec7",
      "file_location":"assets/colors_organized/hue_25/brightness_13/2020040416413001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":13,
      "vividness":3,
      "r":191,
      "g":159,
      "b":200,
      "hex":"#bf9fc8",
      "file_location":"assets/colors_organized/hue_25/brightness_13/2020040416413002-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":13,
      "vividness":4,
      "r":189,
      "g":146,
      "b":199,
      "hex":"#bd92c7",
      "file_location":"assets/colors_organized/hue_25/brightness_13/2020040416413100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":13,
      "vividness":5,
      "r":186,
      "g":134,
      "b":200,
      "hex":"#ba86c8",
      "file_location":"assets/colors_organized/hue_25/brightness_13/2020040416413101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":13,
      "vividness":6,
      "r":182,
      "g":119,
      "b":198,
      "hex":"#b677c6",
      "file_location":"assets/colors_organized/hue_25/brightness_13/2020040416413200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":13,
      "vividness":7,
      "r":180,
      "g":107,
      "b":199,
      "hex":"#b46bc7",
      "file_location":"assets/colors_organized/hue_25/brightness_13/2020040416413201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":13,
      "vividness":8,
      "r":178,
      "g":92,
      "b":199,
      "hex":"#b25cc7",
      "file_location":"assets/colors_organized/hue_25/brightness_13/2020040416413300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":13,
      "vividness":9,
      "r":174,
      "g":79,
      "b":199,
      "hex":"#ae4fc7",
      "file_location":"assets/colors_organized/hue_25/brightness_13/2020040416413301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":13,
      "vividness":10,
      "r":173,
      "g":65,
      "b":200,
      "hex":"#ad41c8",
      "file_location":"assets/colors_organized/hue_25/brightness_13/2020040416413400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":13,
      "vividness":11,
      "r":170,
      "g":52,
      "b":198,
      "hex":"#aa34c6",
      "file_location":"assets/colors_organized/hue_25/brightness_13/2020040416413401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":13,
      "vividness":12,
      "r":167,
      "g":38,
      "b":200,
      "hex":"#a726c8",
      "file_location":"assets/colors_organized/hue_25/brightness_13/2020040416413500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":13,
      "vividness":13,
      "r":165,
      "g":27,
      "b":200,
      "hex":"#a51bc8",
      "file_location":"assets/colors_organized/hue_25/brightness_13/2020040416413501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":13,
      "vividness":14,
      "r":163,
      "g":13,
      "b":199,
      "hex":"#a30dc7",
      "file_location":"assets/colors_organized/hue_25/brightness_13/2020040416413600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":14,
      "vividness":1,
      "r":211,
      "g":200,
      "b":214,
      "hex":"#d3c8d6",
      "file_location":"assets/colors_organized/hue_25/brightness_14/2020040416412000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":14,
      "vividness":2,
      "r":208,
      "g":185,
      "b":214,
      "hex":"#d0b9d6",
      "file_location":"assets/colors_organized/hue_25/brightness_14/2020040416412001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":14,
      "vividness":3,
      "r":204,
      "g":171,
      "b":214,
      "hex":"#ccabd6",
      "file_location":"assets/colors_organized/hue_25/brightness_14/2020040416412100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":14,
      "vividness":4,
      "r":203,
      "g":158,
      "b":215,
      "hex":"#cb9ed7",
      "file_location":"assets/colors_organized/hue_25/brightness_14/2020040416412101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":14,
      "vividness":5,
      "r":200,
      "g":143,
      "b":214,
      "hex":"#c88fd6",
      "file_location":"assets/colors_organized/hue_25/brightness_14/2020040416412102-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":14,
      "vividness":6,
      "r":198,
      "g":128,
      "b":214,
      "hex":"#c680d6",
      "file_location":"assets/colors_organized/hue_25/brightness_14/2020040416412200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":14,
      "vividness":7,
      "r":194,
      "g":113,
      "b":215,
      "hex":"#c271d7",
      "file_location":"assets/colors_organized/hue_25/brightness_14/2020040416412201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":14,
      "vividness":8,
      "r":190,
      "g":100,
      "b":214,
      "hex":"#be64d6",
      "file_location":"assets/colors_organized/hue_25/brightness_14/2020040416412300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":14,
      "vividness":9,
      "r":188,
      "g":86,
      "b":214,
      "hex":"#bc56d6",
      "file_location":"assets/colors_organized/hue_25/brightness_14/2020040416412301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":14,
      "vividness":10,
      "r":184,
      "g":71,
      "b":213,
      "hex":"#b847d5",
      "file_location":"assets/colors_organized/hue_25/brightness_14/2020040416412400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":14,
      "vividness":11,
      "r":182,
      "g":57,
      "b":213,
      "hex":"#b639d5",
      "file_location":"assets/colors_organized/hue_25/brightness_14/2020040416412401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":14,
      "vividness":12,
      "r":180,
      "g":42,
      "b":215,
      "hex":"#b42ad7",
      "file_location":"assets/colors_organized/hue_25/brightness_14/2020040416412500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":14,
      "vividness":13,
      "r":178,
      "g":28,
      "b":214,
      "hex":"#b21cd6",
      "file_location":"assets/colors_organized/hue_25/brightness_14/2020040416412501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":14,
      "vividness":14,
      "r":174,
      "g":13,
      "b":215,
      "hex":"#ae0dd7",
      "file_location":"assets/colors_organized/hue_25/brightness_14/2020040416412600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":15,
      "vividness":1,
      "r":227,
      "g":215,
      "b":229,
      "hex":"#e3d7e5",
      "file_location":"assets/colors_organized/hue_25/brightness_15/2020040416411000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":15,
      "vividness":2,
      "r":223,
      "g":198,
      "b":228,
      "hex":"#dfc6e4",
      "file_location":"assets/colors_organized/hue_25/brightness_15/2020040416411001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":15,
      "vividness":3,
      "r":221,
      "g":183,
      "b":230,
      "hex":"#ddb7e6",
      "file_location":"assets/colors_organized/hue_25/brightness_15/2020040416411100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":15,
      "vividness":4,
      "r":218,
      "g":168,
      "b":229,
      "hex":"#daa8e5",
      "file_location":"assets/colors_organized/hue_25/brightness_15/2020040416411101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":15,
      "vividness":5,
      "r":215,
      "g":153,
      "b":230,
      "hex":"#d799e6",
      "file_location":"assets/colors_organized/hue_25/brightness_15/2020040416411200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":15,
      "vividness":6,
      "r":212,
      "g":137,
      "b":230,
      "hex":"#d489e6",
      "file_location":"assets/colors_organized/hue_25/brightness_15/2020040416411201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":15,
      "vividness":7,
      "r":208,
      "g":122,
      "b":229,
      "hex":"#d07ae5",
      "file_location":"assets/colors_organized/hue_25/brightness_15/2020040416411300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":15,
      "vividness":8,
      "r":205,
      "g":107,
      "b":230,
      "hex":"#cd6be6",
      "file_location":"assets/colors_organized/hue_25/brightness_15/2020040416411301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":15,
      "vividness":9,
      "r":202,
      "g":91,
      "b":230,
      "hex":"#ca5be6",
      "file_location":"assets/colors_organized/hue_25/brightness_15/2020040416411400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":15,
      "vividness":10,
      "r":199,
      "g":76,
      "b":229,
      "hex":"#c74ce5",
      "file_location":"assets/colors_organized/hue_25/brightness_15/2020040416411401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":15,
      "vividness":11,
      "r":196,
      "g":60,
      "b":230,
      "hex":"#c43ce6",
      "file_location":"assets/colors_organized/hue_25/brightness_15/2020040416411402-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":15,
      "vividness":12,
      "r":193,
      "g":45,
      "b":229,
      "hex":"#c12de5",
      "file_location":"assets/colors_organized/hue_25/brightness_15/2020040416411500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":15,
      "vividness":13,
      "r":188,
      "g":30,
      "b":229,
      "hex":"#bc1ee5",
      "file_location":"assets/colors_organized/hue_25/brightness_15/2020040416411501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":25,
      "brightness":15,
      "vividness":14,
      "r":185,
      "g":15,
      "b":228,
      "hex":"#b90fe4",
      "file_location":"assets/colors_organized/hue_25/brightness_15/2020040416411600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":1,
      "vividness":1,
      "r":20,
      "g":10,
      "b":19,
      "hex":"#140a13",
      "file_location":"assets/colors_organized/hue_26/brightness_01/2020040416461001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":1,
      "vividness":2,
      "r":19,
      "g":7,
      "b":19,
      "hex":"#130713",
      "file_location":"assets/colors_organized/hue_26/brightness_01/2020040416461101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":1,
      "vividness":3,
      "r":20,
      "g":3,
      "b":21,
      "hex":"#140315",
      "file_location":"assets/colors_organized/hue_26/brightness_01/2020040416461300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":1,
      "vividness":4,
      "r":19,
      "g":0,
      "b":20,
      "hex":"#130014",
      "file_location":"assets/colors_organized/hue_26/brightness_01/2020040416461400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":2,
      "vividness":1,
      "r":34,
      "g":32,
      "b":35,
      "hex":"#222023",
      "file_location":"assets/colors_organized/hue_26/brightness_02/2020040416455700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":2,
      "vividness":2,
      "r":35,
      "g":30,
      "b":36,
      "hex":"#231e24",
      "file_location":"assets/colors_organized/hue_26/brightness_02/2020040416455701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":2,
      "vividness":3,
      "r":34,
      "g":27,
      "b":34,
      "hex":"#221b22",
      "file_location":"assets/colors_organized/hue_26/brightness_02/2020040416455800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":2,
      "vividness":4,
      "r":35,
      "g":25,
      "b":34,
      "hex":"#231922",
      "file_location":"assets/colors_organized/hue_26/brightness_02/2020040416455801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":2,
      "vividness":5,
      "r":34,
      "g":22,
      "b":34,
      "hex":"#221622",
      "file_location":"assets/colors_organized/hue_26/brightness_02/2020040416455900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":2,
      "vividness":6,
      "r":35,
      "g":18,
      "b":36,
      "hex":"#231224",
      "file_location":"assets/colors_organized/hue_26/brightness_02/2020040416460000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":2,
      "vividness":7,
      "r":34,
      "g":15,
      "b":35,
      "hex":"#220f23",
      "file_location":"assets/colors_organized/hue_26/brightness_02/2020040416460001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":2,
      "vividness":8,
      "r":35,
      "g":13,
      "b":34,
      "hex":"#230d22",
      "file_location":"assets/colors_organized/hue_26/brightness_02/2020040416460100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":2,
      "vividness":9,
      "r":34,
      "g":10,
      "b":34,
      "hex":"#220a22",
      "file_location":"assets/colors_organized/hue_26/brightness_02/2020040416460101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":2,
      "vividness":10,
      "r":35,
      "g":6,
      "b":34,
      "hex":"#230622",
      "file_location":"assets/colors_organized/hue_26/brightness_02/2020040416460201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":2,
      "vividness":11,
      "r":34,
      "g":3,
      "b":34,
      "hex":"#220322",
      "file_location":"assets/colors_organized/hue_26/brightness_02/2020040416460300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":3,
      "vividness":1,
      "r":50,
      "g":45,
      "b":49,
      "hex":"#322d31",
      "file_location":"assets/colors_organized/hue_26/brightness_03/2020040416454601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":3,
      "vividness":2,
      "r":49,
      "g":42,
      "b":49,
      "hex":"#312a31",
      "file_location":"assets/colors_organized/hue_26/brightness_03/2020040416454700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":3,
      "vividness":3,
      "r":50,
      "g":40,
      "b":49,
      "hex":"#322831",
      "file_location":"assets/colors_organized/hue_26/brightness_03/2020040416454701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":3,
      "vividness":4,
      "r":49,
      "g":35,
      "b":50,
      "hex":"#312332",
      "file_location":"assets/colors_organized/hue_26/brightness_03/2020040416454800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":3,
      "vividness":5,
      "r":50,
      "g":33,
      "b":49,
      "hex":"#322131",
      "file_location":"assets/colors_organized/hue_26/brightness_03/2020040416454900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":3,
      "vividness":6,
      "r":49,
      "g":30,
      "b":50,
      "hex":"#311e32",
      "file_location":"assets/colors_organized/hue_26/brightness_03/2020040416454901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":3,
      "vividness":7,
      "r":50,
      "g":26,
      "b":50,
      "hex":"#321a32",
      "file_location":"assets/colors_organized/hue_26/brightness_03/2020040416454902-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":3,
      "vividness":8,
      "r":49,
      "g":23,
      "b":50,
      "hex":"#311732",
      "file_location":"assets/colors_organized/hue_26/brightness_03/2020040416455000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":3,
      "vividness":9,
      "r":49,
      "g":20,
      "b":50,
      "hex":"#311432",
      "file_location":"assets/colors_organized/hue_26/brightness_03/2020040416455001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":3,
      "vividness":10,
      "r":50,
      "g":15,
      "b":48,
      "hex":"#320f30",
      "file_location":"assets/colors_organized/hue_26/brightness_03/2020040416455100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":3,
      "vividness":11,
      "r":49,
      "g":13,
      "b":49,
      "hex":"#310d31",
      "file_location":"assets/colors_organized/hue_26/brightness_03/2020040416455101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":3,
      "vividness":12,
      "r":50,
      "g":9,
      "b":49,
      "hex":"#320931",
      "file_location":"assets/colors_organized/hue_26/brightness_03/2020040416455200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":3,
      "vividness":13,
      "r":49,
      "g":6,
      "b":49,
      "hex":"#310631",
      "file_location":"assets/colors_organized/hue_26/brightness_03/2020040416455201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":3,
      "vividness":14,
      "r":50,
      "g":3,
      "b":49,
      "hex":"#320331",
      "file_location":"assets/colors_organized/hue_26/brightness_03/2020040416455300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":4,
      "vividness":1,
      "r":65,
      "g":60,
      "b":66,
      "hex":"#413c42",
      "file_location":"assets/colors_organized/hue_26/brightness_04/2020040416453601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":4,
      "vividness":2,
      "r":65,
      "g":55,
      "b":64,
      "hex":"#413740",
      "file_location":"assets/colors_organized/hue_26/brightness_04/2020040416453700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":4,
      "vividness":3,
      "r":64,
      "g":52,
      "b":64,
      "hex":"#403440",
      "file_location":"assets/colors_organized/hue_26/brightness_04/2020040416453701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":4,
      "vividness":4,
      "r":65,
      "g":46,
      "b":65,
      "hex":"#412e41",
      "file_location":"assets/colors_organized/hue_26/brightness_04/2020040416453800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":4,
      "vividness":5,
      "r":65,
      "g":43,
      "b":64,
      "hex":"#412b40",
      "file_location":"assets/colors_organized/hue_26/brightness_04/2020040416453801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":4,
      "vividness":6,
      "r":64,
      "g":38,
      "b":65,
      "hex":"#402641",
      "file_location":"assets/colors_organized/hue_26/brightness_04/2020040416453900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":4,
      "vividness":7,
      "r":64,
      "g":33,
      "b":64,
      "hex":"#402140",
      "file_location":"assets/colors_organized/hue_26/brightness_04/2020040416453901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":4,
      "vividness":8,
      "r":65,
      "g":29,
      "b":65,
      "hex":"#411d41",
      "file_location":"assets/colors_organized/hue_26/brightness_04/2020040416454000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":4,
      "vividness":9,
      "r":64,
      "g":26,
      "b":65,
      "hex":"#401a41",
      "file_location":"assets/colors_organized/hue_26/brightness_04/2020040416454100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":4,
      "vividness":10,
      "r":64,
      "g":21,
      "b":64,
      "hex":"#401540",
      "file_location":"assets/colors_organized/hue_26/brightness_04/2020040416454101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":4,
      "vividness":11,
      "r":65,
      "g":17,
      "b":65,
      "hex":"#411141",
      "file_location":"assets/colors_organized/hue_26/brightness_04/2020040416454200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":4,
      "vividness":12,
      "r":65,
      "g":12,
      "b":64,
      "hex":"#410c40",
      "file_location":"assets/colors_organized/hue_26/brightness_04/2020040416454201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":4,
      "vividness":13,
      "r":65,
      "g":7,
      "b":65,
      "hex":"#410741",
      "file_location":"assets/colors_organized/hue_26/brightness_04/2020040416454202-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":4,
      "vividness":14,
      "r":64,
      "g":2,
      "b":65,
      "hex":"#400241",
      "file_location":"assets/colors_organized/hue_26/brightness_04/2020040416454300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":5,
      "vividness":1,
      "r":79,
      "g":69,
      "b":80,
      "hex":"#4f4550",
      "file_location":"assets/colors_organized/hue_26/brightness_05/2020040416452602-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":5,
      "vividness":2,
      "r":80,
      "g":63,
      "b":79,
      "hex":"#503f4f",
      "file_location":"assets/colors_organized/hue_26/brightness_05/2020040416452700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":5,
      "vividness":3,
      "r":80,
      "g":58,
      "b":79,
      "hex":"#503a4f",
      "file_location":"assets/colors_organized/hue_26/brightness_05/2020040416452701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":5,
      "vividness":4,
      "r":79,
      "g":53,
      "b":80,
      "hex":"#4f3550",
      "file_location":"assets/colors_organized/hue_26/brightness_05/2020040416452800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":5,
      "vividness":5,
      "r":79,
      "g":48,
      "b":80,
      "hex":"#4f3050",
      "file_location":"assets/colors_organized/hue_26/brightness_05/2020040416452801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":5,
      "vividness":6,
      "r":79,
      "g":41,
      "b":80,
      "hex":"#4f2950",
      "file_location":"assets/colors_organized/hue_26/brightness_05/2020040416452900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":5,
      "vividness":7,
      "r":79,
      "g":36,
      "b":79,
      "hex":"#4f244f",
      "file_location":"assets/colors_organized/hue_26/brightness_05/2020040416453000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":5,
      "vividness":8,
      "r":79,
      "g":31,
      "b":79,
      "hex":"#4f1f4f",
      "file_location":"assets/colors_organized/hue_26/brightness_05/2020040416453001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":5,
      "vividness":9,
      "r":80,
      "g":27,
      "b":81,
      "hex":"#501b51",
      "file_location":"assets/colors_organized/hue_26/brightness_05/2020040416453100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":5,
      "vividness":10,
      "r":80,
      "g":22,
      "b":80,
      "hex":"#501650",
      "file_location":"assets/colors_organized/hue_26/brightness_05/2020040416453101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":5,
      "vividness":11,
      "r":79,
      "g":14,
      "b":80,
      "hex":"#4f0e50",
      "file_location":"assets/colors_organized/hue_26/brightness_05/2020040416453200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":5,
      "vividness":12,
      "r":80,
      "g":10,
      "b":80,
      "hex":"#500a50",
      "file_location":"assets/colors_organized/hue_26/brightness_05/2020040416453201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":5,
      "vividness":13,
      "r":80,
      "g":7,
      "b":80,
      "hex":"#500750",
      "file_location":"assets/colors_organized/hue_26/brightness_05/2020040416453300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":6,
      "vividness":1,
      "r":95,
      "g":88,
      "b":95,
      "hex":"#5f585f",
      "file_location":"assets/colors_organized/hue_26/brightness_06/2020040416451600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":6,
      "vividness":2,
      "r":94,
      "g":82,
      "b":94,
      "hex":"#5e525e",
      "file_location":"assets/colors_organized/hue_26/brightness_06/2020040416451601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":6,
      "vividness":3,
      "r":95,
      "g":76,
      "b":95,
      "hex":"#5f4c5f",
      "file_location":"assets/colors_organized/hue_26/brightness_06/2020040416451700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":6,
      "vividness":4,
      "r":95,
      "g":69,
      "b":94,
      "hex":"#5f455e",
      "file_location":"assets/colors_organized/hue_26/brightness_06/2020040416451701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":6,
      "vividness":5,
      "r":94,
      "g":63,
      "b":95,
      "hex":"#5e3f5f",
      "file_location":"assets/colors_organized/hue_26/brightness_06/2020040416451800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":6,
      "vividness":6,
      "r":94,
      "g":56,
      "b":95,
      "hex":"#5e385f",
      "file_location":"assets/colors_organized/hue_26/brightness_06/2020040416451801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":6,
      "vividness":7,
      "r":95,
      "g":49,
      "b":95,
      "hex":"#5f315f",
      "file_location":"assets/colors_organized/hue_26/brightness_06/2020040416451900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":6,
      "vividness":8,
      "r":94,
      "g":44,
      "b":95,
      "hex":"#5e2c5f",
      "file_location":"assets/colors_organized/hue_26/brightness_06/2020040416451901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":6,
      "vividness":9,
      "r":95,
      "g":37,
      "b":95,
      "hex":"#5f255f",
      "file_location":"assets/colors_organized/hue_26/brightness_06/2020040416452000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":6,
      "vividness":10,
      "r":95,
      "g":30,
      "b":94,
      "hex":"#5f1e5e",
      "file_location":"assets/colors_organized/hue_26/brightness_06/2020040416452001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":6,
      "vividness":11,
      "r":95,
      "g":25,
      "b":95,
      "hex":"#5f195f",
      "file_location":"assets/colors_organized/hue_26/brightness_06/2020040416452100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":6,
      "vividness":12,
      "r":94,
      "g":20,
      "b":95,
      "hex":"#5e145f",
      "file_location":"assets/colors_organized/hue_26/brightness_06/2020040416452101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":6,
      "vividness":13,
      "r":95,
      "g":13,
      "b":95,
      "hex":"#5f0d5f",
      "file_location":"assets/colors_organized/hue_26/brightness_06/2020040416452200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":6,
      "vividness":14,
      "r":95,
      "g":7,
      "b":94,
      "hex":"#5f075e",
      "file_location":"assets/colors_organized/hue_26/brightness_06/2020040416452201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":7,
      "vividness":1,
      "r":109,
      "g":102,
      "b":110,
      "hex":"#6d666e",
      "file_location":"assets/colors_organized/hue_26/brightness_07/2020040416450501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":7,
      "vividness":2,
      "r":109,
      "g":95,
      "b":110,
      "hex":"#6d5f6e",
      "file_location":"assets/colors_organized/hue_26/brightness_07/2020040416450600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":7,
      "vividness":3,
      "r":110,
      "g":88,
      "b":109,
      "hex":"#6e586d",
      "file_location":"assets/colors_organized/hue_26/brightness_07/2020040416450601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":7,
      "vividness":4,
      "r":109,
      "g":80,
      "b":110,
      "hex":"#6d506e",
      "file_location":"assets/colors_organized/hue_26/brightness_07/2020040416450700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":7,
      "vividness":5,
      "r":109,
      "g":73,
      "b":109,
      "hex":"#6d496d",
      "file_location":"assets/colors_organized/hue_26/brightness_07/2020040416450701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":7,
      "vividness":6,
      "r":109,
      "g":66,
      "b":109,
      "hex":"#6d426d",
      "file_location":"assets/colors_organized/hue_26/brightness_07/2020040416450800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":7,
      "vividness":7,
      "r":110,
      "g":57,
      "b":109,
      "hex":"#6e396d",
      "file_location":"assets/colors_organized/hue_26/brightness_07/2020040416450801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":7,
      "vividness":8,
      "r":110,
      "g":50,
      "b":110,
      "hex":"#6e326e",
      "file_location":"assets/colors_organized/hue_26/brightness_07/2020040416450900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":7,
      "vividness":9,
      "r":110,
      "g":43,
      "b":110,
      "hex":"#6e2b6e",
      "file_location":"assets/colors_organized/hue_26/brightness_07/2020040416450901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":7,
      "vividness":10,
      "r":109,
      "g":36,
      "b":109,
      "hex":"#6d246d",
      "file_location":"assets/colors_organized/hue_26/brightness_07/2020040416451000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":7,
      "vividness":11,
      "r":111,
      "g":30,
      "b":109,
      "hex":"#6f1e6d",
      "file_location":"assets/colors_organized/hue_26/brightness_07/2020040416451001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":7,
      "vividness":12,
      "r":110,
      "g":22,
      "b":109,
      "hex":"#6e166d",
      "file_location":"assets/colors_organized/hue_26/brightness_07/2020040416451100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":7,
      "vividness":13,
      "r":110,
      "g":13,
      "b":110,
      "hex":"#6e0d6e",
      "file_location":"assets/colors_organized/hue_26/brightness_07/2020040416451101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":7,
      "vividness":14,
      "r":109,
      "g":7,
      "b":109,
      "hex":"#6d076d",
      "file_location":"assets/colors_organized/hue_26/brightness_07/2020040416451200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":8,
      "vividness":1,
      "r":124,
      "g":107,
      "b":123,
      "hex":"#7c6b7b",
      "file_location":"assets/colors_organized/hue_26/brightness_08/2020040416445600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":8,
      "vividness":2,
      "r":123,
      "g":99,
      "b":123,
      "hex":"#7b637b",
      "file_location":"assets/colors_organized/hue_26/brightness_08/2020040416445601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":8,
      "vividness":3,
      "r":124,
      "g":90,
      "b":123,
      "hex":"#7c5a7b",
      "file_location":"assets/colors_organized/hue_26/brightness_08/2020040416445700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":8,
      "vividness":4,
      "r":124,
      "g":83,
      "b":123,
      "hex":"#7c537b",
      "file_location":"assets/colors_organized/hue_26/brightness_08/2020040416445701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":8,
      "vividness":5,
      "r":123,
      "g":73,
      "b":122,
      "hex":"#7b497a",
      "file_location":"assets/colors_organized/hue_26/brightness_08/2020040416445800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":8,
      "vividness":6,
      "r":124,
      "g":66,
      "b":124,
      "hex":"#7c427c",
      "file_location":"assets/colors_organized/hue_26/brightness_08/2020040416445801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":8,
      "vividness":7,
      "r":124,
      "g":57,
      "b":124,
      "hex":"#7c397c",
      "file_location":"assets/colors_organized/hue_26/brightness_08/2020040416445900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":8,
      "vividness":8,
      "r":123,
      "g":49,
      "b":124,
      "hex":"#7b317c",
      "file_location":"assets/colors_organized/hue_26/brightness_08/2020040416445901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":8,
      "vividness":9,
      "r":124,
      "g":41,
      "b":123,
      "hex":"#7c297b",
      "file_location":"assets/colors_organized/hue_26/brightness_08/2020040416450000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":8,
      "vividness":10,
      "r":124,
      "g":34,
      "b":124,
      "hex":"#7c227c",
      "file_location":"assets/colors_organized/hue_26/brightness_08/2020040416450001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":8,
      "vividness":11,
      "r":123,
      "g":23,
      "b":122,
      "hex":"#7b177a",
      "file_location":"assets/colors_organized/hue_26/brightness_08/2020040416450100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":8,
      "vividness":12,
      "r":124,
      "g":15,
      "b":124,
      "hex":"#7c0f7c",
      "file_location":"assets/colors_organized/hue_26/brightness_08/2020040416450101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":8,
      "vividness":13,
      "r":124,
      "g":7,
      "b":124,
      "hex":"#7c077c",
      "file_location":"assets/colors_organized/hue_26/brightness_08/2020040416450200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":9,
      "vividness":1,
      "r":140,
      "g":130,
      "b":139,
      "hex":"#8c828b",
      "file_location":"assets/colors_organized/hue_26/brightness_09/2020040416444601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":9,
      "vividness":2,
      "r":139,
      "g":120,
      "b":139,
      "hex":"#8b788b",
      "file_location":"assets/colors_organized/hue_26/brightness_09/2020040416444602-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":9,
      "vividness":3,
      "r":140,
      "g":111,
      "b":139,
      "hex":"#8c6f8b",
      "file_location":"assets/colors_organized/hue_26/brightness_09/2020040416444700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":9,
      "vividness":4,
      "r":138,
      "g":102,
      "b":138,
      "hex":"#8a668a",
      "file_location":"assets/colors_organized/hue_26/brightness_09/2020040416444701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":9,
      "vividness":5,
      "r":139,
      "g":93,
      "b":139,
      "hex":"#8b5d8b",
      "file_location":"assets/colors_organized/hue_26/brightness_09/2020040416444800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":9,
      "vividness":6,
      "r":138,
      "g":83,
      "b":140,
      "hex":"#8a538c",
      "file_location":"assets/colors_organized/hue_26/brightness_09/2020040416444801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":9,
      "vividness":7,
      "r":139,
      "g":74,
      "b":140,
      "hex":"#8b4a8c",
      "file_location":"assets/colors_organized/hue_26/brightness_09/2020040416444900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":9,
      "vividness":8,
      "r":139,
      "g":66,
      "b":139,
      "hex":"#8b428b",
      "file_location":"assets/colors_organized/hue_26/brightness_09/2020040416444901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":9,
      "vividness":9,
      "r":138,
      "g":54,
      "b":139,
      "hex":"#8a368b",
      "file_location":"assets/colors_organized/hue_26/brightness_09/2020040416444902-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":9,
      "vividness":10,
      "r":139,
      "g":45,
      "b":139,
      "hex":"#8b2d8b",
      "file_location":"assets/colors_organized/hue_26/brightness_09/2020040416445000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":9,
      "vividness":11,
      "r":139,
      "g":37,
      "b":139,
      "hex":"#8b258b",
      "file_location":"assets/colors_organized/hue_26/brightness_09/2020040416445001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":9,
      "vividness":12,
      "r":139,
      "g":27,
      "b":139,
      "hex":"#8b1b8b",
      "file_location":"assets/colors_organized/hue_26/brightness_09/2020040416445100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":9,
      "vividness":13,
      "r":140,
      "g":18,
      "b":139,
      "hex":"#8c128b",
      "file_location":"assets/colors_organized/hue_26/brightness_09/2020040416445101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":9,
      "vividness":14,
      "r":139,
      "g":10,
      "b":139,
      "hex":"#8b0a8b",
      "file_location":"assets/colors_organized/hue_26/brightness_09/2020040416445200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":10,
      "vividness":1,
      "r":154,
      "g":144,
      "b":153,
      "hex":"#9a9099",
      "file_location":"assets/colors_organized/hue_26/brightness_10/2020040416443601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":10,
      "vividness":2,
      "r":153,
      "g":134,
      "b":154,
      "hex":"#99869a",
      "file_location":"assets/colors_organized/hue_26/brightness_10/2020040416443700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":10,
      "vividness":3,
      "r":154,
      "g":123,
      "b":154,
      "hex":"#9a7b9a",
      "file_location":"assets/colors_organized/hue_26/brightness_10/2020040416443701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":10,
      "vividness":4,
      "r":154,
      "g":113,
      "b":155,
      "hex":"#9a719b",
      "file_location":"assets/colors_organized/hue_26/brightness_10/2020040416443702-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":10,
      "vividness":5,
      "r":153,
      "g":103,
      "b":154,
      "hex":"#99679a",
      "file_location":"assets/colors_organized/hue_26/brightness_10/2020040416443800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":10,
      "vividness":6,
      "r":154,
      "g":92,
      "b":155,
      "hex":"#9a5c9b",
      "file_location":"assets/colors_organized/hue_26/brightness_10/2020040416443801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":10,
      "vividness":7,
      "r":154,
      "g":82,
      "b":155,
      "hex":"#9a529b",
      "file_location":"assets/colors_organized/hue_26/brightness_10/2020040416443900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":10,
      "vividness":8,
      "r":154,
      "g":72,
      "b":154,
      "hex":"#9a489a",
      "file_location":"assets/colors_organized/hue_26/brightness_10/2020040416443901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":10,
      "vividness":9,
      "r":154,
      "g":60,
      "b":154,
      "hex":"#9a3c9a",
      "file_location":"assets/colors_organized/hue_26/brightness_10/2020040416444000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":10,
      "vividness":10,
      "r":153,
      "g":51,
      "b":153,
      "hex":"#993399",
      "file_location":"assets/colors_organized/hue_26/brightness_10/2020040416444001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":10,
      "vividness":11,
      "r":153,
      "g":40,
      "b":154,
      "hex":"#99289a",
      "file_location":"assets/colors_organized/hue_26/brightness_10/2020040416444100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":10,
      "vividness":12,
      "r":154,
      "g":30,
      "b":154,
      "hex":"#9a1e9a",
      "file_location":"assets/colors_organized/hue_26/brightness_10/2020040416444101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":10,
      "vividness":13,
      "r":154,
      "g":20,
      "b":153,
      "hex":"#9a1499",
      "file_location":"assets/colors_organized/hue_26/brightness_10/2020040416444200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":10,
      "vividness":14,
      "r":153,
      "g":10,
      "b":154,
      "hex":"#990a9a",
      "file_location":"assets/colors_organized/hue_26/brightness_10/2020040416444201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":11,
      "vividness":1,
      "r":169,
      "g":159,
      "b":170,
      "hex":"#a99faa",
      "file_location":"assets/colors_organized/hue_26/brightness_11/2020040416442601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":11,
      "vividness":2,
      "r":169,
      "g":147,
      "b":168,
      "hex":"#a993a8",
      "file_location":"assets/colors_organized/hue_26/brightness_11/2020040416442700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":11,
      "vividness":3,
      "r":169,
      "g":135,
      "b":168,
      "hex":"#a987a8",
      "file_location":"assets/colors_organized/hue_26/brightness_11/2020040416442701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":11,
      "vividness":4,
      "r":170,
      "g":124,
      "b":170,
      "hex":"#aa7caa",
      "file_location":"assets/colors_organized/hue_26/brightness_11/2020040416442800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":11,
      "vividness":5,
      "r":168,
      "g":113,
      "b":170,
      "hex":"#a871aa",
      "file_location":"assets/colors_organized/hue_26/brightness_11/2020040416442801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":11,
      "vividness":6,
      "r":168,
      "g":101,
      "b":170,
      "hex":"#a865aa",
      "file_location":"assets/colors_organized/hue_26/brightness_11/2020040416442900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":11,
      "vividness":7,
      "r":169,
      "g":90,
      "b":169,
      "hex":"#a95aa9",
      "file_location":"assets/colors_organized/hue_26/brightness_11/2020040416442901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":11,
      "vividness":8,
      "r":169,
      "g":79,
      "b":169,
      "hex":"#a94fa9",
      "file_location":"assets/colors_organized/hue_26/brightness_11/2020040416443000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":11,
      "vividness":9,
      "r":169,
      "g":67,
      "b":169,
      "hex":"#a943a9",
      "file_location":"assets/colors_organized/hue_26/brightness_11/2020040416443001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":11,
      "vividness":10,
      "r":168,
      "g":55,
      "b":169,
      "hex":"#a837a9",
      "file_location":"assets/colors_organized/hue_26/brightness_11/2020040416443100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":11,
      "vividness":11,
      "r":169,
      "g":45,
      "b":169,
      "hex":"#a92da9",
      "file_location":"assets/colors_organized/hue_26/brightness_11/2020040416443101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":11,
      "vividness":12,
      "r":170,
      "g":34,
      "b":170,
      "hex":"#aa22aa",
      "file_location":"assets/colors_organized/hue_26/brightness_11/2020040416443200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":11,
      "vividness":13,
      "r":169,
      "g":21,
      "b":169,
      "hex":"#a915a9",
      "file_location":"assets/colors_organized/hue_26/brightness_11/2020040416443201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":11,
      "vividness":14,
      "r":169,
      "g":9,
      "b":169,
      "hex":"#a909a9",
      "file_location":"assets/colors_organized/hue_26/brightness_11/2020040416443300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":12,
      "vividness":1,
      "r":184,
      "g":172,
      "b":184,
      "hex":"#b8acb8",
      "file_location":"assets/colors_organized/hue_26/brightness_12/2020040416441700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":12,
      "vividness":2,
      "r":184,
      "g":160,
      "b":184,
      "hex":"#b8a0b8",
      "file_location":"assets/colors_organized/hue_26/brightness_12/2020040416441701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":12,
      "vividness":3,
      "r":183,
      "g":147,
      "b":183,
      "hex":"#b793b7",
      "file_location":"assets/colors_organized/hue_26/brightness_12/2020040416441800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":12,
      "vividness":4,
      "r":183,
      "g":135,
      "b":183,
      "hex":"#b787b7",
      "file_location":"assets/colors_organized/hue_26/brightness_12/2020040416441801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":12,
      "vividness":5,
      "r":184,
      "g":123,
      "b":183,
      "hex":"#b87bb7",
      "file_location":"assets/colors_organized/hue_26/brightness_12/2020040416441900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":12,
      "vividness":6,
      "r":184,
      "g":111,
      "b":184,
      "hex":"#b86fb8",
      "file_location":"assets/colors_organized/hue_26/brightness_12/2020040416441901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":12,
      "vividness":7,
      "r":183,
      "g":98,
      "b":183,
      "hex":"#b762b7",
      "file_location":"assets/colors_organized/hue_26/brightness_12/2020040416441902-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":12,
      "vividness":8,
      "r":183,
      "g":86,
      "b":183,
      "hex":"#b756b7",
      "file_location":"assets/colors_organized/hue_26/brightness_12/2020040416442000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":12,
      "vividness":9,
      "r":185,
      "g":73,
      "b":185,
      "hex":"#b949b9",
      "file_location":"assets/colors_organized/hue_26/brightness_12/2020040416442001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":12,
      "vividness":10,
      "r":185,
      "g":61,
      "b":185,
      "hex":"#b93db9",
      "file_location":"assets/colors_organized/hue_26/brightness_12/2020040416442100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":12,
      "vividness":11,
      "r":185,
      "g":49,
      "b":185,
      "hex":"#b931b9",
      "file_location":"assets/colors_organized/hue_26/brightness_12/2020040416442101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":12,
      "vividness":12,
      "r":184,
      "g":36,
      "b":184,
      "hex":"#b824b8",
      "file_location":"assets/colors_organized/hue_26/brightness_12/2020040416442200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":12,
      "vividness":13,
      "r":184,
      "g":22,
      "b":183,
      "hex":"#b816b7",
      "file_location":"assets/colors_organized/hue_26/brightness_12/2020040416442201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":12,
      "vividness":14,
      "r":184,
      "g":12,
      "b":184,
      "hex":"#b80cb8",
      "file_location":"assets/colors_organized/hue_26/brightness_12/2020040416442300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":13,
      "vividness":1,
      "r":199,
      "g":187,
      "b":199,
      "hex":"#c7bbc7",
      "file_location":"assets/colors_organized/hue_26/brightness_13/2020040416440800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":13,
      "vividness":2,
      "r":199,
      "g":173,
      "b":200,
      "hex":"#c7adc8",
      "file_location":"assets/colors_organized/hue_26/brightness_13/2020040416440801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":13,
      "vividness":3,
      "r":200,
      "g":159,
      "b":199,
      "hex":"#c89fc7",
      "file_location":"assets/colors_organized/hue_26/brightness_13/2020040416440802-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":13,
      "vividness":4,
      "r":199,
      "g":146,
      "b":200,
      "hex":"#c792c8",
      "file_location":"assets/colors_organized/hue_26/brightness_13/2020040416440900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":13,
      "vividness":5,
      "r":200,
      "g":133,
      "b":200,
      "hex":"#c885c8",
      "file_location":"assets/colors_organized/hue_26/brightness_13/2020040416440901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":13,
      "vividness":6,
      "r":200,
      "g":118,
      "b":200,
      "hex":"#c876c8",
      "file_location":"assets/colors_organized/hue_26/brightness_13/2020040416441000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":13,
      "vividness":7,
      "r":199,
      "g":105,
      "b":199,
      "hex":"#c769c7",
      "file_location":"assets/colors_organized/hue_26/brightness_13/2020040416441001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":13,
      "vividness":8,
      "r":200,
      "g":93,
      "b":199,
      "hex":"#c85dc7",
      "file_location":"assets/colors_organized/hue_26/brightness_13/2020040416441100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":13,
      "vividness":9,
      "r":199,
      "g":80,
      "b":200,
      "hex":"#c750c8",
      "file_location":"assets/colors_organized/hue_26/brightness_13/2020040416441101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":13,
      "vividness":10,
      "r":200,
      "g":66,
      "b":199,
      "hex":"#c842c7",
      "file_location":"assets/colors_organized/hue_26/brightness_13/2020040416441200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":13,
      "vividness":11,
      "r":199,
      "g":53,
      "b":200,
      "hex":"#c735c8",
      "file_location":"assets/colors_organized/hue_26/brightness_13/2020040416441201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":13,
      "vividness":12,
      "r":199,
      "g":39,
      "b":199,
      "hex":"#c727c7",
      "file_location":"assets/colors_organized/hue_26/brightness_13/2020040416441202-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":13,
      "vividness":13,
      "r":199,
      "g":27,
      "b":199,
      "hex":"#c71bc7",
      "file_location":"assets/colors_organized/hue_26/brightness_13/2020040416441300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":13,
      "vividness":14,
      "r":199,
      "g":13,
      "b":200,
      "hex":"#c70dc8",
      "file_location":"assets/colors_organized/hue_26/brightness_13/2020040416441301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":14,
      "vividness":1,
      "r":214,
      "g":200,
      "b":215,
      "hex":"#d6c8d7",
      "file_location":"assets/colors_organized/hue_26/brightness_14/2020040416435801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":14,
      "vividness":2,
      "r":214,
      "g":185,
      "b":215,
      "hex":"#d6b9d7",
      "file_location":"assets/colors_organized/hue_26/brightness_14/2020040416435900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":14,
      "vividness":3,
      "r":214,
      "g":171,
      "b":214,
      "hex":"#d6abd6",
      "file_location":"assets/colors_organized/hue_26/brightness_14/2020040416435901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":14,
      "vividness":4,
      "r":215,
      "g":157,
      "b":215,
      "hex":"#d79dd7",
      "file_location":"assets/colors_organized/hue_26/brightness_14/2020040416440000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":14,
      "vividness":5,
      "r":215,
      "g":143,
      "b":216,
      "hex":"#d78fd8",
      "file_location":"assets/colors_organized/hue_26/brightness_14/2020040416440001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":14,
      "vividness":6,
      "r":214,
      "g":129,
      "b":214,
      "hex":"#d681d6",
      "file_location":"assets/colors_organized/hue_26/brightness_14/2020040416440100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":14,
      "vividness":7,
      "r":214,
      "g":114,
      "b":213,
      "hex":"#d672d5",
      "file_location":"assets/colors_organized/hue_26/brightness_14/2020040416440101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":14,
      "vividness":8,
      "r":214,
      "g":100,
      "b":214,
      "hex":"#d664d6",
      "file_location":"assets/colors_organized/hue_26/brightness_14/2020040416440200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":14,
      "vividness":9,
      "r":214,
      "g":85,
      "b":214,
      "hex":"#d655d6",
      "file_location":"assets/colors_organized/hue_26/brightness_14/2020040416440201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":14,
      "vividness":10,
      "r":214,
      "g":71,
      "b":213,
      "hex":"#d647d5",
      "file_location":"assets/colors_organized/hue_26/brightness_14/2020040416440202-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":14,
      "vividness":11,
      "r":215,
      "g":57,
      "b":214,
      "hex":"#d739d6",
      "file_location":"assets/colors_organized/hue_26/brightness_14/2020040416440300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":14,
      "vividness":12,
      "r":214,
      "g":42,
      "b":214,
      "hex":"#d62ad6",
      "file_location":"assets/colors_organized/hue_26/brightness_14/2020040416440301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":14,
      "vividness":13,
      "r":214,
      "g":28,
      "b":215,
      "hex":"#d61cd7",
      "file_location":"assets/colors_organized/hue_26/brightness_14/2020040416440400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":14,
      "vividness":14,
      "r":214,
      "g":13,
      "b":215,
      "hex":"#d60dd7",
      "file_location":"assets/colors_organized/hue_26/brightness_14/2020040416440401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":15,
      "vividness":1,
      "r":229,
      "g":215,
      "b":230,
      "hex":"#e5d7e6",
      "file_location":"assets/colors_organized/hue_26/brightness_15/2020040416434801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":15,
      "vividness":2,
      "r":229,
      "g":198,
      "b":229,
      "hex":"#e5c6e5",
      "file_location":"assets/colors_organized/hue_26/brightness_15/2020040416434900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":15,
      "vividness":3,
      "r":229,
      "g":183,
      "b":229,
      "hex":"#e5b7e5",
      "file_location":"assets/colors_organized/hue_26/brightness_15/2020040416434901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":15,
      "vividness":4,
      "r":229,
      "g":168,
      "b":228,
      "hex":"#e5a8e4",
      "file_location":"assets/colors_organized/hue_26/brightness_15/2020040416435000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":15,
      "vividness":5,
      "r":229,
      "g":153,
      "b":228,
      "hex":"#e599e4",
      "file_location":"assets/colors_organized/hue_26/brightness_15/2020040416435001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":15,
      "vividness":6,
      "r":230,
      "g":137,
      "b":228,
      "hex":"#e689e4",
      "file_location":"assets/colors_organized/hue_26/brightness_15/2020040416435100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":15,
      "vividness":7,
      "r":229,
      "g":121,
      "b":230,
      "hex":"#e579e6",
      "file_location":"assets/colors_organized/hue_26/brightness_15/2020040416435101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":15,
      "vividness":8,
      "r":229,
      "g":107,
      "b":228,
      "hex":"#e56be4",
      "file_location":"assets/colors_organized/hue_26/brightness_15/2020040416435200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":15,
      "vividness":9,
      "r":229,
      "g":91,
      "b":230,
      "hex":"#e55be6",
      "file_location":"assets/colors_organized/hue_26/brightness_15/2020040416435201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":15,
      "vividness":10,
      "r":229,
      "g":76,
      "b":229,
      "hex":"#e54ce5",
      "file_location":"assets/colors_organized/hue_26/brightness_15/2020040416435202-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":15,
      "vividness":11,
      "r":230,
      "g":60,
      "b":229,
      "hex":"#e63ce5",
      "file_location":"assets/colors_organized/hue_26/brightness_15/2020040416435300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":15,
      "vividness":12,
      "r":229,
      "g":45,
      "b":229,
      "hex":"#e52de5",
      "file_location":"assets/colors_organized/hue_26/brightness_15/2020040416435301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":15,
      "vividness":13,
      "r":230,
      "g":29,
      "b":229,
      "hex":"#e61de5",
      "file_location":"assets/colors_organized/hue_26/brightness_15/2020040416435400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":26,
      "brightness":15,
      "vividness":14,
      "r":229,
      "g":14,
      "b":229,
      "hex":"#e50ee5",
      "file_location":"assets/colors_organized/hue_26/brightness_15/2020040416435401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":1,
      "vividness":1,
      "r":19,
      "g":13,
      "b":17,
      "hex":"#130d11",
      "file_location":"assets/colors_organized/hue_27/brightness_01/2020040416484900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":1,
      "vividness":2,
      "r":19,
      "g":9,
      "b":17,
      "hex":"#130911",
      "file_location":"assets/colors_organized/hue_27/brightness_01/2020040416484901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":1,
      "vividness":3,
      "r":19,
      "g":7,
      "b":17,
      "hex":"#130711",
      "file_location":"assets/colors_organized/hue_27/brightness_01/2020040416485001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":1,
      "vividness":4,
      "r":19,
      "g":2,
      "b":18,
      "hex":"#130212",
      "file_location":"assets/colors_organized/hue_27/brightness_01/2020040416485200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":1,
      "vividness":5,
      "r":19,
      "g":3,
      "b":14,
      "hex":"#13030e",
      "file_location":"assets/colors_organized/hue_27/brightness_01/2020040416485201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":1,
      "vividness":6,
      "r":20,
      "g":0,
      "b":14,
      "hex":"#14000e",
      "file_location":"assets/colors_organized/hue_27/brightness_01/2020040416485300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":2,
      "vividness":1,
      "r":34,
      "g":32,
      "b":33,
      "hex":"#222021",
      "file_location":"assets/colors_organized/hue_27/brightness_02/2020040416483501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":2,
      "vividness":2,
      "r":34,
      "g":29,
      "b":33,
      "hex":"#221d21",
      "file_location":"assets/colors_organized/hue_27/brightness_02/2020040416483600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":2,
      "vividness":3,
      "r":34,
      "g":23,
      "b":31,
      "hex":"#22171f",
      "file_location":"assets/colors_organized/hue_27/brightness_02/2020040416483701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":2,
      "vividness":4,
      "r":35,
      "g":22,
      "b":32,
      "hex":"#231620",
      "file_location":"assets/colors_organized/hue_27/brightness_02/2020040416483800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":2,
      "vividness":5,
      "r":34,
      "g":18,
      "b":31,
      "hex":"#22121f",
      "file_location":"assets/colors_organized/hue_27/brightness_02/2020040416483801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":2,
      "vividness":6,
      "r":35,
      "g":14,
      "b":31,
      "hex":"#230e1f",
      "file_location":"assets/colors_organized/hue_27/brightness_02/2020040416483900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":2,
      "vividness":7,
      "r":34,
      "g":13,
      "b":30,
      "hex":"#220d1e",
      "file_location":"assets/colors_organized/hue_27/brightness_02/2020040416483901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":2,
      "vividness":8,
      "r":34,
      "g":9,
      "b":30,
      "hex":"#22091e",
      "file_location":"assets/colors_organized/hue_27/brightness_02/2020040416484000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":2,
      "vividness":9,
      "r":35,
      "g":7,
      "b":30,
      "hex":"#23071e",
      "file_location":"assets/colors_organized/hue_27/brightness_02/2020040416484100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":2,
      "vividness":10,
      "r":35,
      "g":2,
      "b":29,
      "hex":"#23021d",
      "file_location":"assets/colors_organized/hue_27/brightness_02/2020040416484200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":2,
      "vividness":11,
      "r":35,
      "g":3,
      "b":27,
      "hex":"#23031b",
      "file_location":"assets/colors_organized/hue_27/brightness_02/2020040416484201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":3,
      "vividness":1,
      "r":49,
      "g":43,
      "b":47,
      "hex":"#312b2f",
      "file_location":"assets/colors_organized/hue_27/brightness_03/2020040416482500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":3,
      "vividness":2,
      "r":49,
      "g":39,
      "b":47,
      "hex":"#31272f",
      "file_location":"assets/colors_organized/hue_27/brightness_03/2020040416482501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":3,
      "vividness":3,
      "r":49,
      "g":36,
      "b":46,
      "hex":"#31242e",
      "file_location":"assets/colors_organized/hue_27/brightness_03/2020040416482600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":3,
      "vividness":4,
      "r":50,
      "g":34,
      "b":45,
      "hex":"#32222d",
      "file_location":"assets/colors_organized/hue_27/brightness_03/2020040416482601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":3,
      "vividness":5,
      "r":50,
      "g":29,
      "b":46,
      "hex":"#321d2e",
      "file_location":"assets/colors_organized/hue_27/brightness_03/2020040416482700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":3,
      "vividness":6,
      "r":49,
      "g":26,
      "b":44,
      "hex":"#311a2c",
      "file_location":"assets/colors_organized/hue_27/brightness_03/2020040416482701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":3,
      "vividness":7,
      "r":50,
      "g":22,
      "b":44,
      "hex":"#32162c",
      "file_location":"assets/colors_organized/hue_27/brightness_03/2020040416482800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":3,
      "vividness":8,
      "r":49,
      "g":19,
      "b":43,
      "hex":"#31132b",
      "file_location":"assets/colors_organized/hue_27/brightness_03/2020040416482801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":3,
      "vividness":9,
      "r":49,
      "g":15,
      "b":42,
      "hex":"#310f2a",
      "file_location":"assets/colors_organized/hue_27/brightness_03/2020040416482900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":3,
      "vividness":10,
      "r":49,
      "g":13,
      "b":41,
      "hex":"#310d29",
      "file_location":"assets/colors_organized/hue_27/brightness_03/2020040416482901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":3,
      "vividness":11,
      "r":49,
      "g":10,
      "b":41,
      "hex":"#310a29",
      "file_location":"assets/colors_organized/hue_27/brightness_03/2020040416483000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":3,
      "vividness":12,
      "r":50,
      "g":6,
      "b":41,
      "hex":"#320629",
      "file_location":"assets/colors_organized/hue_27/brightness_03/2020040416483001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":3,
      "vividness":13,
      "r":49,
      "g":3,
      "b":39,
      "hex":"#310327",
      "file_location":"assets/colors_organized/hue_27/brightness_03/2020040416483100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":4,
      "vividness":1,
      "r":65,
      "g":60,
      "b":64,
      "hex":"#413c40",
      "file_location":"assets/colors_organized/hue_27/brightness_04/2020040416481301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":4,
      "vividness":2,
      "r":65,
      "g":55,
      "b":63,
      "hex":"#41373f",
      "file_location":"assets/colors_organized/hue_27/brightness_04/2020040416481400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":4,
      "vividness":3,
      "r":65,
      "g":52,
      "b":62,
      "hex":"#41343e",
      "file_location":"assets/colors_organized/hue_27/brightness_04/2020040416481401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":4,
      "vividness":4,
      "r":65,
      "g":47,
      "b":61,
      "hex":"#412f3d",
      "file_location":"assets/colors_organized/hue_27/brightness_04/2020040416481500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":4,
      "vividness":5,
      "r":65,
      "g":42,
      "b":60,
      "hex":"#412a3c",
      "file_location":"assets/colors_organized/hue_27/brightness_04/2020040416481501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":4,
      "vividness":6,
      "r":64,
      "g":39,
      "b":58,
      "hex":"#40273a",
      "file_location":"assets/colors_organized/hue_27/brightness_04/2020040416481600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":4,
      "vividness":7,
      "r":64,
      "g":34,
      "b":58,
      "hex":"#40223a",
      "file_location":"assets/colors_organized/hue_27/brightness_04/2020040416481601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":4,
      "vividness":8,
      "r":64,
      "g":30,
      "b":57,
      "hex":"#401e39",
      "file_location":"assets/colors_organized/hue_27/brightness_04/2020040416481700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":4,
      "vividness":9,
      "r":65,
      "g":26,
      "b":55,
      "hex":"#411a37",
      "file_location":"assets/colors_organized/hue_27/brightness_04/2020040416481701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":4,
      "vividness":10,
      "r":65,
      "g":21,
      "b":56,
      "hex":"#411538",
      "file_location":"assets/colors_organized/hue_27/brightness_04/2020040416481800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":4,
      "vividness":11,
      "r":65,
      "g":18,
      "b":54,
      "hex":"#411236",
      "file_location":"assets/colors_organized/hue_27/brightness_04/2020040416481801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":4,
      "vividness":12,
      "r":65,
      "g":12,
      "b":54,
      "hex":"#410c36",
      "file_location":"assets/colors_organized/hue_27/brightness_04/2020040416481900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":4,
      "vividness":13,
      "r":64,
      "g":7,
      "b":52,
      "hex":"#400734",
      "file_location":"assets/colors_organized/hue_27/brightness_04/2020040416482000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":4,
      "vividness":14,
      "r":65,
      "g":3,
      "b":52,
      "hex":"#410334",
      "file_location":"assets/colors_organized/hue_27/brightness_04/2020040416482001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":5,
      "vividness":1,
      "r":79,
      "g":74,
      "b":78,
      "hex":"#4f4a4e",
      "file_location":"assets/colors_organized/hue_27/brightness_05/2020040416480400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":5,
      "vividness":2,
      "r":80,
      "g":68,
      "b":78,
      "hex":"#50444e",
      "file_location":"assets/colors_organized/hue_27/brightness_05/2020040416480401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":5,
      "vividness":3,
      "r":80,
      "g":64,
      "b":75,
      "hex":"#50404b",
      "file_location":"assets/colors_organized/hue_27/brightness_05/2020040416480402-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":5,
      "vividness":4,
      "r":80,
      "g":57,
      "b":75,
      "hex":"#50394b",
      "file_location":"assets/colors_organized/hue_27/brightness_05/2020040416480500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":5,
      "vividness":5,
      "r":80,
      "g":52,
      "b":74,
      "hex":"#50344a",
      "file_location":"assets/colors_organized/hue_27/brightness_05/2020040416480501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":5,
      "vividness":6,
      "r":80,
      "g":48,
      "b":72,
      "hex":"#503048",
      "file_location":"assets/colors_organized/hue_27/brightness_05/2020040416480600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":5,
      "vividness":7,
      "r":80,
      "g":41,
      "b":72,
      "hex":"#502948",
      "file_location":"assets/colors_organized/hue_27/brightness_05/2020040416480601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":5,
      "vividness":8,
      "r":80,
      "g":36,
      "b":71,
      "hex":"#502447",
      "file_location":"assets/colors_organized/hue_27/brightness_05/2020040416480700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":5,
      "vividness":9,
      "r":79,
      "g":31,
      "b":69,
      "hex":"#4f1f45",
      "file_location":"assets/colors_organized/hue_27/brightness_05/2020040416480701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":5,
      "vividness":10,
      "r":79,
      "g":26,
      "b":68,
      "hex":"#4f1a44",
      "file_location":"assets/colors_organized/hue_27/brightness_05/2020040416480800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":5,
      "vividness":11,
      "r":79,
      "g":22,
      "b":67,
      "hex":"#4f1643",
      "file_location":"assets/colors_organized/hue_27/brightness_05/2020040416480801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":5,
      "vividness":12,
      "r":79,
      "g":15,
      "b":67,
      "hex":"#4f0f43",
      "file_location":"assets/colors_organized/hue_27/brightness_05/2020040416480900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":5,
      "vividness":13,
      "r":79,
      "g":10,
      "b":65,
      "hex":"#4f0a41",
      "file_location":"assets/colors_organized/hue_27/brightness_05/2020040416480901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":5,
      "vividness":14,
      "r":80,
      "g":6,
      "b":65,
      "hex":"#500641",
      "file_location":"assets/colors_organized/hue_27/brightness_05/2020040416481000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":6,
      "vividness":1,
      "r":94,
      "g":88,
      "b":92,
      "hex":"#5e585c",
      "file_location":"assets/colors_organized/hue_27/brightness_06/2020040416475401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":6,
      "vividness":2,
      "r":95,
      "g":82,
      "b":92,
      "hex":"#5f525c",
      "file_location":"assets/colors_organized/hue_27/brightness_06/2020040416475500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":6,
      "vividness":3,
      "r":94,
      "g":76,
      "b":90,
      "hex":"#5e4c5a",
      "file_location":"assets/colors_organized/hue_27/brightness_06/2020040416475501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":6,
      "vividness":4,
      "r":94,
      "g":69,
      "b":90,
      "hex":"#5e455a",
      "file_location":"assets/colors_organized/hue_27/brightness_06/2020040416475502-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":6,
      "vividness":5,
      "r":95,
      "g":63,
      "b":87,
      "hex":"#5f3f57",
      "file_location":"assets/colors_organized/hue_27/brightness_06/2020040416475600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":6,
      "vividness":6,
      "r":95,
      "g":56,
      "b":87,
      "hex":"#5f3857",
      "file_location":"assets/colors_organized/hue_27/brightness_06/2020040416475601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":6,
      "vividness":7,
      "r":95,
      "g":49,
      "b":85,
      "hex":"#5f3155",
      "file_location":"assets/colors_organized/hue_27/brightness_06/2020040416475700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":6,
      "vividness":8,
      "r":94,
      "g":44,
      "b":82,
      "hex":"#5e2c52",
      "file_location":"assets/colors_organized/hue_27/brightness_06/2020040416475701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":6,
      "vividness":9,
      "r":95,
      "g":38,
      "b":83,
      "hex":"#5f2653",
      "file_location":"assets/colors_organized/hue_27/brightness_06/2020040416475800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":6,
      "vividness":10,
      "r":95,
      "g":31,
      "b":83,
      "hex":"#5f1f53",
      "file_location":"assets/colors_organized/hue_27/brightness_06/2020040416475801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":6,
      "vividness":11,
      "r":94,
      "g":25,
      "b":80,
      "hex":"#5e1950",
      "file_location":"assets/colors_organized/hue_27/brightness_06/2020040416475802-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":6,
      "vividness":12,
      "r":95,
      "g":20,
      "b":79,
      "hex":"#5f144f",
      "file_location":"assets/colors_organized/hue_27/brightness_06/2020040416475900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":6,
      "vividness":13,
      "r":95,
      "g":13,
      "b":79,
      "hex":"#5f0d4f",
      "file_location":"assets/colors_organized/hue_27/brightness_06/2020040416475901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":6,
      "vividness":14,
      "r":94,
      "g":7,
      "b":76,
      "hex":"#5e074c",
      "file_location":"assets/colors_organized/hue_27/brightness_06/2020040416480000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":7,
      "vividness":1,
      "r":109,
      "g":102,
      "b":109,
      "hex":"#6d666d",
      "file_location":"assets/colors_organized/hue_27/brightness_07/2020040416474401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":7,
      "vividness":2,
      "r":109,
      "g":96,
      "b":106,
      "hex":"#6d606a",
      "file_location":"assets/colors_organized/hue_27/brightness_07/2020040416474500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":7,
      "vividness":3,
      "r":110,
      "g":87,
      "b":105,
      "hex":"#6e5769",
      "file_location":"assets/colors_organized/hue_27/brightness_07/2020040416474501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":7,
      "vividness":4,
      "r":110,
      "g":80,
      "b":104,
      "hex":"#6e5068",
      "file_location":"assets/colors_organized/hue_27/brightness_07/2020040416474600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":7,
      "vividness":5,
      "r":109,
      "g":73,
      "b":101,
      "hex":"#6d4965",
      "file_location":"assets/colors_organized/hue_27/brightness_07/2020040416474601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":7,
      "vividness":6,
      "r":110,
      "g":66,
      "b":101,
      "hex":"#6e4265",
      "file_location":"assets/colors_organized/hue_27/brightness_07/2020040416474700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":7,
      "vividness":7,
      "r":110,
      "g":57,
      "b":99,
      "hex":"#6e3963",
      "file_location":"assets/colors_organized/hue_27/brightness_07/2020040416474701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":7,
      "vividness":8,
      "r":110,
      "g":51,
      "b":99,
      "hex":"#6e3363",
      "file_location":"assets/colors_organized/hue_27/brightness_07/2020040416474800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":7,
      "vividness":9,
      "r":110,
      "g":43,
      "b":96,
      "hex":"#6e2b60",
      "file_location":"assets/colors_organized/hue_27/brightness_07/2020040416474801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":7,
      "vividness":10,
      "r":110,
      "g":36,
      "b":95,
      "hex":"#6e245f",
      "file_location":"assets/colors_organized/hue_27/brightness_07/2020040416474900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":7,
      "vividness":11,
      "r":110,
      "g":30,
      "b":93,
      "hex":"#6e1e5d",
      "file_location":"assets/colors_organized/hue_27/brightness_07/2020040416474901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":7,
      "vividness":12,
      "r":109,
      "g":22,
      "b":91,
      "hex":"#6d165b",
      "file_location":"assets/colors_organized/hue_27/brightness_07/2020040416474902-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":7,
      "vividness":13,
      "r":109,
      "g":12,
      "b":89,
      "hex":"#6d0c59",
      "file_location":"assets/colors_organized/hue_27/brightness_07/2020040416475000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":7,
      "vividness":14,
      "r":110,
      "g":7,
      "b":90,
      "hex":"#6e075a",
      "file_location":"assets/colors_organized/hue_27/brightness_07/2020040416475001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":8,
      "vividness":1,
      "r":123,
      "g":116,
      "b":123,
      "hex":"#7b747b",
      "file_location":"assets/colors_organized/hue_27/brightness_08/2020040416473301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":8,
      "vividness":2,
      "r":124,
      "g":108,
      "b":121,
      "hex":"#7c6c79",
      "file_location":"assets/colors_organized/hue_27/brightness_08/2020040416473302-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":8,
      "vividness":3,
      "r":124,
      "g":99,
      "b":120,
      "hex":"#7c6378",
      "file_location":"assets/colors_organized/hue_27/brightness_08/2020040416473400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":8,
      "vividness":4,
      "r":124,
      "g":91,
      "b":118,
      "hex":"#7c5b76",
      "file_location":"assets/colors_organized/hue_27/brightness_08/2020040416473401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":8,
      "vividness":5,
      "r":124,
      "g":83,
      "b":115,
      "hex":"#7c5373",
      "file_location":"assets/colors_organized/hue_27/brightness_08/2020040416473500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":8,
      "vividness":6,
      "r":124,
      "g":74,
      "b":112,
      "hex":"#7c4a70",
      "file_location":"assets/colors_organized/hue_27/brightness_08/2020040416473501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":8,
      "vividness":7,
      "r":124,
      "g":66,
      "b":114,
      "hex":"#7c4272",
      "file_location":"assets/colors_organized/hue_27/brightness_08/2020040416473600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":8,
      "vividness":8,
      "r":124,
      "g":57,
      "b":110,
      "hex":"#7c396e",
      "file_location":"assets/colors_organized/hue_27/brightness_08/2020040416473601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":8,
      "vividness":9,
      "r":124,
      "g":49,
      "b":108,
      "hex":"#7c316c",
      "file_location":"assets/colors_organized/hue_27/brightness_08/2020040416473700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":8,
      "vividness":10,
      "r":123,
      "g":41,
      "b":107,
      "hex":"#7b296b",
      "file_location":"assets/colors_organized/hue_27/brightness_08/2020040416473701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":8,
      "vividness":11,
      "r":124,
      "g":34,
      "b":106,
      "hex":"#7c226a",
      "file_location":"assets/colors_organized/hue_27/brightness_08/2020040416473800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":8,
      "vividness":12,
      "r":124,
      "g":23,
      "b":103,
      "hex":"#7c1767",
      "file_location":"assets/colors_organized/hue_27/brightness_08/2020040416473801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":8,
      "vividness":13,
      "r":123,
      "g":14,
      "b":103,
      "hex":"#7b0e67",
      "file_location":"assets/colors_organized/hue_27/brightness_08/2020040416473900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":8,
      "vividness":14,
      "r":124,
      "g":6,
      "b":100,
      "hex":"#7c0664",
      "file_location":"assets/colors_organized/hue_27/brightness_08/2020040416473901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":9,
      "vividness":1,
      "r":140,
      "g":130,
      "b":138,
      "hex":"#8c828a",
      "file_location":"assets/colors_organized/hue_27/brightness_09/2020040416472301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":9,
      "vividness":2,
      "r":139,
      "g":121,
      "b":135,
      "hex":"#8b7987",
      "file_location":"assets/colors_organized/hue_27/brightness_09/2020040416472400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":9,
      "vividness":3,
      "r":139,
      "g":111,
      "b":134,
      "hex":"#8b6f86",
      "file_location":"assets/colors_organized/hue_27/brightness_09/2020040416472401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":9,
      "vividness":4,
      "r":138,
      "g":101,
      "b":132,
      "hex":"#8a6584",
      "file_location":"assets/colors_organized/hue_27/brightness_09/2020040416472500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":9,
      "vividness":5,
      "r":139,
      "g":93,
      "b":130,
      "hex":"#8b5d82",
      "file_location":"assets/colors_organized/hue_27/brightness_09/2020040416472501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":9,
      "vividness":6,
      "r":139,
      "g":83,
      "b":128,
      "hex":"#8b5380",
      "file_location":"assets/colors_organized/hue_27/brightness_09/2020040416472600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":9,
      "vividness":7,
      "r":138,
      "g":74,
      "b":126,
      "hex":"#8a4a7e",
      "file_location":"assets/colors_organized/hue_27/brightness_09/2020040416472601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":9,
      "vividness":8,
      "r":139,
      "g":65,
      "b":124,
      "hex":"#8b417c",
      "file_location":"assets/colors_organized/hue_27/brightness_09/2020040416472700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":9,
      "vividness":9,
      "r":140,
      "g":55,
      "b":123,
      "hex":"#8c377b",
      "file_location":"assets/colors_organized/hue_27/brightness_09/2020040416472701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":9,
      "vividness":10,
      "r":139,
      "g":45,
      "b":121,
      "hex":"#8b2d79",
      "file_location":"assets/colors_organized/hue_27/brightness_09/2020040416472702-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":9,
      "vividness":11,
      "r":139,
      "g":37,
      "b":120,
      "hex":"#8b2578",
      "file_location":"assets/colors_organized/hue_27/brightness_09/2020040416472800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":9,
      "vividness":12,
      "r":139,
      "g":26,
      "b":116,
      "hex":"#8b1a74",
      "file_location":"assets/colors_organized/hue_27/brightness_09/2020040416472801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":9,
      "vividness":13,
      "r":139,
      "g":18,
      "b":115,
      "hex":"#8b1273",
      "file_location":"assets/colors_organized/hue_27/brightness_09/2020040416472900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":9,
      "vividness":14,
      "r":139,
      "g":10,
      "b":113,
      "hex":"#8b0a71",
      "file_location":"assets/colors_organized/hue_27/brightness_09/2020040416472901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":10,
      "vividness":1,
      "r":154,
      "g":144,
      "b":152,
      "hex":"#9a9098",
      "file_location":"assets/colors_organized/hue_27/brightness_10/2020040416471300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":10,
      "vividness":2,
      "r":155,
      "g":134,
      "b":151,
      "hex":"#9b8697",
      "file_location":"assets/colors_organized/hue_27/brightness_10/2020040416471301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":10,
      "vividness":3,
      "r":153,
      "g":123,
      "b":147,
      "hex":"#997b93",
      "file_location":"assets/colors_organized/hue_27/brightness_10/2020040416471400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":10,
      "vividness":4,
      "r":154,
      "g":113,
      "b":147,
      "hex":"#9a7193",
      "file_location":"assets/colors_organized/hue_27/brightness_10/2020040416471401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":10,
      "vividness":5,
      "r":154,
      "g":103,
      "b":144,
      "hex":"#9a6790",
      "file_location":"assets/colors_organized/hue_27/brightness_10/2020040416471500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":10,
      "vividness":6,
      "r":154,
      "g":92,
      "b":143,
      "hex":"#9a5c8f",
      "file_location":"assets/colors_organized/hue_27/brightness_10/2020040416471501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":10,
      "vividness":7,
      "r":153,
      "g":82,
      "b":140,
      "hex":"#99528c",
      "file_location":"assets/colors_organized/hue_27/brightness_10/2020040416471600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":10,
      "vividness":8,
      "r":154,
      "g":72,
      "b":138,
      "hex":"#9a488a",
      "file_location":"assets/colors_organized/hue_27/brightness_10/2020040416471601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":10,
      "vividness":9,
      "r":154,
      "g":60,
      "b":136,
      "hex":"#9a3c88",
      "file_location":"assets/colors_organized/hue_27/brightness_10/2020040416471700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":10,
      "vividness":10,
      "r":154,
      "g":51,
      "b":134,
      "hex":"#9a3386",
      "file_location":"assets/colors_organized/hue_27/brightness_10/2020040416471701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":10,
      "vividness":11,
      "r":154,
      "g":41,
      "b":133,
      "hex":"#9a2985",
      "file_location":"assets/colors_organized/hue_27/brightness_10/2020040416471800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":10,
      "vividness":12,
      "r":154,
      "g":29,
      "b":129,
      "hex":"#9a1d81",
      "file_location":"assets/colors_organized/hue_27/brightness_10/2020040416471900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":10,
      "vividness":13,
      "r":153,
      "g":20,
      "b":127,
      "hex":"#99147f",
      "file_location":"assets/colors_organized/hue_27/brightness_10/2020040416471901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":10,
      "vividness":14,
      "r":154,
      "g":10,
      "b":124,
      "hex":"#9a0a7c",
      "file_location":"assets/colors_organized/hue_27/brightness_10/2020040416472000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":11,
      "vividness":1,
      "r":169,
      "g":157,
      "b":167,
      "hex":"#a99da7",
      "file_location":"assets/colors_organized/hue_27/brightness_11/2020040416470301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":11,
      "vividness":2,
      "r":169,
      "g":148,
      "b":165,
      "hex":"#a994a5",
      "file_location":"assets/colors_organized/hue_27/brightness_11/2020040416470302-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":11,
      "vividness":3,
      "r":169,
      "g":135,
      "b":162,
      "hex":"#a987a2",
      "file_location":"assets/colors_organized/hue_27/brightness_11/2020040416470400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":11,
      "vividness":4,
      "r":170,
      "g":124,
      "b":160,
      "hex":"#aa7ca0",
      "file_location":"assets/colors_organized/hue_27/brightness_11/2020040416470401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":11,
      "vividness":5,
      "r":169,
      "g":113,
      "b":158,
      "hex":"#a9719e",
      "file_location":"assets/colors_organized/hue_27/brightness_11/2020040416470500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":11,
      "vividness":6,
      "r":170,
      "g":101,
      "b":156,
      "hex":"#aa659c",
      "file_location":"assets/colors_organized/hue_27/brightness_11/2020040416470501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":11,
      "vividness":7,
      "r":169,
      "g":91,
      "b":153,
      "hex":"#a95b99",
      "file_location":"assets/colors_organized/hue_27/brightness_11/2020040416470600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":11,
      "vividness":8,
      "r":169,
      "g":79,
      "b":151,
      "hex":"#a94f97",
      "file_location":"assets/colors_organized/hue_27/brightness_11/2020040416470601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":11,
      "vividness":9,
      "r":169,
      "g":67,
      "b":150,
      "hex":"#a94396",
      "file_location":"assets/colors_organized/hue_27/brightness_11/2020040416470700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":11,
      "vividness":10,
      "r":169,
      "g":56,
      "b":148,
      "hex":"#a93894",
      "file_location":"assets/colors_organized/hue_27/brightness_11/2020040416470701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":11,
      "vividness":11,
      "r":169,
      "g":44,
      "b":144,
      "hex":"#a92c90",
      "file_location":"assets/colors_organized/hue_27/brightness_11/2020040416470800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":11,
      "vividness":12,
      "r":169,
      "g":33,
      "b":141,
      "hex":"#a9218d",
      "file_location":"assets/colors_organized/hue_27/brightness_11/2020040416470801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":11,
      "vividness":13,
      "r":169,
      "g":22,
      "b":139,
      "hex":"#a9168b",
      "file_location":"assets/colors_organized/hue_27/brightness_11/2020040416470900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":11,
      "vividness":14,
      "r":169,
      "g":10,
      "b":138,
      "hex":"#a90a8a",
      "file_location":"assets/colors_organized/hue_27/brightness_11/2020040416470901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":12,
      "vividness":1,
      "r":184,
      "g":172,
      "b":182,
      "hex":"#b8acb6",
      "file_location":"assets/colors_organized/hue_27/brightness_12/2020040416465301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":12,
      "vividness":2,
      "r":184,
      "g":161,
      "b":179,
      "hex":"#b8a1b3",
      "file_location":"assets/colors_organized/hue_27/brightness_12/2020040416465302-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":12,
      "vividness":3,
      "r":184,
      "g":147,
      "b":178,
      "hex":"#b893b2",
      "file_location":"assets/colors_organized/hue_27/brightness_12/2020040416465400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":12,
      "vividness":4,
      "r":183,
      "g":135,
      "b":173,
      "hex":"#b787ad",
      "file_location":"assets/colors_organized/hue_27/brightness_12/2020040416465401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":12,
      "vividness":5,
      "r":185,
      "g":123,
      "b":172,
      "hex":"#b97bac",
      "file_location":"assets/colors_organized/hue_27/brightness_12/2020040416465500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":12,
      "vividness":6,
      "r":184,
      "g":110,
      "b":169,
      "hex":"#b86ea9",
      "file_location":"assets/colors_organized/hue_27/brightness_12/2020040416465501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":12,
      "vividness":7,
      "r":184,
      "g":99,
      "b":167,
      "hex":"#b863a7",
      "file_location":"assets/colors_organized/hue_27/brightness_12/2020040416465600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":12,
      "vividness":8,
      "r":184,
      "g":86,
      "b":163,
      "hex":"#b856a3",
      "file_location":"assets/colors_organized/hue_27/brightness_12/2020040416465601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":12,
      "vividness":9,
      "r":183,
      "g":73,
      "b":162,
      "hex":"#b749a2",
      "file_location":"assets/colors_organized/hue_27/brightness_12/2020040416465700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":12,
      "vividness":10,
      "r":185,
      "g":60,
      "b":160,
      "hex":"#b93ca0",
      "file_location":"assets/colors_organized/hue_27/brightness_12/2020040416465701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":12,
      "vividness":11,
      "r":184,
      "g":48,
      "b":156,
      "hex":"#b8309c",
      "file_location":"assets/colors_organized/hue_27/brightness_12/2020040416465800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":12,
      "vividness":12,
      "r":184,
      "g":36,
      "b":156,
      "hex":"#b8249c",
      "file_location":"assets/colors_organized/hue_27/brightness_12/2020040416465801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":12,
      "vividness":13,
      "r":184,
      "g":23,
      "b":152,
      "hex":"#b81798",
      "file_location":"assets/colors_organized/hue_27/brightness_12/2020040416465900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":12,
      "vividness":14,
      "r":185,
      "g":13,
      "b":151,
      "hex":"#b90d97",
      "file_location":"assets/colors_organized/hue_27/brightness_12/2020040416465901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":13,
      "vividness":1,
      "r":199,
      "g":186,
      "b":196,
      "hex":"#c7bac4",
      "file_location":"assets/colors_organized/hue_27/brightness_13/2020040416464301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":13,
      "vividness":2,
      "r":198,
      "g":173,
      "b":194,
      "hex":"#c6adc2",
      "file_location":"assets/colors_organized/hue_27/brightness_13/2020040416464400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":13,
      "vividness":3,
      "r":199,
      "g":160,
      "b":191,
      "hex":"#c7a0bf",
      "file_location":"assets/colors_organized/hue_27/brightness_13/2020040416464401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":13,
      "vividness":4,
      "r":199,
      "g":146,
      "b":188,
      "hex":"#c792bc",
      "file_location":"assets/colors_organized/hue_27/brightness_13/2020040416464402-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":13,
      "vividness":5,
      "r":200,
      "g":133,
      "b":186,
      "hex":"#c885ba",
      "file_location":"assets/colors_organized/hue_27/brightness_13/2020040416464500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":13,
      "vividness":6,
      "r":199,
      "g":119,
      "b":182,
      "hex":"#c777b6",
      "file_location":"assets/colors_organized/hue_27/brightness_13/2020040416464501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":13,
      "vividness":7,
      "r":199,
      "g":105,
      "b":179,
      "hex":"#c769b3",
      "file_location":"assets/colors_organized/hue_27/brightness_13/2020040416464600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":13,
      "vividness":8,
      "r":198,
      "g":93,
      "b":178,
      "hex":"#c65db2",
      "file_location":"assets/colors_organized/hue_27/brightness_13/2020040416464601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":13,
      "vividness":9,
      "r":199,
      "g":79,
      "b":176,
      "hex":"#c74fb0",
      "file_location":"assets/colors_organized/hue_27/brightness_13/2020040416464700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":13,
      "vividness":10,
      "r":199,
      "g":66,
      "b":173,
      "hex":"#c742ad",
      "file_location":"assets/colors_organized/hue_27/brightness_13/2020040416464701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":13,
      "vividness":11,
      "r":200,
      "g":53,
      "b":170,
      "hex":"#c835aa",
      "file_location":"assets/colors_organized/hue_27/brightness_13/2020040416464800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":13,
      "vividness":12,
      "r":199,
      "g":38,
      "b":167,
      "hex":"#c726a7",
      "file_location":"assets/colors_organized/hue_27/brightness_13/2020040416464801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":13,
      "vividness":13,
      "r":199,
      "g":27,
      "b":165,
      "hex":"#c71ba5",
      "file_location":"assets/colors_organized/hue_27/brightness_13/2020040416464900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":13,
      "vividness":14,
      "r":199,
      "g":12,
      "b":161,
      "hex":"#c70ca1",
      "file_location":"assets/colors_organized/hue_27/brightness_13/2020040416464901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":14,
      "vividness":1,
      "r":214,
      "g":201,
      "b":211,
      "hex":"#d6c9d3",
      "file_location":"assets/colors_organized/hue_27/brightness_14/2020040416463200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":14,
      "vividness":2,
      "r":215,
      "g":185,
      "b":209,
      "hex":"#d7b9d1",
      "file_location":"assets/colors_organized/hue_27/brightness_14/2020040416463201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":14,
      "vividness":3,
      "r":215,
      "g":171,
      "b":206,
      "hex":"#d7abce",
      "file_location":"assets/colors_organized/hue_27/brightness_14/2020040416463300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":14,
      "vividness":4,
      "r":215,
      "g":158,
      "b":203,
      "hex":"#d79ecb",
      "file_location":"assets/colors_organized/hue_27/brightness_14/2020040416463301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":14,
      "vividness":5,
      "r":214,
      "g":143,
      "b":199,
      "hex":"#d68fc7",
      "file_location":"assets/colors_organized/hue_27/brightness_14/2020040416463400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":14,
      "vividness":6,
      "r":214,
      "g":129,
      "b":197,
      "hex":"#d681c5",
      "file_location":"assets/colors_organized/hue_27/brightness_14/2020040416463401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":14,
      "vividness":7,
      "r":215,
      "g":114,
      "b":194,
      "hex":"#d772c2",
      "file_location":"assets/colors_organized/hue_27/brightness_14/2020040416463500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":14,
      "vividness":8,
      "r":215,
      "g":100,
      "b":191,
      "hex":"#d764bf",
      "file_location":"assets/colors_organized/hue_27/brightness_14/2020040416463501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":14,
      "vividness":9,
      "r":214,
      "g":85,
      "b":188,
      "hex":"#d655bc",
      "file_location":"assets/colors_organized/hue_27/brightness_14/2020040416463600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":14,
      "vividness":10,
      "r":214,
      "g":71,
      "b":185,
      "hex":"#d647b9",
      "file_location":"assets/colors_organized/hue_27/brightness_14/2020040416463601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":14,
      "vividness":11,
      "r":215,
      "g":56,
      "b":184,
      "hex":"#d738b8",
      "file_location":"assets/colors_organized/hue_27/brightness_14/2020040416463700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":14,
      "vividness":12,
      "r":214,
      "g":42,
      "b":180,
      "hex":"#d62ab4",
      "file_location":"assets/colors_organized/hue_27/brightness_14/2020040416463701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":14,
      "vividness":13,
      "r":214,
      "g":27,
      "b":176,
      "hex":"#d61bb0",
      "file_location":"assets/colors_organized/hue_27/brightness_14/2020040416463800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":14,
      "vividness":14,
      "r":214,
      "g":12,
      "b":174,
      "hex":"#d60cae",
      "file_location":"assets/colors_organized/hue_27/brightness_14/2020040416463801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":15,
      "vividness":1,
      "r":228,
      "g":215,
      "b":225,
      "hex":"#e4d7e1",
      "file_location":"assets/colors_organized/hue_27/brightness_15/2020040416462101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":15,
      "vividness":2,
      "r":229,
      "g":199,
      "b":223,
      "hex":"#e5c7df",
      "file_location":"assets/colors_organized/hue_27/brightness_15/2020040416462200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":15,
      "vividness":3,
      "r":229,
      "g":183,
      "b":220,
      "hex":"#e5b7dc",
      "file_location":"assets/colors_organized/hue_27/brightness_15/2020040416462201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":15,
      "vividness":4,
      "r":230,
      "g":168,
      "b":217,
      "hex":"#e6a8d9",
      "file_location":"assets/colors_organized/hue_27/brightness_15/2020040416462300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":15,
      "vividness":5,
      "r":228,
      "g":152,
      "b":214,
      "hex":"#e498d6",
      "file_location":"assets/colors_organized/hue_27/brightness_15/2020040416462301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":15,
      "vividness":6,
      "r":229,
      "g":137,
      "b":210,
      "hex":"#e589d2",
      "file_location":"assets/colors_organized/hue_27/brightness_15/2020040416462400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":15,
      "vividness":7,
      "r":230,
      "g":122,
      "b":208,
      "hex":"#e67ad0",
      "file_location":"assets/colors_organized/hue_27/brightness_15/2020040416462500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":15,
      "vividness":8,
      "r":229,
      "g":108,
      "b":205,
      "hex":"#e56ccd",
      "file_location":"assets/colors_organized/hue_27/brightness_15/2020040416462501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":15,
      "vividness":9,
      "r":229,
      "g":91,
      "b":202,
      "hex":"#e55bca",
      "file_location":"assets/colors_organized/hue_27/brightness_15/2020040416462600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":15,
      "vividness":10,
      "r":230,
      "g":76,
      "b":198,
      "hex":"#e64cc6",
      "file_location":"assets/colors_organized/hue_27/brightness_15/2020040416462601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":15,
      "vividness":11,
      "r":228,
      "g":60,
      "b":195,
      "hex":"#e43cc3",
      "file_location":"assets/colors_organized/hue_27/brightness_15/2020040416462700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":15,
      "vividness":12,
      "r":229,
      "g":45,
      "b":193,
      "hex":"#e52dc1",
      "file_location":"assets/colors_organized/hue_27/brightness_15/2020040416462701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":15,
      "vividness":13,
      "r":230,
      "g":30,
      "b":190,
      "hex":"#e61ebe",
      "file_location":"assets/colors_organized/hue_27/brightness_15/2020040416462800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":27,
      "brightness":15,
      "vividness":14,
      "r":228,
      "g":15,
      "b":185,
      "hex":"#e40fb9",
      "file_location":"assets/colors_organized/hue_27/brightness_15/2020040416462801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":1,
      "vividness":1,
      "r":19,
      "g":14,
      "b":18,
      "hex":"#130e12",
      "file_location":"assets/colors_organized/hue_28/brightness_01/2020040416512500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":1,
      "vividness":2,
      "r":19,
      "g":10,
      "b":15,
      "hex":"#130a0f",
      "file_location":"assets/colors_organized/hue_28/brightness_01/2020040416512602-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":1,
      "vividness":3,
      "r":19,
      "g":6,
      "b":15,
      "hex":"#13060f",
      "file_location":"assets/colors_organized/hue_28/brightness_01/2020040416512701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":1,
      "vividness":4,
      "r":19,
      "g":6,
      "b":13,
      "hex":"#13060d",
      "file_location":"assets/colors_organized/hue_28/brightness_01/2020040416512800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":1,
      "vividness":5,
      "r":20,
      "g":3,
      "b":13,
      "hex":"#14030d",
      "file_location":"assets/colors_organized/hue_28/brightness_01/2020040416512900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":1,
      "vividness":6,
      "r":20,
      "g":0,
      "b":12,
      "hex":"#14000c",
      "file_location":"assets/colors_organized/hue_28/brightness_01/2020040416513000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":2,
      "vividness":1,
      "r":34,
      "g":28,
      "b":32,
      "hex":"#221c20",
      "file_location":"assets/colors_organized/hue_28/brightness_02/2020040416511501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":2,
      "vividness":2,
      "r":34,
      "g":25,
      "b":30,
      "hex":"#22191e",
      "file_location":"assets/colors_organized/hue_28/brightness_02/2020040416511600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":2,
      "vividness":3,
      "r":34,
      "g":23,
      "b":29,
      "hex":"#22171d",
      "file_location":"assets/colors_organized/hue_28/brightness_02/2020040416511601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":2,
      "vividness":4,
      "r":34,
      "g":21,
      "b":30,
      "hex":"#22151e",
      "file_location":"assets/colors_organized/hue_28/brightness_02/2020040416511602-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":2,
      "vividness":5,
      "r":35,
      "g":18,
      "b":28,
      "hex":"#23121c",
      "file_location":"assets/colors_organized/hue_28/brightness_02/2020040416511700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":2,
      "vividness":6,
      "r":35,
      "g":15,
      "b":27,
      "hex":"#230f1b",
      "file_location":"assets/colors_organized/hue_28/brightness_02/2020040416511701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":2,
      "vividness":7,
      "r":35,
      "g":13,
      "b":26,
      "hex":"#230d1a",
      "file_location":"assets/colors_organized/hue_28/brightness_02/2020040416511800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":2,
      "vividness":8,
      "r":34,
      "g":10,
      "b":24,
      "hex":"#220a18",
      "file_location":"assets/colors_organized/hue_28/brightness_02/2020040416511801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":2,
      "vividness":9,
      "r":33,
      "g":9,
      "b":22,
      "hex":"#210916",
      "file_location":"assets/colors_organized/hue_28/brightness_02/2020040416511802-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":2,
      "vividness":10,
      "r":35,
      "g":7,
      "b":22,
      "hex":"#230716",
      "file_location":"assets/colors_organized/hue_28/brightness_02/2020040416511900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":2,
      "vividness":11,
      "r":34,
      "g":3,
      "b":21,
      "hex":"#220315",
      "file_location":"assets/colors_organized/hue_28/brightness_02/2020040416511901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":3,
      "vividness":1,
      "r":50,
      "g":46,
      "b":47,
      "hex":"#322e2f",
      "file_location":"assets/colors_organized/hue_28/brightness_03/2020040416510501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":3,
      "vividness":2,
      "r":49,
      "g":40,
      "b":45,
      "hex":"#31282d",
      "file_location":"assets/colors_organized/hue_28/brightness_03/2020040416510600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":3,
      "vividness":3,
      "r":49,
      "g":36,
      "b":45,
      "hex":"#31242d",
      "file_location":"assets/colors_organized/hue_28/brightness_03/2020040416510601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":3,
      "vividness":4,
      "r":49,
      "g":33,
      "b":43,
      "hex":"#31212b",
      "file_location":"assets/colors_organized/hue_28/brightness_03/2020040416510700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":3,
      "vividness":5,
      "r":50,
      "g":30,
      "b":42,
      "hex":"#321e2a",
      "file_location":"assets/colors_organized/hue_28/brightness_03/2020040416510701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":3,
      "vividness":6,
      "r":49,
      "g":27,
      "b":39,
      "hex":"#311b27",
      "file_location":"assets/colors_organized/hue_28/brightness_03/2020040416510800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":3,
      "vividness":7,
      "r":49,
      "g":22,
      "b":37,
      "hex":"#311625",
      "file_location":"assets/colors_organized/hue_28/brightness_03/2020040416510801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":3,
      "vividness":8,
      "r":49,
      "g":20,
      "b":38,
      "hex":"#311426",
      "file_location":"assets/colors_organized/hue_28/brightness_03/2020040416510802-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":3,
      "vividness":9,
      "r":49,
      "g":14,
      "b":34,
      "hex":"#310e22",
      "file_location":"assets/colors_organized/hue_28/brightness_03/2020040416510900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":3,
      "vividness":10,
      "r":50,
      "g":12,
      "b":35,
      "hex":"#320c23",
      "file_location":"assets/colors_organized/hue_28/brightness_03/2020040416510901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":3,
      "vividness":11,
      "r":49,
      "g":9,
      "b":33,
      "hex":"#310921",
      "file_location":"assets/colors_organized/hue_28/brightness_03/2020040416511000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":3,
      "vividness":12,
      "r":49,
      "g":7,
      "b":31,
      "hex":"#31071f",
      "file_location":"assets/colors_organized/hue_28/brightness_03/2020040416511001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":3,
      "vividness":13,
      "r":49,
      "g":3,
      "b":31,
      "hex":"#31031f",
      "file_location":"assets/colors_organized/hue_28/brightness_03/2020040416511100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":4,
      "vividness":1,
      "r":64,
      "g":60,
      "b":61,
      "hex":"#403c3d",
      "file_location":"assets/colors_organized/hue_28/brightness_04/2020040416505600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":4,
      "vividness":2,
      "r":65,
      "g":56,
      "b":61,
      "hex":"#41383d",
      "file_location":"assets/colors_organized/hue_28/brightness_04/2020040416505601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":4,
      "vividness":3,
      "r":64,
      "g":51,
      "b":58,
      "hex":"#40333a",
      "file_location":"assets/colors_organized/hue_28/brightness_04/2020040416505700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":4,
      "vividness":4,
      "r":64,
      "g":47,
      "b":57,
      "hex":"#402f39",
      "file_location":"assets/colors_organized/hue_28/brightness_04/2020040416505701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":4,
      "vividness":5,
      "r":65,
      "g":43,
      "b":56,
      "hex":"#412b38",
      "file_location":"assets/colors_organized/hue_28/brightness_04/2020040416505800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":4,
      "vividness":6,
      "r":65,
      "g":38,
      "b":53,
      "hex":"#412635",
      "file_location":"assets/colors_organized/hue_28/brightness_04/2020040416505801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":4,
      "vividness":7,
      "r":65,
      "g":34,
      "b":52,
      "hex":"#412234",
      "file_location":"assets/colors_organized/hue_28/brightness_04/2020040416505802-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":4,
      "vividness":8,
      "r":64,
      "g":29,
      "b":49,
      "hex":"#401d31",
      "file_location":"assets/colors_organized/hue_28/brightness_04/2020040416505900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":4,
      "vividness":9,
      "r":64,
      "g":26,
      "b":47,
      "hex":"#401a2f",
      "file_location":"assets/colors_organized/hue_28/brightness_04/2020040416505901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":4,
      "vividness":10,
      "r":64,
      "g":22,
      "b":46,
      "hex":"#40162e",
      "file_location":"assets/colors_organized/hue_28/brightness_04/2020040416510000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":4,
      "vividness":11,
      "r":64,
      "g":18,
      "b":46,
      "hex":"#40122e",
      "file_location":"assets/colors_organized/hue_28/brightness_04/2020040416510001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":4,
      "vividness":12,
      "r":66,
      "g":12,
      "b":44,
      "hex":"#420c2c",
      "file_location":"assets/colors_organized/hue_28/brightness_04/2020040416510100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":4,
      "vividness":13,
      "r":64,
      "g":7,
      "b":42,
      "hex":"#40072a",
      "file_location":"assets/colors_organized/hue_28/brightness_04/2020040416510101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":4,
      "vividness":14,
      "r":64,
      "g":2,
      "b":39,
      "hex":"#400227",
      "file_location":"assets/colors_organized/hue_28/brightness_04/2020040416510102-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":5,
      "vividness":1,
      "r":80,
      "g":74,
      "b":78,
      "hex":"#504a4e",
      "file_location":"assets/colors_organized/hue_28/brightness_05/2020040416504601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":5,
      "vividness":2,
      "r":80,
      "g":69,
      "b":75,
      "hex":"#50454b",
      "file_location":"assets/colors_organized/hue_28/brightness_05/2020040416504700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":5,
      "vividness":3,
      "r":80,
      "g":64,
      "b":74,
      "hex":"#50404a",
      "file_location":"assets/colors_organized/hue_28/brightness_05/2020040416504701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":5,
      "vividness":4,
      "r":80,
      "g":58,
      "b":71,
      "hex":"#503a47",
      "file_location":"assets/colors_organized/hue_28/brightness_05/2020040416504800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":5,
      "vividness":5,
      "r":80,
      "g":53,
      "b":68,
      "hex":"#503544",
      "file_location":"assets/colors_organized/hue_28/brightness_05/2020040416504801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":5,
      "vividness":6,
      "r":79,
      "g":48,
      "b":66,
      "hex":"#4f3042",
      "file_location":"assets/colors_organized/hue_28/brightness_05/2020040416504900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":5,
      "vividness":7,
      "r":80,
      "g":42,
      "b":65,
      "hex":"#502a41",
      "file_location":"assets/colors_organized/hue_28/brightness_05/2020040416504901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":5,
      "vividness":8,
      "r":79,
      "g":36,
      "b":63,
      "hex":"#4f243f",
      "file_location":"assets/colors_organized/hue_28/brightness_05/2020040416505000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":5,
      "vividness":9,
      "r":80,
      "g":31,
      "b":60,
      "hex":"#501f3c",
      "file_location":"assets/colors_organized/hue_28/brightness_05/2020040416505001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":5,
      "vividness":10,
      "r":80,
      "g":27,
      "b":57,
      "hex":"#501b39",
      "file_location":"assets/colors_organized/hue_28/brightness_05/2020040416505002-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":5,
      "vividness":11,
      "r":79,
      "g":22,
      "b":55,
      "hex":"#4f1637",
      "file_location":"assets/colors_organized/hue_28/brightness_05/2020040416505100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":5,
      "vividness":12,
      "r":79,
      "g":15,
      "b":52,
      "hex":"#4f0f34",
      "file_location":"assets/colors_organized/hue_28/brightness_05/2020040416505101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":5,
      "vividness":13,
      "r":80,
      "g":9,
      "b":51,
      "hex":"#500933",
      "file_location":"assets/colors_organized/hue_28/brightness_05/2020040416505200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":5,
      "vividness":14,
      "r":79,
      "g":6,
      "b":49,
      "hex":"#4f0631",
      "file_location":"assets/colors_organized/hue_28/brightness_05/2020040416505201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":6,
      "vividness":1,
      "r":95,
      "g":82,
      "b":91,
      "hex":"#5f525b",
      "file_location":"assets/colors_organized/hue_28/brightness_06/2020040416503702-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":6,
      "vividness":2,
      "r":94,
      "g":77,
      "b":87,
      "hex":"#5e4d57",
      "file_location":"assets/colors_organized/hue_28/brightness_06/2020040416503800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":6,
      "vividness":3,
      "r":95,
      "g":68,
      "b":83,
      "hex":"#5f4453",
      "file_location":"assets/colors_organized/hue_28/brightness_06/2020040416503801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":6,
      "vividness":4,
      "r":94,
      "g":63,
      "b":81,
      "hex":"#5e3f51",
      "file_location":"assets/colors_organized/hue_28/brightness_06/2020040416503900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":6,
      "vividness":5,
      "r":95,
      "g":57,
      "b":80,
      "hex":"#5f3950",
      "file_location":"assets/colors_organized/hue_28/brightness_06/2020040416503901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":6,
      "vividness":6,
      "r":94,
      "g":50,
      "b":77,
      "hex":"#5e324d",
      "file_location":"assets/colors_organized/hue_28/brightness_06/2020040416504000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":6,
      "vividness":7,
      "r":94,
      "g":44,
      "b":73,
      "hex":"#5e2c49",
      "file_location":"assets/colors_organized/hue_28/brightness_06/2020040416504001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":6,
      "vividness":8,
      "r":95,
      "g":38,
      "b":71,
      "hex":"#5f2647",
      "file_location":"assets/colors_organized/hue_28/brightness_06/2020040416504002-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":6,
      "vividness":9,
      "r":95,
      "g":31,
      "b":68,
      "hex":"#5f1f44",
      "file_location":"assets/colors_organized/hue_28/brightness_06/2020040416504100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":6,
      "vividness":10,
      "r":95,
      "g":24,
      "b":66,
      "hex":"#5f1842",
      "file_location":"assets/colors_organized/hue_28/brightness_06/2020040416504101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":6,
      "vividness":11,
      "r":95,
      "g":20,
      "b":63,
      "hex":"#5f143f",
      "file_location":"assets/colors_organized/hue_28/brightness_06/2020040416504200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":6,
      "vividness":12,
      "r":95,
      "g":13,
      "b":62,
      "hex":"#5f0d3e",
      "file_location":"assets/colors_organized/hue_28/brightness_06/2020040416504201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":6,
      "vividness":13,
      "r":95,
      "g":7,
      "b":58,
      "hex":"#5f073a",
      "file_location":"assets/colors_organized/hue_28/brightness_06/2020040416504300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":7,
      "vividness":1,
      "r":109,
      "g":103,
      "b":107,
      "hex":"#6d676b",
      "file_location":"assets/colors_organized/hue_28/brightness_07/2020040416502701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":7,
      "vividness":2,
      "r":110,
      "g":94,
      "b":104,
      "hex":"#6e5e68",
      "file_location":"assets/colors_organized/hue_28/brightness_07/2020040416502800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":7,
      "vividness":3,
      "r":110,
      "g":88,
      "b":101,
      "hex":"#6e5865",
      "file_location":"assets/colors_organized/hue_28/brightness_07/2020040416502801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":7,
      "vividness":4,
      "r":109,
      "g":80,
      "b":98,
      "hex":"#6d5062",
      "file_location":"assets/colors_organized/hue_28/brightness_07/2020040416502802-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":7,
      "vividness":5,
      "r":110,
      "g":72,
      "b":95,
      "hex":"#6e485f",
      "file_location":"assets/colors_organized/hue_28/brightness_07/2020040416502900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":7,
      "vividness":6,
      "r":110,
      "g":66,
      "b":91,
      "hex":"#6e425b",
      "file_location":"assets/colors_organized/hue_28/brightness_07/2020040416502901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":7,
      "vividness":7,
      "r":109,
      "g":58,
      "b":89,
      "hex":"#6d3a59",
      "file_location":"assets/colors_organized/hue_28/brightness_07/2020040416503000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":7,
      "vividness":8,
      "r":110,
      "g":50,
      "b":86,
      "hex":"#6e3256",
      "file_location":"assets/colors_organized/hue_28/brightness_07/2020040416503001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":7,
      "vividness":9,
      "r":110,
      "g":44,
      "b":82,
      "hex":"#6e2c52",
      "file_location":"assets/colors_organized/hue_28/brightness_07/2020040416503100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":7,
      "vividness":10,
      "r":109,
      "g":36,
      "b":79,
      "hex":"#6d244f",
      "file_location":"assets/colors_organized/hue_28/brightness_07/2020040416503101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":7,
      "vividness":11,
      "r":109,
      "g":30,
      "b":77,
      "hex":"#6d1e4d",
      "file_location":"assets/colors_organized/hue_28/brightness_07/2020040416503200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":7,
      "vividness":12,
      "r":110,
      "g":22,
      "b":73,
      "hex":"#6e1649",
      "file_location":"assets/colors_organized/hue_28/brightness_07/2020040416503201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":7,
      "vividness":13,
      "r":110,
      "g":12,
      "b":71,
      "hex":"#6e0c47",
      "file_location":"assets/colors_organized/hue_28/brightness_07/2020040416503300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":7,
      "vividness":14,
      "r":110,
      "g":6,
      "b":67,
      "hex":"#6e0643",
      "file_location":"assets/colors_organized/hue_28/brightness_07/2020040416503301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":8,
      "vividness":1,
      "r":123,
      "g":117,
      "b":121,
      "hex":"#7b7579",
      "file_location":"assets/colors_organized/hue_28/brightness_08/2020040416501701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":8,
      "vividness":2,
      "r":124,
      "g":108,
      "b":118,
      "hex":"#7c6c76",
      "file_location":"assets/colors_organized/hue_28/brightness_08/2020040416501800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":8,
      "vividness":3,
      "r":123,
      "g":99,
      "b":113,
      "hex":"#7b6371",
      "file_location":"assets/colors_organized/hue_28/brightness_08/2020040416501801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":8,
      "vividness":4,
      "r":124,
      "g":91,
      "b":110,
      "hex":"#7c5b6e",
      "file_location":"assets/colors_organized/hue_28/brightness_08/2020040416501802-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":8,
      "vividness":5,
      "r":123,
      "g":83,
      "b":107,
      "hex":"#7b536b",
      "file_location":"assets/colors_organized/hue_28/brightness_08/2020040416501900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":8,
      "vividness":6,
      "r":123,
      "g":74,
      "b":103,
      "hex":"#7b4a67",
      "file_location":"assets/colors_organized/hue_28/brightness_08/2020040416501901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":8,
      "vividness":7,
      "r":123,
      "g":66,
      "b":101,
      "hex":"#7b4265",
      "file_location":"assets/colors_organized/hue_28/brightness_08/2020040416502000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":8,
      "vividness":8,
      "r":124,
      "g":57,
      "b":98,
      "hex":"#7c3962",
      "file_location":"assets/colors_organized/hue_28/brightness_08/2020040416502001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":8,
      "vividness":9,
      "r":124,
      "g":48,
      "b":94,
      "hex":"#7c305e",
      "file_location":"assets/colors_organized/hue_28/brightness_08/2020040416502100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":8,
      "vividness":10,
      "r":123,
      "g":41,
      "b":90,
      "hex":"#7b295a",
      "file_location":"assets/colors_organized/hue_28/brightness_08/2020040416502101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":8,
      "vividness":11,
      "r":124,
      "g":34,
      "b":88,
      "hex":"#7c2258",
      "file_location":"assets/colors_organized/hue_28/brightness_08/2020040416502200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":8,
      "vividness":12,
      "r":124,
      "g":23,
      "b":83,
      "hex":"#7c1753",
      "file_location":"assets/colors_organized/hue_28/brightness_08/2020040416502201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":8,
      "vividness":13,
      "r":124,
      "g":15,
      "b":82,
      "hex":"#7c0f52",
      "file_location":"assets/colors_organized/hue_28/brightness_08/2020040416502300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":8,
      "vividness":14,
      "r":124,
      "g":7,
      "b":78,
      "hex":"#7c074e",
      "file_location":"assets/colors_organized/hue_28/brightness_08/2020040416502301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":9,
      "vividness":1,
      "r":140,
      "g":131,
      "b":136,
      "hex":"#8c8388",
      "file_location":"assets/colors_organized/hue_28/brightness_09/2020040416500701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":9,
      "vividness":2,
      "r":139,
      "g":122,
      "b":132,
      "hex":"#8b7a84",
      "file_location":"assets/colors_organized/hue_28/brightness_09/2020040416500800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":9,
      "vividness":3,
      "r":139,
      "g":110,
      "b":128,
      "hex":"#8b6e80",
      "file_location":"assets/colors_organized/hue_28/brightness_09/2020040416500801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":9,
      "vividness":4,
      "r":140,
      "g":102,
      "b":125,
      "hex":"#8c667d",
      "file_location":"assets/colors_organized/hue_28/brightness_09/2020040416500900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":9,
      "vividness":5,
      "r":139,
      "g":93,
      "b":121,
      "hex":"#8b5d79",
      "file_location":"assets/colors_organized/hue_28/brightness_09/2020040416500901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":9,
      "vividness":6,
      "r":139,
      "g":84,
      "b":116,
      "hex":"#8b5474",
      "file_location":"assets/colors_organized/hue_28/brightness_09/2020040416501000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":9,
      "vividness":7,
      "r":139,
      "g":75,
      "b":112,
      "hex":"#8b4b70",
      "file_location":"assets/colors_organized/hue_28/brightness_09/2020040416501001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":9,
      "vividness":8,
      "r":138,
      "g":65,
      "b":110,
      "hex":"#8a416e",
      "file_location":"assets/colors_organized/hue_28/brightness_09/2020040416501100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":9,
      "vividness":9,
      "r":139,
      "g":55,
      "b":105,
      "hex":"#8b3769",
      "file_location":"assets/colors_organized/hue_28/brightness_09/2020040416501101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":9,
      "vividness":10,
      "r":139,
      "g":46,
      "b":101,
      "hex":"#8b2e65",
      "file_location":"assets/colors_organized/hue_28/brightness_09/2020040416501102-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":9,
      "vividness":11,
      "r":138,
      "g":37,
      "b":97,
      "hex":"#8a2561",
      "file_location":"assets/colors_organized/hue_28/brightness_09/2020040416501200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":9,
      "vividness":12,
      "r":140,
      "g":26,
      "b":95,
      "hex":"#8c1a5f",
      "file_location":"assets/colors_organized/hue_28/brightness_09/2020040416501201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":9,
      "vividness":13,
      "r":139,
      "g":17,
      "b":90,
      "hex":"#8b115a",
      "file_location":"assets/colors_organized/hue_28/brightness_09/2020040416501300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":9,
      "vividness":14,
      "r":138,
      "g":10,
      "b":87,
      "hex":"#8a0a57",
      "file_location":"assets/colors_organized/hue_28/brightness_09/2020040416501301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":10,
      "vividness":1,
      "r":154,
      "g":145,
      "b":150,
      "hex":"#9a9196",
      "file_location":"assets/colors_organized/hue_28/brightness_10/2020040416495700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":10,
      "vividness":2,
      "r":154,
      "g":134,
      "b":146,
      "hex":"#9a8692",
      "file_location":"assets/colors_organized/hue_28/brightness_10/2020040416495701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":10,
      "vividness":3,
      "r":154,
      "g":123,
      "b":141,
      "hex":"#9a7b8d",
      "file_location":"assets/colors_organized/hue_28/brightness_10/2020040416495800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":10,
      "vividness":4,
      "r":153,
      "g":113,
      "b":139,
      "hex":"#99718b",
      "file_location":"assets/colors_organized/hue_28/brightness_10/2020040416495801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":10,
      "vividness":5,
      "r":154,
      "g":103,
      "b":134,
      "hex":"#9a6786",
      "file_location":"assets/colors_organized/hue_28/brightness_10/2020040416495900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":10,
      "vividness":6,
      "r":154,
      "g":92,
      "b":131,
      "hex":"#9a5c83",
      "file_location":"assets/colors_organized/hue_28/brightness_10/2020040416495901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":10,
      "vividness":7,
      "r":154,
      "g":81,
      "b":124,
      "hex":"#9a517c",
      "file_location":"assets/colors_organized/hue_28/brightness_10/2020040416500000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":10,
      "vividness":8,
      "r":154,
      "g":72,
      "b":121,
      "hex":"#9a4879",
      "file_location":"assets/colors_organized/hue_28/brightness_10/2020040416500001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":10,
      "vividness":9,
      "r":154,
      "g":61,
      "b":116,
      "hex":"#9a3d74",
      "file_location":"assets/colors_organized/hue_28/brightness_10/2020040416500100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":10,
      "vividness":10,
      "r":155,
      "g":51,
      "b":114,
      "hex":"#9b3372",
      "file_location":"assets/colors_organized/hue_28/brightness_10/2020040416500101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":10,
      "vividness":11,
      "r":154,
      "g":40,
      "b":109,
      "hex":"#9a286d",
      "file_location":"assets/colors_organized/hue_28/brightness_10/2020040416500200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":10,
      "vividness":12,
      "r":154,
      "g":29,
      "b":105,
      "hex":"#9a1d69",
      "file_location":"assets/colors_organized/hue_28/brightness_10/2020040416500201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":10,
      "vividness":13,
      "r":155,
      "g":19,
      "b":101,
      "hex":"#9b1365",
      "file_location":"assets/colors_organized/hue_28/brightness_10/2020040416500300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":10,
      "vividness":14,
      "r":154,
      "g":10,
      "b":97,
      "hex":"#9a0a61",
      "file_location":"assets/colors_organized/hue_28/brightness_10/2020040416500301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":11,
      "vividness":1,
      "r":169,
      "g":158,
      "b":166,
      "hex":"#a99ea6",
      "file_location":"assets/colors_organized/hue_28/brightness_11/2020040416494700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":11,
      "vividness":2,
      "r":169,
      "g":147,
      "b":160,
      "hex":"#a993a0",
      "file_location":"assets/colors_organized/hue_28/brightness_11/2020040416494701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":11,
      "vividness":3,
      "r":169,
      "g":136,
      "b":157,
      "hex":"#a9889d",
      "file_location":"assets/colors_organized/hue_28/brightness_11/2020040416494800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":11,
      "vividness":4,
      "r":169,
      "g":125,
      "b":152,
      "hex":"#a97d98",
      "file_location":"assets/colors_organized/hue_28/brightness_11/2020040416494801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":11,
      "vividness":5,
      "r":169,
      "g":113,
      "b":148,
      "hex":"#a97194",
      "file_location":"assets/colors_organized/hue_28/brightness_11/2020040416494900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":11,
      "vividness":6,
      "r":169,
      "g":101,
      "b":142,
      "hex":"#a9658e",
      "file_location":"assets/colors_organized/hue_28/brightness_11/2020040416494901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":11,
      "vividness":7,
      "r":169,
      "g":90,
      "b":138,
      "hex":"#a95a8a",
      "file_location":"assets/colors_organized/hue_28/brightness_11/2020040416495000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":11,
      "vividness":8,
      "r":169,
      "g":79,
      "b":133,
      "hex":"#a94f85",
      "file_location":"assets/colors_organized/hue_28/brightness_11/2020040416495001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":11,
      "vividness":9,
      "r":169,
      "g":67,
      "b":130,
      "hex":"#a94382",
      "file_location":"assets/colors_organized/hue_28/brightness_11/2020040416495100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":11,
      "vividness":10,
      "r":169,
      "g":55,
      "b":124,
      "hex":"#a9377c",
      "file_location":"assets/colors_organized/hue_28/brightness_11/2020040416495101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":11,
      "vividness":11,
      "r":169,
      "g":45,
      "b":118,
      "hex":"#a92d76",
      "file_location":"assets/colors_organized/hue_28/brightness_11/2020040416495102-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":11,
      "vividness":12,
      "r":169,
      "g":33,
      "b":115,
      "hex":"#a92173",
      "file_location":"assets/colors_organized/hue_28/brightness_11/2020040416495200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":11,
      "vividness":13,
      "r":170,
      "g":22,
      "b":110,
      "hex":"#aa166e",
      "file_location":"assets/colors_organized/hue_28/brightness_11/2020040416495201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":11,
      "vividness":14,
      "r":169,
      "g":9,
      "b":105,
      "hex":"#a90969",
      "file_location":"assets/colors_organized/hue_28/brightness_11/2020040416495300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":12,
      "vividness":1,
      "r":184,
      "g":173,
      "b":179,
      "hex":"#b8adb3",
      "file_location":"assets/colors_organized/hue_28/brightness_12/2020040416493700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":12,
      "vividness":2,
      "r":184,
      "g":160,
      "b":174,
      "hex":"#b8a0ae",
      "file_location":"assets/colors_organized/hue_28/brightness_12/2020040416493701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":12,
      "vividness":3,
      "r":185,
      "g":147,
      "b":170,
      "hex":"#b993aa",
      "file_location":"assets/colors_organized/hue_28/brightness_12/2020040416493800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":12,
      "vividness":4,
      "r":184,
      "g":135,
      "b":164,
      "hex":"#b887a4",
      "file_location":"assets/colors_organized/hue_28/brightness_12/2020040416493801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":12,
      "vividness":5,
      "r":184,
      "g":122,
      "b":159,
      "hex":"#b87a9f",
      "file_location":"assets/colors_organized/hue_28/brightness_12/2020040416493900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":12,
      "vividness":6,
      "r":183,
      "g":110,
      "b":155,
      "hex":"#b76e9b",
      "file_location":"assets/colors_organized/hue_28/brightness_12/2020040416493901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":12,
      "vividness":7,
      "r":185,
      "g":98,
      "b":149,
      "hex":"#b96295",
      "file_location":"assets/colors_organized/hue_28/brightness_12/2020040416494000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":12,
      "vividness":8,
      "r":184,
      "g":86,
      "b":145,
      "hex":"#b85691",
      "file_location":"assets/colors_organized/hue_28/brightness_12/2020040416494001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":12,
      "vividness":9,
      "r":184,
      "g":73,
      "b":141,
      "hex":"#b8498d",
      "file_location":"assets/colors_organized/hue_28/brightness_12/2020040416494100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":12,
      "vividness":10,
      "r":185,
      "g":61,
      "b":134,
      "hex":"#b93d86",
      "file_location":"assets/colors_organized/hue_28/brightness_12/2020040416494101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":12,
      "vividness":11,
      "r":184,
      "g":48,
      "b":130,
      "hex":"#b83082",
      "file_location":"assets/colors_organized/hue_28/brightness_12/2020040416494200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":12,
      "vividness":12,
      "r":184,
      "g":36,
      "b":124,
      "hex":"#b8247c",
      "file_location":"assets/colors_organized/hue_28/brightness_12/2020040416494201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":12,
      "vividness":13,
      "r":184,
      "g":23,
      "b":119,
      "hex":"#b81777",
      "file_location":"assets/colors_organized/hue_28/brightness_12/2020040416494300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":12,
      "vividness":14,
      "r":183,
      "g":12,
      "b":114,
      "hex":"#b70c72",
      "file_location":"assets/colors_organized/hue_28/brightness_12/2020040416494301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":13,
      "vividness":1,
      "r":199,
      "g":186,
      "b":195,
      "hex":"#c7bac3",
      "file_location":"assets/colors_organized/hue_28/brightness_13/2020040416492601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":13,
      "vividness":2,
      "r":200,
      "g":173,
      "b":188,
      "hex":"#c8adbc",
      "file_location":"assets/colors_organized/hue_28/brightness_13/2020040416492700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":13,
      "vividness":3,
      "r":199,
      "g":159,
      "b":183,
      "hex":"#c79fb7",
      "file_location":"assets/colors_organized/hue_28/brightness_13/2020040416492701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":13,
      "vividness":4,
      "r":200,
      "g":146,
      "b":178,
      "hex":"#c892b2",
      "file_location":"assets/colors_organized/hue_28/brightness_13/2020040416492800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":13,
      "vividness":5,
      "r":200,
      "g":133,
      "b":174,
      "hex":"#c885ae",
      "file_location":"assets/colors_organized/hue_28/brightness_13/2020040416492801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":13,
      "vividness":6,
      "r":198,
      "g":119,
      "b":166,
      "hex":"#c677a6",
      "file_location":"assets/colors_organized/hue_28/brightness_13/2020040416492900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":13,
      "vividness":7,
      "r":199,
      "g":106,
      "b":161,
      "hex":"#c76aa1",
      "file_location":"assets/colors_organized/hue_28/brightness_13/2020040416492901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":13,
      "vividness":8,
      "r":199,
      "g":93,
      "b":157,
      "hex":"#c75d9d",
      "file_location":"assets/colors_organized/hue_28/brightness_13/2020040416493000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":13,
      "vividness":9,
      "r":199,
      "g":79,
      "b":151,
      "hex":"#c74f97",
      "file_location":"assets/colors_organized/hue_28/brightness_13/2020040416493001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":13,
      "vividness":10,
      "r":199,
      "g":66,
      "b":145,
      "hex":"#c74291",
      "file_location":"assets/colors_organized/hue_28/brightness_13/2020040416493100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":13,
      "vividness":11,
      "r":200,
      "g":53,
      "b":141,
      "hex":"#c8358d",
      "file_location":"assets/colors_organized/hue_28/brightness_13/2020040416493101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":13,
      "vividness":12,
      "r":199,
      "g":39,
      "b":135,
      "hex":"#c72787",
      "file_location":"assets/colors_organized/hue_28/brightness_13/2020040416493200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":13,
      "vividness":13,
      "r":199,
      "g":26,
      "b":131,
      "hex":"#c71a83",
      "file_location":"assets/colors_organized/hue_28/brightness_13/2020040416493201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":13,
      "vividness":14,
      "r":199,
      "g":12,
      "b":125,
      "hex":"#c70c7d",
      "file_location":"assets/colors_organized/hue_28/brightness_13/2020040416493300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":14,
      "vividness":1,
      "r":214,
      "g":201,
      "b":210,
      "hex":"#d6c9d2",
      "file_location":"assets/colors_organized/hue_28/brightness_14/2020040416491101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":14,
      "vividness":2,
      "r":214,
      "g":185,
      "b":203,
      "hex":"#d6b9cb",
      "file_location":"assets/colors_organized/hue_28/brightness_14/2020040416491200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":14,
      "vividness":3,
      "r":214,
      "g":171,
      "b":198,
      "hex":"#d6abc6",
      "file_location":"assets/colors_organized/hue_28/brightness_14/2020040416491201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":14,
      "vividness":4,
      "r":214,
      "g":157,
      "b":190,
      "hex":"#d69dbe",
      "file_location":"assets/colors_organized/hue_28/brightness_14/2020040416491300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":14,
      "vividness":5,
      "r":214,
      "g":143,
      "b":185,
      "hex":"#d68fb9",
      "file_location":"assets/colors_organized/hue_28/brightness_14/2020040416491301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":14,
      "vividness":6,
      "r":215,
      "g":128,
      "b":179,
      "hex":"#d780b3",
      "file_location":"assets/colors_organized/hue_28/brightness_14/2020040416491400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":14,
      "vividness":7,
      "r":214,
      "g":114,
      "b":174,
      "hex":"#d672ae",
      "file_location":"assets/colors_organized/hue_28/brightness_14/2020040416491500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":14,
      "vividness":8,
      "r":215,
      "g":100,
      "b":169,
      "hex":"#d764a9",
      "file_location":"assets/colors_organized/hue_28/brightness_14/2020040416491501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":14,
      "vividness":9,
      "r":214,
      "g":86,
      "b":163,
      "hex":"#d656a3",
      "file_location":"assets/colors_organized/hue_28/brightness_14/2020040416491600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":14,
      "vividness":10,
      "r":215,
      "g":71,
      "b":158,
      "hex":"#d7479e",
      "file_location":"assets/colors_organized/hue_28/brightness_14/2020040416491601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":14,
      "vividness":11,
      "r":215,
      "g":57,
      "b":152,
      "hex":"#d73998",
      "file_location":"assets/colors_organized/hue_28/brightness_14/2020040416491700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":14,
      "vividness":12,
      "r":214,
      "g":41,
      "b":146,
      "hex":"#d62992",
      "file_location":"assets/colors_organized/hue_28/brightness_14/2020040416491701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":14,
      "vividness":13,
      "r":214,
      "g":27,
      "b":140,
      "hex":"#d61b8c",
      "file_location":"assets/colors_organized/hue_28/brightness_14/2020040416491800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":14,
      "vividness":14,
      "r":215,
      "g":13,
      "b":135,
      "hex":"#d70d87",
      "file_location":"assets/colors_organized/hue_28/brightness_14/2020040416491801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":15,
      "vividness":1,
      "r":230,
      "g":214,
      "b":224,
      "hex":"#e6d6e0",
      "file_location":"assets/colors_organized/hue_28/brightness_15/2020040416490001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":15,
      "vividness":2,
      "r":230,
      "g":199,
      "b":217,
      "hex":"#e6c7d9",
      "file_location":"assets/colors_organized/hue_28/brightness_15/2020040416490100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":15,
      "vividness":3,
      "r":229,
      "g":183,
      "b":211,
      "hex":"#e5b7d3",
      "file_location":"assets/colors_organized/hue_28/brightness_15/2020040416490101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":15,
      "vividness":4,
      "r":230,
      "g":168,
      "b":205,
      "hex":"#e6a8cd",
      "file_location":"assets/colors_organized/hue_28/brightness_15/2020040416490200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":15,
      "vividness":5,
      "r":230,
      "g":153,
      "b":199,
      "hex":"#e699c7",
      "file_location":"assets/colors_organized/hue_28/brightness_15/2020040416490201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":15,
      "vividness":6,
      "r":230,
      "g":137,
      "b":192,
      "hex":"#e689c0",
      "file_location":"assets/colors_organized/hue_28/brightness_15/2020040416490300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":15,
      "vividness":7,
      "r":228,
      "g":122,
      "b":186,
      "hex":"#e47aba",
      "file_location":"assets/colors_organized/hue_28/brightness_15/2020040416490301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":15,
      "vividness":8,
      "r":229,
      "g":107,
      "b":180,
      "hex":"#e56bb4",
      "file_location":"assets/colors_organized/hue_28/brightness_15/2020040416490400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":15,
      "vividness":9,
      "r":229,
      "g":92,
      "b":174,
      "hex":"#e55cae",
      "file_location":"assets/colors_organized/hue_28/brightness_15/2020040416490401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":15,
      "vividness":10,
      "r":229,
      "g":76,
      "b":167,
      "hex":"#e54ca7",
      "file_location":"assets/colors_organized/hue_28/brightness_15/2020040416490500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":15,
      "vividness":11,
      "r":230,
      "g":61,
      "b":162,
      "hex":"#e63da2",
      "file_location":"assets/colors_organized/hue_28/brightness_15/2020040416490501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":15,
      "vividness":12,
      "r":229,
      "g":45,
      "b":156,
      "hex":"#e52d9c",
      "file_location":"assets/colors_organized/hue_28/brightness_15/2020040416490600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":15,
      "vividness":13,
      "r":229,
      "g":30,
      "b":149,
      "hex":"#e51e95",
      "file_location":"assets/colors_organized/hue_28/brightness_15/2020040416490601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":28,
      "brightness":15,
      "vividness":14,
      "r":229,
      "g":14,
      "b":143,
      "hex":"#e50e8f",
      "file_location":"assets/colors_organized/hue_28/brightness_15/2020040416490700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":1,
      "vividness":1,
      "r":19,
      "g":13,
      "b":15,
      "hex":"#130d0f",
      "file_location":"assets/colors_organized/hue_29/brightness_01/2020040416535301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":1,
      "vividness":2,
      "r":20,
      "g":9,
      "b":13,
      "hex":"#14090d",
      "file_location":"assets/colors_organized/hue_29/brightness_01/2020040416535401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":1,
      "vividness":3,
      "r":19,
      "g":7,
      "b":9,
      "hex":"#130709",
      "file_location":"assets/colors_organized/hue_29/brightness_01/2020040416535600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":1,
      "vividness":4,
      "r":19,
      "g":2,
      "b":10,
      "hex":"#13020a",
      "file_location":"assets/colors_organized/hue_29/brightness_01/2020040416535601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":1,
      "vividness":5,
      "r":19,
      "g":0,
      "b":6,
      "hex":"#130006",
      "file_location":"assets/colors_organized/hue_29/brightness_01/2020040416535701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":2,
      "vividness":1,
      "r":34,
      "g":30,
      "b":31,
      "hex":"#221e1f",
      "file_location":"assets/colors_organized/hue_29/brightness_02/2020040416534201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":2,
      "vividness":2,
      "r":34,
      "g":28,
      "b":30,
      "hex":"#221c1e",
      "file_location":"assets/colors_organized/hue_29/brightness_02/2020040416534300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":2,
      "vividness":3,
      "r":34,
      "g":23,
      "b":27,
      "hex":"#22171b",
      "file_location":"assets/colors_organized/hue_29/brightness_02/2020040416534400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":2,
      "vividness":4,
      "r":34,
      "g":22,
      "b":26,
      "hex":"#22161a",
      "file_location":"assets/colors_organized/hue_29/brightness_02/2020040416534401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":2,
      "vividness":5,
      "r":34,
      "g":17,
      "b":25,
      "hex":"#221119",
      "file_location":"assets/colors_organized/hue_29/brightness_02/2020040416534402-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":2,
      "vividness":6,
      "r":34,
      "g":14,
      "b":23,
      "hex":"#220e17",
      "file_location":"assets/colors_organized/hue_29/brightness_02/2020040416534500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":2,
      "vividness":7,
      "r":34,
      "g":13,
      "b":22,
      "hex":"#220d16",
      "file_location":"assets/colors_organized/hue_29/brightness_02/2020040416534501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":2,
      "vividness":8,
      "r":35,
      "g":9,
      "b":20,
      "hex":"#230914",
      "file_location":"assets/colors_organized/hue_29/brightness_02/2020040416534601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":2,
      "vividness":9,
      "r":34,
      "g":6,
      "b":18,
      "hex":"#220612",
      "file_location":"assets/colors_organized/hue_29/brightness_02/2020040416534700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":2,
      "vividness":10,
      "r":34,
      "g":2,
      "b":13,
      "hex":"#22020d",
      "file_location":"assets/colors_organized/hue_29/brightness_02/2020040416534701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":3,
      "vividness":1,
      "r":49,
      "g":43,
      "b":45,
      "hex":"#312b2d",
      "file_location":"assets/colors_organized/hue_29/brightness_03/2020040416533300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":3,
      "vividness":2,
      "r":50,
      "g":39,
      "b":43,
      "hex":"#32272b",
      "file_location":"assets/colors_organized/hue_29/brightness_03/2020040416533301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":3,
      "vividness":3,
      "r":48,
      "g":35,
      "b":42,
      "hex":"#30232a",
      "file_location":"assets/colors_organized/hue_29/brightness_03/2020040416533400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":3,
      "vividness":4,
      "r":49,
      "g":34,
      "b":39,
      "hex":"#312227",
      "file_location":"assets/colors_organized/hue_29/brightness_03/2020040416533401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":3,
      "vividness":5,
      "r":49,
      "g":29,
      "b":38,
      "hex":"#311d26",
      "file_location":"assets/colors_organized/hue_29/brightness_03/2020040416533500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":3,
      "vividness":6,
      "r":49,
      "g":26,
      "b":34,
      "hex":"#311a22",
      "file_location":"assets/colors_organized/hue_29/brightness_03/2020040416533501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":3,
      "vividness":7,
      "r":49,
      "g":23,
      "b":34,
      "hex":"#311722",
      "file_location":"assets/colors_organized/hue_29/brightness_03/2020040416533600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":3,
      "vividness":8,
      "r":50,
      "g":20,
      "b":32,
      "hex":"#321420",
      "file_location":"assets/colors_organized/hue_29/brightness_03/2020040416533601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":3,
      "vividness":9,
      "r":49,
      "g":15,
      "b":29,
      "hex":"#310f1d",
      "file_location":"assets/colors_organized/hue_29/brightness_03/2020040416533700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":3,
      "vividness":10,
      "r":49,
      "g":13,
      "b":27,
      "hex":"#310d1b",
      "file_location":"assets/colors_organized/hue_29/brightness_03/2020040416533701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":3,
      "vividness":11,
      "r":50,
      "g":9,
      "b":27,
      "hex":"#32091b",
      "file_location":"assets/colors_organized/hue_29/brightness_03/2020040416533702-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":3,
      "vividness":12,
      "r":49,
      "g":6,
      "b":23,
      "hex":"#310617",
      "file_location":"assets/colors_organized/hue_29/brightness_03/2020040416533800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":3,
      "vividness":13,
      "r":50,
      "g":3,
      "b":21,
      "hex":"#320315",
      "file_location":"assets/colors_organized/hue_29/brightness_03/2020040416533801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":4,
      "vividness":1,
      "r":65,
      "g":56,
      "b":59,
      "hex":"#41383b",
      "file_location":"assets/colors_organized/hue_29/brightness_04/2020040416532401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":4,
      "vividness":2,
      "r":64,
      "g":52,
      "b":56,
      "hex":"#403438",
      "file_location":"assets/colors_organized/hue_29/brightness_04/2020040416532402-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":4,
      "vividness":3,
      "r":64,
      "g":47,
      "b":53,
      "hex":"#402f35",
      "file_location":"assets/colors_organized/hue_29/brightness_04/2020040416532500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":4,
      "vividness":4,
      "r":64,
      "g":43,
      "b":52,
      "hex":"#402b34",
      "file_location":"assets/colors_organized/hue_29/brightness_04/2020040416532501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":4,
      "vividness":5,
      "r":65,
      "g":39,
      "b":48,
      "hex":"#412730",
      "file_location":"assets/colors_organized/hue_29/brightness_04/2020040416532502-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":4,
      "vividness":6,
      "r":65,
      "g":33,
      "b":46,
      "hex":"#41212e",
      "file_location":"assets/colors_organized/hue_29/brightness_04/2020040416532600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":4,
      "vividness":7,
      "r":64,
      "g":30,
      "b":44,
      "hex":"#401e2c",
      "file_location":"assets/colors_organized/hue_29/brightness_04/2020040416532601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":4,
      "vividness":8,
      "r":65,
      "g":27,
      "b":40,
      "hex":"#411b28",
      "file_location":"assets/colors_organized/hue_29/brightness_04/2020040416532700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":4,
      "vividness":9,
      "r":64,
      "g":21,
      "b":38,
      "hex":"#401526",
      "file_location":"assets/colors_organized/hue_29/brightness_04/2020040416532701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":4,
      "vividness":10,
      "r":65,
      "g":18,
      "b":36,
      "hex":"#411224",
      "file_location":"assets/colors_organized/hue_29/brightness_04/2020040416532800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":4,
      "vividness":11,
      "r":65,
      "g":13,
      "b":35,
      "hex":"#410d23",
      "file_location":"assets/colors_organized/hue_29/brightness_04/2020040416532801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":4,
      "vividness":12,
      "r":65,
      "g":7,
      "b":31,
      "hex":"#41071f",
      "file_location":"assets/colors_organized/hue_29/brightness_04/2020040416532802-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":4,
      "vividness":13,
      "r":65,
      "g":3,
      "b":28,
      "hex":"#41031c",
      "file_location":"assets/colors_organized/hue_29/brightness_04/2020040416532900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":5,
      "vividness":1,
      "r":80,
      "g":74,
      "b":76,
      "hex":"#504a4c",
      "file_location":"assets/colors_organized/hue_29/brightness_05/2020040416531401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":5,
      "vividness":2,
      "r":80,
      "g":69,
      "b":73,
      "hex":"#504549",
      "file_location":"assets/colors_organized/hue_29/brightness_05/2020040416531500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":5,
      "vividness":3,
      "r":79,
      "g":64,
      "b":69,
      "hex":"#4f4045",
      "file_location":"assets/colors_organized/hue_29/brightness_05/2020040416531501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":5,
      "vividness":4,
      "r":79,
      "g":58,
      "b":67,
      "hex":"#4f3a43",
      "file_location":"assets/colors_organized/hue_29/brightness_05/2020040416531502-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":5,
      "vividness":5,
      "r":80,
      "g":52,
      "b":64,
      "hex":"#503440",
      "file_location":"assets/colors_organized/hue_29/brightness_05/2020040416531600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":5,
      "vividness":6,
      "r":80,
      "g":48,
      "b":59,
      "hex":"#50303b",
      "file_location":"assets/colors_organized/hue_29/brightness_05/2020040416531601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":5,
      "vividness":7,
      "r":80,
      "g":42,
      "b":57,
      "hex":"#502a39",
      "file_location":"assets/colors_organized/hue_29/brightness_05/2020040416531700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":5,
      "vividness":8,
      "r":79,
      "g":36,
      "b":53,
      "hex":"#4f2435",
      "file_location":"assets/colors_organized/hue_29/brightness_05/2020040416531701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":5,
      "vividness":9,
      "r":79,
      "g":32,
      "b":50,
      "hex":"#4f2032",
      "file_location":"assets/colors_organized/hue_29/brightness_05/2020040416531800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":5,
      "vividness":10,
      "r":80,
      "g":27,
      "b":47,
      "hex":"#501b2f",
      "file_location":"assets/colors_organized/hue_29/brightness_05/2020040416531801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":5,
      "vividness":11,
      "r":79,
      "g":21,
      "b":43,
      "hex":"#4f152b",
      "file_location":"assets/colors_organized/hue_29/brightness_05/2020040416531900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":5,
      "vividness":12,
      "r":79,
      "g":15,
      "b":41,
      "hex":"#4f0f29",
      "file_location":"assets/colors_organized/hue_29/brightness_05/2020040416531901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":5,
      "vividness":13,
      "r":80,
      "g":10,
      "b":38,
      "hex":"#500a26",
      "file_location":"assets/colors_organized/hue_29/brightness_05/2020040416531902-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":5,
      "vividness":14,
      "r":80,
      "g":7,
      "b":36,
      "hex":"#500724",
      "file_location":"assets/colors_organized/hue_29/brightness_05/2020040416532000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":6,
      "vividness":1,
      "r":94,
      "g":88,
      "b":90,
      "hex":"#5e585a",
      "file_location":"assets/colors_organized/hue_29/brightness_06/2020040416530501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":6,
      "vividness":2,
      "r":94,
      "g":82,
      "b":86,
      "hex":"#5e5256",
      "file_location":"assets/colors_organized/hue_29/brightness_06/2020040416530502-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":6,
      "vividness":3,
      "r":95,
      "g":76,
      "b":82,
      "hex":"#5f4c52",
      "file_location":"assets/colors_organized/hue_29/brightness_06/2020040416530600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":6,
      "vividness":4,
      "r":95,
      "g":69,
      "b":78,
      "hex":"#5f454e",
      "file_location":"assets/colors_organized/hue_29/brightness_06/2020040416530601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":6,
      "vividness":5,
      "r":95,
      "g":63,
      "b":76,
      "hex":"#5f3f4c",
      "file_location":"assets/colors_organized/hue_29/brightness_06/2020040416530700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":6,
      "vividness":6,
      "r":95,
      "g":57,
      "b":72,
      "hex":"#5f3948",
      "file_location":"assets/colors_organized/hue_29/brightness_06/2020040416530701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":6,
      "vividness":7,
      "r":94,
      "g":50,
      "b":67,
      "hex":"#5e3243",
      "file_location":"assets/colors_organized/hue_29/brightness_06/2020040416530800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":6,
      "vividness":8,
      "r":95,
      "g":44,
      "b":63,
      "hex":"#5f2c3f",
      "file_location":"assets/colors_organized/hue_29/brightness_06/2020040416530801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":6,
      "vividness":9,
      "r":95,
      "g":37,
      "b":59,
      "hex":"#5f253b",
      "file_location":"assets/colors_organized/hue_29/brightness_06/2020040416530802-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":6,
      "vividness":10,
      "r":95,
      "g":31,
      "b":57,
      "hex":"#5f1f39",
      "file_location":"assets/colors_organized/hue_29/brightness_06/2020040416530900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":6,
      "vividness":11,
      "r":95,
      "g":25,
      "b":53,
      "hex":"#5f1935",
      "file_location":"assets/colors_organized/hue_29/brightness_06/2020040416530901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":6,
      "vividness":12,
      "r":94,
      "g":19,
      "b":49,
      "hex":"#5e1331",
      "file_location":"assets/colors_organized/hue_29/brightness_06/2020040416531000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":6,
      "vividness":13,
      "r":94,
      "g":13,
      "b":45,
      "hex":"#5e0d2d",
      "file_location":"assets/colors_organized/hue_29/brightness_06/2020040416531001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":6,
      "vividness":14,
      "r":94,
      "g":7,
      "b":41,
      "hex":"#5e0729",
      "file_location":"assets/colors_organized/hue_29/brightness_06/2020040416531100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":7,
      "vividness":1,
      "r":111,
      "g":102,
      "b":105,
      "hex":"#6f6669",
      "file_location":"assets/colors_organized/hue_29/brightness_07/2020040416525601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":7,
      "vividness":2,
      "r":110,
      "g":95,
      "b":100,
      "hex":"#6e5f64",
      "file_location":"assets/colors_organized/hue_29/brightness_07/2020040416525602-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":7,
      "vividness":3,
      "r":109,
      "g":88,
      "b":95,
      "hex":"#6d585f",
      "file_location":"assets/colors_organized/hue_29/brightness_07/2020040416525700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":7,
      "vividness":4,
      "r":110,
      "g":80,
      "b":92,
      "hex":"#6e505c",
      "file_location":"assets/colors_organized/hue_29/brightness_07/2020040416525701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":7,
      "vividness":5,
      "r":110,
      "g":74,
      "b":88,
      "hex":"#6e4a58",
      "file_location":"assets/colors_organized/hue_29/brightness_07/2020040416525800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":7,
      "vividness":6,
      "r":110,
      "g":66,
      "b":83,
      "hex":"#6e4253",
      "file_location":"assets/colors_organized/hue_29/brightness_07/2020040416525801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":7,
      "vividness":7,
      "r":110,
      "g":58,
      "b":80,
      "hex":"#6e3a50",
      "file_location":"assets/colors_organized/hue_29/brightness_07/2020040416525900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":7,
      "vividness":8,
      "r":110,
      "g":50,
      "b":75,
      "hex":"#6e324b",
      "file_location":"assets/colors_organized/hue_29/brightness_07/2020040416525901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":7,
      "vividness":9,
      "r":109,
      "g":43,
      "b":70,
      "hex":"#6d2b46",
      "file_location":"assets/colors_organized/hue_29/brightness_07/2020040416525902-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":7,
      "vividness":10,
      "r":110,
      "g":35,
      "b":65,
      "hex":"#6e2341",
      "file_location":"assets/colors_organized/hue_29/brightness_07/2020040416530000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":7,
      "vividness":11,
      "r":110,
      "g":29,
      "b":61,
      "hex":"#6e1d3d",
      "file_location":"assets/colors_organized/hue_29/brightness_07/2020040416530001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":7,
      "vividness":12,
      "r":109,
      "g":22,
      "b":56,
      "hex":"#6d1638",
      "file_location":"assets/colors_organized/hue_29/brightness_07/2020040416530100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":7,
      "vividness":13,
      "r":109,
      "g":13,
      "b":51,
      "hex":"#6d0d33",
      "file_location":"assets/colors_organized/hue_29/brightness_07/2020040416530101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":7,
      "vividness":14,
      "r":109,
      "g":7,
      "b":47,
      "hex":"#6d072f",
      "file_location":"assets/colors_organized/hue_29/brightness_07/2020040416530102-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":8,
      "vividness":1,
      "r":125,
      "g":116,
      "b":119,
      "hex":"#7d7477",
      "file_location":"assets/colors_organized/hue_29/brightness_08/2020040416524601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":8,
      "vividness":2,
      "r":123,
      "g":108,
      "b":113,
      "hex":"#7b6c71",
      "file_location":"assets/colors_organized/hue_29/brightness_08/2020040416524700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":8,
      "vividness":3,
      "r":124,
      "g":98,
      "b":109,
      "hex":"#7c626d",
      "file_location":"assets/colors_organized/hue_29/brightness_08/2020040416524701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":8,
      "vividness":4,
      "r":123,
      "g":91,
      "b":104,
      "hex":"#7b5b68",
      "file_location":"assets/colors_organized/hue_29/brightness_08/2020040416524702-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":8,
      "vividness":5,
      "r":124,
      "g":83,
      "b":99,
      "hex":"#7c5363",
      "file_location":"assets/colors_organized/hue_29/brightness_08/2020040416524800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":8,
      "vividness":6,
      "r":123,
      "g":74,
      "b":93,
      "hex":"#7b4a5d",
      "file_location":"assets/colors_organized/hue_29/brightness_08/2020040416524801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":8,
      "vividness":7,
      "r":124,
      "g":66,
      "b":90,
      "hex":"#7c425a",
      "file_location":"assets/colors_organized/hue_29/brightness_08/2020040416524900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":8,
      "vividness":8,
      "r":123,
      "g":57,
      "b":84,
      "hex":"#7b3954",
      "file_location":"assets/colors_organized/hue_29/brightness_08/2020040416524901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":8,
      "vividness":9,
      "r":124,
      "g":49,
      "b":79,
      "hex":"#7c314f",
      "file_location":"assets/colors_organized/hue_29/brightness_08/2020040416525000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":8,
      "vividness":10,
      "r":124,
      "g":40,
      "b":73,
      "hex":"#7c2849",
      "file_location":"assets/colors_organized/hue_29/brightness_08/2020040416525001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":8,
      "vividness":11,
      "r":124,
      "g":34,
      "b":69,
      "hex":"#7c2245",
      "file_location":"assets/colors_organized/hue_29/brightness_08/2020040416525100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":8,
      "vividness":12,
      "r":124,
      "g":23,
      "b":63,
      "hex":"#7c173f",
      "file_location":"assets/colors_organized/hue_29/brightness_08/2020040416525101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":8,
      "vividness":13,
      "r":124,
      "g":15,
      "b":57,
      "hex":"#7c0f39",
      "file_location":"assets/colors_organized/hue_29/brightness_08/2020040416525200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":8,
      "vividness":14,
      "r":124,
      "g":6,
      "b":54,
      "hex":"#7c0636",
      "file_location":"assets/colors_organized/hue_29/brightness_08/2020040416525201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":9,
      "vividness":1,
      "r":139,
      "g":130,
      "b":133,
      "hex":"#8b8285",
      "file_location":"assets/colors_organized/hue_29/brightness_09/2020040416523601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":9,
      "vividness":2,
      "r":138,
      "g":121,
      "b":127,
      "hex":"#8a797f",
      "file_location":"assets/colors_organized/hue_29/brightness_09/2020040416523700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":9,
      "vividness":3,
      "r":139,
      "g":111,
      "b":123,
      "hex":"#8b6f7b",
      "file_location":"assets/colors_organized/hue_29/brightness_09/2020040416523701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":9,
      "vividness":4,
      "r":139,
      "g":103,
      "b":117,
      "hex":"#8b6775",
      "file_location":"assets/colors_organized/hue_29/brightness_09/2020040416523800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":9,
      "vividness":5,
      "r":140,
      "g":93,
      "b":111,
      "hex":"#8c5d6f",
      "file_location":"assets/colors_organized/hue_29/brightness_09/2020040416523801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":9,
      "vividness":6,
      "r":138,
      "g":83,
      "b":106,
      "hex":"#8a536a",
      "file_location":"assets/colors_organized/hue_29/brightness_09/2020040416523900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":9,
      "vividness":7,
      "r":138,
      "g":74,
      "b":100,
      "hex":"#8a4a64",
      "file_location":"assets/colors_organized/hue_29/brightness_09/2020040416523901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":9,
      "vividness":8,
      "r":139,
      "g":64,
      "b":94,
      "hex":"#8b405e",
      "file_location":"assets/colors_organized/hue_29/brightness_09/2020040416523902-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":9,
      "vividness":9,
      "r":139,
      "g":55,
      "b":89,
      "hex":"#8b3759",
      "file_location":"assets/colors_organized/hue_29/brightness_09/2020040416524000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":9,
      "vividness":10,
      "r":140,
      "g":46,
      "b":83,
      "hex":"#8c2e53",
      "file_location":"assets/colors_organized/hue_29/brightness_09/2020040416524001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":9,
      "vividness":11,
      "r":139,
      "g":37,
      "b":77,
      "hex":"#8b254d",
      "file_location":"assets/colors_organized/hue_29/brightness_09/2020040416524100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":9,
      "vividness":12,
      "r":138,
      "g":26,
      "b":72,
      "hex":"#8a1a48",
      "file_location":"assets/colors_organized/hue_29/brightness_09/2020040416524101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":9,
      "vividness":13,
      "r":139,
      "g":17,
      "b":66,
      "hex":"#8b1142",
      "file_location":"assets/colors_organized/hue_29/brightness_09/2020040416524200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":9,
      "vividness":14,
      "r":140,
      "g":9,
      "b":61,
      "hex":"#8c093d",
      "file_location":"assets/colors_organized/hue_29/brightness_09/2020040416524201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":10,
      "vividness":1,
      "r":155,
      "g":144,
      "b":148,
      "hex":"#9b9094",
      "file_location":"assets/colors_organized/hue_29/brightness_10/2020040416522700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":10,
      "vividness":2,
      "r":154,
      "g":134,
      "b":143,
      "hex":"#9a868f",
      "file_location":"assets/colors_organized/hue_29/brightness_10/2020040416522701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":10,
      "vividness":3,
      "r":154,
      "g":124,
      "b":136,
      "hex":"#9a7c88",
      "file_location":"assets/colors_organized/hue_29/brightness_10/2020040416522800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":10,
      "vividness":4,
      "r":154,
      "g":113,
      "b":131,
      "hex":"#9a7183",
      "file_location":"assets/colors_organized/hue_29/brightness_10/2020040416522801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":10,
      "vividness":5,
      "r":154,
      "g":103,
      "b":122,
      "hex":"#9a677a",
      "file_location":"assets/colors_organized/hue_29/brightness_10/2020040416522802-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":10,
      "vividness":6,
      "r":154,
      "g":92,
      "b":117,
      "hex":"#9a5c75",
      "file_location":"assets/colors_organized/hue_29/brightness_10/2020040416522900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":10,
      "vividness":7,
      "r":155,
      "g":82,
      "b":111,
      "hex":"#9b526f",
      "file_location":"assets/colors_organized/hue_29/brightness_10/2020040416522901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":10,
      "vividness":8,
      "r":153,
      "g":72,
      "b":105,
      "hex":"#994869",
      "file_location":"assets/colors_organized/hue_29/brightness_10/2020040416523000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":10,
      "vividness":9,
      "r":155,
      "g":61,
      "b":98,
      "hex":"#9b3d62",
      "file_location":"assets/colors_organized/hue_29/brightness_10/2020040416523001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":10,
      "vividness":10,
      "r":153,
      "g":51,
      "b":91,
      "hex":"#99335b",
      "file_location":"assets/colors_organized/hue_29/brightness_10/2020040416523100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":10,
      "vividness":11,
      "r":154,
      "g":41,
      "b":85,
      "hex":"#9a2955",
      "file_location":"assets/colors_organized/hue_29/brightness_10/2020040416523101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":10,
      "vividness":12,
      "r":154,
      "g":30,
      "b":80,
      "hex":"#9a1e50",
      "file_location":"assets/colors_organized/hue_29/brightness_10/2020040416523200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":10,
      "vividness":13,
      "r":154,
      "g":20,
      "b":73,
      "hex":"#9a1449",
      "file_location":"assets/colors_organized/hue_29/brightness_10/2020040416523201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":10,
      "vividness":14,
      "r":153,
      "g":9,
      "b":68,
      "hex":"#990944",
      "file_location":"assets/colors_organized/hue_29/brightness_10/2020040416523300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":11,
      "vividness":1,
      "r":169,
      "g":158,
      "b":162,
      "hex":"#a99ea2",
      "file_location":"assets/colors_organized/hue_29/brightness_11/2020040416521700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":11,
      "vividness":2,
      "r":169,
      "g":148,
      "b":157,
      "hex":"#a9949d",
      "file_location":"assets/colors_organized/hue_29/brightness_11/2020040416521701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":11,
      "vividness":3,
      "r":169,
      "g":135,
      "b":149,
      "hex":"#a98795",
      "file_location":"assets/colors_organized/hue_29/brightness_11/2020040416521800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":11,
      "vividness":4,
      "r":168,
      "g":124,
      "b":141,
      "hex":"#a87c8d",
      "file_location":"assets/colors_organized/hue_29/brightness_11/2020040416521801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":11,
      "vividness":5,
      "r":168,
      "g":113,
      "b":136,
      "hex":"#a87188",
      "file_location":"assets/colors_organized/hue_29/brightness_11/2020040416521900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":11,
      "vividness":6,
      "r":169,
      "g":100,
      "b":128,
      "hex":"#a96480",
      "file_location":"assets/colors_organized/hue_29/brightness_11/2020040416521901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":11,
      "vividness":7,
      "r":169,
      "g":90,
      "b":122,
      "hex":"#a95a7a",
      "file_location":"assets/colors_organized/hue_29/brightness_11/2020040416521902-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":11,
      "vividness":8,
      "r":169,
      "g":79,
      "b":115,
      "hex":"#a94f73",
      "file_location":"assets/colors_organized/hue_29/brightness_11/2020040416522000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":11,
      "vividness":9,
      "r":169,
      "g":67,
      "b":107,
      "hex":"#a9436b",
      "file_location":"assets/colors_organized/hue_29/brightness_11/2020040416522001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":11,
      "vividness":10,
      "r":169,
      "g":56,
      "b":100,
      "hex":"#a93864",
      "file_location":"assets/colors_organized/hue_29/brightness_11/2020040416522100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":11,
      "vividness":11,
      "r":169,
      "g":45,
      "b":95,
      "hex":"#a92d5f",
      "file_location":"assets/colors_organized/hue_29/brightness_11/2020040416522101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":11,
      "vividness":12,
      "r":168,
      "g":34,
      "b":87,
      "hex":"#a82257",
      "file_location":"assets/colors_organized/hue_29/brightness_11/2020040416522200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":11,
      "vividness":13,
      "r":169,
      "g":21,
      "b":81,
      "hex":"#a91551",
      "file_location":"assets/colors_organized/hue_29/brightness_11/2020040416522201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":11,
      "vividness":14,
      "r":169,
      "g":10,
      "b":74,
      "hex":"#a90a4a",
      "file_location":"assets/colors_organized/hue_29/brightness_11/2020040416522300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":12,
      "vividness":1,
      "r":183,
      "g":172,
      "b":176,
      "hex":"#b7acb0",
      "file_location":"assets/colors_organized/hue_29/brightness_12/2020040416520701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":12,
      "vividness":2,
      "r":183,
      "g":160,
      "b":168,
      "hex":"#b7a0a8",
      "file_location":"assets/colors_organized/hue_29/brightness_12/2020040416520702-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":12,
      "vividness":3,
      "r":184,
      "g":148,
      "b":162,
      "hex":"#b894a2",
      "file_location":"assets/colors_organized/hue_29/brightness_12/2020040416520800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":12,
      "vividness":4,
      "r":184,
      "g":135,
      "b":156,
      "hex":"#b8879c",
      "file_location":"assets/colors_organized/hue_29/brightness_12/2020040416520801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":12,
      "vividness":5,
      "r":185,
      "g":123,
      "b":148,
      "hex":"#b97b94",
      "file_location":"assets/colors_organized/hue_29/brightness_12/2020040416520900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":12,
      "vividness":6,
      "r":185,
      "g":110,
      "b":140,
      "hex":"#b96e8c",
      "file_location":"assets/colors_organized/hue_29/brightness_12/2020040416520901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":12,
      "vividness":7,
      "r":184,
      "g":99,
      "b":132,
      "hex":"#b86384",
      "file_location":"assets/colors_organized/hue_29/brightness_12/2020040416521000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":12,
      "vividness":8,
      "r":184,
      "g":86,
      "b":125,
      "hex":"#b8567d",
      "file_location":"assets/colors_organized/hue_29/brightness_12/2020040416521001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":12,
      "vividness":9,
      "r":184,
      "g":72,
      "b":118,
      "hex":"#b84876",
      "file_location":"assets/colors_organized/hue_29/brightness_12/2020040416521100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":12,
      "vividness":10,
      "r":184,
      "g":60,
      "b":110,
      "hex":"#b83c6e",
      "file_location":"assets/colors_organized/hue_29/brightness_12/2020040416521101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":12,
      "vividness":11,
      "r":183,
      "g":49,
      "b":102,
      "hex":"#b73166",
      "file_location":"assets/colors_organized/hue_29/brightness_12/2020040416521102-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":12,
      "vividness":12,
      "r":184,
      "g":36,
      "b":96,
      "hex":"#b82460",
      "file_location":"assets/colors_organized/hue_29/brightness_12/2020040416521200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":12,
      "vividness":13,
      "r":183,
      "g":23,
      "b":87,
      "hex":"#b71757",
      "file_location":"assets/colors_organized/hue_29/brightness_12/2020040416521201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":12,
      "vividness":14,
      "r":184,
      "g":13,
      "b":81,
      "hex":"#b80d51",
      "file_location":"assets/colors_organized/hue_29/brightness_12/2020040416521300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":13,
      "vividness":1,
      "r":199,
      "g":187,
      "b":191,
      "hex":"#c7bbbf",
      "file_location":"assets/colors_organized/hue_29/brightness_13/2020040416515800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":13,
      "vividness":2,
      "r":199,
      "g":173,
      "b":182,
      "hex":"#c7adb6",
      "file_location":"assets/colors_organized/hue_29/brightness_13/2020040416515801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":13,
      "vividness":3,
      "r":200,
      "g":159,
      "b":175,
      "hex":"#c89faf",
      "file_location":"assets/colors_organized/hue_29/brightness_13/2020040416515802-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":13,
      "vividness":4,
      "r":199,
      "g":146,
      "b":166,
      "hex":"#c792a6",
      "file_location":"assets/colors_organized/hue_29/brightness_13/2020040416515900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":13,
      "vividness":5,
      "r":199,
      "g":133,
      "b":160,
      "hex":"#c785a0",
      "file_location":"assets/colors_organized/hue_29/brightness_13/2020040416515901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":13,
      "vividness":6,
      "r":200,
      "g":119,
      "b":151,
      "hex":"#c87797",
      "file_location":"assets/colors_organized/hue_29/brightness_13/2020040416520000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":13,
      "vividness":7,
      "r":200,
      "g":106,
      "b":143,
      "hex":"#c86a8f",
      "file_location":"assets/colors_organized/hue_29/brightness_13/2020040416520001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":13,
      "vividness":8,
      "r":200,
      "g":93,
      "b":135,
      "hex":"#c85d87",
      "file_location":"assets/colors_organized/hue_29/brightness_13/2020040416520100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":13,
      "vividness":9,
      "r":199,
      "g":79,
      "b":127,
      "hex":"#c74f7f",
      "file_location":"assets/colors_organized/hue_29/brightness_13/2020040416520101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":13,
      "vividness":10,
      "r":199,
      "g":66,
      "b":119,
      "hex":"#c74277",
      "file_location":"assets/colors_organized/hue_29/brightness_13/2020040416520200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":13,
      "vividness":11,
      "r":200,
      "g":52,
      "b":110,
      "hex":"#c8346e",
      "file_location":"assets/colors_organized/hue_29/brightness_13/2020040416520201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":13,
      "vividness":12,
      "r":199,
      "g":39,
      "b":103,
      "hex":"#c72767",
      "file_location":"assets/colors_organized/hue_29/brightness_13/2020040416520300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":13,
      "vividness":13,
      "r":200,
      "g":26,
      "b":95,
      "hex":"#c81a5f",
      "file_location":"assets/colors_organized/hue_29/brightness_13/2020040416520301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":13,
      "vividness":14,
      "r":199,
      "g":13,
      "b":86,
      "hex":"#c70d56",
      "file_location":"assets/colors_organized/hue_29/brightness_13/2020040416520302-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":14,
      "vividness":1,
      "r":213,
      "g":201,
      "b":205,
      "hex":"#d5c9cd",
      "file_location":"assets/colors_organized/hue_29/brightness_14/2020040416514800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":14,
      "vividness":2,
      "r":215,
      "g":185,
      "b":197,
      "hex":"#d7b9c5",
      "file_location":"assets/colors_organized/hue_29/brightness_14/2020040416514801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":14,
      "vividness":3,
      "r":214,
      "g":171,
      "b":188,
      "hex":"#d6abbc",
      "file_location":"assets/colors_organized/hue_29/brightness_14/2020040416514900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":14,
      "vividness":4,
      "r":215,
      "g":157,
      "b":181,
      "hex":"#d79db5",
      "file_location":"assets/colors_organized/hue_29/brightness_14/2020040416514901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":14,
      "vividness":5,
      "r":214,
      "g":144,
      "b":172,
      "hex":"#d690ac",
      "file_location":"assets/colors_organized/hue_29/brightness_14/2020040416515000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":14,
      "vividness":6,
      "r":214,
      "g":128,
      "b":163,
      "hex":"#d680a3",
      "file_location":"assets/colors_organized/hue_29/brightness_14/2020040416515001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":14,
      "vividness":7,
      "r":215,
      "g":114,
      "b":154,
      "hex":"#d7729a",
      "file_location":"assets/colors_organized/hue_29/brightness_14/2020040416515100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":14,
      "vividness":8,
      "r":213,
      "g":100,
      "b":144,
      "hex":"#d56490",
      "file_location":"assets/colors_organized/hue_29/brightness_14/2020040416515101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":14,
      "vividness":9,
      "r":214,
      "g":86,
      "b":137,
      "hex":"#d65689",
      "file_location":"assets/colors_organized/hue_29/brightness_14/2020040416515200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":14,
      "vividness":10,
      "r":214,
      "g":71,
      "b":127,
      "hex":"#d6477f",
      "file_location":"assets/colors_organized/hue_29/brightness_14/2020040416515201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":14,
      "vividness":11,
      "r":215,
      "g":56,
      "b":120,
      "hex":"#d73878",
      "file_location":"assets/colors_organized/hue_29/brightness_14/2020040416515202-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":14,
      "vividness":12,
      "r":215,
      "g":41,
      "b":110,
      "hex":"#d7296e",
      "file_location":"assets/colors_organized/hue_29/brightness_14/2020040416515300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":14,
      "vividness":13,
      "r":214,
      "g":28,
      "b":101,
      "hex":"#d61c65",
      "file_location":"assets/colors_organized/hue_29/brightness_14/2020040416515301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":14,
      "vividness":14,
      "r":214,
      "g":13,
      "b":93,
      "hex":"#d60d5d",
      "file_location":"assets/colors_organized/hue_29/brightness_14/2020040416515400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":15,
      "vividness":1,
      "r":230,
      "g":215,
      "b":220,
      "hex":"#e6d7dc",
      "file_location":"assets/colors_organized/hue_29/brightness_15/2020040416513700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":15,
      "vividness":2,
      "r":229,
      "g":199,
      "b":211,
      "hex":"#e5c7d3",
      "file_location":"assets/colors_organized/hue_29/brightness_15/2020040416513701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":15,
      "vividness":3,
      "r":230,
      "g":183,
      "b":201,
      "hex":"#e6b7c9",
      "file_location":"assets/colors_organized/hue_29/brightness_15/2020040416513800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":15,
      "vividness":4,
      "r":230,
      "g":168,
      "b":193,
      "hex":"#e6a8c1",
      "file_location":"assets/colors_organized/hue_29/brightness_15/2020040416513801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":15,
      "vividness":5,
      "r":229,
      "g":153,
      "b":183,
      "hex":"#e599b7",
      "file_location":"assets/colors_organized/hue_29/brightness_15/2020040416513900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":15,
      "vividness":6,
      "r":229,
      "g":137,
      "b":174,
      "hex":"#e589ae",
      "file_location":"assets/colors_organized/hue_29/brightness_15/2020040416513901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":15,
      "vividness":7,
      "r":229,
      "g":122,
      "b":164,
      "hex":"#e57aa4",
      "file_location":"assets/colors_organized/hue_29/brightness_15/2020040416514000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":15,
      "vividness":8,
      "r":229,
      "g":107,
      "b":156,
      "hex":"#e56b9c",
      "file_location":"assets/colors_organized/hue_29/brightness_15/2020040416514001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":15,
      "vividness":9,
      "r":230,
      "g":91,
      "b":146,
      "hex":"#e65b92",
      "file_location":"assets/colors_organized/hue_29/brightness_15/2020040416514100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":15,
      "vividness":10,
      "r":230,
      "g":76,
      "b":138,
      "hex":"#e64c8a",
      "file_location":"assets/colors_organized/hue_29/brightness_15/2020040416514101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":15,
      "vividness":11,
      "r":230,
      "g":61,
      "b":128,
      "hex":"#e63d80",
      "file_location":"assets/colors_organized/hue_29/brightness_15/2020040416514102-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":15,
      "vividness":12,
      "r":229,
      "g":45,
      "b":119,
      "hex":"#e52d77",
      "file_location":"assets/colors_organized/hue_29/brightness_15/2020040416514200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":15,
      "vividness":13,
      "r":228,
      "g":30,
      "b":109,
      "hex":"#e41e6d",
      "file_location":"assets/colors_organized/hue_29/brightness_15/2020040416514201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":29,
      "brightness":15,
      "vividness":14,
      "r":229,
      "g":16,
      "b":100,
      "hex":"#e51064",
      "file_location":"assets/colors_organized/hue_29/brightness_15/2020040416514300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":1,
      "vividness":1,
      "r":19,
      "g":3,
      "b":6,
      "hex":"#130306",
      "file_location":"assets/colors_organized/hue_30/brightness_01/2020040416562200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":2,
      "vividness":1,
      "r":35,
      "g":25,
      "b":26,
      "hex":"#23191a",
      "file_location":"assets/colors_organized/hue_30/brightness_02/2020040416560901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":2,
      "vividness":2,
      "r":34,
      "g":22,
      "b":24,
      "hex":"#221618",
      "file_location":"assets/colors_organized/hue_30/brightness_02/2020040416561000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":2,
      "vividness":3,
      "r":34,
      "g":18,
      "b":21,
      "hex":"#221215",
      "file_location":"assets/colors_organized/hue_30/brightness_02/2020040416561100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":2,
      "vividness":4,
      "r":34,
      "g":15,
      "b":19,
      "hex":"#220f13",
      "file_location":"assets/colors_organized/hue_30/brightness_02/2020040416561101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":2,
      "vividness":5,
      "r":34,
      "g":13,
      "b":18,
      "hex":"#220d12",
      "file_location":"assets/colors_organized/hue_30/brightness_02/2020040416561200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":2,
      "vividness":6,
      "r":35,
      "g":10,
      "b":14,
      "hex":"#230a0e",
      "file_location":"assets/colors_organized/hue_30/brightness_02/2020040416561201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":2,
      "vividness":7,
      "r":34,
      "g":9,
      "b":12,
      "hex":"#22090c",
      "file_location":"assets/colors_organized/hue_30/brightness_02/2020040416561202-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":2,
      "vividness":8,
      "r":34,
      "g":7,
      "b":12,
      "hex":"#22070c",
      "file_location":"assets/colors_organized/hue_30/brightness_02/2020040416561300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":2,
      "vividness":9,
      "r":34,
      "g":3,
      "b":9,
      "hex":"#220309",
      "file_location":"assets/colors_organized/hue_30/brightness_02/2020040416561301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":2,
      "vividness":10,
      "r":35,
      "g":3,
      "b":6,
      "hex":"#230306",
      "file_location":"assets/colors_organized/hue_30/brightness_02/2020040416561400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":3,
      "vividness":1,
      "r":50,
      "g":40,
      "b":41,
      "hex":"#322829",
      "file_location":"assets/colors_organized/hue_30/brightness_03/2020040416560000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":3,
      "vividness":2,
      "r":50,
      "g":35,
      "b":38,
      "hex":"#322326",
      "file_location":"assets/colors_organized/hue_30/brightness_03/2020040416560001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":3,
      "vividness":3,
      "r":50,
      "g":34,
      "b":35,
      "hex":"#322223",
      "file_location":"assets/colors_organized/hue_30/brightness_03/2020040416560002-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":3,
      "vividness":4,
      "r":50,
      "g":29,
      "b":34,
      "hex":"#321d22",
      "file_location":"assets/colors_organized/hue_30/brightness_03/2020040416560100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":3,
      "vividness":5,
      "r":49,
      "g":27,
      "b":30,
      "hex":"#311b1e",
      "file_location":"assets/colors_organized/hue_30/brightness_03/2020040416560101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":3,
      "vividness":6,
      "r":49,
      "g":22,
      "b":27,
      "hex":"#31161b",
      "file_location":"assets/colors_organized/hue_30/brightness_03/2020040416560200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":3,
      "vividness":7,
      "r":49,
      "g":20,
      "b":25,
      "hex":"#311419",
      "file_location":"assets/colors_organized/hue_30/brightness_03/2020040416560201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":3,
      "vividness":8,
      "r":50,
      "g":15,
      "b":22,
      "hex":"#320f16",
      "file_location":"assets/colors_organized/hue_30/brightness_03/2020040416560202-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":3,
      "vividness":9,
      "r":49,
      "g":12,
      "b":19,
      "hex":"#310c13",
      "file_location":"assets/colors_organized/hue_30/brightness_03/2020040416560300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":3,
      "vividness":10,
      "r":49,
      "g":9,
      "b":17,
      "hex":"#310911",
      "file_location":"assets/colors_organized/hue_30/brightness_03/2020040416560301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":3,
      "vividness":11,
      "r":49,
      "g":6,
      "b":15,
      "hex":"#31060f",
      "file_location":"assets/colors_organized/hue_30/brightness_03/2020040416560400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":3,
      "vividness":12,
      "r":50,
      "g":3,
      "b":13,
      "hex":"#32030d",
      "file_location":"assets/colors_organized/hue_30/brightness_03/2020040416560401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":4,
      "vividness":1,
      "r":64,
      "g":60,
      "b":59,
      "hex":"#403c3b",
      "file_location":"assets/colors_organized/hue_30/brightness_04/2020040416554901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":4,
      "vividness":2,
      "r":65,
      "g":56,
      "b":57,
      "hex":"#413839",
      "file_location":"assets/colors_organized/hue_30/brightness_04/2020040416555000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":4,
      "vividness":3,
      "r":64,
      "g":52,
      "b":54,
      "hex":"#403436",
      "file_location":"assets/colors_organized/hue_30/brightness_04/2020040416555001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":4,
      "vividness":4,
      "r":65,
      "g":46,
      "b":50,
      "hex":"#412e32",
      "file_location":"assets/colors_organized/hue_30/brightness_04/2020040416555100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":4,
      "vividness":5,
      "r":65,
      "g":43,
      "b":46,
      "hex":"#412b2e",
      "file_location":"assets/colors_organized/hue_30/brightness_04/2020040416555101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":4,
      "vividness":6,
      "r":65,
      "g":38,
      "b":43,
      "hex":"#41262b",
      "file_location":"assets/colors_organized/hue_30/brightness_04/2020040416555102-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":4,
      "vividness":7,
      "r":65,
      "g":34,
      "b":40,
      "hex":"#412228",
      "file_location":"assets/colors_organized/hue_30/brightness_04/2020040416555200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":4,
      "vividness":8,
      "r":65,
      "g":30,
      "b":36,
      "hex":"#411e24",
      "file_location":"assets/colors_organized/hue_30/brightness_04/2020040416555201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":4,
      "vividness":9,
      "r":64,
      "g":27,
      "b":34,
      "hex":"#401b22",
      "file_location":"assets/colors_organized/hue_30/brightness_04/2020040416555300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":4,
      "vividness":10,
      "r":64,
      "g":21,
      "b":30,
      "hex":"#40151e",
      "file_location":"assets/colors_organized/hue_30/brightness_04/2020040416555301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":4,
      "vividness":11,
      "r":65,
      "g":18,
      "b":26,
      "hex":"#41121a",
      "file_location":"assets/colors_organized/hue_30/brightness_04/2020040416555400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":4,
      "vividness":12,
      "r":64,
      "g":13,
      "b":22,
      "hex":"#400d16",
      "file_location":"assets/colors_organized/hue_30/brightness_04/2020040416555401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":4,
      "vividness":13,
      "r":64,
      "g":6,
      "b":20,
      "hex":"#400614",
      "file_location":"assets/colors_organized/hue_30/brightness_04/2020040416555500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":4,
      "vividness":14,
      "r":64,
      "g":2,
      "b":13,
      "hex":"#40020d",
      "file_location":"assets/colors_organized/hue_30/brightness_04/2020040416555501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":5,
      "vividness":1,
      "r":79,
      "g":69,
      "b":70,
      "hex":"#4f4546",
      "file_location":"assets/colors_organized/hue_30/brightness_05/2020040416554002-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":5,
      "vividness":2,
      "r":80,
      "g":64,
      "b":67,
      "hex":"#504043",
      "file_location":"assets/colors_organized/hue_30/brightness_05/2020040416554100-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":5,
      "vividness":3,
      "r":80,
      "g":57,
      "b":63,
      "hex":"#50393f",
      "file_location":"assets/colors_organized/hue_30/brightness_05/2020040416554101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":5,
      "vividness":4,
      "r":79,
      "g":52,
      "b":57,
      "hex":"#4f3439",
      "file_location":"assets/colors_organized/hue_30/brightness_05/2020040416554200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":5,
      "vividness":5,
      "r":80,
      "g":48,
      "b":53,
      "hex":"#503035",
      "file_location":"assets/colors_organized/hue_30/brightness_05/2020040416554201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":5,
      "vividness":6,
      "r":79,
      "g":42,
      "b":49,
      "hex":"#4f2a31",
      "file_location":"assets/colors_organized/hue_30/brightness_05/2020040416554202-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":5,
      "vividness":7,
      "r":79,
      "g":36,
      "b":45,
      "hex":"#4f242d",
      "file_location":"assets/colors_organized/hue_30/brightness_05/2020040416554300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":5,
      "vividness":8,
      "r":79,
      "g":32,
      "b":40,
      "hex":"#4f2028",
      "file_location":"assets/colors_organized/hue_30/brightness_05/2020040416554301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":5,
      "vividness":9,
      "r":80,
      "g":27,
      "b":37,
      "hex":"#501b25",
      "file_location":"assets/colors_organized/hue_30/brightness_05/2020040416554400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":5,
      "vividness":10,
      "r":79,
      "g":21,
      "b":33,
      "hex":"#4f1521",
      "file_location":"assets/colors_organized/hue_30/brightness_05/2020040416554401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":5,
      "vividness":11,
      "r":79,
      "g":16,
      "b":27,
      "hex":"#4f101b",
      "file_location":"assets/colors_organized/hue_30/brightness_05/2020040416554402-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":5,
      "vividness":12,
      "r":80,
      "g":9,
      "b":23,
      "hex":"#500917",
      "file_location":"assets/colors_organized/hue_30/brightness_05/2020040416554500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":5,
      "vividness":13,
      "r":79,
      "g":7,
      "b":19,
      "hex":"#4f0713",
      "file_location":"assets/colors_organized/hue_30/brightness_05/2020040416554501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":6,
      "vividness":1,
      "r":94,
      "g":82,
      "b":84,
      "hex":"#5e5254",
      "file_location":"assets/colors_organized/hue_30/brightness_06/2020040416553101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":6,
      "vividness":2,
      "r":95,
      "g":76,
      "b":78,
      "hex":"#5f4c4e",
      "file_location":"assets/colors_organized/hue_30/brightness_06/2020040416553200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":6,
      "vividness":3,
      "r":94,
      "g":69,
      "b":73,
      "hex":"#5e4549",
      "file_location":"assets/colors_organized/hue_30/brightness_06/2020040416553201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":6,
      "vividness":4,
      "r":95,
      "g":62,
      "b":69,
      "hex":"#5f3e45",
      "file_location":"assets/colors_organized/hue_30/brightness_06/2020040416553202-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":6,
      "vividness":5,
      "r":94,
      "g":57,
      "b":64,
      "hex":"#5e3940",
      "file_location":"assets/colors_organized/hue_30/brightness_06/2020040416553300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":6,
      "vividness":6,
      "r":95,
      "g":49,
      "b":59,
      "hex":"#5f313b",
      "file_location":"assets/colors_organized/hue_30/brightness_06/2020040416553301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":6,
      "vividness":7,
      "r":95,
      "g":44,
      "b":53,
      "hex":"#5f2c35",
      "file_location":"assets/colors_organized/hue_30/brightness_06/2020040416553400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":6,
      "vividness":8,
      "r":95,
      "g":37,
      "b":49,
      "hex":"#5f2531",
      "file_location":"assets/colors_organized/hue_30/brightness_06/2020040416553401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":6,
      "vividness":9,
      "r":94,
      "g":30,
      "b":44,
      "hex":"#5e1e2c",
      "file_location":"assets/colors_organized/hue_30/brightness_06/2020040416553402-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":6,
      "vividness":10,
      "r":95,
      "g":24,
      "b":38,
      "hex":"#5f1826",
      "file_location":"assets/colors_organized/hue_30/brightness_06/2020040416553500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":6,
      "vividness":11,
      "r":94,
      "g":20,
      "b":33,
      "hex":"#5e1421",
      "file_location":"assets/colors_organized/hue_30/brightness_06/2020040416553501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":6,
      "vividness":12,
      "r":95,
      "g":12,
      "b":28,
      "hex":"#5f0c1c",
      "file_location":"assets/colors_organized/hue_30/brightness_06/2020040416553600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":6,
      "vividness":13,
      "r":95,
      "g":7,
      "b":23,
      "hex":"#5f0717",
      "file_location":"assets/colors_organized/hue_30/brightness_06/2020040416553601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":7,
      "vividness":1,
      "r":110,
      "g":101,
      "b":104,
      "hex":"#6e6568",
      "file_location":"assets/colors_organized/hue_30/brightness_07/2020040416552101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":7,
      "vividness":2,
      "r":110,
      "g":95,
      "b":98,
      "hex":"#6e5f62",
      "file_location":"assets/colors_organized/hue_30/brightness_07/2020040416552102-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":7,
      "vividness":3,
      "r":110,
      "g":88,
      "b":91,
      "hex":"#6e585b",
      "file_location":"assets/colors_organized/hue_30/brightness_07/2020040416552200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":7,
      "vividness":4,
      "r":109,
      "g":80,
      "b":85,
      "hex":"#6d5055",
      "file_location":"assets/colors_organized/hue_30/brightness_07/2020040416552201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":7,
      "vividness":5,
      "r":110,
      "g":73,
      "b":80,
      "hex":"#6e4950",
      "file_location":"assets/colors_organized/hue_30/brightness_07/2020040416552300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":7,
      "vividness":6,
      "r":109,
      "g":66,
      "b":73,
      "hex":"#6d4249",
      "file_location":"assets/colors_organized/hue_30/brightness_07/2020040416552301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":7,
      "vividness":7,
      "r":109,
      "g":58,
      "b":67,
      "hex":"#6d3a43",
      "file_location":"assets/colors_organized/hue_30/brightness_07/2020040416552400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":7,
      "vividness":8,
      "r":111,
      "g":51,
      "b":63,
      "hex":"#6f333f",
      "file_location":"assets/colors_organized/hue_30/brightness_07/2020040416552401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":7,
      "vividness":9,
      "r":110,
      "g":44,
      "b":56,
      "hex":"#6e2c38",
      "file_location":"assets/colors_organized/hue_30/brightness_07/2020040416552500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":7,
      "vividness":10,
      "r":109,
      "g":35,
      "b":50,
      "hex":"#6d2332",
      "file_location":"assets/colors_organized/hue_30/brightness_07/2020040416552501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":7,
      "vividness":11,
      "r":110,
      "g":29,
      "b":44,
      "hex":"#6e1d2c",
      "file_location":"assets/colors_organized/hue_30/brightness_07/2020040416552600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":7,
      "vividness":12,
      "r":110,
      "g":22,
      "b":38,
      "hex":"#6e1626",
      "file_location":"assets/colors_organized/hue_30/brightness_07/2020040416552601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":7,
      "vividness":13,
      "r":110,
      "g":13,
      "b":33,
      "hex":"#6e0d21",
      "file_location":"assets/colors_organized/hue_30/brightness_07/2020040416552700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":7,
      "vividness":14,
      "r":110,
      "g":7,
      "b":26,
      "hex":"#6e071a",
      "file_location":"assets/colors_organized/hue_30/brightness_07/2020040416552701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":8,
      "vividness":1,
      "r":124,
      "g":115,
      "b":118,
      "hex":"#7c7376",
      "file_location":"assets/colors_organized/hue_30/brightness_08/2020040416551200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":8,
      "vividness":2,
      "r":124,
      "g":108,
      "b":111,
      "hex":"#7c6c6f",
      "file_location":"assets/colors_organized/hue_30/brightness_08/2020040416551201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":8,
      "vividness":3,
      "r":124,
      "g":99,
      "b":103,
      "hex":"#7c6367",
      "file_location":"assets/colors_organized/hue_30/brightness_08/2020040416551202-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":8,
      "vividness":4,
      "r":124,
      "g":91,
      "b":98,
      "hex":"#7c5b62",
      "file_location":"assets/colors_organized/hue_30/brightness_08/2020040416551300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":8,
      "vividness":5,
      "r":124,
      "g":83,
      "b":91,
      "hex":"#7c535b",
      "file_location":"assets/colors_organized/hue_30/brightness_08/2020040416551301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":8,
      "vividness":6,
      "r":124,
      "g":74,
      "b":83,
      "hex":"#7c4a53",
      "file_location":"assets/colors_organized/hue_30/brightness_08/2020040416551400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":8,
      "vividness":7,
      "r":124,
      "g":66,
      "b":78,
      "hex":"#7c424e",
      "file_location":"assets/colors_organized/hue_30/brightness_08/2020040416551401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":8,
      "vividness":8,
      "r":124,
      "g":58,
      "b":70,
      "hex":"#7c3a46",
      "file_location":"assets/colors_organized/hue_30/brightness_08/2020040416551500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":8,
      "vividness":9,
      "r":123,
      "g":49,
      "b":64,
      "hex":"#7b3140",
      "file_location":"assets/colors_organized/hue_30/brightness_08/2020040416551501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":8,
      "vividness":10,
      "r":123,
      "g":40,
      "b":56,
      "hex":"#7b2838",
      "file_location":"assets/colors_organized/hue_30/brightness_08/2020040416551600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":8,
      "vividness":11,
      "r":124,
      "g":33,
      "b":50,
      "hex":"#7c2132",
      "file_location":"assets/colors_organized/hue_30/brightness_08/2020040416551601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":8,
      "vividness":12,
      "r":124,
      "g":22,
      "b":43,
      "hex":"#7c162b",
      "file_location":"assets/colors_organized/hue_30/brightness_08/2020040416551700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":8,
      "vividness":13,
      "r":124,
      "g":15,
      "b":38,
      "hex":"#7c0f26",
      "file_location":"assets/colors_organized/hue_30/brightness_08/2020040416551701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":8,
      "vividness":14,
      "r":124,
      "g":6,
      "b":30,
      "hex":"#7c061e",
      "file_location":"assets/colors_organized/hue_30/brightness_08/2020040416551702-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":9,
      "vividness":1,
      "r":139,
      "g":130,
      "b":131,
      "hex":"#8b8283",
      "file_location":"assets/colors_organized/hue_30/brightness_09/2020040416550201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":9,
      "vividness":2,
      "r":140,
      "g":121,
      "b":123,
      "hex":"#8c797b",
      "file_location":"assets/colors_organized/hue_30/brightness_09/2020040416550202-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":9,
      "vividness":3,
      "r":140,
      "g":111,
      "b":116,
      "hex":"#8c6f74",
      "file_location":"assets/colors_organized/hue_30/brightness_09/2020040416550300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":9,
      "vividness":4,
      "r":139,
      "g":102,
      "b":110,
      "hex":"#8b666e",
      "file_location":"assets/colors_organized/hue_30/brightness_09/2020040416550301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":9,
      "vividness":5,
      "r":139,
      "g":93,
      "b":103,
      "hex":"#8b5d67",
      "file_location":"assets/colors_organized/hue_30/brightness_09/2020040416550400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":9,
      "vividness":6,
      "r":139,
      "g":83,
      "b":94,
      "hex":"#8b535e",
      "file_location":"assets/colors_organized/hue_30/brightness_09/2020040416550401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":9,
      "vividness":7,
      "r":138,
      "g":75,
      "b":86,
      "hex":"#8a4b56",
      "file_location":"assets/colors_organized/hue_30/brightness_09/2020040416550500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":9,
      "vividness":8,
      "r":139,
      "g":65,
      "b":80,
      "hex":"#8b4150",
      "file_location":"assets/colors_organized/hue_30/brightness_09/2020040416550501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":9,
      "vividness":9,
      "r":139,
      "g":55,
      "b":71,
      "hex":"#8b3747",
      "file_location":"assets/colors_organized/hue_30/brightness_09/2020040416550600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":9,
      "vividness":10,
      "r":139,
      "g":46,
      "b":65,
      "hex":"#8b2e41",
      "file_location":"assets/colors_organized/hue_30/brightness_09/2020040416550601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":9,
      "vividness":11,
      "r":139,
      "g":36,
      "b":57,
      "hex":"#8b2439",
      "file_location":"assets/colors_organized/hue_30/brightness_09/2020040416550700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":9,
      "vividness":12,
      "r":139,
      "g":27,
      "b":49,
      "hex":"#8b1b31",
      "file_location":"assets/colors_organized/hue_30/brightness_09/2020040416550701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":9,
      "vividness":13,
      "r":140,
      "g":18,
      "b":41,
      "hex":"#8c1229",
      "file_location":"assets/colors_organized/hue_30/brightness_09/2020040416550702-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":9,
      "vividness":14,
      "r":139,
      "g":9,
      "b":35,
      "hex":"#8b0923",
      "file_location":"assets/colors_organized/hue_30/brightness_09/2020040416550800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":10,
      "vividness":1,
      "r":154,
      "g":144,
      "b":145,
      "hex":"#9a9091",
      "file_location":"assets/colors_organized/hue_30/brightness_10/2020040416545101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":10,
      "vividness":2,
      "r":155,
      "g":134,
      "b":139,
      "hex":"#9b868b",
      "file_location":"assets/colors_organized/hue_30/brightness_10/2020040416545200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":10,
      "vividness":3,
      "r":154,
      "g":123,
      "b":129,
      "hex":"#9a7b81",
      "file_location":"assets/colors_organized/hue_30/brightness_10/2020040416545201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":10,
      "vividness":4,
      "r":154,
      "g":113,
      "b":121,
      "hex":"#9a7179",
      "file_location":"assets/colors_organized/hue_30/brightness_10/2020040416545202-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":10,
      "vividness":5,
      "r":154,
      "g":103,
      "b":112,
      "hex":"#9a6770",
      "file_location":"assets/colors_organized/hue_30/brightness_10/2020040416545300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":10,
      "vividness":6,
      "r":154,
      "g":92,
      "b":105,
      "hex":"#9a5c69",
      "file_location":"assets/colors_organized/hue_30/brightness_10/2020040416545301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":10,
      "vividness":7,
      "r":154,
      "g":82,
      "b":96,
      "hex":"#9a5260",
      "file_location":"assets/colors_organized/hue_30/brightness_10/2020040416545400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":10,
      "vividness":8,
      "r":154,
      "g":71,
      "b":87,
      "hex":"#9a4757",
      "file_location":"assets/colors_organized/hue_30/brightness_10/2020040416545401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":10,
      "vividness":9,
      "r":154,
      "g":61,
      "b":80,
      "hex":"#9a3d50",
      "file_location":"assets/colors_organized/hue_30/brightness_10/2020040416545500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":10,
      "vividness":10,
      "r":154,
      "g":51,
      "b":72,
      "hex":"#9a3348",
      "file_location":"assets/colors_organized/hue_30/brightness_10/2020040416545501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":10,
      "vividness":11,
      "r":154,
      "g":40,
      "b":63,
      "hex":"#9a283f",
      "file_location":"assets/colors_organized/hue_30/brightness_10/2020040416545600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":10,
      "vividness":12,
      "r":154,
      "g":30,
      "b":54,
      "hex":"#9a1e36",
      "file_location":"assets/colors_organized/hue_30/brightness_10/2020040416545601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":10,
      "vividness":13,
      "r":154,
      "g":20,
      "b":47,
      "hex":"#9a142f",
      "file_location":"assets/colors_organized/hue_30/brightness_10/2020040416545700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":10,
      "vividness":14,
      "r":154,
      "g":9,
      "b":38,
      "hex":"#9a0926",
      "file_location":"assets/colors_organized/hue_30/brightness_10/2020040416545701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":11,
      "vividness":1,
      "r":169,
      "g":159,
      "b":160,
      "hex":"#a99fa0",
      "file_location":"assets/colors_organized/hue_30/brightness_11/2020040416544101-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":11,
      "vividness":2,
      "r":169,
      "g":147,
      "b":150,
      "hex":"#a99396",
      "file_location":"assets/colors_organized/hue_30/brightness_11/2020040416544200-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":11,
      "vividness":3,
      "r":168,
      "g":135,
      "b":142,
      "hex":"#a8878e",
      "file_location":"assets/colors_organized/hue_30/brightness_11/2020040416544201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":11,
      "vividness":4,
      "r":169,
      "g":123,
      "b":133,
      "hex":"#a97b85",
      "file_location":"assets/colors_organized/hue_30/brightness_11/2020040416544300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":11,
      "vividness":5,
      "r":169,
      "g":113,
      "b":124,
      "hex":"#a9717c",
      "file_location":"assets/colors_organized/hue_30/brightness_11/2020040416544301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":11,
      "vividness":6,
      "r":169,
      "g":101,
      "b":114,
      "hex":"#a96572",
      "file_location":"assets/colors_organized/hue_30/brightness_11/2020040416544400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":11,
      "vividness":7,
      "r":168,
      "g":90,
      "b":104,
      "hex":"#a85a68",
      "file_location":"assets/colors_organized/hue_30/brightness_11/2020040416544401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":11,
      "vividness":8,
      "r":170,
      "g":79,
      "b":97,
      "hex":"#aa4f61",
      "file_location":"assets/colors_organized/hue_30/brightness_11/2020040416544500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":11,
      "vividness":9,
      "r":169,
      "g":67,
      "b":88,
      "hex":"#a94358",
      "file_location":"assets/colors_organized/hue_30/brightness_11/2020040416544501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":11,
      "vividness":10,
      "r":169,
      "g":55,
      "b":78,
      "hex":"#a9374e",
      "file_location":"assets/colors_organized/hue_30/brightness_11/2020040416544600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":11,
      "vividness":11,
      "r":169,
      "g":45,
      "b":69,
      "hex":"#a92d45",
      "file_location":"assets/colors_organized/hue_30/brightness_11/2020040416544601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":11,
      "vividness":12,
      "r":168,
      "g":34,
      "b":61,
      "hex":"#a8223d",
      "file_location":"assets/colors_organized/hue_30/brightness_11/2020040416544602-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":11,
      "vividness":13,
      "r":169,
      "g":22,
      "b":51,
      "hex":"#a91633",
      "file_location":"assets/colors_organized/hue_30/brightness_11/2020040416544700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":11,
      "vividness":14,
      "r":169,
      "g":10,
      "b":42,
      "hex":"#a90a2a",
      "file_location":"assets/colors_organized/hue_30/brightness_11/2020040416544800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":12,
      "vividness":1,
      "r":184,
      "g":172,
      "b":174,
      "hex":"#b8acae",
      "file_location":"assets/colors_organized/hue_30/brightness_12/2020040416543201-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":12,
      "vividness":2,
      "r":185,
      "g":160,
      "b":164,
      "hex":"#b9a0a4",
      "file_location":"assets/colors_organized/hue_30/brightness_12/2020040416543300-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":12,
      "vividness":3,
      "r":184,
      "g":147,
      "b":155,
      "hex":"#b8939b",
      "file_location":"assets/colors_organized/hue_30/brightness_12/2020040416543301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":12,
      "vividness":4,
      "r":185,
      "g":135,
      "b":146,
      "hex":"#b98792",
      "file_location":"assets/colors_organized/hue_30/brightness_12/2020040416543302-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":12,
      "vividness":5,
      "r":185,
      "g":123,
      "b":136,
      "hex":"#b97b88",
      "file_location":"assets/colors_organized/hue_30/brightness_12/2020040416543400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":12,
      "vividness":6,
      "r":184,
      "g":110,
      "b":125,
      "hex":"#b86e7d",
      "file_location":"assets/colors_organized/hue_30/brightness_12/2020040416543401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":12,
      "vividness":7,
      "r":185,
      "g":98,
      "b":115,
      "hex":"#b96273",
      "file_location":"assets/colors_organized/hue_30/brightness_12/2020040416543500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":12,
      "vividness":8,
      "r":184,
      "g":85,
      "b":105,
      "hex":"#b85569",
      "file_location":"assets/colors_organized/hue_30/brightness_12/2020040416543501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":12,
      "vividness":9,
      "r":185,
      "g":73,
      "b":97,
      "hex":"#b94961",
      "file_location":"assets/colors_organized/hue_30/brightness_12/2020040416543600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":12,
      "vividness":10,
      "r":184,
      "g":60,
      "b":86,
      "hex":"#b83c56",
      "file_location":"assets/colors_organized/hue_30/brightness_12/2020040416543601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":12,
      "vividness":11,
      "r":183,
      "g":49,
      "b":76,
      "hex":"#b7314c",
      "file_location":"assets/colors_organized/hue_30/brightness_12/2020040416543602-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":12,
      "vividness":12,
      "r":184,
      "g":35,
      "b":65,
      "hex":"#b82341",
      "file_location":"assets/colors_organized/hue_30/brightness_12/2020040416543700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":12,
      "vividness":13,
      "r":184,
      "g":23,
      "b":56,
      "hex":"#b81738",
      "file_location":"assets/colors_organized/hue_30/brightness_12/2020040416543701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":12,
      "vividness":14,
      "r":184,
      "g":13,
      "b":45,
      "hex":"#b80d2d",
      "file_location":"assets/colors_organized/hue_30/brightness_12/2020040416543800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":13,
      "vividness":1,
      "r":198,
      "g":186,
      "b":188,
      "hex":"#c6babc",
      "file_location":"assets/colors_organized/hue_30/brightness_13/2020040416542301-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":13,
      "vividness":2,
      "r":200,
      "g":173,
      "b":178,
      "hex":"#c8adb2",
      "file_location":"assets/colors_organized/hue_30/brightness_13/2020040416542400-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":13,
      "vividness":3,
      "r":199,
      "g":159,
      "b":167,
      "hex":"#c79fa7",
      "file_location":"assets/colors_organized/hue_30/brightness_13/2020040416542401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":13,
      "vividness":4,
      "r":199,
      "g":146,
      "b":156,
      "hex":"#c7929c",
      "file_location":"assets/colors_organized/hue_30/brightness_13/2020040416542500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":13,
      "vividness":5,
      "r":199,
      "g":133,
      "b":145,
      "hex":"#c78591",
      "file_location":"assets/colors_organized/hue_30/brightness_13/2020040416542501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":13,
      "vividness":6,
      "r":200,
      "g":119,
      "b":134,
      "hex":"#c87786",
      "file_location":"assets/colors_organized/hue_30/brightness_13/2020040416542502-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":13,
      "vividness":7,
      "r":199,
      "g":106,
      "b":125,
      "hex":"#c76a7d",
      "file_location":"assets/colors_organized/hue_30/brightness_13/2020040416542600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":13,
      "vividness":8,
      "r":199,
      "g":93,
      "b":113,
      "hex":"#c75d71",
      "file_location":"assets/colors_organized/hue_30/brightness_13/2020040416542601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":13,
      "vividness":9,
      "r":199,
      "g":79,
      "b":104,
      "hex":"#c74f68",
      "file_location":"assets/colors_organized/hue_30/brightness_13/2020040416542700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":13,
      "vividness":10,
      "r":199,
      "g":66,
      "b":93,
      "hex":"#c7425d",
      "file_location":"assets/colors_organized/hue_30/brightness_13/2020040416542701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":13,
      "vividness":11,
      "r":200,
      "g":53,
      "b":82,
      "hex":"#c83552",
      "file_location":"assets/colors_organized/hue_30/brightness_13/2020040416542702-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":13,
      "vividness":12,
      "r":199,
      "g":38,
      "b":71,
      "hex":"#c72647",
      "file_location":"assets/colors_organized/hue_30/brightness_13/2020040416542800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":13,
      "vividness":13,
      "r":200,
      "g":26,
      "b":61,
      "hex":"#c81a3d",
      "file_location":"assets/colors_organized/hue_30/brightness_13/2020040416542801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":13,
      "vividness":14,
      "r":199,
      "g":13,
      "b":50,
      "hex":"#c70d32",
      "file_location":"assets/colors_organized/hue_30/brightness_13/2020040416542900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":14,
      "vividness":1,
      "r":215,
      "g":200,
      "b":203,
      "hex":"#d7c8cb",
      "file_location":"assets/colors_organized/hue_30/brightness_14/2020040416541401-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":14,
      "vividness":2,
      "r":215,
      "g":186,
      "b":191,
      "hex":"#d7babf",
      "file_location":"assets/colors_organized/hue_30/brightness_14/2020040416541500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":14,
      "vividness":3,
      "r":214,
      "g":171,
      "b":180,
      "hex":"#d6abb4",
      "file_location":"assets/colors_organized/hue_30/brightness_14/2020040416541501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":14,
      "vividness":4,
      "r":215,
      "g":157,
      "b":169,
      "hex":"#d79da9",
      "file_location":"assets/colors_organized/hue_30/brightness_14/2020040416541502-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":14,
      "vividness":5,
      "r":214,
      "g":143,
      "b":157,
      "hex":"#d68f9d",
      "file_location":"assets/colors_organized/hue_30/brightness_14/2020040416541600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":14,
      "vividness":6,
      "r":215,
      "g":128,
      "b":145,
      "hex":"#d78091",
      "file_location":"assets/colors_organized/hue_30/brightness_14/2020040416541601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":14,
      "vividness":7,
      "r":215,
      "g":113,
      "b":134,
      "hex":"#d77186",
      "file_location":"assets/colors_organized/hue_30/brightness_14/2020040416541700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":14,
      "vividness":8,
      "r":214,
      "g":100,
      "b":123,
      "hex":"#d6647b",
      "file_location":"assets/colors_organized/hue_30/brightness_14/2020040416541701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":14,
      "vividness":9,
      "r":215,
      "g":85,
      "b":111,
      "hex":"#d7556f",
      "file_location":"assets/colors_organized/hue_30/brightness_14/2020040416541702-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":14,
      "vividness":10,
      "r":214,
      "g":71,
      "b":99,
      "hex":"#d64763",
      "file_location":"assets/colors_organized/hue_30/brightness_14/2020040416541800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":14,
      "vividness":11,
      "r":214,
      "g":57,
      "b":88,
      "hex":"#d63958",
      "file_location":"assets/colors_organized/hue_30/brightness_14/2020040416541801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":14,
      "vividness":12,
      "r":213,
      "g":42,
      "b":76,
      "hex":"#d52a4c",
      "file_location":"assets/colors_organized/hue_30/brightness_14/2020040416541900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":14,
      "vividness":13,
      "r":214,
      "g":28,
      "b":65,
      "hex":"#d61c41",
      "file_location":"assets/colors_organized/hue_30/brightness_14/2020040416541901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":14,
      "vividness":14,
      "r":215,
      "g":13,
      "b":53,
      "hex":"#d70d35",
      "file_location":"assets/colors_organized/hue_30/brightness_14/2020040416542000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":15,
      "vividness":1,
      "r":229,
      "g":214,
      "b":217,
      "hex":"#e5d6d9",
      "file_location":"assets/colors_organized/hue_30/brightness_15/2020040416540500-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":15,
      "vividness":2,
      "r":230,
      "g":199,
      "b":205,
      "hex":"#e6c7cd",
      "file_location":"assets/colors_organized/hue_30/brightness_15/2020040416540501-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":15,
      "vividness":3,
      "r":229,
      "g":183,
      "b":193,
      "hex":"#e5b7c1",
      "file_location":"assets/colors_organized/hue_30/brightness_15/2020040416540600-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":15,
      "vividness":4,
      "r":230,
      "g":168,
      "b":181,
      "hex":"#e6a8b5",
      "file_location":"assets/colors_organized/hue_30/brightness_15/2020040416540601-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":15,
      "vividness":5,
      "r":229,
      "g":152,
      "b":168,
      "hex":"#e598a8",
      "file_location":"assets/colors_organized/hue_30/brightness_15/2020040416540602-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":15,
      "vividness":6,
      "r":230,
      "g":137,
      "b":156,
      "hex":"#e6899c",
      "file_location":"assets/colors_organized/hue_30/brightness_15/2020040416540700-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":15,
      "vividness":7,
      "r":229,
      "g":122,
      "b":142,
      "hex":"#e57a8e",
      "file_location":"assets/colors_organized/hue_30/brightness_15/2020040416540701-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":15,
      "vividness":8,
      "r":229,
      "g":107,
      "b":130,
      "hex":"#e56b82",
      "file_location":"assets/colors_organized/hue_30/brightness_15/2020040416540800-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":15,
      "vividness":9,
      "r":229,
      "g":91,
      "b":117,
      "hex":"#e55b75",
      "file_location":"assets/colors_organized/hue_30/brightness_15/2020040416540801-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":15,
      "vividness":10,
      "r":229,
      "g":76,
      "b":106,
      "hex":"#e54c6a",
      "file_location":"assets/colors_organized/hue_30/brightness_15/2020040416540900-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":15,
      "vividness":11,
      "r":230,
      "g":61,
      "b":94,
      "hex":"#e63d5e",
      "file_location":"assets/colors_organized/hue_30/brightness_15/2020040416540901-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":15,
      "vividness":12,
      "r":229,
      "g":45,
      "b":81,
      "hex":"#e52d51",
      "file_location":"assets/colors_organized/hue_30/brightness_15/2020040416541000-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":15,
      "vividness":13,
      "r":230,
      "g":30,
      "b":69,
      "hex":"#e61e45",
      "file_location":"assets/colors_organized/hue_30/brightness_15/2020040416541001-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
   {
      "hue":30,
      "brightness":15,
      "vividness":14,
      "r":230,
      "g":15,
      "b":57,
      "hex":"#e60f39",
      "file_location":"assets/colors_organized/hue_30/brightness_15/2020040416541002-02CB906EA538A35643C1E1484C4B947D.jpg"
   },
]

export {colors};